// @ts-nocheck
import React, { useEffect, useState } from 'react';
import ModalCard from '../ModalCard';
import { useApi } from '../../../hooks';
import { getRolesRequest } from '../../../api/rolesApi';
import { createUserRequest, deleteUserRequest, updateUserRequest } from '../../../api/userApi';
import { formValidator } from '../../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT, VALIDATION_TEMPLATE } from '../../../helpers/formValidator';
import { getSubjectsRequest } from '../../../api/subjectApi';
import { SUBJECT_TYPE_ATTRIBUTES } from '../../../constants/enums';
import { formatPhone } from '../../../helpers/stringHelper';
import { useModalStore } from '../../../store';

const selectorFields = { value: 'id', label: 'name' };

const initialSelectorValue = { id: '', name: '' };

const initialUserInfo = {
  email: '',
  last_name: '',
  first_name: '',
  middle_name: '',
  phone: '',
  inn: { inn: '' },
  ogrn: '',
  subject_type: '',
  registry_address: '',
  actual_address: '',
  password: '',
  role: { ...initialSelectorValue },
  subject: { ...initialSelectorValue },
};

const userFrame = [
  {
    id: 1,
    title: 'Регистрационные данные',
    inputFields: [
      {
        id: 'email', label: 'E-mail', type: 'email', required: true,
      },
      {
        id: 'phone', label: 'Номер телефона', type: 'tel', required: true,
      },
      { id: 'password_text', sx: { width: 349 }, text: 'По умолчанию паролем является номер телефона без специальных символов' },
      {
        id: 'password', label: 'Пароль', type: 'password', required: true,
      },
    ],
  },
  {
    id: 2,
    containers: [
      {
        id: 1,
        title: 'Роль',
        inputFields: [
          {
            id: 'role', label: 'Роль', type: 'autocomplete', request: getRolesRequest, fields: selectorFields, required: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Связанный субъект',
        inputFields: [
          {
            id: 'subject',
            label: 'Субъект',
            type: 'autocomplete',
            request: (params) => getSubjectsRequest({ params }),
            fields: selectorFields,
            required: true,
          },
          {
            id: 'inn',
            label: 'ИНН',
            type: 'autocomplete',
            required: true,
            request: (params) => getSubjectsRequest({ params }),
            fields: { label: 'inn', id: 'inn' },
          },
          {
            id: 'subject_type', label: 'Тип субъекта', disabled: () => true,
          },
          {
            id: 'ogrn', label: 'ОГРН', disabled: () => true,
          },
          {
            id: 'registry_address', label: 'Адрес регистрации', disabled: () => true,
          },
          {
            id: 'actual_address', label: 'Адрес фактического местонахождения', disabled: () => true,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Личные данные',
    inputFields: [
      { id: 'last_name', label: 'Фамилия', required: true },
      { id: 'first_name', label: 'Имя', required: true },
      { id: 'middle_name', label: 'Отчество', required: true },
    ],
  },
];

export default function ModalUsers({
  closeModal, userData, updateUsers, isCreateUserModal,
}) {
  const { modalStore, setModalStore } = useModalStore();
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    if (!userData?.id) return;

    setIsLoading(true);

    const subjectAttributes = userData?.subject_attributes?.attributes;

    setUserInfo({
      ...userData,
      subject: {
        id: userData.subject_id,
        name: userData?.subject_attributes?.attributes?.name,
      },
      inn: { inn: subjectAttributes?.inn },
      ogrn: subjectAttributes?.ogrn,
      subject_type: subjectAttributes?.subject_type_attributes?.attributes?.name,
      registry_address: subjectAttributes?.registry_address,
      actual_address: subjectAttributes?.actual_address,
      role: { id: userData.role_id, name: userData?.role_attributes?.attributes?.name },
    });
    setIsLoading(false);
  }, [userData]);

  const handleChange = (field) => (event, value) => {
    if (['inn', 'subject'].includes(field)) {
      if (value.inn) {
        setError((prevState) => ({
          ...prevState,
          inn: { inn: '' },
          subject: { id: '', name: '' },
        }));

        setUserInfo((prevState) => ({
          ...prevState,
          inn: { ...prevState.inn, ...value },
          subject: value,
          ogrn: value.ogrn,
          registry_address: value.registry_address,
          actual_address: value.actual_address,
          subject_type: value?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name,
        }));
        return;
      }
      if (!value.inn || !value.name) {
        setError((prevState) => ({
          ...prevState,
          inn: { inn: '' },
          subject: { id: '', name: '' },
        }));

        setUserInfo((prevState) => ({
          ...prevState,
          inn: { inn: '' },
          subject: { id: '', name: '' },
          subject_type: '',
          ogrn: '',
          registry_address: '',
          actual_address: '',
        }));
        return;
      }
    }

    const params = { [field]: value };
    const errorParams = { [field]: '' };

    if (field === 'phone') {
      params.password = formatPhone(value);
      errorParams.password = '';
    }

    setError((prevState) => ({
      ...prevState,
      ...errorParams,
    }));

    setUserInfo((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const updateUserInfo = async (requestType) => {
    const {
      first_name,
      last_name,
      middle_name,
      password,
      role,
      phone,
      subject,
      email,
    } = userInfo;

    const newUserInfo = {
      first_name,
      last_name,
      middle_name,
      phone,
      role_id: role.id,
      subject_id: subject.id,
      email,
    };

    const customRules = [
      { name: VALIDATION_TEMPLATE.email },
      { name: VALIDATION_TEMPLATE.phone },
    ];
    const inputRule = {
      email: [VALIDATION_TEMPLATE.email],
      phone: [VALIDATION_TEMPLATE.phone],
    };

    if (password !== undefined && (isCreateUserModal || password.length > 0)) {
      newUserInfo.password = password;
      customRules.push(...[
        { name: VALIDATION_TEMPLATE.password },
      ]);
      inputRule.password = [VALIDATION_TEMPLATE.password];
    }

    const { hasErrors, validField } = formValidator({
      form: newUserInfo,
      customRules,
      inputRule,
      ignoreInputs: ['ogrn'],
    });

    setError(validField);
    if (hasErrors) {
      throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };
    }

    newUserInfo.password_confirmation = newUserInfo.password;

    newUserInfo.phone = formatPhone(newUserInfo.phone);

    if (requestType === 'update') await updateUserRequest(userInfo.id, newUserInfo);
    else if (requestType === 'create') await createUserRequest(newUserInfo);

    updateUsers();
    closeModal();
  };

  const { makeRequest: onUpdateUserInfo } = useApi({
    setIsLoading,
    request: updateUserInfo,
    successMessage: 'Данные сохранены',
  });

  const deleteObject = () => {
    setModalStore({
      ...modalStore,
      deleteModal: {
        isOpen: true,
        callback: async () => {
          await updateUsers();
          closeModal();
        },
        objectName: 'пользователя',
        deleteRequest: deleteUserRequest,
        id: userData.id,
        message: 'Пользователь удалён',
      },
    });
  };

  return (
    <ModalCard
      open
      isLoading={isLoading}
      inputs={userFrame}
      close={closeModal}
      error={error}
      containerMaxWidth="md"
      handleChange={handleChange}
      inputData={userInfo}
      firstButtonProp={!userData?.deleted_at && userData?.id && {
        label: 'Удалить пользователя',
        onClick: deleteObject,
      }}
      secondButtonProp={{
        onClick: () => onUpdateUserInfo(isCreateUserModal ? 'create' : 'update'),
        label: 'Сохранить',
      }}
    />
  );
}
