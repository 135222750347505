/* eslint-disable */

const translatedApiError = {
  subject: 'Субъект',
  subjects: 'Субъекты',
  user: 'Пользователь',
  users: 'Пользователи',
  address: 'Адрес',
  addresses: 'Адреса',
  role: 'Роль',
  roles: 'Роли',
  privilege: 'Привилегия',
  privileges: 'Привилегии',
  email: 'E-mail',
  login: 'Логин',
  phone: 'Телефон',
  inn: 'ИНН',
  ogrn: 'ОГРН',
  password: 'Пароль',
  password_confirmation: 'Пароль подтверждения',
  first_name: 'Имя',
  file: 'Файл',
  last_name: 'Фамилия',
  middle_name: 'Отчество',
  name: 'Название',
  application_subjects: 'В заявлении',
  place_objective: 'Объект размещения',
  transfer_objective: 'Объект перевозчик',
  transfer_subject: 'Субъект перевозчик',
};

export const handleApiError = (error, pushNotification) => {
  const noResponseFromServer = error?.data === undefined;
  const isErrorString = typeof error?.data === 'string';
  const isHtmlError = isErrorString && error.data.includes('html');

  if (error?.status === 404) {
    return pushNotification({ message: 'Запись не найдена' });
  }

  if (isErrorString && !isHtmlError) {
    return pushNotification({ message: error?.data });
  }

  if (isHtmlError || noResponseFromServer || typeof error?.data?.error === 'string') {
    pushNotification({
      message: error?.data?.error
        || (noResponseFromServer ? 'Сервер не отвечает на запросы' : 'Сервер не смог обработать запрос'),
    });
    return;
  }

  if (error.data?.message !== undefined) {
    return pushNotification({ message: error.data?.message || "Неизвестная ошибка" })
  }

  const errorNames = Object.keys(error.data);

  errorNames.forEach((errorName) => {
    const splitName = errorName.split('.');
    const translated = splitName.map((item) => translatedApiError[item]).join(' > ');
    pushNotification({ message: [`${translated || errorName} ${error.data[errorName][0]}`] });
  });
};
