// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React, { useState } from 'react';
import { getReferencesRequest } from '../../../../../api/referenceApi';
import { getSubjectsRequest, updateSubjectRequest } from '../../../../../api/subjectApi';
import {
  OBJECT_SUBJECT_RELATIONSHIPS_NAME,
  getInitialObjectSubjectRelationship,
} from '../../../../../constants/lkCadastr/cadastrForm8';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../../../constants/lkCadastr/cadastrStorage';
import { OKTMOS_URL } from '../../../../../constants/urls';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';
import { getOktmoLabel } from '../../../../../helpers/stringHelper';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import { AsyncAutocomplete } from '../../../../AutoCompletes/AsyncAutocomplete';
import AddButton from '../../../../Buttons/AddButton';
import DeleteButton from '../../../../Buttons/DeleteButton';
import CustomDatePicker from '../../../../CustomDatePicker';
import CustomInput from '../../../../Inputs';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../../Inputs/CustomAutocomplete';
import NavTabs from '../../../../Tabs';
import { TextTitle } from '../../../../Text';
import ModalAddOktmo from '../../../ModalAddOktmo';
import { compareId } from '../../../../../helpers';

export function WasteTransporters({
  placingObject,
  changeObject,
  isDemo,
  setCreateSubjectField,
  placingObjectField,
  openDeleteModal,
}) {
  const objectSubjectRelationships = placingObject[OBJECT_SUBJECT_RELATIONSHIPS_NAME];
  const [oktmoInfo, setOktmoInfo] = useState(null);

  const { tab, setTab, tabs } = useCadastreTabs({ items: objectSubjectRelationships, tabName: 'Юридическое лицо' });

  const closeOktmoModal = () => setOktmoInfo(null);

  const setNewOktmo = async ({ oktmo, subject, field }) => {
    await updateSubjectRequest(subject?.id, { oktmo: getOktmoLabel(oktmo) });
    changeObject(field, {
      ...subject,
      oktmo: getOktmoLabel(oktmo),
    });
    closeOktmoModal();
  };

  const modalOktmo = (
    <ModalAddOktmo
      close={closeOktmoModal}
      setNewOktmo={(newOktmo) => setNewOktmo({
        oktmo: newOktmo,
        subject: oktmoInfo.subject,
        field: oktmoInfo.field,
      })}
      isOpen
    />
  );

  return (
    <Box sx={flexGap2}>
      {oktmoInfo !== null && modalOktmo}
      <Box sx={flexRowGap2}>
        <TextTitle h3>
          Наименование юридического лица
          (ФИО индивидуального предпринимателя),
          транспортирующего отхода на объект
        </TextTitle>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeObject(
              OBJECT_SUBJECT_RELATIONSHIPS_NAME,
              [...objectSubjectRelationships, getInitialObjectSubjectRelationship()],
            )}
          >
            Добавить юридическое лицо
          </AddButton>
        )}
      </Box>
      <NavTabs tab={tab} setTab={setTab} tabsComponents={tabs} />
      {objectSubjectRelationships.map((objectSubjectRelationship, objectSubjectRelationshipIndex) => {
        if (tab !== objectSubjectRelationship.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${OBJECT_SUBJECT_RELATIONSHIPS_NAME}.${objectSubjectRelationshipIndex}.${field}`, value, undefined, { objectSubjectRelationshipIndex });
        };

        const onDelete = async () => {
          const deletedObjectSubjectRelationship = await deleteForm8Entity({
            id: objectSubjectRelationship.id,
            apiType: OBJECT_SUBJECT_RELATIONSHIPS_NAME,
          });

          if (!deletedObjectSubjectRelationship) return;

          const cloneArray = objectSubjectRelationships.slice();
          cloneArray.splice(objectSubjectRelationshipIndex, 1);

          changeObject(
            OBJECT_SUBJECT_RELATIONSHIPS_NAME,
            cloneArray,
            'delete',
          );
        };

        const isZeroSubject = compareId(objectSubjectRelationship.subject_id?.name, 0);

        return (
          <Box key={objectSubjectRelationship.randId} sx={flexGap2}>
            {!isDemo && objectSubjectRelationships.length > 1 && objectSubjectRelationship?.id && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, objectSubjectRelationshipIndex, 'Юридическое лицо')}
              >
                Удалить юридическое лицо
              </DeleteButton>
            )}
            <Box sx={flexGap2}>
              <Alert severity="info">
                Для поиска организации в справочнике введите наименование или ИНН в графу «Наименование юридического лица (ФИО индивидуального предпринимателя), транспортирующего отхода на объект»,  в случае отсутствия нажмите кнопку «+Создать субъект» и добавьте организацию в справочник.
              </Alert>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!isZeroSubject}
                debounceTime={0}
                disabled={isDemo}
                request={(params) => getSubjectsRequest({ params })}
                label="Наименование или ФИО"
                sx={fullWidth}
                noOptionComponent={(
                  <AddButton
                    variant="outlined"
                    onClick={() => setCreateSubjectField({
                      field: `${placingObjectField}.${OBJECT_SUBJECT_RELATIONSHIPS_NAME}.${objectSubjectRelationshipIndex}.subject_id`,
                      objectSubjectRelationshipIndex,
                    })}
                  >
                    Создать субъект
                  </AddButton>
                )}
                value={objectSubjectRelationship.subject_id}
                onChange={(e, value) => onChange('subject_id', value)}
              />
              <CustomInput
                disabled
                multiline
                label="Фактический адрес"
                sx={fullWidth}
                value={objectSubjectRelationship.subject_id?.actual_address}
              />
              <CustomInput
                disabled
                multiline
                label="Адрес регистрации"
                sx={fullWidth}
                value={objectSubjectRelationship.subject_id?.registry_address}
              />
              <AsyncAutocomplete
                debounceTime={0}
                validImmediately={!(!objectSubjectRelationship?.subject_id?.id || objectSubjectRelationship?.subject_id?.oktmo || isZeroSubject)}
                disabled={!objectSubjectRelationship?.subject_id?.id || objectSubjectRelationship?.subject_id?.oktmo || isZeroSubject}
                label="ОКТМО"
                optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                stringValue={objectSubjectRelationship?.subject_id?.oktmo}
                onChange={(e, value) => setNewOktmo({
                  oktmo: value,
                  subject: objectSubjectRelationship?.subject_id,
                  field: `${OBJECT_SUBJECT_RELATIONSHIPS_NAME}.${objectSubjectRelationshipIndex}.subject_id`,
                })}
                noOptionComponent={(
                  <AddButton
                    onClick={() => setOktmoInfo({
                      field: `${OBJECT_SUBJECT_RELATIONSHIPS_NAME}.${objectSubjectRelationshipIndex}.subject_id`,
                      subject: objectSubjectRelationship?.subject_id,
                    })}
                    variant="outlined"
                  >
                    Добавить ОКТМО
                  </AddButton>
                )}
                additionalFilter={(options) => options.map((option) => ({
                  ...option,
                  label: `${option.code} ${option.name}`,
                }))}
                request={(params) => getReferencesRequest({ url: OKTMOS_URL, params })}
                sx={fullWidth}
                containerSx={fullWidth}
              />
              <CustomInput
                disabled
                label="ИНН"
                value={objectSubjectRelationship.subject_id?.inn}
              />
              <CustomInput
                canBeZero
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Номер документа"
                value={objectSubjectRelationship.agreement_number}
                onChange={(e, value) => onChange('agreement_number', value)}
              />
              <CustomDatePicker
                canBeZero
                validImmediately
                disabled={isDemo}
                sx={fitContent}
                label="Дата"
                value={objectSubjectRelationship.agreement_date}
                onChange={(e, value) => onChange('agreement_date', value)}
              />
              <CustomInput
                canBeZero
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                type="number"
                label="Количество, т"
                value={objectSubjectRelationship.tons_qty}
                onChange={(e, value) => onChange('tons_qty', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
