// @ts-nocheck
import { useEffect } from 'react';
import { useTableStore } from '../store';
import { useApi } from './useApi';

export function useTableSearch({
  search,
  setter,
  deps = [],
  params,
}) {
  const {
    setTableStore, tableStore: {
      search: tableSearch, page, perPage, searchText,
    },
  } = useTableStore();

  const { makeRequest, isLoading, defaultRequest } = useApi({
    request: async (requestParams) => {
      const data = await search({
        page: searchText ? 1 : page,
        total: perPage,
        search: searchText || undefined,
        ...params,
        ...requestParams,
      }, setTableStore);

      if (typeof setter === 'function') setter(data);
    },
  });

  useEffect(() => {
    if (search) {
      setTableStore({ search: makeRequest });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchText, search, ...deps]);

  useEffect(() => {
    if (tableSearch) {
      tableSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSearch, page, perPage, ...deps]);

  return {
    isLoading, makeRequest, setTableStore, page, perPage, searchText, defaultRequest,
  };
}
