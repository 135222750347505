import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import NavTabs from '../Tabs';
import {
  CADASTR_CONTACT_INFO_ROUTE,
  CADASTR_FAQ_ROUTE,
  CADASTR_FEEDBACK_ROUTE,
  CADASTRE_ROUTE,
} from '../../constants/routes';

const TABS = [
  { name: CADASTR_FAQ_ROUTE, label: 'Вопросы и ответы' },
  { name: CADASTR_CONTACT_INFO_ROUTE, label: 'Контактная информация' },
  { name: CADASTR_FEEDBACK_ROUTE, label: 'Обратная связь' },
  // { name: CADASTR_ARHCIVE_ROUTE, label: 'Архив отчётов' },
];

export default function CadastrToolbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tab = useMemo(() => TABS.find(({ name }) => pathname.includes(name))?.name || '', [pathname]);

  if (!pathname.includes(`${CADASTRE_ROUTE}/`)) return null;

  return (
    <Box display="flex" alignItems="flex-end" minHeight="64px">
      <NavTabs tabsComponents={TABS} setTab={(newTab) => navigate(newTab)} tab={tab} />
    </Box>
  );
}
