import { Box, styled } from '@mui/material';

const InputsBoxStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 24,
  marginBottom: 16,
});

export default InputsBoxStyled;
