import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Link } from '@mui/material';
import { getUrlFromFile } from '../../helpers/fileHelper';
import DeleteButton from '../Buttons/DeleteButton';

function UrlFromFile({
  file,
  onClick = () => {},
  download = true,
  showRemove = true,
  removeFile,
  isLoading,
  showOnlyLink,
  isFileReadyToShow,
}) {
  const [link, setLink] = useState('');

  const getFile = useCallback(async () => {
    const result = await getUrlFromFile(file);
    setLink(result);
  }, [file]);

  useEffect(() => {
    if (file && !isFileReadyToShow) getFile();
    else if (isFileReadyToShow) setLink(file?.file?.size ? URL.createObjectURL(file?.file) : file?.file);
    // eslint-disable-next-line
  }, [file, isFileReadyToShow]);

  if (!file && !showOnlyLink) return null;

  if (isLoading) return <CircularProgress />;

  const linkFileComponent = link && (
    <Link target="_blank" onClick={onClick} download={download} href={link} rel="noreferrer">
      Файл:
      {' '}
      {file.name}
    </Link>
  );

  if (showOnlyLink) return file ? linkFileComponent : <p>Файл отсутствует</p>;

  return (
    <Box display="flex" alignItems="center">
      {linkFileComponent}
      {showRemove && (
        <DeleteButton sx={{ ml: 1 }} onClick={() => removeFile()}>
          Удалить
        </DeleteButton>
      )}
    </Box>
  );
}

export default UrlFromFile;
