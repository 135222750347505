import { compareId } from '../../helpers';
import { addTotalCadastreCount } from '../../helpers/cadastreForms/getFormField';
import { OWNERSHIP_TYPE_TRANSLATE } from '../vehicleConstants';
import { getInitialForm2Item } from './cadastrForm2';
import { getInitialForm3Item } from './cadastrForm3';

const wasteProductFields = [
  { name: 'code', valid: (code) => code?.id },
  'quantity',
  { name: 'hazard_class', valid: (hazard_class, data) => data.code?.hazard_class || hazard_class },
];

export const getInitialWasteProduct = (newWasteId) => {
  const additionalProps = newWasteId ? { id: newWasteId } : {};

  return ({
    ...additionalProps,
    randId: Math.random(),
    code: '',
    hazard_class: '',
    quantity: '',
    form2Waste: getInitialForm2Item(),
    form3Waste: getInitialForm3Item(),
  });
};

const containerFields = [
  { name: 'container_material', valid: (container_material) => container_material?.id },
  'container_capacity',
  'container_quantity',
];

export const getInitialContainer = (newContainerId) => {
  const additionalProps = newContainerId ? { id: newContainerId } : {};

  return ({
    ...additionalProps,
    randId: Math.random(),
    container_material: '',
    container_capacity: '',
    container_quantity: '',
  });
};

const platformFields = [
  'area_number',
  { name: 'objective_id', valid: (objective_id) => objective_id?.id },
];

export const getInitialPlatforms = (newPlatformId) => {
  const additionalProps = newPlatformId ? { id: newPlatformId } : {};

  return ({
    ...additionalProps,
    randId: Math.random(),
    area_number: '',
    objective_id: '',
    containers: [getInitialContainer()],
  });
};

const leaseFields = [
  'lease_contract_date',
  'lease_contract_number',
  'ownership_type',
];

const ownershipTypeFields = [
  'ownership_type',
];

const getInitialLease = () => ({
  ownership_type: '',
  lease_contract_date: null,
  lease_contract_number: '',
});

const buildingFields = [
  { name: 'objective_id', valid: (objective_id) => objective_id?.id || compareId(objective_id?.label, 0) },
  'building',
  { name: 'enterprise_activity_types', valid: (enterprise_activity_types) => enterprise_activity_types?.[0] === '0' || enterprise_activity_types?.[0]?.id },
  'total_area',
];

export const getInitialForm1Item = (buildingId) => {
  const addedProps = buildingId !== undefined ? { id: buildingId } : {};

  return ({
    ...addedProps,
    randId: Math.random(),
    objective_id: '',
    building: '',
    ...getInitialLease(),
    enterprise_activity_types: [],
    total_area: '',
    waste_products: [],
    platforms: [],
  });
};

export const getInitialForm1 = () => ({
  items: [getInitialForm1Item()],
});

export const form1PercentGetter = (forms) => {
  const { items } = forms.form1;

  let allDataLength = 0;
  let allFilledCount = 0;

  const addAllDataLength = (count) => {
    allDataLength += count;
  };

  const addFilledCount = () => {
    allFilledCount += 1;
  };

  items.forEach((building) => {
    if (building.ownership_type === OWNERSHIP_TYPE_TRANSLATE.rent) {
      addAllDataLength(leaseFields.length);
      addTotalCadastreCount(leaseFields, building, addFilledCount);
    } else {
      addAllDataLength(1);
      addTotalCadastreCount(ownershipTypeFields, building, addFilledCount);
    }

    addAllDataLength(buildingFields.length);
    addTotalCadastreCount(buildingFields, building, addFilledCount);

    building.waste_products.forEach((wasteProduct) => {
      addAllDataLength(wasteProductFields.length);

      addTotalCadastreCount(wasteProductFields, wasteProduct, addFilledCount);
    });

    building.platforms.forEach((platform) => {
      addAllDataLength(platformFields.length);

      addTotalCadastreCount(platformFields, platform, addFilledCount);

      platform.containers.forEach((container) => {
        addAllDataLength(containerFields.length);

        addTotalCadastreCount(containerFields, container, addFilledCount);
      });
    });
  });

  if (allFilledCount === 0) return 0;

  return Math.floor((allFilledCount / allDataLength) * 100);
};

export const CONTAINER_MATERIALS = [
  { id: 'plastic', value: 'Пластик', label: 'Пластик' },
  { id: 'metal', value: 'Металл', label: 'Металл' },
  { id: 'other', value: 'Иное', label: 'Иное' },
];
