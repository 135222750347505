// @ts-nocheck
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useApi } from '../../../hooks';
import { getSubjectRequest } from '../../../api/subjectApi';
import { VEHICLES_URL } from '../../../constants/urls';
import CustomTable from '../../Tables/CustomTable';
import { ReactComponent as ConnectedRINSIcon } from '../../../assets/connected_rins_icon.svg';
import { ReactComponent as NotConnectedRINSIcon } from '../../../assets/not_connected_rins_icon.svg';
import { TextSignature } from '../../Text';

const initialVehicleTable = [
  {
    field: 'egrz',
    getField: ({ egrz, model, guid_bnso }) => (
      <Typography sx={{ width: 92 }}>
        <p>{egrz}</p>
        <p>{guid_bnso}</p>
        <TextSignature>{model}</TextSignature>
      </Typography>
    ),
  },
  {
    field: 'ownership_type_text',
    getField: ({ ownership_type_text }) => (
      <Typography sx={{ width: 92 }}>
        <TextSignature>Тип владения</TextSignature>
        <p>{ownership_type_text}</p>
      </Typography>
    ),
  },
  {
    field: 'id',
    getField: ({
      is_connected_rins,
    }) => (is_connected_rins ? <ConnectedRINSIcon /> : <NotConnectedRINSIcon />),
  },
];

export default function CarParkTable({ cellData, open, searchText }) {
  const [vehicles, setVehicles] = useState([]);
  const [totalVehicles, setTotalVehicles] = useState(0);

  const { isLoading } = useApi({
    setter: setVehicles,
    request: () => getSubjectRequest({
      id: cellData.id,
      url: VEHICLES_URL,
      params: {
        total: 1000,
        search: searchText,
      },
      setTableStore: (response) => setTotalVehicles(response?.meta?.total || 0),
    }),
    shouldRequest: true,
  });

  return (
    <CustomTable
      hideHeader
      hidePagination
      onClick={open}
      isLoading={isLoading}
      total={totalVehicles}
      data={vehicles}
      sx={{
        table: {
          minWidth: '320px',
        },
        '.MuiTableCell-root p': {
          margin: 0,
        },
      }}
      columns={initialVehicleTable}
    />
  );
}
