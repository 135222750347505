// @ts-nocheck
import React, { useMemo } from 'react';
import {
  Box, Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CustomInput from '../Inputs';
import BackdropLoading from '../BackdropLoading';
import {
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  ENTERPRISE_TYPE_ENUM,
  HAZARD_CLASS_ENUM,
} from '../../constants/enums';
import { Text, TextMain, TextTitle } from '../Text';
import CadastrEdit from './CadastrEdit';
import CadastrFieldsList from './CadastrFieldsList';
import { INPUT_EMPTY_TEXT } from '../../constants';
import { getApplicationEnumsRequest } from '../../api/applicationsApi';
import { getSubjectEnumsRequest } from '../../api/subjectApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import { getReferencesRequest } from '../../api/referenceApi';
import { upperCaseFirstLetter } from '../../helpers/stringHelper';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { formatActivityOptions } from '../../helpers/cadastreForms/cadastreFormsPrepareFrontend';
import { FNSLoadData } from '../FNSLoadData';

const subjectActivityFields = [
  {
    title: 'Виды деятельности',
    field: ENTERPRISE_ACTIVITY_TYPE_IDS,
    request: (params) => getReferencesRequest({
      url: ENTERPRISE_ACTIVITY_TYPES_ENUM,
      params,
      filter: formatActivityOptions,
    }),
    multiple: true,
    optionField: DEFAULT_AUTOCOMPLETE_FIELD,
    getField: (form) => form[ENTERPRISE_ACTIVITY_TYPE_IDS]?.map(({
      label,
      id,
    }) => <Text key={id}>{label}</Text>),
  },
  {
    title: 'Класс опасности отходов',
    field: HAZARD_CLASS_ENUM,
    optionField: DEFAULT_AUTOCOMPLETE_FIELD,
    request: (params) => getApplicationEnumsRequest(
      HAZARD_CLASS_ENUM,
      params,
    ),
    multiple: true,
    getField: (form) => {
      const hazardClassesComponents = form[HAZARD_CLASS_ENUM]
        .map(({ label }) => <Text key={label}>{label}</Text>);

      return hazardClassesComponents.length ? hazardClassesComponents : INPUT_EMPTY_TEXT;
    },
  },
  {
    title: 'Вид предприятия',
    field: ENTERPRISE_TYPE_ENUM,
    request: (params) => getSubjectEnumsRequest({ enum_field: ENTERPRISE_TYPE_ENUM, params }),
    optionField: DEFAULT_AUTOCOMPLETE_FIELD,
    getField: (form) => (form?.[ENTERPRISE_TYPE_ENUM]?.label
      ? <Text>{form[ENTERPRISE_TYPE_ENUM].label}</Text>
      : INPUT_EMPTY_TEXT),
  },
  {
    title: 'Тип субъекта',
    field: 'subject_type_attributes',
    getField: (form) => upperCaseFirstLetter(form.subject_type_attributes?.attributes?.name),
  },
];

export default function CadastrRegistrationData({
  error, editField, changeEdit, userInfo, makeRequest, onChange, isLoading, subject_type, getMember,
}) {
  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const subjectDataFields = useMemo(() => [
    {
      title: 'Адрес регистрации',
      field: 'registry_address',
      multiline: true,
      sx: { width: tabletSizeAndLower ? 320 : 500 },
    },
    {
      title: 'Адрес фактического местонахождения',
      field: 'actual_address',
      multiline: true,
      sx: { width: tabletSizeAndLower ? 320 : 500 },
    },
    { title: 'ИНН', field: 'inn' },
    { title: 'ОГРН', field: 'ogrn' },
    { title: 'КПП', field: 'kpp' },
  ], [tabletSizeAndLower]);

  return (
    <Box position="relative">
      <BackdropLoading isLoading={isLoading} />
      <Box display="flex" gap={10} flexWrap="wrap">
        <Box>
          <Box mb={3} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <TextTitle h3>Регистрационные данные</TextTitle>
            <Box sx={flexGap2}>
              {userInfo.full_name && (
                <Box width={320}>
                  <TextTitle>Полное наименование субъекта</TextTitle>
                  <TextMain>{userInfo.full_name}</TextMain>
                </Box>
              )}
              <Box>
                <TextTitle>Руководитель организации</TextTitle>
                <Box>
                  <CadastrEdit
                    error={error}
                    onChange={onChange}
                    editField={editField}
                    field="boss_name"
                    title="ФИО"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                  <CadastrEdit
                    error={error}
                    onChange={onChange}
                    editField={editField}
                    field="email"
                    title="E-mail организации (логин)"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                  <CadastrEdit
                    error={error}
                    editField={editField}
                    onChange={onChange}
                    title="Номер телефона"
                    field="boss_phone"
                    type="tel"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                </Box>
              </Box>
              <Box>
                <TextTitle>Ответственное лицо</TextTitle>
                <Box display="flex" flexDirection="column">
                  <CadastrEdit
                    error={error}
                    onChange={onChange}
                    editField={editField}
                    field="contact_name"
                    title="ФИО"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                  <CadastrEdit
                    error={error}
                    onChange={onChange}
                    editField={editField}
                    field="contact_email"
                    title="E-mail"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                  <CadastrEdit
                    error={error}
                    editField={editField}
                    onChange={onChange}
                    title="Номер телефона"
                    field="contact_phone"
                    type="tel"
                    changeEdit={changeEdit}
                    makeRequest={makeRequest}
                    userInfo={userInfo}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} mb={5} width="320px">
            <Typography component="h4" className="text-title">Изменение пароля</Typography>
            <CustomInput
              required
              value={userInfo.old_password}
              type="password"
              label="Текущий пароль"
              name="old_password"
              onChange={onChange}
              error={error.old_password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Новый пароль"
              name="password"
              type="password"
              value={userInfo.password}
              error={error.password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Подтверждение пароля"
              type="password"
              name="password_confirmation"
              value={userInfo.password_confirmation}
              error={error.password_confirmation}
            />
            <Button onClick={() => makeRequest(['password_confirmation', 'password', 'old_password'])}>
              Сохранить
              пароль
            </Button>
          </Box>
        </Box>
        <CadastrFieldsList
          userInfo={userInfo}
          editField={editField}
          changeEdit={changeEdit}
          makeRequest={makeRequest}
          onChange={onChange}
          error={error}
          fields={subjectDataFields}
          title="Данные субъекта"
        />
        <CadastrFieldsList
          userInfo={userInfo}
          editField={editField}
          onChange={onChange}
          changeEdit={changeEdit}
          makeRequest={makeRequest}
          error={error}
          fields={subjectActivityFields}
          title="Деятельность субъекта"
        />
        <FNSLoadData userInfo={userInfo} subject_type={subject_type} callback={() => getMember()} />
      </Box>
    </Box>
  );
}
