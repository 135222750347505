// @ts-nocheck
import Api from './api';

export const getReferencesRequest = ({
  url, params, filter, shouldCache, setTableStore = undefined,
}) => Api(
  { params, url: url.includes('/') ? url : `/${url}` },
  {
    isEntityAttributes: true, filter, shouldCache, setTableStore,
  },
);

export const getReferenceRequest = ({ id, url }) => Api({ method: 'get', url: `${url}/${id}` });

export const updateReferenceRequest = ({ id, data, url }) => Api({ method: 'put', url: `${url}/${id}`, data });

export const deleteReferenceRequest = ({ id, url }) => Api({ method: 'delete', url: `${url}/${id}` });

export const createReferenceRequest = ({ data, url }) => Api({ method: 'post', url, data });
