import Api from './api';
import { DESTROY_FEW, PRIVILEGE_ROLES_URL, ROLES_URL } from '../constants/urls';

export const getRolesRequest = (params, setTableStore) => Api(
  { url: ROLES_URL, params },
  { isEntityAttributes: true, setTableStore },
);


export const getRoleRequest = (id) => Api({ method: 'get', url: `${ROLES_URL}/${id}` });

export const getRolePrivilegesRequest = (id, params) => Api({
  method: 'get',
  params,
  url: `${ROLES_URL}/${id}${PRIVILEGE_ROLES_URL}`,
}, { isEntityAttributes: true });

export const updateRoleRequest = (id, data) => Api({ method: 'put', url: `${ROLES_URL}/${id}`, data });

export const createRoleRequest = (role) => Api({
  method: 'post',
  url: ROLES_URL,
  data: { role },
}, { isEntityAttributes: true });

export const deleteRoleRequest = (id) => Api({ method: 'delete', url: `${ROLES_URL}/${id}` });

export const removeRolePrivilegeRequest = (ids) => Api({
  method: 'delete',
  url: `${PRIVILEGE_ROLES_URL}${DESTROY_FEW}?ids=${ids.toString()}`,
}, { restUrl: '' });

export const addPrivilegeRoleRequest = (privilege_roles) => Api({
  method: 'post',
  url: PRIVILEGE_ROLES_URL,
  data: { privilege_roles },
});
