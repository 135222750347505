import {
  checkIsControlOrigin,
  checkIsPrivateOrigin,
  checkIsPublicCadastreOrigin,
  checkIsPublicRKO47Origin,
} from '../helpers/browserLocation';
import { PUBLIC_EMAIL_CONFIRMATION_ROUTE } from './routes/emailConfirmationRoutes';

export const ANALYTICS = '/analytics';

export const ADMIN_REGISTRY_SUBJECTS_ROUTE = '/admin/registry-subjects';
export const ADMIN_REGISTRY_OBJECTS_ROUTE = '/admin/registry-objects';
export const ADMIN_OPERATIONS_ROUTE = '/admin/operations';
export const ADMIN_SEND_EMAIL_ROUTE = '/admin/send-email';
export const ADMIN_USERS_ROLES_ROUTE = '/admin/users-roles';
export const ADMIN_NOTIFICATIONS_ROUTE = '/admin/notifications';
export const ADMIN_SUBSCRIPTIONS_ROUTE = '/admin/subscriptions';
export const ADMIN_WASTE_MANAGEMENT_GUIDE = '/admin/waste-management-guide';
export const ADMIN_TYPE_ENTERPRISE_GUIDE = '/admin/type-enterprise-guide';
export const ADMIN_HAZARD_CLASSES_GUIDE = '/admin/hazard-classes-guide';
export const ADMIN_WASTE_CONDITION_ROUTE = '/admin/waste-conditions';
export const ADMIN_TER_SCHEME_ROUTE = '/admin/ter-scheme';
export const ADMIN_WASTE_CADASTRE_ROUTE = '/admin/waste-cadastre';
export const ADMIN_PREMISES_APPOINTMENT_GUIDE = '/admin/premises-appointment-guide';
export const ADMIN_OKTMO_GUIDE = '/admin/oktmo-guide';
export const ADMIN_FKKO_GUIDE = '/admin/fkko-guide';
export const ADMIN_REGISTRY_TS = '/admin/registry-ts';
export const ADMIN_REGISTRY_APPLICATIONS = '/admin/registry-applications';
export const ADMIN_SUBJECT_TYPE_PAGE = '/admin/type-subject-guide';
export const ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE = '/admin/enterprise-activity-kind';
export const ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE = `/admin${ANALYTICS}-control-carriers`;
export const ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE = `/admin${ANALYTICS}-waste-cadastre`;

export const CARRIER_GENERAL_INFO_ROUTE = '/carrier/general-info';
export const CARRIER_PERSONAL_ACCOUNT_ROUTE = '/carrier/personal-account';

export const CADASTRE_ROUTE = '/cadastre-lk';
export const CADASTR_GENERAL_INFO_ROUTE = `${CADASTRE_ROUTE}/general-info`;
export const CADASTR_PERSONAL_ACCOUNT_ROUTE = `${CADASTRE_ROUTE}/personal-account`;
export const CADASTR_MAP_ROUTE = `${CADASTRE_ROUTE}/map`;
export const CADASTR_DOCUMENT_RECOMMENDATION_ROUTE = `${CADASTRE_ROUTE}/document-recommendation`;
export const CADASTR_NORMATIVE_BASE_ROUTE = `${CADASTRE_ROUTE}/normative-base`;
export const CADASTR_FAQ_ROUTE = `${CADASTRE_ROUTE}/faq`;
export const CADASTR_CONTACT_INFO_ROUTE = `${CADASTRE_ROUTE}/contact_info`;
export const CADASTR_FEEDBACK_ROUTE = `${CADASTRE_ROUTE}/feedback`;
export const CADASTR_ARHCIVE_ROUTE = `${CADASTRE_ROUTE}/archive`;

export const COMMITTEE_ROUTE = '/lkkgen';
export const COMMITTEE_MONITORING_VEHICLES = `${COMMITTEE_ROUTE}/monitoring-vehicles`;
export const COMMITTEE_ANALYTICS = `${COMMITTEE_ROUTE}${ANALYTICS}`;
export const COMMITTEE_DEVIATION = `${COMMITTEE_ROUTE}/deviation`;
export const COMMITTEE_PROFILE = `${COMMITTEE_ROUTE}/profile`;
export const COMMITTEE_WASTE_CARRIERS = `${COMMITTEE_ROUTE}/waste-carriers`;

export const APPLICATION_ROUTE = '/application';
export const APPLICATION_HASH = `#${APPLICATION_ROUTE}`;
export const VEHICLE_HASH = '#vehicle';
export const CREATE_APPLICATION = `${APPLICATION_HASH}-create`;
export const CREATE_VEHICLE = `${VEHICLE_HASH}-create`;

export const INITIAL_RKO_47_ROUTE = '/';

export const PUBLIC_REGISTRATION_SUBJECT_ROUTE = '/public/registration-subject';
export const PUBLIC_LOGIN_ADMIN_PAGE_ROUTE = '/public/login-admin';
export const PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE = checkIsPublicRKO47Origin() ? '/read_TS' : '/public/login-admin-terscheme';
export const PUBLIC_FORGOT_PASSWORD_ROUTE = '/public/forgot-password';
export const PUBLIC_RESET_PASSWORD_ROUTE = '/public/reset-password';
export const PUBLIC_APPLICATION_ROUTE = '/public/application';
export const PUBLIC_LOGIN_CARRIER_PAGE_ROUTE = checkIsControlOrigin() ? '/' : '/public/login-carrier';
export const PUBLIC_LOGIN_CADASTR_PAGE_ROUTE = checkIsPublicCadastreOrigin() ? '/cadastre' : '/public/login-cadastr';
export const PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE = '/public/login-cadastradmin';
export const PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE = checkIsControlOrigin() ? COMMITTEE_ROUTE : '/public/login-committee';

export const CADASTR_ADMIN_ROUTE = '/admin_cadastre';
export const CADASTR_ADMIN_GENERAL_INFO_ROUTE = `${CADASTR_ADMIN_ROUTE}/general-info`;
export const CADASTR_ADMIN_REPORTS_ROUTE = `${CADASTR_ADMIN_ROUTE}/reports`;
export const CADASTR_ADMIN_MAP_ROUTE = `${CADASTR_ADMIN_ROUTE}/map`;
export const CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE = `${CADASTR_ADMIN_ROUTE}/document-recommendation`;
export const CADASTR_ADMIN_NORMATIVE_BASE_ROUTE = `${CADASTR_ADMIN_ROUTE}/normative-base`;
export const CADASTR_ADMIN_FAQ_ROUTE = `${CADASTR_ADMIN_ROUTE}/faq`;
export const CADASTR_ADMIN_CONTACT_INFO_ROUTE = `${CADASTR_ADMIN_ROUTE}/contact_info`;
export const CADASTR_ADMIN_FEEDBACK_ROUTE = `${CADASTR_ADMIN_ROUTE}/feedback`;

export const PUBLIC_ROUTES = [
  ...Object.values(PUBLIC_EMAIL_CONFIRMATION_ROUTE),
  PUBLIC_REGISTRATION_SUBJECT_ROUTE,
  PUBLIC_RESET_PASSWORD_ROUTE,
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_FORGOT_PASSWORD_ROUTE,
  PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
  PUBLIC_APPLICATION_ROUTE,
].filter((route) => {
  if (route === PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE) return checkIsPrivateOrigin();
  if (route === PUBLIC_LOGIN_CADASTR_PAGE_ROUTE) return !checkIsControlOrigin();
  return true;
});

export const COMMITTEE_ROUTES = [
  COMMITTEE_WASTE_CARRIERS,
  COMMITTEE_ANALYTICS,
  COMMITTEE_MONITORING_VEHICLES,
  COMMITTEE_DEVIATION,
  COMMITTEE_PROFILE,
];

export const SUBJECT_ROUTES = [
  CARRIER_PERSONAL_ACCOUNT_ROUTE,
  CARRIER_GENERAL_INFO_ROUTE,
];

export const CADASTR_ROUTES = [
  CADASTR_GENERAL_INFO_ROUTE,
  CADASTR_PERSONAL_ACCOUNT_ROUTE,
  CADASTR_MAP_ROUTE,
  CADASTR_DOCUMENT_RECOMMENDATION_ROUTE,
  CADASTR_NORMATIVE_BASE_ROUTE,
  CADASTR_FAQ_ROUTE,
  CADASTR_CONTACT_INFO_ROUTE,
  CADASTR_FEEDBACK_ROUTE,
  CADASTR_ARHCIVE_ROUTE,
];

export const CADASTR_ADMIN_ROUTES = [
  CADASTR_ADMIN_GENERAL_INFO_ROUTE,
  CADASTR_ADMIN_REPORTS_ROUTE,
  CADASTR_ADMIN_MAP_ROUTE,
  CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE,
  CADASTR_ADMIN_NORMATIVE_BASE_ROUTE,
  CADASTR_ADMIN_FAQ_ROUTE,
  CADASTR_ADMIN_CONTACT_INFO_ROUTE,
  CADASTR_ADMIN_FEEDBACK_ROUTE,
];

export const ADMIN_ROUTES = [
  ADMIN_USERS_ROLES_ROUTE,
  ADMIN_NOTIFICATIONS_ROUTE,
  ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE,
  ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE,
  ADMIN_REGISTRY_SUBJECTS_ROUTE,
  ADMIN_REGISTRY_TS,
  ADMIN_REGISTRY_OBJECTS_ROUTE,
  ADMIN_OPERATIONS_ROUTE,
  ADMIN_SEND_EMAIL_ROUTE,
  ADMIN_WASTE_MANAGEMENT_GUIDE,
  ADMIN_TYPE_ENTERPRISE_GUIDE,
  ADMIN_HAZARD_CLASSES_GUIDE,
  ADMIN_TER_SCHEME_ROUTE,
  ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE,
  ADMIN_SUBJECT_TYPE_PAGE,
  ADMIN_WASTE_CONDITION_ROUTE,
  ADMIN_REGISTRY_APPLICATIONS,
  ADMIN_PREMISES_APPOINTMENT_GUIDE,
  ADMIN_WASTE_CADASTRE_ROUTE,
  ADMIN_OKTMO_GUIDE,
  ADMIN_SUBSCRIPTIONS_ROUTE,
  ADMIN_FKKO_GUIDE,
  ...COMMITTEE_ROUTES,
  ...CADASTR_ADMIN_ROUTES,
];
