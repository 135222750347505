import React from 'react';
import { Box, ButtonBase } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { BUTTON_BLUE } from '../../constants/colors';
import { ReactComponent as FullScreen } from '../../assets/fullscreenIcon.svg';

const buttonStyle = {
  width: '44px',
  height: '44px',
  minWidth: '44px',
  background: 'white',
  borderRadius: '8px',
  color: BUTTON_BLUE,
  svg: {
    fill: BUTTON_BLUE,
  },
};

const buttonFullscreenSx = {
  width: '44px', minWidth: '44px', height: '44px', background: 'white', borderRadius: '8px',
};

export default function MapControls({ mapRef, changeFullscreen }) {
  return (
    <Box
      position="absolute"
      display="flex"
      width="100%"
      height="100%"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Box
        position="relative"
        zIndex={401}
        display="flex"
        flexDirection="column"
        gap={1}
        pr={1}
      >
        <ButtonBase
          sx={buttonStyle}
          variant="contained"
          onClick={() => mapRef?.current?.zoomIn()}
        >
          <Add />
        </ButtonBase>
        <ButtonBase
          sx={buttonStyle}
          variant="contained"
          onClick={() => mapRef?.current?.zoomOut()}
        >
          <Remove />
        </ButtonBase>
        <ButtonBase
          sx={buttonFullscreenSx}
          onClick={changeFullscreen}
          variant="contained"
        >
          <FullScreen />
        </ButtonBase>
      </Box>
    </Box>
  );
}
