import { ENTERPRISE_TYPE_ENUM, SUBJECT_TYPE_ENUM, WASTE_MANAGEMENT_ENUM } from '../constants/enums';
import {
  getActivityTypeByValue,
  getEnterpriseTypeByValue,
  getSubjectTypeByValue,
} from '../constants/subjectConstants';
import { compareId } from './compareId';
import { getFullName, upperCaseFirstLetter } from './stringHelper';

export const addEntityAddress = (object, addresses) => {
  const addressLabel = addresses.find(({ id }) => compareId(id, object.address_id))?.label;
  return {
    ...object,
    address: addressLabel,
  };
};

export const filterUniqWaste = (options, wastes) => options.filter((item) => !wastes.some((wasteProduct) => compareId(item.id, wasteProduct.code?.id)));

export const getEntityAttributes = (entity) => ({ ...entity, ...entity.attributes });

export const filterIndividualSubjectKind = (newOptions) => newOptions
  .filter(({ value }) => value !== 5);

export const addEntityAddressSelector = (object, addresses) => addEntityAddress(object, addresses);

export const addSubjectSelectorAttributes = (object) => ({
  ...object,
  [SUBJECT_TYPE_ENUM]: getSubjectTypeByValue(object[SUBJECT_TYPE_ENUM]),
  [WASTE_MANAGEMENT_ENUM]: getActivityTypeByValue(object[WASTE_MANAGEMENT_ENUM]),
  [ENTERPRISE_TYPE_ENUM]: getEnterpriseTypeByValue(object[ENTERPRISE_TYPE_ENUM]),
});

export const translateAllSubjectAttributes = (
  object,
  addresses,
) => addSubjectSelectorAttributes(addEntityAddressSelector(object, addresses));

export const addEntitiesAddress = (data, addresses) => data
  .map((item) => addEntityAddress(item, addresses));

export const formatAttributes = (object) => {
  const mainObject = {
    ...object.attributes,
    id: object.id,
    attributeId: object?.attributes?.id,
    type: object.type,
    relationships: object.relationships,
  };

  if (object?.attributes?.first_name) {
    mainObject.fullName = getFullName(object?.attributes);
  }

  return mainObject;
};

export const formatEntityAttributes = ({ data: { data: newData, meta }, data }, { setTableStore }) => {
  if (setTableStore && meta) {
    setTableStore({ meta });
  }

  if (!data?.data && data?.id) return data;

  if (!newData) return;

  if (Array.isArray(newData)) {
    return newData.map((item) => formatAttributes(item));
  }
  return formatAttributes(newData);
};

export const formatEnumFieldData = (data, enum_field) => {
  const enumFieldData = data[enum_field];
  return (Object.keys(enumFieldData).map((field) => ({
    label: enumFieldData[field],
    id: field,
    value: field,
  })));
};

export const formatEnumToSelectOption = (options) => options.map((item) => ({
  label: item.name,
  value: item.id,
  id: item.id,
}));

export const getHazardClassObject = (object) => ({
  hazard_class_text: upperCaseFirstLetter(object?.hazard_class_text),
  hazard_class: object?.hazard_class,
});

const HAZARD_CLASSES = {
  one: 'Первый',
  two: 'Второй',
  three: 'Третий',
  four: 'Четвёртый',
  five: 'Пятый',
};

export const getHazardClassByName = (hazard_class_name = '') => ({
  hazard_class_text: upperCaseFirstLetter(HAZARD_CLASSES[hazard_class_name]),
  hazard_class: hazard_class_name,
});

export const subjectWithInnOptions = (options) => options.map((subject) => ({
  ...subject,
  name: `${subject.name} / ИНН: ${subject.inn}`,
}));
