import { getAddressesRequest } from '../api/geolocation';
import { useApi } from './useApi';

export const useAddressRequest = ({ setter, address_id, shouldRequest } = {}) => {
  const getAddresses = async (data) => {
    const addresses = await getAddressesRequest({
      id: data?.new_address_id || address_id,
      ...data?.params,
    });
    setter?.(addresses);
    data?.callback?.(addresses);
    return addresses;
  };

  const { makeRequest, isLoading, defaultRequest } = useApi({
    request: getAddresses,
    shouldRequest,
  });

  return { isLoading, makeRequest, defaultRequest };
};
