// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { getGeoServerData } from '../../api/geolocation';
import { getObjectivesRequest } from '../../api/objectivesApi';
import RedHouse from '../../assets/map-marker-red.svg';
import BackdropLoading from '../../components/BackdropLoading';
import CustomCheckbox from '../../components/CustomCheckbox';
import LeafletMap from '../../components/Map/LeafletMap';
import areas_lo from '../../components/Map/areas_lo.json';
import ObjectInfoSlider from '../../components/Map/components/ObjectInfoSlider';
import { WASTE_MANAGEMENT_TYPES_ENUM, WASTE_MANAGEMENT_TYPE_IDS } from '../../constants/enums';
import { convertCRS } from '../../helpers/mapHelper';
import { objectChangeDotProperty } from '../../helpers/objectHelper';
import { useApi } from '../../hooks';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import useGetReferenceItems from '../../hooks/useGetRefferenceItems';


const TEST_LAYERS = [
  { id: 'areas_lo', value: false, name: 'Границы районов Санкт-Петербурга' },
  { id: 'regions_lo', value: false, name: 'Районы ЛО' },
];

const objectivesRequest = (params) => getObjectivesRequest({ params });

export default function CadastrMap() {
  const [wasteGeneratingObjects, setWasteGeneratorObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [regions, setRegions] = useState();
  const [layers, setLayers] = useState(TEST_LAYERS);

  const { getReferenceTypeIdsByCode: getWasteTypeIdsByCode, referenceTypes } = useGetReferenceItems({ referenceUrl: WASTE_MANAGEMENT_TYPES_ENUM });

  const { isLoading: loadingObjects, makeRequest: getObjectivesApi } = useApi({
    setter: setWasteGeneratorObjects,
    request: objectivesRequest,
    cancelPrevious: true,
  });

  useEffect(() => {
    if (referenceTypes.length && !wasteGeneratingObjects.length) {
      const generatingWasteTypes = getWasteTypeIdsByCode(['generating']);

      const params = {
        [WASTE_MANAGEMENT_TYPE_IDS]: generatingWasteTypes.join(','),
        total: 1000000,
      };

      getObjectivesApi(params);
    }
  }, [referenceTypes, wasteGeneratingObjects]);

  useApi({
    request: getGeoServerData,
    shouldRequest: true,
    setter: ({ data }) => {
      const cloneFeatures = data.features?.slice?.();

      if (cloneFeatures) {
        const formattedFeatures = cloneFeatures.map((item) => ({
          ...item,
          coordinates: item.geometry.coordinates[0][0]
            .map((coordinates) => convertCRS(coordinates[0], coordinates[1])),
        }));
        setRegions(formattedFeatures);
      }
    },
  });

  const { isMobileTablet } = useCustomMediaQuery();

  const layer = useMemo(() => ({
    areas_lo,
    regions_lo: regions,
  }), [regions]);

  const changeLayer = (index) => (e, value) => {
    const changedLayers = objectChangeDotProperty({
      field: `${index}`,
      value: { ...layers[index], value },
      object: layers,
    });

    setLayers(changedLayers);
  };

  const wasteGeneratingPoints = useMemo(
    () => wasteGeneratingObjects.map(({ address_attributes, ...rest }) => ({
      ...rest,
      id: address_attributes.id,
      ...address_attributes.attributes,
      tooltip: rest.name,
    })),
    [wasteGeneratingObjects],
  );

  return (
    <Box sx={{ width: '100%', height: '100%', pt: 3 }}>
      <BackdropLoading isLoading={loadingObjects} />
      {selectedObject?.id !== undefined && (
        <ObjectInfoSlider
          info={selectedObject}
          onClose={() => setSelectedObject({})}
        />
      )}
      <Box display="flex">
        {layers.map(({ id, value: itemValue, name }, index) => (
          <CustomCheckbox
            key={id}
            name={id}
            label={name}
            value={itemValue}
            onChange={changeLayer(index)}
          />
        ))}
      </Box>
      <LeafletMap
        hasLegend={false}
        regions={regions}
        width="100%"
        height={isMobileTablet ? '50vh' : 'calc(100vh - 240px)'}
        layers={layers
          .filter(({ value }) => value)
          .map(({ id }) => ({ id, value: layer[id] }))}
        clusters={[
          {
            points: wasteGeneratingPoints,
            polygonColor: 'red',
            markerIcon: RedHouse,
            clusterColor: 'red',
            onClick: setSelectedObject,
          },
        ]}
      />
    </Box>
  );
}
