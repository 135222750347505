// @ts-nocheck
import { Box } from '@mui/material';
import cloneDeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import { getCitiesRequest } from '../../api/addressApi';
import { getApplicationEnumsRequest } from '../../api/applicationsApi';
import { getObjectivesRequest } from '../../api/objectivesApi';
import { getReferencesRequest } from '../../api/referenceApi';
import { createReportRequest } from '../../api/reportApi';
import { getSubjectsRequest } from '../../api/subjectApi';
import { getWasteTypesRequest } from '../../api/wasteTypeApi';
import { AddressAutocomplete } from '../../components/AutoCompletes';
import { AsyncAutocomplete } from '../../components/AutoCompletes/AsyncAutocomplete';
import BackdropLoading from '../../components/BackdropLoading';
import CustomButton from '../../components/Buttons/CustomButton';
import Submit from '../../components/Buttons/SubmitButton';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomRadioGroup from '../../components/CustomRadio';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_OPTION } from '../../components/Inputs/CustomAutocomplete';
import Selector, { DEFAULT_SELECTOR_FIELDS } from '../../components/Selectors/Selector';
import { TextTitle } from '../../components/Text';
import { DISTRICTS_OPTIONS } from '../../constants/districts';
import {
  DEFAULT_ENUM_OPTION,
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  HAZARD_CLASS_ENUM,
} from '../../constants/enums';
import { flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { ENTERPRISE_ACTIVITY_TYPES_URL, WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import { downloadBlobFileOnClick, formValidator } from '../../helpers';
import { formatDate } from '../../helpers/dateHelper';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { useApi } from '../../hooks';

const autocompleteSx = { width: '100%' };

const COMPARATIVE_REPORT = 'comparative';
const BY_ACTIVITIES = 'by_activities';

const reportOptions = [
  { id: COMPARATIVE_REPORT, label: 'Сравнительный отчет', value: COMPARATIVE_REPORT },
  { id: BY_ACTIVITIES, label: 'По видам деятельности', value: BY_ACTIVITIES },
  { id: 'count_by_subject', label: 'По количеству отчётов (субъект)', value: 'count_by_subject' },
  { id: 'count_by_years', label: 'По количеству отчётов (год)', value: 'count_by_years' },
  { id: 'motion_scheme', label: 'Схема движ (образователи)', value: 'motion_scheme' },
  {
    id: 'motion_scheme_transportation',
    label: 'Схема движ (транспортирование)',
    value: 'motion_scheme_transportation',
  },
  { id: 'motion_scheme_placing', label: 'Схема движ (размещения)', value: 'motion_scheme_placing' },
];

const initialForm = {
  year: null,
  date_from: null,
  date_to: null,
  kind: reportOptions[0],
  format_type: 'xlsx',
  waste_type_id: { ...DEFAULT_ENUM_OPTION },
  waste_management_type: { ...DEFAULT_ENUM_OPTION },
  [ENTERPRISE_ACTIVITY_TYPE_IDS]: [],
  subject_owner_id: { ...DEFAULT_ENUM_OPTION },
  subject_transport: { ...DEFAULT_ENUM_OPTION },
  subject_id: { ...DEFAULT_ENUM_OPTION },
  object_name: { ...DEFAULT_ENUM_OPTION },
  [HAZARD_CLASS_ENUM]: { ...DEFAULT_AUTOCOMPLETE_OPTION },
  accept_subject_id: { ...DEFAULT_ENUM_OPTION },
  waste_generator_district: { ...DEFAULT_ENUM_OPTION },
  waste_generator_city: { ...DEFAULT_ENUM_OPTION },
  waste_conditions: { ...DEFAULT_ENUM_OPTION },
  waste_group: { ...DEFAULT_ENUM_OPTION },
  subject_transfer_id: { ...DEFAULT_ENUM_OPTION },
  subject: { ...DEFAULT_ENUM_OPTION },
};

export default function AnalyticsWasteCadastrePage() {
  const [form, setForm] = useState({ ...initialForm });

  const {
    kind,
    waste_type_id,
    format_type,
    accept_subject_id,
    subject_id,
    object_name,
    subject_owner_id,
    subject_transfer_id,
    hazard_class,
    year,
    address,
    date_from,
    date_to,
    waste_group,
    waste_generator_district,
    waste_generator_city,
    // waste_conditions,
  } = form;

  const isActivityKind = kind.value === BY_ACTIVITIES;
  const isWasteCompare = kind.value === COMPARATIVE_REPORT;
  const isReportCount = kind.value === 'count_by_subject';
  const isReportCountYears = kind.value === 'count_by_years';
  const isSchemeMovementGenerator = kind.value === 'motion_scheme';
  const isSchemeMovementTransport = kind.value === 'motion_scheme_transportation';
  const isSchemeMovementPlacement = kind.value === 'motion_scheme_placing';

  const [error, setError] = useState({
    format_type: '',
  });

  const onChange = ({ target: { name } }, value) => {
    if (name === 'kind') {
      setError({});

      setForm({
        ...cloneDeep(initialForm),
        [name]: value,
      });
      return;
    }

    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const downloadReport = async () => {
    const requiredField = { kind };

    const ignoreInputs = [];
    // requiredField.year = form.year;

    const { hasErrors, validField } = formValidator({
      form: requiredField,
      ignoreInputs,
      generalRules: ['null', 'empty'],
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const options = {};

    if (form.year) options.year = form.year.getFullYear();
    if (form.date_to) options.date_to = form.date_to;
    if (form.date_from) options.date_from = form.date_from;

    if (isReportCount && form[ENTERPRISE_ACTIVITY_TYPE_IDS].length) {
      options[ENTERPRISE_ACTIVITY_TYPE_IDS] = form[ENTERPRISE_ACTIVITY_TYPE_IDS].map(({
        id,
      }) => id);
    }

    if (isActivityKind || isWasteCompare || isSchemeMovementTransport) {
      if (form.waste_group?.name) options.waste_group = form.waste_group?.name;
      if (form.waste_generator_district?.id) options.waste_generator_district = form.waste_generator_district.id;
      if (form.waste_generator_city?.name) options.waste_generator_city = form.waste_generator_city.name;
    }

    // if (isActivityKind) {
    //   if (form.waste_conditions?.name) options.waste_conditions = form.waste_conditions.name;
    // }

    if (isSchemeMovementTransport) {
      if (subject_transfer_id.id) options.transfer_subject_id = subject_transfer_id.id;
      if (accept_subject_id.id) options.accept_subject_id = accept_subject_id.id;
    }

    if (isReportCount || isSchemeMovementTransport || isSchemeMovementGenerator) {
      if (subject_id?.id) options.subject_id = subject_id.id;
    }

    if (isSchemeMovementPlacement) {
      if (subject_transfer_id.id) options.subject_transfer_id = subject_transfer_id.id;
      if (subject_owner_id.id) {
        options.subject_owner_id = subject_owner_id.id;
      }
    }

    if (waste_type_id.id) options.waste_type_id = waste_type_id.id;

    if (isSchemeMovementGenerator) {
      if (hazard_class.id) options.hazard_class = hazard_class.id;
    }

    if (subject_id.id) {
      options.subject_id = subject_id.id;
    }

    if (accept_subject_id.id) {
      options.accept_subject_id = accept_subject_id.id;
    }

    const response = await createReportRequest({
      format_type,
      report_type: 'analytical_reports',
      options,
      kind: form.kind.value,
    });

    const dateFromToName = form.date_from && ` c ${formatDate({
      date: form.date_from,
    })} по ${formatDate({
      date: form.date_to,
    })}`;

    const dateYearName = form.year && ` c ${formatDate({
      date: form.year,
    })} года`;

    const dateFileName = `${kind.label}${dateYearName || dateFromToName || ''}`;

    if (response?.file_url) downloadBlobFileOnClick(response.file_url, dateFileName);
  };

  const { makeRequest, isLoading: loadingDownloadReport } = useApi({
    request: downloadReport,
  });

  const clearDates = () => setForm((prevState) => ({
    ...prevState,
    year: null,
    date_to: null,
    date_from: null,
  }));

  return (
    <Box>
      <BackdropLoading position="fixed" isLoading={loadingDownloadReport} />
      <TextTitle h2 sx={{ mb: '10px' }}>Создание отчета</TextTitle>
      <Box display="flex" gap={2} flexWrap="wrap" mb={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextTitle sx={{ fontWeight: 'bold' }}>Тип отчета</TextTitle>
          <Selector
            sx={{ minWidth: '320px' }}
            label="Выберите тип отчета"
            name="kind"
            value={kind}
            onChange={onChange}
            options={reportOptions}
            error={error.kind?.id}
          />
        </Box>
        {!isReportCountYears && (
          <Box display="flex" flexDirection="column" gap={2} flexBasis="50%">
            {(isSchemeMovementGenerator || isActivityKind) && <TextTitle sx={{ fontWeight: 'bold' }}>Условия фильтрации</TextTitle>}
            {isSchemeMovementGenerator && (
              <Box sx={{ ...flexRowGap2, alignItems: 'center' }}>
                <CustomDatePicker
                  isYearPicker
                  yearFrom={2022}
                  yearTo={2040}
                  sx={{ mr: '10px' }}
                  label="Год"
                  name="year"
                  value={year}
                  error={error.year}
                  onChange={onChange}
                />
                {(year) && (
                  <CustomButton variant="text" onClick={clearDates}>
                    Сбросить год
                  </CustomButton>
                )}
              </Box>
            )}
            {!isReportCountYears && !isSchemeMovementGenerator && !isActivityKind && (
              <>
                <Box display="flex" flexDirection="column" gap={2}>
                  <TextTitle>Период заполнения сведений в Кадастр</TextTitle>
                  <Box sx={flexRowGap2}>
                    <CustomDatePicker
                      sx={{ mr: '10px' }}
                      label="Начало:"
                      name="date_from"
                      value={date_from}
                      error={error.date_from}
                      onChange={onChange}
                    />
                    <CustomDatePicker
                      name="date_to"
                      label="Окончание:"
                      value={date_to}
                      error={error.date_to}
                      onChange={onChange}
                    />
                  </Box>
                </Box>
                {(date_from || date_to) && (
                  <Box>
                    <CustomButton variant="text" onClick={clearDates}>
                      Сбросить дату и
                      время
                    </CustomButton>
                  </Box>
                )}
              </>
            )}
            {(
              isSchemeMovementTransport
              || isSchemeMovementGenerator
              || isSchemeMovementPlacement
            ) && (
            <AsyncAutocomplete
              sx={autocompleteSx}
              label="Вид отхода (ФККО)"
              name="waste_type_id"
              request={getWasteTypesRequest}
              value={waste_type_id}
              onChange={onChange}
            />
            )}
            {(isReportCount || isSchemeMovementTransport || isSchemeMovementGenerator || isActivityKind) && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Субъект отчета"
                name="subject_id"
                value={subject_id}
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                onChange={onChange}
              />
            )}
            {isSchemeMovementPlacement && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Субъект-владелец объекта размещения"
                name="subject_owner_id"
                value={subject_owner_id}
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                onChange={onChange}
              />
            )}
            {isReportCount && (
              <AsyncAutocomplete
                multiple
                sx={autocompleteSx}
                label="Вид деятельности субъекта"
                name={ENTERPRISE_ACTIVITY_TYPE_IDS}
                request={(params) => getReferencesRequest({
                  url: ENTERPRISE_ACTIVITY_TYPES_URL,
                  params,
                })}
                value={form[ENTERPRISE_ACTIVITY_TYPE_IDS]}
                onChange={onChange}
              />
            )}
            {(isActivityKind || isWasteCompare || isSchemeMovementTransport) && (
              <>
                <AsyncAutocomplete
                  sx={autocompleteSx}
                  label={isSchemeMovementTransport ? 'Наименование цели вывоза' : 'Вид деятельности'}
                  name="waste_group"
                  request={(params) => getReferencesRequest({
                    url: WASTE_MANAGEMENT_TYPES_URL,
                    params,
                  })}
                  value={waste_group}
                  onChange={onChange}
                />
                {!isActivityKind && (
                  <>
                    <CustomAutocomplete
                      sx={autocompleteSx}
                      label="Район образования отходов"
                      name="waste_generator_district"
                      value={waste_generator_district}
                      options={DISTRICTS_OPTIONS}
                      onChange={onChange}
                    />
                    <AsyncAutocomplete
                      sx={autocompleteSx}
                      label="Поселение образования"
                      name="waste_generator_city"
                      request={getCitiesRequest}
                      value={waste_generator_city}
                      onChange={onChange}
                    />
                  </>
                )}
              </>
            )}
            {isSchemeMovementGenerator && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Класс отходов"
                name={HAZARD_CLASS_ENUM}
                optionField={DEFAULT_SELECTOR_FIELDS}
                request={(params) => getApplicationEnumsRequest(
                  HAZARD_CLASS_ENUM,
                  params,
                )}
                value={hazard_class}
                onChange={onChange}
              />
            )}
            {(isSchemeMovementPlacement || isSchemeMovementTransport) && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label={isSchemeMovementTransport ? 'Субъект, передавший для транспортирования отходы' : 'Субъект, транспортирующий отходы на объект размещения'}
                name="subject_transfer_id"
                value={subject_transfer_id}
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                onChange={onChange}
              />
            )}
            {false && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Объект, принявший отходы"
                request={(params) => getObjectivesRequest({ params })}
                name="object_name"
                value={object_name}
                onChange={onChange}
              />
            )}
            {/* todo mb return in the future */}
            {false && (
              <AddressAutocomplete
                required
                sx={autocompleteSx}
                name="address"
                setNewAddress={(newAddress) => onChange({ target: { name: 'address' } }, newAddress)}
                value={address}
                onChange={onChange}
              />
            )}
            {isSchemeMovementTransport && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Субъект, принимающий отходы"
                name="accept_subject_id"
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                value={accept_subject_id}
                onChange={onChange}
              />
            )}
            {/* {isActivityKind && (
              <AsyncAutocomplete
                sx={autocompleteSx}
                label="Агрегатное состояние отходов."
                name="waste_conditions"
                optionField={{ label: 'name', value: 'id' }}
                request={(params) => getReferencesRequest({ url: WASTE_CONDITION_TYPES, params })}
                value={waste_conditions}
                onChange={onChange}
              />
            )} */}
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          <CustomRadioGroup
            required
            onChange={onChange}
            value={format_type}
            name="format_type"
            error={error.format_type}
            label="Формат отчета"
            subTitle="Выберите в каком формате будет сформирован отчет:"
            options={[
              { label: 'PDF', value: 'pdf' },
              { label: 'XLSX', value: 'xlsx' },
            ]}
          />
          <Submit value="Создать отчет" onClick={makeRequest} sx={{ height: '50px', width: '200px' }} />
        </Box>
      </Box>
    </Box>
  );
}
