import React from 'react';
import { Text } from './Text';

export function TextSignature({
  children, big, medium, component, sx,
}) {
  return (
    <Text component={component} sx={sx} className="color-signature" big={big} medium={medium}>
      {children}
    </Text>
  );
}
