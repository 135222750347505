import React, { useEffect, useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CustomTable from '../../components/Tables/CustomTable';
import NavTabs from '../../components/Tabs';
import ModalRole from '../../components/Modals/ModalRole';
import ModalUsers from '../../components/Modals/ModalUsers';
import { useApi, useTableSearch } from '../../hooks';
import { deleteUserRequest, getUsersRequest } from '../../api/userApi';
import { deleteRoleRequest, getRolesRequest } from '../../api/rolesApi';
import { getFullName } from '../../helpers/stringHelper';
import { useTableStore } from '../../store';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomCheckbox from '../../components/CustomCheckbox';
import { usePrivilegeRole } from '../../hooks/usePrivilegeRole';

const userRows = [
  { headerTitle: 'Адрес электронной почты', field: 'email' },
  { headerTitle: 'ФИО', field: 'full_name', getField: (row) => getFullName(row) },
  { headerTitle: 'Телефон', field: 'phone' },
  { headerTitle: 'Субъект', field: 'subject_name', getField: ({ subject_attributes }) => subject_attributes?.attributes?.name },
  { headerTitle: 'Роль', field: 'role_name', getField: ({ role_attributes }) => role_attributes?.attributes?.name },
];

const roleRows = [
  { headerTitle: 'Название роли', field: 'name' },
  { headerTitle: 'Код', field: 'code' },
  { headerTitle: 'Номер', field: 'id' },
];

export default function UsersRolesPage() {
  const [tab, setTab] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState({ role: {}, user: {} });
  const [isCreateUserModal, setCreatingUserModal] = useState(false);
  const [isCreateRoleModal, setCreatingRoleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [withDeleted, setWithDeleted] = useState(true);
  const { tableStore: { page, perPage, searchText }, setTableStore } = useTableStore();

  const hasPrivilege = usePrivilegeRole();

  const userPrivilege = hasPrivilege('users_read_all');
  const rolePrivilege = hasPrivilege('roles_read_all');

  useEffect(() => {
    const privilegeTabs = [];
    if (userPrivilege && !tabs.some((item) => item.name === 'user')) {
      privilegeTabs.push({
        name: 'user',
        label: 'Пользователи',
      });
    }
    if (rolePrivilege && !tabs.some((item) => item.name === 'role')) {
      privilegeTabs.push({
        name: 'role',
        label: 'Роли',
      });
    }
    setTabs(privilegeTabs);
  }, [userPrivilege, rolePrivilege]);

  useEffect(() => {
    if (tabs.length) {
      setTab(tabs[0].name);
    } else {
      setTab(false);
    }
  }, [tabs]);

  const isRole = tab === 'role';

  const hasDeleted = !isRole && withDeleted;

  const changeModal = (modalName, modalData) => {
    setModal((prevState) => ({ ...prevState, [modalName]: modalData }));
  };

  const changeCreatingModal = (isOpen) => {
    if (isRole) setCreatingRoleModal(isOpen);
    else setCreatingUserModal(isOpen);
  };

  const closeCreatingModal = () => changeCreatingModal(false);

  const searchUsers = async (params) => {
    if (isRole) return;

    const newData = await getUsersRequest(
      {
        page,
        total: perPage,
        search: searchText,
        with_deleted: hasDeleted ? true : undefined,
        ...params,
      },
      setTableStore,
    );

    if (newData) setTableData(newData);
  };

  const { makeRequest: onSearchUsers } = useApi({
    request: searchUsers,
    setIsLoading,
  });

  const searchRoles = async (params) => {
    const newData = await getRolesRequest(
      {
        page,
        total: perPage,
        search: searchText,
        ...params,
      },
      setTableStore,
    );

    if (newData) setTableData(newData);
  };

  const { makeRequest: onSearchRoles } = useApi({
    request: searchRoles,
    setIsLoading,
  });

  const closeModal = (modalName) => {
    changeModal(modalName, {});
  };

  const closeUserModal = () => closeModal('user');
  const closeRoleModal = () => closeModal('role');

  const { isLoading: isTableLoading } = useTableSearch({
    search: tabs.length && (isRole ? getRolesRequest : getUsersRequest),
    deps: [isRole, hasDeleted],
    params: { with_deleted: !isRole && hasDeleted ? true : undefined },
    setter: (newData) => setTableData(newData),
  });

  const resetTable = () => {
    setTableStore({
      page: 1,
    });
  };

  const deleteRow = async ({ id }) => {
    const deleteRequest = isRole ? deleteRoleRequest : deleteUserRequest;

    await deleteRequest(id);
    setTableStore({ page: 1 });
    if (isRole) return searchRoles({ page: 1 });
    return searchUsers({ page: 1 });
  };

  const changeWithDeleted = () => setWithDeleted(!withDeleted);

  const changeTab = (newTab) => {
    setTab(newTab);
    resetTable();
  };

  const canDeleteRole = hasPrivilege('role_destroy');
  const canDeleteUser = hasPrivilege('user_destroy');
  const canDelete = isRole ? canDeleteRole : canDeleteUser;

  const canCreateRole = hasPrivilege('role_create');
  const canCreateUser = hasPrivilege('user_create');
  const canCreate = isRole ? canCreateRole : canCreateUser;

  const canUpdateRole = hasPrivilege('role_update');
  const canUpdateUser = hasPrivilege('user_update');
  const canUpdate = isRole ? canUpdateRole : canUpdateUser;

  return (
    <>
      <Toolbar sx={{ mb: '8' }} />
      <Box display="flex" mb="16px" justifyContent="space-between">
        <Box display="flex">
          <NavTabs tabsComponents={tabs} tab={tab} setTab={changeTab} />
        </Box>
        <Box display="flex" alignItems="center">
          {!isRole && (
            <CustomCheckbox
              label="Включать архивные"
              onChange={changeWithDeleted}
              value={withDeleted}
            />
          )}
          {canCreate && (
            <CustomButton
              variant="contained"
              startIcon={<PersonAddAltIcon />}
              onClick={() => changeCreatingModal(true)}
            >
              Добавить
              {' '}
              {isRole ? 'роль' : 'пользователя'}
            </CustomButton>
          )}
        </Box>
      </Box>

      <CustomTable
        data={tableData}
        isLoading={isLoading || isTableLoading}
        columns={isRole ? roleRows : userRows}
        onClick={(row) => canUpdate && changeModal(tab, row)}
        deleteRow={canDelete && {
          deleteRequest: deleteRow,
          deleteMessage: isRole ? 'Роль удалена' : 'Пользователь удалён',
          getDeleteObjectName: (row) => (isRole ? `роль ${row.name}` : `пользователя ${getFullName(row)}`),
        }}
      />

      {(modal.user.id !== undefined || isCreateUserModal) && (
        <ModalUsers
          closeModal={isCreateUserModal ? closeCreatingModal : closeUserModal}
          updateUsers={() => {
            resetTable();
            onSearchUsers({ page: 1 });
          }}
          userData={modal.user}
          isCreateUserModal={isCreateUserModal}
        />
      )}

      {(modal.role.id !== undefined || isCreateRoleModal) && (
        <ModalRole
          updateRoles={() => {
            resetTable();
            onSearchRoles({ page: 1 });
          }}
          closeModal={isCreateRoleModal ? closeCreatingModal : closeRoleModal}
          isCreateRoleModal={isCreateRoleModal}
          roleData={modal.role}
          onClick={(user) => changeModal('user', user)}
        />
      )}
    </>
  );
}
