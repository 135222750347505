import { WASTE_TYPES_URL } from '../constants/urls';
import api from './api';

export const getWasteTypesRequest = (params, setTableStore) => api({
  url: WASTE_TYPES_URL,
  params: {
    ...params,
    total: 100,
  },
}, {
  isEntityAttributes: true,
  setTableStore,
  filter: (newValues) => newValues.map((item) => ({ ...item, label: `${item.code} ${item.name}`, value: item.id })),
});
