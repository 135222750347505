export const PRIVILEGES_TYPES = [
  { id: 'vehicle', label: 'Транспорт' },
  { id: 'user', label: 'Пользователь' },
  { id: 'application', label: 'Разрешения' },
  { id: 'objective', label: 'Объекты' },
  { id: 'subject', label: 'Субъекты' },
  { id: 'address', label: 'Адрес' },
  { id: 'role', label: 'Роли' },
  { id: 'waste_type', label: 'Вид отходов' },
  { id: 'analyst_control', label: 'Аналитика "Контроль перевозчиков' },
  { id: 'analyst_cadastre', label: 'Аналитика "Кадастр отходов' },
  { id: 'admin_cadastre', label: 'Администрирование кадастра' },
  { id: 'reference_information', label: 'Нормативно справочная информация' },
  { id: 'mail', label: 'Отправка почты' },
  { id: 'admin_ter_scheme', label: 'Администрирование Терсхемы' },
  { id: 'lk_kgen', label: 'ЛК КГЭН' },
];
