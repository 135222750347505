// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import ModalCard from './ModalCard';
import { DEFAULT_SELECTOR_FIELDS, DEFAULT_SELECTOR_OPTION } from '../Selectors/Selector';
import { useApi } from '../../hooks';
import { compareId, formValidator } from '../../helpers';
import {
  createSubjectRequest,
  deleteSubjectRequest,
  getSubjectEnumsRequest,
  updateSubjectRequest,
} from '../../api/subjectApi';
import { formatPhone } from '../../helpers/stringHelper';
import {
  DEFAULT_ENUM_OPTION,
  DIVISION_TYPE_ENUM,
  DIVISION_TYPE_ENUM_TEXT,
  ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES,
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  ENTERPRISE_TYPE_ENUM,
  ENTERPRISE_TYPE_TEXT_ENUM,
  SUBJECT_TYPE_ATTRIBUTES,
  SUBJECT_TYPE_ENUM,
  SUBJECT_TYPES,
} from '../../constants/enums';
import { useModalStore } from '../../store';
import { filterIndividualSubjectKind, getEntityAttributes } from '../../helpers/entityHelper';
import { getReferencesRequest } from '../../api/referenceApi';
import { ENTERPRISE_ACTIVITY_TYPES_URL, OKTMOS_URL } from '../../constants/urls';
import { USER_ENTITY_TYPE } from '../../constants';
import useGetReferenceItems from '../../hooks/useGetRefferenceItems';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { AlertRequiredFields } from '../Inputs/AlertRequiredFields';
import { useAdminRole } from '../../hooks/useAdminRole';

const checkGeneratingSubject = (enterprise_activity_types) => enterprise_activity_types.some((item) => item.code === 'generating');

const additional_partitions = [
  { id: 1, value: 'cadastre', label: 'Кадастр' },
  { id: 2, value: 'control', label: 'Контроль' },
  { id: 3, value: '-', label: '-' },
];

const initialSubjectInputs = {
  [ENTERPRISE_ACTIVITY_TYPE_IDS]: [],
  subject_type: { ...DEFAULT_ENUM_OPTION },
  [ENTERPRISE_TYPE_ENUM]: DEFAULT_SELECTOR_OPTION,
  [DIVISION_TYPE_ENUM]: DEFAULT_SELECTOR_OPTION,
  name: '',
  ogrn: '',
  inn: '',
  okved: '',
  registry_address: '',
  actual_address: '',
  boss_name: '',
  phone: '',
  email: '',
  password: '',
  password_confirmation: '',
  confirmed_at: '',
  regional_operator: false,
  budget_enterprise: false,
  filial: false,
  head_subject: false,
  okpo: '',
  okato: '',
  oktmo: { ...DEFAULT_ENUM_OPTION },
};

const filterRecipientActivities = (types) => (options) => options
  .filter(({ id }) => types?.some((type) => compareId(type, id)));

function ModalAddSubject({
  close,
  updateSubjects,
  subjectData = {},
  setNewSubject,
  division,
  divisionSubjectType,
  canEdit = true,
  canDelete = true,
  canCreate = true,
}) {
  const [subject, setSubject] = useState(initialSubjectInputs);
  const { isUserAdmin } = useAdminRole();
  const { modalStore, setModalStore } = useModalStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const { getReferenceTypeIdsByCode: getEnterpriseTypeIdsByCode } = useGetReferenceItems({ referenceUrl: ENTERPRISE_ACTIVITY_TYPES_ENUM });

  const { makeRequest: getDivision } = useApi({
    request: (params) => getSubjectEnumsRequest({ enum_field: DIVISION_TYPE_ENUM, params }),
    setter: (divisions) => {
      const currentDivision = divisions.find((item) => item.id === division);

      if (currentDivision) {
        setSubject((prevState) => ({
          ...prevState,
          division: currentDivision,
        }));
      }
    },
  });

  const enterpriseIds = getEnterpriseTypeIdsByCode(['pending', 'utilization', 'neutralization', 'placing']);

  const subjectFrame = useMemo(() => [
    {
      id: 1,
      subTitle: 'Субъект',
      title: 'Общие сведения о субъекте',
      inputFields: [
        {
          id: SUBJECT_TYPE_ENUM,
          required: true,
          label: 'Тип субъекта',
          type: 'autocomplete',
          request: (params) => getReferencesRequest({
            url: SUBJECT_TYPES,
            params,
            filter: divisionSubjectType === 'recipient' && filterIndividualSubjectKind,
          }),
        },
        {
          id: 'inn',
          label: 'ИНН',
          required: true,
          disabled: (row) => row.id !== undefined,
          type: 'innMask',
        },
        {
          id: 'division',
          label: 'Раздел',
          type: 'autocomplete',
          request: (params) => getSubjectEnumsRequest({ enum_field: DIVISION_TYPE_ENUM, params }),
          hide: () => Boolean(division),
          useDefaultFilter: true,
          fields: DEFAULT_SELECTOR_FIELDS,
        },
        {
          id: 'additional_partition',
          label: 'Дополнительный ЛК',
          type: 'select',
        },
        {
          id: 'ogrn',
          label: (inputData) => (compareId(inputData.subject_type?.id === 3) ? 'ОГРНИП' : 'ОГРН'),
          required: (inputData) => !(
            inputData.subject_type?.code === 'self_employed'
            && checkGeneratingSubject(inputData[ENTERPRISE_ACTIVITY_TYPE_IDS])
          ),
        },
        {
          id: 'name',
          label: (inputData) => (compareId(inputData.subject_type_attributes?.id === 4) ? 'ФИО' : 'Краткое наименование организации'),
          required: true,
          multiline: true,
        },
        {
          id: 'full_name',
          label: 'Полное наименование организации',
          required: true,
          multiline: true,
        },
        { id: 'registry_address', label: 'Адрес регистрации', required: true },
        { id: 'actual_address', label: 'Фактический адрес', required: true },
      ],
    },
    {
      id: 2,
      containers: [
        {
          id: 1,
          visible: (inputData) => inputData.subject_type.code
            === USER_ENTITY_TYPE.legal_entity.name,
          title: 'Вид предприятия',
          inputFields: [
            {
              error: (inputError) => inputError[ENTERPRISE_TYPE_ENUM],
              id: ENTERPRISE_TYPE_ENUM,
              label: 'Предприятие',
              type: 'autocomplete',
              request: (params) => getSubjectEnumsRequest({ enum_field: ENTERPRISE_TYPE_ENUM, params }),
              fields: DEFAULT_SELECTOR_FIELDS,
            },
          ],
        },
        {
          id: 2,
          title: 'Вид обращения с отходами',
          inputFields: [
            {
              id: ENTERPRISE_ACTIVITY_TYPE_IDS,
              required: true,
              label: 'Вид деятельности',
              type: 'autocomplete',
              error: (inputError) => inputError[ENTERPRISE_ACTIVITY_TYPE_IDS],
              multiple: true,
              request: (params) => getReferencesRequest({
                url: ENTERPRISE_ACTIVITY_TYPES_URL,
                params,
                filter: divisionSubjectType === 'recipient' && filterRecipientActivities(enterpriseIds),
              }),
              selectFirstOption: divisionSubjectType === 2 && 2,
              disabled: () => divisionSubjectType === 2,
            },
            { id: 'kpp', label: 'КПП' },
            { id: 'okved', label: 'ОКВЭД' },
            {
              id: 'regional_operator',
              label: 'Региональный оператор',
              type: 'checkbox',
              hide: () => divisionSubjectType?.length,
            },
            {
              id: 'budget_enterprise',
              label: 'Бюджетное предприятие',
              type: 'checkbox',
              hide: () => divisionSubjectType?.length,
            },
            {
              id: 'filial', label: 'Филиал', type: 'checkbox', hide: () => divisionSubjectType?.length,
            },
            {
              id: 'head_subject',
              label: 'Головной субъект',
              type: 'checkbox',
              hide: () => divisionSubjectType?.length,
            },
            { id: 'okpo', label: 'ОКПО' },
            { id: 'okato', label: 'ОКАТО' },
            {
              id: 'oktmo',
              label: 'ОКТМО',
              type: 'autocomplete',
              fields: { label: 'label' },
              additionalFilter: (options) => options.map((option) => ({
                ...option,
                label: `${option.code} ${option.name}`,
              })),
              request: (params) => getReferencesRequest({ url: OKTMOS_URL, params }),
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: 'Контактные данные',
      inputFields: [
        {
          id: 'phone', label: 'Номер телефона', type: 'tel',
        },
        {
          id: 'email', label: 'E-mail', type: 'email', required: true,
        },
        {
          id: 'password', label: 'Пароль', type: 'password', required: true, hide: () => Boolean(division),
        },
        {
          id: 'password_confirmation',
          label: 'Подтвердить пароль',
          type: 'password',
          required: true,
          hide: () => Boolean(division),
        },
        {
          id: 'confirmed_at',
          label: 'Почта подтверждена',
          type: 'checkbox',
          hide: () => !isUserAdmin,
        },
      ],
    },
  ], [division, divisionSubjectType, enterpriseIds, isUserAdmin]);

  useEffect(() => {
    if (
      subjectData.id
      || subjectData.name
      || subjectData.inn
      || subjectData.subject_type_attributes?.id
    ) {
      const fillAutoPassword = division ? {
        password: subjectData.inn,
        password_confirmation: subjectData.inn,
      } : {};

      const { oktmo_attributes } = subjectData;

      const additionalPartition = additional_partitions
        .find((item) => compareId(item.value, subjectData.additional_partition || '-'));

      setSubject((prevState) => ({
        ...prevState,
        ...subjectData,
        confirmed_at: !!subjectData.confirmed_at,
        [DIVISION_TYPE_ENUM]: {
          id: subjectData[DIVISION_TYPE_ENUM],
          value: subjectData[DIVISION_TYPE_ENUM],
          label: subjectData[DIVISION_TYPE_ENUM_TEXT],
        },
        [ENTERPRISE_ACTIVITY_TYPE_IDS]: subjectData?.[ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES]?.map(
          (item) => getEntityAttributes(item),
        ) || [],
        [SUBJECT_TYPE_ENUM]: {
          id: subjectData?.[SUBJECT_TYPE_ATTRIBUTES]?.id || '',
          name: subjectData?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
          code: subjectData?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.code || '',
        },
        additional_partition: additionalPartition,
        [ENTERPRISE_TYPE_ENUM]: {
          id: subjectData[ENTERPRISE_TYPE_ENUM] || '',
          value: subjectData[ENTERPRISE_TYPE_ENUM] || '',
          label: subjectData[ENTERPRISE_TYPE_TEXT_ENUM] || '',
        },
        oktmo: {
          id: oktmo_attributes?.id || '',
          ...oktmo_attributes?.attributes,
          label: oktmo_attributes?.attributes?.name ? `${oktmo_attributes.attributes.code} ${oktmo_attributes.attributes.name}` : '',
        },
        ...fillAutoPassword,
      }));
    }
  }, [subjectData, division]);

  useEffect(() => {
    if (division) getDivision(DIVISION_TYPE_ENUM, { search: division });
    /* eslint-disable-next-line */
  }, [division]);

  const onCreateUpdateSubject = async (confirmedAtValue) => {
    if (confirmedAtValue !== undefined && subject.id) {
      setIsLoading(true);

      let newConfirmedAt = '';

      if (confirmedAtValue) newConfirmedAt = new Date();

      const newSubject = await updateSubjectRequest(subjectData.id, { confirmed_at: newConfirmedAt });

      setNewSubject?.(newSubject);

      if (updateSubjects) await updateSubjects();

      setIsLoading(false);

      return;
    }

    const {
      inn,
      email,
      phone,
      name,
      okved,
      ogrn,
      actual_address,
      registry_address,
      subject_type,
      password,
      password_confirmation,
      head_subject,
      filial,
      okato,
      full_name,
      okpo,
      oktmo,
      regional_operator,
      budget_enterprise,
      kpp,
      confirmed_at,
      additional_partition,
    } = subject;

    const form = {
      inn,
      name,
      email,
      password,
      password_confirmation,
      actual_address,
      registry_address,
      filial,
      regional_operator,
      budget_enterprise,
      head_subject,
      full_name,
      additional_partition: compareId(additional_partition?.value, '-') ? '' : additional_partition?.value,
    };

    if (okato) form.okato = okato;
    if (okpo) form.okpo = okpo;
    if (oktmo?.id) form.oktmo = oktmo.name;
    if (okved) form.okved = okved;
    if (kpp) form.kpp = kpp;


    if (confirmed_at) form.confirmed_at = new Date();
    else form.confirmed_at = '';

    const skipOgrn = subject_type.code === 'self_employed' && checkGeneratingSubject(subject[ENTERPRISE_ACTIVITY_TYPE_IDS]);

    if (skipOgrn && ogrn) form.ogrn = ogrn;
    else form.ogrn = ogrn;

    const ignoreInputs = [
      'additional_partition',
      'okved',
      'phone',
      'kpp',
      'okato',
      'okpo',
      'oktmo',
      'regional_operator',
      'budget_enterprise',
      'filial',
      'head_subject',
      'confirmed_at',
    ];

    if (subject_type.code !== USER_ENTITY_TYPE.legal_entity.name) {
      ignoreInputs.push(ENTERPRISE_TYPE_ENUM);
    }

    if (skipOgrn) ignoreInputs.push('ogrn');

    if (subjectData.id && password.length === 0 && password_confirmation.length === 0) {
      ignoreInputs.push(...['password', 'password_confirmation']);
    }

    if (subjectData.id) ignoreInputs.push(('inn'));

    const { hasErrors, validField } = formValidator({
      form: {
        ...form,
        subject_type: { id: subject_type.id, code: subject_type.code },
        [ENTERPRISE_ACTIVITY_TYPE_IDS]: subject[ENTERPRISE_ACTIVITY_TYPE_IDS].map(({ id }) => id),
      },
      ignoreInputs,
      customRules: [
        { name: 'email' },
        { name: 'array' },
        { name: 'password' },
        { name: 'inn' },
        {
          name: 'password_confirmation',
          text: 'Пароли не совпадают',
          valid: (item, checkedForm) => checkedForm.password === item,
        },
      ],
      inputRule: {
        email: ['email'],
        [ENTERPRISE_ACTIVITY_TYPE_IDS]: ['array'],
        inn: ['inn'],
        password: ['password'],
        password_confirmation: ['password_confirmation'],
      },
    });

    setError(validField);

    if (hasErrors) {
      throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };
    }

    if (!form.password) {
      delete form.password;
      delete form.password_confirmation;
    }

    if (phone) form.phone = formatPhone(phone);
    form.division = subject.division.id;
    form.subject_type_id = subject[SUBJECT_TYPE_ENUM].id;
    form[ENTERPRISE_ACTIVITY_TYPE_IDS] = subject[ENTERPRISE_ACTIVITY_TYPE_IDS].map(({ id }) => id);

    let newSubject;

    if (subjectData.id) {
      newSubject = await updateSubjectRequest(subjectData.id, form);
    } else {
      newSubject = await createSubjectRequest(form);
    }

    setNewSubject?.(newSubject);

    close();

    if (updateSubjects) await updateSubjects();
  };

  const { defaultRequest: createUpdateSubject } = useApi({
    request: onCreateUpdateSubject,
    shouldRequest: false,
    setIsLoading,
    successMessage: 'Субъект сохранён',
  });

  const handleChange = (field) => (event, value) => {
    const fillAutoPasswordError = field === 'inn' && division === 'internal' ? {
      password: '',
      password_confirmation: '',
    } : {};

    if (field === 'confirmed_at' && subjectData.id) {
      onCreateUpdateSubject(value);
    }

    setError((prevState) => ({
      ...prevState,
      [field]: '',
      ...fillAutoPasswordError,
    }));
    const fillAutoPassword = field === 'inn' && division === 'internal' ? {
      password: value,
      password_confirmation: value,
    } : {};

    const resetInn = field === 'subject_type' ? { inn: '' } : {};

    setSubject((prevState) => ({
      ...prevState,
      [field]: value,
      ...fillAutoPassword,
      ...resetInn,
    }));
  };

  const deleteSubject = () => {
    setModalStore({
      ...modalStore,
      deleteModal: {
        isOpen: true,
        callback: async () => {
          await updateSubjects();
          close();
        },
        objectName: 'субъект',
        deleteRequest: deleteSubjectRequest,
        id: subject.id,
        message: 'Субъект удалён',
      },
    });
  };

  const canCreateOrEdit = subjectData.id ? canEdit : canCreate;

  const selectorOption = {
    additional_partition: additional_partitions.filter((item) => !compareId(item.value, subjectData.partition)),
  };

  return (
    <ModalCard
      open
      inputs={subjectFrame}
      inputData={subject}
      close={close}
      isLoading={isLoading}
      selectorOption={selectorOption}
      canEdit={canEdit}
      additionalTopContainer={<AlertRequiredFields />}
      error={error}
      handleChange={handleChange}
      title={subjectFrame[0].subTitle}
      firstButtonProp={canDelete && subjectData.id && {
        label: 'Удалить субъект',
        onClick: deleteSubject,
      }}
      secondButtonProp={canCreateOrEdit && {
        label: subjectData.id ? 'Обновить субъект' : 'Добавить субъект',
        onClick: () => createUpdateSubject(),
      }}
    />
  );
}

export default ModalAddSubject;
