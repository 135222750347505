import api from './api';
import { COMMITTEES_URL, SIGN_IN_COMITTEE_URL } from '../constants/urls';

export const loginCommitteeRequest = (user) => api({ method: 'post', url: SIGN_IN_COMITTEE_URL, data: { user } }, { isEntityAttributes: true });
export const getCommitteesRequest = (params, setTableStore) => api({
  url: COMMITTEES_URL,
  params,
}, { isEntityAttributes: true, setTableStore });
export const getCommitteeRequest = (id) => api({ url: `${COMMITTEES_URL}/${id}` });
export const updateCommitteeRequest = (id, data) => api({ method: 'put', url: `${COMMITTEES_URL}/${id}`, data });
export const deleteCommitteeRequest = (id) => api({ method: 'delete', url: `${COMMITTEES_URL}/${id}` });
