import { useState } from 'react';
import { getRolePrivilegesRequest } from '../api/rolesApi';
import { useUserStore } from '../store';
import { useApi } from './useApi';

export function useGetPrivileges() {
  const { userStore } = useUserStore();
  const [privileges, setPrivileges] = useState();

  const { defaultRequest: getPrivileges } = useApi({
    setter: (newPrivileges) => setPrivileges(newPrivileges.map((item) => item?.privilege_attributes?.attributes?.code)),
    shouldRequest: userStore?.role_id,
    request: (localUserStore) => {
      const mainUserStore = localUserStore || userStore;
      return getRolePrivilegesRequest(mainUserStore?.role_id, { total: 10000 });
    },
  });

  return { privileges, getPrivileges };
}
