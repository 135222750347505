// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';
import { getInitialForm4Standard } from '../../constants/lkCadastr/cadastrForm4';
import { flexGap2, flexRowGap2, fullWidth } from '../../constants/lkCadastr/cadastrStorage';
import { TextTitle } from '../Text';
import CustomInput from '../Inputs';
import CustomDatePicker from '../CustomDatePicker';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { formatAddressString, getOktmoLabel } from '../../helpers/stringHelper';
import NavTabs from '../Tabs';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import { getWasteTypesRequest } from '../../api/wasteTypeApi';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import LimitYears from './CadastreForm4LimitYears';
import { getSubjectRequest, getSubjectsRequest } from '../../api/subjectApi';
import CustomButton from '../Buttons/CustomButton';
import { HAZARD_CLASS_ENUM, WASTE_MANAGEMENT_TYPE_IDS } from '../../constants/enums';
import { useApi } from '../../hooks';
import { getReferencesRequest } from '../../api/referenceApi';
import { OBJECTIVES_URL, OKTMOS_URL, WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import ModalAddOktmo from '../Modals/ModalAddOktmo';
import { putAddressRequest } from '../../api/addressApi';
import Selector from '../Selectors/Selector';
import { compareId } from '../../helpers';
import { getApplicationEnumsRequest } from '../../api/applicationsApi';
import { SubjectSearchAlert } from '../Subject/SubjectSearchAlert';
import { setTabsAndTab } from '../../hooks/useCadastreTabs';

const getWasteTabs = (standards) => standards.map((standard, index) => ({
  label: `Форма отхода ${index + 1}`,
  name: standard.randId,
}));

const getWasteManagementTypes = () => getReferencesRequest({ url: WASTE_MANAGEMENT_TYPES_URL });

export default function CadastreForm4Document({
  reportApprovalStandard,
  deleteForm4Entity,
  changeItemFormByField,
  onChange,
  isDemo,
  reportApprovalStandardIndex,
  reportApprovalStandards,
  openDeleteModal,
  form1ObjectsPlacingOptions,
  setCreateSubjectField,
  setOpenedObject,
  subjectAttributes,
  setCreateObjectInfo,
  placingTypesIds,
}) {
  const object = reportApprovalStandard.objective_id;
  const [isModalOktmo, setIsModalOktmo] = useState(false);

  const changeReportApprovalStandard = (field, value, approvalWasteIndex, limitWasteIndex, limitWasteKind) => onChange({
    field: `documents.${reportApprovalStandardIndex}.${field}`,
    value,
    approvalWasteIndex,
    limitWasteIndex,
    limitWasteKind,
  });

  const setNewAddress = (newAddress) => {
    changeReportApprovalStandard('objective_id', {
      ...object,
      address_attributes: {
        ...newAddress,
        attributes: {
          ...newAddress,
        },
      },
    });
  };

  const setNewOktmo = async ({ oktmo, address }) => {
    const newAddress = await putAddressRequest({ oktmo_id: oktmo.id }, address.id);

    changeReportApprovalStandard('objective_id', {
      ...object,
      address_attributes: {
        ...newAddress,
        attributes: {
          ...newAddress,
        },
      },
    });
  };

  const modalOktmo = (
    <ModalAddOktmo
      setNewAddress={setNewAddress}
      close={() => setIsModalOktmo(false)}
      addressId={object?.address_attributes?.id}
      isOpen
    />
  );

  const [placingType, setPlacingType] = useState('');
  const [wasteTabs, setWasteTabs] = useState([]);
  const [hazardClassTab, setHazardClassTab] = useState('all');
  const [wasteTab, setWasteTab] = useState('');

  const approvalWastes = reportApprovalStandard.standards;

  useApi({
    setter: (data) => {
      if (data?.length) {
        setPlacingType(data.filter((item) => item.code === 'placing')?.[0]?.id || '');
      }
    },
    request: getWasteManagementTypes,
    shouldRequest: true,
  });

  useEffect(() => {
    const filteredByHazardClass = hazardClassTab !== 'all'
      ? approvalWastes.filter((item) => item.code?.hazard_class === hazardClassTab)
      : approvalWastes;

    const filteredWasteTabs = getWasteTabs(filteredByHazardClass);

    if (
      filteredWasteTabs.length === wasteTabs.length
      && filteredWasteTabs.every((item, index) => compareId(wasteTabs[index].name, item.name))
    ) return;

    setTabsAndTab({
      tab: wasteTab, setTab: setWasteTab, setTabs: setWasteTabs, filteredTabs: filteredWasteTabs,
    });
  }, [approvalWastes, hazardClassTab, wasteTabs]);

  const addNewStandard = () => changeItemFormByField({
    field: `form4.documents.${reportApprovalStandardIndex}.standards`,
    value: [...approvalWastes, getInitialForm4Standard()],
  });

  const getHazardClassCount = (hazardClass) => approvalWastes
    .reduce((prevStandard, { code }) => {
      if (code?.hazard_class === hazardClass) return prevStandard + 1;
      return prevStandard;
    }, 0);

  const hazardClassTabs = [
    { label: 'Все классы опасности', name: 'all' },
    {
      label: `Первый (${getHazardClassCount('one')})`,
      name: 'one',
      hide: () => compareId(getHazardClassCount('one'), 0),
    },
    {
      label: `Второй (${getHazardClassCount('two')})`,
      name: 'two',
      hide: () => compareId(getHazardClassCount('two'), 0),
    },
    {
      label: `Третий (${getHazardClassCount('three')})`,
      name: 'three',
      hide: () => compareId(getHazardClassCount('three'), 0),
    },
    {
      label: `Четвёртый (${getHazardClassCount('four')})`,
      name: 'four',
      hide: () => compareId(getHazardClassCount('four'), 0),
    },
    {
      label: `Пятый (${getHazardClassCount('five')})`,
      name: 'five',
      hide: () => compareId(getHazardClassCount('five'), 0),
    },
  ];

  const onDeleteReportApprovalStandard = async () => {
    if (reportApprovalStandard?.id) {
      const deletedId = await deleteForm4Entity({ approvalStandardId: reportApprovalStandard.id });

      if (!deletedId) return;
    }

    const cloneArray = reportApprovalStandards.slice();
    cloneArray.splice(reportApprovalStandardIndex, 1);

    changeItemFormByField({
      field: 'form4.documents',
      value: cloneArray,
    });
  };

  return (
    <Box sx={flexGap2}>
      {isModalOktmo && modalOktmo}
      <Box sx={flexGap2}>
        <Box display="flex" alignItems="center" gap={3}>
          <TextTitle h3>
            Реквизиты документа
            №
            {reportApprovalStandardIndex + 1}
          </TextTitle>
          {!isDemo && reportApprovalStandards.length > 1 && (
            <DeleteButton
              onClick={() => openDeleteModal(onDeleteReportApprovalStandard, reportApprovalStandardIndex, 'Документ')}
            >
              Удалить документ
            </DeleteButton>
          )}
        </Box>
        <Box sx={flexRowGap2}>
          <CustomInput
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            label="№ документа"
            value={reportApprovalStandard.document_number}
            onChange={(e, value) => changeReportApprovalStandard('document_number', value)}
          />
          <CustomDatePicker
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            label="Дата выдачи документа"
            value={reportApprovalStandard.document_date}
            onChange={(e, value) => changeReportApprovalStandard('document_date', value)}
          />
          <CustomDatePicker
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            label="Срок действия документа"
            value={reportApprovalStandard.document_expiration}
            onChange={(e, value) => changeReportApprovalStandard('document_expiration', value)}
          />
        </Box>
      </Box>
      <Box sx={flexGap2}>
        <TextTitle h3>Объект</TextTitle>
        <Box sx={flexRowGap2}>
          <AsyncAutocomplete
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            sx={fullWidth}
            containerSx={fullWidth}
            label="Наименование объекта"
            request={(params) => getSubjectRequest({
              id: subjectAttributes.id,
              url: OBJECTIVES_URL,
              params,
            })}
            value={object}
            onChange={(e, value) => changeReportApprovalStandard('objective_id', value)}
          />
          <CustomInput
            disabled
            sx={{ width: '100%' }}
            label="Адрес"
            value={formatAddressString(object?.address_attributes?.attributes)}
          />
          <AsyncAutocomplete
            disabled={!object?.id || object?.address_attributes
              ?.attributes?.oktmo_attributes?.attributes?.name}
            label="Код ОКТМО"
            debounceTime={0}
            optionField={DEFAULT_AUTOCOMPLETE_FIELD}
            stringValue={getOktmoLabel(object?.address_attributes
              ?.attributes?.oktmo_attributes?.attributes)}
            onChange={(e, value) => setNewOktmo({ oktmo: value, address: object?.address_attributes })}
            noOptionComponent={(
              <AddButton onClick={() => setIsModalOktmo(true)} variant="outlined">
                Добавить ОКТМО
              </AddButton>
            )}
            additionalFilter={(options) => options.map((option) => ({
              ...option,
              label: `${option.code} ${option.name}`,
            }))}
            request={(params) => getReferencesRequest({ url: OKTMOS_URL, params })}
            sx={fullWidth}
            containerSx={fullWidth}
          />
        </Box>
      </Box>
      <TextTitle h3>Отходы</TextTitle>
      <NavTabs
        tabsComponents={hazardClassTabs}
        setTab={setHazardClassTab}
        tab={hazardClassTab}
      />
      <NavTabs tabsComponents={wasteTabs} setTab={setWasteTab} tab={wasteTab} />
      {!isDemo && <AddButton variant="outlined" onClick={addNewStandard}>Добавить норматив отхода</AddButton>}
      {approvalWastes.map((approvalWaste, approvalWasteIndex) => {
        if (hazardClassTab !== 'all' && !compareId(approvalWaste.code.hazard_class, hazardClassTab)) return null;
        if (wasteTab !== 'all' && !compareId(approvalWaste.randId, wasteTab)) return null;

        const changeYearLimit = (field, value, type) => changeItemFormByField({
          field: `form4.documents.${reportApprovalStandardIndex}.standards.${approvalWasteIndex}.${field}`,
          value,
          type,
        });

        const changeApprovalWaste = (field, value, limitWasteIndex, limitWasteKind) => changeReportApprovalStandard(
          `standards.${approvalWasteIndex}.${field}`,
          value,
          approvalWasteIndex,
          limitWasteIndex,
          limitWasteKind,
        );

        const onDeleteDocumentapprovalWaste = async () => {
          if (approvalWaste?.id) {
            const deletedId = await deleteForm4Entity({ approvalWasteId: approvalWaste.id });

            if (!deletedId) return;
          }

          const cloneArray = approvalWastes.slice();
          cloneArray.splice(approvalWasteIndex, 1);

          changeItemFormByField({
            field: `form4.documents.${reportApprovalStandardIndex}.standards`,
            value: cloneArray,
          });
        };

        const zeroTransferSubjectId = compareId(approvalWaste.transfer_subject_id?.name, 0);
        const zeroPlaceObjectiveId = compareId(approvalWaste.place_objective_id?.label, 0);

        return (
          <Box sx={flexGap2} key={approvalWaste.code.name}>
            <Box sx={flexRowGap2}>
              <TextTitle h2>
                Отход №
                {approvalWasteIndex + 1}
              </TextTitle>
              {approvalWastes.length > 1 && (
                <DeleteButton
                  onClick={() => openDeleteModal(onDeleteDocumentapprovalWaste, approvalWasteIndex, 'Отход')}
                >
                  Удалить отход
                </DeleteButton>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box sx={flexGap2}>
                <TextTitle h3>
                  Лимиты на размещение отходов
                </TextTitle>
                <Box sx={flexRowGap2}>
                  <Box sx={flexGap2}>
                    <TextTitle>
                      Отходы передаваемые на размещение
                      другим индивидуальным предпринимателям или юридическим лицам
                    </TextTitle>
                    <Box sx={flexGap2}>
                      <AsyncAutocomplete
                        validImmediately
                        disabled={isDemo}
                        debounceTime={0}
                        sx={{ width: '100%' }}
                        request={getWasteTypesRequest}
                        value={approvalWaste.code}
                        optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                        label="Код отхода по ФККО"
                        onChange={(e, value) => changeApprovalWaste('code', value)}
                      />
                      {!approvalWaste.code?.hazard_class
                        && <Alert severity="error">Необходимо указать класс опасности в поле ниже</Alert>}
                      <Selector
                        validImmediately
                        disabled={isDemo || !approvalWaste.code?.id || (approvalWaste.code?.hazard_class && !compareId(approvalWaste?.code?.code?.at(-1), 0))}
                        sx={fullWidth}
                        request={(params) => getApplicationEnumsRequest(HAZARD_CLASS_ENUM, params)}
                        value={{
                          label: approvalWaste.code?.hazard_class_text,
                          value: approvalWaste.code?.hazard_class,
                          id: approvalWaste.code?.hazard_class,
                        }}
                        optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                        label="Класс опасности"
                        onChange={(e, value) => changeApprovalWaste('code', {
                          ...approvalWaste.code,
                          hazard_class: value.id,
                          hazard_class_text: value.label,
                        })}
                      />
                      <CustomInput
                        validImmediately
                        debounceTime={0}
                        disabled={isDemo}
                        sx={{ width: '100%' }}
                        type="number"
                        label="Годовой норматив образования отходов, тонн"
                        value={approvalWaste.standard_tons}
                        onChange={(e, value) => changeApprovalWaste('standard_tons', value)}
                      />
                      <SubjectSearchAlert />
                      <AsyncAutocomplete
                        debounceTime={0}
                        validImmediately={!(isDemo || zeroTransferSubjectId)}
                        disabled={isDemo}
                        shouldRequest={placingTypesIds.length}
                        request={(params) => getSubjectsRequest({
                          params: {
                            ...params,
                            enterprise_activity_type_ids: placingTypesIds.join(','),
                          },
                        })}
                        label="Наименование субъекта (или ФИО ИП)"
                        sx={fullWidth}
                        noOptionComponent={(
                          <AddButton
                            variant="outlined"
                            onClick={() => setCreateSubjectField(
                              `documents.${reportApprovalStandardIndex}.standards.${approvalWasteIndex}.transfer_subject_id`,
                            )}
                          >
                            Создать субъект
                          </AddButton>
                        )}
                        value={approvalWaste.transfer_subject_id}
                        onChange={(e, value) => changeApprovalWaste('transfer_subject_id', value)}
                      />
                      <AsyncAutocomplete
                        debounceTime={0}
                        validImmediately={!(isDemo || zeroTransferSubjectId)}
                        disabled={isDemo || !approvalWaste.transfer_subject_id?.id || zeroTransferSubjectId}
                        sx={fullWidth}
                        label="Наименование объекта размещения отходов"
                        shouldRequest={placingType && approvalWaste.transfer_subject_id?.id}
                        request={(params) => getSubjectRequest({
                          id: approvalWaste.transfer_subject_id?.id,
                          url: OBJECTIVES_URL,
                          params: {
                            ...params,
                            [WASTE_MANAGEMENT_TYPE_IDS]: `${placingType}`,
                          },
                        })}
                        noOptionComponent={(
                          <AddButton
                            variant="outlined"
                            onClick={() => setCreateObjectInfo({
                              subject: approvalWaste.transfer_subject_id,
                              newObjectName: approvalWaste.transfer_objective_id?.name || '',
                              field: `documents.${reportApprovalStandardIndex}.standards.${approvalWasteIndex}.transfer_objective_id`,
                            })}
                          >
                            Создать объект
                          </AddButton>
                        )}
                        value={approvalWaste.transfer_objective_id}
                        onChange={(e, value) => changeApprovalWaste('transfer_objective_id', value)}
                      />
                      <Box sx={flexRowGap2}>
                        {approvalWaste.transfer_objective_id?.id && !approvalWaste.transfer_objective_id?.groro && (
                          <CustomButton
                            onClick={() => setOpenedObject({
                              object: approvalWaste.transfer_objective_id,
                              field: `documents.${reportApprovalStandardIndex}.standards.${approvalWasteIndex}.transfer_objective_id`,
                            })}
                          >
                            Добавить ГРОРО
                          </CustomButton>
                        )}
                        <CustomInput
                          disabled
                          sx={fullWidth}
                          label="Номер объекта размещения отходов в ГРОРО"
                          value={approvalWaste.transfer_objective_id?.groro}
                        />
                      </Box>
                    </Box>
                    <LimitYears
                      isDemo={isDemo}
                      approvalWaste={approvalWaste}
                      deleteForm4Entity={deleteForm4Entity}
                      openDeleteModal={openDeleteModal}
                      changeYearLimit={changeYearLimit}
                      changeItem={changeApprovalWaste}
                      yearName="limit_waste_transferred_years"
                    />
                  </Box>
                  <Box sx={flexGap2}>
                    <TextTitle>
                      Отходы размещаемые на эксплуатируемых
                      (собственных) объектах размещения отходов
                    </TextTitle>
                    <Box sx={flexGap2}>
                      <CustomAutocomplete
                        debounceTime={0}
                        validImmediately={!(isDemo || zeroPlaceObjectiveId)}
                        useDefaultFilter
                        disabled={isDemo}
                        sx={fullWidth}
                        options={form1ObjectsPlacingOptions}
                        label="Наименование объекта размещения отходов"
                        value={approvalWaste.place_objective_id}
                        onChange={(e, value) => changeApprovalWaste('place_objective_id', value)}
                      />
                      <Box sx={flexRowGap2}>
                        {approvalWaste.place_objective_id?.id && !approvalWaste.place_objective_id?.groro && (
                          <CustomButton
                            onClick={() => setOpenedObject({
                              object: approvalWaste.place_objective_id,
                              field: `documents.${reportApprovalStandardIndex}.standards.${approvalWasteIndex}.place_objective_id`,
                            })}
                          >
                            Добавить ГРОРО
                          </CustomButton>
                        )}
                        <CustomInput
                          disabled
                          sx={fullWidth}
                          label="Номер объекта размещения отходов в ГРОРО"
                          value={approvalWaste.place_objective_id?.groro}
                        />
                      </Box>
                    </Box>
                    <LimitYears
                      isDemo={isDemo}
                      approvalWaste={approvalWaste}
                      deleteForm4Entity={deleteForm4Entity}
                      openDeleteModal={openDeleteModal}
                      changeItem={changeApprovalWaste}
                      changeYearLimit={changeYearLimit}
                      yearName="limit_waste_placed_years"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
