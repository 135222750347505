import { useHeaderStore } from '../store';

export function useChangeSideDrawer() {
  const { headerStore, setHeaderStore } = useHeaderStore();

  const toggleDrawer = (value) => {
    setHeaderStore({ isOpenDrawer: typeof value === 'boolean' ? value : !headerStore.isOpenDrawer });
  };

  const closeDrawer = () => toggleDrawer(false);
  const openDrawer = () => toggleDrawer(true);

  return {
    toggleDrawer, closeDrawer, openDrawer, isOpenDrawer: headerStore.isOpenDrawer,
  };
}
