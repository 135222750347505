import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box, List, ListItem, ListItemButton, ListItemIcon,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SquareIcon from '@mui/icons-material/Square';
import { usePrivilegeRole } from '../hooks/usePrivilegeRole';

export default function CustomNestedList({
  title, subLists, Icon = SquareIcon, isActivePathname,
}) {
  const [activeMenuButton, setActiveMenuButton] = useState('');
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();

  const hasPrivilege = usePrivilegeRole();

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const checkActivePathname = () => subLists.some((item) => item.href === activeMenuButton);

  useEffect(() => {
    setActiveMenuButton(pathname);
  }, [pathname]);

  const onClickMenuButton = (href) => navigate(href);

  const isListActive = isActivePathname ? checkActivePathname() : open;

  return (
    <List>
      <ListItemButton sx={{ background: isListActive && '#cfecff' }} onClick={handleClick}>
        {Icon && (
          <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
            <Icon fontSize="large" />
          </ListItemIcon>
        )}
        <ListItemText primary={title} />
        <Box>{open ? <ExpandLess /> : <ExpandMore />}</Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subLists.map(({ subTitle, href, show }) => {
          if (show && !show(hasPrivilege)) return null;

          return (
            <ListItem sx={{ pb: 0, pr: 0 }} key={subTitle}>
              <ListItemButton
                sx={{
                  background: activeMenuButton === href && '#cfecff',
                  pt: 0,
                  pb: 0,
                  pr: 0,
                }}
                onClick={() => onClickMenuButton(href)}
              >
                <ListItemText primary={subTitle} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </Collapse>
    </List>
  );
}
