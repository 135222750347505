// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { putAddressRequest } from '../../api/addressApi';
import { getAddressesRequest } from '../../api/geolocation';
import { createObjectiveRequest, deleteObjectiveRequest, updateObjectiveRequest } from '../../api/objectivesApi';
import { getReferencesRequest } from '../../api/referenceApi';
import { getSubjectsRequest } from '../../api/subjectApi';
import { DEFAULT_ENUM_OPTION, SUBJECT_TYPE_ATTRIBUTES, WASTE_MANAGEMENT_ENUM } from '../../constants/enums';
import { OKTMOS_URL, WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import { compareId, formValidator } from '../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { formatAddressString, getOktmoLabel } from '../../helpers/stringHelper';
import { useApi } from '../../hooks';
import { useModalStore } from '../../store';
import AddButton from '../Buttons/AddButton';
import { AlertRequiredFields } from '../Inputs/AlertRequiredFields';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import { DEFAULT_SELECTOR_OPTION } from '../Selectors/Selector';
import { TextMain } from '../Text';
import ModalAddAddress from './ModalAddAddress';
import ModalAddOktmo from './ModalAddOktmo';
import ModalCard from './ModalCard';

const initialObjectInputs = {
  subject: { id: '', name: '' },
  name: '',
  [WASTE_MANAGEMENT_ENUM]: { ...DEFAULT_ENUM_OPTION },
  address: DEFAULT_SELECTOR_OPTION,
  inn: { inn: '' },
  subject_type: '',
  oktmo: DEFAULT_SELECTOR_OPTION,
};

function ModalAddObject({
  close,
  updateObjects,
  modalAddressTitle,
  setNewObject,
  objectData = {},
  wasteManagementTypes,
  wasteExcludeAccumulationCodes,
  subjectData,
  showMap,
  division,
  status,
  objectTypeTitle = 'обращения с отходами',
  helpComponent,
}) {
  const { modalStore, setModalStore } = useModalStore();
  const [objectInput, setObjectInput] = useState(initialObjectInputs);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isOpenModal, setIsOpenModal] = useState({
    address: false,
    oktmo: false,
  });

  const openModal = (field) => setIsOpenModal((prev) => ({ ...prev, [field]: true }));
  const closeModal = (field) => setIsOpenModal((prev) => ({ ...prev, [field]: false }));

  const objectFrame = useMemo(() => [
    {
      id: 1,
      title: 'Адрес объекта',
      inputFields: [
        {
          id: 'address',
          label: 'Адрес',
          required: true,
          type: 'autocomplete',
          multiline: true,
          useDefaultFilter: true,
          debounceTime: 0,
          noOptionComponent: (
            <AddButton onClick={() => openModal('address')} variant="outlined">
              Добавить
              Адрес
            </AddButton>
          ),
          request: getAddressesRequest,
          fields: DEFAULT_AUTOCOMPLETE_FIELD,
        },
        {
          id: 'oktmo',
          label: 'ОКТМО',
          required: true,
          type: 'autocomplete',
          disabled: (data) => data.address.oktmo_attributes?.id || !data.address.id,
          fields: { label: 'label' },
          additionalFilter: (options) => options.map((option) => ({
            ...option,
            label: `${option.code} ${option.name}`,
          })),
          noOptionComponent: (
            <AddButton onClick={() => openModal('oktmo')} variant="outlined">
              Добавить
              ОКТМО
            </AddButton>
          ),
          request: (params) => getReferencesRequest({ url: OKTMOS_URL, params }),
        },
      ],
    },
    {
      id: 2,
      containers: [
        {
          id: 2,
          title: 'Данные объекта',
          inputFields: [
            { id: 'name', label: 'Наименование объекта', required: true },
            {
              component: helpComponent || (
                <Box mb={2}>
                  <TextMain>
                    Примеры наименования объекта
                  </TextMain>
                  <TextMain>
                    1) Строительство жилого дома по адресу..
                  </TextMain>
                  <TextMain>
                    2) Контейнерная площадка..,
                  </TextMain>
                  <TextMain>
                    3) жилой дом, магазин и т д
                  </TextMain>
                </Box>
              ),
            },
            {
              id: WASTE_MANAGEMENT_ENUM,
              label: 'Вид обращения отходов',
              required: true,
              type: 'autocomplete',
              selectFirstOption: wasteManagementTypes?.length === 1 || wasteExcludeAccumulationCodes?.length === 1,
              disabled: wasteManagementTypes?.length === 1 || wasteExcludeAccumulationCodes?.length === 1,
              request: (params) => getReferencesRequest({
                url: WASTE_MANAGEMENT_TYPES_URL,
                params,
                filter: (options) => {
                  if (wasteManagementTypes?.length) {
                    return options
                      .filter(({ id }) => wasteManagementTypes
                        .some((type) => compareId(type, id)));
                  }
                  if (wasteExcludeAccumulationCodes?.length) {
                    return options
                      .filter(({ code }) => wasteExcludeAccumulationCodes
                        .some((outerCode) => compareId(outerCode, code)));
                  }
                  return options;
                },
              }),
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: 'Данные субъекта',
      inputFields: [
        {
          id: 'subject',
          label: 'Наименование субъекта',
          required: true,
          type: 'autocomplete',
          request: (params) => getSubjectsRequest({ params: { ...params, division } }),
          disabled: subjectData?.subject?.id,
        },
        {
          id: 'inn',
          label: 'ИНН',
          type: 'autocomplete',
          required: true,
          request: (params) => getSubjectsRequest({ params: { ...params, division } }),
          disabled: subjectData?.inn?.inn,
          fields: { label: 'inn', id: 'inn' },
        },
        {
          id: 'subject_type',
          label: 'Тип субъекта',
          disabled: true,
        },
      ],
    },
  ], [wasteManagementTypes, division, subjectData, wasteExcludeAccumulationCodes]);

  useEffect(() => {
    if (objectData?.id !== undefined) {
      const {
        subject_attributes,
        name,
        waste_management_type_attributes,
      } = objectData;

      const subjectAttributes = objectData?.subject_attributes?.attributes;

      const oktmoAttributes = objectData?.address_attributes?.attributes?.oktmo_attributes?.attributes;

      setObjectInput({
        subject: { id: subject_attributes?.id, name: subjectAttributes?.name },
        name,
        [WASTE_MANAGEMENT_ENUM]: {
          id: waste_management_type_attributes?.id,
          name: waste_management_type_attributes?.attributes?.name,
        },
        address: {
          value: objectData?.address_attributes?.id,
          label: formatAddressString(objectData?.address_attributes?.attributes),
          id: objectData?.address_attributes?.id,
        },
        inn: { inn: subjectAttributes?.inn },
        subject_type: subjectAttributes?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
        oktmo: oktmoAttributes?.name ? `${oktmoAttributes?.code} ${oktmoAttributes?.name}` : '',
      });
    }
    /* eslint-disable-next-line */
  }, [objectData]);

  const isUpdate = objectData?.id !== undefined;

  const onCreateObject = async () => {
    const {
      subject, name, address: objectAddress, oktmo,
    } = objectInput;

    const ignoreInputs = ['subject_type'];

    const { hasErrors, validField } = formValidator({
      form: {
        ...objectInput,
        address: { id: objectAddress.id },
        oktmo: { id: oktmo.id },
        subject: { id: subject.id },
        inn: { inn: objectInput.inn },
        [WASTE_MANAGEMENT_ENUM]: { id: objectInput[WASTE_MANAGEMENT_ENUM].id },
      },
      ignoreInputs,
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const requestData = {
      address_id: objectAddress.id,
      subject_id: subject.id,
      name,
      waste_management_type_id: objectInput[WASTE_MANAGEMENT_ENUM].id,
    };

    if (status) requestData.status = status;

    let newObject;

    if (isUpdate) newObject = await updateObjectiveRequest(objectData.id, requestData);
    else newObject = await createObjectiveRequest(requestData);

    if (setNewObject) setNewObject(newObject);

    if (updateObjects) await updateObjects();

    close();
  };

  const { makeRequest: createObject } = useApi({
    request: onCreateObject,
    setIsLoading,
    successMessage: `Объект ${isUpdate ? 'обновлён' : 'создан'}`,
  });

  const handleChange = (field) => async (event, value) => {
    if (['inn', 'subject'].includes(field) && value.inn) {
      setError((prevState) => ({
        ...prevState,
        inn: { inn: '' },
        subject: { id: '', name: '' },
        subject_type: '',
      }));

      setObjectInput((prevState) => ({
        ...prevState,
        inn: { ...prevState.inn, ...value },
        subject: value,
        subject_type: value?.subject_type_attributes?.attributes?.name || '',
      }));
      return;
    }

    if (field === 'address') {
      setError((prevState) => ({
        ...prevState,
        oktmo: { ...DEFAULT_SELECTOR_OPTION },
        address: { ...DEFAULT_SELECTOR_OPTION },
      }));

      setObjectInput((prevState) => ({
        ...prevState,
        oktmo: {
          ...value?.oktmo_attributes,
          ...value?.oktmo_attributes?.attributes,
          label: getOktmoLabel(value?.oktmo_attributes?.attributes),
        },
        address: value,
      }));
      return;
    }

    if (field === 'oktmo') {
      setError((prev) => ({
        ...prev,
        oktmo: { ...DEFAULT_SELECTOR_OPTION },
        address: { ...DEFAULT_SELECTOR_OPTION },
      }));

      try {
        await putAddressRequest({ oktmo_id: value.id }, objectInput.address.id);

        setObjectInput((prev) => ({
          ...prev,
          [field]: value,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    setError((prevState) => ({
      ...prevState,
      [field]: '',
    }));
    setObjectInput((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (subjectData) {
      setObjectInput((prevState) => ({
        ...prevState,
        ...subjectData,
      }));
    }
  }, [subjectData]);

  const deleteObject = () => {
    setModalStore({
      ...modalStore,
      deleteModal: {
        isOpen: true,
        callback: async () => {
          await updateObjects();
          close();
        },
        objectName: 'объект',
        deleteRequest: deleteObjectiveRequest,
        id: objectData.id,
        message: 'Объект удалён',
      },
    });
  };

  const setNewAddress = (newAddress) => {
    setObjectInput((prevState) => ({
      ...prevState,
      address: {
        ...newAddress,
        value: newAddress.id,
        label: formatAddressString(newAddress),
      },
      oktmo: {
        ...newAddress?.oktmo_attributes,
        ...newAddress?.oktmo_attributes?.attributes,
        label: getOktmoLabel(newAddress?.oktmo_attributes?.attributes),
      },
    }));
  };

  const modalAddress = (
    <ModalAddAddress
      showMap={showMap}
      isLoadingMap={isLoading}
      setIsLoadingMap={setIsLoading}
      modalTitle={modalAddressTitle}
      addressInfo={objectInput.address}
      close={() => closeModal('address')}
      setNewAddress={setNewAddress}
    />
  );

  const modalOktmo = (
    <ModalAddOktmo
      setNewAddress={setNewAddress}
      close={() => closeModal('oktmo')}
      addressId={objectInput?.address?.id}
      isOpen
    />
  );

  return (
    <ModalCard
      open
      inputs={objectFrame}
      inputData={objectInput}
      close={close}
      isLoading={isLoading}
      additionalTopContainer={<AlertRequiredFields />}
      title={`${isUpdate ? `${objectData?.deleted_at ? '(АРХИВ) ' : ''}Редактирование` : 'Добавление'} объекта ${objectTypeTitle}`}
      error={error}
      additionalContainer={isOpenModal.address ? modalAddress : isOpenModal.oktmo && modalOktmo}
      handleChange={handleChange}
      firstButtonProp={!objectData?.deleted_at && objectData?.subject_id && {
        label: 'Удалить объект',
        onClick: deleteObject,
      }}
      secondButtonProp={!objectData?.deleted_at && {
        label: `${isUpdate ? 'Обновить' : 'Добавить'} объект`,
        onClick: () => createObject(),
      }}
    />
  );
}

export default ModalAddObject;
