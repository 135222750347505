import { useEffect, useState } from 'react';
import { compareId } from '../helpers';
import { getReferencesRequest } from '../api/referenceApi';

export default function useGetReferenceItems({ referenceUrl }) {
  const [referenceTypes, setReferenceTypes] = useState([]);

  const getReferenceTypeIdsByCode = (types) => types.map((type) => referenceTypes.find(({ code }) => compareId(code, type))?.id) || '';

  useEffect(() => {
    getReferencesRequest({ url: referenceUrl, params: { total: 1000 } })
      .then(((responseSubjectsTypes) => setReferenceTypes(responseSubjectsTypes)));
  }, [referenceUrl]);

  return { referenceTypes, getReferenceTypeIdsByCode };
}
