// @ts-nocheck
import React from 'react';
import { Box, Typography } from '@mui/material';
import { AddressAutocomplete } from '../AutoCompletes';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { getSubjectRequest, getSubjectsRequest, updateSubjectRequest } from '../../api/subjectApi';
import InputsBoxStyled from '../CreateApllication/InputsBoxStyled';
import TitleBoxStyled from '../CreateApllication/TitleBoxStyled';
import { getObjectivesRequest } from '../../api/objectivesApi';
import AddButton from '../Buttons/AddButton';
import { OBJECTIVES_URL, WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import { SUBJECT_TYPES, WASTE_MANAGEMENT_ENUM } from '../../constants/enums';
import { filterIndividualSubjectKind } from '../../helpers/entityHelper';
import { getReferencesRequest } from '../../api/referenceApi';
import { compareId } from '../../helpers';

const getSubject = () => (params) => getSubjectsRequest({
  params: {
    ...params,
    // enterprise_activity_type_ids: subjectTypeIds.join(','),
    total: 100,
  },
  shouldCache: true,
});

export default function WasteRecipient({
  error,
  onChange,
  openNewSubjectModal,
  openNewObjectModal,
  subjectTypeIds,
  wasteManagementTypeIds,
  recipientWasteManagementType,
  errorWasteManagementType,
  wasteRecipient: {
    object,
    name: subject,
    subject_type,
    inn,
    ogrn,
  },
}) {
  const onChangeObject = (e, value) => onChange(e, value, { field: 'waste_recipient.object' });

  const onChangeOrganisation = (e, value) => onChange(e, value, { field: 'waste_recipient' });

  const hasSubject = subject?.id !== '';

  const getSubjectApi = getSubject(subjectTypeIds);

  return (
    <Box>
      <Typography fontWeight="bold" fontSize={20}>Получатель отходов:</Typography>
      <Box mb={1}>
        <TitleBoxStyled fontWeight="bold">Организация</TitleBoxStyled>
        <InputsBoxStyled>
          <AsyncAutocomplete
            required
            shouldRequest={subjectTypeIds.length}
            request={getSubjectApi}
            label="Наименование субъекта"
            optionField={{
              id: 'id',
              value: 'id',
              label: 'name',
            }}
            name="name"
            value={subject}
            noOptionComponent={(
              <AddButton variant="outlined" onClick={openNewSubjectModal}>
                Добавить субъект
              </AddButton>
            )}
            onChange={onChangeOrganisation}
            error={error?.name?.id}
          />
          <AsyncAutocomplete
            required
            request={(params) => getReferencesRequest({
              url: SUBJECT_TYPES,
              params,
              filter: filterIndividualSubjectKind,
            })}
            value={subject_type}
            optionField={{ label: 'name', value: 'id' }}
            onChange={async (e, value) => {
              const newSubject = await updateSubjectRequest(subject.id, { subject_type_id: value.id });

              return onChangeOrganisation(e, newSubject);
            }}
            name="subject_type"
            disabled={hasSubject && subject_type.id}
            label="Тип субъекта"
            error={error?.subject_type?.name || error?.subject_type?.value}
          />
          <AsyncAutocomplete
            required
            value={inn}
            error={error?.inn?.inn}
            name="inn"
            onChange={onChangeOrganisation}
            label="ИНН"
            disabled={hasSubject && inn.inn}
            optionField={{
              label: 'inn',
              value: 'inn',
            }}
            shouldRequest={subjectTypeIds.length}
            request={getSubjectApi}
          />
          <AsyncAutocomplete
            value={ogrn}
            error={error?.ogrn?.ogrn}
            name="ogrn"
            onChange={onChangeOrganisation}
            disabled={hasSubject && ogrn.ogrn}
            label="ОГРН"
            optionField={{
              label: 'ogrn',
              value: 'ogrn',
            }}
            shouldRequest={subjectTypeIds.length}
            request={getSubjectApi}
          />
        </InputsBoxStyled>
      </Box>
      <Box>
        <TitleBoxStyled fontWeight="bold">Объект</TitleBoxStyled>
        <InputsBoxStyled>
          <AsyncAutocomplete
            required
            useDefaultFilter
            request={(params) => {
              const finalParams = {
                ...params,
                waste_management_type_ids: wasteManagementTypeIds.join(','),
              };
              if (subject.id !== '') {
                return getSubjectRequest({
                  id: subject.id,
                  url: OBJECTIVES_URL,
                  params: finalParams,
                });
              }
              return getObjectivesRequest({ params: finalParams });
            }}
            deps={[subject]}
            label="Объект"
            noOptionComponent={(
              <AddButton variant="outlined" onClick={openNewObjectModal}>
                Создать объект
              </AddButton>
            )}
            optionField={{
              value: 'id',
              label: 'name',
            }}
            error={error?.object?.name?.id}
            name="name"
            value={object.name}
            onChange={onChangeObject}
          />
          <AsyncAutocomplete
            required
            shouldRequest={wasteManagementTypeIds.length}
            name={WASTE_MANAGEMENT_ENUM}
            request={(params) => getReferencesRequest({ url: WASTE_MANAGEMENT_TYPES_URL, params })}
            sx={{ width: '320px' }}
            additionalFilter={(options) => options
              .filter(({
                id,
              }) => wasteManagementTypeIds?.some((type) => compareId(type, id)))}
            value={recipientWasteManagementType}
            error={errorWasteManagementType}
            label="Цель передачи отходов на объект обращения"
            onChange={(e, value) => onChange(e, value)}
          />
          <AddressAutocomplete
            required
            disabled
            error={error?.object?.address?.value}
            name="address"
            setNewAddress={(newAddress) => onChangeObject({ target: { name: 'address' } }, newAddress)}
            value={object.address}
            onChange={onChangeObject}
          />
        </InputsBoxStyled>
      </Box>
    </Box>
  );
}
