// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LeafletMap from '../../components/Map/LeafletMap';

export default function AddressPointMap({ address, onClickMap, isLoading }) {
  const [selectedAddressPlace, setSelectedAddressPlace] = useState({});
  const [outerMap, setOuterMap] = useState();

  const onClick = (latLng, map) => {
    setSelectedAddressPlace(latLng);

    onClickMap(latLng, map);
  };

  useEffect(() => {
    if (outerMap?.fitBounds && address?.place?.[0]) {
      const lat = address.place[1];
      const lng = address.place[0];
      setSelectedAddressPlace({
        lat,
        lng,
      });
      outerMap.fitBounds([
        [lat, lng],
        [lat, lng],
      ]);
    }
  }, [address, outerMap]);

  return (
    <Box sx={{ width: '100%', height: '100%', pt: 3 }}>
      <LeafletMap
        isMapLoading={isLoading}
        hasLegend={false}
        setOuterMap={setOuterMap}
        outerMap={outerMap}
        width="100%"
        height="40vh"
        onClickMap={onClick}
        selectedMarker={selectedAddressPlace}
        fromAddress={address ? [address] : undefined}
      />
    </Box>
  );
}
