import React from 'react';
import ReferenceGenerator from '../../../components/ReferenceGenerator';
import { SUBJECT_TYPES } from '../../../constants/enums';

export default function SubjectTypeReferencePage() {
  return (
    <ReferenceGenerator
      url={SUBJECT_TYPES}
      importKind={SUBJECT_TYPES}
    />
  );
}
