import { update } from 'lodash';
import cloneDeep from 'lodash.clonedeep';

export function isArray(value) {
  return Array.isArray(value);
}

export function isObject(value) {
  return !isArray(value) && value !== null && !(value instanceof Date) && typeof value === 'object';
}

export function getParamsFromObject({ object }) {
  let stringParams = '';
  let count = 0;

  Object.keys(object).forEach((key) => {
    if (count === 0) stringParams = '?';

    count += 1;
    stringParams += `${key}=${object[key]}&`;
  });
  return stringParams.slice(0, stringParams.length - 1);
}

export const jsonCopyObject = (object) => JSON.parse(JSON.stringify(object));

// example 1:
// object { prop1: { prop2: { prop3: 'value' }}}
// field 'prop1.prop2.prop3'
// value 'changedValueString'
// return '{ prop1: { prop2: { prop3: 'changedValueString' }}}
//
// example 2:
// object { a: 10 }
// field: 'prop1.prop2.prop3'
// value 'someValue'
// return '{ a: 10, prop1: { prop2: { prop3: 'someValue' }}
//
// example 3:
// object: object = { prop2: [ { prop3: [ { prop5: '1' } ] }, { prop4: [ { prop6: '2' } ] ] }
// field: 'object.prop2.0.prop3
// value: 'someValue'
// return { prop2: [ { prop3: 'someValue' } ] }
//
// example 4:
// object: array = [ { prop1: [ { prop2: '1' } ] } ]
// field: 'array.0.prop1.0.prop2
// value: 'someValue'
// return [ { prop1: [ { prop2: 'someValue' } ] } ]

export function objectChangeDotProperty({ field, value, object }) {
  const cloneObject = object;

  update(object, field, () => value);

  return cloneDeep(cloneObject);
}

export const getObjectValuesByField = (initialObject, object) => {
  const valuesObject = {};

  Object.keys(initialObject).forEach((field) => {
    valuesObject[field] = object[field];
  });

  return valuesObject;
};

export const deleteUndefinedFromObject = (params = {}) => {
  const filteredParams = { ...params };

  Object.keys(filteredParams).forEach((key) => {
    if (filteredParams[key] === undefined || filteredParams[key] === '') {
      delete filteredParams[key];
    }
  });

  return filteredParams;
};
