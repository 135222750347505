import useDeleteModal from '../useDeleteModal';

export function useDeleteCadastreEntity() {
  const deleteModal = useDeleteModal();

  const openDeleteModal = (deleteCallback, index, objectName) => {
    deleteModal({
      deleteItem: () => deleteCallback(),
      objectName: `${objectName} №${index + 1}`,
    });
  };

  return { openDeleteModal };
}
