// @ts-nocheck
import {
  Alert, Box, useMediaQuery, useTheme,
} from '@mui/material';
import cadesplugin from 'crypto-pro-cadesplugin';
import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { METHODS_API } from '../../api/api';
import {
  cadastreGenerateTransmittalMailRequest,
  cadastreReportHistoryRequest,
  getCadastreReportStatusesRequest,
} from '../../api/cadastreApi';
import CustomButton from '../../components/Buttons/CustomButton';
import CadastrTable from '../../components/Cadastr/CadastrTable';
import { CellStatus } from '../../components/Cadastr/CellStatus';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import Selector from '../../components/Selectors/Selector';
import { Text } from '../../components/Text';
import { ENTERPRISE_ACTIVITY_TYPE_IDS, ENTERPRISE_TYPE_ENUM, HAZARD_CLASS_ENUM } from '../../constants/enums';
import {
  calcBlockedForm,
  flexGap2,
  flexRowGap2,
  getFormsData,
  getReportFormIdString,
  prepareFormTable,
  REPORT_STATUS_NAMES,
} from '../../constants/lkCadastr/cadastrStorage';
import { CADASTR_GENERAL_INFO_ROUTE } from '../../constants/routes';
import { GENERATE_TRANSMITTAL_MAIL_URL } from '../../constants/urls';
import { formatDate } from '../../helpers/dateHelper';
import { useApi } from '../../hooks';
import useGetMember from '../../hooks/useGetMember';
import { useUserStore } from '../../store';

const reportsTableColumns = [
  {
    headerTitle: 'Номер отчёта',
    field: 'id',
    getField: ({
      id,
      created_at,
      report_status_attributes,
    }) => getReportFormIdString({ id, created_at, code: report_status_attributes?.attributes?.code }),
  },
  {
    headerTitle: 'Дата подачи',
    field: 'application_date',
    getField: ({ application_date }) => formatDate({ date: application_date }),
  },
  {
    headerTitle: 'Дата формирования',
    field: 'created_at',
    getField: ({ created_at }) => formatDate({ date: created_at }),
  },
  {
    headerTitle: 'Дата подтверждения',
    field: 'approved_date',
    getField: ({ approved_date }) => formatDate({ date: approved_date }),
  },
  {
    headerTitle: 'Статус',
    field: 'status',
    getField: ({ report_status_attributes }) => report_status_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Заполнено',
    field: 'filling_percentage',
    getField: ({ filling_percentage }) => (
      <CellStatus complete={filling_percentage} />
    ),
  },
];

const formsTableColumns = [
  {
    headerTitle: 'Наименование формы',
    field: 'label',
  },
  {
    headerTitle: 'Заполнено',
    field: 'filling_percentage',
    getField: CellStatus,
  },
];

async function doCertsList() {
  const certsApi = await cadesplugin();
  const newCerts = await certsApi.getCertsList();

  return newCerts;
}

const getOwnerOfSign = (cert) => {
  const ownerData = cert?.friendlySubjectInfo?.();
  const ownerPeriod = cert?.friendlyValidPeriod?.();

  return {
    owner: ownerData[0].text,
    from: ownerPeriod.from.ddmmyy,
    to: ownerPeriod.to.ddmmyy,
    serialNumber: cert.serialNumber,
  };
};

export default function CadastrReports() {
  const navigate = useNavigate();
  const { userStore } = useUserStore();
  const theme = useTheme();
  const [reports, setReports] = useState([]);
  const [isModalDownloadReport, setIsModalDownloadReport] = useState(false);
  const [forms, setForms] = useState(getFormsData());
  const [modalSign, setModalSign] = useState(false);
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [modalSignature, setModalSignature] = useState({});
  const [reportStatuses, setReportStatuses] = useState('');
  const [loading, setLoading] = useState(false);

  useApi({
    request: getCadastreReportStatusesRequest,
    setter: setReportStatuses,
    shouldRequest: true,
  });

  const getCerts = async () => {
    const formattedCertificates = await doCertsList();
    setCertificates(formattedCertificates.map((cert) => {
      const issuerInfo = cert.friendlyIssuerInfo();
      const ownerInfo = getOwnerOfSign(cert);

      return {
        ...cert,
        label: issuerInfo[0]?.text ? `${issuerInfo[0].text} ${ownerInfo.owner}` : '',
        id: cert.serialNumber,
        value: cert.serialNumber,
      };
    }));
  };

  const getSubjectReports = (params) => cadastreReportHistoryRequest({
    params: {
      subject_id: userStore?.id, order_column: 'created_at', order_type: 'desc', ...params,
    },
  });

  const { makeRequest: getReports, isLoading: isLoadingReports } = useApi({
    setter: (newReports) => {
      setReports(newReports);

      const actualReport = newReports?.[0];

      if (actualReport) setForms(prepareFormTable(actualReport));
    },
    request: getSubjectReports,
    shouldRequest: userStore?.id,
  });

  const findStatusIdByCode = (code) => reportStatuses.find((item) => item.code === code)?.id;

  useLayoutEffect(() => {
    if (userStore?.id) {
      const hasActivityType = userStore?.[ENTERPRISE_ACTIVITY_TYPE_IDS]?.length;
      const hasType = userStore?.[ENTERPRISE_TYPE_ENUM];
      const hasHazardClass = userStore?.[HAZARD_CLASS_ENUM]?.length;

      if (!(hasType && hasActivityType && hasHazardClass)) {
        navigate(CADASTR_GENERAL_INFO_ROUTE);
      }
    }
  }, [userStore]);

  const { defaultRequest: reportHistoryApi } = useApi({
    request: cadastreReportHistoryRequest,
  });

  useGetMember();

  const currentFormStatus = reports?.[0]?.report_status_attributes?.attributes?.code;

  const buttonText = useMemo(() => {
    switch (currentFormStatus) {
      case REPORT_STATUS_NAMES.checked:
        return 'Сформировать сопроводительный документ';
      default:
        return '';
    }
  }, [currentFormStatus]);

  const buttonAction = useMemo(() => {
    switch (currentFormStatus) {
      case REPORT_STATUS_NAMES.checked:
        return () => setIsModalDownloadReport(true);
      default:
        return '';
    }
  }, [currentFormStatus]);

  const { defaultRequest: generateMail } = useApi({
    request: cadastreGenerateTransmittalMailRequest,
  });

  const changeReportStatusToPrinted = async () => reportHistoryApi({
    method: METHODS_API.PUT,
    data: {
      report_status_id: findStatusIdByCode(REPORT_STATUS_NAMES.paper_document_printed),
    },
    id: reports[0].id,
  });

  const downloadReport = (withSignature) => async () => {
    const fileUrl = reports[0]?.transmittal_mail_url;

    if (!fileUrl) {
      await generateMail({
        method: METHODS_API.PUT,
        id: reports[0]?.id,
        rest: GENERATE_TRANSMITTAL_MAIL_URL,
      });
    }

    if (withSignature) {
      setModalSign(true);
    } else if (reports[0].report_status_attributes?.attributes?.code === REPORT_STATUS_NAMES.checked) {
      const newReport = await changeReportStatusToPrinted();

      const newReports = reports.slice();
      newReports.splice(0, 1, newReport);
      setReports(newReports);

      setModalSignature(newReport);
    }

    setIsModalDownloadReport(false);
  };

  const closeModalSign = () => setModalSign(false);

  const signFiles = async () => {
    setLoading(true);

    try {
      const formattedCertificates = await doCertsList();

      let newReport = await reportHistoryApi({
        method: METHODS_API.PUT,
        id: reports[0]?.id,
        data: { sign_info: getOwnerOfSign(formattedCertificates.find((cert) => cert.serialNumber === selectedCertificate.id)) },
      });

      if (newReport?.id) {
        if (reports[0].report_status_attributes?.attributes?.code === REPORT_STATUS_NAMES.checked) {
          newReport = await changeReportStatusToPrinted();
        }

        const newReports = reports.slice();
        newReports.splice(0, 1, newReport);
        setReports(newReports);

        setModalSignature(newReport);

        closeModalSign();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (modalSign) {
      getCerts();
    }
  }, [modalSign]);

  const isCurrentYearReport = useMemo(
    () => {
      const newDate = new Date();
      const day = newDate.getDay();
      const month = newDate.getMonth();

      const less21April = !(month === 3 && day > 21);

      const hasCurrentYearReport = reports[0]?.created_at
        && new Date(reports[0].created_at).getFullYear() === new Date().getFullYear();

      return !isLoadingReports && month <= 3 && less21April && !hasCurrentYearReport;
    },
    [reports, isLoadingReports],
  );

  const notFullFormId = useMemo(() => {
    const subjectAttributes = reports[0]?.subject_attributes?.attributes
      ? { ...reports[0]?.subject_attributes?.attributes, ...reports[0]?.subject_attributes }
      : userStore;

    return calcBlockedForm({
      userStore: subjectAttributes,
      completedForms: forms.reduce((prev, item) => {
        prev[`form${item.id}`] = item.complete;
        return prev;
      }, {}),
    });
  }, [forms, reports, userStore]);

  return (
    <Box pt={1} display="flex" justifyContent="space-between" flexWrap={tabletSizeAndLower && 'wrap'}>
      <ModalWithTitle
        open={modalSign}
        close={!loading && closeModalSign}
        containerSx={{ width: '720px' }}
        title="Выбрать сертификат для подписи"
      >
        <Box display="flex" gap={3}>
          <Selector
            multiline
            options={certificates}
            value={selectedCertificate}
            onChange={(e, value) => setSelectedCertificate(value)}
          />
          <CustomButton isLoading={loading} disabled={!selectedCertificate} onClick={signFiles}>Подписать</CustomButton>
        </Box>
      </ModalWithTitle>
      <ModalWithTitle
        containerSx={{ width: 'fit-content' }}
        open={isModalDownloadReport}
        close={() => setIsModalDownloadReport()}
        title="Подписать электронной подписью"
      >
        <Box sx={flexGap2}>
          <Alert severity="warning">
            Напоминаем, что подписание ЭЦП не освобождает от подачи документа на бумажном носителе в Комитет
            Ленинградской области по обращению с отходами.
            Предоставление информации осуществляется в отчётный период в срок не позднее 20 апреля года, следующего за
            отчётным.
            Дата представления документа, подтверждающего внесение сведений, в Комитет определяется :
            <Text sx={{ ml: 2 }}>а) в случае отправления почтой - по дате отправления (почтовому штемпелю) ;</Text>
            <Text sx={{ ml: 2 }}>б) в случае доставки лично - по дате поступления в Комитет.</Text>
          </Alert>
          <Box sx={{ ...flexRowGap2, justifyContent: 'center' }}>
            <CustomButton onClick={downloadReport(true)}>Да</CustomButton>
            <CustomButton color="error" onClick={downloadReport()}>Нет</CustomButton>
          </Box>
        </Box>
      </ModalWithTitle>
      <CadastrTable
        title="Мои отчёты"
        type="reports"
        tableData={reports}
        isLoading={isLoadingReports}
        getReports={getReports}
        forms={forms}
        reports={reports}
        modalSignature={modalSignature}
        setModalSignature={setModalSignature}
        buttonText={isCurrentYearReport && 'Создать отчёт'}
        tableColumns={reportsTableColumns}
      />
      <CadastrTable
        title="Отчётные формы"
        type="forms"
        disabledRow={(row) => Number(row.id) > Number(notFullFormId)}
        tableData={forms}
        isLoading={isLoadingReports}
        getReports={getReports}
        reports={reports}
        buttonText={buttonText}
        buttonAction={buttonAction}
        tableColumns={formsTableColumns}
      />
    </Box>
  );
}
