// @ts-nocheck
import React, { useState } from 'react';
import { useApi } from '../../../hooks';
import { APPLICATIONS_URL } from '../../../constants/urls';
import { getSubjectRequest } from '../../../api/subjectApi';
import CustomTable from '../../Tables/CustomTable';
import { ReactComponent as QrCodeIcon } from '../../../assets/qr_code_icon.svg';
import { getDayMonthNameYear } from '../../../helpers/dateHelper';
import TitleSubtitle from '../../TitleSubtitle';

const initialVehicleTable = [
  {
    field: 'egrz',
    getField: ({ created_at, vehicle_attributes }) => (
      <TitleSubtitle title={getDayMonthNameYear(created_at)} subTitle={`ТС: ${vehicle_attributes?.attributes?.egrz}`} />
    ),
  },
  {
    field: 'ownership_type_text',
    getField: ({ application_objectives_attributes }) => (
      <TitleSubtitle
        title="Количество точек"
        subTitle={application_objectives_attributes?.length}
      />
    ),
  },
  {
    field: 'id',
    getField: () => <QrCodeIcon />,
  },
];

export default function PermitTable({ open, cellData, searchText }) {
  const [applications, setApplications] = useState([]);
  const [totalPermit, setTotalPermit] = useState(0);

  const { isLoading } = useApi({
    setter: setApplications,
    request: () => getSubjectRequest({
      id: cellData.id,
      url: APPLICATIONS_URL,
      params: {
        total: 1000,
        search: searchText,
      },
      setTableStore: (response) => setTotalPermit(response?.meta?.total || 0),
    }),
    shouldRequest: true,
  });

  return (
    <CustomTable
      hideHeader
      hidePagination
      onClick={open}
      isLoading={isLoading}
      data={applications}
      total={totalPermit}
      sx={{
        table: {
          minWidth: '320px',
        },
        '.MuiTableCell-root p': {
          margin: 0,
        },
      }}
      columns={initialVehicleTable}
    />
  );
}
