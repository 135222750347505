import React from 'react';
import { Alert } from '@mui/material';
import CustomButton from '../../../components/Buttons/CustomButton';
import EmailConfirmationContainer from '../../../components/EmailConfirmationContainer';

export default function EmailConfirmationErrorToken() {
  return (
    <EmailConfirmationContainer>
      <Alert severity="error">Ссылка для подтверждения более не действительна. Необходимо использовать новую</Alert>
      <CustomButton href="/">Вернуться на главную</CustomButton>
    </EmailConfirmationContainer>
  );
}
