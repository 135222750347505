import { EMAILS_URL, NOTIFICATIONS_URL, SUBSCRIPTIONS_URL } from '../constants/urls';
import api, { commonApi } from './api';

export const getEmailsRequest = (params, setTableStore) => api({
  url: EMAILS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const sendEmailRequest = (data) => api({
  url: EMAILS_URL,
  method: 'post',
  data: { email: data },
});

export const subscriptionsRequest = commonApi(SUBSCRIPTIONS_URL, 'newsletter');

export const notificationsRequest = commonApi(NOTIFICATIONS_URL, 'notification');
