import {
  CADASTRE_APPROVAL_STANDARD_WASTES_URL,
  CADASTRE_AREA_DOCUMENT_URL,
  CADASTRE_AREAS_URL,
  CADASTRE_CONTACTS_URL,
  CADASTRE_CONTAINERS_URL,
  CADASTRE_ENVIRONMENT_MONITORING_TYPES_URL,
  CADASTRE_ENVIRONMENTAL_SYSTEM_TYPES_URL,
  CADASTRE_FEEDBACKS_URL,
  CADASTRE_FILLING_RECOMMENDATIONS_URL,
  CADASTRE_FORM1_BUILDINGS_URL,
  CADASTRE_FORM2_REPORT_WASTES_URL,
  CADASTRE_FORM3_REPORT_GENERATIONS_URL,
  CADASTRE_FORM4_REPORT_APPROVAL_STANDARDS_URL,
  CADASTRE_FORM5_REPORT_LICENSES_URL,
  CADASTRE_FORM6_REPORT_ACTUAL_VOLUMES_URL,
  CADASTRE_FORM7_REPORT_TECHNOLOGIES_URL,
  CADASTRE_FORM8_REPORT_PLACING_WASTES_URL,
  CADASTRE_INDUSTRIAL_AREAS_URL,
  CADASTRE_INSTALLATIONS_URL,
  CADASTRE_LIMIT_WASTES_URL,
  CADASTRE_MANUFACTURED_PRODUCTS_URL,
  CADASTRE_MONITORING_TYPES_URL,
  CADASTRE_NEAREST_TOWNS_URL,
  CADASTRE_NEAREST_WATER_OBJECTS_URL,
  CADASTRE_OBJECT_RIGHT_TYPES_URL,
  CADASTRE_OBJECT_SUBJECT_RELATIONSHIPS_URL,
  CADASTRE_PLACES_WASTES_URL,
  CADASTRE_PLACING_OBJECTS_URL,
  CADASTRE_PROJECT_DOCUMENTS_URL,
  CADASTRE_QUESTION_ANSWERS_URL,
  CADASTRE_REPORT_ACTUAL_VOLUMES_URL,
  CADASTRE_REPORT_APPROVAL_STANDARDS_URL,
  CADASTRE_REPORT_BUILDINGS_URL,
  CADASTRE_REPORT_COMPONENT_COMPOSITION_WASTES_URL,
  CADASTRE_REPORT_GENERATIONS_URL,
  CADASTRE_REPORT_HISTORIES_URL,
  CADASTRE_REPORT_LICENSES_URL,
  CADASTRE_REPORT_PLACING_WASTES_URL,
  CADASTRE_REPORT_STATUSES_URL,
  CADASTRE_REPORT_TECHNOLOGIES_IMPORT_URL,
  CADASTRE_REPORT_TECHNOLOGIES_URL,
  CADASTRE_REPORT_WASTES_URL,
  CADASTRE_RESTRICTED_AREA_TYPES_URL,
  CADASTRE_SECONDARY_WASTES_URL,
  CADASTRE_TECHNOLOGIES_URL,
  CADASTRE_TECHNOLOGY_OBJECTS_URL,
  CADASTRE_TECHNOLOGY_WASTES_URL,
  CADASTRE_TRANSPORTED_WASTES_URL,
  CADASTRE_WASTE_PLACEMENT_MUNICIPALITIES_URL,
  CADASTRE_WASTE_TRANSFERS_URL,
  CADASTRE_WASTE_URL,
  ENUMS_URL,
} from '../constants/urls';
import api, { METHODS_API } from './api';

const getUrlWithId = (url, id, rest) => `${url}${id ? `/${id}` : ''}${rest || ''}`;

export const cadastreApi = (url, entity) => ({
  data, params, method, id, rest, isFormData, controller, setTableStore, isEntityAttributes,
} = {
  method: METHODS_API.GET, data: undefined, id: undefined, params: undefined, rest: undefined,
}) => {
  const dataObject = !isFormData && entity ? { [entity]: data } : data;
  const bodyData = data ? dataObject : undefined;

  return api({
    url: getUrlWithId(url, id, rest),
    method,
    params: method === METHODS_API.GET || method === undefined
      ? { total: 10000, ...params }
      : undefined,
    data: bodyData,
  }, {
    isEntityAttributes: isEntityAttributes !== undefined ? isEntityAttributes : entity !== 'mail',
    controller,
    restUrl: rest ? '' : undefined,
    setTableStore,
  });
};

export const cadastreSignMailRequest = (id, isSigned) => api({
  url: `${CADASTRE_REPORT_HISTORIES_URL}/${id}/file_download`,
  method: METHODS_API.GET,
  responseType: 'blob',
  params: isSigned && { file: 'transmittal_mail_signed' },
}, { restUrl: '' });

export const cadastreRecommendationRequest = (id) => api({
  url: `${CADASTRE_FILLING_RECOMMENDATIONS_URL}/${id}/file_download`,
  method: METHODS_API.GET,
  responseType: 'blob',
}, { restUrl: '' });

export const cadastreGenerateTransmittalMailRequest = cadastreApi(CADASTRE_REPORT_HISTORIES_URL, 'mail');

export const cadastreReportHistoryPreviousRequest = cadastreApi(CADASTRE_REPORT_HISTORIES_URL, '');

// Отчет: Здания, помещения, сооружения
export const cadastreReportBuildingsRequest = cadastreApi(CADASTRE_REPORT_BUILDINGS_URL, 'report_building');

// Форма 2 - Отходы
export const cadastreReportWastesRequest = cadastreApi(CADASTRE_REPORT_WASTES_URL, 'report_waste');

// Форма 2 Компонентный состав
export const cadastreComponentCompositionWastesRequest = cadastreApi(
  CADASTRE_REPORT_COMPONENT_COMPOSITION_WASTES_URL,
  'component_composition_waste',
);

// Форма 3 Сведения об образовании отходов производства и потребления
export const cadastreReportGenerationsRequest = cadastreApi(
  CADASTRE_REPORT_GENERATIONS_URL,
  'report_generation',
);

export const cadastreWasteTransfersRequest = cadastreApi(
  CADASTRE_WASTE_TRANSFERS_URL,
  'waste_transfer',
);

// Форма 4 Документ об утверждении нормативов
export const cadastreReportApprovalStandardRequest = cadastreApi(
  CADASTRE_REPORT_APPROVAL_STANDARDS_URL,
  'report_approval_standard',
);

export const cadastreLimitWastesRequest = cadastreApi(
  CADASTRE_LIMIT_WASTES_URL,
  'limit_waste',
);

export const cadastreApprovalStandardWastesRequest = cadastreApi(
  CADASTRE_APPROVAL_STANDARD_WASTES_URL,
  'approval_standard_waste',
);

export const cadastreTransportedWastesRequest = cadastreApi(
  CADASTRE_TRANSPORTED_WASTES_URL,
  'transported_waste',
);

// Форма 5 - Промплощадки
export const cadastreIndustrialAreasRequest = cadastreApi(CADASTRE_INDUSTRIAL_AREAS_URL, 'industrial_area');

// Форма 5 - Лицензии
export const cadastreReportLicenseRequest = cadastreApi(CADASTRE_REPORT_LICENSES_URL, 'report_license');

// Отчеты
export const cadastreReportHistoryRequest = cadastreApi(CADASTRE_REPORT_HISTORIES_URL, 'report_history');

// Статусы отчёта
export const getCadastreReportStatusesRequest = cadastreApi(CADASTRE_REPORT_STATUSES_URL, 'report_status');

// Площадки
export const cadastreAreasRequest = cadastreApi(CADASTRE_AREAS_URL, 'area');

// Отходы
export const cadastreWasteRequest = cadastreApi(CADASTRE_WASTE_URL, 'waste');

export const cadastreContainerRequest = cadastreApi(CADASTRE_CONTAINERS_URL, 'container');

// Форма 6 - Фактические объёмы
export const cadastreReportActualVolumesRequest = cadastreApi(
  CADASTRE_REPORT_ACTUAL_VOLUMES_URL,
  'report_actual_volume',
);

// Форма 7 - Технологии
export const cadastreReportTechnologiesRequest = cadastreApi(
  CADASTRE_REPORT_TECHNOLOGIES_URL,
  'report_technology',
);

export const cadastreReportTechnologiesImportRequest = cadastreApi(
  CADASTRE_REPORT_TECHNOLOGIES_IMPORT_URL,
  'report_technology',
);

export const cadastreInstallationsRequest = cadastreApi(
  CADASTRE_INSTALLATIONS_URL,
  'installation',
);

export const cadastreMonitoringTypesRequest = cadastreApi(
  CADASTRE_MONITORING_TYPES_URL,
  'monitoring_type',
);

export const cadastreTechnologyRequest = cadastreApi(
  CADASTRE_TECHNOLOGIES_URL,
  'technology',
);

export const cadastreManufacturedProductsRequest = cadastreApi(
  CADASTRE_MANUFACTURED_PRODUCTS_URL,
  'manufactured_product',
);

export const getTechnologyEnumsRequest = (enum_field, params) => api(
  { url: `${CADASTRE_TECHNOLOGIES_URL}${ENUMS_URL}`, params: { ...params, total: 1000 } },
  { enum_field },
);

export const getInstallationEnumsRequest = (enum_field, params) => api(
  { url: `${CADASTRE_INSTALLATIONS_URL}${ENUMS_URL}`, params: { ...params, total: 1000 } },
  { enum_field },
);

export const getObjectiveStateEnumsRequest = (enum_field, params) => api(
  { url: `${CADASTRE_PLACING_OBJECTS_URL}${ENUMS_URL}`, params: { ...params, total: 1000 } },
  { enum_field },
);

export const getObjectiveRightTypeEnumsRequest = (enum_field, params) => api(
  { url: `${CADASTRE_OBJECT_RIGHT_TYPES_URL}${ENUMS_URL}`, params: { ...params, total: 1000 } },
  { enum_field },
);

export const getNearestTownDirectionEnumsRequest = (params) => api(
  { url: `${CADASTRE_NEAREST_TOWNS_URL}${ENUMS_URL}`, params: { ...params, total: 1000 } },
  { enum_field: 'direction' },
);

export const cadastreTechnologyObjectsRequest = cadastreApi(
  CADASTRE_TECHNOLOGY_OBJECTS_URL,
  'technology_object',
);

export const cadastrePlacedWastesRequest = cadastreApi(
  CADASTRE_PLACES_WASTES_URL,
  'placed_waste',
);

export const cadastreObjectRightTypesRequest = cadastreApi(
  CADASTRE_OBJECT_RIGHT_TYPES_URL,
  'object_right_type',
);

export const cadastreEnvironmentMonitoringTypesRequest = cadastreApi(
  CADASTRE_ENVIRONMENT_MONITORING_TYPES_URL,
  'environmental_monitoring_type',
);

export const cadastreAreaDocumentsRequest = cadastreApi(
  CADASTRE_AREA_DOCUMENT_URL,
  'area_document',
);

export const cadastrePlacingObjectsRequest = cadastreApi(
  CADASTRE_PLACING_OBJECTS_URL,
  'placing_object',
);

export const cadastreNearestTownsRequest = cadastreApi(
  CADASTRE_NEAREST_TOWNS_URL,
  'nearest_town',
);

export const cadastreEnvironmentalSystemTypesRequest = cadastreApi(
  CADASTRE_ENVIRONMENTAL_SYSTEM_TYPES_URL,
  'environmental_system_type',
);

export const cadastreObjectSubjectRelationshipRequest = cadastreApi(
  CADASTRE_OBJECT_SUBJECT_RELATIONSHIPS_URL,
  'object_subject_relationship',
);

export const cadastreRestrictedAreaTypesRequest = cadastreApi(
  CADASTRE_RESTRICTED_AREA_TYPES_URL,
  'restricted_area_type',
);

export const cadastreProjectDocumentsRequest = cadastreApi(
  CADASTRE_PROJECT_DOCUMENTS_URL,
  'project_document',
);

export const cadastreWastePlacementMunicipalitiesRequest = cadastreApi(
  CADASTRE_WASTE_PLACEMENT_MUNICIPALITIES_URL,
  'waste_placement_municipality',
);

export const cadastreNearestWaterObjectsRequest = cadastreApi(
  CADASTRE_NEAREST_WATER_OBJECTS_URL,
  'nearest_water_object',
);

export const cadastreSecondaryWastesRequest = cadastreApi(
  CADASTRE_SECONDARY_WASTES_URL,
  'secondary_waste',
);

export const cadastreTechnologyWastesRequest = cadastreApi(
  CADASTRE_TECHNOLOGY_WASTES_URL,
  'technology_waste',
);

export const cadastreReportPlacingWastesRequest = cadastreApi(
  CADASTRE_REPORT_PLACING_WASTES_URL,
  'report_placing_waste',
);

export const cadastreFillingRecommendationsRequest = cadastreApi(
  CADASTRE_FILLING_RECOMMENDATIONS_URL,
  'filling_recommendation',
);

export const cadastreFeedbacksRequest = cadastreApi(
  CADASTRE_FEEDBACKS_URL,
  'feedback',
);

export const cadastreContactsRequest = cadastreApi(
  CADASTRE_CONTACTS_URL,
  'contact',
);

export const cadastreQuestionAnswersRequest = cadastreApi(
  CADASTRE_QUESTION_ANSWERS_URL,
  'question_answer',
);

export const cadastreForm1BuildingsRequest = cadastreApi(
  CADASTRE_FORM1_BUILDINGS_URL,
  'report_buildings',
);

export const cadastreForm2ReportWastesRequest = cadastreApi(
  CADASTRE_FORM2_REPORT_WASTES_URL,
  'report_wastes',
);

export const cadastreForm3ReportGenerationsRequest = cadastreApi(
  CADASTRE_FORM3_REPORT_GENERATIONS_URL,
  'report_generations',
);

export const cadastreForm4ReportApprovalStandardsRequest = cadastreApi(
  CADASTRE_FORM4_REPORT_APPROVAL_STANDARDS_URL,
  'report_approval_standards',
);

export const cadastreForm5ReportLicensesRequest = cadastreApi(
  CADASTRE_FORM5_REPORT_LICENSES_URL,
  'report_licenses',
);

export const cadastreForm6ReportActualVolumesRequest = cadastreApi(
  CADASTRE_FORM6_REPORT_ACTUAL_VOLUMES_URL,
  'report_actual_volumes',
);

export const cadastreForm7ReportActualVolumesRequest = cadastreApi(
  CADASTRE_FORM7_REPORT_TECHNOLOGIES_URL,
  'report_technologies',
);

export const cadastreForm8ReportPlacingWastesRequest = cadastreApi(
  CADASTRE_FORM8_REPORT_PLACING_WASTES_URL,
  'report_placing_wastes',
);
