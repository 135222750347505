import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { EditSharp, Report } from '@mui/icons-material';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import FAQAccordion from '../../components/FAQAccordion';
import CustomButton from '../../components/Buttons/CustomButton';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import CustomInput from '../../components/Inputs';
import { useApi, usePushNotification } from '../../hooks';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { cadastreFeedbacksRequest } from '../../api/cadastreApi';
import { METHODS_API } from '../../api/api';
import useDeleteModal from '../../hooks/useDeleteModal';
import DeleteButton from '../../components/Buttons/DeleteButton';
import { Text } from '../../components/Text';
import { useCadastreStore } from '../../store';

function AccordionTitleComponent({ questionInfo }) {
  return (
    <Text big component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {!questionInfo.answer && (
        <Report color="error" />
      )}
      <Text medium component="span" sx={{ fontWeight: 'bold' }}>
        {questionInfo.subject_attributes?.attributes?.name}
        :
      </Text>
      {' '}
      <Text medium component="span">{questionInfo.message}</Text>
    </Text>
  );
}

export default function CadastrAdminFeedback() {
  const { cadastreStore: { questions = [] }, setCadastreStore } = useCadastreStore();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState({});
  const pushNotification = usePushNotification();

  const { defaultRequest: feedbackApi } = useApi({
    request: cadastreFeedbacksRequest,
  });

  const getFeedbacks = () => feedbackApi().then((resQuestions) => {
    setCadastreStore({ questions: resQuestions });
  });

  const openModal = (item) => {
    setSelectedQuestion(item);
    setAnswer(item.answer || '');
  };

  const closeModal = () => {
    setAnswer('');
    setSelectedQuestion(null);
  };

  const changeAnswer = (e, value) => setAnswer(value);

  const sendFeedback = async () => {
    let hasErrors = false;
    let errorAnswer = '';

    if (!answer) {
      hasErrors = true;
      errorAnswer = HAS_REQUIRED_FIELDS_TEXT;
    }

    setError({
      answer: errorAnswer,
    });

    if (hasErrors) return;

    const updatedQuestion = await feedbackApi({
      method: METHODS_API.PUT,
      data: {
        answer,
      },
      id: selectedQuestion.id,
    });

    if (updatedQuestion?.id) {
      getFeedbacks();
      pushNotification({ message: 'Данные вопроса обновлены', variant: 'success' });
      closeModal();
    } else {
      pushNotification({ message: 'Данные вопроса не удалось обновить' });
    }
  };

  const { makeRequest, isLoading } = useApi({
    request: sendFeedback,
  });

  const openDeleteModal = useDeleteModal();

  const deleteFeedback = async (currentQuestion) => {
    const deletedFeedback = await feedbackApi({ id: currentQuestion.id, method: METHODS_API.DELETE });

    if (deletedFeedback?.id) {
      getFeedbacks();
      pushNotification({ message: 'Вопрос успешно удалён', variant: 'success' });
      closeModal();
    } else {
      pushNotification({ message: 'Вопрос не удалось удалить' });
    }
  };

  const onDeleteFeedback = (currentQuestion) => {
    openDeleteModal({
      objectName: `(вопрос ${currentQuestion.message})`,
      deleteItem: () => deleteFeedback(currentQuestion),
      id: currentQuestion.id,
    });
  };

  const getItem = useCallback((item) => (
    <Box sx={flexGap2}>
      <CustomButton onClick={() => openModal(item)} startIcon={<EditSharp />} sx={{ width: 'fit-content', mt: 3 }}>
        Добавить/Изменить ответ
      </CustomButton>
      <DeleteButton sx={{ width: 'fit-content' }} onClick={() => onDeleteFeedback(item)}>Удалить вопрос</DeleteButton>
    </Box>
  ), []);

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <ModalWithTitle
        open={Boolean(selectedQuestion)}
        isLoading={isLoading}
        close={closeModal}
        title={selectedQuestion?.question}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <CustomInput label="Ответ" multiline value={answer} onChange={changeAnswer} error={error.answer} />
          <CustomButton onClick={makeRequest}>Сохранить ответ</CustomButton>
        </Box>
      </ModalWithTitle>
      <FAQAccordion
        getTitle={AccordionTitleComponent}
        fields={{ question: 'message', answer: 'answer' }}
        faqs={questions}
        getItem={getItem}
      />
    </Box>
  );
}
