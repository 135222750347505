import { Route } from 'react-router-dom';
import React from 'react';
import {
  CADASTR_ADMIN_CONTACT_INFO_ROUTE,
  CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE,
  CADASTR_ADMIN_FAQ_ROUTE,
  CADASTR_ADMIN_FEEDBACK_ROUTE,
  CADASTR_ADMIN_NORMATIVE_BASE_ROUTE,
  CADASTR_ADMIN_REPORTS_ROUTE,
} from '../routes';

import CadastrAdminReportsPage from '../../pages/CadastrAdmin/CadastrAdminReportsPage';
import CadastreCommonRecommendations from '../../components/Cadastr/CadastreCommonRecommendations';
import CadastrAdminFeedback from '../../pages/CadastrAdmin/CadastrAdminFeedback';
import CadastrAdminNormativeBase from '../../pages/CadastrAdmin/CadastrAdminNormativeBase';
import CadastrAdminContactInfo from '../../pages/CadastrAdmin/CadastrAdminContactInfo';
import CadastrAdminFAQ from '../../pages/CadastrAdmin/CadastrAdminFAQ';
import { checkIsPrivateOrigin } from '../../helpers/browserLocation';

export const getAdminCadastreRoutes = (hasPrivilege) => !checkIsPrivateOrigin() || (!hasPrivilege('admin_cadastre') ? null : (
  <>
    <Route
      exact
      path={CADASTR_ADMIN_REPORTS_ROUTE}
      element={<CadastrAdminReportsPage />}
    />
    <Route
      exact
      path={CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE}
      element={<CadastreCommonRecommendations isAdmin />}
    />
    <Route
      exact
      path={CADASTR_ADMIN_FEEDBACK_ROUTE}
      element={<CadastrAdminFeedback />}
    />
    <Route
      exact
      path={CADASTR_ADMIN_NORMATIVE_BASE_ROUTE}
      element={<CadastrAdminNormativeBase />}
    />
    <Route
      exact
      path={CADASTR_ADMIN_CONTACT_INFO_ROUTE}
      element={<CadastrAdminContactInfo />}
    />
    <Route
      exact
      path={CADASTR_ADMIN_FAQ_ROUTE}
      element={<CadastrAdminFAQ />}
    />
  </>
));
