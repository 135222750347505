import { formsPercentGetter } from '../../constants/lkCadastr/cadastrStorage';
import { formatEnumToSelectOption } from '../entityHelper';

export default function cadastreFormsPrepareFrontend({
  setCompletedForms, forms, excludeLicense, errorSetter,
}) {
  const formsArray = ['form1', 'form2', 'form3', 'form4', 'form5', 'form6', 'form7', 'form8'];

  const newCompletedForms = {};

  formsArray.forEach((formName) => {
    const completePercent = formsPercentGetter[formName](forms, {
      ignoreLicense: excludeLicense,
      errorSetter,
    });

    newCompletedForms[formName] = completePercent;
  });

  if (setCompletedForms) setCompletedForms(newCompletedForms);

  return newCompletedForms;
}

export const filterActivityType = (options) => options.filter((activity) => !['Управление', 'Перегрузка'].includes(activity.name));

export const formatActivityOptions = (options) => formatEnumToSelectOption(
  filterActivityType(options),
);
