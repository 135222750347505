export const ADDRESSES_URL = '/addresses';
export const ADDRESS_BY_COORDINATES = `${ADDRESSES_URL}/by_coordinates`;
export const ADDRESSES_CITIES_URL = `${ADDRESSES_URL}/cities`;
export const ADDRESSES_DISTRICTS_URL = `${ADDRESSES_URL}/districts`;
export const REGISTRATION_SUBJECT_URL = '/subjects';
export const IMPORTS_URL = '/imports';
export const EXPORTS_SUBJECT_URL = '/exports';
export const REPORTS_GENERATE_URL = '/reports';
export const OKTMOS_URL = '/oktmos';

export const SIGN_IN_ADMIN_URL = '/users/sign_in';
export const SIGN_IN_USER_URL = '/users/sign_in';
export const SIGN_OUT_USER_URL = '/users/sign_out';

export const COLUMN_URL = '/columns';

export const SIGN_IN_SUBJECT_URL = '/subjects/sign_in';
export const SIGN_OUT_SUBJECT_URL = '/subjects/sign_out';

export const SIGN_IN_COMITTEE_URL = '/users/sign_in';

export const MEMBER_URL = '/member';
export const SUBJECTS_URL = '/subjects';
export const CHECK_BY_INN_URL = '/check_by_inn';
export const UPDATE_EMAIL_BY_INN_URL = '/update_email_by_inn';
export const INFO_BY_INN_URL = '/info_by_inn';
export const FORGET_PASSWORD_URL = '/forget';
export const RESET_PASSWORD_URL = '/reset';
export const EMAIL_PASSWORD_URL = '/update_email_password';
export const ENUMS_URL = '/enums';
export const SMAV_URL = '/SMAV';
export const WASTE_TYPES_URL = '/waste_types';
export const WASTE_MANAGEMENT_TYPES_URL = '/waste_management_types';
export const ENTERPRISE_ACTIVITY_TYPES_URL = '/enterprise_activity_types';
export const OBJECTIVES_URL = '/objectives';
export const SEARCH_BY_AREA_URL = '/search_by_area';
export const COMMITTEES_URL = '/committees';
export const CONTROL_METHODS_URL = '/control_method';
export const USERS_URL = '/users';
export const ROLES_URL = '/roles';
export const PRIVILEGE_ROLES_URL = '/privilege_roles';
export const DESTROY_FEW = '/destroy_few';
export const PRIVILEGES_URL = '/privileges';
export const VEHICLES_URL = '/vehicles';
export const EMAILS_URL = '/emails';
export const SUBSCRIPTIONS_URL = '/newsletters';
export const NOTIFICATIONS_URL = '/notifications';
export const OPERATIONS_URL = '/operations';
export const OPERATION_TYPES_URL = '/operation_types';
export const VEHICLE_POSITIONS_URL = '/car_positions';
export const RNIC_PROXY_POSITIONS_URL = '/ernic';
export const VEHICLE_LAST_POSITIONS_URL = '/car_positions/last_positions';
export const SEARCH_BY_POLYGON_URL = `${VEHICLE_POSITIONS_URL}/search_by_polygon`;
export const SEARCH_BY_POLYGON_IN_OUT_URL = `${VEHICLE_POSITIONS_URL}/polygon_in_out`;
export const APPLICATIONS_URL = '/applications';
export const APPLICATION_OBJECTIVES_URL = '/application_objectives';
export const APPLICATIONS_TER_SCHEME_URL = '/applications/ter_scheme';
export const APPLICATION_URL = '/application';
export const VIOLATIONS_URL = '/violations';
export const IMPORT_VIOLATIONS_URL = '/import_violations';
export const DEVIATIONS = 'deviations';
export const ROUTE_DEVIATIONS_URL = `/route_${DEVIATIONS}`;
export const PARKING_DEVIATIONS_URL = `/parking_${DEVIATIONS}`;
export const CONCENTRATION_POINTS_URL = '/concentration_points';
export const CADASTRE_URL = '/cadastre';
export const CADASTRE_REPORT_BUILDINGS_URL = `${CADASTRE_URL}/report_buildings`;
export const GENERATE_TRANSMITTAL_MAIL_URL = '/generate_transmittal_mail';
export const CADASTRE_REPORT_WASTES_URL = `${CADASTRE_URL}/report_wastes`;
export const CADASTRE_INDUSTRIAL_AREAS_URL = `${CADASTRE_URL}/industrial_areas`;
export const CADASTRE_REPORT_LICENSES_URL = `${CADASTRE_URL}/report_licenses`;
export const CADASTRE_REPORT_COMPONENT_COMPOSITION_WASTES_URL = `${CADASTRE_URL}/component_composition_wastes`;
export const CADASTRE_REPORT_GENERATIONS_URL = `${CADASTRE_URL}/report_generations`;
export const CADASTRE_WASTE_TRANSFERS_URL = '/waste_transfers';
export const CADASTRE_REPORT_APPROVAL_STANDARDS_URL = `${CADASTRE_URL}/report_approval_standards`;
export const CADASTRE_LIMIT_WASTES_URL = `${CADASTRE_URL}/limit_wastes`;
export const CADASTRE_APPROVAL_STANDARD_WASTES_URL = `${CADASTRE_URL}/approval_standard_wastes`;
export const CADASTRE_REPORT_ACTUAL_VOLUMES_URL = `${CADASTRE_URL}/report_actual_volumes`;
export const CADASTRE_REPORT_TECHNOLOGIES_URL = `${CADASTRE_URL}/report_technologies`;
export const CADASTRE_REPORT_TECHNOLOGIES_IMPORT_URL = `${IMPORTS_URL}/create_cadastre`;
export const CADASTRE_INSTALLATIONS_URL = `${CADASTRE_URL}/installations`;
export const CADASTRE_PLACING_OBJECTS_URL = `${CADASTRE_URL}/placing_objects`;
export const CADASTRE_ENVIRONMENT_MONITORING_TYPES_URL = `${CADASTRE_URL}/environmental_monitoring_types`;
export const CADASTRE_AREA_DOCUMENT_URL = `${CADASTRE_URL}/area_documents`;
export const CADASTRE_NEAREST_TOWNS_URL = `${CADASTRE_URL}/nearest_towns`;
export const CADASTRE_OBJECT_SUBJECT_RELATIONSHIPS_URL = `${CADASTRE_URL}/object_subject_relationships`;
export const CADASTRE_RESTRICTED_AREA_TYPES_URL = `${CADASTRE_URL}/restricted_area_types`;
export const CADASTRE_ENVIRONMENTAL_SYSTEM_TYPES_URL = `${CADASTRE_URL}/environmental_system_types`;
export const CADASTRE_PROJECT_DOCUMENTS_URL = `${CADASTRE_URL}/project_documents`;
export const CADASTRE_WASTE_PLACEMENT_MUNICIPALITIES_URL = `${CADASTRE_URL}/waste_placement_municipalities`;
export const CADASTRE_NEAREST_WATER_OBJECTS_URL = `${CADASTRE_URL}/nearest_water_objects`;
export const CADASTRE_OBJECT_RIGHT_TYPES_URL = `${CADASTRE_URL}/object_right_types`;
export const CADASTRE_MONITORING_TYPES_URL = `${CADASTRE_URL}/monitoring_types`;
export const CADASTRE_TECHNOLOGIES_URL = `${CADASTRE_URL}/technologies`;
export const CADASTRE_MANUFACTURED_PRODUCTS_URL = `${CADASTRE_URL}/manufactured_products`;
export const CADASTRE_TECHNOLOGY_OBJECTS_URL = `${CADASTRE_URL}/technology_objects`;
export const CADASTRE_PLACES_WASTES_URL = `${CADASTRE_URL}/placed_wastes`;
export const CADASTRE_SECONDARY_WASTES_URL = `${CADASTRE_URL}/secondary_wastes`;
export const CADASTRE_TECHNOLOGY_WASTES_URL = `${CADASTRE_URL}/technology_wastes`;
export const CADASTRE_REPORT_PLACING_WASTES_URL = `${CADASTRE_URL}/report_placing_wastes`;
export const CADASTRE_FILLING_RECOMMENDATIONS_URL = `${CADASTRE_URL}/filling_recommendations`;
export const CADASTRE_FEEDBACKS_URL = `${CADASTRE_URL}/feedbacks`;
export const CADASTRE_CONTACTS_URL = `${CADASTRE_URL}/contacts`;
export const CADASTRE_QUESTION_ANSWERS_URL = `${CADASTRE_URL}/question_answers`;
export const CADASTRE_FORM1_BUILDINGS_URL = `${CADASTRE_URL}/forms/buildings`;
export const CADASTRE_FORM2_REPORT_WASTES_URL = `${CADASTRE_URL}/forms/wastes`;
export const CADASTRE_FORM3_REPORT_GENERATIONS_URL = `${CADASTRE_URL}/forms/report_generations`;
export const CADASTRE_FORM4_REPORT_APPROVAL_STANDARDS_URL = `${CADASTRE_URL}/forms/report_approval_standards`;
export const CADASTRE_FORM5_REPORT_LICENSES_URL = `${CADASTRE_URL}/forms/licenses`;
export const CADASTRE_FORM6_REPORT_ACTUAL_VOLUMES_URL = `${CADASTRE_URL}/forms/actual_volumes`;
export const CADASTRE_FORM7_REPORT_TECHNOLOGIES_URL = `${CADASTRE_URL}/forms/report_technologies`;
export const CADASTRE_FORM8_REPORT_PLACING_WASTES_URL = `${CADASTRE_URL}/forms/report_placing_wastes`;
export const CADASTRE_TRANSPORTED_WASTES_URL = `${CADASTRE_URL}/transported_wastes`;
export const CADASTRE_REPORT_HISTORIES_URL = `${CADASTRE_URL}/report_histories`;
export const CADASTRE_REPORT_STATUSES_URL = `${CADASTRE_URL}/report_statuses`;
export const CADASTRE_AREAS_URL = `${CADASTRE_URL}/areas`;
export const CADASTRE_WASTE_URL = `${CADASTRE_URL}/wastes`;
export const CADASTRE_CONTAINERS_URL = `${CADASTRE_URL}/containers`;
export const CADASTRE_DUPLICATE_PREVIOUS_REPORT_URL = '/duplicate_by_previous';

export const PETROSOFT_REPORT_URL = '/getReport';

export const PETROSOFT_BEARER_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzNSIsInNjcCI6InVzZXIiLCJhdWQiOm51bGwsImlhdCI6MTY5ODE0NTAwMCwiZXhwIjoiMTcwMzMyOTAwMCIsImp0aSI6IjE0NGNkYjUxLWFlNDYtNDU3Yy1iODE5LTg3ZTQ4MDg4NDFkMyJ9.zjKxdT0pz2NMZU3YnWE86fK2sItduuqAFNyvC-J6sqM';
