import axios from 'axios';
import api from './api';
import { ADDRESSES_URL } from '../constants/urls';
import { formatAddressString } from '../helpers/stringHelper';
import { checkIsPublicRKO47Origin } from '../helpers/browserLocation';

export const getAddressesRequest = async (params) => api({ url: `${ADDRESSES_URL}`, params })
  .then((json) => json?.data?.data?.map?.((address) => ({
    ...address.attributes,
    id: address.id,
    value: address.id,
    label: formatAddressString(address.attributes),
  })));

export const getAddressesDistrictsRequest = async (params) => api({ url: `${ADDRESSES_URL}/districts`, params })
  .then((json) => {
    if (!json?.data?.map) return;

    const uniqDistricts = new Map(
      json?.data?.map((object) => [object.district, object.district]),
    );

    return Array.from(uniqDistricts).map((districtArray) => ({
      id: districtArray[0],
      label: districtArray[0],
      value: districtArray[0],
    }));
  });

// const mainPetrosoftUrl = 'http://192.168.25.151:8080';
// const mainPublicPetrosoftUrl = 'http://192.168.76.43:220';
const secondPetrosoftUrl = 'http://192.168.25.147:3333';

export const getGeoServerData = async () => axios.get(checkIsPublicRKO47Origin() ? 'http://192.168.76.43:220/geoserver/wfs?service=wfs&versionf=1.1.0&request=GetFeature&typeNames=rko:regions&outputFormat=application/json' : `${secondPetrosoftUrl}/geoserver/wfs?service=wfs&versionf=1.1.0&request=GetFeature&typeNames=rko:regions&outputFormat=application/json`);
