import React, { useEffect } from 'react';
import {
  Box, Pagination, Stack,
} from '@mui/material';
import { useTableStore } from '../store';
import initialTableStore from '../store/tableStore';
import { TextTitle } from './Text';
import {
  BACKGROUND_LIGHT, BUTTON_BLUE, TEXT_MAIN, TEXT_TITLE,
} from '../constants/colors';

const paginationSx = {
  'button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeLarge.MuiPaginationItem-text.MuiPaginationItem-rounded.MuiPaginationItem-page': {
    background: BACKGROUND_LIGHT,
    '&.Mui-selected': {
      background: BUTTON_BLUE,
      color: 'white',
    },
    color: TEXT_MAIN,
  },
  '.MuiPaginationItem-root': {
    minWidth: '34px',
    minHeight: '31px',
    height: '31px',
  },
};

export default function CustomPagination({
  perPage, setPerPage, page, setPage, total, perPageOptions = [10, 20, 30, 40],
}) {
  const { tableStore, setTableStore } = useTableStore();

  const mainPerPage = perPage || tableStore.perPage || perPageOptions[0];
  const mainPage = page ?? tableStore.page;
  const mainTotal = total || tableStore.meta?.total || 1;

  const changePage = (e, value) => setTableStore({ page: value });

  const changePerPage = (e, value) => setTableStore({ perPage: value, page: 1 });

  const mainSetPage = setPage || changePage;
  const setMainPerPage = setPerPage || changePerPage;

  useEffect(() => () => {
    if (total || perPage || page) return;

    setTableStore(initialTableStore);
    /* eslint-disable-next-line */
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '8px 0',
        gap: '10px',
      }}
    >
      <Pagination
        page={mainPage}
        onChange={mainSetPage}
        size="large"
        count={Math.ceil(mainTotal / (perPage === null ? perPageOptions.at(-1) : mainPerPage))}
        sx={paginationSx}
        shape="rounded"
      />
      {perPage !== null && setMainPerPage && (
        <Box pr="16px">
          <Stack direction="row" alignItems="center" gap={2}>
            <TextTitle sx={{ fontWeight: 'bold' }} component="span">Показывать по:</TextTitle>
            {perPageOptions.map((count) => (
              <TextTitle
                key={count}
                onClick={(e) => setMainPerPage(e, count)}
                sx={{ color: mainPerPage === count ? TEXT_TITLE : BUTTON_BLUE, cursor: 'pointer' }}
                variant="text"
              >
                {count}
              </TextTitle>
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
