import { compareId } from '../../helpers';
import { addTotalCadastreCount } from '../../helpers/cadastreForms/getFormField';

const transportedWasteField = [
  { name: 'waste_type_id', valid: (waste_type_id) => waste_type_id?.id || compareId(waste_type_id?.name, 0) },
  { name: 'waste_management_type_ids', valid: (waste_management_type_ids, { waste_type_id }) => waste_management_type_ids?.[0]?.id || compareId(waste_type_id?.name, 0) },
  'actual_volume_transported_waste',
];

export const getInitialForm6TransportedWaste = () => ({
  randId: Math.random(),
  waste_type_id: '',
  actual_volume_transported_waste: '',
  waste_management_type_ids: [],
});

export const FORM6_TRANSPORTED_WASTE = 'transportedWastes';

const actualVolumeField = [
  { name: 'transfer_document_number', valid: (transfer_document_number, { transfer_subject_id }) => transfer_document_number || compareId(transfer_subject_id?.name, 0) },
  { name: 'transfer_document_date', valid: (transfer_document_date, { transfer_subject_id }) => transfer_document_date || compareId(transfer_subject_id?.name, 0) },
  { name: 'transfer_subject_id', valid: (transfer_subject_id) => transfer_subject_id?.id || compareId(transfer_subject_id?.name, 0) },
  { name: 'accept_subject_id', valid: (accept_subject_id) => accept_subject_id?.id || compareId(accept_subject_id?.name, 0) },
  { name: 'accept_document_number', valid: (accept_document_number, { accept_subject_id }) => accept_document_number || compareId(accept_subject_id?.name, 0) },
  { name: 'accept_document_date', valid: (accept_document_date, { accept_subject_id }) => accept_document_date || compareId(accept_subject_id?.name, 0) },
];

export const getInitialForm6ActualVolume = () => ({
  randId: Math.random(),
  transfer_subject_id: '',
  transfer_document_number: '',
  transfer_document_date: '',
  [FORM6_TRANSPORTED_WASTE]: [getInitialForm6TransportedWaste()],
  objective_id: '',
  accept_subject_id: '',
  accept_document_number: '',
  accept_document_date: '',
});

export const getInitialForm6 = () => ({
  actualVolumes: [getInitialForm6ActualVolume()],
});

export const form6PercentGetter = (forms, { errorSetter }) => {
  const { actualVolumes } = forms.form6;

  let allDataLength = 0;
  let allFilledData = 0;

  const addTransportedWasteCount = (count) => {
    allDataLength += count;
  };
  const addFilledCount = () => {
    allFilledData += 1;
  };

  const newErrors = [];

  actualVolumes.forEach((actualVolume) => {
    addTransportedWasteCount(actualVolumeField.length);
    addTotalCadastreCount(actualVolumeField, actualVolume, addFilledCount, { errorSetter: () => newErrors.push(`actualVolume${actualVolume.randId}`) });

    actualVolume.transportedWastes.forEach((transportedWaste) => {
      addTransportedWasteCount(transportedWasteField.length);
      addTotalCadastreCount(transportedWasteField, transportedWaste, addFilledCount, { errorSetter: () => newErrors.push(`transportedWaste${actualVolume.randId}${transportedWaste.randId}`) });
    });
  });

  if (allDataLength === 0) return 0;

  errorSetter({ errorForm6: newErrors });

  return Math.floor((allFilledData / allDataLength) * 100);
};
