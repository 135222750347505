// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AddCard } from '@mui/icons-material';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import FAQAccordion from '../../components/FAQAccordion';
import CustomButton from '../../components/Buttons/CustomButton';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import CustomInput from '../../components/Inputs';
import { useApi, usePushNotification } from '../../hooks';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { cadastreFeedbacksRequest } from '../../api/cadastreApi';
import { METHODS_API } from '../../api/api';
import { useUserStore } from '../../store';
import { compareId } from '../../helpers';

export default function CadastrFeedback() {
  const [questions, setQuestions] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [error, setError] = useState('');
  const pushNotification = usePushNotification();
  const { userStore } = useUserStore();

  const { defaultRequest: feedbackApi } = useApi({
    request: cadastreFeedbacksRequest,
  });

  const getFeedbacks = () => feedbackApi({ params: { total: 1000, subject_id: userStore.id } }).then((resQuestions) => {
    // todo no backend
    const newQuestions = resQuestions.filter((item) => compareId(item.subject_id, userStore.id));
    setQuestions(newQuestions);
  });

  useEffect(() => {
    if (userStore.id) getFeedbacks();
  }, [userStore.id]);

  const openModal = () => setIsModal(true);

  const closeModal = () => {
    setNewQuestion('');
    setIsModal(false);
  };

  const changeNewQuestion = (e, value) => setNewQuestion(value);

  const sendQuestion = async () => {
    if (!newQuestion) return setError(HAS_REQUIRED_FIELDS_TEXT);

    const newQuestionResponse = await feedbackApi({
      method: METHODS_API.POST,
      data: {
        message: newQuestion,
        subject_id: userStore.id,
      },
    });

    if (newQuestionResponse?.id) {
      getFeedbacks();
      pushNotification({ message: 'Вопрос отправлен', variant: 'success' });
      closeModal();
    } else {
      pushNotification({ message: 'Не удалось отправить вопрос' });
    }
  };

  const { makeRequest, isLoading } = useApi({
    request: sendQuestion,
  });

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <ModalWithTitle
        open={isModal}
        isLoading={isLoading}
        close={closeModal}
        subTitleProps={{
          sx: { fontWeight: 'initial' },
          h4: true,
        }}
        title="Новый вопрос"
        subTitle="Здесь Вы можете задать свой вопрос или оставить контактный номер телефона для связи с сотрудником."
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <CustomInput multiline value={newQuestion} onChange={changeNewQuestion} error={error} />
          <CustomButton onClick={makeRequest}>Отправить вопрос</CustomButton>
        </Box>
      </ModalWithTitle>
      <CustomButton onClick={openModal} startIcon={<AddCard />} sx={{ width: 'fit-content' }}>
        Добавить вопрос
      </CustomButton>
      <FAQAccordion
        fields={{
          question: 'message', answer: 'answer', created_at: 'created_at', updated_at: 'updated_at',
        }}
        faqs={questions}
      />
    </Box>
  );
}
