export const readToken = () => {
  try {
    return localStorage.getItem('token');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
};
export const writeToken = (response) => localStorage.setItem('token', response);
export const deleteToken = () => localStorage.removeItem('token');

export const writeLastRoute = () => localStorage.setItem('lastRoute', window.location.pathname);
export const readLastRoute = () => localStorage.getItem('lastRoute');
export const removeLastRoute = () => localStorage.removeItem('lastRoute');

export const copyTextToClipboard = async (text) => {
  try {
    await window?.navigator?.clipboard?.writeText?.(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};
