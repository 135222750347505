import React from 'react';
import { Text } from '../Text';
import { getCompleteColor } from '../../constants/lkCadastr/cadastrStorage';

export function CellStatus({ complete }) {
  return (
    <Text component="span" sx={{ fontWeight: 'bold', color: getCompleteColor(complete) }}>
      {complete || 0}
      %
    </Text>
  );
}
