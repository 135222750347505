const LOCAL_HOST = 'localhost';
const CARRIER_ORIGINS = ['control-waste.lenobl.ru']; // carrier public origin
const PUBLIC_PROF_IT_ORIGIN = 'rko.47rnic.ru'; // prof IT
const PUBLIC_CADASTRE_ORIGIN = 'rko.lenreg.ru'; // cadastre public origin
const PUBLIC_CADASTRE_WASTE_ORIGIN = 'cadastre-waste.lenobl.ru'; // cadastre public origin
const PUBLIC_CADASTRE_ORIGINS = [PUBLIC_CADASTRE_ORIGIN, PUBLIC_CADASTRE_WASTE_ORIGIN, '192.168.20.156'];
export const PRIVATE_ORIGINS = [LOCAL_HOST, 'http://192.168.76.40', 'http://192.168.25.147', PUBLIC_PROF_IT_ORIGIN, PUBLIC_CADASTRE_ORIGIN, 'http://109.195.86.203'];
export const STAGING_ORIGINS = [LOCAL_HOST, 'http://192.168.76.40', PUBLIC_PROF_IT_ORIGIN];

export const checkIsPrivateOrigin = () => PRIVATE_ORIGINS.some((privateOrigin) => window?.location?.origin.includes(privateOrigin));
export const checkIsControlOrigin = () => CARRIER_ORIGINS.some((controlOrigin) => window?.location?.origin.includes(controlOrigin));
export const checkIsPrivateCadastreOrigin = () => [PUBLIC_CADASTRE_WASTE_ORIGIN].some((privateCadastreOrigin) => window?.location?.origin.includes(privateCadastreOrigin));
export const checkIsPublicRKO47Origin = () => STAGING_ORIGINS.some((stagingOrigin) => window?.location?.origin.includes(stagingOrigin));
export const checkIsPublicCadastreOrigin = () => PUBLIC_CADASTRE_ORIGINS.some((route) => window?.location?.origin.includes(route));
