import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function CustomSwitch({
  label, onChange, value, name, Icon, sx,
}) {
  return (
    <FormGroup sx={{
      display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 2, ...sx,
    }}
    >
      {Icon}
      <FormControlLabel
        sx={{ fontSize: 14 }}
        control={(
          <Switch
            value={value}
            name={name}
            onChange={(event) => onChange(event, event.target.checked)}
            checked={value}
          />
      )}
        label={label}
      />
    </FormGroup>
  );
}
