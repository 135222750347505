import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import AdminSideNav from './AdminSideNav';
import { useUserStore } from '../store';
import { PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE } from '../constants/routes';

export default function BarMenu() {
  const { userStore } = useUserStore();
  const { pathname } = useLocation();

  if (!userStore.id) return null;

  const publicRoutes = pathname.includes('/public') || pathname.includes(PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE);

  if (publicRoutes) return null;

  return (
    <>
      <Header />
      {userStore?.type !== 'subject' && <AdminSideNav />}
    </>
  );
}
