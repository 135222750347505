import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextMain } from '../Text';
import CustomButton from '../Buttons/CustomButton';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { useHeaderStore, useUserStore } from '../../store';
import { usePushNotification } from '../../hooks';

export default function HeaderLinks({ links, sx }) {
  const { userStore } = useUserStore();
  const { setHeaderStore } = useHeaderStore();
  const pushNotification = usePushNotification();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isMobileTablet } = useCustomMediaQuery();

  if (!links) return null;

  const changeRoute = (href) => {
    if (isMobileTablet) setHeaderStore({ visibleHeader: false });
    navigate(href);
  };

  return (
    <Box
      display="flex"
      alignItems={isMobileTablet ? 'flex-start' : 'center'}
      gap={3}
      flexDirection={isMobileTablet && 'column'}
      sx={sx}
    >
      {
        links.map(({
          title, href, icon, onClick,
        }) => {
          const isActiveLink = pathname === href;

          return (
            <CustomButton
              variant="info"
              sx={{
                borderRadius: 0,
                whiteSpace: 'nowrap',
                opacity: isActiveLink ? 1 : 0.7,
                pl: isMobileTablet ? 1 : 2,
                path: { fill: !isActiveLink && 'gray' },
              }}
              key={title}
              startIcon={icon}
              onClick={() => {
                if (onClick) {
                  return onClick({
                    userStore, changeRoute, href, pushNotification,
                  });
                }
                changeRoute(href);
              }}
            >
              <TextMain medium>{title}</TextMain>
            </CustomButton>
          );
        })
      }
    </Box>
  );
}
