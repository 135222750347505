import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useLocation } from 'react-router-dom';
import { COMMITTEE_ANALYTICS, COMMITTEE_ROUTES } from '../constants/routes';
import { ReactComponent as LogoIcon } from '../assets/logo.svg';
import HeaderLinks from './Header/HeaderLinks';
import HeaderUser from './Header/HeaderUser';
import { useUserStore } from '../store';
import { CARRIER_LINKS } from '../constants';
import { COMMITTEE_STAFF_LINKS } from '../constants/headerConstants';
import useCustomMediaQuery from '../hooks/useCustomMediaQuery';

export default function BottomSideMenu() {
  const { pathname } = useLocation();
  const { userStore } = useUserStore();
  const [isOpen, setIsOpen] = useState(false);

  const getCurrentUserLinks = () => {
    if (userStore.type === 'subject') {
      return CARRIER_LINKS;
    }
  };

  const getCurrentStaffLinks = () => {
    const isInspector = userStore?.role_attributes?.attributes?.code === 'inspector';

    const committeeRoutes = isInspector
      ? COMMITTEE_ROUTES.filter((committeeRoute) => committeeRoute !== COMMITTEE_ANALYTICS)
      : COMMITTEE_ROUTES;

    if (committeeRoutes.includes(pathname)) {
      if (isInspector) {
        return COMMITTEE_STAFF_LINKS
          .filter((committeeLink) => committeeLink.href !== COMMITTEE_ANALYTICS);
      }
      return COMMITTEE_STAFF_LINKS;
    }
  };

  const { isMobileTablet } = useCustomMediaQuery();

  if (!isMobileTablet) return null;

  const toggleDrawer = (open) => (event) => {
    if (
      event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          px={isMobileTablet ? 0 : 3}
          py={1}
          display="flex"
          alignItems={isMobileTablet ? 'flex-start' : 'center'}
          justifyContent="space-between"
          flexDirection={isMobileTablet && 'column'}
          overflow="auto"
        >
          <Box
            display="flex"
            width={isMobileTablet ? '100%' : 'initial'}
            alignItems={isMobileTablet ? 'flex-start' : 'center'}
            gap={3}
            flexDirection={isMobileTablet && 'column'}
          >
            <Box display="flex" mx="auto">
              <LogoIcon />
            </Box>
            <HeaderLinks links={getCurrentStaffLinks()} />
            <HeaderLinks links={getCurrentUserLinks()} />
          </Box>
          <HeaderUser />
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
