import AnalyticsIcon from '@mui/icons-material/Analytics';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import GroupIcon from '@mui/icons-material/Group';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import MapIcon from '@mui/icons-material/Map';
import SubjectIcon from '@mui/icons-material/Subject';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import {
  Box,
  Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DRAWER_WIDTH } from '../constants';
import {
  ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE,
  ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE,
  ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE,
  ADMIN_FKKO_GUIDE,
  ADMIN_HAZARD_CLASSES_GUIDE,
  ADMIN_NOTIFICATIONS_ROUTE,
  ADMIN_OKTMO_GUIDE,
  ADMIN_OPERATIONS_ROUTE,
  ADMIN_PREMISES_APPOINTMENT_GUIDE,
  ADMIN_REGISTRY_APPLICATIONS,
  ADMIN_REGISTRY_OBJECTS_ROUTE,
  ADMIN_REGISTRY_SUBJECTS_ROUTE,
  ADMIN_REGISTRY_TS,
  ADMIN_SEND_EMAIL_ROUTE,
  ADMIN_SUBJECT_TYPE_PAGE, ADMIN_SUBSCRIPTIONS_ROUTE,
  ADMIN_TER_SCHEME_ROUTE,
  ADMIN_TYPE_ENTERPRISE_GUIDE,
  ADMIN_USERS_ROLES_ROUTE,
  ADMIN_WASTE_CADASTRE_ROUTE,
  ADMIN_WASTE_CONDITION_ROUTE,
  ADMIN_WASTE_MANAGEMENT_GUIDE,
  CADASTR_ADMIN_REPORTS_ROUTE,
  CADASTR_ADMIN_ROUTE,
  COMMITTEE_ROUTE,
  COMMITTEE_WASTE_CARRIERS,
} from '../constants/routes';
import { useAdminRole } from '../hooks/useAdminRole';
import { useChangeSideDrawer } from '../hooks/useChangeSideDrawer';
import useCustomMediaQuery from '../hooks/useCustomMediaQuery';
import { usePrivilegeRole } from '../hooks/usePrivilegeRole';
import CustomNestedList from './CustomNestedList';
import HeaderUser from './Header/HeaderUser';
import { Text } from './Text';

const subListsGuide = [
  {
    subTitle: 'Виды обращения с отходами',
    href: ADMIN_WASTE_MANAGEMENT_GUIDE,
  },
  {
    subTitle: 'Виды предприятий',
    href: ADMIN_TYPE_ENTERPRISE_GUIDE,
  },
  {
    subTitle: 'Классы опасности',
    href: ADMIN_HAZARD_CLASSES_GUIDE,
  },
  {
    subTitle: 'Виды деятельности субъектов',
    href: ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE,
  },
  {
    subTitle: 'Типы субъектов',
    href: ADMIN_SUBJECT_TYPE_PAGE,
  },
  {
    subTitle: 'Агрегатные состояния и физические формы отходовов',
    href: ADMIN_WASTE_CONDITION_ROUTE,
  },
  {
    subTitle: 'Назначения помещений',
    href: ADMIN_PREMISES_APPOINTMENT_GUIDE,
  },
  {
    subTitle: 'ОКТМО',
    href: ADMIN_OKTMO_GUIDE,
  },
  {
    subTitle: 'ФККО',
    href: ADMIN_FKKO_GUIDE,
  },
];

const subListAnalytics = [
  {
    subTitle: 'Аналитика "Кадастр отходов"',
    href: ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE,
    show: (hasPrivilege) => hasPrivilege('analyst_cadastre'),
  },
  {
    subTitle: 'Аналитика "Контроль перевозчиков строительных отходов ЛО"',
    href: ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE,
    show: (hasPrivilege) => hasPrivilege('analyst_control'),
  },
];

const menuItems = [
  {
    href: ADMIN_USERS_ROLES_ROUTE,
    title: 'Пользователи и роли',
    Icon: GroupIcon,
    show: (hasPrivilege) => hasPrivilege('roles_read_all') || hasPrivilege('users_read_all'),
  },
  {
    href: ADMIN_REGISTRY_SUBJECTS_ROUTE,
    title: 'Реестр субъектов',
    Icon: SubjectIcon,
    show: (hasPrivilege) => hasPrivilege('subjects_read_all'),
  },
  {
    href: ADMIN_REGISTRY_OBJECTS_ROUTE,
    title: 'Реестр объектов обращения с отходами',
    Icon: BusinessIcon,
    show: (hasPrivilege) => hasPrivilege('objectives_read_all'),
  },
  {
    href: ADMIN_REGISTRY_TS,
    title: 'Реестр ТС',
    Icon: DirectionsCarIcon,
    show: (hasPrivilege) => hasPrivilege('vehicles_read_all'),
  },
  {
    href: ADMIN_REGISTRY_APPLICATIONS,
    title: 'Реестр разрешений',
    Icon: DocumentScannerIcon,
    show: (hasPrivilege) => hasPrivilege('applications_read_all'),
  },
  {
    href: COMMITTEE_WASTE_CARRIERS,
    activeRoute: COMMITTEE_ROUTE,
    title: 'ЛК КГЭН',
    Icon: DocumentScannerIcon,
    show: (hasPrivilege) => hasPrivilege('lk_kgen'),
  },
  {
    href: CADASTR_ADMIN_REPORTS_ROUTE,
    activeRoute: CADASTR_ADMIN_ROUTE,
    title: 'Администрирование кадастра',
    show: (hasPrivilege) => hasPrivilege('admin_cadastre'),
    Icon: DocumentScannerIcon,
  },
  {
    id: 5,
    show: (hasPrivilege) => hasPrivilege('analyst_cadastre') || hasPrivilege('analyst_control'),
    component: (
      <CustomNestedList
        isActivePathname
        title="Аналитика"
        subLists={subListAnalytics}
        Icon={AnalyticsIcon}
      />
    ),
  },
  {
    id: 6,
    show: (hasPrivilege) => hasPrivilege('reference_information'),
    component: (
      <CustomNestedList
        isActivePathname
        title="Нормативно-справочная информация"
        subLists={subListsGuide}
        Icon={ImportContactsIcon}
      />
    ),
  },
  {
    href: ADMIN_OPERATIONS_ROUTE,
    title: 'Логирование действий в Кадастре',
    Icon: WebStoriesIcon,
    show: (_, isMainAdmin) => isMainAdmin,
  },
  {
    href: ADMIN_SEND_EMAIL_ROUTE,
    title: 'Отправка E-mail',
    Icon: ForwardToInboxIcon,
    show: (hasPrivilege) => hasPrivilege('send_mail'),
  },
  {
    title: 'Рассылки',
    href: ADMIN_SUBSCRIPTIONS_ROUTE,
    Icon: ForwardToInboxIcon,
    show: (_, isMainAdmin) => isMainAdmin,
  },
  {
    title: 'Уведомления',
    href: ADMIN_NOTIFICATIONS_ROUTE,
    Icon: ForwardToInboxIcon,
    show: (_, isMainAdmin) => isMainAdmin,
  },
  {
    href: ADMIN_WASTE_CADASTRE_ROUTE,
    title: 'Кадастр отходов',
    Icon: MapIcon,
    // todo timed hide Petrosoft admin panel
    hide: () => true,
  },
  {
    href: ADMIN_TER_SCHEME_ROUTE,
    title: 'Администрирование Терсхемы',
    Icon: MapIcon,
    show: (hasPrivilege) => hasPrivilege('admin_ter_scheme'),
  },
];

export default function AdminSideNav() {
  const [activeMenuButton, setActiveMenuButton] = useState('');
  const { toggleDrawer, isOpenDrawer } = useChangeSideDrawer();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { isUserMainAdmin } = useAdminRole();

  const hasPrivilege = usePrivilegeRole();

  useEffect(() => {
    setActiveMenuButton(pathname);
  }, [pathname]);

  const onClickMenuButton = (href) => navigate(href);

  const isOpenedDrawerWidth = isOpenDrawer ? DRAWER_WIDTH : 0;

  const { isMobileTablet } = useCustomMediaQuery();

  return (
    <Drawer
      sx={{
        width: isOpenedDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isOpenedDrawerWidth,
          boxSizing: 'border-box',
        },
        '& .css-10hburv-MuiTypography-root': {
          fontSize: '14px',
        },
      }}
      variant="persistent"
      anchor="left"
      open={isOpenDrawer}
    >
      <Toolbar sx={{ height: '98px', display: 'flex', justifyContent: 'space-between' }}>
        <Text>РКО</Text>
        <Box onClick={toggleDrawer} display="flex" alignItems="center" gap={1}>
          <ChevronLeftIcon />
          <Text>Скрыть меню</Text>
        </Box>
      </Toolbar>
      {isMobileTablet && <HeaderUser />}
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        {menuItems.map(({
          href, title, component, id, Icon, activeRoute, hide, show,
        }) => {
          if (!isUserMainAdmin && show && !show(hasPrivilege)) return null;
          if (hide && hide(hasPrivilege)) return null;
          if (component) return <React.Fragment key={id}>{component}</React.Fragment>;

          const isActiveBackground = activeMenuButton.includes(activeRoute || href);

          return (
            <ListItem key={title} disablePadding>
              <ListItemButton
                sx={{ background: isActiveBackground && '#cfecff' }}
                onClick={() => onClickMenuButton(href)}
              >
                <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
                  <Icon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
