import React from 'react';
import CustomIconButton from './CustomIconButton';
import { ReactComponent as DeleteIcon } from '../../assets/delete_icon.svg';

export default function DeleteButton({
  children, onClick, sx, name, title,
}) {
  return (
    <CustomIconButton
      sx={sx}
      Icon={DeleteIcon}
      title={title}
      onClick={onClick}
      color="error"
      name={name}
    >
      {children}
    </CustomIconButton>
  );
}
