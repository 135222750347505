import { useEffect, useState } from 'react';
import { useAddressRequest } from './useAddressRequest';
import { translateAllSubjectAttributes } from '../helpers/entityHelper';
import { useUserStore } from '../store';

export const useTranslateSubjectAttributes = ({ setter }) => {
  const { userStore } = useUserStore();
  const [addresses, setAddresses] = useState(null);
  const { isLoading } = useAddressRequest({
    setter: setAddresses,
    address_id: userStore.address_id,
    shouldRequest: true,
  });

  const translateSubject = () => {
    if (addresses && userStore.subject_type_attributes?.id !== undefined) {
      const formattedUser = translateAllSubjectAttributes(userStore, addresses);
      setter((prevState) => ({
        ...prevState,
        ...formattedUser,
      }));
    }
  };

  useEffect(() => {
    if (addresses && userStore.subject_type_attributes?.id !== undefined) {
      const formattedUser = translateAllSubjectAttributes(userStore, addresses);
      setter((prevState) => ({
        ...prevState,
        ...formattedUser,
      }));
    }
  }, [userStore, addresses, setter]);

  return { isLoading, translateSubject };
};
