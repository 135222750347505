import { Box } from '@mui/material';
import React, { useState } from 'react';
import { getVehiclesEnumsRequest } from '../../../api/vehiclesApi';
import { ReactComponent as ConnectedRINSIcon } from '../../../assets/connected_rins_icon.svg';
import { ReactComponent as NotConnectedRINSIcon } from '../../../assets/not_connected_rins_icon.svg';
import { TEXT_NO_VALUE } from '../../../constants';
import { RED } from '../../../constants/colors';
import { OWNERSHIP_TYPE_ENUM } from '../../../constants/enums';
import { useApi } from '../../../hooks';
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery';
import useVehiclePositionListener from '../../../hooks/useVehiclePositionListener';
import CloseModalButton from '../../Buttons/CloseModalButton';
import DeleteButton from '../../Buttons/DeleteButton';
import EditButton from '../../Buttons/EditButton';
import LeafletMap from '../../Map/LeafletMap';
import PermissionTable from '../../Tables/PermissionTable';
import ViolationTable from '../../Tables/ViolationTable';
import { TextMain, TextSignature, TextTitle } from '../../Text';
import TitleSubtitle from '../../TitleSubtitle';
import VehicleTrackControl from '../../VehicleTrackControl';
import CenteredModal from '../CenteredModal';

const modalContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

export default function ModalVehicleInfo({
  vehicle,
  onEdit,
  onDelete,
  close,
  hasMap = true,
}) {
  const [ownershipTypes, setOwnershipTypes] = useState([]);
  const [vehiclePosition, setVehiclePosition] = useState();
  const [vehicleTracks, setVehicleTracks] = useState([]);
  const [vehicleTrackingLoading, setVehicleTrackingLoading] = useState(false);
  const [outerMap, setOuterMap] = useState();

  const { isLoading } = useApi({
    setter: setOwnershipTypes,
    shouldRequest: true,
    request: () => getVehiclesEnumsRequest(OWNERSHIP_TYPE_ENUM),
  });

  useVehiclePositionListener({
    vehiclePosition,
    setVehiclePosition,
    guid_bnso: hasMap ? !vehicle.deleted_at && vehicle.guid_bnso : undefined,
    setLoading: setVehicleTrackingLoading,
  });

  const boundVehicle = () => {
    if ((vehiclePosition?.place) && outerMap?.fitBounds) {
      outerMap.fitBounds([
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
      ]);
    }
  };

  const {
    is_connected_rins,
    guid_bnso,
    egrz,
    model,
    ownership_type,
    subject_attributes,
    brand,
    deleted_at,
    is_inactive,
  } = vehicle;

  const { isMobileTablet } = useCustomMediaQuery();

  const mobileTabletContainerStyle = isMobileTablet ? { flexWrap: 'wrap' } : {};

  return (
    <CenteredModal
      open
      isLoading={isLoading}
      close={close}
      containerSx={{ height: '80vh', width: '70%' }}
    >
      <CloseModalButton close={close} sx={{ position: 'absolute', top: 14, right: 14 }} />
      <Box sx={{ ...modalContainerStyle, ...mobileTabletContainerStyle }}>
        <Box width={!isMobileTablet && hasMap ? '50%' : '100%'} p={3}>
          <TextTitle h2>
            Информация о ТС
          </TextTitle>
          {(deleted_at || is_inactive) && <TextSignature big sx={{ color: RED, mb: 1 }}> (В архиве)</TextSignature>}
          <Box display="flex" alignItems="flex-start" flexDirection="column" flexWrap="wrap" gap={2} mb={3}>
            {!is_inactive && onDelete && <DeleteButton onClick={onDelete}>Удалить ТС</DeleteButton>}
            {hasMap && (
              <VehicleTrackControl
                isLoading={vehicleTrackingLoading}
                boundVehicle={boundVehicle}
                setter={setVehicleTracks}
                guid_bnso={vehicle.guid_bnso}
                vehiclePosition={vehiclePosition}
              />
            )}
          </Box>
          {!deleted_at && !is_inactive && onEdit && (
            <EditButton onClick={onEdit}>Редактировать информацию о ТС</EditButton>
          )}
          <Box my={4} display="flex" alignItems="flex-start" gap={2}>
            {is_connected_rins ? <ConnectedRINSIcon /> : <NotConnectedRINSIcon />}
            <Box>
              <TextMain medium>
                ТС
                <strong>
                  {is_connected_rins ? '' : ' не'}
                  {' '}
                  подключено
                </strong>
                {' '}
                к РИНС ЛО
              </TextMain>
              <TextSignature>
                ID БНСО:
                {' '}
                {guid_bnso || TEXT_NO_VALUE}
              </TextSignature>
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" gap={2} mb={2}>
            <Box>
              <TitleSubtitle title="ЕГРЗ ТС (Номер ТС):" subTitle={egrz} sx={{ marginBottom: 2 }} />
              <TitleSubtitle
                title="Тип владения:"
                subTitle={ownershipTypes.find(({ value }) => value === ownership_type)?.label}
              />
            </Box>
            <Box>
              <TitleSubtitle title="Марка и модель ТС:" subTitle={`${brand} ${model}`} sx={{ marginBottom: 2 }} />
              <TitleSubtitle title="Перевозчик:" subTitle={subject_attributes?.attributes?.name} />
            </Box>
          </Box>
        </Box>
        {hasMap && (
          <LeafletMap
            outerMap={outerMap}
            setOuterMap={setOuterMap}
            width={isMobileTablet ? '100%' : '50%'}
            tracked_routes={vehicleTracks}
            height="calc(90vh - 200px)"
            trackedVehicles={vehiclePosition && [{
              ...vehiclePosition,
              lng: vehiclePosition.place?.x,
              lat: vehiclePosition.place?.y,
            }]}
          />
        )}
      </Box>
      <Box p={4}>
        <TextTitle h3>Нарушения</TextTitle>
        <ViolationTable vehicle_id={vehicle.id} />
      </Box>
      {vehicle?.id && (
        <Box p="30px">
          <TextTitle h3>Информация о разрешении</TextTitle>
          <PermissionTable vehicle_id={vehicle.id} />
        </Box>
      )}
    </CenteredModal>
  );
}
