import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../Text';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import AddButton from '../../../Buttons/AddButton';
import { EQUIPMENTS_NAME, getInitialEquipmentItem } from '../../../../constants/lkCadastr/cadastrForm7';
import { flexGap2, flexRowGap2, fullWidth } from '../../../../constants/lkCadastr/cadastrStorage';
import { ObjectPower } from './ObjectPower';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import NavTabs from '../../../Tabs';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../Inputs/CustomAutocomplete';
import { getInstallationEnumsRequest } from '../../../../api/cadastreApi';
import { AsyncAutocomplete } from '../../../AutoCompletes/AsyncAutocomplete';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';
import { compareId } from '../../../../helpers';

export function Equipments({
  equipments, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: equipments, tabName: 'Установка' });

  return (
    <Box sx={flexGap2}>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeItem(
            EQUIPMENTS_NAME,
            [...equipments, getInitialEquipmentItem()],
          )}
        >
          Добавить установку
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {equipments.map((installation, installationIndex) => {
        if (tab !== installation.randId) return null;

        const change = (field, value) => changeItem(
          `${EQUIPMENTS_NAME}.${installationIndex}.${field}`,
          value,
          undefined,
          { installationIndex },
        );

        const onDelete = async () => {
          if (installation?.id) {
            const deletedInstallation = await deleteForm7Entity({ id: installation.id, apiType: EQUIPMENTS_NAME });

            if (!deletedInstallation) return;
          }

          const cloneArray = equipments.slice();
          cloneArray.splice(installationIndex, 1);

          changeItem(
            EQUIPMENTS_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box key={installation.randId} sx={flexGap2}>
            {!isDemo && equipments.length > 1 && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, installationIndex, 'Установку')}
              >
                Удалить установку
              </DeleteButton>
            )}
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Наименование установки"
              value={installation.name}
              onChange={(e, value) => change('name', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Наименование изготовителя"
              value={installation.manufacturers_name}
              onChange={(e, value) => change('manufacturers_name', value)}
            />
            <ObjectPower
              isDemo={isDemo}
              data={installation}
              change={change}
              field_project="project_capacity"
            />
            <AsyncAutocomplete
              canBeZero
              validImmediately={!compareId(installation.kind?.label, 0)}
              debounceTime={0}
              disabled={isDemo}
              sx={fullWidth}
              label="Тип"
              value={installation.kind}
              optionField={DEFAULT_AUTOCOMPLETE_FIELD}
              request={(params) => getInstallationEnumsRequest('kind', params)}
              onChange={(e, value) => change('kind', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Режим эксплуатации"
              value={installation.exploitation_mode}
              onChange={(e, value) => change('exploitation_mode', value)}
            />
            <Box>
              <TextTitle>Температура эксплуатации, С</TextTitle>
              <Box sx={flexRowGap2}>
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  type="number"
                  label="от"
                  value={installation.exploitation_temperature_from}
                  onChange={(e, value) => change('exploitation_temperature_from', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  type="number"
                  label="до"
                  value={installation.exploitation_temperature_to}
                  onChange={(e, value) => change('exploitation_temperature_to', value)}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
