import React, { memo, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Objects } from './components/Objects';
import ModalAddObject from '../ModalAddObject';
import { OBJECT_STATUS } from '../../../constants/objectConstants';
import { SUBJECT_TYPE_ATTRIBUTES, WASTE_MANAGEMENT_TYPE_IDS } from '../../../constants/enums';
import { REPORT_TECHNOLOGIES_NAME } from '../../../constants/lkCadastr/cadastrForm7';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import { TextMain } from '../../Text';

function ModalCadastrForm7({
  data,
  onChange,
  isDemo,
  findForm1Object,
  form1Objects,
  wasteExcludeAccumulationParams,
  form5Licenses,
  changeLicenseUnneeded,
  is_license_unneeded,
  isHideForm5,
  subjectAttributes,
}) {
  const [createObjectIndex, setCreateObjectIndex] = useState(null);

  const setNewObject = (newObject) => {
    onChange(`${REPORT_TECHNOLOGIES_NAME}.${createObjectIndex}.objective_id`, {
      ...newObject,
      label: formatObjectWithAddress({ objective: newObject }),
      reportTechnologyIndex: createObjectIndex,
    });
  };

  const newObjectName = useMemo(() => {
    if (createObjectIndex !== null) return data?.reportTechnologies[createObjectIndex].objective_id.name;
  }, [createObjectIndex, data?.reportTechnologies]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {createObjectIndex !== null && (
        <ModalAddObject
          objectTypeTitle=""
          close={() => setCreateObjectIndex(null)}
          division="internal"
          helpComponent={(
            <Box mb={2}>
              <TextMain>
                Примеры наименования объекта
              </TextMain>
              <TextMain>
                1) Объект образования отходов ...
              </TextMain>
              <TextMain>
                2) Объекта обращения с отходами ...
              </TextMain>
              <TextMain>
                3) Производственная площадка ...
              </TextMain>
            </Box>
          )}
          status={OBJECT_STATUS.DRAFT}
          subjectData={{
            subject: subjectAttributes,
            inn: { inn: subjectAttributes.inn },
            subject_type: subjectAttributes?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
            name: newObjectName,
          }}
          wasteManagementTypes={wasteExcludeAccumulationParams[WASTE_MANAGEMENT_TYPE_IDS].split(',')}
          setNewObject={setNewObject}
        />
      )}
      <Objects
        isHideForm5={isHideForm5}
        isDemo={isDemo}
        form1Objects={form1Objects}
        form5Licenses={form5Licenses}
        findForm1Object={findForm1Object}
        setCreateObjectIndex={setCreateObjectIndex}
        changeItem={onChange}
        reportTechnologies={data?.reportTechnologies}
        changeLicenseUnneeded={changeLicenseUnneeded}
        is_license_unneeded={is_license_unneeded}
      />
    </Box>
  );
}

export default memo(ModalCadastrForm7);
