import React, { memo } from 'react';
import {
  Checkbox, FormControl, FormControlLabel, FormHelperText, styled,
} from '@mui/material';
import { CheckboxIcon } from './SvgIcons';
import { BACKGROUND_CHECKBOX, BORDER_CHECKBOX } from '../constants/colors';

const BpIcon = styled('div')(() => ({
  borderRadius: 3,
  width: 21,
  height: 21,
  border: `1px solid ${BORDER_CHECKBOX}`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  background: BACKGROUND_CHECKBOX,
  'input:disabled ~ &': {
    boxShadow: 'none',
  },
}));

function MuiCheckbox(props) {
  return (
    <Checkbox
      sx={{ '&:hover': { bgcolor: 'transparent' } }}
      color="default"
      checkedIcon={<CheckboxIcon />}
      icon={<BpIcon className="checkbox-outline" />}
      {...props}
    />
  );
}

const ctd = function (e) {
  e.stopPropagation();
};

function CustomCheckbox({
  value, name, onChange, label, sx, error, disabled, required, formControlSx,
}) {
  const errorText = error;
  const errorClassname = errorText ? 'error' : '';
  const requiredClassName = required ? ' required' : '';

  const disabledSx = disabled ? {
    opacity: 0.5,
    pointerEvents: 'none',
    'svg rect': { fill: ({ palette }) => palette.primary.light },
  } : {};

  return (
    <FormControl sx={formControlSx}>
      <FormControlLabel
        sx={{
          width: 'fit-content',
          '&:hover .MuiFormControlLabel-label': {
            color: ({ palette }) => palette.primary.main,
          },
          '& .MuiFormControlLabel-label': {
            color: ({ palette }) => value && palette.primary.main,
          },
          '&.error': {
            '& .MuiFormControlLabel-label': {
              color: ({ palette }) => palette.error.main,
            },
            '& .checkbox-outline': {
              borderColor: ({ palette }) => palette.error.main,
            },
          },
          ...disabledSx,
          ...sx,
        }}
        className={`${errorClassname}${requiredClassName}`}
        disabled={disabled}
        onClick={ctd}
        onChange={(e) => {
          onChange(e, e.target.checked);
        }}
        control={(
          <MuiCheckbox
            name={name}
            disabled={disabled}
            checked={Boolean(value)}
            value={Boolean(value)}
          />
        )}
        label={label ? `${label}${required ? ' *' : ''}` : ''}
      />
      {errorText && <FormHelperText sx={{ ml: '29px' }} error={Boolean(error)}>{errorText}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CustomCheckbox);
