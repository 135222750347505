// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React, { memo } from 'react';
import { getInitialForm5License } from '../../../constants/lkCadastr/cadastrForm5';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import AddButton from '../../Buttons/AddButton';
import CadastreForm5License from '../../Cadastr/CadastreForm5License';
import CustomCheckbox from '../../CustomCheckbox';
import NavTabs from '../../Tabs';

function ModalCadastrForm5({
  data: { licenses },
  changeItemFormByField,
  onChange,
  isDemo,
  form1Objects,
  onChangeForm5Entity,
  deleteForm5Entity,
  changeLicenseUnneeded,
  is_license_unneeded,
}) {
  const { tabs, tab, setTab } = useCadastreTabs({ items: licenses, tabName: 'Лицензия' });

  const addNewLicense = () => {
    setTab(tabs[0].name);
    changeItemFormByField({ field: 'form5.licenses', value: [...licenses, getInitialForm5License()] });
  };

  const { openDeleteModal } = useDeleteCadastreEntity();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Alert severity="info">
        Не требуется лицензия на деятельность по сбору, транспортированию, обработке, утилизации, обезвреживанию, размещению отходов V классов опасности
      </Alert>
      <CustomCheckbox disabled={isDemo} label="Лицензия не требуется" value={is_license_unneeded} onChange={() => changeLicenseUnneeded()} />
      {!is_license_unneeded && (
        <>
          <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
          {!isDemo && <AddButton sx={{ mt: 3 }} variant="outlined" onClick={addNewLicense}>Добавить лицензию</AddButton>}
          {licenses.map((license, licenseIndex) => {
            if (tab !== license.randId) return null;

            return (
              <CadastreForm5License
                key={license.randId}
                openDeleteModal={openDeleteModal}
                onChange={onChange}
                license={license}
                onChangeForm5Entity={onChangeForm5Entity}
                deleteForm5Entity={deleteForm5Entity}
                licenses={licenses}
                form1Objects={form1Objects}
                licenseIndex={licenseIndex}
                changeItemFormByField={changeItemFormByField}
                isDemo={isDemo}
              />
            );
          })}
        </>
      )}
    </Box>
  );
}

export default memo(ModalCadastrForm5);
