// @ts-nocheck
import { Box } from '@mui/material';
import React from 'react';
import { flexGap2, flexRowGap2 } from '../../../../../constants/lkCadastr/cadastrStorage';
import { TextTitle } from '../../../../Text';
import { AsyncAutocomplete } from '../../../../AutoCompletes/AsyncAutocomplete';
import { getWasteTypesRequest } from '../../../../../api/wasteTypeApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../../Inputs/CustomAutocomplete';
import { getReferencesRequest } from '../../../../../api/referenceApi';
import { WASTE_MANAGEMENT_TYPES_URL } from '../../../../../constants/urls';
import AddButton from '../../../../Buttons/AddButton';
import {
  FORM6_TRANSPORTED_WASTE,
  getInitialForm6TransportedWaste,
} from '../../../../../constants/lkCadastr/cadastrForm6';
import DeleteButton from '../../../../Buttons/DeleteButton';
import { filterActivityType } from '../../../../../helpers/cadastreForms/cadastreFormsPrepareFrontend';
import CustomInput from '../../../../Inputs';
import { compareId } from '../../../../../helpers';

export function Form6TransportedWaste({
  transportedWastes,
  onChange,
  changeItemFormByField,
  isDemo,
  tab,
  openDeleteModal,
  deleteForm6Entity,
}) {
  return (
    <Box sx={flexGap2}>
      <Box sx={flexRowGap2}>
        <TextTitle h3>
          Транспортируемые отходы
        </TextTitle>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeItemFormByField([
              ...transportedWastes,
              getInitialForm6TransportedWaste(),
            ])}
          >
            Добавить транспортируемый отход
          </AddButton>
        )}
      </Box>
      {transportedWastes.map((transportedWaste, transportedWasteIndex) => {
        if (tab !== transportedWaste.randId) return null;

        const changeItem = (field, value) => onChange(`${FORM6_TRANSPORTED_WASTE}.${transportedWasteIndex}.${field}`, value, transportedWasteIndex);

        const onDeleteTransportedWaste = async () => {
          if (transportedWaste?.id) {
            const deletedTransportedWaste = await deleteForm6Entity({ transportedWasteId: transportedWaste.id });

            if (!deletedTransportedWaste) return;
          }

          const cloneArray = transportedWastes.slice();
          cloneArray.splice(transportedWasteIndex, 1);

          changeItemFormByField(cloneArray, 'delete');
        };

        const isWasteZero = compareId(transportedWaste.waste_type_id?.label, 0);

        return (
          <Box key={transportedWaste.randId} sx={flexGap2}>
            <Box display="flex" alignItems="center" mb={1} gap={3}>
              <TextTitle>
                Транспортируемый отход
                №
                {transportedWasteIndex + 1}
              </TextTitle>
              {!isDemo && transportedWastes.length > 1 && (
                <DeleteButton onClick={() => openDeleteModal(onDeleteTransportedWaste, transportedWasteIndex, 'Транспортируемый отход')}>
                  Удалить транспортируемый отход
                </DeleteButton>
              )}
            </Box>
            <Box sx={flexGap2}>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!isWasteZero}
                debounceTime={0}
                disabled={isDemo}
                sx={{ width: '100%' }}
                request={getWasteTypesRequest}
                value={transportedWaste.waste_type_id}
                optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'name' }}
                label="Наименование отхода"
                onChange={(e, value) => changeItem('waste_type_id', value)}
              />
              <AsyncAutocomplete
                validImmediately={!isWasteZero}
                debounceTime={0}
                disabled={isDemo || isWasteZero}
                sx={{ width: '100%' }}
                request={getWasteTypesRequest}
                value={transportedWaste.waste_type_id}
                optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'code' }}
                label="Код отхода по ФККО"
                onChange={(e, value) => changeItem('waste_type_id', value)}
              />
              <AsyncAutocomplete
                required
                multiple
                validImmediately={!isWasteZero}
                debounceTime={0}
                disabled={isDemo || isWasteZero}
                request={(params) => getReferencesRequest({
                  url: WASTE_MANAGEMENT_TYPES_URL,
                  params,
                  filter: filterActivityType,
                })}
                sx={{ width: '320px' }}
                value={transportedWaste.waste_management_type_ids}
                label="Наименование цели вывоза"
                onChange={(e, value) => changeItem('waste_management_type_ids', value)}
              />
            </Box>
            <Box sx={flexGap2}>
              <TextTitle h3>
                Фактический объем транспортируемых отходов
              </TextTitle>
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="т/год"
                type="number"
                value={transportedWaste.actual_volume_transported_waste}
                onChange={(e, value) => changeItem('actual_volume_transported_waste', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
