// @ts-nocheck
import PublishIcon from '@mui/icons-material/Publish';
import { Box } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import {
  createReferenceRequest,
  deleteReferenceRequest,
  getReferencesRequest,
  updateReferenceRequest,
} from '../api/referenceApi';
import { importSubjectRequest } from '../api/subjectApi';
import { formValidator, getObjectValuesByField } from '../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../helpers/formValidator';
import { useApi, usePushNotification, useTableSearch } from '../hooks';
import { useTableStore } from '../store';
import BackdropLoading from './BackdropLoading';
import CustomButton from './Buttons/CustomButton';
import CustomCheckbox from './CustomCheckbox';
import { FileUpload } from './File/FileUpload';
import ModalCard from './Modals/ModalCard';
import CustomTable from './Tables/CustomTable';

export default function ReferenceGenerator({
  url,
  importKind,
  additionalInputs = [],
  additionalColumns = [],
  additionalInfo = {},
  ignoreInputs = [],
  canDelete = true,
  canCreate = true,
  checkArchive = true,
}) {
  const { setTableStore: changeTableStore } = useTableStore();
  const pushNotification = usePushNotification();
  const [withDeleted, setWithDeleted] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState({});
  const [modalInfo, setModalInfo] = useState();

  const changeWithDeleted = () => {
    changeTableStore({ page: 1 });
    setWithDeleted(!withDeleted);
  };

  const columns = useMemo(() => [
    { headerTitle: 'Код', field: 'code' },
    { headerTitle: 'Наименование', field: 'name' },
    ...additionalColumns,
  ], [additionalColumns]);

  const initialInfo = useMemo(() => ({
    name: '',
    code: '',
    ...additionalInfo,
  }), [additionalInfo]);

  const frame = useMemo(() => [
    {
      id: 1,
      inputFields: [
        {
          id: 'name', label: 'Название', multiline: true, required: true,
        },
        { id: 'code', label: 'Код', required: true },
        ...additionalInputs,
      ],
    },
  ], [additionalInputs]);

  const { makeRequest: importFile, isLoading: importLoading } = useApi({
    request: importSubjectRequest,
    setter: (data) => {
      const {
        updated, created, skipped, errors,
      } = data?.logs || {};

      if (errors.length) {
        errors.forEach((message) => pushNotification({ message }));
      } else {
        pushNotification({
          message: `Создано ${created.length}, Обновлено ${updated.length}, Пропущено ${skipped.length}`,
          variant: 'warning',
        });
      }
    },
  });

  const uploadFile = async (e, file, removeFiles) => {
    const formData = new FormData();
    formData.append('import[file]', file);
    formData.append('import[format_type]', 'csv');
    formData.append('import[kind]', importKind);
    await importFile(formData);

    removeFiles();
  };

  const search = useCallback((
    params,
    setTableStore,
  ) => getReferencesRequest({ params, url, setTableStore }), [url]);

  const { makeRequest: getTableData, isLoading } = useTableSearch({
    search,
    setter: setTableData,
    params: { with_deleted: withDeleted || undefined },
    deps: [withDeleted],
  });

  const openModal = (row) => {
    const actualRow = row;

    if (additionalInfo) {
      Object.keys(additionalInfo).forEach((key) => {
        if (additionalInfo[key]?.value !== undefined) {
          actualRow[key] = {
            value: row[key] || '',
            id: row[key] || '',
            label: row[`${key}_text`] || '',
          };
        }
      });
    }

    setModalInfo(actualRow);
  };

  const closeModal = () => setModalInfo();

  const changeModalInfo = (field) => (e, value) => {
    setModalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: '',
    }));
  };

  const onCreateEdit = async () => {
    const { hasErrors, validField } = formValidator({
      form: getObjectValuesByField(initialInfo, modalInfo),
      ignoreInputs,
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const data = { code: modalInfo.code, name: modalInfo.name };

    if (additionalInfo) {
      Object.keys(additionalInfo).forEach((key) => {
        data[key] = modalInfo[key]?.value || modalInfo[key];
      });
    }

    if (modalInfo.id === undefined) {
      await createReferenceRequest({ data, url });
    } else {
      await updateReferenceRequest({ id: modalInfo.id, data, url });
    }

    await getTableData();
    closeModal();
  };

  const { makeRequest } = useApi({
    request: onCreateEdit,
  });

  const deleteRequest = async (row) => {
    deleteReferenceRequest({ id: row.id, url });
    await getTableData();
    closeModal();
  };

  return (
    <Box width="100%">
      <BackdropLoading isLoading={importLoading} />
      {modalInfo !== undefined && (
        <ModalCard
          open
          containerSx={{ width: '414px' }}
          inputData={modalInfo}
          handleChange={changeModalInfo}
          close={closeModal}
          inputs={frame}
          error={error}
          title={modalInfo?.id === undefined ? 'Добавление' : 'Редактирование'}
          secondButtonProp={{
            label: 'Сохранить',
            onClick: makeRequest,
          }}
        />
      )}
      <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={2} gap={3}>
        {checkArchive && (
        <CustomCheckbox
          label="Включать архивные"
          onChange={changeWithDeleted}
          value={withDeleted}
        />
        )}
        {importKind && (
          <FileUpload
            buttonVariant="contained"
            setFile={uploadFile}
            id="file"
            extensions={['csv', 'xml']}
            name="file"
            endIcon={<PublishIcon />}
            label="Импорт"
          />
        )}
        {canCreate && (
        <CustomButton onClick={() => openModal({ ...initialInfo })}>
          Добавить
        </CustomButton>
        )}
      </Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={columns}
        deleteRow={canDelete && {
          getDeleteObjectName: (row) => `(${row.name})`,
          deleteRequest,
          deleteMessage: 'Удаление прошло успешно',
        }}
        editRow={openModal}
      />
    </Box>
  );
}
