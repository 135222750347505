import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ModalScrollBox from '../../components/ModalScrollBox';
import ModalColumnTitle from '../../components/ModalColumnTitle';

function UsersInfo({ users, openModal }) {
  if (!users?.length) return null;

  return (
    <Box>
      <ModalColumnTitle>Пользователи в группе</ModalColumnTitle>
      <ModalScrollBox>
        {users?.map((user) => (
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: '#717171',
              mb: 2,
            }}
            key={user.id}
            onClick={() => openModal(user)}
          >
            <Typography>{user.fullName}</Typography>
            <Typography>{user.email}</Typography>
          </Button>
        ))}
      </ModalScrollBox>
    </Box>
  );
}
export default UsersInfo;
