import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../../Text';
import DeleteButton from '../../../../Buttons/DeleteButton';
import AddButton from '../../../../Buttons/AddButton';
import { getInitialPlacedWaste, PLACED_WASTES_NAME } from '../../../../../constants/lkCadastr/cadastrForm8';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../../../Inputs';
import { AsyncAutocomplete } from '../../../../AutoCompletes/AsyncAutocomplete';
import { getWasteTypesRequest } from '../../../../../api/wasteTypeApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../../Inputs/CustomAutocomplete';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import NavTabs from '../../../../Tabs';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';
import { compareId } from '../../../../../helpers';

export function Form8PlacedWastes({
  placingObject, changeObject, isDemo, openDeleteModal,
}) {
  const placedWastes = placingObject[PLACED_WASTES_NAME];

  const { tab, setTab, tabs } = useCadastreTabs({ items: placedWastes, tabName: 'Размещённый отход' });

  return (
    <Box sx={flexGap2}>
      <Box sx={flexRowGap2}>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeObject(
              PLACED_WASTES_NAME,
              [...placedWastes, getInitialPlacedWaste()],
            )}
          >
            Добавить размещённый отход
          </AddButton>
        )}
      </Box>
      <NavTabs tab={tab} setTab={setTab} tabsComponents={tabs} />
      {placedWastes.map((placedWaste, placedWasteIndex) => {
        if (tab !== placedWaste.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${PLACED_WASTES_NAME}.${placedWasteIndex}.${field}`, value, undefined, { placedWasteIndex });
        };

        const onDelete = async () => {
          const deletedPlacedWaste = await deleteForm8Entity({ id: placedWaste.id, apiType: PLACED_WASTES_NAME });

          if (!deletedPlacedWaste) return;

          const cloneArray = placedWastes.slice();
          cloneArray.splice(placedWasteIndex, 1);

          changeObject(
            PLACED_WASTES_NAME,
            cloneArray,
            'delete',
          );
        };

        const isZeroWaste = compareId(placedWaste.waste_type_id?.code, 0);

        return (
          <Box key={placedWaste.randId} sx={flexGap2}>
            <Box display="flex" alignItems="center" mb={1} gap={3}>
              <TextTitle>
                Информация
                №
                {placedWasteIndex + 1}
              </TextTitle>
              {!isDemo && placedWastes.length > 1 && placedWaste?.id && (
                <DeleteButton
                  onClick={() => openDeleteModal(onDelete, placedWasteIndex, 'Размещённый отход')}
                >
                  Удалить размещённый отход
                </DeleteButton>
              )}
            </Box>
            <Box sx={flexGap2}>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!isZeroWaste}
                debounceTime={0}
                disabled={isDemo}
                sx={fullWidth}
                request={getWasteTypesRequest}
                value={placedWaste.waste_type_id}
                optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'code' }}
                label="Код отхода по ФККО"
                onChange={(e, value) => onChange('waste_type_id', value)}
              />
              <AsyncAutocomplete
                canBeZero
                validImmediately={!compareId(placedWaste.waste_type_id?.name, 0) && !isZeroWaste}
                debounceTime={0}
                disabled={isDemo || isZeroWaste}
                sx={fullWidth}
                request={getWasteTypesRequest}
                value={placedWaste.waste_type_id}
                optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'name' }}
                label="Наименование размещаемых отходов по ФККО"
                onChange={(e, value) => onChange('waste_type_id', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                type="number"
                label="Количество, т"
                sx={fitContent}
                value={placedWaste.tons_per_year}
                onChange={(e, value) => onChange('tons_per_year', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Технология размещения отходов"
                value={placedWaste.placement_technology}
                onChange={(e, value) => onChange('placement_technology', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Способ размещения отходов"
                value={placedWaste.placement_method}
                onChange={(e, value) => onChange('placement_method', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
