import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { METHODS_API } from '../../api/api';
import { subscriptionsRequest } from '../../api/emailsSubscriptionsApi';
import ModalCard from '../../components/Modals/ModalCard';
import CustomTable from '../../components/Tables/CustomTable';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { formValidator } from '../../helpers';
import { getDayMonthNameYearTime } from '../../helpers/dateHelper';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { useApi, usePushNotification, useTableSearch } from '../../hooks';

const initialTableSubscriptionFields = [
  { headerTitle: 'Код', field: 'id' },
  { headerTitle: 'От кого', field: 'from' },
  { headerTitle: 'Дата/Время рассылки', field: 'send_at', getField: (row) => getDayMonthNameYearTime(row.send_at) },
  { headerTitle: 'Текст сообщения', field: 'body' },
];

const frame = [
  {
    id: 1,
    inputFields: [
      {
        id: 'send_at',
        label: 'Дата/Время рассылки',
        type: 'datepicker',
        required: true,
        inputSx: { width: 'fit-content' },
        isDateTime: true,
      },
      {
        id: 'body', label: 'Текст сообщения', multiline: true, required: true, inputSx: { width: '100%' },
      },
    ],
  },
];

// todo feature
// const initialSubscription = {
//   subscription_date: null,
//   message: '',
// };

const getSubscriptions = (params, setTableStore) => subscriptionsRequest({ params }, setTableStore);

export default function RegistrySubscriptionsPage() {
  const [tableData, setTableData] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState({});
  const pushNotification = usePushNotification();

  const { isLoading: tableSearchLoading, defaultRequest: getSubscriptionsTableData } = useTableSearch({
    search: getSubscriptions,
    setter: setTableData,
  });

  const { defaultRequest: subscriptionApi, isLoading: deleteLoading } = useApi({
    request: subscriptionsRequest,
  });

  const isLoading = deleteLoading || tableSearchLoading;

  // todo feature
  // const deleteRow = async ({ id }) => {
  //   const deletedSubscription = await subscriptionApi({
  //     method: METHODS_API.DELETE,
  //     id,
  //   });
  //
  //   if (deletedSubscription?.id) {
  //     setTableStore({ page: 1 });
  //     getSubscriptionsTableData({ page: 1 });
  //   }
  // };

  const changeModalInfo = (field) => (e, value) => {
    setSubscription((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: '',
    }));
  };

  const openSubscriptionModal = (tableSubscription) => {
    setSubscription({
      id: tableSubscription.id,
      body: tableSubscription.body,
      send_at: tableSubscription.send_at,
    });
  };

  const saveSubscription = async () => {
    const { hasErrors, validField } = formValidator({
      form: {
        body: subscription.body,
        send_at: subscription.send_at,
      },
    });

    setError(validField);

    if (hasErrors) {
      pushNotification({ message: HAS_REQUIRED_FIELDS_TEXT });
      return;
    }

    const newSubscription = await subscriptionApi({
      method: subscription?.id ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        send_at: subscription.send_at,
        body: subscription.body,
      },
      id: subscription?.id,
    });

    if (newSubscription?.id) {
      await getSubscriptionsTableData();
      setSubscription(null);
    }
  };

  return (
    <Box sx={{ ...flexGap2, mt: 2 }}>
      {/* todo feature */}
      {/* <CustomButton sx={{ ...fitContent, mt: 1 }} onClick={() => openSubscriptionModal(initialSubscription)}>Создать рассылку</CustomButton> */}
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={initialTableSubscriptionFields}
        onClick={openSubscriptionModal}
        // todo feature
        // deleteRow={{
        //   deleteRequest: deleteRow,
        //   deleteMessage: 'Рассылка удалена',
        //   getDeleteObjectName: (row) => `Рассылка ${row.id}`,
        // }}
      />
      <ModalCard
        singleColumn
        handleChange={changeModalInfo}
        inputs={frame}
        open={subscription}
        inputData={subscription}
        error={error}
        close={() => setSubscription(null)}
        title={subscription?.id ? 'Редактирование' : 'Добавление'}
        secondButtonProp={{
          label: 'Сохранить',
          onClick: saveSubscription,
        }}
      />
    </Box>
  );
}
