import { useEffect, useState } from 'react';
import { subDays } from 'date-fns';
import { useApi } from './useApi';
import { getVehiclesLastPosition, getVehiclesPosition } from '../api/vehiclesApi';
import { formatToServerDate } from '../helpers/dateHelper';

let interval;
let loading;

const removeInterval = () => {
  if (interval) clearInterval(interval);
  interval = undefined;
  loading = undefined;
};

export default function useVehiclePositionListener({
  vehiclePosition,
  setVehiclePosition,
  guid_bnso,
  request = getVehiclesLastPosition,
  setLoading,
}) {
  const [isPosition, setIsPosition] = useState(false);

  const { makeRequest } = useApi({
    request: async (vehicle_guid_bnso) => request(vehicle_guid_bnso, {
      created_at: {
        from: formatToServerDate(subDays(new Date(), 3)),
        to: formatToServerDate(new Date()),
      },
    })
      .then((data) => {
        if (data?.data?.length) {
          setVehiclePosition(data?.data[0]);
        } else {
          setVehiclePosition(null);
        }
      })
      .catch(() => {})
      .finally(() => {
        if (vehiclePosition) setLoading(false);
        loading = false;
      }),
  });

  useEffect(() => {
    if (guid_bnso && vehiclePosition !== null && !isPosition && !interval) {
      interval = setInterval(() => {
        if (loading) return;

        loading = true;

        if (!vehiclePosition && loading) setLoading(true);

        makeRequest(guid_bnso);
      }, 10000);
    }

    removeInterval();
    /* eslint-disable-next-line */
  }, [guid_bnso, vehiclePosition, isPosition]);

  useEffect(() => {
    if (guid_bnso) makeRequest(guid_bnso);
    /* eslint-disable-next-line */
  }, [guid_bnso]);

  const { makeRequest: getPosition } = useApi({
    request: () => (
      getVehiclesPosition(guid_bnso, {
        created_at: {
          from: formatToServerDate(subDays(new Date(), 3)),
          to: formatToServerDate(new Date()),
        },
      })
        .then(({ data }) => {
          if (data?.length) {
            setIsPosition(true);
            setVehiclePosition(data[0]);
          } else {
            removeInterval();
            setLoading(false);
          }
        })
    ),
  });

  useEffect(() => {
    if (vehiclePosition === null && guid_bnso !== undefined) {
      getPosition();
    }
    /* eslint-disable-next-line */
  }, [vehiclePosition]);

  useEffect(() => () => {
    removeInterval();
  }, []);
}
