import * as L from 'leaflet';
import 'leaflet-simple-map-screenshoter';
import { useEffect, useState } from 'react';

const pluginOptions = {
  cropImageByInnerWH: true, // crop blank opacity from image borders
  hidden: true, // hide screen icon
  preventDownload: false, // prevent download on button click
  domtoimageOptions: {
    quality: 1,
  },
  screenName: 'screen', // string or function
  hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
  mimeType: 'image/png', // used if format == image,
};

export function useLeafletScreenshot({ map, setter, hasMap = true }) {
  const [screenshot, setScreenshot] = useState();

  useEffect(() => {
    if (!hasMap) {
      setter(false);
    }
  }, [hasMap]);

  useEffect(() => {
    if (!map) return;

    const simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(map);

    setScreenshot(simpleMapScreenshoter);
  }, [map]);

  return () => {
    const format = 'image'; // 'image' - return base64, 'canvas' - return canvas
    const overridedPluginOptions = {
      mimeType: 'image/png',
    };
    setTimeout(() => {
      screenshot?.takeScreen?.(format, overridedPluginOptions)
        .then((image) => {
          setter(image);
        }).catch((e) => {
          console.error(e);
        });
    }, 1000);
  };
}
