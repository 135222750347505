// @ts-nocheck
import {
  Box, CssBaseline, useMediaQuery, useTheme,
} from '@mui/material';
import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes, useLocation,
} from 'react-router-dom';
import { getMemberRequest } from './api/userApi';
import BarMenu from './components/BarMenu';
import ModalDelete from './components/Modals/ModalDelete';
import { DRAWER_WIDTH } from './constants';
import {
  INITIAL_RKO_47_ROUTE,
  PUBLIC_APPLICATION_ROUTE,
  PUBLIC_FORGOT_PASSWORD_ROUTE,
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
  PUBLIC_REGISTRATION_SUBJECT_ROUTE,
  PUBLIC_RESET_PASSWORD_ROUTE,
} from './constants/routes';
import { PUBLIC_EMAIL_CONFIRMATION_ROUTES } from './constants/routes/emailConfirmationRoutes';
import { useApi, usePushNotification } from './hooks';
import AdminLoginPage from './pages/Admin/AdminLoginPage';
import CarrierLoginPage from './pages/Carrier/CarrierLoginPage';
import CommitteeLoginPage from './pages/Committee/CommitteeLoginPage';
import ForgotPasswordPage from './pages/Public/Password/ForgotPasswordPage';
import ResetPasswordPage from './pages/Public/Password/ResetPasswordPage';
import PublicApplicationPage from './pages/Public/PublicApplicationPage';
import RegistrationSubjectPage from './pages/Public/RegistrationSubjectPage';
import { useHeaderStore, useModalStore, useUserStore } from './store';

import { CURRENT_APP_VER } from './appVersion';
import BottomSideMenu from './components/BottomSideMenu';
import { TextMain } from './components/Text';
import { getAdminRoutes } from './constants/routes/adminRoutes';
import { getAdminCadastreRoutes } from './constants/routes/cadastreAdminRoutes';
import { getCadastreRoutes } from './constants/routes/cadastreRoutes';
import { getCarrierRoutes } from './constants/routes/carrierRoutes';
import { getCommitteeRoutes } from './constants/routes/committeeRoutes';
import { checkIsControlOrigin, checkIsPrivateOrigin } from './helpers/browserLocation';
import { copyTextToClipboard } from './helpers/storage';
import { useChangeSideDrawer } from './hooks/useChangeSideDrawer';
import { usePrivilegeRole } from './hooks/usePrivilegeRole';
import { useRoleRedirect } from './hooks/useRoleRedirect';
import AdminTerSchemeLoginPage from './pages/Admin/AdminTerSchemeLoginPage';
import CadastrLoginPage from './pages/Cadastr/CadastrLoginPage';
import CadastrAdminLoginPage from './pages/CadastrAdmin/CadastrAdminLoginPage';
import Rko47InitialPage from './pages/Rko47/Rko47InitialPage';

const routesWithoutContainer = [
  { path: PUBLIC_LOGIN_CARRIER_PAGE_ROUTE, Component: CarrierLoginPage },
  { path: PUBLIC_FORGOT_PASSWORD_ROUTE, Component: ForgotPasswordPage },
  { path: INITIAL_RKO_47_ROUTE, Component: Rko47InitialPage },
  { path: PUBLIC_RESET_PASSWORD_ROUTE, Component: ResetPasswordPage },
  { path: PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE, Component: CommitteeLoginPage },
];

if (checkIsPrivateOrigin()) {
  routesWithoutContainer.push(...[
    {
      path: PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
      Component: CadastrAdminLoginPage,
    },
    { path: PUBLIC_LOGIN_ADMIN_PAGE_ROUTE, Component: AdminLoginPage },
  ]);
}

if (!checkIsControlOrigin()) {
  routesWithoutContainer.push(...[
    { path: PUBLIC_LOGIN_CADASTR_PAGE_ROUTE, Component: CadastrLoginPage },
  ]);
}

const publicRoutes = [
  {
    path: PUBLIC_APPLICATION_ROUTE, hasId: true, Component: PublicApplicationPage,
  },
  {
    path: PUBLIC_REGISTRATION_SUBJECT_ROUTE, Component: RegistrationSubjectPage,
  },
  { path: PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE, Component: AdminTerSchemeLoginPage },
];

const includeRoute = (routes) => routes.some(({ path }) => path === window.location.pathname
  || (window.location.pathname === path));

function MainRoutes() {
  useRoleRedirect();

  const { userStore, setUserStore } = useUserStore();
  const { headerStore } = useHeaderStore();
  const [headerHeight, setHeaderHeight] = useState();
  const { pathname } = useLocation();

  const { isOpenDrawer } = useChangeSideDrawer();

  const { makeRequest: getMember } = useApi({
    request: async () => {
      const userData = await getMemberRequest();

      setUserStore(userData);
    },
  });

  useEffect(() => {
    if (!window?.location?.pathname?.includes?.(PUBLIC_APPLICATION_ROUTE)) getMember();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    const header = document.body.querySelector('header');
    setHeaderHeight(header?.clientHeight || 0);
  }, [userStore, pathname, headerStore]);

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const mainSx = useMemo(() => {
    let pStyle = 3;

    if (tabletSizeAndLower) pStyle = '4px';

    if (includeRoute(routesWithoutContainer) || includeRoute(publicRoutes)) pStyle = '';

    return ({
      width: tabletSizeAndLower || !isOpenDrawer ? '100vw' : `calc(100% - ${DRAWER_WIDTH}px)`,
      minHeight: '100vh',
      flexGrow: 1,
      p: pStyle,
      pt: headerHeight ? `${headerHeight}px` : '',
      /* eslint-disable-next-line */
    });
  }, [tabletSizeAndLower, headerHeight, isOpenDrawer]);

  const hasPrivilege = usePrivilegeRole();

  return (
    <Box
      component="main"
      className={pathname !== PUBLIC_REGISTRATION_SUBJECT_ROUTE ? 'background-main' : ''}
      sx={mainSx}
    >
      <Routes>
        {publicRoutes.map(({
          path, exact = true, hasId, Component,
        }) => (
          <Route
            key={path}
            exact={exact}
            path={`${path}${hasId ? '/:id' : ''}`}
            element={<Component />}
          />
        ))}
        {
          !userStore?.id && (
            routesWithoutContainer.map(({
              path, exact = true, hasId, Component,
            }) => (
              <Route
                key={path}
                exact={exact}
                path={`${path}${hasId ? '/:id' : ''}`}
                element={<Component />}
              />
            ))
          )
        }
        {PUBLIC_EMAIL_CONFIRMATION_ROUTES.map(({ path, Component }) => (
          <Route
            key={path}
            exact
            path={path}
            element={<Component />}
          />
        ))}
        {getCommitteeRoutes(hasPrivilege)}
        {getCadastreRoutes(userStore)}
        {getCarrierRoutes()}
        {getAdminCadastreRoutes(hasPrivilege)}
        {getAdminRoutes(hasPrivilege)}
      </Routes>
    </Box>
  );
}

function App() {
  const { modalStore } = useModalStore();
  const pushNotification = usePushNotification();

  const copyText = () => {
    pushNotification({
      message: `Скопировано ver:${CURRENT_APP_VER}`, variant: 'warning', autoHideDuration: 1000, vertical: 'bottom',
    });
    copyTextToClipboard(`ver:${CURRENT_APP_VER}`);
  };

  return (
    <Router>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <TextMain
          onClick={copyText}
          sx={{
            position: 'fixed', bottom: 0, right: 0, opacity: 0.5, fontSize: 8, zIndex: 99999, cursor: 'default',
          }}
        >
          {CURRENT_APP_VER}
        </TextMain>
        <CssBaseline />
        <BarMenu />
        {modalStore.deleteModal.isOpen && <ModalDelete />}
        <MainRoutes />
      </Box>
      <BottomSideMenu />
    </Router>
  );
}

export default App;
