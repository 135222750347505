import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { cadastreContactsRequest } from '../../api/cadastreApi';
import CustomButton from '../../components/Buttons/CustomButton';
import { TextTitle } from '../../components/Text';
import { INPUT_EMPTY_TEXT } from '../../constants';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { stringToPhone } from '../../helpers/stringHelper';
import { useApi } from '../../hooks';

const initialForm = {
  address: '',
  contact: '',
  email: '',
  link_committee: '',
  link_cloodoo: '',
};

export default function CadastrContactInfo() {
  const [contactInfo, setContactInfo] = useState(initialForm);

  const { defaultRequest: contactApi, isLoading } = useApi({
    request: cadastreContactsRequest,
  });

  useEffect(() => {
    contactApi()
      .then((responseContactInfo) => {
        setContactInfo(responseContactInfo?.[0] || initialForm);
      });
  }, []);

  if (isLoading) return <CircularProgress sx={{ mt: 3 }} />;

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <Box>
        <TextTitle>
          Адрес:
          {' '}
          {contactInfo.address || INPUT_EMPTY_TEXT}
        </TextTitle>
        <TextTitle>
          Телефон:
          {' '}
          {stringToPhone(contactInfo.contact) || INPUT_EMPTY_TEXT}
        </TextTitle>
        <TextTitle>
          E-mail:
          {' '}
          {contactInfo.email || INPUT_EMPTY_TEXT}
        </TextTitle>
        <TextTitle>
          Комитет Ленинградской области по обращению с отходами
          <CustomButton sx={{ height: 'fit-content' }} variant="text" href={contactInfo.link_committee}>
            {contactInfo.link_committee}
          </CustomButton>
        </TextTitle>
        <TextTitle>
          ЛОГКУ «Центр Ленинградской области по организации деятельности по обращению с отходами»
          <CustomButton sx={{ height: 'fit-content' }} variant="text" href={contactInfo.link_cloodoo}>
            {contactInfo.link_cloodoo}
          </CustomButton>
        </TextTitle>
      </Box>
      {/* todo no backend */}
      {/* <ContactPhone /> */}
    </Box>
  );
}
