// @ts-nocheck
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSubjectRequest } from '../../api/subjectApi';
import { getViolationEnumsRequest, getViolationsRequest } from '../../api/violationsApi';
import { VIOLATION_TYPE_ENUM } from '../../constants/enums';
import { CARRIER_PERSONAL_ACCOUNT_ROUTE } from '../../constants/routes';
import { VIOLATIONS_URL } from '../../constants/urls';
import { getDayMonthYear, getHourMinute } from '../../helpers/dateHelper';
import { useApi } from '../../hooks';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import SearchInput from '../Inputs/SearchInput';
import ModalCreateApplication from '../Modals/ModalApplication/ModalCreateApplication';
import { DEFAULT_SELECTOR_OPTION } from '../Selectors/Selector';
import CustomTable from './CustomTable';

const applicationTableColumns = [
  {
    headerTitle: 'Наименование нарушения',
    field: 'name',
  },
  {
    headerTitle: 'Тип нарушения',
    field: 'kind_text',
  },
  {
    headerTitle: 'Дата и время',
    field: 'committed_at',
    getField: ({ committed_at }) => `${getDayMonthYear(committed_at)} ${getHourMinute(committed_at)}`,
  },
  {
    headerTitle: 'Основание нарушения',
    field: 'reason',
  },
  {
    headerTitle: 'ТС',
    field: 'vehicle',
    getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.brand,
  },
  {
    headerTitle: 'Название субъекта',
    field: 'subject',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
];

export default function ViolationTable({
  subject_id,
  vehicle_id,
  open = () => {},
  searchText: outerSearchText,
  setViolations,
}) {
  const [editRow, setEditRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [violationType, setViolationType] = useState(DEFAULT_SELECTOR_OPTION);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const location = useLocation();

  const changeSearchText = (e, value) => setSearchText(value);

  const { makeRequest, isLoading } = useApi({
    request: async () => {
      if (subject_id) {
        return getSubjectRequest(
          {
            id: subject_id,
            url: VIOLATIONS_URL,
            params: {
              page,
              total: perPage,
              search: searchText || outerSearchText,
              kind: violationType.id,
            },
            setTableStore: ({ meta: { total: totalPageCount } }) => setTotal(totalPageCount),
          },
        );
      }

      if (vehicle_id) {
        const params = { vehicle_id };

        if (location.pathname.includes(CARRIER_PERSONAL_ACCOUNT_ROUTE)) params.kind = 'not_connected_to_rins';

        return getViolationsRequest(
          params,
          ({ meta: { total: totalPageCount } }) => setTotal(totalPageCount),
        );
      }
    },
    shouldRequest: true,
    setter: (data) => {
      setTableData(data);
      if (setViolations) setViolations(data);
    },
    deps: [page, perPage, violationType.id],
  });

  const closeModal = (shouldRequest = false) => {
    if (shouldRequest) makeRequest();

    setEditRow(null);
  };

  const changeViolationType = (e, value) => {
    setViolationType(value);
  };

  const mainTheme = useTheme();
  const tabletSizeAndLower = useMediaQuery(mainTheme.breakpoints.down('md'));

  return (
    <Box position="relative" flexBasis="40%">
      {Boolean(editRow) && (
        <ModalCreateApplication close={closeModal} />
      )}
      <Box
        overflow="auto"
        gap={3}
        p={tabletSizeAndLower ? 0 : 1}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" alignItems="flex-start" flexWrap="wrap" pt={1} gap={1}>
          <SearchInput
            sx={{ width: 320, background: 'white' }}
            label="Поиск нарушений"
            onKeyEnter={makeRequest}
            value={searchText}
            onChange={changeSearchText}
          />
          {!location.pathname.includes(CARRIER_PERSONAL_ACCOUNT_ROUTE) && (
            <AsyncAutocomplete
              multiline
              value={violationType}
              onChange={changeViolationType}
              optionField={DEFAULT_AUTOCOMPLETE_FIELD}
              request={(params) => getViolationEnumsRequest(VIOLATION_TYPE_ENUM, params)}
              label="Фильтр по типу нарушения"
            />
          )}
        </Box>
        <CustomTable
          perPage={perPage}
          total={total}
          page={page}
          onClick={open}
          setPage={(e, value) => setPage(value)}
          setPerPage={(e, value) => setPerPage(value)}
          data={tableData}
          isLoading={isLoading}
          columns={applicationTableColumns}
        />
      </Box>
    </Box>
  );
}
