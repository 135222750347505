// @ts-nocheck
import { Alert, Box, Link } from '@mui/material';
import React, { memo, useState } from 'react';
import { METHODS_API } from '../../../api/api';
import { cadastreReportHistoryRequest, cadastreReportTechnologiesImportRequest } from '../../../api/cadastreApi';
import XLSXform6Example from '../../../assets/files/KO_template_import_transport.xlsx';
import { getInitialForm6ActualVolume } from '../../../constants/lkCadastr/cadastrForm6';
import { getActualVolumesHelper } from '../../../helpers/cadastreForms/form6Helper';
import { useApi } from '../../../hooks';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import { precisionAdd } from '../../../helpers/numberHelper';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import AddButton from '../../Buttons/AddButton';
import CustomButton from '../../Buttons/CustomButton';
import CadastreForm6ActualVolume from '../../Cadastr/CadastreForm6ActualVolume';
import DropZone from '../../File/DropZone';
import NavTabs from '../../Tabs';
import ModalAddSubject from '../ModalAddSubject';
import ModalWithTitle from '../ModalWithTitle';
import BackdropLoading from '../../BackdropLoading';
import { useCadastreStore } from '../../../store';
import { TextTitle } from '../../Text';

function ModalCadastrForm6({
  data: { actualVolumes },
  changeItemFormByField,
  onChange,
  isDemo,
  deleteForm6Entity,
  report,
}) {
  const [createSubjectInfo, setCreateSubjectInfo] = useState(null);
  const [openedImport, setOpenedImport] = useState(false);
  const { cadastreStore } = useCadastreStore();

  const openImportModal = () => setOpenedImport(true);
  const closeImportModal = () => setOpenedImport(false);

  const { tab, tabs, setTab } = useCadastreTabs({ items: actualVolumes, tabName: 'Фактический объём' });

  const closeNewSubjectModal = () => setCreateSubjectInfo(null);

  const addNewActualVolume = () => {
    setTab(tabs[0].name);
    changeItemFormByField({ field: 'form6.actualVolumes', value: [...actualVolumes, getInitialForm6ActualVolume()] });
  };

  const setNewSubject = (newSubject) => {
    onChange({
      field: `actualVolumes.${createSubjectInfo.index}.${createSubjectInfo.fieldName}`,
      value: { ...newSubject, ...newSubject?.attributes },
      reportActualVolumeIndex: createSubjectInfo.index,
    });
    closeNewSubjectModal();
  };

  const { openDeleteModal } = useDeleteCadastreEntity();


  const getFiles = async (dropZoneFiles) => {
    const formData = new FormData();

    formData.append('import[file]', dropZoneFiles[0]);
    formData.append('import[format_type]', 'xlsx');
    formData.append('import[kind]', 'report_actual_volumes');

    await cadastreReportTechnologiesImportRequest({
      method: METHODS_API.POST,
      data: formData,
      rest: `?report_history_id=${report.id}`,
      isFormData: true,
    });

    const newReport = await cadastreReportHistoryRequest({
      method: METHODS_API.GET,
      id: report.id,
    });

    const createdActualVolumes = await getActualVolumesHelper(newReport);

    changeItemFormByField({
      field: 'form6', value: createdActualVolumes, type: 'import',
    });

    closeImportModal();
  };

  const { defaultRequest: importFromFile, isLoading: loadingImport } = useApi({ request: getFiles });
  const totalVolumes = actualVolumes.reduce(
    (prev, item) => precisionAdd(prev, item.transportedWastes.reduce((x, i) => precisionAdd(x, i.actual_volume_transported_waste), 0)),
    0,
  );

  const actualVolumeObj = (actualVolumes.find((el, i, arr) => {
    if (el.randId == tab) { return true; }
    return false;
  }));

  function actualVolumeNumber() {
    if (actualVolumeObj != null) { return actualVolumeObj.transfer_subject_id.name; }
    return '-';
  }

  const actualTabVolumes = actualVolumes.reduce((prev, item) => {
    if (item.randId != tab) {
      return prev;
    }

    return precisionAdd(prev, item.transportedWastes.reduce((x, i) => precisionAdd(x, i.actual_volume_transported_waste), 0));
  }, 0);


  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box>
        <TextTitle h3>
          Итого по всем:
          {' '}
          {totalVolumes}
        </TextTitle>
        <TextTitle h4>
          Итого по
          {' '}
          {actualVolumeNumber()}
          :
          {' '}
          {actualTabVolumes}
        </TextTitle>
      </Box>
      <BackdropLoading position="fixed" isLoading={loadingImport} />
      {!isDemo && <CustomButton onClick={openImportModal}>Импортировать из файла</CustomButton>}
      <NavTabs getErrorTab={(tabId) => cadastreStore.errorForm6?.some((item) => item.includes(tabId))} tabsComponents={tabs} setTab={setTab} tab={tab} />
      {createSubjectInfo !== null && (
        <ModalAddSubject
          division="internal"
          close={closeNewSubjectModal}
          setNewSubject={setNewSubject}
        />
      )}
      {openedImport && (
        <ModalWithTitle open close={closeImportModal} title="Импортировать из файла">
          <Alert severity="warning" sx={{ mb: 2 }}>
            Загруженный файл должен быть формате Office Excel до 20 Мб, содержать не более 1000 строк. В файле должны содержаться наименование (ФИО), ИНН, документ об указании услуг и транспортируемые отходы.
            <Link sx={{ ml: 1 }} href={XLSXform6Example}>Скачать пример файла импорта</Link>
          </Alert>
          <DropZone sx={{ height: 120 }} getFiles={(e, files) => importFromFile(files)} maxFiles={1} />
        </ModalWithTitle>
      )}
      {!isDemo && (
        <AddButton variant="outlined" onClick={addNewActualVolume}>
          Добавить лицо, передавшее отходы для транспортирования
        </AddButton>
      )}
      {actualVolumes.map((actualVolume, actualVolumeIndex) => {
        if (actualVolume.randId !== tab) return null;

        return (
          <CadastreForm6ActualVolume
            deleteForm6Entity={deleteForm6Entity}
            setCreateSubjectInfo={setCreateSubjectInfo}
            key={actualVolume.randId}
            openDeleteModal={openDeleteModal}
            actualVolume={actualVolume}
            actualVolumes={actualVolumes}
            onChange={onChange}
            isDemo={isDemo}
            changeItemFormByField={changeItemFormByField}
            actualVolumeIndex={actualVolumeIndex}
          />
        );
      })}
    </Box>
  );
}

export default memo(ModalCadastrForm6);
