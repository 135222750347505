import React from 'react';
import { Add } from '@mui/icons-material';
import CustomIconButton from './CustomIconButton';

export default function AddButton({
  children, onClick, sx, variant,
}) {
  return (
    <CustomIconButton
      sx={sx}
      variant={variant}
      Icon={Add}
      onClick={onClick}
    >
      {children}
    </CustomIconButton>
  );
}
