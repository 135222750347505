import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import cloneDeep from 'lodash.clonedeep';
import { useNavigate } from 'react-router-dom';
import ModalWithTitle from '../ModalWithTitle';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../../Inputs/CustomAutocomplete';
import CustomInput from '../../Inputs';
import WasteGenerator, { OBJECTS_FIELD } from '../../Carrier/WasteGenerator';
import {
  changeGenerators,
  compareId,
  formValidator,
  getCloneGenerator,
  getObjectValuesByField,
} from '../../../helpers';
import AddButton from '../../Buttons/AddButton';
import WasteRecipient from '../../Carrier/WasteRecipient';
import {
  applicationDocumentRequest, applicationObjectivesDocumentRequest,
  createApplicationRequest,
  getApplicationEnumsRequest,
  updateApplicationObjectivesRequest,
  updateApplicationRequest,
} from '../../../api/applicationsApi';
import { FileUpload, SIZE_TEN_MEGABYTE } from '../../File/FileUpload';
import InputsBoxStyled from '../../CreateApllication/InputsBoxStyled';
import { getParamsFromObject, objectChangeDotProperty } from '../../../helpers/objectHelper';
import { AsyncAutocomplete } from '../../AutoCompletes/AsyncAutocomplete';
import { useApi } from '../../../hooks';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../../helpers/formValidator';
import { useUserStore } from '../../../store';
import ModalAddVehicle from '../ModalAddVehicle';
import ModalAddObject from '../ModalAddObject';
import BackdropLoading from '../../BackdropLoading';
import {
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  HAZARD_CLASS_ENUM,
  HAZARD_CLASS_ENUM_TEXT,
  WASTE_MANAGEMENT_ENUM, WASTE_MANAGEMENT_TYPE_ID,
  WASTE_MANAGEMENT_TYPES_ENUM,
} from '../../../constants/enums';
import ModalAddSubject from '../ModalAddSubject';
import { formatAddressString } from '../../../helpers/stringHelper';
import CustomCheckbox from '../../CustomCheckbox';
import Selector from '../../Selectors/Selector';
import { getSubjectRequest } from '../../../api/subjectApi';
import { VEHICLES_URL } from '../../../constants/urls';
import useGetReferenceItems from '../../../hooks/useGetRefferenceItems';
import { AlertRequiredFields } from '../../Inputs/AlertRequiredFields';

const defaultSubject = {
  inn: '', ogrn: '', id: '', name: '', subject_type: '',
};

const initialSubject = {
  id: '',
  name: {
    name: '',
    id: '',
  },
  inn: {
    inn: '',
  },
  subject_type: { code: '', name: '', id: '' },
  ogrn: { ogrn: '' },
};

const initialGeneratorObject = {
  id: '',
  name: {
    id: '',
    name: '',
  },
  [WASTE_MANAGEMENT_ENUM]: {
    code: '',
    name: '',
    id: '',
  },
  address: { ...DEFAULT_AUTOCOMPLETE_OPTION },
};

const initialRecipientObject = {
  id: '',
  name: {
    id: '',
    name: '',
  },
  address: { ...DEFAULT_AUTOCOMPLETE_OPTION },
};

const initialDocument = {
  document_name: '',
  file: '',
};

const wasteGenerator = {
  ...initialSubject,
  objects: initialGeneratorObject,
};

const initialApplication = {
  egrz: { egrz: '' },
  egrz_trailer: '',
  model: '',
  brand: '',
  isActualData: false,
  is_hazard_class_rule: false,
  [HAZARD_CLASS_ENUM]: [],
  [WASTE_MANAGEMENT_ENUM]: {
    code: '',
    name: '',
    id: '',
  },
  waste_generators: [{
    waste_generator_documents: [{ ...initialDocument }],
    id: Math.random(),
    ...initialSubject,
    objects: [{ ...initialGeneratorObject }],
  }],
  waste_recipient: {
    ...initialSubject,
    object: { ...initialRecipientObject },
  },
  waste_recipient_document: { ...initialDocument },
};

const DEFAULT_ADDITIONAL_PROPS = { index: undefined, generatorIndex: undefined, field: undefined };

const initialNewObject = { generatorIndex: null, index: null };

export default function ModalCreateApplication({ close, applicationData }) {
  const { userStore } = useUserStore();
  const navigate = useNavigate();
  const [application, setApplication] = useState({ ...initialApplication });
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [isApplicationLoading, setIsAppliationLoading] = useState(false);
  const [newGeneratorSubjectIndex, setNewGeneratorSubjectIndex] = useState();
  const [isOpenRecipientSubjectModal, setIsOpenRecipientSubjectModal] = useState(false);
  const [newGeneratorObjectIndex, setNewGeneratorObjectIndex] = useState(initialNewObject);
  const [isOpenRecipientObjectModal, setIsOpenRecipientObjectModal] = useState(false);
  const [error, setError] = useState({});

  const { getReferenceTypeIdsByCode: getEnterpriseTypeIdsByCode } = useGetReferenceItems({ referenceUrl: ENTERPRISE_ACTIVITY_TYPES_ENUM });
  const { getReferenceTypeIdsByCode: getWasteTypeIdsByCode } = useGetReferenceItems({ referenceUrl: WASTE_MANAGEMENT_TYPES_ENUM });

  const isFiveHazardClass = useMemo(
    () => application.hazard_class.length === 1 && application.hazard_class
      .some(({ value }) => value === 'five'),
    [application.hazard_class],
  );

  const addWasteGenerator = () => {
    const cloneGenerators = application.waste_generators.slice();
    cloneGenerators.push({
      ...initialSubject,
      id: Math.random(),
      waste_generator_documents: [{ ...initialDocument }],
      objects: [{ ...initialGeneratorObject }],
    });

    setApplication((prevState) => ({
      ...prevState,
      waste_generators: cloneGenerators,
    }));
  };

  const deleteWasteGenerator = (wasteGeneratorIndex) => () => {
    const cloneWasteGenerators = application.waste_generators.slice();
    cloneWasteGenerators.splice(wasteGeneratorIndex, 1);
    setApplication((prevState) => ({
      ...prevState,
      waste_generators: cloneWasteGenerators,
    }));
  };

  const deleteWasteGeneratorDocument = ({ generatorIndex, index }) => {
    const cloneDocuments = application.waste_generators[generatorIndex].waste_generator_documents
      .slice();
    cloneDocuments.splice(index, 1);
    const cloneApplication = objectChangeDotProperty({
      field: `waste_generators.${generatorIndex}.waste_generator_documents`,
      value: cloneDocuments,
      object: application,
    });
    setApplication(cloneApplication);

    const cloneDocumentsError = error.waste_generators[generatorIndex].waste_generator_documents
      .slice();
    cloneDocumentsError.splice(index, 1);
    const cloneApplicationError = objectChangeDotProperty({
      field: `waste_generators.${generatorIndex}.waste_generator_documents`,
      value: cloneDocumentsError,
      object: error,
    });
    setError(cloneApplicationError);
  };

  const setFullFilledValue = ({ value, field, errorValue }) => {
    const cloneApplication = objectChangeDotProperty({
      field,
      value,
      object: application,
    });

    setApplication(cloneApplication);

    const cloneError = objectChangeDotProperty({
      field,
      value: errorValue,
      object: error,
    });
    setError(cloneError);
  };

  const fullFillSubject = ({
    value,
    object,
    field,
    errorValue = {},
  }) => {
    const fullFilled = {
      ...object,
      inn: { inn: value.inn },
      id: value.id,
      name: {
        id: value.id,
        name: value.name,
      },
      subject_type: {
        id: value.subject_type_attributes?.id,
        code: value.subject_type_attributes?.attributes?.code,
        name: value.subject_type_attributes?.attributes?.name,
      },
      ogrn: { ogrn: value.ogrn },
    };

    if (Array.isArray(object.objects)) fullFilled.objects = [cloneDeep(initialGeneratorObject)];
    else fullFilled.object = cloneDeep(initialRecipientObject);

    setFullFilledValue({
      field,
      value: fullFilled,
      errorValue,
    });
  };

  const fullFillObject = ({
    value,
    object,
    field,
    errorValue = {},
  }, objectType) => {
    const isGenerator = objectType !== 'recipient';

    if (!value.address_id) {
      const newValue = {
        ...object,
        name: {
          id: '',
          name: '',
        },
        id: '',
        address: { ...DEFAULT_AUTOCOMPLETE_OPTION },
      };

      if (isGenerator) {
        newValue[WASTE_MANAGEMENT_ENUM] = { code: '', name: '', id: '' };
      }

      return setFullFilledValue({
        field,
        value: newValue,
        errorValue,
      });
    }

    const fullFilled = {
      ...object,
      name: {
        id: value.id,
        name: value.name,
      },
      id: value.id,
      address: {
        id: value.address_id,
        value: value.address_id,
        label: formatAddressString(value.address_attributes?.attributes),
      },
    };

    if (isGenerator) {
      fullFilled[WASTE_MANAGEMENT_ENUM] = {
        id: value?.waste_management_type_attributes?.id,
        name: value?.waste_management_type_attributes?.attributes?.name,
        code: value?.waste_management_type_attributes?.attributes?.code,
      };
    }

    setFullFilledValue({
      field,
      value: fullFilled,
      errorValue,
    });
  };

  const changeGeneratorByIndex = async ({
    field,
    value,
    generatorIndex,
    index,
    name,
  }) => {
    let dotField = `waste_generators.${generatorIndex}`;

    if (name === 'file' || name === 'document_name') {
      dotField += `.waste_generator_documents.${index}`;
    }

    if (field) {
      dotField = `waste_generators.${generatorIndex}.${field}.${index}`;
    }

    if (name === 'name' && !value.id && !value.name && field !== OBJECTS_FIELD) {
      return fullFillSubject({
        field: dotField,
        value: { ...defaultSubject },
        object: application.waste_generators[generatorIndex],
        errorValue: {
          ...error?.waste_generators?.[generatorIndex],
          inn: { inn: '' },
          name: { id: '', name: '' },
          ogrn: { ogrn: '' },
          subject_type: { name: '', code: '', id: '' },
        },
      });
    }

    if (value?.inn && value?.name) {
      return fullFillSubject({
        field: dotField,
        value,
        object: application.waste_generators[generatorIndex],
        errorValue: {
          ...error?.waste_generators?.[generatorIndex],
          inn: { inn: '' },
          name: { id: '', name: '' },
          ogrn: { ogrn: '' },
          subject_type: { name: '', code: '', id: '' },
        },
      });
    }

    if (field === OBJECTS_FIELD) {
      if ((name === 'name' && value.type === 'objective')
        || (name === 'name' && !value.id && !value.name)
      ) {
        const generatorsFullFilled = {
          field: dotField,
          value,
          object: application.waste_generators[generatorIndex][field][index],
        };

        return fullFillObject(generatorsFullFilled);
      }
    }

    const cloneApplication = objectChangeDotProperty({
      field: `${dotField}.${name}`,
      value,
      object: application,
    });

    setApplication(cloneApplication);

    const cloneError = objectChangeDotProperty({
      field: `${dotField}.${name}`,
      value: '',
      object: error,
    });
    setError(cloneError);
  };

  const checkGeneratorsError = () => {
    let hasGeneratorsError = false;
    const wasteGeneratorsError = application.waste_generators.map((wasteGeneratorItem) => {
      const { hasErrors: hasSubjectErrors, validField: subjectValidField } = formValidator({
        form: getObjectValuesByField(initialSubject, wasteGeneratorItem),
      });

      const generatorDocumentsErrors = [];
      wasteGeneratorItem.waste_generator_documents.forEach((item) => {
        const {
          hasErrors: hasGeneratorErrors,
          validField: generatorValidField,
        } = formValidator({
          form: { file: !!item.file, document_name: item.document_name },
        });
        if (hasGeneratorErrors) hasGeneratorsError = true;

        generatorDocumentsErrors.push(generatorValidField);
      });

      if (hasSubjectErrors) hasGeneratorsError = true;

      const objectsError = wasteGeneratorItem.objects.map((object) => {
        const { hasErrors, validField } = formValidator({
          form: getObjectValuesByField(initialGeneratorObject, object),
          ignoreInputs: [WASTE_MANAGEMENT_ENUM],
        });
        if (hasErrors && !hasGeneratorsError) hasGeneratorsError = true;
        return validField;
      });

      return {
        ...subjectValidField,
        waste_generator_documents: generatorDocumentsErrors,
        objects: objectsError,
      };
    });
    return { hasGeneratorsError, wasteGeneratorsError };
  };

  const changeWasteRecipient = ({
    value,
    field,
    name,
  }) => {
    const wasteRecipientProps = {
      value,
      field,
      errorValue: '',
    };

    if (name === 'name' && !value.name && !value.id && !field.includes('object')) {
      wasteRecipientProps.object = application.waste_recipient;
      wasteRecipientProps.value = { ...defaultSubject };
      return fullFillSubject(wasteRecipientProps);
    }

    if ((name === 'inn' || name === 'name') && value?.name && value?.inn) {
      wasteRecipientProps.object = application.waste_recipient;
      return fullFillSubject(wasteRecipientProps);
    }

    if (field.includes('object')) {
      if (value?.name && value?.address_attributes) {
        const isGenerator = !field.includes('waste_recipient');

        const newObjectValue = {
          id: value.id,
          name: {
            name: value.name,
            id: value.id,
          },
          address: {
            id: value.address_attributes.id,
            value: value.address_attributes.id,
            ...value.address_attributes.attributes,
            label: formatAddressString(value.address_attributes.attributes),
          },
        };

        if (isGenerator) {
          newObjectValue[WASTE_MANAGEMENT_ENUM] = {
            id: value.waste_management_type_attributes?.id,
            code: value.waste_management_type_attributes?.attributes?.code,
            name: value.waste_management_type_attributes?.attributes?.name,
          };
        }

        const cloneApplication = objectChangeDotProperty({
          field,
          value: newObjectValue,
          object: application,
        });

        setApplication(cloneApplication);

        const cloneError = objectChangeDotProperty({
          field,
          value: {},
          object: error,
        });

        setError(cloneError);

        return;
      }

      if ((name === 'name' && value.type === 'objective')
        || (name === 'name' && !value.id && !value.name)
      ) {
        wasteRecipientProps.object = application.waste_recipient.object;
        return fullFillObject(wasteRecipientProps, 'recipient');
      }
    }

    const cloneApplication = objectChangeDotProperty({
      field: `${field}.${name}`,
      value,
      object: application,
    });

    const cloneError = objectChangeDotProperty({
      field: `${field}.${name}`,
      value: '',
      object: error,
    });

    setApplication(cloneApplication);
    setError(cloneError);
  };

  const changeWasteRecipientDocument = ({ field, value }) => {
    const cloneApplication = objectChangeDotProperty({
      field: `waste_recipient_document.${field}`,
      value: field === 'file' && !value ? initialDocument.file : value,
      object: application,
    });

    setApplication(cloneApplication);

    const errorApplication = objectChangeDotProperty({
      field: `waste_recipient_document.${field}`,
      value: '',
      object: error,
    });

    setError(errorApplication);
  };

  const onChange = (
    e,
    value,
    { index, generatorIndex, field } = DEFAULT_ADDITIONAL_PROPS,
  ) => {
    const { target: { name } } = e;

    if (name.includes('waste_recipient_document')) {
      changeWasteRecipientDocument({ field, value });
      return;
    }

    if (generatorIndex !== undefined && generatorIndex !== null) {
      return changeGeneratorByIndex({
        index, generatorIndex, field, name, value,
      });
    }
    if (field?.includes?.('waste_recipient')) {
      changeWasteRecipient({ field, value, name });
      return;
    }

    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));

    let autoCompleteVehicle = {};

    if (name === 'egrz') {
      if (value?.egrz && value?.brand) {
        autoCompleteVehicle = {
          egrz_trailer: value.egrz_trailer,
          brand: value.brand,
          model: value.model,
        };

        setError((prevState) => ({
          ...prevState,
          brand: '',
          model: '',
          egrz: '',
        }));
      }
      if (!value.egrz) {
        autoCompleteVehicle = {
          egrz_trailer: '',
          brand: '',
          model: '',
          egrz: { egrz: '' },
        };
      }
    }

    setApplication((prevState) => ({
      ...prevState,
      [name]: value,
      ...autoCompleteVehicle,
    }));
  };

  const deleteItem = (generatorIndex) => (field, index) => {
    const {
      cloneField,
      cloneWasteGenerator,
      cloneWasteGenerators,
    } = getCloneGenerator({
      object: application, field, generatorIndex, itemIndex: index,
    });

    changeGenerators({
      setter: setApplication,
      cloneField,
      cloneWasteGenerators,
      cloneWasteGenerator,
      generatorIndex,
      field,
      deleteCount: 1,
      index,
    });
  };

  const addItem = (generatorIndex) => (field) => {
    const {
      cloneField,
      cloneWasteGenerator,
      cloneWasteGenerators,
    } = getCloneGenerator({
      object: application, field, generatorIndex,
    });

    changeGenerators({
      setter: setApplication,
      cloneField,
      cloneWasteGenerators,
      cloneWasteGenerator,
      generatorIndex,
      field,
      deleteCount: 0,
      index: cloneField.length,
      item: { ...wasteGenerator[field] },
    });
  };

  const createApplication = async () => {
    const { hasGeneratorsError, wasteGeneratorsError } = checkGeneratorsError();

    const ignoreInputs = ['egrz_trailer', HAZARD_CLASS_ENUM];

    if (isFiveHazardClass) ignoreInputs.push('is_hazard_class_rule');

    const { hasErrors, validField } = formValidator({
      form: {
        ...application,
        egrz: { egrz: application.egrz.id },
        [WASTE_MANAGEMENT_ENUM]: application?.[WASTE_MANAGEMENT_ENUM]?.id,
      },
      ignoreInputs,
    });

    const {
      hasErrors: hasWasteRecipientObjectError,
      validField: recipientObjectError,
    } = formValidator({
      form: getObjectValuesByField(
        initialApplication.waste_recipient.object,
        application.waste_recipient.object,
      ),
    });

    const {
      hasErrors: hasWasteRecipientSubjectError,
      validField: recipientSubjectError,
    } = formValidator({
      form: getObjectValuesByField(
        initialApplication.waste_recipient,
        application.waste_recipient,
      ),
    });

    setError({
      ...validField,
      waste_generators: wasteGeneratorsError,
      waste_recipient: {
        ...recipientSubjectError,
        object: recipientObjectError,
      },
    });

    if (
      hasGeneratorsError
      || hasErrors
      || hasWasteRecipientObjectError
      || hasWasteRecipientSubjectError
    ) {
      throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };
    }

    const application_generators_attributes = [];

    application.waste_generators.forEach(({
      objects, waste_generator_documents,
    }) => {
      const objectives = objects.map(({ id: objective_id }, index) => ({
        priority: index + 1,
        objective_id,
        document_name: waste_generator_documents[0].document_name,
      }));

      application_generators_attributes.push(...objectives);
    });

    const wasteForm = {};

    if (application[HAZARD_CLASS_ENUM].length) {
      wasteForm[HAZARD_CLASS_ENUM] = application[HAZARD_CLASS_ENUM].map(({ id }) => id);
    }

    const newApplication = {
      ...wasteForm,
      subject_id: userStore.id,
      vehicle_id: application.egrz.id,
      application_objectives_attributes: [
        ...application_generators_attributes,
      ],
      [WASTE_MANAGEMENT_TYPE_ID]: application[WASTE_MANAGEMENT_ENUM]?.id,
      objective_id: application.waste_recipient.object.id,
      document_name: application.waste_recipient_document.document_name,
    };

    const createdApplication = await createApplicationRequest(newApplication);

    const application_objectives_attributes = createdApplication?.application_objectives_attributes;
    // eslint-disable-next-line no-restricted-syntax,no-unsafe-optional-chaining
    for await (const application_objective of application_objectives_attributes) {
      const formData = new FormData();

      const applicationObjectiveAttr = application_objective?.attributes;

      const applicationObjectiveDocumentName = applicationObjectiveAttr?.document_name;

      if (applicationObjectiveDocumentName) {
        const foundWasteGeneratorByDocumentName = application.waste_generators
          .find((wasteGeneratorItem) => {
            const hasSameDocumentName = wasteGeneratorItem.waste_generator_documents
              .some((wasteGeneratorDocument) => wasteGeneratorDocument.document_name === applicationObjectiveDocumentName);

            const hasSameSubjectId = compareId(
              wasteGeneratorItem.name.id,
              applicationObjectiveAttr.objective_attributes.attributes.subject_id,
            );

            return (hasSameSubjectId && hasSameDocumentName);
          });

        const file = foundWasteGeneratorByDocumentName?.waste_generator_documents?.[0]?.file;

        if (file) {
          formData.append('application_objective[document]', file);
          formData.append('application_objective[application_id]', createdApplication?.id);
          formData.append('application_objective[objective_id]', applicationObjectiveAttr?.objective_id);
          await updateApplicationObjectivesRequest(application_objective.id, formData);
        }
      }
    }

    const formData = new FormData();
    formData.append('application[document]', application.waste_recipient_document.file);
    formData.append('application[objective_id]', createdApplication.objective_id);
    await updateApplicationRequest(
      createdApplication.id,
      formData,
      { 'Content-Type': 'multipart/form-data' },
    );

    close(true);

    navigate(getParamsFromObject({
      object: { modal: 'application', modalId: createdApplication.id },
    }));
  };

  const { makeRequest: onCreateApplication, isLoading } = useApi({
    request: createApplication,
    successMessage: 'Разрешение успешно создано',
  });

  const openVehicleModal = () => setVehicleInfo({
    egrz_trailer: application.egrz_trailer,
    egrz: application.egrz.egrz,
    model: application.model,
    brand: application.brand,
  });

  const closeNewSubjectModal = () => {
    if (newGeneratorSubjectIndex !== undefined) setNewGeneratorSubjectIndex();
    else setIsOpenRecipientSubjectModal(false);
  };

  const setNewSubject = (newSubject) => {
    if (newGeneratorSubjectIndex !== undefined) {
      onChange(
        { target: { name: 'inn' } },
        newSubject,
        { generatorIndex: newGeneratorSubjectIndex },
      );
    } else {
      onChange(
        { target: { name: 'inn' } },
        newSubject,
        { field: 'waste_recipient' },
      );
    }
    closeNewSubjectModal();
  };

  const setNewObject = (newObject) => {
    if (newGeneratorObjectIndex.generatorIndex !== null) {
      onChange(
        { target: { name: 'name' } },
        newObject,
        {
          generatorIndex: newGeneratorObjectIndex.generatorIndex,
          index: newGeneratorObjectIndex.index,
          field: OBJECTS_FIELD,
        },
      );
    } else {
      onChange(
        { target: { name: 'name' } },
        newObject,
        { field: 'waste_recipient.object' },
      );
    }
  };

  const openNewSubjectModal = (generatorIndex) => {
    if (generatorIndex !== undefined) setNewGeneratorSubjectIndex(generatorIndex);
    else setIsOpenRecipientSubjectModal(true);
  };

  const openNewObjectModal = (generatorIndex, index) => {
    if (generatorIndex !== undefined) setNewGeneratorObjectIndex({ generatorIndex, index });
    else setIsOpenRecipientObjectModal(true);
  };

  const closeNewObjectModal = () => {
    if (newGeneratorObjectIndex.generatorIndex !== null) {
      setNewGeneratorObjectIndex({ ...initialNewObject });
    } else {
      setIsOpenRecipientObjectModal(false);
    }
  };

  const closeVehicleModal = () => setVehicleInfo({});

  const setNewVehicle = (newVehicle) => {
    onChange({ target: { name: 'egrz' } }, newVehicle);
  };

  const getSubjectOfNewObject = () => {
    const isGeneratorObject = newGeneratorObjectIndex.generatorIndex !== null;
    let wasteGeneratorSubject;

    if (isGeneratorObject) {
      const newObjectWasteGeneratorSlot = application
        .waste_generators[newGeneratorObjectIndex.generatorIndex];

      const generatorObject = newObjectWasteGeneratorSlot
        ?.objects?.[newGeneratorObjectIndex.index];

      const generatorAddress = generatorObject?.address;

      wasteGeneratorSubject = {};

      if (newObjectWasteGeneratorSlot.name.id) {
        wasteGeneratorSubject = {
          subject: newObjectWasteGeneratorSlot.name,
          inn: newObjectWasteGeneratorSlot.inn,
          subject_type: newObjectWasteGeneratorSlot.subject_type.name,
        };
      }

      if (generatorAddress?.id) wasteGeneratorSubject.address = generatorAddress;

      wasteGeneratorSubject.name = generatorObject.name.name;
    }

    let wasteRecipientSubject;

    if (application.waste_recipient) {
      wasteRecipientSubject = {};

      const newObjectWasteRecipientSlot = application.waste_recipient;

      if (newObjectWasteRecipientSlot.name.id) {
        wasteRecipientSubject = {
          subject: newObjectWasteRecipientSlot.name,
          inn: newObjectWasteRecipientSlot.inn,
          subject_type: newObjectWasteRecipientSlot?.subject_type?.name || '',
        };
      }

      wasteRecipientSubject.name = newObjectWasteRecipientSlot.object.name.name;

      const newAddress = newObjectWasteRecipientSlot.object.address;

      if (newAddress.id) wasteRecipientSubject.address = newAddress;
    }

    return wasteGeneratorSubject || wasteRecipientSubject;
  };

  const getSubjectOfNewSubject = () => {
    const newSubjectWasteGeneratorSlot = application
      .waste_generators[newGeneratorSubjectIndex];

    const wasteGeneratorSubject = newGeneratorSubjectIndex !== undefined
      && {
        inn: newSubjectWasteGeneratorSlot?.inn?.inn,
        name: newSubjectWasteGeneratorSlot?.name?.name,
        ogrn: newSubjectWasteGeneratorSlot?.ogrn?.ogrn,
        ogrnip: newSubjectWasteGeneratorSlot?.ogrn?.ogrn,
      };

    if (wasteGeneratorSubject && newSubjectWasteGeneratorSlot?.subject_type_attributes?.id) {
      wasteGeneratorSubject.subject_type = {
        id: newSubjectWasteGeneratorSlot.subject_type_attributes?.id,
        code: newSubjectWasteGeneratorSlot.subject_type_attributes?.attributes?.code,
        name: newSubjectWasteGeneratorSlot.subject_type_attributes?.attributes?.name,
      };
    }

    const newSubjectWasteRecipientSlot = application.waste_recipient;

    const wasteRecipientSubject = {
      inn: newSubjectWasteRecipientSlot.inn?.inn,
      name: newSubjectWasteRecipientSlot.name?.name,
      ogrn: newSubjectWasteRecipientSlot.ogrn?.ogrn,
      ogrnip: newSubjectWasteRecipientSlot.ogrn?.ogrn,
    };

    if (wasteRecipientSubject && newSubjectWasteRecipientSlot?.subject_type_attributes?.id) {
      wasteRecipientSubject.subject_type = {
        id: newSubjectWasteRecipientSlot.subject_type.id,
        code: newSubjectWasteRecipientSlot.subject_type_attributes?.attributes?.code,
        name: newSubjectWasteRecipientSlot.subject_type_attributes?.attributes?.name,
      };
    }

    return wasteGeneratorSubject || wasteRecipientSubject;
  };

  const { defaultRequest: getDocumentApi } = useApi({
    request: applicationDocumentRequest,
  });

  const { defaultRequest: getObjectivesDocumentApi } = useApi({
    request: applicationObjectivesDocumentRequest,
  });

  useEffect(() => {
    setApplication(cloneDeep(initialApplication));

    (async () => {
      if (applicationData) {
        const wasteGeneratorsData = [];
        setIsAppliationLoading(true);

        for await (const waste_generator of applicationData.application_objectives_attributes) {
          const currentObject = waste_generator.attributes.objective_attributes || {};
          const currentObjectAttributes = currentObject?.attributes || {};
          const currentObjectAddress = currentObjectAttributes?.address_attributes || {};
          const currentSubject = currentObjectAttributes?.subject_attributes || {};
          const currentSubjectAttributes = currentObjectAttributes?.subject_attributes?.attributes || {};
          const waste_generator_documents = [];

          const response = await getObjectivesDocumentApi(waste_generator.id);

          const file = response?.data;

          if (file) {
            waste_generator_documents.push({
              document_name: waste_generator.attributes?.document_name,
              file,
            });
          } else {
            waste_generator_documents.push({ ...initialDocument });
          }

          const generatorIndex = wasteGeneratorsData
            .findIndex(({ name, waste_generator_documents: generatorDocuments }) => {
              const hasSameSubject = compareId(name.id, currentObjectAttributes.subject_id);
              const hasSameDocumentName = generatorDocuments
                .some((generatorsDocument) => compareId(
                  generatorsDocument.document_name,
                  waste_generator?.attributes?.document_name,
                ));

              return hasSameSubject && hasSameDocumentName;
            });

          const hasSubjectGenerator = generatorIndex !== -1;

          if (hasSubjectGenerator) {
            wasteGeneratorsData[generatorIndex].objects.push({
              id: currentObject?.id,
              name: {
                name: currentObjectAttributes.name,
                id: currentObject?.id,
              },
              address: {
                id: currentObjectAddress.id,
                ...currentObjectAddress.attributes,
                label: formatAddressString(currentObjectAddress.attributes),
                value: currentObjectAddress.id,
              },
            });
          } else {
            const objects = [{
              id: currentObject?.id,
              name: {
                name: currentObjectAttributes.name,
                id: currentObject?.id,
              },
              address: {
                id: currentObjectAddress.id,
                ...currentObjectAddress.attributes,
                label: formatAddressString(currentObjectAddress.attributes),
                value: currentObjectAddress.id,
              },
            }];

            wasteGeneratorsData.push({
              ...initialSubject,
              id: waste_generator.id,
              waste_generator_documents,
              inn: { inn: currentSubjectAttributes?.inn },
              name: { name: currentSubjectAttributes?.name, id: currentSubject.id },
              ogrn: { ogrn: currentSubjectAttributes?.ogrn },
              subject_type: {
                id: currentSubjectAttributes.subject_type_attributes?.id || '',
                name: currentSubjectAttributes.subject_type_attributes?.attributes?.name || '',
                code: currentSubjectAttributes.subject_type_attributes?.attributes?.code || '',
              },
              objects,
            });
          }
        }

        const currentVehicle = {
          id: applicationData.vehicle_attributes?.id,
          ...applicationData.vehicle_attributes?.attributes,
        };

        const recipientObjective = applicationData?.objective_attributes?.attributes;
        const recipientSubject = recipientObjective?.subject_attributes;
        const recipientSubjectAttributes = recipientSubject?.attributes;
        const recipientAddress = recipientObjective?.address_attributes;

        const copiedApplication = {
          waste_generators: wasteGeneratorsData,
          waste_recipient: {
            ...initialSubject,
            id: applicationData?.objective_id,
            inn: { inn: recipientSubjectAttributes?.inn },
            name: { name: recipientSubjectAttributes?.name, id: recipientSubject?.id },
            ogrn: { ogrn: recipientSubjectAttributes?.ogrn },
            subject_type: {
              id: recipientSubjectAttributes?.subject_type_attributes?.id,
              name: recipientSubjectAttributes?.subject_type_attributes?.attributes?.name,
              code: recipientSubjectAttributes?.subject_type_attributes?.attributes?.code,
            },
            object: {
              id: applicationData.objective_id,
              name: { name: recipientObjective?.name, id: applicationData.objective_id },
              address: {
                id: recipientAddress?.id,
                ...recipientAddress?.attributes,
                label: formatAddressString(recipientAddress?.attributes),
                value: recipientAddress?.id,
              },
            },
          },
          egrz: { ...currentVehicle },
          egrz_trailer: currentVehicle?.egrz_trailer,
          model: currentVehicle?.model || '',
          brand: currentVehicle?.brand,
          [WASTE_MANAGEMENT_ENUM]: {
            id: applicationData?.waste_management_type_attributes?.id || '',
            code: applicationData?.waste_management_type_attributes?.attributes?.code || '',
            name: applicationData?.waste_management_type_attributes?.attributes?.name || '',
          },
          [HAZARD_CLASS_ENUM]: applicationData[HAZARD_CLASS_ENUM].map((hazardCode, index) => ({
            id: hazardCode,
            value: hazardCode,
            label: applicationData[HAZARD_CLASS_ENUM_TEXT][index],
          })),
        };

        if (applicationData.document_url) {
          const response = await getDocumentApi(applicationData.id);

          const file = response?.data;

          if (file) {
            copiedApplication.waste_recipient_document = {
              document_name: applicationData?.document_name,
              file,
            };
          } else {
            copiedApplication.waste_recipient_document = { ...initialDocument };
          }
        }

        setApplication((prevState) => ({
          ...prevState,
          ...copiedApplication,
        }));
        setIsAppliationLoading(false);
      }
    })();
    /* eslint-disable-next-line */
  }, []);

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const recipientWasteManagementTypes = getWasteTypeIdsByCode(['pending', 'utilization', 'neutralization', 'placing']);
  const generatingWasteManagementTypes = getWasteTypeIdsByCode(['generating']);
  const generatingEnterpriseTypes = getEnterpriseTypeIdsByCode(['generating']);

  const mainLoading = isLoading || isApplicationLoading;

  return (
    <ModalWithTitle
      open
      containerSx={{ maxHeight: '100vh', width: '100vw' }}
      mainSx={{ bgcolor: 'white' }}
      close={close}
      subTitle="Создание разрешения на перемещение отходов"
    >
      <BackdropLoading isLoading={mainLoading} />
      <AlertRequiredFields sx={{ mb: 3 }} />
      {vehicleInfo.egrz !== undefined && (
        <ModalAddVehicle
          setNewVehicle={setNewVehicle}
          vehicle={vehicleInfo}
          close={closeVehicleModal}
        />
      )}
      {(newGeneratorSubjectIndex !== undefined || isOpenRecipientSubjectModal) && (
        <ModalAddSubject
          division="internal"
          divisionSubjectType={isOpenRecipientSubjectModal ? 'recipient' : 'generator'}
          subjectData={getSubjectOfNewSubject()}
          close={closeNewSubjectModal}
          setNewSubject={setNewSubject}
        />
      )}
      {(newGeneratorObjectIndex.generatorIndex !== null || isOpenRecipientObjectModal) && (
        <ModalAddObject
          objectTypeTitle={!isOpenRecipientObjectModal ? 'отходообразователя' : undefined}
          close={closeNewObjectModal}
          subjectData={getSubjectOfNewObject()}
          division="internal"
          wasteManagementTypes={isOpenRecipientObjectModal
            ? recipientWasteManagementTypes
            : generatingWasteManagementTypes}
          setNewObject={setNewObject}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography fontWeight="bold">Автомобиль</Typography>
          <InputsBoxStyled>
            <AsyncAutocomplete
              required
              request={(params) => getSubjectRequest({
                id: userStore.id,
                shouldCache: true,
                url: VEHICLES_URL,
                params,
              })}
              name="egrz"
              label="ЕГРЗ ТС (Номер ТС)"
              noOptionComponent={(
                <AddButton variant="outlined" onClick={openVehicleModal}>
                  Создать автомобиль
                </AddButton>
              )}
              optionField={{
                value: 'egrz',
                id: 'egrz',
                label: 'egrz',
              }}
              value={application.egrz}
              error={error.egrz?.egrz}
              onChange={onChange}
            />
            <CustomInput
              value={application.brand}
              name="brand"
              label="Марка"
              error={error.brand}
              onChange={onChange}
            />
            <CustomInput
              name="model"
              label="Модель"
              value={application.model}
              error={error.model}
              onChange={onChange}
            />
            <CustomInput
              name="egrz_trailer"
              label="ЕГРЗ прицепа"
              value={application.egrz_trailer}
              error={error.egrz_trailer}
              onChange={onChange}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
                return e;
              }}
            />
          </InputsBoxStyled>
          <Typography fontWeight="bold">
            Классы опасности транспортируемых (перемещаемых) отходов на территории Ленинградской области
          </Typography>
          <InputsBoxStyled>
            <Selector
              multiple
              sx={{ width: tabletSizeAndLower ? '100%' : '600px' }}
              label="Класс отходов"
              request={(params) => getApplicationEnumsRequest(
                HAZARD_CLASS_ENUM,
                params,
                (options) => options.filter((item) => item.id !== 'one' && item.id !== 'two'),
              )}
              value={application[HAZARD_CLASS_ENUM]}
              optionField={DEFAULT_AUTOCOMPLETE_FIELD}
              error={error?.[HAZARD_CLASS_ENUM]?.id}
              onChange={onChange}
              name={HAZARD_CLASS_ENUM}
            />
            {!isFiveHazardClass && (
              <CustomCheckbox
                required
                onChange={onChange}
                name="is_hazard_class_rule"
                value={application.is_hazard_class_rule}
                error={error?.is_hazard_class_rule}
                label="Соответствие работников, допущенных к обращению с отходами I-IV классов опасности, требованиям статьи 15 Федерального закона от 24.06.1998 № 89-ФЗ «Об отходах производства и потребления»"
              />
            )}
          </InputsBoxStyled>
          {application.waste_generators.map((item, index) => (
            <WasteGenerator
              key={item.id}
              onChange={onChange}
              deleteDocument={deleteWasteGeneratorDocument}
              error={error.waste_generators?.[index]}
              deleteItem={deleteItem(index)}
              addItem={addItem(index)}
              generatingEnterpriseTypes={generatingEnterpriseTypes}
              generatingWasteManagementTypes={generatingWasteManagementTypes}
              openNewSubjectModal={() => openNewSubjectModal(index)}
              openNewObjectModal={openNewObjectModal}
              number={index + 1}
              wasteGenerator={item}
              deleteWasteGenerator={
                application.waste_generators.length > 1
                && deleteWasteGenerator(index)
              }
            />
          ))}
          <AddButton variant="outlined" onClick={addWasteGenerator}>Добавить отходообразователя</AddButton>
          <WasteRecipient
            openNewObjectModal={() => openNewObjectModal()}
            openNewSubjectModal={() => openNewSubjectModal()}
            onChange={onChange}
            error={error.waste_recipient}
            errorWasteManagementType={error[WASTE_MANAGEMENT_ENUM]}
            wasteManagementTypeIds={recipientWasteManagementTypes}
            subjectTypeIds={getEnterpriseTypeIdsByCode(['pending', 'utilization', 'neutralization', 'placing'])}
            wasteRecipient={application.waste_recipient}
            recipientWasteManagementType={application[WASTE_MANAGEMENT_ENUM]}
          />
          <Box display="flex" flexDirection="column" gap={3} mb={1}>
            <Typography fontWeight="bold">Договор с получателем отходов:</Typography>
            <Box display="flex" alignItems="center" flexWrap="flex" gap={5}>
              <CustomInput
                required
                label="Номер договора"
                value={application.waste_recipient_document.document_name}
                name="waste_recipient_document"
                error={error.waste_recipient_document?.document_name}
                onChange={(e, value) => onChange(e, value, { field: 'document_name' })}
              />
              <FileUpload
                required
                isFileReadyToShow
                extensions={['*']}
                fileName={application.waste_recipient_document?.document_name}
                setFile={(e, value) => onChange(e, value, { field: 'file' })}
                onChange={(e, values) => onChange(e, values[0]?.file, { field: 'file' })}
                id="waste_recipient_document"
                name="waste_recipient_document"
                error={error.waste_recipient_document?.file}
                limitSize={SIZE_TEN_MEGABYTE}
                files={[{ file: application.waste_recipient_document.file, name: application.waste_recipient_document?.document_name }]}
                label="Прикрепить договор с получателем отходов"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomCheckbox
        required
        sx={{ mt: '20px' }}
        formControlSx={{ width: '100%', alignItems: 'center' }}
        label="Достоверность представленных сведений подтверждаю"
        value={application.isActualData}
        name="isActualData"
        error={error.isActualData}
        onChange={onChange}
      />
      <Button
        sx={{
          display: 'flex',
          height: '56px',
          mx: 'auto',
          mt: 3,
          fontSize: 20,
        }}
        color="warning"
        variant="contained"
        onClick={onCreateApplication}
      >
        Создать разрешение
      </Button>
    </ModalWithTitle>
  );
}
