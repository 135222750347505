// @ts-nocheck
import React, {
  memo, useMemo,
} from 'react';
import { Box } from '@mui/material';
import NavTabs from '../../Tabs';
import { getForm1WasteProducts } from '../../../helpers/cadastrHelper';
import Form2WasteProduct from '../../Cadastr/CadastreForm2WasteProduct';
import { filterComponentComposition } from '../../../constants/lkCadastr/cadastrForm2';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import { useCadastreStore } from '../../../store';

function ModalCadastrForm2({
  data: { items: buildings }, onChange, changeItemFormByField, isDemo, onChangeForm2Entity, deleteForm2Entity,
}) {
  const { cadastreStore } = useCadastreStore();


  const filteredItems = useMemo(
    () => filterComponentComposition(getForm1WasteProducts(buildings)),
    [buildings],
  );

  const { tab, setTab, tabs } = useCadastreTabs({ items: filteredItems, tabName: 'Форма отхода' });

  const { openDeleteModal } = useDeleteCadastreEntity();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <NavTabs
        getErrorTab={(tabId) => cadastreStore.errorForm2?.some?.((item) => item.includes('waste') && item.includes(tabId))}
        tabsComponents={tabs}
        setTab={setTab}
        tab={tab}
      />
      {buildings.map((building, buildingIndex) => (
        <React.Fragment key={building?.randId}>
          {building?.waste_products.map((waste_product, wasteProductIndex) => {
            const changeItem = (field, value, componentCompositionIndex) => {
              onChange({
                field: `items.${buildingIndex}.waste_products.${wasteProductIndex}.form2Waste.${field}`,
                value,
                buildingForm2Index: buildingIndex,
                reportWasteIndex: wasteProductIndex,
                componentCompositionIndex,
              });
            };

            const dataIndex = buildingIndex === 0 ? wasteProductIndex : buildings
              .slice(0, buildingIndex)
              .reduce((prev, item) => item.waste_products.length + prev, 0) + wasteProductIndex;

            const changeItemByField = (field, value, type) => changeItemFormByField({
              field: `form1.items.${buildingIndex}.waste_products.${wasteProductIndex}.form2Waste.${field}`,
              value,
              type,
            });

            if (waste_product.randId !== tab) return null;

            return (
              <Form2WasteProduct
                key={waste_product.randId}
                objectName={formatObjectWithAddress({ objective: building?.objective_id })}
                waste_product={waste_product}
                building={building}
                onChangeForm2Entity={onChangeForm2Entity}
                deleteForm2Entity={deleteForm2Entity}
                changeItemFormByField={changeItemByField}
                changeItem={changeItem}
                openDeleteModal={openDeleteModal}
                dataIndex={dataIndex}
                isDemo={isDemo || waste_product.code?.hazard_class === 'five'}
              />
            );
          })}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default memo(ModalCadastrForm2);
