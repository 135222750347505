import React, { useState } from 'react';
import { Box } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MapIcon from '@mui/icons-material/Map';
import CustomTable from '../../components/Tables/CustomTable';
import { useApi, usePushNotification, useTableSearch } from '../../hooks';
import { deleteObjectiveRequest, getObjectiveByAreaRequest, getObjectivesRequest } from '../../api/objectivesApi';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomCheckbox from '../../components/CustomCheckbox';
import ModalAdminAddObject from '../../components/Modals/ModalAdminAddObject';
import { useTableStore } from '../../store';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import LeafletMap from '../../components/Map/LeafletMap';
import { formatLatLngToPostGisLineString } from '../../helpers/mapHelper';
import RedHouse from '../../assets/map-marker-red.svg';
import Selector from '../../components/Selectors/Selector';

const objectRows = [
  {
    headerTitle: 'Наименование объекта',
    field: 'name',
  },
  {
    headerTitle: 'Наименование субъекта',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Статус',
    field: 'status_text',
  },
  { headerTitle: 'ИНН субъекта', field: 'inn', getField: (row) => row?.subject_attributes?.attributes?.inn },
  { headerTitle: 'Регион', field: 'region', getField: (row) => row?.address_attributes?.attributes?.region },
  { headerTitle: 'Район', field: 'district', getField: (row) => row?.address_attributes?.attributes?.district },
  {
    headerTitle: 'Город/Населённый пункт',
    field: 'city',
    getField: (row) => row?.address_attributes?.attributes?.city,
  },
  {
    headerTitle: 'Номер корпуса',
    field: 'hull_number',
    getField: (row) => row?.address_attributes?.attributes?.hull_number,
  },
  {
    headerTitle: 'Номер строения',
    field: 'building_number',
    getField: (row) => row?.address_attributes?.attributes?.building_number,
  },
  { headerTitle: 'Улица', field: 'street', getField: (row) => row?.address_attributes?.attributes?.street },
  { headerTitle: 'Номер дома', field: 'house', getField: (row) => row?.address_attributes?.attributes?.house },
  {
    headerTitle: 'ОГРН (ОГРНИП) субъекта',
    field: 'ogrn',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.ogrn,
  },
  {
    headerTitle: 'Кадастровый номер',
    field: 'cadastral_number',
    getField: (row) => row?.address_attributes?.attributes?.cadastral_number,
  },
];

const objectStatuses = [
  { id: 'draft', value: 'draft', label: 'Черновик' },
  { id: 'published_ter_scheme', value: 'published_ter_scheme', label: 'Опубликован в тер. схеме' },
];

const objectiveRequest = (params, setTableStore) => getObjectivesRequest({ params, setTableStore });

export default function RegistryObjects() {
  const pushNotification = usePushNotification();
  const { setTableStore } = useTableStore();
  const [tableData, setTableData] = useState([]);
  const [objectData, setObjectData] = useState({});
  const [isOpenAddObject, setsIsOpenAddObject] = useState(false);
  const [mapObjectives, setMapObjectives] = useState();
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [withDeleted, setWithDeleted] = useState(true);
  const [objectStatus, setObjectStatus] = useState('');

  const changeWithDeleted = () => setWithDeleted(!withDeleted);

  const closeAddObject = () => {
    setsIsOpenAddObject(false);
    setObjectData({});
  };

  const openAddObject = () => setsIsOpenAddObject(true);

  const onClickRow = (row) => {
    setObjectData(row);
  };

  const { makeRequest: searchObjects, isLoading } = useTableSearch({
    search: objectiveRequest,
    setter: setTableData,
    params: { with_deleted: withDeleted || undefined, status: objectStatus.value || undefined },
    deps: [withDeleted, objectStatus],
  });

  const deleteRow = async ({ id }) => {
    await deleteObjectiveRequest(id);
    setTableStore({ page: 1 });
    searchObjects({ page: 1 });
  };

  const updateObjects = () => {
    setTableStore({ page: 1 });
    searchObjects({ page: 1 });
  };

  const openMap = () => setIsOpenMap(true);

  const closeMap = () => {
    setMapObjectives();
    setIsOpenMap(false);
  };

  const {
    makeRequest: getAreaRequest,
    isLoading: isLoadingArea,
  } = useApi({
    request: getObjectiveByAreaRequest,
    setter: (responseObjectives) => {
      setMapObjectives(responseObjectives.map(({ address_attributes, ...rest }) => ({
        ...rest,
        ...address_attributes.attributes,
        tooltip: rest.name,
      })));
    },
  });

  const onSelectObjects = (mapEvent) => {
    const selectedArea = mapEvent?._latlngs;

    if (!selectedArea?.[0]?.length) {
      return pushNotification({ message: 'Не выбрана область поиска на карте', variant: 'warning' });
    }

    if (selectedArea?.[0]?.length > 2) {
      getAreaRequest({ polygon: formatLatLngToPostGisLineString(selectedArea), total: 100000 });
      mapEvent?._map?.areaSelection?.deactivate();
    }
  };

  const changeObjectStatus = (e, value) => setObjectStatus(value);

  return (
    <>
      {isOpenMap && (
        <ModalWithTitle isLoading={isLoadingArea} close={closeMap} title="Поиск объектов на карте" open>
          <LeafletMap
            height="70vh"
            width="100%"
            selectArea={onSelectObjects}
            clusters={Boolean(mapObjectives?.length) && [{
              points: mapObjectives,
              polygonColor: 'red',
              markerIcon: RedHouse,
              clusterColor: 'red',
              legendText: 'Объекты',
              onClick: onClickRow,
            }]}
          />
        </ModalWithTitle>
      )}
      <Box display="flex" my="16px" justifyContent="flex-end">
        <Box display="flex" mr="auto" alignItems="center" gap={3}>
          <CustomCheckbox
            label="Включать архивные"
            onChange={changeWithDeleted}
            value={withDeleted}
          />
          <Selector label="Статус" value={objectStatus} onChange={changeObjectStatus} options={objectStatuses} />
          {objectStatus && (
            <CustomButton variant="outlined" color="warning" onClick={() => setObjectStatus('')}>Сбросить статус</CustomButton>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap={3}>
          <CustomButton
            variant="contained"
            startIcon={<MapIcon />}
            onClick={openMap}
          >
            Поиск на карте
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<PersonAddAltIcon />}
            onClick={openAddObject}
          >
            Добавить объект
          </CustomButton>
        </Box>
      </Box>

      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={objectRows}
        onClick={onClickRow}
        deleteRow={{
          deleteRequest: deleteRow,
          deleteMessage: 'Объект удалён',
          getDeleteObjectName: (row) => `объект ${row.name}`,
        }}
      />
      {(isOpenAddObject || objectData?.id) && (
        <ModalAdminAddObject
          objectData={objectData}
          updateObjects={updateObjects}
          close={closeAddObject}
        />
      )}
    </>
  );
}
