// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React from 'react';
import { getReferencesRequest } from '../../api/referenceApi';
import { WASTE_CONDITION_ENUM, WASTE_CONDITION_TYPES } from '../../constants/enums';
import { getInitialComponentCompositions } from '../../constants/lkCadastr/cadastrForm2';
import { flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { precisionAdd } from '../../helpers/numberHelper';
import { upperCaseFirstLetter } from '../../helpers/stringHelper';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import CustomAccordion from '../CustomAccordion';
import CustomCheckbox from '../CustomCheckbox';
import CustomDatePicker from '../CustomDatePicker';
import CustomInput from '../Inputs';
import { Text, TextTitle } from '../Text';
import { compareId } from '../../helpers';

const COMPONENT_COMPOSITIONS_NAME = 'component_compositions';

export default function Form2WasteProduct({
  dataIndex,
  isDemo,
  changeItem,
  waste_product,
  changeItemFormByField,
  objectName,
  deleteForm2Entity,
  openDeleteModal,
}) {
  const { form2Waste } = waste_product;

  const sumMassFraction = form2Waste?.[COMPONENT_COMPOSITIONS_NAME]
    ?.reduce?.((prevCount, componentItem) => precisionAdd(prevCount, (Number(componentItem?.mass_fraction) || 0)), 0) || 0;

  return (
    <Box sx={flexGap2}>
      <Box sx={flexGap2}>
        <TextTitle h3>
          {objectName}
        </TextTitle>
        <TextTitle h2>
          Отход №
          {dataIndex + 1}
        </TextTitle>
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <CustomInput
          disabled
          debounceTime={0}
          sx={{ width: '100%' }}
          value={waste_product.code?.label}
          label="Код и наименование отхода"
        />
        <CustomInput
          disabled
          debounceTime={0}
          sx={{ width: '100%' }}
          label="Класс опасности отходов"
          value={upperCaseFirstLetter(waste_product.code?.hazard_class_text)}
        />
        <Box sx={flexRowGap2}>
          <CustomCheckbox
            disabled={isDemo}
            onChange={(e, value) => changeItem('is_developed_passport', value)}
            label="Разработан паспорт отходов"
            value={form2Waste.is_developed_passport}
          />
        </Box>
        {form2Waste.is_developed_passport && (
          <CustomCheckbox
            disabled={isDemo}
            onChange={(e, value) => changeItem('is_passport_approved', value)}
            label="Паспорт утвержден"
            value={form2Waste.is_passport_approved}
          />
        )}
        {
          form2Waste.is_developed_passport && (
            <>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!compareId(form2Waste[WASTE_CONDITION_ENUM].name, 0)}
                debounceTime={0}
                disabled={isDemo}
                sx={{ width: '100%' }}
                label="Агрегатное состояние отходов."
                request={(params) => getReferencesRequest({
                  url: WASTE_CONDITION_TYPES,
                  params: { ...params, total: 1000 },
                })}
                value={form2Waste[WASTE_CONDITION_ENUM]}
                onChange={(e, value) => changeItem(WASTE_CONDITION_ENUM, value)}
              />
              <CustomDatePicker
                canBeZero
                validImmediately
                disabled={isDemo}
                label="Дата утверждения паспорта отходов 1-4 классов опасности"
                value={form2Waste.waste_approval_date}
                onChange={(e, value) => changeItem('waste_approval_date', value)}
              />
              <Box>
                <Text>
                  Наименование технологического процесса,
                  в результате которого образовался отход, или процесса, в
                  результате которого товар (продукция) утратил свои потребительские свойства
                </Text>
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  sx={{ width: '100%' }}
                  value={form2Waste.name_technological_process}
                  onChange={(e, value) => changeItem('name_technological_process', value)}
                />
              </Box>
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                value={form2Waste.name_original_product}
                onChange={(e, value) => changeItem('name_original_product', value)}
                label="Наименование исходного товара"
              />
            </>
          )
        }
        <CustomAccordion title="Компонентные составы отходов">
          <Box sx={flexGap2}>
            <CustomCheckbox
              disabled={isDemo}
              onChange={(e, value) => changeItem('is_known_component_composition', value)}
              label="Известен компонентный состав"
              value={form2Waste.is_known_component_composition}
            />
            {form2Waste.is_known_component_composition && (
              <>
                <TextTitle>
                  Всего (Массовая доля):
                  {' '}
                  {sumMassFraction}
                  %
                </TextTitle>
                {sumMassFraction !== 0 && sumMassFraction < 100 && (
                  <Alert severity="error">Сумма массовой доли не полная</Alert>
                )}
                {form2Waste[COMPONENT_COMPOSITIONS_NAME].map((component_composition, index) => {
                  const onDeleteComponentComposition = async () => {
                    if (component_composition?.id) {
                      const deletedId = await deleteForm2Entity({ componentCompositionId: component_composition?.id });

                      if (!deletedId) return;
                    }

                    const cloneArray = form2Waste[COMPONENT_COMPOSITIONS_NAME].slice();
                    cloneArray.splice(index, 1);

                    changeItemFormByField(COMPONENT_COMPOSITIONS_NAME, cloneArray, 'delete');
                  };

                  return (
                    <Box sx={flexGap2} key={component_composition.randId}>
                      <Box sx={flexRowGap2}>
                        <Text>
                          Компонентный состав отхода №
                          {index + 1}
                        </Text>
                        {!isDemo && form2Waste[COMPONENT_COMPOSITIONS_NAME].length > 1 && (
                          <DeleteButton onClick={() => openDeleteModal(onDeleteComponentComposition, index, 'Компонентный состав')}>
                            Удалить компонентный состав отхода
                          </DeleteButton>
                        )}
                      </Box>
                      <Box display="flex" flexDirection="column" gap={3}>
                        <CustomInput
                          validImmediately
                          debounceTime={0}
                          disabled={isDemo}
                          label="Наименование компонента"
                          value={component_composition.component_name}
                          onChange={(e, value) => changeItem(`${COMPONENT_COMPOSITIONS_NAME}.${index}.component_name`, value, index)}
                        />
                        <CustomInput
                          validImmediately
                          debounceTime={0}
                          disabled={isDemo}
                          label="Массовая доля (%)"
                          type="number"
                          value={component_composition.mass_fraction}
                          onChange={(e, value) => changeItem(`${COMPONENT_COMPOSITIONS_NAME}.${index}.mass_fraction`, value, index)}
                        />
                      </Box>
                    </Box>
                  );
                })}
                {!isDemo && (
                  <AddButton
                    onClick={() => changeItemFormByField(
                      COMPONENT_COMPOSITIONS_NAME,
                      [
                        ...form2Waste[COMPONENT_COMPOSITIONS_NAME],
                        getInitialComponentCompositions(),
                      ],
                    )}
                  >
                    Добавить компонентный состав отхода
                  </AddButton>
                )}
              </>
            )}
          </Box>
        </CustomAccordion>
      </Box>
    </Box>
  );
}
