import { compareId } from '../../helpers';

export const getInitialForm5IndustrialArea = () => ({
  randId: Math.random(),
  name: '',
  area_number: 0,
  objective_id: '',
  works_another_waste: [],
  works_self_waste: [],
});

export const getInitialForm5License = () => ({
  randId: Math.random(),
  industrialAreas: [getInitialForm5IndustrialArea()],
  license_number: '',
  license_issue_date: null,
  license_expiration: '',
  is_forever: false,
});

export const getInitialForm5 = () => ({
  licenses: [getInitialForm5License()],
});

export const form5PercentGetter = (forms, { ignoreLicense }) => {
  const {
    licenses,
  } = forms.form5;

  if (ignoreLicense) return 100;

  const licenseLength = Object.keys(getInitialForm5License()).length - ['randId', 'industrialAreas', 'is_forever'].length;
  const industrialAreaLength = [
    'name',
    'area_number',
    'objective_id',
  ].length;

  let allDataLength = 0;

  const totalFilledCount = licenses.reduce((prev, license) => {
    allDataLength += licenseLength;

    let totalLicensesFilled = 0;

    if (license?.license_number) totalLicensesFilled += 1;
    if (license?.license_issue_date || compareId(license?.license_issue_date, 0)) totalLicensesFilled += 1;

    if (!license?.is_forever && (license?.license_expiration || compareId(license?.license_expiration, 0))) totalLicensesFilled += 1;
    if (license?.is_forever) totalLicensesFilled += 1;

    totalLicensesFilled += license.industrialAreas.reduce((prevIndustrialArea, {
      name,
      area_number,
      objective_id,
    }) => {
      allDataLength += industrialAreaLength;

      let totalIndustrialAreaCount = 0;

      if (name) totalIndustrialAreaCount += 1;
      if (area_number) totalIndustrialAreaCount += 1;
      if (objective_id?.id || compareId(objective_id?.label, 0)) totalIndustrialAreaCount += 1;

      return prevIndustrialArea + totalIndustrialAreaCount;
    }, 0);

    return prev + +totalLicensesFilled;
  }, 0);

  return Math.floor((totalFilledCount / allDataLength) * 100);
};
