import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { deleteToken, handleApiError } from '../helpers';
import { isObject } from '../helpers/objectHelper';
import { usePushNotification } from './usePushNotification';

export const handleErrors = (e, pushNotification) => {
  if (e?.logout) {
    pushNotification({ message: 'Перенаправление через 5 секунд', variant: 'warning' });
    setTimeout(() => {
      deleteToken();
      window.location.reload();
    }, 5000);
  }

  const apiError = e?.response;

  // eslint-disable-next-line no-console
  console.error(e, apiError, e?.message);

  if (e?.message === 'canceled') return;

  handleApiError(apiError, pushNotification);
};

let prevLocation = '';

export const useApi = ({
  setter,
  request,
  successMessage,
  setIsLoading,
  shouldRequest = false,
  errorCallback,
  skipError = false,
  cancelPrevious = false,
  cancelWhenChangeLocation = true,
  deps = [],
} = {}) => {
  const { pathname } = useLocation();
  const pushNotification = usePushNotification();
  const [innerLoading, setInnerLoading] = useState(Boolean(shouldRequest));
  const controller = useRef(null);

  const cancelRequest = () => {
    if (controller?.current?.abort) {
      controller.current.abort();
      controller.current = null;
    }
  };

  useEffect(() => {
    prevLocation = pathname;
  }, []);

  useEffect(() => {
    if (cancelWhenChangeLocation && prevLocation !== pathname) {
      cancelRequest();
    }
  }, [cancelWhenChangeLocation, pathname, controller]);

  const makeRequest = async (data) => {
    if (!request) return;

    setIsLoading?.(true);
    setInnerLoading(true);
    try {
      if (cancelPrevious || data?.cancelPrevious) cancelRequest();

      const mainData = data?.target !== undefined || !isObject(data) ? data : { ...data };
      if ((cancelPrevious || cancelWhenChangeLocation) && isObject(mainData)) mainData.controller = controller;

      const result = await request(mainData);
      setter?.(result);
      if (successMessage) pushNotification({ message: successMessage, variant: 'success', autoHideDuration: 1000 });
      setIsLoading?.(false);
      setInnerLoading(false);
      return result;
    } catch (e) {
      if (e?.response?.status !== 0 || e?.frontendError) {
        if (errorCallback) errorCallback(e);

        if (!skipError) {
          if (e?.frontendError || e?.frontendError === null) {
            if (e?.frontendError) pushNotification({ message: e?.frontendError });
          } else {
            handleErrors(e, pushNotification);
          }
        }
      }
    }
    setIsLoading?.(false);
    setInnerLoading(false);
  };

  const debounceRequest = useDebouncedCallback(makeRequest, 200);

  useEffect(() => {
    if (shouldRequest) debounceRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceRequest, shouldRequest, ...deps]);

  return {
    makeRequest: debounceRequest,
    defaultRequest: makeRequest,
    isLoading: innerLoading,
    cancelRequest,
  };
};
