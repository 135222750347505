// @ts-nocheck
import React, { useState } from 'react';
import {
  Box, Paper,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomInput from '../../../components/Inputs';
import { useApi, usePushNotification } from '../../../hooks';
import { compareId, validateEmail } from '../../../helpers';
import BackButton from '../../../components/BackButton';
import CustomButton from '../../../components/Buttons/CustomButton';
import { forgotPasswordSubjectRequest } from '../../../api/subjectApi';
import { forgotPasswordUserRequest } from '../../../api/userApi';
import {
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
  PUBLIC_RESET_PASSWORD_ROUTE,
} from '../../../constants/routes';

const paperStyle = {
  width: '320px', p: '56px', display: 'flex', flexDirection: 'column', position: 'relative',
};

export const userTypes = [
  { name: 'committee', url: PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE },
  { name: 'admin', url: PUBLIC_LOGIN_ADMIN_PAGE_ROUTE },
  { name: 'cadastre-admin', url: PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE },
  { name: 'ter-scheme', url: PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE },
];
export const subjectTypes = [
  { name: 'cadastre', url: PUBLIC_LOGIN_CADASTR_PAGE_ROUTE },
  { name: 'carrier', url: PUBLIC_LOGIN_CARRIER_PAGE_ROUTE },
];

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('type');
  const pushNotification = usePushNotification();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const changeEmail = (e, value) => {
    setEmail(value);
    setError('');
  };

  const resetPassword = async () => {
    const validatedEmail = validateEmail(email);

    if (!validatedEmail) {
      setError('Введён не верный E-mail');
      return;
    }

    const isUser = userTypes.some(({ name }) => compareId(name, userType));

    const mainItem = isUser
      ? userTypes.find((item) => compareId(item.name, userType))
      : subjectTypes.find((item) => compareId(item.name, userType));

    const forgotRequest = isUser ? forgotPasswordUserRequest : forgotPasswordSubjectRequest;

    const body = {
      email,
      redirect_url: `${window.location.origin}${PUBLIC_RESET_PASSWORD_ROUTE}?user_type=${mainItem.name}&reset_password_token=`,
      user_type: mainItem.name,
    };

    await forgotRequest(body);
    pushNotification({ message: 'На вашу почту выслана ссылка для восстановления пароля', variant: 'warning' });
    navigate(-1);
  };

  const { makeRequest: onResetPassword } = useApi({
    request: resetPassword,
  });

  return (
    <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Paper sx={paperStyle}>
        <BackButton path={-1} />
        <CustomInput sx={{ mb: 2 }} label="Введите E-mail" value={email} error={error} onChange={changeEmail} />
        <CustomButton variant="contained" onClick={onResetPassword}>Восстановить пароль</CustomButton>
      </Paper>
    </Box>
  );
}
