import React from 'react';
import LoginPage from '../Public/LoginPage';
import { CADASTR_ADMIN_REPORTS_ROUTE } from '../../constants/routes';
import { loginUserRequest } from '../../api/userApi';

export default function CadastrAdminLoginPage() {
  return (
    <LoginPage
      forgotPasswordParams="cadastre-admin"
      loginRequest={loginUserRequest}
      redirectUrl={CADASTR_ADMIN_REPORTS_ROUTE}
    />
  );
}
