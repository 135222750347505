export const getFormField = (field, filterFields) => {
  for (let i = 0; i <= filterFields.length; i += 1) {
    const searchField = filterFields[i];
    if (field.includes(searchField)) {
      return searchField;
    }
  }
};

export const addTotalCadastreCount = (fields, data, counter, {
  isDebug, errorSetter,
} = {
  isDebug: false,
  index: undefined,
  errorSetter: undefined,
}) => {
  fields.forEach((field) => {
    if (typeof field === 'string' ? ![undefined, null, ''].includes(data[field]) : field.valid(data[field.name], data)) {
      counter();
    } else if (isDebug || errorSetter) {
      if (window?.showCadastreErrors) errorSetter?.();

      if (isDebug) {
        console.dir(field);
        console.dir(data);
        console.dir(data[field]);
        console.dir(field?.valid?.(data[field.name], data));
      }
    }
  });
};
