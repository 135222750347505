import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../../Text';
import DeleteButton from '../../../../Buttons/DeleteButton';
import AddButton from '../../../../Buttons/AddButton';
import {
  getInitialForm8AreaDocuments,
  FORM8_AREA_DOCUMENTS_NAME,
} from '../../../../../constants/lkCadastr/cadastrForm8';
import { fitContent, flexGap2 } from '../../../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../../../Inputs';
import CustomDatePicker from '../../../../CustomDatePicker';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import NavTabs from '../../../../Tabs';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';

export function Form8Areas({
  placingObject, changeObject, isDemo, openDeleteModal,
}) {
  const form8Areas = placingObject[FORM8_AREA_DOCUMENTS_NAME];

  const { tab, setTab, tabs } = useCadastreTabs({ items: form8Areas, tabName: 'Документ' });

  return (
    <Box sx={flexGap2}>
      <TextTitle h3>
        Правоустонавливающий документ на
        земельный участок, на котром расположен объект
      </TextTitle>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeObject(FORM8_AREA_DOCUMENTS_NAME, [...form8Areas, getInitialForm8AreaDocuments()])}
        >
          Добавить правоустонавливающий документ
        </AddButton>
      )}
      <NavTabs tab={tab} setTab={setTab} tabsComponents={tabs} />
      {form8Areas.map((form8Area, form8AreaIndex) => {
        if (tab !== form8Area.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${FORM8_AREA_DOCUMENTS_NAME}.${form8AreaIndex}.${field}`, value, undefined, { areaDocumentIndex: form8AreaIndex });
        };

        const onDelete = async () => {
          const deletedAreaDocument = await deleteForm8Entity({ id: form8Area.id, apiType: FORM8_AREA_DOCUMENTS_NAME });

          if (!deletedAreaDocument) return;

          const cloneArray = form8Areas.slice();
          cloneArray.splice(form8AreaIndex, 1);

          changeObject(FORM8_AREA_DOCUMENTS_NAME, cloneArray, 'delete');
        };

        return (
          <Box key={form8Area.randId} sx={flexGap2}>
            <TextTitle>
              Правоустонавливающий документ
              №
              {form8AreaIndex + 1}
            </TextTitle>
            {!isDemo && form8Areas.length > 1 && form8Area?.id && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, form8AreaIndex, 'Правоустонавливающий документ')}
              >
                Удалить правоустонавливающий документ
              </DeleteButton>
            )}
            <Box sx={flexGap2}>
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Наименование документа о землеотводе"
                value={form8Area.document_name}
                onChange={(e, value) => onChange('document_name', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Наименование органа, выдавшего документ о землеотводе"
                value={form8Area.body_name}
                onChange={(e, value) => onChange('body_name', value)}
              />
              <CustomDatePicker
                canBeZero
                validImmediately
                disabled={isDemo}
                label="Дата выдачи"
                sx={fitContent}
                value={form8Area.issue_date}
                onChange={(e, value) => onChange('issue_date', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Номер"
                sx={fitContent}
                value={form8Area.document_number}
                onChange={(e, value) => onChange('document_number', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Кадастровый номер земельного участка"
                value={form8Area.cadastral_number_area}
                onChange={(e, value) => onChange('cadastral_number_area', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Собственник земельного участка"
                value={form8Area.owner}
                onChange={(e, value) => onChange('owner', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Категория земельного участка"
                value={form8Area.area_category}
                onChange={(e, value) => onChange('area_category', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Вид разрешённого использования"
                value={form8Area.permitted_use_type}
                onChange={(e, value) => onChange('permitted_use_type', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
