import React from 'react';
import { Box } from '@mui/material';
import CustomInput from '../Inputs';
import { INPUT_EMPTY_TEXT } from '../../constants';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import PhoneInput from '../Inputs/PhoneInput';
import CustomButton from '../Buttons/CustomButton';

function CadastrEditInput({
  field, error, onChange, form, optionField, request, multiline, multiple, type,
}) {
  const errorText = typeof error[field] === 'string' ? error[field] : error[field]?.value;
  const inputValue = form[field];

  if (type === 'tel') {
    return (
      <PhoneInput
        name={field}
        onChange={onChange}
        error={errorText}
        value={inputValue}
        label=""
      />
    );
  }

  if (request) {
    return (
      <AsyncAutocomplete
        value={inputValue}
        label=""
        multiple={multiple}
        name={field}
        onChange={onChange}
        error={errorText}
        request={request}
        optionField={optionField || DEFAULT_AUTOCOMPLETE_FIELD}
      />
    );
  }
  return (
    <CustomInput
      name={field}
      onChange={onChange}
      multiline={multiline}
      label=""
      value={inputValue}
      error={errorText}
    />
  );
}

const getCorrectValue = (form, field) => {
  const value = form[field];
  let finalValue = INPUT_EMPTY_TEXT;
  if (value?.label !== undefined) {
    if (value.label) {
      finalValue = value.label;
    }
  } else if (value) {
    finalValue = value;
  }

  return finalValue;
};

export default function CadastrRowEdit({
  error,
  editField,
  changeEdit,
  field,
  getField,
  userInfo,
  makeRequest,
  onChange,
  type,
  request,
  multiline,
  multiple,
}) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {editField[field] ? (
        <CadastrEditInput
          type={type}
          editField={editField}
          field={field}
          getField={getField}
          onChange={onChange}
          request={request}
          error={error}
          form={userInfo}
          multiline={multiline}
          multiple={multiple}
        />
      ) : (
        <Box>
          {getField ? (getField(userInfo) || INPUT_EMPTY_TEXT) : getCorrectValue(userInfo, field)}
        </Box>
      )}
      {editField[field] && (
        <Box display="flex" gap={1}>
          <CustomButton color="error" onClick={() => changeEdit(field, false)}>Отмена</CustomButton>
          <CustomButton onClick={() => makeRequest([field])}>Сохранить</CustomButton>
        </Box>
      )}
    </Box>
  );
}
