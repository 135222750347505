import { Route } from 'react-router-dom';
import React from 'react';
import {
  CARRIER_GENERAL_INFO_ROUTE,
  CARRIER_PERSONAL_ACCOUNT_ROUTE,
} from '../routes';
import CarrierGeneralInfoPage from '../../pages/Carrier/CarrierGeneralInfoPage';
import CarrierPersonalAccountPage from '../../pages/Carrier/CarrierPersonalAccountPage';
import { checkIsPrivateCadastreOrigin } from '../../helpers/browserLocation';

export const getCarrierRoutes = () => (checkIsPrivateCadastreOrigin() ? null : (
  <>
    <Route
      exact
      path={CARRIER_GENERAL_INFO_ROUTE}
      element={<CarrierGeneralInfoPage />}
    />
    <Route
      exact
      path={CARRIER_PERSONAL_ACCOUNT_ROUTE}
      element={<CarrierPersonalAccountPage />}
    />
  </>
));
