import { Route } from 'react-router-dom';
import React from 'react';
import {
  CADASTR_CONTACT_INFO_ROUTE,
  CADASTR_DOCUMENT_RECOMMENDATION_ROUTE, CADASTR_FAQ_ROUTE, CADASTR_FEEDBACK_ROUTE,
  CADASTR_GENERAL_INFO_ROUTE, CADASTR_MAP_ROUTE, CADASTR_NORMATIVE_BASE_ROUTE,
  CADASTR_PERSONAL_ACCOUNT_ROUTE,
} from '../routes';
import CadastrGeneralInfoPage from '../../pages/Cadastr/CadastrGeneralInfoPage';
import CadastrReports from '../../pages/Cadastr/CadastrReports';
import CadastrMap from '../../pages/Cadastr/CadastrMap';
import CadastreCommonRecommendations from '../../components/Cadastr/CadastreCommonRecommendations';
import CadastrNormativeBase from '../../pages/Cadastr/CadastrNormativeBase';
import CadastrFAQ from '../../pages/Cadastr/CadastrFAQ';
import CadastrFeedback from '../../pages/Cadastr/CadastrFeedback';
import CadastrContactInfo from '../../pages/Cadastr/CadastrContactInfo';
import { compareId } from '../../helpers';
import { checkIsControlOrigin } from '../../helpers/browserLocation';

export const getCadastreRoutes = (userStore) => {
  if (checkIsControlOrigin()) return null;

  const subject = userStore?.subject_attributes?.attributes;

  if (
    (compareId(subject?.partition, 'control') && !subject?.additional_partition)
    || (compareId(userStore?.partition, 'control') && !userStore?.additional_partition)
  ) return null;

  return (
    <>
      <Route
        exact
        path={CADASTR_GENERAL_INFO_ROUTE}
        element={<CadastrGeneralInfoPage />}
      />
      <Route
        exact
        path={CADASTR_PERSONAL_ACCOUNT_ROUTE}
        element={<CadastrReports />}
      />
      <Route
        exact
        path={CADASTR_MAP_ROUTE}
        element={<CadastrMap />}
      />
      <Route
        exact
        path={CADASTR_DOCUMENT_RECOMMENDATION_ROUTE}
        element={<CadastreCommonRecommendations />}
      />
      <Route
        exact
        path={CADASTR_NORMATIVE_BASE_ROUTE}
        element={<CadastrNormativeBase />}
      />
      <Route
        exact
        path={CADASTR_FAQ_ROUTE}
        element={<CadastrFAQ />}
      />
      <Route
        exact
        path={CADASTR_FEEDBACK_ROUTE}
        element={<CadastrFeedback />}
      />
      <Route
        exact
        path={CADASTR_CONTACT_INFO_ROUTE}
        element={<CadastrContactInfo />}
      />
    </>
  );
};
