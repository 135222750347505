import { Box } from '@mui/material';
import React from 'react';
import { FORM8_LICENSES } from '../../constants/lkCadastr/cadastrForm8';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { compareId } from '../../helpers';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import DeleteButton from '../Buttons/DeleteButton';
import CustomCheckbox from '../CustomCheckbox';
import CustomDatePicker from '../CustomDatePicker';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import NavTabs from '../Tabs';
import LicensesWorkTypes from './LicenseWorkTypes';

export default function CadastreLicensesContainer({
  placingObject,
  changeObject,
  isDemo,
  form5Licenses,
  changeLicenseUnneeded,
  is_license_unneeded,
  isHideForm5,
  openDeleteModal,
}) {
  const licenses = placingObject[FORM8_LICENSES];

  const { tab, setTab, tabs } = useCadastreTabs({ items: licenses, tabName: 'Лицензия' });

  return (
    <Box sx={flexGap2}>
      {(isHideForm5 || is_license_unneeded) && (
        <CustomCheckbox
          disabled={isDemo || (is_license_unneeded && !isHideForm5)}
          label="Лицензия не требуется"
          value={is_license_unneeded}
          onChange={changeLicenseUnneeded}
        />
      )}
      {
        (!(is_license_unneeded && !isHideForm5) && ((isHideForm5 && !is_license_unneeded) || !isHideForm5) && form5Licenses?.length > 0) && (
          <>
            {/* todo backend not ready */}
            {/* {(!isDemo) && ( */}
            {/*  <AddButton */}
            {/*    variant="outlined" */}
            {/*    onClick={() => changeObject( */}
            {/*      FORM8_LICENSES, */}
            {/*      [...licenses, getInitialForm8Licenses()], */}
            {/*    )} */}
            {/*  > */}
            {/*    Добавить лицензию */}
            {/*  </AddButton> */}
            {/* )} */}
            <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
            {licenses.map((license, licenseIndex) => {
              if (tab !== license.randId) return null;

              const changeLicense = (field, value) => {
                changeObject(`${FORM8_LICENSES}.${licenseIndex}.${field}`, value);
              };

              const mainLicense = form5Licenses.find((item) => compareId(item.id, license?.license?.id));

              const onDeleteLicense = () => {
                const cloneArray = licenses.slice();
                cloneArray.splice(licenseIndex, 1);

                changeObject(
                  FORM8_LICENSES,
                  cloneArray,
                  'delete',
                );
              };

              return (
                <Box key={license.randId} sx={flexGap2}>
                  <Box display="flex" alignItems="center" gap={3}>
                    {licenses.length > 1 && (
                      <DeleteButton onClick={() => openDeleteModal(onDeleteLicense, licenseIndex, 'Лицензию')}>
                        Удалить лицензию
                      </DeleteButton>
                    )}
                  </Box>
                  <Box sx={flexGap2}>
                    <CustomAutocomplete
                      validImmediately
                      debounceTime={0}
                      disabled={isDemo}
                      label="Лицензия"
                      value={mainLicense}
                      fields={{ label: 'license_number' }}
                      onChange={(e, value) => changeLicense('license', value)}
                      options={form5Licenses}
                    />
                    <CustomDatePicker
                      disabled
                      label="Дата"
                      value={mainLicense?.license_issue_date || null}
                    />
                    <Box display="flex" alignItems="center" gap={3}>
                      <CustomDatePicker
                        disabled
                        label="Срок действия лицензии"
                        value={mainLicense?.is_forever ? null : mainLicense?.license_expiration || null}
                      />
                      <CustomCheckbox
                        disabled
                        value={mainLicense?.is_forever}
                        label="Бессрочно"
                      />
                    </Box>
                    <LicensesWorkTypes mainLicense={mainLicense} />
                  </Box>
                </Box>
              );
            })}
          </>
        )
      }
    </Box>
  );
}
