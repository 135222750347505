import EmailConfirmationSuccess from '../../pages/Public/EmailConfirmation/EmailConfirmationSuccess';
import EmailConfirmationError from '../../pages/Public/EmailConfirmation/EmailConfirmationError';
import EmailConfirmationErrorToken from '../../pages/Public/EmailConfirmation/EmailConfirmationErrorToken';
import EmailConfirmationAgain from '../../pages/Public/EmailConfirmation/EmailConfirmationAgain';

export const PUBLIC_EMAIL_CONFIRMATION_ROUTE = {
  SUCCESS: '/public/email-confirmation-success',
  ERROR: '/public/email-confirmation-error',
  ERROR_TOKEN: '/public/email-confirmation-error-token',
  AGAIN: '/public/email-confirmation-again',
};

export const PUBLIC_EMAIL_CONFIRMATION_ROUTES = [
  { path: PUBLIC_EMAIL_CONFIRMATION_ROUTE.SUCCESS, Component: EmailConfirmationSuccess },
  { path: PUBLIC_EMAIL_CONFIRMATION_ROUTE.ERROR, Component: EmailConfirmationError },
  { path: PUBLIC_EMAIL_CONFIRMATION_ROUTE.ERROR_TOKEN, Component: EmailConfirmationErrorToken },
  { path: PUBLIC_EMAIL_CONFIRMATION_ROUTE.AGAIN, Component: EmailConfirmationAgain },
];
