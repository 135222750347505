// @ts-nocheck
import { METHODS_API } from '../../api/api';
import { cadastreForm3ReportGenerationsRequest, cadastreReportGenerationsRequest, cadastreWasteTransfersRequest } from '../../api/cadastreApi';
import { getInitialForm3Item, getInitialWasteTransfer } from '../../constants/lkCadastr/cadastrForm3';
import { getForm1WasteProducts } from '../cadastrHelper';
import { getHazardClassObject } from '../entityHelper';
import { sortByCreatedAt } from '../sortHelper';
import { valueToString } from '../stringHelper';

export const getReportGenerationsHelper = async (report) => {
  if (report?.report_generation_ids?.length) {
    const allReportGenerations = [];

    const reportGenerationsByIds = await cadastreReportGenerationsRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    sortByCreatedAt(reportGenerationsByIds).forEach((reportGeneration) => {
      const additionalForm3Items = {};

      const wasteTypeAttributes = reportGeneration?.waste_type_attributes;

      const wasteTransfers = reportGeneration?.waste_transfers_attributes?.map?.((wasteTransfer) => {
        const wasteTransferAttributes = wasteTransfer?.attributes;

        return ({
          ...getInitialWasteTransfer(),
          ...wasteTransfer,
          out_processing: valueToString(wasteTransferAttributes.out_processing) || '0',
          out_utilization: valueToString(wasteTransferAttributes.out_utilization) || '0',
          out_neutralization: valueToString(wasteTransferAttributes.out_neutralization) || '0',
          out_for_store: valueToString(wasteTransferAttributes.out_for_store) || '0',
          out_under_store: valueToString(wasteTransferAttributes.out_under_store) || '0',
          document_number: valueToString(wasteTransferAttributes.document_number) || '0',
          application_date: valueToString(wasteTransferAttributes.application_date) || '0',
          under_store: valueToString(wasteTransferAttributes.under_store) || '0',
          store: valueToString(wasteTransferAttributes.store) || '0',
          subject_id: {
            name: wasteTransferAttributes?.subject_attributes?.attributes?.name || '0',
            ...wasteTransferAttributes?.subject_attributes,
            ...wasteTransferAttributes?.subject_attributes?.attributes,
          },
          available_on_organisation: valueToString(wasteTransferAttributes.available_on_organisation) || '0',
        });
      });

      allReportGenerations.push({
        ...getInitialForm3Item(),
        ...reportGeneration,
        ...additionalForm3Items,
        get_import: valueToString(reportGeneration.get_import) || '0',
        get_all: valueToString(reportGeneration.get_all) || '0',
        waste_type_id: {
          ...wasteTypeAttributes,
          value: wasteTypeAttributes?.id || '',
          label: wasteTypeAttributes?.attributes?.code ? `${wasteTypeAttributes?.attributes?.code} ${wasteTypeAttributes?.attributes?.name}` : '0',
          ...getHazardClassObject(reportGeneration),
        },
        waste_transfers: wasteTransfers || [getInitialWasteTransfer()],
        get_processing: valueToString(reportGeneration.get_processing) || '',
        get_utilization: valueToString(reportGeneration.get_utilization) || '',
        get_neutralization: valueToString(reportGeneration.get_neutralization) || '',
        availability_waste_period: valueToString(reportGeneration.availability_waste_period) || '',
      });
    });

    return allReportGenerations;
  }
};

export const deleteForm3ReportGeneration = async ({ wasteId }) => {
  const deletedBuilding = await cadastreReportGenerationsRequest({
    method: METHODS_API.DELETE,
    id: wasteId,
  });

  return deletedBuilding?.id;
};

export const deleteForm3WasteTransfer = async ({ wasteTransferId }) => {
  const deletedWasteTransfer = await cadastreWasteTransfersRequest({
    method: METHODS_API.DELETE,
    id: wasteTransferId,
  });

  return deletedWasteTransfer?.id;
};

export const deleteForm3AcceptingEntities = async ({ forms }) => {
  if (!forms?.form3?.items) return;

  for await (const waste of forms.form3.items) {
    const reportGenerationId = waste?.id;

    if (reportGenerationId) {
      await cadastreReportGenerationsRequest({
        method: METHODS_API.DELETE,
        id: reportGenerationId,
      });
    }
  }
};

export const saveForm3ReportGeneration = async ({
  forms,
  reportId,
  isImportToExistingReport,
  previousForms,
}) => {
  const form1WasteProducts = getForm1WasteProducts(forms.form1.items);
  const form1PreviousWasteProducts = previousForms && getForm1WasteProducts(previousForms.form1.items);

  const mainForms = previousForms || forms;

  const mainForm1WasteProducts = form1PreviousWasteProducts || form1WasteProducts;

  let form3AcceptingItems = mainForms?.form3?.items;

  if (!mainForms?.form3?.hasAcceptingWastes) {
    await deleteForm3AcceptingEntities({ forms: mainForms });
    form3AcceptingItems = [];
  }

  const reportGenrationData = mainForm1WasteProducts.map((wasteProduct, index) => {
    const { form3Waste } = wasteProduct;

    const form3WasteId = form3Waste.id;

    const form3Data = {
      waste_type_id: wasteProduct.code?.id,
      report_building_id: form1WasteProducts[index].buildingId,
      report_history_id: reportId,
      waste_id: form1WasteProducts[index].id,
      is_accepting: false,
      availability_waste_period: form3Waste.availability_waste_period,
      get_import: form3Waste.get_import,
      get_all: form3Waste.get_all,
      get_processing: form3Waste.get_processing,
      get_utilization: form3Waste.get_utilization,
      get_neutralization: form3Waste.get_neutralization,
      waste_transfers_attributes: form3Waste?.waste_transfers?.map((wasteTransfer) => {
        const wasteTransferData = {
          store: wasteTransfer.store,
          under_store: wasteTransfer.under_store,
          out_processing: wasteTransfer.out_processing,
          out_utilization: wasteTransfer.out_utilization,
          out_neutralization: wasteTransfer.out_neutralization,
          out_for_store: wasteTransfer.out_for_store,
          out_under_store: wasteTransfer.out_under_store,
          subject_id: wasteTransfer.subject_id?.id,
          document_number: wasteTransfer.document_number,
          application_date: wasteTransfer.document_number === '0' ? null : valueToString(wasteTransfer.application_date),
          available_on_organisation: wasteTransfer.available_on_organisation,
        };

        if (!isImportToExistingReport && wasteTransfer?.id) wasteTransferData.id = wasteTransfer.id;

        return wasteTransferData;
      }),
    };

    if (!isImportToExistingReport && form3WasteId) form3Data.id = form3WasteId;

    return form3Data;
  });

  const reportGenerationAccpetingData = form3AcceptingItems.map((wasteAccepting) => {
    const form3WasteId = wasteAccepting.id;

    const form3AcceptingData = {
      waste_type_id: wasteAccepting.waste_type_id?.id,
      hazard_class: wasteAccepting?.waste_type_id?.hazard_class || '',
      is_accepting: true,
      report_history_id: reportId,
      availability_waste_period: wasteAccepting.availability_waste_period,
      get_import: wasteAccepting.get_import,
      get_all: wasteAccepting.get_all,
      get_processing: wasteAccepting.get_processing,
      get_utilization: wasteAccepting.get_utilization,
      get_neutralization: wasteAccepting.get_neutralization,
      waste_transfers_attributes: wasteAccepting?.waste_transfers?.map((wasteTransfer) => {
        const wasteTransferData = {
          store: wasteTransfer.store,
          under_store: wasteTransfer.under_store,
          out_processing: wasteTransfer.out_processing,
          out_utilization: wasteTransfer.out_utilization,
          out_neutralization: wasteTransfer.out_neutralization,
          out_for_store: wasteTransfer.out_for_store,
          out_under_store: wasteTransfer.out_under_store,
          subject_id: wasteTransfer.subject_id?.id,
          document_number: wasteTransfer.document_number,
          application_date: wasteTransfer.document_number === '0' ? null : valueToString(wasteTransfer.application_date),
          available_on_organisation: wasteTransfer.available_on_organisation,
        };

        if (wasteTransfer?.id) wasteTransferData.id = wasteTransfer.id;

        return wasteTransferData;
      }),
      store: wasteAccepting.store,
      under_store: wasteAccepting.under_store,
    };

    if (form3WasteId) form3AcceptingData.id = form3WasteId;

    return form3AcceptingData;
  });

  return cadastreForm3ReportGenerationsRequest({
    method: METHODS_API.POST,
    data: [...reportGenrationData, ...reportGenerationAccpetingData],
  });
};
