import { Box, Typography } from '@mui/material';
import React from 'react';
import ApplicationSubjectInfo from './ApplicationSubjectInfo';
import { formatAddressString } from '../../helpers/stringHelper';
import { GreenPointIcon, RedPointIcon } from '../SvgIcons';

export default function ApplicationWasteInfo({ application, isLoadingFiles }) {
  const waste_recipient = application.objective_attributes;
  const recipient_object = application.objective_attributes?.attributes;
  const recipient_subject = recipient_object
    ?.subject_attributes
    ?.attributes;

  return (
    <Box display="flex" gap={3} flexWrap="wrap">
      <Box display="flex" flexWrap="wrap" flexDirection="column" gap={3}>
        <Typography display="flex" alignItems="baseline" gap={1} component="h3" className="text-title">
          <RedPointIcon />
          Отходообразователи
        </Typography>
        {application?.application_objectives_attributes
          ?.map?.(({
            id,
            attributes: {
              created_at: applicationObjectiveCreatedDate,
              objective_attributes,
              document_url,
              extension,
              document_name,
            },
          }) => (
            <ApplicationSubjectInfo
              key={id}
              inn={objective_attributes?.attributes?.subject_attributes?.attributes?.inn}
              name={objective_attributes?.attributes?.subject_attributes?.attributes?.name}
              objectName={objective_attributes?.attributes?.name}
              address={objective_attributes
                ?.attributes
                ?.address_attributes
                ?.attributes && formatAddressString(
                objective_attributes
                  ?.attributes
                  ?.address_attributes
                  ?.attributes,
              )}
              ogrn={objective_attributes?.attributes?.subject_attributes?.attributes?.ogrn}
              created_at={applicationObjectiveCreatedDate}
              documents={
                !isLoadingFiles && document_url
                  ? [{ document: document_url, documentName: `Договор №: ${document_name}`, extension }]
                  : []
              }
            />
          ))}
      </Box>
      <Box display="flex" flexWrap="wrap" flexDirection="column" gap={3}>
        <Typography display="flex" alignItems="baseline" gap={1} component="h3" className="text-title">
          <GreenPointIcon />
          Субъект обращения с отходами
        </Typography>
        <ApplicationSubjectInfo
          inn={recipient_subject?.inn}
          name={recipient_subject?.name}
          objectName={recipient_object?.name}
          address={recipient_object?.address_attributes?.attributes && (
            formatAddressString(recipient_object?.address_attributes?.attributes)
          )}
          ogrn={recipient_subject?.ogrn}
          created_at={waste_recipient?.created_at}
          documents={!isLoadingFiles && application?.document_url ? [{
            document: application?.document_url,
            documentName: `Договор №: ${application?.document_name}`,
            extension: application?.extension,
          }] : []}
        />
      </Box>
    </Box>
  );
}
