import {
  CONCENTRATION_POINTS_URL,
  PARKING_DEVIATIONS_URL,
  ROUTE_DEVIATIONS_URL,
} from '../constants/urls';
import api from './api';

export const getParkingDeviationsRequest = (params, setTableStore) => api({
  url: PARKING_DEVIATIONS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const getConcentrationPointsRequest = (params, setTableStore) => api({
  url: CONCENTRATION_POINTS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const deleteConcentrationPointRequest = (id) => api({ method: 'delete', url: `${CONCENTRATION_POINTS_URL}/${id}` });

export const getRouteDeviationsRequest = (params, setTableStore) => api({
  url: ROUTE_DEVIATIONS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });
