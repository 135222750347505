import cloneDeep from 'lodash.clonedeep';

export const getForm1WasteProducts = (form1Items, field) => form1Items
  .reduce((prev, { waste_products }) => {
    const formattedWasteProducts = field ? waste_products.map((item) => item[field]) : waste_products;

    prev.push(...formattedWasteProducts);

    return prev;
  }, []);

export const removeForm2and3Waste = (form1Items) => form1Items.map((form1Item) => ({
  ...form1Item,
  waste_products: form1Item.waste_products.map((waste_product) => {
    const cloneWasteProduct = cloneDeep(waste_product);
    delete cloneWasteProduct.form2Waste;
    delete cloneWasteProduct.form3Waste;

    return cloneWasteProduct;
  }),
}));
