// @ts-nocheck
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PublishIcon from '@mui/icons-material/Publish';
import {
  Box, Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getReferencesRequest } from '../../api/referenceApi';
import {
  deleteSubjectRequest,
  getSubjectEnumsRequest,
  getSubjectsRequest,
  importSubjectRequest,
} from '../../api/subjectApi';
import { AsyncAutocomplete } from '../../components/AutoCompletes/AsyncAutocomplete';
import BackdropLoading from '../../components/BackdropLoading';
import CustomButton from '../../components/Buttons/CustomButton';
import { FileUpload } from '../../components/File/FileUpload';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../../components/Inputs/CustomAutocomplete';
import ModalAddSubject from '../../components/Modals/ModalAddSubject';
import ModalExportSubject from '../../components/Modals/ModalExportSubject';
import Selector, { DEFAULT_SELECTOR_OPTION } from '../../components/Selectors/Selector';
import CustomTable from '../../components/Tables/CustomTable';
import { DISTRICTS_OPTIONS } from '../../constants/districts';
import { ENTERPRISE_ACTIVITY_TYPE_IDS, ENTERPRISE_TYPE_ENUM } from '../../constants/enums';
import { ENTERPRISE_ACTIVITY_TYPES_URL } from '../../constants/urls';
import { useApi, usePushNotification, useTableSearch } from '../../hooks';
import { usePrivilegeRole } from '../../hooks/usePrivilegeRole';
import { useTableStore } from '../../store';

const getPartitionInfo = (partition_type) => ({ partition, additional_partition }) => {
  const mainPartition = partition_type === 'main' ? partition : additional_partition;

  switch (mainPartition) {
    case 'control': return 'Контроль';
    case 'cadastre': return 'Кадастр';
    default: return '-';
  }
};

const subjectRows = [
  { headerTitle: 'Наименование', field: 'name' },
  { headerTitle: 'Телефон', field: 'phone' },
  { headerTitle: 'ИНН', field: 'inn' },
  { headerTitle: 'ОГРН', field: 'ogrn' },
  { headerTitle: 'Адрес регистрации', field: 'registry_address' },
  { headerTitle: 'База данных', field: 'partition', getField: getPartitionInfo('main') },
  { headerTitle: 'Дополнительный ЛК', field: 'additional_partition', getField: getPartitionInfo('additional') },
];

const subjectFilters = [
  { value: ENTERPRISE_ACTIVITY_TYPE_IDS, label: 'По виду деятельности' },
  { value: ENTERPRISE_TYPE_ENUM, label: 'По виду предприятия' },
  { value: 'district', label: 'По муниципальному району Ленинградской области' },
];

const subjectRequest = (params, setTableStore) => getSubjectsRequest({ params, setTableStore });

export default function RegistrySubjects() {
  const pushNotification = usePushNotification();
  const { setTableStore } = useTableStore();
  const [tableData, setTableData] = useState([]);
  const [subjectData, setSubjectData] = useState({});
  const [isOpenAddSubject, setIsOpenAddSubject] = useState(false);
  const [isOpenExportSubject, setIsOpenExportSubject] = useState(false);
  const [filterType, setFilterType] = useState({ ...DEFAULT_SELECTOR_OPTION });
  const [filterAutocomplete, setFilterAutocomplete] = useState({ ...DEFAULT_SELECTOR_OPTION });

  const hasPrivilege = usePrivilegeRole();

  const closeAddUser = () => {
    setSubjectData({});
    setIsOpenAddSubject(false);
  };

  const openAddSubject = () => setIsOpenAddSubject(true);

  const getParams = () => {
    if (Array.isArray(filterAutocomplete)) {
      const filterIds = filterAutocomplete.map(({ id }) => id);
      const restString = filterIds.length === 1 ? ',' : '';
      return `${filterIds.join(',')}${restString}`;
    }

    return filterAutocomplete.label || filterAutocomplete.value || undefined;
  };

  const { makeRequest: searchSubjects, isLoading } = useTableSearch({
    search: subjectRequest,
    setter: setTableData,
    params: {
      [filterType.value]: getParams(),
    },
    deps: [filterType, filterAutocomplete],
  });

  const { makeRequest: importFile, isLoading: importLoading } = useApi({
    request: importSubjectRequest,
    setter: (data) => {
      const {
        updated, created, skipped, errors,
      } = data?.logs || {};

      if (errors.length) {
        errors.forEach((message) => pushNotification({ message }));
      } else {
        pushNotification({
          message: `Создано ${created.length}, Обновлено ${updated.length}, Пропущено ${skipped.length}`,
          variant: 'warning',
        });
      }
    },
  });

  const deleteRow = async ({ id }) => {
    await deleteSubjectRequest(id);
    setTableStore({ page: 1 });
    searchSubjects({ page: 1 });
  };

  const changeFilterType = (e, value) => {
    const newValue = value.value === ENTERPRISE_ACTIVITY_TYPE_IDS
      ? []
      : { ...DEFAULT_AUTOCOMPLETE_OPTION };
    setFilterAutocomplete(newValue);
    setFilterType(value);
  };

  const uploadFile = async (e, file, removeFiles) => {
    const formData = new FormData();
    formData.append('import[file]', file);
    formData.append('import[format_type]', 'xml');
    formData.append('import[kind]', 'subjects');
    await importFile(formData);

    removeFiles();
  };

  const openExportSubject = () => setIsOpenExportSubject(true);

  const closeExportSubject = () => setIsOpenExportSubject(false);

  const changeFilter = (e, value) => {
    setFilterAutocomplete(value);
  };

  const updateSubjects = () => {
    setTableStore({ page: 1 });
    searchSubjects({ page: 1 });
  };

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const canDelete = hasPrivilege('subject_destroy');
  const canEdit = hasPrivilege('subject_update');
  const canCreate = hasPrivilege('subject_create');
  const canSearch = hasPrivilege('subject_search');

  const onClickRow = (row) => {
    setSubjectData(row);
  };

  useEffect(() => {
    if (!canSearch) {
      setTableStore({ search: null });
    } else {
      setTableStore({ search: subjectRequest });
      searchSubjects();
    }
  }, [canSearch]);

  return (
    <>
      <Toolbar sx={{ mb: '8' }} />
      {isOpenExportSubject && <ModalExportSubject close={closeExportSubject} />}
      <BackdropLoading isLoading={importLoading} position={{ position: 'fixed' }} />
      <Box display="flex" mb="16px" gap={2} justifyContent="flex-end" flexDirection={tabletSizeAndLower && 'column'}>
        <Box display="flex" gap={2} justifyContent="flex-start" mr="auto" flexWrap="wrap">
          <Selector
            options={subjectFilters}
            onChange={changeFilterType}
            value={filterType}
            label="Тип фильтра"
          />
          {filterType.value === ENTERPRISE_ACTIVITY_TYPE_IDS && (
            <AsyncAutocomplete
              multiline
              multiple
              label="Значение фильтра"
              value={filterAutocomplete}
              onChange={changeFilter}
              name={ENTERPRISE_ACTIVITY_TYPE_IDS}
              request={(params) => getReferencesRequest({
                url: ENTERPRISE_ACTIVITY_TYPES_URL,
                params,
              })}
            />
          )}
          {filterType.value === ENTERPRISE_TYPE_ENUM && (
            <AsyncAutocomplete
              multiline
              label="Значение фильтра"
              value={filterAutocomplete}
              onChange={changeFilter}
              name={ENTERPRISE_TYPE_ENUM}
              optionField={DEFAULT_AUTOCOMPLETE_FIELD}
              request={(params) => getSubjectEnumsRequest({ enum_field: ENTERPRISE_TYPE_ENUM, params })}
            />
          )}
          {filterType.value === 'district' && (
            <CustomAutocomplete
              value={filterAutocomplete}
              label="Поиск по району"
              onChange={changeFilter}
              name="district"
              options={DISTRICTS_OPTIONS}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" gap={3}>
          <CustomButton
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={openExportSubject}
          >
            Экспорт
          </CustomButton>
          <FileUpload
            buttonVariant="contained"
            setFile={uploadFile}
            id="file"
            name="file"
            extensions={['xml']}
            endIcon={<PublishIcon />}
            label="Импорт"
          />
          {canCreate && (
            <CustomButton
              variant="contained"
              startIcon={<PersonAddAltIcon />}
              onClick={openAddSubject}
            >
              Добавить субъект
            </CustomButton>
          )}
        </Box>
      </Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={subjectRows}
        deleteRow={canDelete && {
          deleteRequest: deleteRow,
          deleteMessage: 'Субъект удалён',
          getDeleteObjectName: (row) => `субъект ${row.name}`,
        }}
        onClick={onClickRow}
      />

      {(isOpenAddSubject || subjectData?.id) && (
        <ModalAddSubject
          subjectData={subjectData}
          updateSubjects={updateSubjects}
          canEdit={canEdit}
          canDelete={canDelete}
          canCreate={canCreate}
          close={closeAddUser}
        />
      )}
    </>
  );
}
