import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomTable from '../../components/Tables/CustomTable';
import { useTableSearch } from '../../hooks';
import { formatDate, HOURS_MINUTES_SECONDS_DATE_MASK } from '../../helpers/dateHelper';
import { getOperationsRequest, getOperationTypesRequest } from '../../api/operationsApi';
import { DEFAULT_SELECTOR_OPTION } from '../../components/Selectors/Selector';
import { TextTitle } from '../../components/Text';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomDatePicker from '../../components/CustomDatePicker';
import { AsyncAutocomplete } from '../../components/AutoCompletes/AsyncAutocomplete';
import { getFullName } from '../../helpers/stringHelper';

const applicationTableColumns = [
  {
    headerTitle: 'Дата',
    field: 'created_at',
    getField: (row) => formatDate({ date: row.created_at }),
  },
  {
    headerTitle: 'Время',
    field: 'created_at',
    getField: ({ created_at }) => formatDate({
      date: created_at,
      mask: HOURS_MINUTES_SECONDS_DATE_MASK,
    }),
  },
  {
    headerTitle: 'Тип события',
    field: 'operation_type_attributes',
    getField: ({ operation_type_attributes }) => operation_type_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Название субъекта',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'ИНН субъекта',
    field: 'inn',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.inn,
  },
  {
    headerTitle: 'Имя пользователя',
    field: 'user_name',
    getField: ({ user_attributes }) => getFullName(user_attributes?.attributes),
  },
  {
    headerTitle: 'Событие',
    field: 'name',
  },
  {
    headerTitle: 'Описание',
    field: 'description',
  },
];

export default function AdminOperationsPage() {
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [operationType, setOperationType] = useState({ ...DEFAULT_SELECTOR_OPTION });

  const { isLoading } = useTableSearch({
    search: getOperationsRequest,
    setter: setTableData,
    params: {
      date_from: dateFrom || undefined,
      date_to: dateTo || undefined,
      operation_type_id: operationType.id || undefined,
    },
    deps: [dateFrom, dateTo, operationType],
  });

  const clearDates = () => {
    setDateFrom(null);
    setDateTo(null);
  };

  const onChange = (e, value) => {
    if (e.target.name === 'date_from') setDateFrom(value);
    else setDateTo(value);
  };

  const changeOperationType = (e, value) => setOperationType(value);

  return (
    <>
      <Box my="20px" gap={4} display="flex" alignItems="flex-end">
        <AsyncAutocomplete
          label="Тип события"
          containerSx={{ width: '300px' }}
          request={(params) => getOperationTypesRequest({ ...params, total: 1000 })}
          value={operationType}
          onChange={changeOperationType}
        />
        <Box>
          <TextTitle>Период активности</TextTitle>
          <CustomDatePicker
            isDateTime={false}
            sx={{ mr: '10px' }}
            placeholder="Начало:"
            name="date_from"
            value={dateFrom}
            maxDate={dateTo}
            onChange={onChange}
          />
          <CustomDatePicker
            isDateTime={false}
            name="date_to"
            minDate={dateFrom}
            placeholder="Окончание:"
            value={dateTo}
            onChange={onChange}
          />
          {(dateFrom || dateTo) && (
            <Box display="inline-flex">
              <CustomButton sx={{ ml: 2 }} variant="text" onClick={clearDates}>Сбросить дату и время</CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={applicationTableColumns}
      />
    </>
  );
}
