import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../Text';
import CustomInput from '../Inputs';
import DeleteButton from '../Buttons/DeleteButton';
import AddButton from '../Buttons/AddButton';
import { APPROVAL_STANDARD_YEAR_KINDS, getInitialLimitYear } from '../../constants/lkCadastr/cadastrForm4';
import { precisionAdd } from '../../helpers/numberHelper';

export default function LimitYears({
  approvalWaste,
  changeItem,
  yearName,
  isDemo,
  changeYearLimit,
  openDeleteModal,
  deleteForm4Entity,
}) {
  return (
    <>
      <TextTitle sx={{ mt: 3 }}>В том числе по годам</TextTitle>
      <TextTitle>
        Итого:
        {' '}
        {precisionAdd(approvalWaste[yearName].reduce((prev, { tons }) => precisionAdd(prev, tons), 0))}
      </TextTitle>
      {approvalWaste[yearName].map((limitWaste, yearIndex) => {
        const {
          year, tons, randId, id,
        } = limitWaste;

        const changeLimitItem = (field, value) => changeItem(`${yearName}.${yearIndex}.${field}`, value, yearIndex, yearName === 'limit_waste_transferred_years' ? APPROVAL_STANDARD_YEAR_KINDS.transferred : APPROVAL_STANDARD_YEAR_KINDS.placed);

        const onDelete = async () => {
          const deletedId = await deleteForm4Entity({ limitWasteId: id });

          if (!deletedId) return;

          const cloneArray = approvalWaste[yearName].slice();
          cloneArray.splice(yearIndex, 1);

          changeYearLimit(yearName, cloneArray, 'delete');
        };

        return (
          <Box key={randId || id}>
            <Box display="flex" alignItems="center" mb={1} gap={3}>
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="Год/число"
                value={year}
                onChange={(e, value) => changeLimitItem('year', value)}
              />
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="Тонн"
                type="number"
                value={tons}
                onChange={(e, value) => changeLimitItem('tons', value)}
              />
              {!isDemo && approvalWaste[yearName].length > 1 && limitWaste?.id && (
                <DeleteButton onClick={() => openDeleteModal(onDelete, yearIndex, 'Год')}>
                  Удалить год
                </DeleteButton>
              )}
            </Box>
          </Box>
        );
      })}
      {!isDemo && (
        <AddButton onClick={() => changeYearLimit(yearName, [...approvalWaste[yearName], getInitialLimitYear()])}>
          Добавить год
        </AddButton>
      )}
    </>
  );
}
