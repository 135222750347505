import { Typography } from '@mui/material';
import React from 'react';

export function TextTitle({
  children, h1 = false, h2 = false, h3 = false, h4 = false, sx = {}, component = null, onClick = null, className = '', onMouseUp = null,
}) {
  const getComponentType = () => {
    let paragraphType;

    if (h1) paragraphType = 'h1';
    if (h2) paragraphType = 'h2';
    if (h3) paragraphType = 'h3';
    if (h4) paragraphType = 'h4';

    return paragraphType;
  };

  return (
    <Typography
      sx={{ fontWeight: 700, ...sx }}
      onMouseUp={onMouseUp}
      onClick={onClick}
      component={component || getComponentType()}
      className={`text-title ${className || ''}`}
    >
      {children}
    </Typography>
  );
}
