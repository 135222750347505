import { METHODS_API } from '../../api/api';
import {
  cadastreComponentCompositionWastesRequest,
  cadastreForm2ReportWastesRequest,
  cadastreReportWastesRequest,
} from '../../api/cadastreApi';
import { WASTE_CONDITION_ENUM } from '../../constants/enums';
import { getInitialComponentCompositions, getInitialForm2Item } from '../../constants/lkCadastr/cadastrForm2';
import { getForm1WasteProducts } from '../cadastrHelper';
import { compareId } from '../compareId';
import { sortByCreatedAt } from '../sortHelper';
import { valueToString } from '../stringHelper';

export const getReportWastesHelper = async (report) => {
  if (report?.report_waste_ids?.length) {
    const allReportWastes = [];

    const reportWastesByIds = await cadastreReportWastesRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    sortByCreatedAt(reportWastesByIds).forEach((reportWaste) => {
      const additionalForm1Items = {};

      const currentComponentComposition = reportWaste?.component_composition_wastes_attributes;

      if (currentComponentComposition.length) {
        additionalForm1Items.component_compositions = currentComponentComposition
          .map((componentComposition) => ({
            ...getInitialComponentCompositions(),
            ...componentComposition,
            ...componentComposition?.attributes,
            mass_fraction: String(componentComposition?.attributes?.mass_fraction) || '',
          }));
      }

      const newReportWaste = {
        ...getInitialForm2Item(),
        ...reportWaste,
        ...additionalForm1Items,
        is_passport_approved: reportWaste.is_passport_approved,
        is_developed_passport: reportWaste.is_developed_passport,
        is_known_component_composition: Boolean(currentComponentComposition?.length),
        waste_approval_date: reportWaste.waste_approval_date || '0',
        [WASTE_CONDITION_ENUM]: {
          ...reportWaste.waste_aggregate_state_attributes,
          ...reportWaste.waste_aggregate_state_attributes?.attributes,
          name: reportWaste.waste_aggregate_state_attributes?.attributes?.name || '0',
        },
      };

      delete newReportWaste.report_history_attributes;

      allReportWastes.push(newReportWaste);
    });

    return allReportWastes;
  }
  return [];
};

export const deleteForm2ReportWaste = async ({ reportWasteId }) => {
  const deletedReportWaste = await cadastreReportWastesRequest({
    method: METHODS_API.DELETE,
    id: reportWasteId,
  });

  return deletedReportWaste?.id;
};

export const deleteForm2ComponentComposition = async ({ componentCompositionId }) => {
  const deletedComponentComposition = await cadastreComponentCompositionWastesRequest({
    method: METHODS_API.DELETE,
    id: componentCompositionId,
  });

  return deletedComponentComposition?.id;
};

export const saveForm2ReportWaste = async ({
  forms,
  reportId,
  isImportToExistingReport,
  previousForms,
}) => {
  const form1WasteProducts = getForm1WasteProducts(forms.form1.items);
  const form1PreviousWasteProducts = previousForms && getForm1WasteProducts(previousForms.form1.items);

  const mainWasteProducts = form1PreviousWasteProducts || form1WasteProducts;

  const newForm2ReportWastes = mainWasteProducts.reduce((prevWasteProduct, wasteProduct, index) => {
    if (compareId(wasteProduct.hazard_class, 'five') || compareId(wasteProduct.code?.hazard_class, 'five')) return prevWasteProduct;

    const { form2Waste } = wasteProduct;

    const reportWasteId = form2Waste?.id;

    const form2Data = {
      waste_approval_date: valueToString(form2Waste.waste_approval_date),
      name_technological_process: form2Waste.name_technological_process,
      name_code_waste: wasteProduct.code?.id,
      name_original_product: form2Waste.name_original_product,
      is_passport_approved: form2Waste.is_passport_approved,
      is_developed_passport: form2Waste.is_developed_passport,
      waste_aggregate_state_id: form2Waste[WASTE_CONDITION_ENUM]?.id || '',
      report_history_id: reportId,
    };

    if (!isImportToExistingReport && form1WasteProducts?.[index]?.id) form2Data.waste_id = form1WasteProducts[index].id;
    if (!isImportToExistingReport && reportWasteId) form2Data.id = reportWasteId;

    if (!form2Waste.is_known_component_composition) {
      form2Waste.component_compositions.forEach((componentComposition) => {
        const componentCompositionId = componentComposition?.id;
        if (componentCompositionId) deleteForm2ComponentComposition({ componentCompositionId });
      });
    }

    if (form2Waste.is_known_component_composition) {
      form2Data.component_composition_wastes = form2Waste.component_compositions.map((componentComposition) => {
        const componentCompositionId = componentComposition.id;

        const componentCompositionData = {
          component_name: componentComposition.component_name,
          mass_fraction: componentComposition.mass_fraction,
        };

        if (!isImportToExistingReport && reportWasteId) componentCompositionData.report_waste_id = reportWasteId;
        if (!isImportToExistingReport && componentCompositionId) componentCompositionData.id = componentCompositionId;

        return componentCompositionData;
      });
    }

    prevWasteProduct.push(form2Data);

    return prevWasteProduct;
  }, []);

  return cadastreForm2ReportWastesRequest({
    method: METHODS_API.POST,
    data: newForm2ReportWastes,
  });
};
