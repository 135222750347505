import { Typography } from '@mui/material';
import React from 'react';

export function Text({
  children, big = false, medium = false, className = '', sx, component, onClick = () => {},
}) {
  const getFontSize = () => {
    if (sx?.fontSize) return '';

    if (big) return 'text-big';
    if (medium) return 'text-medium';

    return 'text-small';
  };

  const fontSize = getFontSize();

  return (
    <Typography
      component={component}
      sx={sx}
      onClick={onClick}
      className={`${fontSize} custom-text ${className}`}
    >
      {children}
    </Typography>
  );
}
