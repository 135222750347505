import { useApi } from './useApi';
import { getMemberRequest } from '../api/userApi';
import { useUserStore } from '../store';

export default function useGetMember() {
  const { setUserStore } = useUserStore();

  const { isLoading: isMemberLoading } = useApi({
    request: async () => {
      const userData = await getMemberRequest();

      setUserStore(userData);
    },
    setter: (freshMember) => setUserStore(freshMember),
    shouldRequest: true,
  });

  return { isMemberLoading };
}
