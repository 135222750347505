import { Box } from '@mui/material';
import React from 'react';
import { fitContent, flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { TextTitle } from '../Text';
import CustomCheckbox from '../CustomCheckbox';
import { ACTIVITY_KINDS_NAMES } from '../../constants/subjectConstants';

export default function LicensesWorkTypes({ mainLicense }) {
  if (!mainLicense?.id) return null;

  const hasWorkSelfWaste = (type) => mainLicense?.industrialAreas.some((attributes) => attributes?.works_self_waste?.includes(type));
  const hasWorkAnotherWaste = (type) => mainLicense?.industrialAreas.some((attributes) => attributes?.works_another_waste?.includes(type));

  return (
    <Box sx={flexGap2}>
      <TextTitle h3>
        Виды работ
      </TextTitle>
      <Box sx={{ ...flexRowGap2, flexWrap: 'wrap' }}>
        <Box sx={flexGap2}>
          <TextTitle>
            С собственными отходами
          </TextTitle>
          <Box sx={flexRowGap2}>
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Транспортирование"
              value={hasWorkSelfWaste(ACTIVITY_KINDS_NAMES.transporting)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Обработка"
              value={hasWorkSelfWaste(ACTIVITY_KINDS_NAMES.processing)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Утилизация"
              value={hasWorkSelfWaste(ACTIVITY_KINDS_NAMES.utilization)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Обезвреживание"
              value={hasWorkSelfWaste(ACTIVITY_KINDS_NAMES.neutralization)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Размещение"
              value={hasWorkSelfWaste(ACTIVITY_KINDS_NAMES.placing)}
            />
          </Box>
        </Box>
        <Box sx={flexGap2}>
          <TextTitle>
            С отходами сторонних организаций
          </TextTitle>
          <Box sx={flexRowGap2}>
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Сбор"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.collection)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Транспортирование"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.transporting)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Обработка"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.processing)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Утилизация"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.utilization)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Обезвреживание"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.neutralization)}
            />
            <CustomCheckbox
              disabled
              sx={fitContent}
              label="Размещение"
              value={hasWorkAnotherWaste(ACTIVITY_KINDS_NAMES.placing)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
