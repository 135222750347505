import React from 'react';
import { ReactComponent as EditIcon } from '../../assets/edit_gray_icon.svg';
import CustomIconButton from './CustomIconButton';

export default function EditButton({
  children, title, onClick, sx, variant = 'contained', Icon = EditIcon,
}) {
  return (
    <CustomIconButton
      sx={sx}
      title={title}
      Icon={Icon}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </CustomIconButton>
  );
}
