import React from 'react';
import {
  Box, Modal, styled, useMediaQuery, useTheme,
} from '@mui/material';

const StyledContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '90vh',
  overflow: 'auto',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  boxShadow: 24,
  width: '80vw',
});

export default function CenteredModal({
  children, close, open, sx, containerSx,
}) {
  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const mobileTabletSx = tabletSizeAndLower ? { width: '100vw', maxHeight: '100vh', height: '100vh' } : {};

  return (
    <Modal open={Boolean(open)} onClose={close} sx={sx}>
      <StyledContainer sx={{ ...containerSx, ...mobileTabletSx }}>
        {children}
      </StyledContainer>
    </Modal>
  );
}
