export const getCloneGenerator = ({
  field, generatorIndex, itemIndex, object,
}) => {
  const cloneWasteGenerators = object.waste_generators.slice();
  const cloneWasteGenerator = { ...cloneWasteGenerators[generatorIndex] };
  const cloneField = cloneWasteGenerator[field].slice();
  const cloneFieldItem = itemIndex && cloneField[itemIndex];

  return {
    cloneWasteGenerators, cloneWasteGenerator, cloneField, cloneFieldItem,
  };
};

export const changeGenerators = ({
  cloneField,
  index,
  deleteCount,
  item,
  field,
  cloneWasteGenerators,
  generatorIndex,
  cloneWasteGenerator,
  setter,
}) => {
  if (item) cloneField.splice(index, deleteCount, item);
  else cloneField.splice(index, deleteCount);

  cloneWasteGenerators.splice(
    generatorIndex,
    1,
    { ...cloneWasteGenerator, [field]: cloneField },
  );
  setter((prevState) => ({
    ...prevState,
    waste_generators: cloneWasteGenerators,
  }));
};
