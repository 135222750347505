import { useNavigate } from 'react-router-dom';
import { checkIsSuperAdmin, formatPrivilegesToString } from '../helpers/roleHelper';
import { useGetPrivileges } from './useGetPrivileges';

export function useGetRoleRedirectRoute({ getRoleRoutes }) {
  const navigate = useNavigate();

  const { getPrivileges } = useGetPrivileges();

  return async (userStore) => {
    const privileges = await getPrivileges(userStore);
    const hasPrivilege = (privilege) => formatPrivilegesToString(privileges).some((privilegeItem) => privilegeItem === privilege);
    const userRoutes = getRoleRoutes(checkIsSuperAdmin(userStore) ? () => true : hasPrivilege);

    const privilegeRoute = userRoutes?.props?.children?.find?.((item) => Boolean(item))?.props?.path;

    navigate(privilegeRoute);
  };
}
