import React, { useState } from 'react';
import { Toolbar } from '@mui/material';
import CustomTable from '../../components/Tables/CustomTable';
import { useTableSearch } from '../../hooks';
import { formatDate } from '../../helpers/dateHelper';
import { ModalApplicationInfo } from '../../components/Modals/ModalApplication/ModalApplication';
import { getApplicationsRequest } from '../../api/applicationsApi';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';

const applicationTableColumns = [
  {
    headerTitle: 'Дата выдачи разрешений',
    field: 'created_at',
    getField: (row) => formatDate({ date: row.created_at }),
  },
  {
    headerTitle: 'ЕГРЗ ТС (Номер ТС)',
    field: 'egrz',
    getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz,
  },
  {
    headerTitle: 'Субъект, транспортирующий отходы',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Объект (образователь отходов)',
    field: 'waste_generator_objective_id',
    getField: ({ application_objectives_attributes }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Объект (обращение с отходами)',
    field: 'recipient_object_name',
    getField: ({ objective_attributes }) => objective_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Субъект (образователь отходов)',
    field: 'waste_generator_subject_id',
    getField: ({ application_objectives_attributes }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Субъект (обращение с отходами)',
    field: 'recipient_subject_name',
    getField: ({ objective_attributes }) => objective_attributes
      ?.attributes?.subject_attributes?.attributes?.name,
  },
];

export default function RegistryApplicationsPage() {
  const [tableData, setTableData] = useState([]);
  const [applicationData, setApplicationData] = useState({});

  const { isMobileTablet } = useCustomMediaQuery();

  const { isLoading } = useTableSearch({
    search: getApplicationsRequest,
    setter: setTableData,
  });

  const closeAddObject = () => {
    setApplicationData({});
  };

  const onClickRow = (row) => {
    setApplicationData(row);
  };

  return (
    <>
      <Toolbar sx={{ mb: '8' }} />
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={applicationTableColumns}
        onClick={onClickRow}
      />
      {applicationData?.id && (
        <ModalApplicationInfo
          hasCloseButton={isMobileTablet}
          id={applicationData.id}
          close={closeAddObject}
        />
      )}
    </>
  );
}
