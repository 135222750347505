import Api from './api';
import { COLUMN_URL, ENUMS_URL, REPORTS_GENERATE_URL } from '../constants/urls';

export const createReportRequest = (report) => Api({
  method: 'post',
  url: REPORTS_GENERATE_URL,
  data: { report },
}, { isEntityAttributes: true });

export const getReportsRequest = () => Api({
  url: `${REPORTS_GENERATE_URL}/${ENUMS_URL}`,
});

export const getReportColumnsRequest = (user) => Api(
  { url: `${REPORTS_GENERATE_URL}/${COLUMN_URL}`, data: { user } },
  { filter: ({ data }) => data },
);
