import React from 'react';
import { Save } from '@mui/icons-material';
import CustomIconButton from './CustomIconButton';

export default function SaveButton({
  children, onClick, sx, title, variant,
}) {
  return (
    <CustomIconButton
      sx={sx}
      title={title}
      Icon={Save}
      onClick={onClick}
      variant={variant}
    >
      {children}
    </CustomIconButton>
  );
}
