// @ts-nocheck
import { OBJECTIVES_URL, SEARCH_BY_AREA_URL } from '../constants/urls';
import api from './api';

export const getObjectivesRequest = ({ params, setTableStore, shouldCache }) => api({
  url: OBJECTIVES_URL,
  params,
}, { isEntityAttributes: true, setTableStore, shouldCache });

export const getObjectiveByAreaRequest = (params) => api(
  {
    url: `${OBJECTIVES_URL}${SEARCH_BY_AREA_URL}`,
    params: {
      ...params,
      total: 10000,
    },
  },
  { isEntityAttributes: true },
);

export const updateObjectiveRequest = (id, data) => api({
  method: 'put',
  url: `${OBJECTIVES_URL}/${id}`,
  data: { objective: { ...data } },
}, { isEntityAttributes: true });

export const deleteObjectiveRequest = (id) => api({ method: 'delete', url: `${OBJECTIVES_URL}/${id}` });

export const createObjectiveRequest = (objective) => api({
  method: 'post',
  url: OBJECTIVES_URL,
  data: { objective: { status: 'draft', ...objective } },
}, { isEntityAttributes: true });
