import React from 'react';
import { ReactComponent as CarrierManageProfile } from '../assets/carrier-manage-profile-icon.svg';
import { ReactComponent as CarrierPersonalAccountIcon } from '../assets/carrier-personal-account-icon.svg';
import { ReactComponent as DeviationIcon } from '../assets/deviation.svg';
import { ReactComponent as MonitoringTsIcon } from '../assets/monitoring_ts_icon.svg';
import { ReactComponent as UserIcon } from '../assets/user_icon_blue.svg';
import { ReactComponent as WasteCarriersIcon } from '../assets/waste_carriers_icon.svg';
import { ENTERPRISE_ACTIVITY_TYPE_IDS, ENTERPRISE_TYPE_ENUM, HAZARD_CLASS_ENUM } from './enums';
import {
  CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE,
  CADASTR_ADMIN_NORMATIVE_BASE_ROUTE,
  CADASTR_ADMIN_REPORTS_ROUTE,
  CADASTR_DOCUMENT_RECOMMENDATION_ROUTE,
  CADASTR_GENERAL_INFO_ROUTE,
  CADASTR_MAP_ROUTE,
  CADASTR_NORMATIVE_BASE_ROUTE,
  CADASTR_PERSONAL_ACCOUNT_ROUTE,
  CARRIER_GENERAL_INFO_ROUTE,
  CARRIER_PERSONAL_ACCOUNT_ROUTE,
  COMMITTEE_DEVIATION,
  COMMITTEE_MONITORING_VEHICLES,
  COMMITTEE_PROFILE,
  COMMITTEE_WASTE_CARRIERS,
} from './routes';

export const CARRIER_LINKS = [
  { title: 'Личный кабинет', href: CARRIER_PERSONAL_ACCOUNT_ROUTE, icon: <CarrierPersonalAccountIcon /> },
  { title: 'Общие сведения', href: CARRIER_GENERAL_INFO_ROUTE, icon: <CarrierManageProfile /> },
];

export const CADASTR_LINKS = [
  {
    title: 'Личный кабинет',
    href: CADASTR_PERSONAL_ACCOUNT_ROUTE,
    icon: <CarrierPersonalAccountIcon />,
    onClick: ({
      userStore, href, changeRoute, pushNotification,
    }) => {
      const hasActivityType = userStore[ENTERPRISE_ACTIVITY_TYPE_IDS].length;
      const hasType = userStore[ENTERPRISE_TYPE_ENUM];
      const hasHazardClass = userStore[HAZARD_CLASS_ENUM].length;
      const hasBossPhone = userStore.boss_phone?.length;

      if (hasActivityType && hasType && hasHazardClass && hasBossPhone) {
        changeRoute(href);
      } else {
        const messages = [];
        if (!hasActivityType) messages.push('Вид деятельности');
        if (!hasType) messages.push('Вид предприятия');
        if (!hasHazardClass) messages.push('Класс опасности');
        if (!hasBossPhone) messages.push('Номер телефона руководителя организации');
        pushNotification({ message: `Нужно заполнить (${messages.join(', ')})` });
      }
    },
  },
  { title: 'Общие сведения', href: CADASTR_GENERAL_INFO_ROUTE, icon: <CarrierManageProfile /> },
  { title: 'Карта', href: CADASTR_MAP_ROUTE, icon: <MonitoringTsIcon /> },
  { title: 'Рекомендации по заполнению', href: CADASTR_DOCUMENT_RECOMMENDATION_ROUTE, icon: <CarrierManageProfile /> },
  { title: 'Нормативная база', href: CADASTR_NORMATIVE_BASE_ROUTE, icon: <CarrierManageProfile /> },
];

export const CADASTR_ADMIN_LINKS = [
  {
    title: 'Личный кабинет',
    href: CADASTR_ADMIN_REPORTS_ROUTE,
    icon: <CarrierPersonalAccountIcon />,
  },
  {
    title: 'Рекомендации по заполнению',
    href: CADASTR_ADMIN_DOCUMENT_RECOMMENDATION_ROUTE,
    icon: <CarrierManageProfile />,
  },
  { title: 'Нормативная база', href: CADASTR_ADMIN_NORMATIVE_BASE_ROUTE, icon: <CarrierManageProfile /> },
];

export const COMMITTEE_STAFF_LINKS = [
  { title: 'Перевозчики отходов', href: COMMITTEE_WASTE_CARRIERS, icon: <WasteCarriersIcon /> },
  { title: 'Мониторинг ТС', href: COMMITTEE_MONITORING_VEHICLES, icon: <MonitoringTsIcon /> },
  { title: 'Отклонения', href: COMMITTEE_DEVIATION, icon: <DeviationIcon /> },
  { title: 'Профиль', href: COMMITTEE_PROFILE, icon: <UserIcon /> },
];
