import React from 'react';
import { Box } from '@mui/material';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import AddButton from '../../../Buttons/AddButton';
import { getInitialMonitoringKinds, MONITORING_KINDS_NAME } from '../../../../constants/lkCadastr/cadastrForm7';
import { flexGap2 } from '../../../../constants/lkCadastr/cadastrStorage';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import NavTabs from '../../../Tabs';
import CustomCheckbox from '../../../CustomCheckbox';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';

export function MonitoringKinds({
  monitoringKinds, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: monitoringKinds, tabName: 'Вид мониторинга' });

  return (
    <Box sx={flexGap2}>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeItem(MONITORING_KINDS_NAME, [...monitoringKinds, getInitialMonitoringKinds()])}
        >
          Добавить вид мониторинга
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {monitoringKinds.map((monitoringKind, monitoringKindIndex) => {
        if (tab !== monitoringKind.randId) return null;

        const change = (field, value) => changeItem(
          `${MONITORING_KINDS_NAME}.${monitoringKindIndex}.${field}`,
          value,
          undefined,
          { monitoringKindIndex },
        );

        const onDelete = async () => {
          if (monitoringKind?.id) {
            const deletedMonitoringKind = await deleteForm7Entity({
              id: monitoringKind.id,
              apiType: MONITORING_KINDS_NAME,
            });

            if (!deletedMonitoringKind) return;
          }

          const cloneArray = monitoringKinds.slice();
          cloneArray.splice(monitoringKindIndex, 1);

          changeItem(
            MONITORING_KINDS_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box key={monitoringKind.randId} sx={flexGap2}>
            {!isDemo && monitoringKinds.length > 1 && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, monitoringKindIndex, 'Вид мониторинга')}
              >
                Удалить вид мониторинга
              </DeleteButton>
            )}
            <CustomInput
              debounceTime={0}
              validImmediately
              disabled={isDemo}
              label="Наименование вида мониторинга"
              value={monitoringKind.name}
              onChange={(e, value) => change('name', value)}
            />
            <CustomCheckbox
              disabled={isDemo}
              label="Соблюдение нормативов"
              value={monitoringKind.is_observation_standard}
              onChange={(e, value) => change('is_observation_standard', value)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
