import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import CustomButton from '../Buttons/CustomButton';
import { TextSignature } from '../Text';
import { ReactComponent as NoTerScheme } from '../../assets/noTerScheme.svg';
import { BUTTON_BLUE } from '../../constants/colors';
import { ReactComponent as AddressRed } from '../../assets/address_red.svg';
import { ReactComponent as AddressGreen } from '../../assets/address_green.svg';
import { ReactComponent as AddressBlue } from '../../assets/address_blue.svg';

function LegendItem({ text, component, isVisible }) {
  if (!isVisible) return null;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex" alignItems="center" justifyContent="center" width={33}>{component}</Box>
      <TextSignature>
        {text}
      </TextSignature>
    </Box>
  );
}

export function LegendLine({ color, sx }) {
  return <Box width={22} borderRadius={2} height={6} bgcolor={color} sx={{ ...sx }} />;
}

const legendSx = {
  bgcolor: 'white',
  position: 'absolute',
  borderRadius: 2,
  top: 12,
  left: 12,
  zIndex: 401,
};

const paperSx = {
  p: 1,
};

export default function LegendControl({
  hasPlannedRoute,
  hasTrackedRoute,
  hasDeviationRoute,
  hasNoTerSchemePoints,
  concentrationPoints,
  clusters,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={legendSx}>
      <CustomButton variant="text" onClick={() => setIsOpen(!isOpen)}>Легенда</CustomButton>
      {isOpen && (
        <Paper sx={paperSx}>
          {
            clusters?.map?.(({ markerIcon, legendText }) => (
              <LegendItem isVisible text={legendText} component={<img src={markerIcon} alt="" />} />
            ))
          }
          {Boolean(concentrationPoints?.length) && (
            <>
              <LegendItem isVisible text="Концентрация от 2 до 5 ТС" component={<Box className="concentration-point yellow" />} />
              <LegendItem isVisible text="Концентрация 6 и более ТС" component={<Box className="concentration-point red" />} />
            </>
          )}
          <LegendItem isVisible={hasNoTerSchemePoints} text="Объекты вне тер. схемы" component={<NoTerScheme />} />
          <LegendItem
            isVisible={hasTrackedRoute}
            text="Трек транспорта"
            component={<LegendLine color="orange" />}
          />
          <LegendItem
            isVisible={hasPlannedRoute}
            text="Плановый маршрут"
            component={<LegendLine color={BUTTON_BLUE} />}
          />
          <LegendItem
            isVisible={hasPlannedRoute}
            text="Плановый маршрут (откуда)"
            component={<AddressRed />}
          />
          <LegendItem
            isVisible={hasPlannedRoute}
            text="Плановый маршрут (куда)"
            component={<AddressGreen />}
          />
          <LegendItem
            isVisible={hasDeviationRoute}
            text="Места съезда с трассы"
            component={<LegendLine color="red" />}
          />
          <LegendItem
            isVisible={hasDeviationRoute}
            text="Конечная точка съезда с трассы"
            component={<AddressBlue />}
          />
        </Paper>
      )}
    </Box>
  );
}
