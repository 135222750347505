import React from 'react';
import { Box } from '@mui/material';
import CenteredModal from './CenteredModal';
import { useModalStore } from '../../store';
import { useApi } from '../../hooks';
import ModalColumnTitle from './components/ModalColumnTitle';
import CustomButton from '../Buttons/CustomButton';

export default function ModalDelete() {
  const { modalStore, setModalStore } = useModalStore();

  const {
    deleteModal: {
      id, callback, message, deleteRequest, objectName = 'Объект',
    },
  } = modalStore;

  const closeDeleteModal = () => {
    setModalStore({
      ...modalStore,
      deleteModal: { isOpen: false },
    });
  };

  const onDelete = async () => {
    await deleteRequest(id);

    if (callback) await callback();

    closeDeleteModal();
  };

  const { makeRequest } = useApi({
    request: onDelete,
    successMessage: message,
  });

  return (
    <CenteredModal
      close={closeDeleteModal}
      containerSx={{
        width: 'fit-content', maxWidth: 640, p: '40px', borderRadius: '16px',
      }}
      open
    >
      <ModalColumnTitle>
        Вы уверены, что хотите удалить
        {' '}
        {objectName}
        ?
      </ModalColumnTitle>
      <Box display="flex" justifyContent="center" gap={2} mt="40px">
        <CustomButton variant="contained" onClick={closeDeleteModal}>Нет, не удалять</CustomButton>
        <CustomButton variant="contained" color="error" onClick={makeRequest}>Да, удалить</CustomButton>
      </Box>
    </CenteredModal>
  );
}
