// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TitleSubtitle from '../../TitleSubtitle';
import { getDayMonthNameYearTime } from '../../../helpers/dateHelper';
import { useApi, useLeafletScreenshot } from '../../../hooks';
import { applicationQRCodeRequest, getApplicationRequest } from '../../../api/applicationsApi';
import VehicleInformationContainer from '../../Vehicle/VehicleInformationContainer';
import ApplicationSubjectInfo from '../../Application/ApplicationSubjectInfo';
import { formatAddressString } from '../../../helpers/stringHelper';
import LeafletMap from '../../Map/LeafletMap';
import BackdropLoading from '../../BackdropLoading';
import ParamsModal from '../ParamsModal';
import QrCode from '../../QrCode';
import { PUBLIC_APPLICATION_ROUTE } from '../../../constants/routes';
import { GreenPointIcon, RedPointIcon } from '../../SvgIcons';
import useVehiclePositionListener from '../../../hooks/useVehiclePositionListener';
import { TextTitle } from '../../Text';
import SaveButton from '../../Buttons/SaveButton';
import { ReactComponent as PrintIcon } from '../../../assets/print_icon.svg';
import CustomButton from '../../Buttons/CustomButton';
import CenteredModal from '../CenteredModal';
import CloseModalButton from '../../Buttons/CloseModalButton';
import ApplicationPdf from '../../ApplicationPdf';
import { getTrackCircleChildren } from '../../../helpers/mapHelper';
import useRnicInfo from '../../../hooks/useRnicInfo';
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery';
import { useGetApplicationFiles } from '../../../hooks/application/useGetApplicationFiles';

export function ModalApplicationInfo({
  hasCloseButton = true,
  close,
  afterClose,
  id: applicationId,
  onCopy,
  hasMap = true,
  sx,
  deviationRoutes,
  noTerSchemeObjects,
  hasPublicLink = true,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [vehiclePosition, setVehiclePosition] = useState();
  const [vehicleTracks, setVehicleTracks] = useState([]);
  const [application, setApplication] = useState({});
  const [isLoadingCoordinate, setIsLoadingCoordinate] = useState(false);
  const [mapImage, setMapImage] = useState();
  const [map, setMap] = useState();
  const [localQrCodeUrl, setLocalQrCodeUrl] = useState('');
  const makeMapScreen = useLeafletScreenshot({ map, setter: setMapImage, hasMap });

  useGetApplicationFiles({ id: application?.id, application, setApplication });

  useApi({
    setter: (qrCode) => {
      if (qrCode?.data) {
        setLocalQrCodeUrl(window.URL.createObjectURL(qrCode.data));
      }
    },
    shouldRequest: true,
    request: () => applicationQRCodeRequest(applicationId),
  });

  const { isMobileTablet } = useCustomMediaQuery();

  const { isLoading: applicationLoading } = useApi({
    request: () => getApplicationRequest(applicationId),
    setter: setApplication,
    shouldRequest: applicationId !== undefined,
    errorCallback: (error) => {
      if (error?.response?.status === 404) {
        navigate(pathname);
      }
    },
  });

  const printApplication = () => navigate(`${PUBLIC_APPLICATION_ROUTE}/${applicationId}#print`);

  const onClose = () => {
    if (typeof close === 'function') close();
    else navigate(pathname);

    if (typeof afterClose === 'function') afterClose();
  };

  useVehiclePositionListener({
    setVehiclePosition,
    guid_bnso: hasMap ? application?.vehicle_attributes?.attributes?.guid_bnso : undefined,
    vehiclePosition,
    setLoading: setIsLoadingCoordinate,
  });

  const { rnicInfo } = useRnicInfo({
    guid_bnso: hasMap ? application?.vehicle_attributes?.attributes?.guid_bnso : undefined,
  });

  useEffect(() => {
    if (makeMapScreen && !mapImage) makeMapScreen();
  }, [makeMapScreen, mapImage]);

  if (!application.id) return <BackdropLoading position="fixed" />;

  const boundVehicle = () => {
    if ((vehiclePosition?.place?.x) && map?.fitBounds) {
      map.fitBounds([
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
      ]);
    }
  };

  const {
    hazard_class_text,
    application_objectives_attributes = [],
    created_at,
    vehicle_attributes,
    subject_attributes,
  } = application;

  const waste_recipient = application?.objective_attributes;
  const recipient_object = waste_recipient?.attributes;
  const recipient_subject = recipient_object
    ?.subject_attributes
    ?.attributes;

  return (
    <CenteredModal
      open
      containerSx={{
        maxHeight: '100vh', position: 'relative', width: '85vw', ...sx,
      }}
      close={onClose}
    >
      {hasCloseButton && (
        <CloseModalButton sx={{ position: 'absolute', top: 0, right: 0 }} close={onClose} />
      )}
      <BackdropLoading isLoading={applicationLoading} />
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box py={4} px={isMobileTablet ? '4px' : 4} width={isMobileTablet ? '100vh' : '40vw'}>
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} mb={3} mt={1}>
            <CustomButton startIcon={<PrintIcon />} onClick={printApplication}>
              Распечатать разрешение
            </CustomButton>
            <ApplicationPdf application={application} mapImage={mapImage} localQrCodeUrl={localQrCodeUrl} />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <TextTitle h1>Информация о разрешении</TextTitle>
            {onCopy && (
              <SaveButton variant="outlined" onClick={() => onCopy(application)}>
                Создать новое разрешение
              </SaveButton>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection={(hasMap || hasPublicLink || isMobileTablet) ? 'column' : 'row'}
            alignItems={(hasMap || hasPublicLink || isMobileTablet) ? '' : 'center'}
            gap={3}
            mb={3}
          >
            <QrCode
              hasPublicLink={hasPublicLink}
              clipboardUrl={`${PUBLIC_APPLICATION_ROUTE}/${applicationId}`}
              url={localQrCodeUrl}
              sx={!hasMap ? { flexDirection: 'row', alignItems: 'center' } : undefined}
            />
            <Box>
              {
                noTerSchemeObjects?.map?.(({
                  subject_id,
                  subject_attributes: noTerSchemeSubject,
                }) => (
                  <TitleSubtitle
                    key={subject_id}
                    title="Наименование субъекта, отсутствующего в территориальной схеме"
                    subTitle={noTerSchemeSubject?.attributes?.name}
                  />
                ))
              }
              <TitleSubtitle
                title="Субъект перевозчик"
                subTitle={subject_attributes?.attributes?.name}
              />
              <TitleSubtitle
                title="Дата и время получения разрешения:"
                subTitle={getDayMonthNameYearTime(created_at)}
              />
              <Box display="flex" alignItems="center" gap={3}>
                {hazard_class_text.length === 0 && (
                  <TitleSubtitle
                    title="Класс отходов:"
                    subTitle={null}
                  />
                )}
                {hazard_class_text.map((text) => (
                  <TitleSubtitle
                    key={text}
                    title="Класс отходов:"
                    subTitle={text}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          {vehicle_attributes?.id !== undefined && (
            <VehicleInformationContainer
              hasTrackControl={hasMap}
              setVehicleTracks={setVehicleTracks}
              isLoading={isLoadingCoordinate}
              vehiclePosition={vehiclePosition}
              brand={vehicle_attributes?.attributes?.brand}
              model={vehicle_attributes?.attributes?.model}
              egrz={vehicle_attributes?.attributes?.egrz}
              egrz_trailer={vehicle_attributes?.attributes?.egrz_trailer}
              is_connected_rins={vehicle_attributes?.attributes?.is_connected_rins}
              ownership_type_text={vehicle_attributes?.attributes?.ownership_type_text}
              guid_bnso={vehicle_attributes?.attributes?.guid_bnso}
              deleted_at={vehicle_attributes?.attributes?.deleted_at}
              boundsVehicle={boundVehicle}
            />
          )}
          <Box display="flex" gap={3} mb={3} flexWrap="wrap">
            <Box display="flex" flexDirection="column" gap={3} flexWrap="wrap">
              <Typography display="flex" alignItems="center" gap={1} component="h3" className="text-title">
                <RedPointIcon />
                Отходообразователи
              </Typography>
              {application_objectives_attributes
                .map(({
                  id,
                  attributes: {
                    created_at: applicationObjectiveCreatedDate,
                    objective_attributes,
                    document_url,
                    document_name,
                    extension,
                  },
                }, index) => (
                  <ApplicationSubjectInfo
                    key={id}
                    index={index}
                    inn={objective_attributes?.attributes?.subject_attributes?.attributes?.inn}
                    name={objective_attributes?.attributes?.subject_attributes?.attributes?.name}
                    objectName={objective_attributes?.attributes?.name}
                    address={objective_attributes
                      ?.attributes
                      ?.address_attributes
                      ?.attributes && formatAddressString(
                      objective_attributes
                        ?.attributes
                        ?.address_attributes
                        ?.attributes,
                    )}
                    ogrn={objective_attributes?.attributes?.subject_attributes?.attributes?.ogrn}
                    created_at={applicationObjectiveCreatedDate}
                    documents={
                      document_url
                        ? [{ document: document_url, documentName: `Договор №: ${document_name}`, extension }]
                        : []
                    }
                  />
                ))}
            </Box>
            <Box display="flex" flexDirection="column" gap={3} flexWrap="wrap">
              <Typography display="flex" alignItems="center" gap={1} component="h3" className="text-title">
                <GreenPointIcon />
                Субъект обращения с отходами
              </Typography>
              <ApplicationSubjectInfo
                inn={recipient_subject?.inn}
                name={recipient_subject?.name}
                address={recipient_object?.address_attributes?.attributes && (
                  formatAddressString(recipient_object?.address_attributes?.attributes)
                )}
                objectName={recipient_object?.name}
                ogrn={recipient_subject?.ogrn}
                created_at={waste_recipient?.created_at}
                documents={application?.document_url ? [{
                  document: application?.document_url,
                  extension: application?.extension,
                  documentName: `Договор №: ${application.document_name}`,
                }] : []}
              />
            </Box>
          </Box>
        </Box>
        {hasMap && (
          <LeafletMap
            sx={isMobileTablet ? {} : { position: 'sticky', top: '0' }}
            setOuterMap={setMap}
            outerMap={map}
            deviationRoutes={deviationRoutes}
            noTerSchemeObjects={noTerSchemeObjects}
            trackedVehicles={
              (vehiclePosition?.place?.x)
              && [{ ...vehiclePosition, egrz: vehicle_attributes?.attributes?.egrz }]
            }
            tracked_routes={vehicleTracks}
            getTrackCircleChildren={getTrackCircleChildren(
              vehicleTracks,
              vehicle_attributes?.attributes,
              rnicInfo,
            )}
            fromAddress={application?.application_objectives_attributes
              ?.map((item) => item
                ?.attributes
                ?.objective_attributes
                ?.attributes
                ?.address_attributes
                ?.attributes)}
            toAddress={application
              ?.objective_attributes
              ?.attributes
              ?.address_attributes
              ?.attributes && [application
              ?.objective_attributes
              ?.attributes
              ?.address_attributes
              ?.attributes]}
            planned_routes={
              Boolean(application.planned_routes?.length) && application.planned_routes
            }
            height={isMobileTablet ? '300px' : '100vh'}
            width={isMobileTablet ? '100vw' : 700}
          />
        )}
      </Box>
    </CenteredModal>
  );
}

export default function ModalApplication({
  onCopy, close, hasMap = true, sx, deviationRoutes, afterClose, noTerSchemeObjects, hasPublicLink,
}) {
  const [searchParams] = useSearchParams();

  return (
    <ParamsModal modalName="application">
      <ModalApplicationInfo
        hasCloseButton
        hasPublicLink={hasPublicLink}
        noTerSchemeObjects={noTerSchemeObjects}
        afterClose={afterClose}
        deviationRoutes={deviationRoutes}
        sx={sx}
        hasMap={hasMap}
        close={close}
        onCopy={onCopy}
        id={searchParams.get('modalId')}
      />
    </ParamsModal>
  );
}
