import React, { memo } from 'react';
import { Popup } from 'react-leaflet';
import TitleSubtitle from '../TitleSubtitle';
import { getDayMonthNameYearTime, getDistanceDate } from '../../helpers/dateHelper';

const textSx = { mb: 1 };

const shouldMemo = (
  prevProps,
  nextProps,
) => prevProps?.vehicle?.place?.x === nextProps?.vehicle?.place?.x;

export const VehiclePopup = memo(({ vehicle }) => (
  <Popup>
    <TitleSubtitle
      sx={textSx}
      title="Дата и время фиксации"
      subTitle={getDayMonthNameYearTime(vehicle.created_at)}
    />
    <TitleSubtitle
      sx={textSx}
      title="Продолжительность стоянки"
      subTitle={getDistanceDate(vehicle.time || vehicle?.navigationTime)}
    />
    <TitleSubtitle sx={textSx} title="ЕГРЗ ТС (Номер ТС)" subTitle={vehicle.egrz || vehicle?.stateNumber} />
    <TitleSubtitle
      sx={textSx}
      title="Марка и модель ТС:"
      subTitle={`${vehicle.brand ? `${vehicle.brand} ` : ''}${vehicle.model}`}
    />
    <TitleSubtitle sx={textSx} title="Скорость" subTitle={vehicle.velocity} />
    <TitleSubtitle
      title="Координаты"
      subTitle={`${vehicle.place?.x} ${vehicle.place?.y}`}
    />
  </Popup>
), shouldMemo);
