// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { TextMain, TextTitle } from '../../components/Text';
import VehicleInformationContainer from '../../components/Vehicle/VehicleInformationContainer';
import LeafletMap from '../../components/Map/LeafletMap';
import { useApi, useLeafletScreenshot } from '../../hooks';
import { applicationQRCodeRequest, getApplicationRequest } from '../../api/applicationsApi';
import ApplicationWasteInfo from '../../components/Application/ApplicationWasteInfo';
import QrCode from '../../components/QrCode';
import {
  CADASTR_ADMIN_ROUTE,
  CARRIER_PERSONAL_ACCOUNT_ROUTE,
  PUBLIC_APPLICATION_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
} from '../../constants/routes';
import BackdropLoading from '../../components/BackdropLoading';
import useVehiclePositionListener from '../../hooks/useVehiclePositionListener';
import ApplicationPdf from '../../components/ApplicationPdf';
import CustomButton from '../../components/Buttons/CustomButton';
import TitleSubtitle from '../../components/TitleSubtitle';
import { getDayMonthNameYearTime } from '../../helpers/dateHelper';
import { getTrackCircleChildren } from '../../helpers/mapHelper';
import useRnicInfo from '../../hooks/useRnicInfo';
import { useUserStore } from '../../store';
import { useGetApplicationFiles } from '../../hooks/application/useGetApplicationFiles';

export default function PublicApplicationPage() {
  const { id } = useParams();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [application, setApplication] = useState({});
  const [vehiclePosition, setVehiclePosition] = useState();
  const [vehicleTracks, setVehicleTracks] = useState([]);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isLoadingCoordinate, setIsLoadingCoordinate] = useState(true);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const [printMoment, setPrintMoment] = useState(false);
  const [mapImage, setMapImage] = useState();
  const [outerMap, setOuterMap] = useState();
  const makeMapScreen = useLeafletScreenshot({ map: outerMap, setter: setMapImage });
  const [localQrCodeUrl, setLocalQrCodeUrl] = useState('');
  const { userStore } = useUserStore();

  useApi({
    setter: (qrCode) => {
      if (qrCode?.data) {
        setLocalQrCodeUrl(window.URL.createObjectURL(qrCode.data));
      }
    },
    shouldRequest: true,
    request: () => applicationQRCodeRequest(id),
  });

  const { isLoading } = useApi({
    setter: setApplication,
    request: () => getApplicationRequest(id),
    shouldRequest: Boolean(id),
    errorCallback: (err) => {
      if (err?.response?.status === 404) navigate(PUBLIC_LOGIN_CARRIER_PAGE_ROUTE);
    },
  });

  useGetApplicationFiles({
    id: application?.id,
    application,
    setApplication,
    setIsLoading: setIsLoadingFiles,
  });

  useVehiclePositionListener({
    setVehiclePosition,
    guid_bnso: application?.vehicle_attributes?.attributes?.guid_bnso,
    vehiclePosition,
    setLoading: setIsLoadingCoordinate,
  });

  const { rnicInfo } = useRnicInfo({
    guid_bnso: application?.vehicle_attributes?.attributes?.guid_bnso,
  });

  const shouldPrint = hash?.includes?.('print') && !isLoading && !isMapLoading;

  const beforePrint = () => {
    setPrintMoment(true);
  };

  const afterPrint = () => {
    setPrintMoment(false);
  };

  useEffect(() => {
    if (shouldPrint) {
      beforePrint();
      navigate(pathname);
    }
    /* eslint-disable-next-line */
  }, [shouldPrint]);

  useEffect(() => {
    if (printMoment) {
      window.print();
    }
  }, [printMoment]);

  useEffect(() => {
    if (makeMapScreen && !mapImage) makeMapScreen();
  }, [makeMapScreen, mapImage]);

  useEffect(() => {
    window.addEventListener('afterprint', afterPrint);

    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  const printApplication = () => beforePrint();

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  if (id === undefined || !application?.id) return <TextMain medium>Загрузка</TextMain>;

  const boundsVehicle = vehiclePosition?.place && outerMap?.fitBounds
    ? () => {
      outerMap.fitBounds([
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
      ]);
    } : undefined;

  const toAddress = application?.objective_attributes
    ?.attributes
    ?.address_attributes
    ?.attributes;

  const {
    hazard_class_text,
    created_at,
    subject_attributes,
  } = application;

  const vehicleAttributes = application?.vehicle_attributes?.attributes;

  const canNavigate = userStore?.type === 'subject' || userStore?.type === 'user';

  const redirectByRole = () => {
    if (userStore?.type === 'subject') {
      navigate(CARRIER_PERSONAL_ACCOUNT_ROUTE);
    } else if (userStore?.type === 'user') {
      navigate(CADASTR_ADMIN_ROUTE);
    }
  };

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
      <BackdropLoading isLoading={isLoading || isMapLoading} position="fixed" />
      <Box width={tabletSizeAndLower ? '100%' : '70%'} pt={tabletSizeAndLower ? '4px' : 8}>
        {!printMoment && canNavigate && !(mapImage === undefined || isMapLoading || isLoading)
          && <BackButton onClick={redirectByRole} />}
        <Box
          px={tabletSizeAndLower && '4px'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={2}
          flexWrap="wrap"
        >
          <QrCode
            clipboardUrl={`${PUBLIC_APPLICATION_ROUTE}/${id}`}
            url={localQrCodeUrl}
          />
          <Box display="flex" flexDirection="column" gap={3} my={3}>
            <TitleSubtitle
              title="Субъект перевозчик"
              subTitle={subject_attributes?.attributes?.name}
            />
            <TitleSubtitle
              title="Дата и время получения разрешения:"
              subTitle={getDayMonthNameYearTime(created_at)}
            />
            <Box display="flex" alignItems="center" gap={3}>
              {hazard_class_text.length === 0 && (
                <TitleSubtitle
                  title="Класс отходов:"
                  subTitle={null}
                />
              )}
              {hazard_class_text.map((text) => (
                <TitleSubtitle
                  key={text}
                  title="Класс отходов:"
                  subTitle={text}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" gap={4}>
          <Box px={tabletSizeAndLower && '4px'}>
            <VehicleInformationContainer
              {...vehicleAttributes}
              showError={false}
              isPrintMoment={printMoment}
              isLoading={isLoadingCoordinate}
              setVehicleTracks={setVehicleTracks}
              vehiclePosition={vehiclePosition}
              boundsVehicle={boundsVehicle}
            />
            <Box>
              <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                <TextTitle h1>Данные разрешения</TextTitle>
              </Box>
              <ApplicationWasteInfo isLoadingFiles={isLoadingFiles} application={application} />
            </Box>
            {!printMoment && (
              <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} mb={3} mt={1}>
                <CustomButton onClick={printApplication} variant="contained" color="warning">
                  Распечатать разрешение
                </CustomButton>
                <ApplicationPdf application={application} mapImage={mapImage} localQrCodeUrl={localQrCodeUrl} />
              </Box>
            )}
          </Box>
          <LeafletMap
            outerMap={outerMap}
            hideControlButtons={printMoment}
            setOuterMap={setOuterMap}
            tracked_routes={vehicleTracks}
            getTrackCircleChildren={getTrackCircleChildren(
              vehicleTracks,
              vehicleAttributes,
              rnicInfo,
            )}
            zoomOutTime={null}
            trackedVehicles={
              (vehiclePosition?.place?.x)
              && [{ ...vehiclePosition, egrz: vehicleAttributes?.egrz }]
            }
            setIsMapLoading={setIsMapLoading}
            fromAddress={application?.application_objectives_attributes
              ?.map((item) => item
                ?.attributes
                ?.objective_attributes
                ?.attributes
                ?.address_attributes
                ?.attributes)}
            toAddress={toAddress && [toAddress]}
            planned_routes={application.planned_routes}
            width={520}
            height={520}
          />
        </Box>
      </Box>
    </Box>
  );
}
