import { compareId } from '../compareId';
import { METHODS_API } from '../../api/api';
import {
  cadastreForm7ReportActualVolumesRequest,
  cadastreInstallationsRequest,
  cadastreManufacturedProductsRequest,
  cadastreMonitoringTypesRequest,
  cadastreReportTechnologiesRequest,
  cadastreSecondaryWastesRequest,
  cadastreTechnologyObjectsRequest,
  cadastreTechnologyRequest,
  cadastreTechnologyWastesRequest,
} from '../../api/cadastreApi';
import {
  EQUIPMENTS_NAME,
  getInitialEquipmentItem,
  getInitialManufacturedProduct,
  getInitialMonitoringKinds,
  getInitialReportObject,
  getInitialSecondaryProducts,
  getInitialTechnology,
  getInitialWasteItem,
  MANUFACTURED_PRODUCTS_NAME,
  MONITORING_KINDS_NAME,
  REPORT_TECHNOLOGIES_NAME,
  SECONDARY_PRODUCTS_NAME,
  TECHNOLOGIES_NAME,
  WASTE_ITEMS_NAME,
} from '../../constants/lkCadastr/cadastrForm7';
import { formatObjectWithAddress, valueToString } from '../stringHelper';

export const getReportTechnologiesHelper = (report) => {
  if (report?.report_technologies_attributes?.length) {
    const reportTechnology = report?.report_technologies_attributes.at(-1);

    const allTechnologyObjects = [];

    const additionalFormItems = {};

    const technologyObjects = reportTechnology?.attributes?.technology_objects_attributes;

    technologyObjects.forEach((technologyObjectAttrbiutes) => {
      const technologyObject = { ...technologyObjectAttrbiutes, ...technologyObjectAttrbiutes?.attributes };
      const license = technologyObject.report_license_attributes;
      const technologies = technologyObject.technologies_attributes;

      if (technologies?.length) {
        additionalFormItems[TECHNOLOGIES_NAME] = technologies
          .map((technology) => {
            const technologyAttributes = technology?.attributes;

            return ({
              ...getInitialTechnology(),
              ...technologyAttributes,
              ...technology,
              develop_year: valueToString(technologyAttributes?.develop_year),
              technology_purpose: {
                label: technologyAttributes?.technology_purpose_text || '0',
                id: technologyAttributes?.technology_purpose || '',
                value: technologyAttributes?.technology_purpose || '',
              },
              [WASTE_ITEMS_NAME]: technologyAttributes?.technology_wastes_attributes?.length
                ? technologyAttributes.technology_wastes_attributes.map((wasteItem) => {
                  const wasteItemAttributes = wasteItem?.attributes;

                  return {
                    ...getInitialWasteItem(),
                    ...wasteItem,
                    ...wasteItemAttributes,
                    tons_per_yer: valueToString(wasteItemAttributes.tons_per_yer),
                    waste_type_id: {
                      ...wasteItemAttributes?.waste_type_attributes,
                      ...wasteItemAttributes?.waste_type_attributes?.attributes,
                      name: wasteItemAttributes?.waste_type_attributes?.attributes?.name || '0',
                    },
                  };
                })
                : [getInitialWasteItem()],
              [SECONDARY_PRODUCTS_NAME]: technologyAttributes?.secondary_wastes_attributes?.length
                ? technologyAttributes.secondary_wastes_attributes.map((secondaryWaste) => {
                  const secondaryWasteAttributes = secondaryWaste?.attributes;

                  return {
                    ...getInitialSecondaryProducts(),
                    ...secondaryWaste,
                    ...secondaryWasteAttributes,
                    tons_per_yer: valueToString(secondaryWasteAttributes.tons_per_yer),
                    waste_type_id: {
                      ...secondaryWasteAttributes?.waste_type_attributes,
                      ...secondaryWasteAttributes?.waste_type_attributes?.attributes,
                      name: secondaryWasteAttributes?.waste_type_attributes?.attributes?.name || '0',
                      code: secondaryWasteAttributes?.waste_type_attributes?.attributes?.code || '0',
                    },
                  };
                })
                : [getInitialSecondaryProducts()],
              [MANUFACTURED_PRODUCTS_NAME]: technologyAttributes?.manufactured_products_attributes?.length
                ? technologyAttributes.manufactured_products_attributes.map((manufactured) => {
                  const manufacturedAttributes = manufactured.attributes;
                  return {
                    ...getInitialManufacturedProduct(),
                    ...manufactured,
                    ...manufacturedAttributes,
                    tons_per_yer: valueToString(manufacturedAttributes.tons_per_yer),
                  };
                })
                : [getInitialManufacturedProduct()],
              [MONITORING_KINDS_NAME]: technologyAttributes?.monitoring_types_attributes?.length
                ? technologyAttributes.monitoring_types_attributes.map((monitoring) => {
                  const monitoringAttributes = monitoring?.attributes;

                  return {
                    ...getInitialMonitoringKinds(),
                    ...monitoring,
                    ...monitoringAttributes,
                  };
                })
                : [getInitialMonitoringKinds()],
              [EQUIPMENTS_NAME]: technologyAttributes?.installations_attributes?.length
                ? technologyAttributes.installations_attributes.map((installation) => {
                  const installationAttributes = installation?.attributes;
                  return ({
                    ...getInitialEquipmentItem(),
                    ...installation,
                    ...installationAttributes,
                    exploitation_temperature_from: valueToString(installationAttributes.exploitation_temperature_from),
                    exploitation_temperature_to: valueToString(installationAttributes.exploitation_temperature_to),
                    project_capacity: valueToString(installationAttributes.project_capacity),
                    kind: {
                      label: installationAttributes?.kind_text || '0',
                      id: installationAttributes?.kind || '',
                      value: installationAttributes?.kind || '',
                    },
                  });
                })
                : [getInitialEquipmentItem()],
            });
          });
      }

      allTechnologyObjects.push({
        ...getInitialReportObject(),
        ...technologyObject,
        szz: valueToString(technologyObject?.szz),
        design_power: valueToString(technologyObject?.design_power),
        actual_power: valueToString(technologyObject?.actual_power),
        exploitation_out_date: technologyObject?.exploitation_out_date || '0',
        exploitation_in_date: technologyObject?.exploitation_in_date || '0',
        date_conclusion: technologyObject?.date_conclusion || '0',
        objective_id: {
          ...technologyObject?.objective_attributes,
          ...technologyObject?.objective_attributes?.attributes,
          label: formatObjectWithAddress({ objective: technologyObject }) || '0',
        },
        license: {
          ...license,
          ...license?.attributes,
          license_number: license?.attributes?.license_number || '0',
        },
        ...additionalFormItems,
      });
    });

    if (!allTechnologyObjects.length) allTechnologyObjects.push(getInitialReportObject());

    return {
      id: reportTechnology?.id,
      [REPORT_TECHNOLOGIES_NAME]: allTechnologyObjects,
    };
  }
};

export const deleteForm7Entity = async ({ id, apiType }) => {
  const DELETE_API = {
    [REPORT_TECHNOLOGIES_NAME]: cadastreTechnologyObjectsRequest,
    [TECHNOLOGIES_NAME]: cadastreTechnologyRequest,
    [WASTE_ITEMS_NAME]: cadastreTechnologyWastesRequest,
    [MONITORING_KINDS_NAME]: cadastreMonitoringTypesRequest,
    [SECONDARY_PRODUCTS_NAME]: cadastreSecondaryWastesRequest,
    [MANUFACTURED_PRODUCTS_NAME]: cadastreManufacturedProductsRequest,
    [EQUIPMENTS_NAME]: cadastreInstallationsRequest,
  };

  const deletedItem = await DELETE_API[apiType]({
    method: METHODS_API.DELETE,
    id,
  });

  return deletedItem?.id;
};

export const deleteForm7Entities = async ({
  initialForms,
  forms,
}) => {
  // eslint-disable-next-line no-restricted-syntax
  for await (const initialTechnologyObject of initialForms.form7[REPORT_TECHNOLOGIES_NAME]) {
    const initialTechnologyObjectId = initialTechnologyObject?.id;

    // eslint-disable-next-line no-continue
    if (!initialTechnologyObjectId) continue;

    const existingTechnologyObject = forms.form7[REPORT_TECHNOLOGIES_NAME]
      .find((reportTechnology) => compareId(reportTechnology.id, initialTechnologyObjectId));

    if (!existingTechnologyObject) {
      await cadastreTechnologyObjectsRequest({
        method: METHODS_API.DELETE,
        id: initialTechnologyObjectId,
      });
    } else {
      const initialLicense = initialTechnologyObject.license;

      const initialLicenseId = initialLicense.id;

      // eslint-disable-next-line no-continue
      if (!initialLicenseId) continue;

      for await (const initialTechnology of initialTechnologyObject[TECHNOLOGIES_NAME]) {
        const initialTechnologyId = initialTechnology.id;

        // eslint-disable-next-line no-continue
        if (!initialTechnologyId) continue;

        const existingTechnology = existingTechnologyObject[TECHNOLOGIES_NAME]
          .find((technology) => compareId(
            technology.id,
            initialTechnologyId,
          ));

        if (!existingTechnology) {
          await cadastreReportTechnologiesRequest({
            method: METHODS_API.DELETE,
            id: initialTechnologyId,
          });
        } else {
          const technologiesApis = [
            { request: cadastreTechnologyWastesRequest, itemsName: WASTE_ITEMS_NAME },
            { request: cadastreMonitoringTypesRequest, itemsName: MONITORING_KINDS_NAME },
            { request: cadastreSecondaryWastesRequest, itemsName: SECONDARY_PRODUCTS_NAME },
            { request: cadastreManufacturedProductsRequest, itemsName: MANUFACTURED_PRODUCTS_NAME },
            { request: cadastreInstallationsRequest, itemsName: EQUIPMENTS_NAME },
          ];

          for await (const api of technologiesApis) {
            for await (const initialItem of initialTechnology[api.itemsName]) {
              const initialItemId = initialItem.id;

              // eslint-disable-next-line no-continue
              if (!initialItemId) continue;

              const existingItem = existingTechnology[api.itemsName]
                .find((item) => compareId(
                  item.id,
                  initialItemId,
                ));

              if (!existingItem) {
                await api.request({
                  method: METHODS_API.DELETE,
                  id: initialItemId,
                });
              }
            }
          }
        }
      }
    }
  }
};

export const saveOneForm7ReportTechnology = async ({ reportTechnology, reportId }) => {
  let reportTechnologyId = reportTechnology.id;

  try {
    const newReportTechnology = await cadastreReportTechnologiesRequest({
      method: reportTechnologyId ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        report_history_id: reportId,
      },
      id: reportTechnologyId,
    });

    reportTechnologyId = newReportTechnology?.id;
  } catch (e) {
    console.error(e);
  }

  return { reportTechnologyId };
};

export const saveOneForm7TechnologyObject = async ({ reportTechnology, technologyObject, reportId }) => {
  let reportTechnologyId = reportTechnology.id;
  let technologyObjectId = technologyObject?.id;

  if (!reportTechnologyId) {
    const { reportTechnologyId: newReportTechnologyId } = await saveOneForm7ReportTechnology({
      reportId,
      reportTechnology,
    });

    if (newReportTechnologyId) reportTechnologyId = newReportTechnologyId;
  }

  const { license } = technologyObject;

  const licenseId = license?.id;

  const newTechnologyObject = await cadastreTechnologyObjectsRequest({
    method: technologyObjectId ? METHODS_API.PUT : METHODS_API.POST,
    data: {
      exploitation_in_date: valueToString(technologyObject.exploitation_in_date),
      exploitation_out_date: valueToString(technologyObject.exploitation_out_date),
      reason_for_decommissioning: technologyObject.reason_for_decommissioning,
      design_power: technologyObject.design_power,
      actual_power: technologyObject.actual_power,
      szz: technologyObject.szz,
      body_name_conclusion: technologyObject.body_name_conclusion,
      date_conclusion: valueToString(technologyObject.date_conclusion),
      number_conclusion: technologyObject.number_conclusion,
      report_license_id: licenseId || '',
      objective_id: technologyObject.objective_id?.id || '',
      report_technology_id: reportTechnologyId,
    },
    id: technologyObjectId,
  });

  technologyObjectId = newTechnologyObject?.id;

  return { reportTechnologyId, technologyObjectId };
};

export const saveOneForm7TechnologyItem = async ({
  reportTechnology,
  technology,
  technologyObject,
  reportId,
  technologyWaste,
  installation,
  manufacturedProduct,
  secondaryWaste,
  monitoringKind,
}) => {
  let reportTechnologyId = reportTechnology?.id;
  let technologyObjectId = technologyObject?.id;

  if (!technologyObjectId) {
    const {
      reportTechnologyId: newReportTechnologyId,
      technologyObjectId: newTechnologyObjectId,
    } = await saveOneForm7TechnologyObject({
      reportId,
      reportTechnology,
      technologyObject,
    });

    if (newTechnologyObjectId) technologyObjectId = newTechnologyObjectId;
    if (newReportTechnologyId) reportTechnologyId = newReportTechnologyId;
  }

  let technologyId = technology?.id;
  let technologyWasteId = technologyWaste?.id;
  let installationId = installation?.id;
  let manufacturedProductId = manufacturedProduct?.id;
  let secondaryWasteId = secondaryWaste?.id;
  let monitoringKindId = monitoringKind?.id;

  const hasSomeInnerProps = technologyWaste
    || installation
    || manufacturedProduct
    || secondaryWaste
    || monitoringKind;

  if ((technology && !technologyId && hasSomeInnerProps) || (technology && !hasSomeInnerProps)) {
    const newTechnology = await cadastreTechnologyRequest({
      data: {
        name: technology.name,
        technology_purpose: technology.technology_purpose?.value || '',
        develop_year: technology.develop_year,
        short_description: technology.short_description,
        developer_name: technology.developer_name,
        legal_address: technology.legal_address,
        actual_address: technology.actual_address,
        developer_inn: technology.developer_inn,
        developer_kpp: technology.developer_kpp,
        developer_oktmo: technology.developer_oktmo,
        developer_ogrn: technology.developer_ogrn,
        developer_okved: technology.developer_okved,
        developer_okpo: technology.developer_okpo,
        technology_object_id: technologyObjectId,
      },
      method: technologyId ? METHODS_API.PUT : METHODS_API.POST,
      id: technologyId,
    });

    technologyId = newTechnology?.id;
  }

  try {
    if (technologyWaste) {
      const newTechnologyWaste = await cadastreTechnologyWastesRequest({
        data: {
          waste_type_id: technologyWaste.waste_type_id?.id || '',
          name: technologyWaste.name,
          tons_per_yer: technologyWaste.tons_per_yer,
          technology_id: technologyId,
        },
        method: technologyWaste.id ? METHODS_API.PUT : METHODS_API.POST,
        id: technologyWaste.id,
      });

      technologyWasteId = newTechnologyWaste?.id;
    } else if (installation) {
      const newInstallation = await cadastreInstallationsRequest({
        data: {
          name: installation.name,
          manufacturers_name: installation.manufacturers_name,
          project_capacity: installation.project_capacity,
          kind: installation.kind?.value,
          exploitation_mode: installation.exploitation_mode,
          exploitation_temperature_from: installation.exploitation_temperature_from,
          exploitation_temperature_to: installation.exploitation_temperature_to,
          technology_id: technologyId,
        },
        method: installation.id ? METHODS_API.PUT : METHODS_API.POST,
        id: installation.id,
      });

      installationId = newInstallation?.id;
    } else if (manufacturedProduct) {
      const newManufacturedProduct = await cadastreManufacturedProductsRequest({
        data: {
          product_name: manufacturedProduct.product_name,
          certificate_available: manufacturedProduct.certificate_available,
          tons_per_yer: manufacturedProduct.tons_per_yer,
          code_okp: manufacturedProduct.code_okp,
          technology_id: technologyId,
        },
        method: manufacturedProduct.id ? METHODS_API.PUT : METHODS_API.POST,
        id: manufacturedProduct.id,
      });

      manufacturedProductId = newManufacturedProduct?.id;
    } else if (secondaryWaste) {
      const zeroValue = secondaryWaste.waste_type_id?.name === '0' || secondaryWaste.waste_type_id?.code === '0';

      const newSecondaryWaste = await cadastreSecondaryWastesRequest({
        data: {
          waste_type_id: zeroValue ? '' : secondaryWaste.waste_type_id?.id || '',
          name: zeroValue ? '' : secondaryWaste.waste_type_id?.name || '',
          tons_per_yer: secondaryWaste.tons_per_yer,
          technology_id: technologyId,
        },
        method: secondaryWaste.id ? METHODS_API.PUT : METHODS_API.POST,
        id: secondaryWaste.id,
      });

      secondaryWasteId = newSecondaryWaste?.id;
    } else if (monitoringKind) {
      const newMonitoringKind = await cadastreMonitoringTypesRequest({
        data: {
          name: monitoringKind.name,
          is_observation_standard: monitoringKind.is_observation_standard,
          technology_id: technologyId,
        },
        method: monitoringKind.id ? METHODS_API.PUT : METHODS_API.POST,
        id: monitoringKind.id,
      });

      monitoringKindId = newMonitoringKind?.id;
    }
  } catch (e) {
    console.error(e);
  }

  return {
    reportTechnologyId,
    technologyObjectId,
    technologyWasteId,
    technologyId,
    installationId,
    manufacturedProductId,
    monitoringKindId,
    secondaryWasteId,
  };
};

export const createUpdateForm7Entities = async ({ forms, reportId }) => {
  const form7Info = forms.form7;

  let reportTechnologyId = form7Info.id;

  const newReportTechnology = await cadastreReportTechnologiesRequest({
    method: reportTechnologyId ? METHODS_API.PUT : METHODS_API.POST,
    data: {
      report_history_id: reportId,
    },
    id: reportTechnologyId,
  });

  reportTechnologyId = newReportTechnology?.id;

  for await (const technologyObject of form7Info[REPORT_TECHNOLOGIES_NAME]) {
    let technologyObjectId = technologyObject.id;

    const { license } = technologyObject;

    const licenseId = license?.id;

    const newTechnologyObject = await cadastreTechnologyObjectsRequest({
      method: technologyObjectId ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        exploitation_in_date: valueToString(technologyObject.exploitation_in_date),
        exploitation_out_date: valueToString(technologyObject.exploitation_out_date),
        reason_for_decommissioning: technologyObject.reason_for_decommissioning,
        design_power: technologyObject.design_power,
        actual_power: technologyObject.actual_power,
        szz: technologyObject.szz,
        body_name_conclusion: technologyObject.body_name_conclusion,
        date_conclusion: valueToString(technologyObject.date_conclusion),
        number_conclusion: technologyObject.number_conclusion,
        report_license_id: licenseId || '',
        objective_id: technologyObject.objective_id?.id || '',
        report_technology_id: reportTechnologyId,
      },
      id: technologyObjectId,
    });

    technologyObjectId = newTechnologyObject?.id;

    // eslint-disable-next-line no-continue
    if (!technologyObjectId) continue;

    // eslint-disable-next-line no-restricted-syntax
    for await (const technology of technologyObject[TECHNOLOGIES_NAME]) {
      try {
        let technologyId = technology.id;

        const newTechnology = await cadastreTechnologyRequest({
          data: {
            name: technology.name,
            technology_purpose: technology.technology_purpose?.value || '',
            develop_year: technology.develop_year,
            short_description: technology.short_description,
            developer_name: technology.developer_name,
            legal_address: technology.legal_address,
            actual_address: technology.actual_address,
            developer_inn: technology.developer_inn,
            developer_kpp: technology.developer_kpp,
            developer_oktmo: technology.developer_oktmo,
            developer_ogrn: technology.developer_ogrn,
            developer_okved: technology.developer_okved,
            developer_okpo: technology.developer_okpo,
            technology_object_id: technologyObjectId,
          },
          method: technologyId ? METHODS_API.PUT : METHODS_API.POST,
          id: technologyId,
        });

        technologyId = newTechnology.id;

        for await (const wasteItem of technology[WASTE_ITEMS_NAME]) {
          try {
            await cadastreTechnologyWastesRequest({
              data: {
                waste_type_id: wasteItem.waste_type_id?.id || '',
                name: wasteItem.name,
                tons_per_yer: wasteItem.tons_per_yer,
                technology_id: technologyId,
              },
              method: wasteItem.id ? METHODS_API.PUT : METHODS_API.POST,
              id: wasteItem.id,
            });
          } catch (e) {
            console.error(e);
          }
        }
        for await (const installation of technology[EQUIPMENTS_NAME]) {
          try {
            await cadastreInstallationsRequest({
              data: {
                name: installation.name,
                manufacturers_name: installation.manufacturers_name,
                project_capacity: installation.project_capacity,
                kind: installation.kind?.value,
                exploitation_mode: installation.exploitation_mode,
                exploitation_temperature_from: installation.exploitation_temperature_from,
                exploitation_temperature_to: installation.exploitation_temperature_to,
                technology_id: technologyId,
              },
              method: installation.id ? METHODS_API.PUT : METHODS_API.POST,
              id: installation.id,
            });
          } catch (e) {
            console.error(e);
          }
        }

        for await (const manufacturedProduct of technology[MANUFACTURED_PRODUCTS_NAME]) {
          try {
            await cadastreManufacturedProductsRequest({
              data: {
                product_name: manufacturedProduct.product_name,
                certificate_available: manufacturedProduct.certificate_available,
                tons_per_yer: manufacturedProduct.tons_per_yer,
                code_okp: manufacturedProduct.code_okp,
                technology_id: technologyId,
              },
              method: manufacturedProduct.id ? METHODS_API.PUT : METHODS_API.POST,
              id: manufacturedProduct.id,
            });
          } catch (e) {
            console.error(e);
          }
        }

        for await (const secondaryWaste of technology[SECONDARY_PRODUCTS_NAME]) {
          try {
            await cadastreSecondaryWastesRequest({
              data: {
                waste_type_id: secondaryWaste.waste_type_id?.id || '',
                name: secondaryWaste.name,
                tons_per_yer: secondaryWaste.tons_per_yer,
                technology_id: technologyId,
              },
              method: secondaryWaste.id ? METHODS_API.PUT : METHODS_API.POST,
              id: secondaryWaste.id,
            });
          } catch (e) {
            console.error(e);
          }
        }

        for await (const monitoringKind of technology[MONITORING_KINDS_NAME]) {
          try {
            await cadastreMonitoringTypesRequest({
              data: {
                name: monitoringKind.name,
                is_observation_standard: monitoringKind.is_observation_standard,
                technology_id: technologyId,
              },
              method: monitoringKind.id ? METHODS_API.PUT : METHODS_API.POST,
              id: monitoringKind.id,
            });
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};

export const saveForm7ReportTechnologies = async ({
  forms,
  reportId,
  isImportToExistingReport,
  previousForms,
}) => {
  const form7Info = forms.form7;
  const previousForm7Info = previousForms?.form7;

  const mainForm7Info = previousForm7Info || form7Info;

  const form5InfoLicenses = forms.form5.licenses;
  const previousForm5InfoLicenses = previousForms?.form5?.licenses;

  const reportTechnologyId = mainForm7Info.id;

  const reportTechnologyData = {
    report_history_id: reportId,
    technology_objects: mainForm7Info[REPORT_TECHNOLOGIES_NAME].map((technologyObject) => {
      const technologyObjectId = technologyObject.id;

      const { license } = technologyObject;

      let licenseId = license?.id;

      if (previousForm5InfoLicenses) {
        const foundPreviousLicenseIndex = previousForm5InfoLicenses.findIndex((item) => compareId(item.id, licenseId));
        const foundCurrentLicense = form5InfoLicenses[foundPreviousLicenseIndex];

        licenseId = foundCurrentLicense?.id;
      }

      const technologyObjectData = {
        exploitation_in_date: valueToString(technologyObject.exploitation_in_date),
        exploitation_out_date: valueToString(technologyObject.exploitation_out_date),
        reason_for_decommissioning: technologyObject.reason_for_decommissioning,
        design_power: technologyObject.design_power,
        actual_power: technologyObject.actual_power,
        szz: technologyObject.szz,
        body_name_conclusion: technologyObject.body_name_conclusion,
        date_conclusion: valueToString(technologyObject.date_conclusion),
        number_conclusion: technologyObject.number_conclusion,
        objective_id: technologyObject.objective_id?.id || '',
        [TECHNOLOGIES_NAME]: technologyObject[TECHNOLOGIES_NAME].map((technology) => {
          const technologyId = technology.id;

          const technologyData = {
            name: technology.name,
            technology_purpose: technology.technology_purpose?.value || '',
            develop_year: technology.develop_year,
            short_description: technology.short_description,
            developer_name: technology.developer_name,
            legal_address: technology.legal_address,
            actual_address: technology.actual_address,
            developer_inn: technology.developer_inn,
            developer_kpp: technology.developer_kpp,
            developer_oktmo: technology.developer_oktmo,
            developer_ogrn: technology.developer_ogrn,
            developer_okved: technology.developer_okved,
            developer_okpo: technology.developer_okpo,
            technology_wastes: technology[WASTE_ITEMS_NAME].map((wasteItem) => {
              const wasteItemId = wasteItem.id;

              const wasteItemData = {
                waste_type_id: wasteItem.waste_type_id?.id || '',
                name: wasteItem.name,
                tons_per_yer: wasteItem.tons_per_yer,
              };

              if (!isImportToExistingReport && technologyId) wasteItemData.technology_id = technologyId;
              if (!isImportToExistingReport && wasteItemId) wasteItemData.id = wasteItemId;

              return wasteItemData;
            }),
            installations: technology[EQUIPMENTS_NAME].map((equipment) => {
              const equipmentId = equipment.id;

              const equipmentData = {
                name: equipment.name,
                manufacturers_name: equipment.manufacturers_name,
                project_capacity: equipment.project_capacity,
                kind: equipment.kind?.value,
                exploitation_mode: equipment.exploitation_mode,
                exploitation_temperature_from: equipment.exploitation_temperature_from,
                exploitation_temperature_to: equipment.exploitation_temperature_to,
              };

              if (!isImportToExistingReport && technologyId) equipmentData.technology_id = technologyId;
              if (!isImportToExistingReport && equipmentId) equipmentData.id = equipmentId;

              return equipmentData;
            }),
            manufactured_products: technology[MANUFACTURED_PRODUCTS_NAME].map((manufacturedProduct) => {
              const manufacturedProductId = manufacturedProduct.id;

              const manufacturedProductData = {
                product_name: manufacturedProduct.product_name,
                certificate_available: manufacturedProduct.certificate_available,
                tons_per_yer: manufacturedProduct.tons_per_yer,
                code_okp: manufacturedProduct.code_okp,
              };

              if (!isImportToExistingReport && technologyId) manufacturedProductData.technology_id = technologyId;
              if (!isImportToExistingReport && manufacturedProductId) manufacturedProductData.id = manufacturedProductId;

              return manufacturedProductData;
            }),
            secondary_wastes: technology[SECONDARY_PRODUCTS_NAME].map((secondaryProduct) => {
              const secondaryProductId = secondaryProduct.id;

              const secondaryProductData = {
                waste_type_id: secondaryProduct.waste_type_id?.id || '',
                name: secondaryProduct.name,
                tons_per_yer: secondaryProduct.tons_per_yer,
              };

              if (!isImportToExistingReport && technologyId) secondaryProductData.technology_id = technologyId;
              if (!isImportToExistingReport && secondaryProductId) secondaryProductData.id = secondaryProductId;

              return secondaryProductData;
            }),
            monitoring_types: technology[MONITORING_KINDS_NAME].map((monitoringKind) => {
              const monitoringKindId = monitoringKind.id;

              const monitoringKindData = {
                name: monitoringKind.name,
                is_observation_standard: monitoringKind.is_observation_standard,
              };

              if (!isImportToExistingReport && technologyId) monitoringKindData.technology_id = technologyId;
              if (!isImportToExistingReport && monitoringKindId) monitoringKindData.id = monitoringKindId;

              return monitoringKindData;
            }),
          };

          if (!isImportToExistingReport && technologyObjectId) technologyData.technology_object_id = technologyObjectId;
          if (!isImportToExistingReport && technologyId) technologyData.id = technologyId;

          return technologyData;
        }),
      };

      if (licenseId) technologyObjectData.report_license_id = licenseId || '';
      if (!isImportToExistingReport && reportTechnologyId) technologyObjectData.report_technology_id = reportTechnologyId;
      if (!isImportToExistingReport && technologyObjectId) technologyObjectData.id = technologyObjectId;

      return technologyObjectData;
    }),
  };

  const responseReportTechnologies = await cadastreForm7ReportActualVolumesRequest({
    method: METHODS_API.POST,
    data: [reportTechnologyData],
  });

  return responseReportTechnologies?.data;
};
