import React from 'react';
import { Alert } from '@mui/material';
import CustomButton from '../../../components/Buttons/CustomButton';
import EmailConfirmationContainer from '../../../components/EmailConfirmationContainer';

export default function EmailConfirmationError() {
  return (
    <EmailConfirmationContainer>
      <Alert severity="error">Не удалось подтвердить ваш E-mail. Свяжитесь с администратором</Alert>
      <CustomButton href="/">Вернуться на главную</CustomButton>
    </EmailConfirmationContainer>
  );
}
