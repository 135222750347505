import React from 'react';
import LoginPage from '../Public/LoginPage';
import { loginSubjectRequest } from '../../api/subjectApi';
import { CARRIER_PERSONAL_ACCOUNT_ROUTE } from '../../constants/routes';

export default function CarrierLoginPage() {
  return (
    <LoginPage
      forgotPasswordParams="carrier"
      title="перевозчика"
      loginRequest={loginSubjectRequest}
      redirectUrl={CARRIER_PERSONAL_ACCOUNT_ROUTE}
    />
  );
}
