// @ts-nocheck
import PrintIcon from '@mui/icons-material/Print';
import { Alert, Box, CircularProgress } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import React, { useState } from 'react';
import useScanDetection from 'use-scan-detection';
import { METHODS_API } from '../../../api/api';
import {
  cadastreReportHistoryRequest,
  cadastreSignMailRequest,
  getCadastreReportStatusesRequest,
} from '../../../api/cadastreApi';
import { REPORT_STATUS_NAMES, flexGap2 } from '../../../constants/lkCadastr/cadastrStorage';
import { compareId, downloadBlobFileOnClick } from '../../../helpers';
import { useApi } from '../../../hooks';
import { useUserStore } from '../../../store';
import CustomButton from '../../Buttons/CustomButton';
import ModalWithTitle from '../ModalWithTitle';

function ScannerHookComponent({ searchReportByCode }) {
  useScanDetection({
    onComplete: (code) => {
      if (searchReportByCode) searchReportByCode(code);
    },
  });

  return null;
}

export default function ModalSignatureMail({
  report, close, searchReportByCode, isModalScan, getReports,
}) {
  const { userStore } = useUserStore();
  const [reportStatuses, setReportStatuses] = useState('');
  const [signMail, setSignMail] = useState(null);

  const signatureMailUrl = report?.transmittal_mail_signed_url || report?.transmittal_mail_url;

  useApi({
    setter: (mail) => {
      if (mail?.data) {
        setSignMail(window.URL.createObjectURL(mail.data));
      } else {
        close();
      }
    },
    shouldRequest: Boolean(report.id),
    errorCallback: () => {
      close();
    },
    request: () => cadastreSignMailRequest(report.id, Boolean(report?.transmittal_mail_signed_url)),
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const findStatusIdByCode = (code) => reportStatuses.find((item) => item.code === code)?.id;

  const { reportStatusesLoading } = useApi({
    request: getCadastreReportStatusesRequest,
    setter: (data) => {
      setReportStatuses(
        data.filter((status) => status.code !== REPORT_STATUS_NAMES.formation),
      );
    },
    shouldRequest: true,
  });

  const { defaultRequest: reportHistoryApi } = useApi({
    request: cadastreReportHistoryRequest,
  });

  if ((!signatureMailUrl || !signMail) && !isModalScan) return null;

  const downloadMail = async () => {
    downloadBlobFileOnClick(signMail, 'Сопроводительное письмо');

    const isUserSubject = userStore?.type === 'subject';

    if (isUserSubject && compareId(report.report_status_attributes?.id, findStatusIdByCode(REPORT_STATUS_NAMES.checked))) {
      await reportHistoryApi({
        method: METHODS_API.PUT,
        data: {
          report_status_id: findStatusIdByCode(REPORT_STATUS_NAMES.paper_document_printed),
        },
        id: report.id,
      });

      await getReports();
    }

    close();
  };

  return (
    <ModalWithTitle
      containerSx={{ height: isModalScan ? 'initial' : '100vh', maxHeight: '97vh', width: '720px' }}
      close={close}
      open
      title={isModalScan ? 'Cканирование документа' : 'Сопроводительное письмо'}
    >
      {(isModalScan || report?.report_status_attributes?.attributes?.code === REPORT_STATUS_NAMES.checked) && (
        <ScannerHookComponent searchReportByCode={searchReportByCode} />
      )}
      {isModalScan ? (
        <Box sx={{ ...flexGap2, alignItems: 'center' }}>
          <Alert severity="error">ВАЖНО! Убедитесь, что у вас включена английская раскладка EN.</Alert>
          <Alert severity="warning">Ожидается считывание символов. Не нажимайте кнопки на клавиатуре.</Alert>
          <CircularProgress />
        </Box>
      ) : (
        <Box py={3} width="100%" sx={flexGap2}>
          <Box>
            <CustomButton
              isLoading={reportStatusesLoading}
              onClick={downloadMail}
              startIcon={<PrintIcon />}
            >
              Печать
            </CustomButton>
          </Box>
          <Box width="100%">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
              <Viewer
                localization={ru_RU}
                defaultScale="ActualSize"
                fileUrl={signMail}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </Box>
        </Box>
      )}
    </ModalWithTitle>
  );
}
