import { compareId } from '../../helpers';
import { addTotalCadastreCount } from '../../helpers/cadastreForms/getFormField';

export const FORM8_AREA_DOCUMENTS_NAME = 'area_documents';
export const PLACED_WASTES_NAME = 'placed_wastes';
export const NEAREST_WATER_OBJECT_NAME = 'nearest_water_object';
export const NEAREST_TOWN_NAME = 'nearest_town';
export const PROJECT_DOCUMENT_NAME = 'project_document';
export const ENVIRONMENT_MONITORING_TYPE_NAME = 'environmental_monitoring_types';
export const OBJECT_RIGHT_TYPES_NAME = 'object_right_types';
export const OBJECT_SUBJECT_RELATIONSHIPS_NAME = 'object_subject_relationships';
export const WASTE_PLACEMENT_MUNICIPALITIES_NAME = 'waste_placement_municipalities';
export const RESTRICTED_AREA_TYPE_NAME = 'restricted_area_type';
export const ENVIRONMENTAL_SYSTEM_TYPE = 'environmental_system_type';
export const FORM8_LICENSES = 'licenses';
export const FORM8_PLACING_OBJECTS_NAME = 'placing_objects';

const form8AreaFields = [
  'document_name',
  'body_name',
  { name: 'issue_date', valid: (issue_date) => issue_date || compareId(issue_date, 0) },
  'document_number',
  'cadastral_number_area',
  'owner',
  'area_category',
  'permitted_use_type',
];

export const getInitialForm8AreaDocuments = () => ({
  randId: Math.random(),

  document_name: '',
  body_name: '',
  issue_date: null,
  document_number: '',
  cadastral_number_area: '',
  owner: '',
  area_category: '',
  permitted_use_type: '',
});

const environmentalSystemTypeFields = [
  'name',
];

export const getInitialEnvironmentalSystemType = () => ({
  randId: Math.random(),
  name: '',
});

const environmentMonitoringTypeFields = [
  'normative_rule',
  'name',
];

export const getInitialEnvironmentMonitoringType = () => ({
  randId: Math.random(),
  normative_rule: '',
  name: '',
});

const wastePlacementMunicipalityFields = [
  { name: 'oktmo_id', valid: (oktmo_id) => oktmo_id?.id || compareId(oktmo_id?.label, 0) },
  'waste_volume',
];

export const getInitialWastePlacementMunicipality = () => ({
  randId: Math.random(),
  oktmo_id: '',
  waste_volume: '',
});

const wasteTransporterFields = [
  { name: 'subject_id', valid: (subject_id) => subject_id?.id || compareId(subject_id?.name, 0) },
  'agreement_number',
  { name: 'agreement_date', valid: (agreement_date) => agreement_date || compareId(agreement_date, 0) },
  'tons_qty',
];

export const getInitialObjectSubjectRelationship = () => ({
  randId: Math.random(),
  subject_id: '',
  agreement_number: '',
  agreement_date: null,
  tons_qty: '',
});

const objectRightTypeFields = [
  { name: 'right_type', valid: (right_type) => right_type?.id || compareId(right_type?.label, 0) },
  'document_number',
  'body_name',
  { name: 'issue_date', valid: (issue_date) => issue_date || compareId(issue_date, 0) },
  'kind_number',
];

export const getInitialObjectRight = () => ({
  randId: Math.random(),
  right_type: '',
  document_number: '',
  body_name: '',
  issue_date: null,
  kind_number: '',
});

const placedWasteFields = [
  { name: 'waste_type_id', valid: (waste_type) => waste_type?.id || compareId(waste_type?.name, 0) },
  'tons_per_year',
  'placement_technology',
  'placement_method',
];

export const getInitialPlacedWaste = () => ({
  randId: Math.random(),
  waste_type_id: '',
  tons_per_year: '',
  placement_technology: '',
  placement_method: '',
});

const projectDocumentFields = [
  'name',
  'developer_name',
  'develope_year',
  'positive_result_gee',
  { name: 'issue_date', valid: (issue_date) => issue_date || compareId(issue_date, 0) },
  'document_number',
  'organisation_approved_gee',
  'issue_date_gee',
  'result_number_gee',
];

export const getInitialProjectDocument = () => ({
  randId: Math.random(),
  name: '',
  developer_name: '',
  develope_year: '',
  positive_result_gee: '',
  issue_date: null,
  document_number: '',
  organisation_approved_gee: '',
  issue_date_gee: null,
  result_number_gee: '',
});

const restrictedAreaType = ['name'];

export const getInitialRestrictedAreaType = () => ({
  randId: Math.random(),
  name: '',
});

const form8LicenseFields = [
  { name: 'license', valid: (license) => license?.id },
];

export const getInitialForm8Licenses = () => ({
  randId: Math.random(),
  license: '',
});

const nearestTownFields = [
  'name',
  { name: 'direction', valid: (direction) => direction?.id || compareId(direction?.label, 0) },
  'distance',
];

export const getInitialNearestTown = () => ({
  name: '',
  direction: '',
  distance: '',
});

const nearestWaterObjectFields = [
  'name',
  'distance',
];

export const getInitialNearestWasterObject = () => ({
  name: '',
  distance: '',
});

const objectFields = [
  { name: 'objective_id', valid: (objective_id) => objective_id?.id || compareId(objective_id?.name, 0) },
  { name: 'object_state', valid: (object_state) => object_state?.id || compareId(object_state?.label, 0) },
  { name: 'reclamation_type', valid: ((reclamation_type, object) => object?.noReclamation || reclamation_type) },
  { name: 'reclamation_date', valid: ((reclamation_date, object) => object?.noReclamation || reclamation_date) },
  'operating_year_end',
  'commissioning_year',
  'document_name',
  'object_design_power',
  'object_actual_power',
  'szz_size',
  'object_capacity',
  'object_area',
  'placed_total',
  'registration_number_groro',
  'registration_date_groro',
];

export const getInitialForm8PlacedObject = () => ({
  randId: Math.random(),
  objective_id: '',
  object_state: '',
  noReclamation: false,
  reclamation_type: '',
  reclamation_date: null,
  operating_year_end: '',
  commissioning_year: '',
  document_name: '',
  object_design_power: '',
  object_actual_power: '',
  szz_size: '',
  object_capacity: '',
  object_area: '',
  placed_total: '',
  registration_number_groro: '',
  registration_date_groro: null,

  // objects
  [ENVIRONMENTAL_SYSTEM_TYPE]: getInitialEnvironmentalSystemType(),
  [RESTRICTED_AREA_TYPE_NAME]: getInitialRestrictedAreaType(),
  [PROJECT_DOCUMENT_NAME]: getInitialProjectDocument(),
  [NEAREST_TOWN_NAME]: getInitialNearestTown(),
  [NEAREST_WATER_OBJECT_NAME]: getInitialNearestWasterObject(),

  // arrays
  [FORM8_LICENSES]: [getInitialForm8Licenses()],
  [PLACED_WASTES_NAME]: [getInitialPlacedWaste()],
  [FORM8_AREA_DOCUMENTS_NAME]: [getInitialForm8AreaDocuments()],
  [ENVIRONMENT_MONITORING_TYPE_NAME]: [getInitialEnvironmentMonitoringType()],
  [OBJECT_RIGHT_TYPES_NAME]: [getInitialObjectRight()],
  [OBJECT_SUBJECT_RELATIONSHIPS_NAME]: [getInitialObjectSubjectRelationship()],
  [WASTE_PLACEMENT_MUNICIPALITIES_NAME]: [getInitialWastePlacementMunicipality()],
});

export const getInitialForm8 = () => ({
  id: '',
  [FORM8_PLACING_OBJECTS_NAME]: [getInitialForm8PlacedObject()],
});

export const form8FilledCount = (objects, addAllDataLength, addFilledData, ignoreLicense) => {
  objects.forEach((object) => {
    addAllDataLength(objectFields.length);
    addTotalCadastreCount(objectFields, object, addFilledData);

    addAllDataLength(nearestTownFields.length);
    addTotalCadastreCount(nearestTownFields, object[NEAREST_TOWN_NAME], addFilledData);

    addAllDataLength(restrictedAreaType.length);
    addTotalCadastreCount(restrictedAreaType, object[RESTRICTED_AREA_TYPE_NAME], addFilledData);

    addAllDataLength(nearestWaterObjectFields.length);
    addTotalCadastreCount(nearestWaterObjectFields, object[NEAREST_WATER_OBJECT_NAME], addFilledData);

    addAllDataLength(projectDocumentFields.length);
    addTotalCadastreCount(projectDocumentFields, object[PROJECT_DOCUMENT_NAME], addFilledData);

    addAllDataLength(environmentalSystemTypeFields.length);
    addTotalCadastreCount(environmentalSystemTypeFields, object[ENVIRONMENTAL_SYSTEM_TYPE], addFilledData);

    const objectCalcItems = [
      { name: FORM8_AREA_DOCUMENTS_NAME, itemsFields: form8AreaFields },
      { name: ENVIRONMENT_MONITORING_TYPE_NAME, itemsFields: environmentMonitoringTypeFields },
      { name: PLACED_WASTES_NAME, itemsFields: placedWasteFields },
      { name: OBJECT_RIGHT_TYPES_NAME, itemsFields: objectRightTypeFields },
      { name: WASTE_PLACEMENT_MUNICIPALITIES_NAME, itemsFields: wastePlacementMunicipalityFields },
      { name: FORM8_LICENSES, itemsFields: form8LicenseFields },
      { name: OBJECT_SUBJECT_RELATIONSHIPS_NAME, itemsFields: wasteTransporterFields },
    ];

    objectCalcItems.forEach(({ name, itemsFields }) => {
      if ((name === FORM8_LICENSES && !ignoreLicense) || name !== FORM8_LICENSES) {
        object[name].forEach((item) => {
          addAllDataLength(itemsFields.length);

          addTotalCadastreCount(itemsFields, item, addFilledData);
        });
      }
    });
  });
};

export const form8PercentGetter = (forms, { ignoreLicense }) => {
  const { form8 } = forms;

  let allDataLength = 0;
  let filledData = 0;

  const addAllDataLength = (count) => {
    allDataLength += count;
  };

  const addFilledData = () => {
    filledData += 1;
  };

  form8FilledCount(form8[FORM8_PLACING_OBJECTS_NAME], addAllDataLength, addFilledData, ignoreLicense);

  return Math.floor((filledData / allDataLength) * 100);
};
