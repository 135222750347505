export const DRAWER_WIDTH = 270;
export const PHONE_LENGTH = 11;
export const RUBY_REST_URL = '.json';
export const TEXT_NO_VALUE = 'Нет';

export { OWNERSHIP_TYPE_TRANSLATE } from './vehicleConstants';
export { CARRIER_LINKS } from './headerConstants';
export { INPUT_EMPTY_TEXT, INPUT_TEL_TYPE } from './inputConstants';
export { isWebView, getExtensionWebView } from './mobile';

export {
  SUBJECT_KINDS,
  ACTIVITY_KINDS,
  ENTERPRISE_TYPES,
  USER_ENTITY_TYPE,
} from './subjectConstants';


