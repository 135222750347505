// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import LeafletMap from './LeafletMap';
import WasteMapFilters from './WasteMapFilters';
import { useApi, usePushNotification } from '../../hooks';
import { getObjectivesRequest } from '../../api/objectivesApi';
import { WASTE_MANAGEMENT_TYPE_IDS, WASTE_MANAGEMENT_TYPES_ENUM } from '../../constants/enums';
import ObjectInfoSlider from './components/ObjectInfoSlider';
import areas_lo from './areas_lo.json';
import RedHouse from '../../assets/map-marker-red.svg';
import YellowHouse from '../../assets/map-marker-yellow.svg';
import useTrackVehiclesPositions from '../../hooks/useTrackVehiclesPositions';
import VehicleInfoSlider from './components/VehicleInfoSlider';
import { getVehiclesRequest } from '../../api/vehiclesApi';
import { convertCRS, getTrackCircleChildren } from '../../helpers/mapHelper';
import useRnicInfo from '../../hooks/useRnicInfo';
import { getGeoServerData } from '../../api/geolocation';
import { compareId } from '../../helpers';
import { getReferencesRequest } from '../../api/referenceApi';
import { WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import InfoSlider from './components/InfoSlider';
import TitleSubtitle from '../TitleSubtitle';
import CustomButton from '../Buttons/CustomButton';
import { TextTitle } from '../Text';
import { getDayMonthNameYearTime } from '../../helpers/dateHelper';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import useGetReferenceItems from '../../hooks/useGetRefferenceItems';

const TEST_LAYERS = [
  { id: 'areas_lo', value: false, name: 'Границы районов Санкт-Петербурга' },
  { id: 'regions_lo', value: false, name: 'Районы ЛО' },
];

const selectedAreaTitleSx = {
  display: 'flex',
  gap: 1,
};

export default function MapWithFilters() {
  const [objectives, setObjectives] = useState([]);
  const [vehicleTracks, setVehicleTracks] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [mergedVehicles, setMergedVehicles] = useState([]);
  const [searchVehicle, setSearchVehicle] = useState('');
  const [wasteFilters, setWasteFilters] = useState([]);
  const [wasteGeneratorInfo, setWasteGeneratorInfo] = useState({});
  const [vehicleEGTZ, setVehicleEGTZ] = useState();
  const [areVehiclesVisible, setAreVehiclesVisible] = useState(false);
  const [wasteRecipientObjects, setWasteRecipientObjects] = useState([]);
  const [wasteRecipientFilters, setWasteRecipientFilters] = useState([]);
  const [selectedArea, setSelectedArea] = useState();
  const [selectedAreaVehicles, setSelectedAreaVehicles] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [layers, setLayers] = useState(TEST_LAYERS);
  const [regions, setRegions] = useState();
  const [outerMap, setOuterMap] = useState();
  const [wasteGeneratorStatuses, setWasteGeneratorStatuses] = useState([]);
  const pushNotification = usePushNotification();

  const layer = useMemo(() => ({
    areas_lo,
    regions_lo: regions,
  }), [regions]);

  useApi({
    setter: setAllVehicles,
    request: () => getVehiclesRequest({ total: 5000 }),
    shouldRequest: true,
  });

  useTrackVehiclesPositions({
    vehicles,
    setVehicles,
    shouldRequest: areVehiclesVisible,
  });

  useApi({
    request: getGeoServerData,
    shouldRequest: true,
    errorCallback: () => pushNotification({ message: 'Ошибка при попытке полчить слои карты' }),
    setter: ({ data }) => {
      const cloneFeatures = data.features?.slice?.();

      if (cloneFeatures) {
        const formattedFeatures = cloneFeatures.map((item) => ({
          ...item,
          coordinates: item.geometry.coordinates[0][0]
            .map((coordinates) => convertCRS(coordinates[0], coordinates[1])),
        }));
        setRegions(formattedFeatures);
      }
    },
  });

  const { rnicInfo } = useRnicInfo({ guid_bnso: vehicleEGTZ?.guid_bnso });

  const onStartSearching = (searchValue = searchVehicle) => {
    const lookingVehicleIndex = vehicles
      .findIndex((item) => item.car_handler.includes(searchValue));

    if (lookingVehicleIndex !== -1) {
      outerMap?.setZoom?.(18);
      const { place } = vehicles[lookingVehicleIndex];
      outerMap?.fitBounds([
        [place?.x, place?.y],
        [place?.x, place?.y],
      ]);
    }
  };

  const { makeRequest: onGetVehicle, isLoading: isVehiclesLoading } = useApi({
    request: () => searchVehicle.length
      && vehicles.length
      && getVehiclesRequest({ search: searchVehicle }),
    setter: (newVehicles) => onStartSearching(newVehicles?.[0]?.guid_bnso),
  });

  useApi({
    setter: setObjectives,
    request: () => {
      const finalParams = wasteFilters.filter(({ value }) => value).map(({ id }) => id).join(',');

      const params = {
        [WASTE_MANAGEMENT_TYPE_IDS]: finalParams,
        total: 100000,
      };
      return getObjectivesRequest({ params });
    },
    shouldRequest: wasteFilters.some(({ value }) => value),
    deps: [wasteFilters],
  });

  useApi({
    setter: setWasteRecipientObjects,
    request: () => {
      const finalParams = wasteRecipientFilters.filter(({ value }) => value).map(({ id }) => id).join(',');

      const params = {
        [WASTE_MANAGEMENT_TYPE_IDS]: finalParams,
        total: 100000,
        has_application: true,
      };
      return getObjectivesRequest({ params });
    },
    shouldRequest: wasteRecipientFilters.some(({ value }) => value),
    deps: [wasteRecipientFilters],
  });

  const { getReferenceTypeIdsByCode: getWasteTypeIdsByCode, referenceTypes } = useGetReferenceItems({ referenceUrl: WASTE_MANAGEMENT_TYPES_ENUM });

  const generatingWasteTypes = getWasteTypeIdsByCode(['generating']);

  const { makeRequest: getGeneratingWasteTypes } = useApi({
    setter: (filters) => {
      const testFilters = filters
        .filter(({ id }) => !generatingWasteTypes.some((type) => compareId(type, id)))
        .map((item) => ({
          ...item,
          value: false,
        }));

      setWasteFilters(testFilters);
    },
    request: () => getReferencesRequest({ url: WASTE_MANAGEMENT_TYPES_URL }),
  });

  useEffect(() => {
    if (referenceTypes.length) getGeneratingWasteTypes();
  }, [referenceTypes]);

  useApi({
    setter: (filters) => {
      const generatingWasteFilters = filters
        .filter(({ id }) => generatingWasteTypes.some((type) => compareId(type, id)))
        .map((item) => ({
          ...item,
          value: false,
        }));

      setWasteRecipientFilters(generatingWasteFilters);
    },
    request: () => getReferencesRequest({ url: WASTE_MANAGEMENT_TYPES_URL }),
    shouldRequest: generatingWasteTypes.length,
  });

  const wasteManagementPoints = useMemo(
    () => objectives
      .filter(({ status }) => {
        if (wasteGeneratorStatuses.length) {
          return wasteGeneratorStatuses.includes(status);
        }
        return true;
      })
      .map(({ address_attributes, ...rest }) => ({
        ...rest,
        id: address_attributes.id,
        ...address_attributes.attributes,
        tooltip: rest.name,
      })),
    [objectives, wasteGeneratorStatuses],
  );

  const wasteRecipientPoints = useMemo(
    () => wasteRecipientObjects.map(({ address_attributes, ...rest }) => ({
      ...rest,
      id: address_attributes.id,
      ...address_attributes.attributes,
      tooltip: rest.name,
    })),
    [wasteRecipientObjects],
  );

  const changeGeneratorInfo = (info) => {
    setWasteGeneratorInfo(info);
  };

  const onChangeSearch = (e, value) => {
    setSearchVehicle(value);
  };

  const onClickTrackedMarker = (data) => {
    setVehicleEGTZ();

    setTimeout(() => setVehicleEGTZ({ ...data, guid_bnso: data?.car_handler }), 100);
  };

  const closeVehicleSlider = () => {
    setVehicleEGTZ();
    setVehicleTracks([]);
  };

  useEffect(() => {
    if (wasteFilters.every(({ value }) => !value) && objectives.length) {
      setObjectives([]);
    }
  }, [wasteFilters, objectives]);

  useEffect(() => {
    if (wasteRecipientFilters.every(({ value }) => !value) && wasteRecipientObjects.length) {
      setWasteRecipientObjects([]);
    }
  }, [wasteRecipientFilters, wasteRecipientObjects]);

  useEffect(() => {
    if (!areVehiclesVisible) {
      setVehicleTracks([]);
      setVehicleEGTZ();
      setVehicles([]);
      setMergedVehicles([]);
    }
  }, [areVehiclesVisible]);

  useEffect(() => {
    if (vehicles.length && allVehicles.length) {
      let newMergedVehicles = vehicles.map((vehicle) => {
        const currentVehicleInfo = allVehicles
          .find(({ guid_bnso }) => compareId(vehicle.car_handler, guid_bnso));

        return { ...vehicle, ...currentVehicleInfo };
      });
      if (selectedAreaVehicles?.length) {
        newMergedVehicles = newMergedVehicles
          .filter(({ car_handler }) => selectedAreaVehicles
            .some(({ guid_bnso }) => compareId(guid_bnso, car_handler)));
      }
      setMergedVehicles(newMergedVehicles);
    }
  }, [allVehicles, vehicles, selectedAreaVehicles]);

  const changeGeneratorStatuses = ({ target: { name } }, value) => {
    if (!value) setWasteGeneratorStatuses((prevState) => prevState.filter((item) => item !== name));
    else setWasteGeneratorStatuses((prevState) => [...prevState, name]);
  };

  const changeSelectedVehicles = (data) => {
    setSelectedAreaVehicles(data);
  };

  const unselectArea = () => {
    setSelectedAreaVehicles([]);
    setSelectedArea();
    outerMap?.areaSelection?.deactivate();
  };

  const boundsAreaVehicle = ({ place }) => {
    outerMap?.fitBounds([
      [place?.x, place?.y],
      [place?.x, place?.y],
    ]);
  };

  const { isMobileTablet } = useCustomMediaQuery();

  return (
    <Box display="flex" gap={1} flexWrap="wrap" justifyContent="space-between" position="relative">
      <LeafletMap
        hasLegend={false}
        regions={regions}
        getTrackCircleChildren={getTrackCircleChildren(vehicleTracks, vehicleEGTZ, rnicInfo)}
        outerMap={outerMap}
        setOuterMap={setOuterMap}
        tracked_routes={vehicleTracks}
        onClickTrackedMarker={onClickTrackedMarker}
        sx={{ flex: '1 1 0 ' }}
        height={isMobileTablet ? '50vh' : 'calc(100vh - 240px)'}
        selectArea={(mapEvent) => setSelectedArea(mapEvent?._latlngs)}
        unselectArea={unselectArea}
        trackedVehicles={areVehiclesVisible && (
          vehicleTracks.length ? mergedVehicles
            .filter(({ car_handler }) => car_handler === vehicleEGTZ?.guid_bnso) : mergedVehicles
        )}
        layers={layers
          .filter(({ value }) => value)
          .map(({ id }) => ({ id, value: layer[id] }))}
        clusters={[
          {
            points: wasteManagementPoints,
            polygonColor: 'red',
            markerIcon: RedHouse,
            clusterColor: 'red',
            onClick: changeGeneratorInfo,
          },
          {
            points: wasteRecipientPoints,
            polygonColor: 'rgb(229, 189, 85)',
            markerIcon: YellowHouse,
            clusterColor: 'rgb(229, 189, 85)',
            onClick: changeGeneratorInfo,
          },
        ]}
      />
      {wasteGeneratorInfo?.id !== undefined && (
        <ObjectInfoSlider
          info={wasteGeneratorInfo}
          onClose={() => changeGeneratorInfo({})}
        />
      )}
      {selectedAreaVehicles.length > 0 && mergedVehicles?.length > 0
        && selectedAreaVehicles.length >= mergedVehicles?.length && (
          <InfoSlider>
            <TextTitle>ТС попавшие в выделенную область</TextTitle>
            <Box overflow="auto" display="flex" flexDirection="column">
              {mergedVehicles.map((item, index) => (
                <Box key={item.guid_bnso} mb={3}>
                  <CustomButton sx={{ maxHeight: '42px', mb: 2 }} onClick={() => boundsAreaVehicle(item)}>
                    Показать ТС
                  </CustomButton>
                  {item.egrz
                    && <TitleSubtitle sx={selectedAreaTitleSx} title="ЕГРЗ ТС (Номер ТС)" subTitle={item.egrz} />}
                  <TitleSubtitle sx={selectedAreaTitleSx} title="Номер БНСО" subTitle={item.car_handler} />
                  <TitleSubtitle
                    sx={selectedAreaTitleSx}
                    title="Время входа в область"
                    subTitle={getDayMonthNameYearTime(selectedAreaVehicles[index].time_in)}
                  />
                  <TitleSubtitle
                    sx={selectedAreaTitleSx}
                    title="Время выхода из области"
                    subTitle={getDayMonthNameYearTime(selectedAreaVehicles[index].time_out)}
                  />
                </Box>
              ))}
            </Box>
          </InfoSlider>
      )}
      {Boolean(vehicleEGTZ) && (
        <VehicleInfoSlider
          setVehicleTracks={setVehicleTracks}
          vehicleRef={outerMap}
          vehicleEGTZ={{ ...vehicleEGTZ, modelRCO: vehicleEGTZ?.model, ...rnicInfo }}
          onClose={closeVehicleSlider}
        />
      )}
      <WasteMapFilters
        clearSelectedArea={unselectArea}
        selectedArea={selectedArea}
        setSelectedAreaVehicles={changeSelectedVehicles}
        searchVehicle={searchVehicle}
        setWasteGeneratorStatuses={changeGeneratorStatuses}
        wasteGeneratorStatuses={wasteGeneratorStatuses}
        setSearchVehicle={onChangeSearch}
        onStartSearching={!isVehiclesLoading && searchVehicle.length && onGetVehicle}
        areVehiclesVisible={areVehiclesVisible}
        setAreVehiclesVisible={setAreVehiclesVisible}
        wasteRecipientFilters={wasteRecipientFilters}
        setWasteRecipientFilters={setWasteRecipientFilters}
        layers={layers}
        setLayers={setLayers}
        wasteFilters={wasteFilters}
        setWasteFilters={setWasteFilters}
      />
    </Box>
  );
}
