import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../../Text';
import DeleteButton from '../../../../Buttons/DeleteButton';
import AddButton from '../../../../Buttons/AddButton';
import { getInitialObjectRight, OBJECT_RIGHT_TYPES_NAME } from '../../../../../constants/lkCadastr/cadastrForm8';
import CustomInput from '../../../../Inputs';
import CustomDatePicker from '../../../../CustomDatePicker';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../../../constants/lkCadastr/cadastrStorage';
import { getObjectiveRightTypeEnumsRequest } from '../../../../../api/cadastreApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../../Inputs/CustomAutocomplete';
import { AsyncAutocomplete } from '../../../../AutoCompletes/AsyncAutocomplete';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import NavTabs from '../../../../Tabs';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';
import { compareId } from '../../../../../helpers';

export function ObjectRights({
  placingObject, changeObject, isDemo, openDeleteModal,
}) {
  const objectRights = placingObject[OBJECT_RIGHT_TYPES_NAME];

  const { tab, setTab, tabs } = useCadastreTabs({ items: objectRights, tabName: 'Право на объект' });

  return (
    <Box sx={flexGap2}>
      <Box sx={flexRowGap2}>
        <TextTitle h3>
          Вид права на объект
        </TextTitle>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeObject(
              OBJECT_RIGHT_TYPES_NAME,
              [...objectRights, getInitialObjectRight()],
            )}
          >
            Добавить право на объект
          </AddButton>
        )}
      </Box>
      <NavTabs tab={tab} setTab={setTab} tabsComponents={tabs} />
      {objectRights.map((objectRight, objectRightIndex) => {
        if (tab !== objectRight.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${OBJECT_RIGHT_TYPES_NAME}.${objectRightIndex}.${field}`, value, undefined, { objectRightTypeIndex: objectRightIndex });
        };

        const onDelete = async () => {
          const deletedObjectRightType = await deleteForm8Entity({
            id: objectRight.id,
            apiType: OBJECT_RIGHT_TYPES_NAME,
          });

          if (!deletedObjectRightType) return;

          const cloneArray = objectRights.slice();
          cloneArray.splice(objectRightIndex, 1);

          changeObject(
            OBJECT_RIGHT_TYPES_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box key={objectRight.randId} sx={flexGap2}>
            {!isDemo && objectRights.length > 1 && objectRight?.id && (
              <DeleteButton onClick={() => openDeleteModal(onDelete, objectRightIndex, 'Право на объект')}>
                Удалить право на объект
              </DeleteButton>
            )}
            <Box sx={flexGap2}>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!compareId(objectRight.right_type?.label, 0)}
                debounceTime={0}
                disabled={isDemo}
                sx={fullWidth}
                request={(params) => getObjectiveRightTypeEnumsRequest('right_type', params)}
                optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                onChange={(e, value) => onChange('right_type', value)}
                label="Вид права"
                value={objectRight.right_type}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Наименование документа"
                value={objectRight.document_number}
                onChange={(e, value) => onChange('document_number', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Наименование органа, выдавшего документ"
                value={objectRight.body_name}
                onChange={(e, value) => onChange('body_name', value)}
              />
              <CustomDatePicker
                canBeZero
                validImmediately
                disabled={isDemo}
                label="Дата выдачи"
                sx={fitContent}
                value={objectRight.issue_date}
                onChange={(e, value) => onChange('issue_date', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Номер"
                sx={fitContent}
                value={objectRight.kind_number}
                onChange={(e, value) => onChange('kind_number', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
