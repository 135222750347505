export const isWebView = ['web-view-user-agent'].includes(window.navigator.userAgent);

export const getExtensionWebView = (string) => {
  if (string.includes('pdf')) return 'pdf';
  if (string.includes('docx')) return 'docx';
  if (string.includes('doc')) return 'doc';
  if (string.includes('jpg')) return 'jpg';
  if (string.includes('png')) return 'png';
  if (string.includes('xlsx')) return 'xlsx';

  return 'text';
};
