import React from 'react';
import { TextTitle } from '../../Text';

export default function ModalColumnTitle({ children }) {
  return (
    <TextTitle h3 sx={{ mb: 2 }}>
      {children}
    </TextTitle>
  );
}
