export const USER_ENTITY_TYPE = {
  legal_entity: {
    name: 'legal_entity',
    label: 'ЮЛ',
    id: 2,
  },
  individual_entrepreneur: {
    name: 'individual_entrepreneur',
    label: 'ИП',
    id: 3,
  },
  self_employed: {
    name: 'self_employed',
    label: 'Самозанятый',
    id: 4,
  },
  individual: {
    name: 'individual',
    label: 'Физ лицо',
    id: 5,
  },
};

export const SUBJECT_KINDS = [
  { label: 'ЮЛ', value: 'legal_entity', id: 2 },
  { label: 'ИП', value: 'individual_entrepreneur', id: 3 },
  { label: 'Самозанятый', value: 'self_employed', id: 4 },
  { label: 'Физ лицо', value: 'individual', id: 5 },
];

const getTypeByValue = (compareValue, options) => options
  .find(({ value }) => value === compareValue) || { value: '', label: '', id: '' };

export const ENTERPRISE_TYPES = [
  { label: 'Малое предприятие', value: 'small', id: 0 },
  { label: 'Среднее предприятие', value: 'middle', id: 1 },
  { label: 'Большое предприятие', value: 'big', id: 2 },
];

export const ACTIVITY_KINDS_NAMES = {
  transporting: 'transporting',
  generating: 'generating',
  reloading: 'reloading',
  pending: 'pending',
  processing: 'processing',
  neutralization: 'neutralization',
  utilization: 'utilization',
  placing: 'placing',
  management: 'management',
  collection: 'collection',
};

export const ACTIVITY_KINDS = [
  { label: 'Транспортирование отходов', value: 'transporting', id: 0 },
  { label: 'Образование', value: 'generating', id: 1 },
  { label: 'Перегрузка', value: 'reloading', id: 2 },
  { label: 'Обработка', value: 'pending', id: 3 },
  { label: 'Обезвреживание', value: 'neutralization', id: 4 },
  { label: 'Утилизация', value: 'utilization', id: 5 },
  { label: 'Размещение', value: 'placing', id: 6 },
  { label: 'Управление', value: 'management', id: 7 },
];

export const getSubjectTypeByValue = (kind, options) => getTypeByValue(
  kind,
  options || SUBJECT_KINDS,
);

export const getActivityTypeByValue = (activity_kind) => getTypeByValue(
  activity_kind,
  ACTIVITY_KINDS,
);

export const getEnterpriseTypeByValue = (enterprise_type) => getTypeByValue(
  enterprise_type,
  ENTERPRISE_TYPES,
);
