// @ts-nocheck
import React, { useMemo, useState } from 'react';
import {
  Box, useMediaQuery, useTheme,
} from '@mui/material';
import { formatDate } from '../../helpers/dateHelper';
import { REPORT_STATUS_NAMES } from '../../constants/lkCadastr/cadastrStorage';
import useGetMember from '../../hooks/useGetMember';
import CadastrAdminTable from '../../components/CadastrAdmin/CadastrAdminTable';
import CustomCheckbox from '../../components/CustomCheckbox';
import CustomDatePicker from '../../components/CustomDatePicker';
import Selector from '../../components/Selectors/Selector';
import { useApi } from '../../hooks';
import { cadastreReportHistoryRequest, getCadastreReportStatusesRequest } from '../../api/cadastreApi';
import { METHODS_API } from '../../api/api';
import { CellStatus } from '../../components/Cadastr/CellStatus';
import { valueToString } from '../../helpers/stringHelper';
import { useTableStore, useUserStore } from '../../store';
import { USER_TEST_ADMIN } from '../../constants/testAdminContants';

const initialReportFilter = {
  search: '',
  report_status_id: '',
  send_date_from: null,
  send_date_to: null,
  confirmed_date_from: null,
  confirmed_date_to: null,
  date_from: null,
  date_to: null,
  delay_date_from: null,
  delay_date_to: null,
};

export default function CadastrAdminReportsPage() {
  const theme = useTheme();
  const [reports, setReports] = useState([]);
  const { userStore } = useUserStore();
  const [reportFilter, setReportFilter] = useState(initialReportFilter);
  const [reportStatuses, setReportStatuses] = useState('');
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));
  const { tableStore, setTableStore } = useTableStore();

  useGetMember();

  const isAdminEditor = userStore?.email === USER_TEST_ADMIN;

  const { makeRequest: getReportHistories, isLoading } = useApi({
    setter: setReports,
    request: (outerParams) => {
      const params = {
        ...reportFilter,
        report_status_id: reportFilter?.report_status_id?.id,
        ...outerParams?.params,
        page: tableStore.page,
        total: tableStore.perPage,
      };

      if (!isAdminEditor) {
        params.filling_percentage = 100;
        params.exclude_status_codes = REPORT_STATUS_NAMES.formation;
      }

      return cadastreReportHistoryRequest({
        setTableStore,
        params,
      });
    },
    cancelPrevious: true,
    deps: [tableStore.page, tableStore.perPage],
    shouldRequest: userStore?.email,
  });

  const { defaultRequest: changeReportHistory } = useApi({
    request: cadastreReportHistoryRequest,
  });

  const changeStatus = (index) => async (e, report_status_id) => {
    e.stopPropagation();

    const newReport = await changeReportHistory({
      method: METHODS_API.PUT,
      id: reports[index].id,
      data: { report_status_id: report_status_id.id },
    });

    const newReports = reports.slice();
    newReports.splice(index, 1, newReport);
    setReports(newReports);
  };

  const changeDelayStatus = async function (rowIndex, event, value) {
    event.stopPropagation();

    const newReport = await changeReportHistory({
      method: METHODS_API.PUT,
      id: reports[rowIndex].id,
      data: { is_delayed: value },
    });

    const newReports = reports.slice();
    newReports.splice(rowIndex, 1, newReport);
    setReports(newReports);
  };

  const changeDateOfDelay = async function (rowIndex, event, value) {
    if (Number.isNaN(Date.parse(value))) { return; }

    const newReport = await changeReportHistory({
      method: METHODS_API.PUT,
      id: reports[rowIndex].id,
      data: { date_of_delay: value },
    });

    const newReports = reports.slice();
    newReports.splice(rowIndex, 1, newReport);
    setReports(newReports);
  };


  const reportsTableColumns = useMemo(() => [
    {
      headerTitle: 'Субъект',
      field: 'subject',
      getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
    },
    {
      headerTitle: 'Номер отчёта',
      field: 'id',
      getField: ({ id, created_at }) => {
        const year = new Date(created_at)?.getFullYear();
        return `${id}/${year ? year - 1 : 0}`;
      },
    },
    {
      headerTitle: 'Дата подачи',
      field: 'application_date',
      getField: ({ application_date }) => formatDate({ date: application_date }),
    },
    {
      headerTitle: 'Дата подтверждения',
      field: 'approved_date',
      getField: ({ approved_date }) => formatDate({ date: approved_date }),
    },
    {
      headerTitle: 'Просрочка отправки',
      field: 'is_delayed',
      // eslint-disable-next-line react/no-unstable-nested-components
      getComponent: ({ is_delayed, date_of_delay, rowIndex }) => (
        <Box>
          <CustomCheckbox
            label="Да"
            value={is_delayed}
            onChange={(e, value) => changeDelayStatus(rowIndex, e, value)}
          />
          {is_delayed ? (
            <CustomDatePicker
              label=""
              value={date_of_delay}
              onChange={(e, value) => changeDateOfDelay(rowIndex, e, value)}
            />
          ) : null}
        </Box>
      ),
    },
    {
      headerTitle: 'Статус',
      field: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      getComponent: ({ report_status_attributes, rowIndex }) => (
        <Selector
          value={report_status_attributes?.attributes}
          optionField={{ value: 'code', id: 'code', label: 'name' }}
          onChange={changeStatus(rowIndex)}
          options={reportStatuses}
        />
      ),
    },
    {
      headerTitle: 'Заполнено',
      field: 'complete',
      // eslint-disable-next-line react/no-unstable-nested-components
      getField: ({ filling_percentage }) => <CellStatus complete={filling_percentage} />,
    },
  ], [reports, reportStatuses]);

  useApi({
    request: getCadastreReportStatusesRequest,
    setter: (data) => {
      setReportStatuses(isAdminEditor ? data
        : data.filter((status) => status.code !== REPORT_STATUS_NAMES.formation));
    },
    shouldRequest: userStore?.email,
  });

  const changeFilter = (filterName) => (value, noSearch) => {
    const newReportFilter = { ...reportFilter, [filterName]: value };
    setReportFilter(newReportFilter);

    const params = {};

    if (newReportFilter.search) params.search = newReportFilter.search;
    if (newReportFilter.date_from) params.date_from = valueToString(newReportFilter.date_from);
    if (newReportFilter.date_to) params.date_to = valueToString(newReportFilter.date_to);
    if (newReportFilter.report_status_id?.id) params.report_status_id = newReportFilter.report_status_id?.id;

    if (!noSearch) getReportHistories({ params });
  };

  const clearReportFilter = () => {
    setReportFilter(initialReportFilter);
    getReportHistories();
  };

  return (
    <Box pt={1} display="flex" justifyContent="space-between" flexWrap={tabletSizeAndLower && 'wrap'}>
      <CadastrAdminTable
        title="Отчёты"
        isLoading={isLoading}
        setReports={setReports}
        getReports={getReportHistories}
        clearFilter={clearReportFilter}
        reportStatuses={reportStatuses}
        filter={reportFilter}
        changeFilter={changeFilter}
        tableData={reports}
        tableColumns={reportsTableColumns}
      />
    </Box>
  );
}
