import React from 'react';
import { Box } from '@mui/material';
import CopyClipboard from './CopyClipboard';

export default function QrCode({
  url, clipboardUrl, sx, hasPublicLink,
}) {
  return (
    <Box display="flex" flexDirection="column" width="fit-content" sx={sx}>
      <Box
        component="img"
        width={280}
        height={280}
        mb={1}
        src={url}
        alt=""
      />
      {hasPublicLink && <CopyClipboard text={`${window.location.origin}${clipboardUrl}`} />}
    </Box>
  );
}
