import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { EditSharp } from '@mui/icons-material';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import FAQAccordion from '../../components/FAQAccordion';
import CustomButton from '../../components/Buttons/CustomButton';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import CustomInput from '../../components/Inputs';
import { useApi, usePushNotification } from '../../hooks';
import { formValidator } from '../../helpers';
import { cadastreQuestionAnswersRequest } from '../../api/cadastreApi';
import { METHODS_API } from '../../api/api';
import useDeleteModal from '../../hooks/useDeleteModal';

const initialQuestion = {
  answer: '',
  question: '',
};

export default function CadastrAdminFAQ() {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [form, setForm] = useState(initialQuestion);
  const [error, setError] = useState({});
  const pushNotification = usePushNotification();

  const { defaultRequest: questionApi } = useApi({
    request: cadastreQuestionAnswersRequest,
  });

  const getQuestions = () => questionApi().then((resQuestions) => {
    setQuestions(resQuestions);
  });

  useEffect(() => {
    getQuestions();
  }, []);

  const openModal = (item) => {
    setSelectedQuestion(item);
    setForm(item);
  };

  const closeModal = () => {
    setForm(initialQuestion);
    setSelectedQuestion(null);
  };

  const change = (field) => (e, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const sendQuestion = async () => {
    const { hasErrors, validField } = formValidator({
      form: {
        answer: form.answer,
        question: form.question,
      },
    });

    setError(validField);

    if (hasErrors) return;

    const updatedQuestion = await questionApi({
      method: selectedQuestion.id ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        answer: form.answer,
        question: form.question,
      },
      id: selectedQuestion.id,
    });

    if (updatedQuestion?.id) {
      getQuestions();
      pushNotification({ message: 'Данные вопроса обновлены', variant: 'success' });
      closeModal();
    } else {
      pushNotification({ message: 'Данные вопроса не удалось обновить' });
    }
  };

  const { makeRequest, isLoading } = useApi({
    request: sendQuestion,
  });

  const getItem = useCallback((item) => (
    <CustomButton onClick={() => openModal(item)} startIcon={<EditSharp />} sx={{ width: 'fit-content', mt: 3 }}>
      Добавить/Изменить отвёт
    </CustomButton>
  ), []);

  const openDeleteModal = useDeleteModal();

  const deleteQuestion = async (currentQuestion) => {
    const deletedFeedback = await questionApi({ id: currentQuestion.id, method: METHODS_API.DELETE });

    if (deletedFeedback?.id) {
      getQuestions();
      pushNotification({ message: 'Вопрос успешно удалён', variant: 'success' });
      closeModal();
    } else {
      pushNotification({ message: 'Вопрос не удалось удалить' });
    }
  };

  const onDeleteQuestion = (currentQuestion) => {
    openDeleteModal({
      objectName: `вопрос (${currentQuestion.question})`,
      deleteItem: () => deleteQuestion(currentQuestion),
      id: currentQuestion.id,
    });
  };

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <ModalWithTitle
        open={Boolean(selectedQuestion)}
        isLoading={isLoading}
        close={closeModal}
        title={selectedQuestion?.id ? selectedQuestion.question : 'Создать вопрос'}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <CustomInput
            label="Вопрос"
            multiline
            value={form.question}
            onChange={change('question')}
            error={error.answer}
          />
          <CustomInput
            label="Ответ"
            multiline
            value={form.answer}
            onChange={change('answer')}
            error={error.answer}
          />
          <CustomButton onClick={makeRequest}>Сохранить ответ</CustomButton>
        </Box>
      </ModalWithTitle>
      <CustomButton sx={{ width: 'fit-content' }} onClick={() => setSelectedQuestion({})}>Создать вопрос</CustomButton>
      <FAQAccordion faqs={questions} getItem={getItem} deleteItem={onDeleteQuestion} />
    </Box>
  );
}
