import { Box } from '@mui/material';
import React from 'react';
import { flexGap2, flexRowGap2, fullWidth } from '../../constants/lkCadastr/cadastrStorage';
import { compareId } from '../../helpers';
import CustomCheckbox from '../CustomCheckbox';
import CustomDatePicker from '../CustomDatePicker';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import LicensesWorkTypes from './LicenseWorkTypes';

export default function CadastreForm7License({
  license, changeObject, isDemo, form5Licenses, isHideForm5, is_license_unneeded, changeLicenseUnneeded,
}) {
  const changeLicense = (value) => changeObject('license', value);

  const mainLicense = form5Licenses.find((item) => compareId(item.id, license?.id));

  const hasZeroLicense = compareId(license?.license_number, 0);

  return (
    <Box sx={flexGap2}>
      {(isHideForm5 || is_license_unneeded) && (
        <CustomCheckbox
          disabled={isDemo || (is_license_unneeded && !isHideForm5)}
          label="Лицензия не требуется"
          value={is_license_unneeded}
          onChange={changeLicenseUnneeded}
        />
      )}
      {
        (!(is_license_unneeded && !isHideForm5) && ((isHideForm5 && !is_license_unneeded) || !isHideForm5) && form5Licenses?.length > 0)
        && (
          <>
            <Box sx={flexRowGap2}>
              <CustomAutocomplete
                canBeZero
                validImmediately={!hasZeroLicense}
                disabled={isDemo}
                label="Лицензия"
                sx={fullWidth}
                containerSx={fullWidth}
                value={mainLicense || (hasZeroLicense && license)}
                fields={{ label: 'license_number' }}
                onChange={(e, value) => changeLicense(value)}
                options={form5Licenses}
              />
              <CustomDatePicker
                disabled
                label="Дата"
                value={mainLicense?.license_issue_date || null}
              />
              <Box display="flex" alignItems="center" gap={3}>
                <CustomDatePicker
                  disabled
                  label="Срок действия лицензии"
                  value={mainLicense?.is_forever ? null : mainLicense?.license_expiration || null}
                />
                <CustomCheckbox
                  disabled
                  value={mainLicense?.is_forever}
                  label="Бессрочно"
                />
              </Box>
            </Box>
            <LicensesWorkTypes mainLicense={mainLicense} />
          </>
        )
      }
    </Box>
  );
}
