import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import CarParkTable from './CarParkTable';
import PermitTable from './PermitTable';
import TitleSubtitle from '../../TitleSubtitle';
import { TextTitle } from '../../Text';
import ViolationTable from '../../Tables/ViolationTable';
import { useTableStore } from '../../../store';
import { SUCCESS_TEXT } from '../../../constants/colors';
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery';

export default function BlockModalWasteCarrier({
  open, fields, subject, violations, setViolations,
}) {
  const { tableStore: { searchText } } = useTableStore();

  const { isMobileTablet } = useCustomMediaQuery();

  const fullWidth = isMobileTablet ? { width: '100%' } : {};

  return (
    <Box sx={{ ...fullWidth }}>
      <Box
        mb={4}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={4}
      >
        <Box>
          <Typography mb={2} fontWeight="bold" fontSize="18px">
            Информация о субъекте
          </Typography>
          {violations && (
            <Box mb={2}>
              {
                violations.length === 0 ? (
                  <Alert>
                    <Typography sx={{ color: SUCCESS_TEXT }}>Нарушений не обнаружено</Typography>
                  </Alert>
                ) : (
                  <Alert severity="error">
                    <Typography>Обнаружены нарушения</Typography>
                  </Alert>
                )
              }
            </Box>
          )}
          {fields.map(({ title, field, getField }) => (
            <Box mb={1} key={title} maxWidth={320}>
              <TitleSubtitle
                title={title}
                subTitle={getField ? getField(subject) : subject[field]}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ ...fullWidth }}>
          <Typography pb={2} fontWeight="bold" fontSize="18px">Зарегистрированные ТС</Typography>
          <CarParkTable searchText={searchText} open={open} cellData={subject} />
        </Box>
        <Box sx={{ ...fullWidth }}>
          <Typography pb={2} fontWeight="bold" fontSize="18px">Разрешения</Typography>
          <PermitTable searchText={searchText} open={open} cellData={subject} />
        </Box>
      </Box>
      <TextTitle h3>Нарушения</TextTitle>
      {subject.id && (
        <ViolationTable
          setViolations={setViolations}
          searchText={searchText}
          open={open}
          subject_id={subject.id}
        />
      )}
    </Box>
  );
}
