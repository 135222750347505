// @ts-nocheck
import React, { useState } from 'react';
import { Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_TYPE_ENUM,
} from '../../constants/enums';
import { exportSubjectRequest, getSubjectEnumsRequest } from '../../api/subjectApi';
import ModalWithTitle from './ModalWithTitle';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../Inputs/CustomAutocomplete';
import { formatDate } from '../../helpers/dateHelper';
import { useDownloadFile } from '../../hooks/useDownloadFile';
import { useApi } from '../../hooks';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import CustomButton from '../Buttons/CustomButton';
import { AddressAutocomplete } from '../AutoCompletes';
import { getReferencesRequest } from '../../api/referenceApi';
import { ENTERPRISE_ACTIVITY_TYPES_URL } from '../../constants/urls';

const initialForm = {
  [ENTERPRISE_TYPE_ENUM]: DEFAULT_AUTOCOMPLETE_OPTION,
  [ENTERPRISE_ACTIVITY_TYPE_IDS]: [],
  district: DEFAULT_AUTOCOMPLETE_OPTION,
};

export default function ModalExportSubject({ close }) {
  const [form, setForm] = useState(initialForm);

  const { district, enterprise_type } = form;

  const onChange = ({ target: { name } }, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { makeRequest: makeFileLink, isLoading: isDownloadingFile } = useDownloadFile({
    fileName: `Экспорт субъектов за ${formatDate({ date: new Date() })}`,
  });

  const downloadReport = async () => {
    const enterpriseIds = form[ENTERPRISE_ACTIVITY_TYPE_IDS].map(({ id }) => id);

    const response = await exportSubjectRequest({
      district: form.district.value || undefined,
      [ENTERPRISE_ACTIVITY_TYPE_IDS]: enterpriseIds.length ? enterpriseIds : undefined,
      [ENTERPRISE_TYPE_ENUM]: enterprise_type.value || undefined,
    });

    makeFileLink({ url: response?.file_url });
  };

  const { makeRequest, isLoading: loadingDownloadReport } = useApi({
    request: downloadReport,
  });

  const isLoading = isDownloadingFile || loadingDownloadReport;

  return (
    <ModalWithTitle
      containerSx={{ width: 'fit-content' }}
      close={close}
      title="Экспорт субьектов"
      open
      isLoading={isLoading}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <AsyncAutocomplete
          multiple
          value={form[ENTERPRISE_ACTIVITY_TYPE_IDS]}
          onChange={onChange}
          request={(params) => getReferencesRequest({ url: ENTERPRISE_ACTIVITY_TYPES_URL, params })}
          label="Вид деятельности"
          name={ENTERPRISE_ACTIVITY_TYPE_IDS}
        />
        <AsyncAutocomplete
          value={enterprise_type}
          onChange={onChange}
          request={(params) => getSubjectEnumsRequest({ enum_field: ENTERPRISE_TYPE_ENUM, params })}
          label="Вид предприятия"
          name={ENTERPRISE_TYPE_ENUM}
          optionField={DEFAULT_AUTOCOMPLETE_FIELD}
        />
        <AddressAutocomplete
          hasExample={false}
          value={district}
          name="district"
          filter={(options) => {
            const uniqDistricts = new Map(
              options.map((object) => [object.district, object.district]),
            );
            return Array.from(uniqDistricts).map((districtArray) => ({
              label: districtArray[0],
              value: districtArray[0],
            }));
          }}
          sx={{ width: 300 }}
          onChange={onChange}
          label="Район"
        />
        <CustomButton
          endIcon={<FileDownloadIcon />}
          onClick={makeRequest}
          sx={{ height: '50px', width: '200px' }}
        >
          Экспортировать
        </CustomButton>
      </Box>
    </ModalWithTitle>
  );
}
