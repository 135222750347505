import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomTable from './CustomTable';
import { useApi } from '../../hooks';
import SearchInput from '../Inputs/SearchInput';
import { getApplicationsRequest } from '../../api/applicationsApi';
import { formatDate } from '../../helpers/dateHelper';
import { getVehicleItemsRequest } from '../../api/vehiclesApi';
import { APPLICATIONS_URL } from '../../constants/urls';
import { ModalApplicationInfo } from '../Modals/ModalApplication/ModalApplication';

const applicationTableColumns = [
  {
    headerTitle: 'Дата выдачи разрешений',
    field: 'created_at',
    getField: (row) => formatDate({ date: row.created_at }),
  },
  {
    headerTitle: 'ЕГРЗ ТС (Номер ТС)',
    field: 'egrz',
    getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz,
  },
  {
    headerTitle: 'Субъект, транспортирующий отходы',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Субъект (образователь отходов)',
    field: 'gerenator_name',
    getField: ({
      application_objectives_attributes,
    }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Объект (образователь отходов)',
    field: 'waste_generator_objective_id',
    getField: ({ application_objectives_attributes }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Субъект (обращения с отходами)',
    field: 'recipient_subject_name',
    getField: ({ objective_attributes }) => objective_attributes
      ?.attributes?.subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Объект (обращение с отходами)',
    field: 'recipient_object_name',
    getField: ({
      application_objectives_attributes,
    }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.name,
  },
];

const tableContainerSx = { width: '100%' };

export default function PermissionTable({ subject_id, vehicle_id }) {
  const [editRow, setEditRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchText, setSearchText] = useState('');

  const changeSearchText = (e, value) => setSearchText(value);

  const { makeRequest, isLoading } = useApi({
    request: async () => {
      const params = {
        page,
        total: perPage,
        search: searchText,
        subject_id,
      };

      if (vehicle_id) {
        return getVehicleItemsRequest(
          {
            url: APPLICATIONS_URL,
            id: vehicle_id,
            params,
          },
          ({ meta: { total: totalPageCount } }) => setTotal(totalPageCount),
        );
      }
      return getApplicationsRequest(
        params,
        ({ meta: { total: totalPageCount } }) => setTotal(totalPageCount),
      );
    },
    shouldRequest: true,
    setter: setTableData,
    deps: [page, perPage],
  });

  const closeModal = (shouldRequest = false) => {
    if (shouldRequest) makeRequest();

    setEditRow(null);
  };

  return (
    <Box position="relative" flexBasis="40%">
      {Boolean(editRow) && (
        <ModalApplicationInfo id={editRow.id} close={closeModal} />
      )}
      <SearchInput
        sx={{ width: 320, my: 1 }}
        label="Поиск разрешения"
        onKeyEnter={makeRequest}
        value={searchText}
        onChange={changeSearchText}
      />
      <CustomTable
        containerSx={tableContainerSx}
        perPage={perPage}
        total={total}
        page={page}
        onClick={(row) => setEditRow(row)}
        setPage={(e, value) => setPage(value)}
        setPerPage={(e, value) => setPerPage(value)}
        data={tableData}
        isLoading={isLoading}
        columns={applicationTableColumns}
      />
    </Box>
  );
}
