import { Box } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '../../assets/logout_icon.svg';
import { COMMITTEE_PROFILE } from '../../constants/routes';
import { getFullName } from '../../helpers/stringHelper';
import { useChangeSideDrawer } from '../../hooks/useChangeSideDrawer';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { usePrivilegeRole } from '../../hooks/usePrivilegeRole';
import { useLogoutStore, useUserStore } from '../../store';
import CustomButton from '../Buttons/CustomButton';
import { TextMain } from '../Text';

export default function HeaderUser() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userStore } = useUserStore();
  const logout = useLogoutStore();
  const { toggleDrawer } = useChangeSideDrawer();

  const isAdminRoute = pathname.includes('/admin');

  const hasPrivilege = usePrivilegeRole();

  const { isMobileTablet } = useCustomMediaQuery();

  const openCommitteeProfile = () => {
    navigate(COMMITTEE_PROFILE);
    toggleDrawer();
  };

  return (
    <Box
      display="flex"
      flexDirection={isAdminRoute ? 'column' : 'row'}
      alignItems="center"
      p={1}
      gap={1}
    >
      <TextMain>
        {getFullName(userStore) || userStore.name}
      </TextMain>
      {hasPrivilege('lk_kgen') && isMobileTablet && <CustomButton color="text" onClick={openCommitteeProfile}>Профиль</CustomButton>}
      <CustomButton sx={{ height: '30px', '& p': { fontSize: '12px' } }} endIcon={<LogoutIcon />} onClick={logout}>
        Выход
      </CustomButton>
    </Box>
  );
}
