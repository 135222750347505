import React from 'react';
import ReferenceGenerator from '../../../components/ReferenceGenerator';
import { WASTE_TYPES_URL } from '../../../constants/urls';
import { HAZARD_CLASS_ENUM, WASTE_TYPES_ENUM } from '../../../constants/enums';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../../../components/Inputs/CustomAutocomplete';
import { getApplicationEnumsRequest } from '../../../api/applicationsApi';

export default function FKKOReferencePage() {
  return (
    <ReferenceGenerator
      url={WASTE_TYPES_URL}
      importKind={WASTE_TYPES_ENUM}
      ignoreInputs={['hazard_class']}
      additionalInputs={[
        {
          id: 'hazard_class',
          label: 'Класс опасности',
          type: 'autocomplete',
          fields: DEFAULT_AUTOCOMPLETE_FIELD,
          request: (params) => getApplicationEnumsRequest(HAZARD_CLASS_ENUM, params),
        },
      ]}
      additionalColumns={[{
        headerTitle: 'Класс опасности',
        field: 'hazard_class_text',
      }]}
      additionalInfo={{ hazard_class: { ...DEFAULT_AUTOCOMPLETE_OPTION } }}
    />
  );
}
