import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useApi, usePushNotification } from '../hooks';
import CustomDatePicker from './CustomDatePicker';
import { formValidator } from '../helpers';
import CustomButton from './Buttons/CustomButton';
import { getVehiclesByPolygonInOutRequest, getVehiclesByPolygonRequest } from '../api/vehiclesApi';
import { formatToServerDate } from '../helpers/dateHelper';

export default function SelectAreaControl({
  setSelectedAreaVehicles, selectedArea, areVehiclesVisible, clearSelectedArea,
}) {
  const pushNotification = usePushNotification();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [error, setError] = useState({});

  const { makeRequest: getAreaVehicles, isLoading: isDeviationsLoading } = useApi(({
    request: async () => {
      const [polygonVehicles, polygonInOut] = await Promise.all([
        getVehiclesByPolygonRequest({
          polygon: selectedArea[0].map(({ lat, lng }) => [lat, lng]),
          date_from: formatToServerDate(fromDate),
          date_to: formatToServerDate(toDate),
        }),
        getVehiclesByPolygonInOutRequest({
          polygon: selectedArea[0].map(({ lat, lng }) => [lat, lng]),
          date_from: formatToServerDate(fromDate),
          date_to: formatToServerDate(toDate),
        }),
      ]);

      return { polygonVehicles: polygonVehicles?.data, polygonInOut: polygonInOut?.data };
    },
    setter: ({ polygonVehicles, polygonInOut }) => {
      if (polygonVehicles?.length) {
        const formattedVehicleInfo = polygonVehicles.map((guid_bnso) => {
          const vehicleInfo = { guid_bnso };

          if (polygonInOut?.length) {
            // eslint-disable-next-line prefer-destructuring
            vehicleInfo.time_in = polygonInOut[0];
            // eslint-disable-next-line prefer-destructuring
            vehicleInfo.time_out = polygonInOut[1];
          }
          return vehicleInfo;
        });

        setSelectedAreaVehicles(formattedVehicleInfo);
      } else {
        pushNotification({
          message: 'Данные по ТС в указанной области не найдены',
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
    },
  }));

  const onShowDeviations = () => {
    if (!areVehiclesVisible) {
      return pushNotification({ message: 'Необходимо отобразить все ТС' });
    }
    const { hasErrors, validField } = formValidator({
      form: { fromDate, toDate, selectedArea },
    });

    setError(validField);

    if (hasErrors) {
      if (validField.selectedArea) {
        pushNotification({ message: 'Необходимо выделить область на карте' });
      }
      return;
    }

    getAreaVehicles();
  };

  return (
    <Box display="flex" gap={1} flexDirection="column" alignItems="flex-start">
      <Box display="flex" gap={2} flexWrap="wrap">
        <CustomDatePicker
          value={fromDate}
          onChange={(e, value) => {
            setFromDate(value);
            setError((prevState) => ({ ...prevState, fromDate: '' }));
          }}
          error={error.fromDate}
        />
        <CustomDatePicker
          value={toDate}
          error={error.toDate}
          onChange={(e, value) => {
            setToDate(value);
            setError((prevState) => ({ ...prevState, toDate: '' }));
          }}
        />
      </Box>
      <CustomButton
        isLoading={isDeviationsLoading}
        onClick={onShowDeviations}
        variant="outlined"
      >
        Показать ТС в выделенной области
      </CustomButton>
      {selectedArea?.length && (
        <CustomButton color="warning" onClick={clearSelectedArea} variant="text">
          Сбросить выделение обалсти
        </CustomButton>
      )}
    </Box>
  );
}
