import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import style from './slider.module.scss';
import TitleSubtitle from '../../../TitleSubtitle';
import VehicleTrackControl from '../../../VehicleTrackControl';
import CloseModalButton from '../../../Buttons/CloseModalButton';
import { getDayMonthNameYearTime } from '../../../../helpers/dateHelper';

const shouldMemo = (prevProps, nextProps) => {
  if (!prevProps.vehicleEGTZ?.guid_bnso && !nextProps.vehicleEGTZ?.guid_bnso) return true;

  if (prevProps.vehicleEGTZ?.velocity !== nextProps.vehicleEGTZ?.velocity) return false;

  return prevProps.vehicleEGTZ?.guid_bnso === nextProps.vehicleEGTZ?.guid_bnso;
};

function VehicleInfoSlider({
  onClose, vehicleEGTZ, vehicleRef, setVehicleTracks,
}) {
  const [vehiclePosition, setVehiclePosition] = useState();

  useEffect(() => {
    if (vehicleEGTZ?.place?.x) {
      setVehiclePosition(vehicleEGTZ);
    }
  }, [vehicleEGTZ]);

  const boundVehicle = () => {
    if ((vehiclePosition?.place) && vehicleRef?.fitBounds) {
      vehicleRef.fitBounds([
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
        [
          vehiclePosition?.place?.x,
          vehiclePosition?.place?.y,
        ],
      ]);
    }
  };

  return (
    <Box className={style.info_wrapper}>
      <>
        <CloseModalButton sx={{ mb: 2 }} close={onClose} />
        <Box display="flex" flexDirection="column" gap={1}>
          <VehicleTrackControl
            boundVehicle={boundVehicle}
            setter={setVehicleTracks}
            guid_bnso={vehicleEGTZ?.guid_bnso}
            vehiclePosition={vehiclePosition}
          />
          <TitleSubtitle title="База данных ТС" subTitle={vehicleEGTZ?.egrz ? 'РКО' : 'РИНС ЛО'} />
          <TitleSubtitle title="ЕГРЗ ТС (Номер ТС)" subTitle={vehicleEGTZ?.egrz || vehicleEGTZ?.stateNumber} />
          {vehicleEGTZ?.subject_attributes?.attributes?.ownership_type && (
            <TitleSubtitle title="Тип владения" subTitle={vehicleEGTZ?.subject_attributes?.attributes?.ownership_type} />
          )}
          <TitleSubtitle title="Марка" subTitle={vehicleEGTZ?.brand} />
          <TitleSubtitle title="Модель" subTitle={vehicleEGTZ?.modelRCO || vehicleEGTZ?.model} />
          <TitleSubtitle title="Скорость" subTitle={vehicleEGTZ?.velocity?.toString()} />
          <TitleSubtitle title="Номер БНСО" subTitle={vehicleEGTZ?.guid_bnso || vehicleEGTZ?.equipmentUin} />
          <TitleSubtitle title="Наименование перевозчика" subTitle={vehicleEGTZ?.subject_attributes?.attributes?.name || vehicleEGTZ?.organizationName} />
          <TitleSubtitle title="Дата и время получения последней координаты" subTitle={getDayMonthNameYearTime(vehicleEGTZ?.time || vehicleEGTZ?.navigationTime)} />
        </Box>
      </>
    </Box>
  );
}

export default memo(VehicleInfoSlider, shouldMemo);
