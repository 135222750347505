import { useEffect, useState } from 'react';
import { compareId } from '../helpers';

const getItemTabs = (items, tabName) => items.map((item, index) => ({
  label: `${tabName} №${index + 1}`,
  name: item.randId,
}));

export const setTabsAndTab = ({
  setTab, setTabs, tab, filteredTabs,
}) => {
  setTabs((prev) => {
    if (filteredTabs.length === 0) return filteredTabs;
    if (filteredTabs.length === 1) {
      setTab(filteredTabs.at(-1).name);
    } else if (prev.length < filteredTabs.length) {
      setTab(filteredTabs.at(-1).name);
    } else {
      const currentTabIndex = prev.findIndex((item) => item.name === tab);
      const finalTab = prev?.[currentTabIndex - 1]?.name || filteredTabs[0].name;
      setTab(finalTab);
    }
    return filteredTabs;
  });
};

export default function useCadastreTabs({ items, tabName }) {
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState('');

  useEffect(() => {
    const filteredTabs = getItemTabs(items, tabName);

    if (items.length === 0 && tabs.length !== 0) setTabs([]);

    if (
      !filteredTabs.length
      || (tabs.length === items.length && tabs.every((currentTab) => items.some((item) => compareId(item.randId, currentTab.name))))
    ) return;

    setTabsAndTab({
      tab, setTab, setTabs, filteredTabs,
    });
  }, [items, tabs]);

  return { tabs, tab, setTab };
}
