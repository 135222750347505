import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import { CircularProgress, Slide, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import reportWebVitals from './reportWebVitals';
import { Store } from './store';
import { mainTheme } from './styles/theme';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<CircularProgress />}>
      <Store>
        <ThemeProvider theme={mainTheme}>
          <SnackbarProvider
            iconVariant={{ error: '⛔' }}
            TransitionComponent={Slide}
            maxSnack={3}
          >
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </Store>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
