import React from 'react';
import { Box } from '@mui/material';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import AddButton from '../../../Buttons/AddButton';
import { getInitialWasteItem } from '../../../../constants/lkCadastr/cadastrForm7';
import { getWasteTypesRequest } from '../../../../api/wasteTypeApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../Inputs/CustomAutocomplete';
import { AsyncAutocomplete } from '../../../AutoCompletes/AsyncAutocomplete';
import { flexGap2 } from '../../../../constants/lkCadastr/cadastrStorage';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import NavTabs from '../../../Tabs';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';
import { compareId } from '../../../../helpers';

const WASTE_ITEMS_NAME = 'wasteItems';

export function WasteItems({
  wasteItems, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: wasteItems, tabName: 'Отход' });

  return (
    <Box sx={flexGap2}>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeItem(
            WASTE_ITEMS_NAME,
            [...wasteItems, getInitialWasteItem()],
          )}
        >
          Добавить отход
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {wasteItems.map((technologyWaste, technologyWasteIndex) => {
        if (tab !== technologyWaste.randId) return null;

        const change = (field, value) => changeItem(
          `${WASTE_ITEMS_NAME}.${technologyWasteIndex}.${field}`,
          value,
          undefined,
          { technologyWasteIndex },
        );

        const onDelete = async () => {
          if (technologyWaste?.id) {
            const deletedTechnologyWaste = await deleteForm7Entity({ id: technologyWaste.id, apiType: WASTE_ITEMS_NAME });

            if (!deletedTechnologyWaste) return;
          }

          const cloneArray = wasteItems.slice();
          cloneArray.splice(technologyWasteIndex, 1);

          changeItem(
            WASTE_ITEMS_NAME,
            cloneArray,
            'delete',
          );
        };

        const isZeroWaste = compareId(technologyWaste.waste_type_id?.name, 0);

        return (
          <Box key={technologyWaste.randId} sx={flexGap2}>
            {!isDemo && wasteItems.length > 1 && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, technologyWasteIndex, 'Отход')}
              >
                Удалить отход
              </DeleteButton>
            )}
            <AsyncAutocomplete
              canBeZero
              validImmediately={!isZeroWaste}
              debounceTime={0}
              disabled={isDemo}
              sx={{ width: '100%' }}
              request={getWasteTypesRequest}
              value={technologyWaste.waste_type_id}
              optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'name' }}
              label="Наименование отхода"
              onChange={(e, value) => change('waste_type_id', value)}
            />
            <AsyncAutocomplete
              canBeZero
              validImmediately={!isZeroWaste}
              debounceTime={0}
              disabled={isDemo || isZeroWaste}
              sx={{ width: '100%' }}
              request={getWasteTypesRequest}
              value={technologyWaste.waste_type_id}
              optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: 'code' }}
              label="Код отхода по ФККО"
              onChange={(e, value) => change('waste_type_id', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              type="number"
              label="Количество, т/год"
              value={technologyWaste.tons_per_yer}
              onChange={(e, value) => change('tons_per_yer', value)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
