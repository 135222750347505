import React from 'react';
import { Alert, Box } from '@mui/material';
import CustomButton from '../../../components/Buttons/CustomButton';

export default function EmailConfirmationSuccess() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={3}
      height="100vh"
    >
      <Alert>Ваш E-mail подтверждён</Alert>
      <CustomButton href="/">Вернуться на главную</CustomButton>
    </Box>
  );
}
