// @ts-nocheck
import {
  CHECK_BY_INN_URL,
  EMAIL_PASSWORD_URL,
  ENUMS_URL,
  EXPORTS_SUBJECT_URL,
  FORGET_PASSWORD_URL,
  IMPORTS_URL, INFO_BY_INN_URL,
  REGISTRATION_SUBJECT_URL,
  RESET_PASSWORD_URL,
  SIGN_IN_SUBJECT_URL,
  SUBJECTS_URL,
  UPDATE_EMAIL_BY_INN_URL,
} from '../constants/urls';
import api, { METHODS_API } from './api';

export const loginSubjectRequest = (subject) => api({
  method: 'post',
  url: SIGN_IN_SUBJECT_URL,
  data: { subject },
})
  .then(({ data }) => ({ ...data, type: 'subject' }));

export const getSubjectsRequest = ({ params, setTableStore, shouldCache }) => api({
  url: SUBJECTS_URL,
  params,
}, { isEntityAttributes: true, setTableStore, shouldCache });

export const getSubjectIsSMEVRequest = (params) => api({
  url: `${SUBJECTS_URL}${CHECK_BY_INN_URL}`,
  params,
});

export const changeSubjectEmailByInnRequest = (subject) => api({
  url: `${SUBJECTS_URL}${UPDATE_EMAIL_BY_INN_URL}`,
  method: METHODS_API.PUT,
  data: { subject },
});

export const getSubjectSMEVRequest = (params) => api({
  url: `${SUBJECTS_URL}${INFO_BY_INN_URL}`,
  params,
});

export const getSubjectRequest = ({
  id, url = '', params, shouldCache, setTableStore,
}) => api(
  { url: `${SUBJECTS_URL}/${id}${url}`, params },
  { isEntityAttributes: true, setTableStore, shouldCache },
);

export const forgotPasswordSubjectRequest = (email) => api(
  { url: `${SUBJECTS_URL}${FORGET_PASSWORD_URL}`, method: 'POST', data: { subject: email } },
  { isEntityAttributes: true },
);

export const resetPasswordRequest = ({
  url = SUBJECTS_URL,
  reset_password_token,
  password_confirmation,
  password,
  user_type = 'subject',
}) => api(
  {
    url: `${url}${RESET_PASSWORD_URL}`,
    method: 'POST',
    data: { [user_type]: { reset_password_token, password, password_confirmation } },
  },
  { isEntityAttributes: true },
);

export const getSubjectEnumsRequest = ({
  enum_field, params, filter, shouldCache,
}) => api(
  { url: `${SUBJECTS_URL}${ENUMS_URL}`, params },
  { enum_field, filter, shouldCache },
);

export const updateSubjectRequest = (id, data) => api({
  method: 'put',
  url: `${SUBJECTS_URL}/${id}`,
  data: { subject: data },
}, { isEntityAttributes: true });

export const updateSubjectEmailPasswordRequest = (id, data) => api({
  method: 'put',
  url: `${SUBJECTS_URL}/${id}${EMAIL_PASSWORD_URL}`,
  data: { subject: data },
});

export const deleteSubjectRequest = (id) => api({ method: 'delete', url: `${SUBJECTS_URL}/${id}` });

export const createSubjectRequest = (subject) => api({
  method: 'post',
  url: REGISTRATION_SUBJECT_URL,
  data: { subject },
}, { isEntityAttributes: true });

export const importSubjectRequest = (formData) => api({
  method: 'post',
  url: IMPORTS_URL,
  data: formData,
}, {
  isEntityAttributes: true,
  isMultipartForm: true,
});

export const exportSubjectRequest = (filters) => api({
  method: 'post',
  url: EXPORTS_SUBJECT_URL,
  data: { export: { filters } },
}, {
  isEntityAttributes: true,
});
