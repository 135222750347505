import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import React, { useState } from 'react';
import { deleteVehicleRequest, getVehiclesRequest } from '../../api/vehiclesApi';
import CustomCheckbox from '../../components/CustomCheckbox';
import HeaderSortFilter from '../../components/HeaderSortFilter';
import ModalVehicleInfo from '../../components/Modals/Carrier/ModalVehicleInfo';
import ModalAddVehicle from '../../components/Modals/ModalAddVehicle';
import CustomTable from '../../components/Tables/CustomTable';
import { useTableSearch } from '../../hooks';
import useDeleteModal from '../../hooks/useDeleteModal';
import { usePrivilegeRole } from '../../hooks/usePrivilegeRole';
import { useTableStore } from '../../store';

const initialTableVehicle = [
  { headerTitle: 'ID БНСО', field: 'guid_bnso' },
  {
    headerTitle: 'Наименование субъекта',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  { headerTitle: 'ЕГРЗ ТС (Номер ТС)', field: 'egrz' },
  { headerTitle: 'ЕГРЗ прицепа', field: 'egrz_trailer' },
  { headerTitle: 'Марка ТС', field: 'brand' },
  { headerTitle: 'Модель ТС', field: 'model' },
  { headerTitle: 'Тип владения', field: 'ownership_type_text' },
  {
    headerTitle: 'Подключение к РИНС ЛО',
    field: 'is_connected_rins',
    getField: ({ is_connected_rins }) => (is_connected_rins
      ? <DoneIcon color="success" />
      : <CloseIcon color="error" />),
  },
  { headerTitle: 'Нарушения', field: 'violations_count' },
];

export default function RegistryTS() {
  const { setTableStore } = useTableStore();
  const [tableData, setTableData] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [notConnectedRINS, setNotConnectedRINS] = useState(false);
  const [withDeleted, setWithDeleted] = useState(false);

  const hasPrivilege = usePrivilegeRole();

  const changeConnectedRINS = () => {
    setTableStore({
      page: 1,
    });
    setNotConnectedRINS(!notConnectedRINS);
  };

  const changeWithDeleted = () => {
    setTableStore({
      page: 1,
    });
    setWithDeleted(!withDeleted);
  };

  const openVehicleModal = (row) => setVehicle(row);

  const closeVehicleModal = () => setVehicle({});

  const closeEditModal = () => setIsOpenEditModal(false);

  const { isLoading, makeRequest } = useTableSearch({
    search: getVehiclesRequest,
    setter: setTableData,
    deps: [notConnectedRINS, withDeleted],
    params: {
      is_connected_rins: notConnectedRINS ? false : undefined,
      is_inactive: withDeleted,
    },
  });

  const openDeleteModal = useDeleteModal();

  const deleteRow = async ({ id }) => {
    await deleteVehicleRequest(id);
    setTableStore({ page: 1 });
    makeRequest({ page: 1 });
  };

  const onDeleteRow = () => {
    openDeleteModal({
      objectName: `ТС ${vehicle.brand} ${vehicle.model} ${vehicle.egrz}`,
      deleteItem: () => deleteRow(vehicle),
      id: vehicle.id,
      message: 'ТС удалёно',
    });
  };

  return (
    <>
      <HeaderSortFilter
        component={(
          <>
            <CustomCheckbox
              label="Не подключено к РИНС ЛО"
              onChange={changeConnectedRINS}
              value={notConnectedRINS}
            />
            <CustomCheckbox
              label="Архивные"
              onChange={changeWithDeleted}
              value={withDeleted}
            />
          </>
        )}
      />
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={initialTableVehicle}
        onClick={openVehicleModal}
        deleteRow={!withDeleted && hasPrivilege('vehicle_destroy') && {
          deleteRequest: deleteRow,
          deleteMessage: 'ТС удалёно',
          getDeleteObjectName: (row) => `ТС ${row.egrz}`,
        }}
      />
      {isOpenEditModal && (
        <ModalAddVehicle
          vehicle={vehicle}
          close={closeEditModal}
          updateVehicleInfo={makeRequest}
        />
      )}
      {vehicle?.id && (
        <ModalVehicleInfo onDelete={hasPrivilege('vehicle_destroy') && onDeleteRow} vehicle={vehicle} close={closeVehicleModal} />
      )}
    </>
  );
}
