import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomInput from '../Inputs';
import BackdropLoading from '../BackdropLoading';
import CarrierEdit from '../Carrier/CarrierEdit';

export default function CommitteeRegistrationData({
  error, editField, changeEdit, userInfo, makeRequest, onChange, isLoading,
}) {
  return (
    <Box position="relative">
      <BackdropLoading isLoading={isLoading} />
      <Box display="flex" gap={10} flexWrap="wrap">
        <Box display="flex" flexWrap="wrap" gap={3}>
          <Box mb={3}>
            <Typography mb={2} component="h3" className="text-title">Регистрационные данные</Typography>
            <Box display="flex" gap={3} flexDirection="column">
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="last_name"
                title="Фамилия"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="middle_name"
                title="Отчество"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="first_name"
                title="Имя"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="role"
                title="Должность"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="email"
                title="E-mail"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                editField={editField}
                onChange={onChange}
                title="Номер телефона"
                field="phone"
                type="tel"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} mb={5} width="320px">
            <Typography mb={2} component="h3" className="text-title">Изменение пароля</Typography>
            <CustomInput
              required
              value={userInfo.old_password}
              type="password"
              label="Текущий пароль"
              name="old_password"
              onChange={onChange}
              error={error.old_password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Новый пароль"
              name="password"
              type="password"
              value={userInfo.password}
              error={error.password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Подтверждение пароля"
              type="password"
              name="password_confirmation"
              value={userInfo.password_confirmation}
              error={error.password_confirmation}
            />
            <Button onClick={() => makeRequest(['password_confirmation', 'password', 'old_password'])}>
              Сохранить
              пароль
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
