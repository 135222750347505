export const WASTE_MANAGEMENT_ENUM = 'waste_management_type';
export const WASTE_MANAGEMENT_TYPES_ENUM = 'waste_management_types';
export const WASTE_MANAGEMENT_TYPE_IDS = 'waste_management_type_ids';
export const WASTE_MANAGEMENT_TYPE_ID = 'waste_management_type_id';
export const WASTE_TYPES_ENUM = 'waste_types';
export const OWNERSHIP_TYPE_ENUM = 'ownership_type';
export const OWNERSHIP_TYPE_ENUM_TEXT = 'ownership_type_text';
export const HAZARD_CLASS_ENUM = 'hazard_class';
export const HAZARD_CLASS_ENUM_TEXT = 'hazard_class_text';
export const DIVISION_TYPE_ENUM = 'division';
export const DIVISION_TYPE_ENUM_TEXT = 'division_text';
export const ENTERPRISE_TYPE_ENUM = 'enterprise_type';
export const ENTERPRISE_ACTIVITY_TYPES_ENUM = 'enterprise_activity_types';
export const ENTERPRISE_ACTIVITY_TYPE_IDS = 'enterprise_activity_type_ids';
export const ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES = 'enterprise_activity_types_attributes';
export const ENTERPRISE_TYPE_TEXT_ENUM = 'enterprise_type_text';
export const SUBJECT_TYPE_ENUM = 'subject_type';
export const SUBJECT_TYPE_ATTRIBUTES = 'subject_type_attributes';
export const WASTE_CONDITION_TYPES = 'waste_aggregate_states';
export const OBJECTIVE_TYPES = 'objective_types';
export const WASTE_CONDITION_ENUM = 'waste_aggregate_state';
export const WASTE_CONDITION_ATTRIBUTES = 'waste_aggregate_state_attributes';
export const SUBJECT_TYPES = 'subject_types';
export const VIOLATION_TYPE_ENUM = 'kind';

export const getObjectAttributes = ({ object, field }) => object[`${field}_attributes`];

export const DEFAULT_ENUM_OPTION = {
  name: '',
  id: '',
};
