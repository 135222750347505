// @ts-nocheck
import { Box } from '@mui/material';
import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { getForm1WasteProducts } from '../../../helpers/cadastrHelper';
import { precisionAdd } from '../../../helpers/numberHelper';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import CadastreForm3Wastes from '../../Cadastr/CadastreForm3Wastes';
import CustomAccordion from '../../CustomAccordion';
import NavTabs from '../../Tabs';
import { TextMain } from '../../Text';
import ModalAddSubject from '../ModalAddSubject';
import { useCadastreStore } from '../../../store';
import { compareId } from '../../../helpers';

const WASTE_TABS = [
  { name: 'generating', label: 'Образуемые отходы' },
  { name: 'accepting', label: 'Принимаемые отходы ' },
];

function ModalCadastrForm3({
  data: { items: buildings }, form3Items, onChange, isDemo, changeItemFormByField, onChangeForm3,
}) {
  const [createSubjectField, setCreateSubjectField] = useState(null);
  const [wasteTab, setWasteTab] = useState(WASTE_TABS[0].name);
  const { cadastreStore } = useCadastreStore();

  const { openDeleteModal } = useDeleteCadastreEntity();

  const form1WasteProducts = useMemo(() => getForm1WasteProducts(buildings, 'form3Waste'), [buildings]);
  const form1Wastes = useMemo(() => getForm1WasteProducts(buildings), [buildings]);

  const isAccepting = wasteTab === 'accepting';

  const closeNewSubjectModal = () => setCreateSubjectField(null);

  const setNewSubject = (newSubject) => {
    onChange({
      field: createSubjectField.field,
      value: { ...newSubject, ...newSubject?.attributes },
      buildingForm3Index: createSubjectField.buildingIndex,
      reportGenerationIndex: createSubjectField.reportGenerationIndex,
    });
    closeNewSubjectModal();
  };

  const { tab, tabs, setTab } = useCadastreTabs({ items: buildings, tabName: 'Здание' });

  const totalWastesItems = isAccepting ? form3Items?.items : form1WasteProducts;

  const totalReportYearWasteCount = totalWastesItems.reduce((prev, item) => precisionAdd(prev, item.availability_waste_period), 0);

  const totalReportYearWasteTypeCount = form1Wastes.reduce((prev, item) => precisionAdd(prev, item.quantity), 0);

  const totalFromOtherOrganisation = totalWastesItems.reduce((prev, item) => precisionAdd(prev, item.get_all), 0);

  const totalToOtherOrganisation = totalWastesItems.reduce(
    (prev, item) => precisionAdd(prev, item.waste_transfers.reduce(
      (prevTransfer, itemTransfer) => precisionAdd(
        prevTransfer,
        itemTransfer.out_processing,
        itemTransfer.out_utilization,
        itemTransfer.out_neutralization,
        itemTransfer.out_for_store,
        itemTransfer.out_under_store,
      ),
      0,
    )),
    0,
  );

  const totalToOtherOrganisationUtilization = totalWastesItems.reduce((prev, item) => precisionAdd(prev, item.get_utilization), 0);
  const totalToOtherOrganisationNeutralization = totalWastesItems.reduce((prev, item) => precisionAdd(prev, item.get_neutralization), 0);
  const totalToOtherOrganisationProcessing = totalWastesItems.reduce((prev, item) => precisionAdd(prev, item.get_processing), 0);

  const totalReportYearWastePlacement = totalWastesItems.reduce((prev, item) => precisionAdd(
    prev,
    item?.waste_transfers.reduce((prevTransfer, wasteTransfer) => precisionAdd(prevTransfer, wasteTransfer.store), 0),
    item?.waste_transfers.reduce((prevTransfer, wasteTransfer) => precisionAdd(prevTransfer, wasteTransfer.under_store), 0),
  ), 0);
  

  
  const totalEndReportYearWastePlacement = +(Number(totalReportYearWasteCount)+(!isAccepting ? Number(totalReportYearWasteTypeCount) : 0)+
  (isAccepting ? Number(totalFromOtherOrganisation) : 0 )
  -Number(totalReportYearWastePlacement)-Number(totalToOtherOrganisation)-Number(totalToOtherOrganisationProcessing)-Number(totalToOtherOrganisationUtilization)-Number(totalToOtherOrganisationNeutralization)).toFixed(8);

  const getBuilding = useCallback((tabId) => buildings.find((building) => compareId(building.randId, tabId)) || {}, [buildings]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {createSubjectField !== null && (
        <ModalAddSubject
          division="internal"
          close={closeNewSubjectModal}
          setNewSubject={setNewSubject}
        />
      )}
      <CustomAccordion title={`Всего ${isAccepting ? 'принимаемых' : 'образуемых'} отходов`}>
        <TextMain big>
          - Наличие отходов на начало отчётного периода:
          {' '}
          {totalReportYearWasteCount}
        </TextMain>
        {!isAccepting && (
          <TextMain big>
            - Образование отходов за отчётный период:
            {' '}
            {totalReportYearWasteTypeCount}
          </TextMain>
        )}
        {isAccepting && (
          <TextMain big>
            - Поступление отходов от других организаций:
              {' '}
              {totalFromOtherOrganisation}
          </TextMain>
        )}
        <TextMain big>
          - Передача отходов другим организациям:
          {' '}
          {totalToOtherOrganisation}
        </TextMain>
        <TextMain big>
          - Размещение отходов на собственных объектах за отчётный год:
          {' '}
          {totalReportYearWastePlacement}
        </TextMain>
        <TextMain big>
          - Наличие на предприятии на конец отчётного года:
          {' '}
          {totalEndReportYearWastePlacement}
        </TextMain>
        <TextMain big>
          - Обработано на собственных объектах за отчетный год:
          {' '}
          {totalToOtherOrganisationProcessing}
        </TextMain>
        <TextMain big>
          - Утилизировано на собственных объектах за отчетный год:
          {' '}
          {totalToOtherOrganisationUtilization}
        </TextMain>
        <TextMain big>
          - Обезврежено на собственных объектах за отчетный год:
          {' '}
          {totalToOtherOrganisationNeutralization}
        </TextMain>
      </CustomAccordion>
      <NavTabs
        getErrorTab={(tabId) => cadastreStore.errorForm3?.some?.((item) => item.includes(tabId))}
        tabsComponents={WASTE_TABS}
        setTab={setWasteTab}
        tab={wasteTab}
      />
      {wasteTab === 'accepting' && (
        <CadastreForm3Wastes
          form3Items={form3Items}
          setCreateSubjectField={setCreateSubjectField}
          openDeleteModal={openDeleteModal}
          onChange={onChangeForm3}
          changeItemFormByField={changeItemFormByField}
          isDemo={isDemo}
        />
      )}
      {wasteTab === 'generating' && (
        <>
          <NavTabs
            getErrorTab={(tabId) => {
              const currentBuildingWasteId = `${getBuilding(tabId).id}`;
              return cadastreStore.errorForm3?.some?.((errorForm3) => errorForm3.includes(currentBuildingWasteId));
            }}
            tabsComponents={tabs}
            setTab={setTab}
            tab={tab}
          />
          {buildings.map((building, buildingIndex) => {
            if (building.randId !== tab) return null;

            return (
              <CadastreForm3Wastes
                buildingIndex={buildingIndex}
                setCreateSubjectField={setCreateSubjectField}
                changeItemFormByField={changeItemFormByField}
                key={building.randId}
                openDeleteModal={openDeleteModal}
                building={building}
                onChange={onChange}
                isDemo={isDemo}
              />
            );
          })}
        </>
      )}
    </Box>
  );
}

export default memo(ModalCadastrForm3);
