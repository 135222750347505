import React, { useEffect, useState } from 'react';
import { Box, Link } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { useApi } from '../../hooks';
import { cadastreFillingRecommendationsRequest, cadastreRecommendationRequest } from '../../api/cadastreApi';
import { METHODS_API } from '../../api/api';
import { compareId } from '../../helpers';
import useDeleteModal from '../../hooks/useDeleteModal';
import BackdropLoading from '../BackdropLoading';
import DropZone from '../File/DropZone';
import DeleteButton from '../Buttons/DeleteButton';
import { TextTitle } from '../Text';
import { createUrlFromLocalBlob } from '../../helpers/fileHelper';

export default function CadastreCommonRecommendations({ isAdmin }) {
  const [files, setFiles] = useState([]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const { defaultRequest: fillingRecommendationApi, isLoading } = useApi({
    request: cadastreFillingRecommendationsRequest,
  });

  useEffect(() => {
    fillingRecommendationApi().then(async (data) => {
      const blobFiles = [];
      for await (const item of data) {
        const blobFile = await cadastreRecommendationRequest(item.id);
        const blobUrl = await createUrlFromLocalBlob(blobFile?.data);

        blobFiles.push({
          file_url: blobUrl,
          id: item.id,
          name: item.name,
        });
      }
      setFiles(blobFiles);
    });
  }, []);

  const deleteRecommendation = async (recommendationId) => {
    const response = await fillingRecommendationApi({
      method: METHODS_API.DELETE,
      id: recommendationId,
    });

    if (response?.id) {
      setFiles((prev) => prev.filter((recommendation) => !compareId(recommendation.id, recommendationId)));
    }
  };

  const getFiles = async (e, dropZoneFiles) => {
    const newFiles = [];

    for await (const file of dropZoneFiles) {
      const formData = new FormData();

      formData.append('filling_recommendation[file]', file);

      const responseFile = await fillingRecommendationApi({
        method: METHODS_API.POST,
        data: formData,
        isFormData: true,
      });

      newFiles.push(responseFile);
    }

    setFiles(newFiles);
  };

  const openDeleteModal = useDeleteModal();

  const onDeleteRecommendation = (recommendationId, recommendationNumber) => {
    openDeleteModal({
      objectName: `рекомендацию №${recommendationNumber}`,
      deleteItem: () => deleteRecommendation(recommendationId),
      id: recommendationId,
      message: 'Рекомендация успешно удалена',
    });
  };

  return (
    <Box pt={3} width="100%" sx={{ ...flexGap2, position: 'relative' }}>
      <BackdropLoading isLoading={isLoading} />
      {isAdmin && <DropZone sx={{ height: 120 }} getFiles={getFiles} maxFiles={5} />}
      {files.map(({ file_url, name, id }, index) => (
        <Box key={id} height="600px" sx={flexRowGap2}>
          <Box>
            <TextTitle>
              Рекомендация №
              {index + 1}
            </TextTitle>
            <Link target="_blank" download href={file_url} rel="noreferrer">
              Cсылка на файл:
              {' '}
              {name}
            </Link>
            {isAdmin && (
              <DeleteButton onClick={() => onDeleteRecommendation(id, index + 1)} sx={{ mt: 3 }}>
                Удалить
                рекомендацию
              </DeleteButton>
            )}
          </Box>
          {file_url.includes('pdf') ? (
            <Box flex="1 1 0" overflow="auto" height="100%">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                <Viewer
                  localization={ru_RU}
                  defaultScale="ActualSize"
                  fileUrl={file_url}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </Box>
          ) : (
            <TextTitle sx={{ color: (theme) => theme.palette.warning.main }}>
              Предпросмотр файла не доступен. Загрузите файл в ручную
            </TextTitle>
          )}
        </Box>
      ))}
    </Box>
  );
}
