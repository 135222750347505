import { Box } from '@mui/material';
import React, { useState } from 'react';
import { TextTitle } from '../../../Text';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import { AsyncAutocomplete } from '../../../AutoCompletes/AsyncAutocomplete';
import CustomDatePicker from '../../../CustomDatePicker';
import AddButton from '../../../Buttons/AddButton';
import {
  getInitialReportObject,
  REPORT_TECHNOLOGIES_NAME,
  TECHNOLOGIES_NAME,
} from '../../../../constants/lkCadastr/cadastrForm7';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../../constants/lkCadastr/cadastrStorage';
import { ObjectPower } from './ObjectPower';
import { Technologies } from './Technologies';
import { formatObjectWithAddress } from '../../../../helpers/stringHelper';
import CustomAccordion from '../../../CustomAccordion';
import NavTabs from '../../../Tabs';
import CadastreForm7License from '../../../Cadastr/CadastreForm7License';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import { getReferencesRequest } from '../../../../api/referenceApi';
import { ENTERPRISE_ACTIVITY_TYPES_ENUM } from '../../../../constants/enums';
import { formatEnumToSelectOption } from '../../../../helpers/entityHelper';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../Inputs/CustomAutocomplete';
import { useDeleteCadastreEntity } from '../../../../hooks/cadastre/useDeleteCadastreEntity';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';
import { compareId } from '../../../../helpers';

const REPORT_OBJECT_TABS = [
  { label: 'Информация об объекте', name: 'object_info' },
  { label: 'Технологии', name: TECHNOLOGIES_NAME },
];

export function Objects({
  reportTechnologies,
  changeItem,
  isDemo,
  subjectAttributes,
  setCreateObjectIndex,
  form1Objects,
  findForm1Object,
  form5Licenses,
  is_license_unneeded,
  changeLicenseUnneeded,
  isHideForm5,
}) {
  const { openDeleteModal } = useDeleteCadastreEntity();

  const [tab, setTab] = useState(REPORT_OBJECT_TABS[0].name);

  const { tab: objectTab, setTab: setObjectTab, tabs: objectTabs } = useCadastreTabs({
    items: reportTechnologies,
    tabName: 'Объект',
  });

  const form1ObjectsOptions = form1Objects
    .map((item) => ({
      ...item.objective_id,
    }));

  const form1ObjectsAddressOptions = form1Objects
    .map((item) => ({
      ...item.objective_id,
      label: formatObjectWithAddress({ objective: item.objective_id }),
    }));

  return (
    <Box sx={flexGap2}>
      <Box sx={flexGap2}>
        <Box sx={flexRowGap2}>
          <TextTitle h3>Объекты</TextTitle>
          {!isDemo && (
            <AddButton
              variant="outlined"
              onClick={() => changeItem({
                field: REPORT_TECHNOLOGIES_NAME,
                value: [...reportTechnologies, getInitialReportObject()],
              })}
            >
              Добавить объект
            </AddButton>
          )}
        </Box>
        <NavTabs tab={objectTab} setTab={setObjectTab} tabsComponents={objectTabs} />
        <Box sx={flexGap2}>
          {reportTechnologies.map((object, objectIndex) => {
            if (objectTab !== object.randId) return null;

            const changeObject = (field, value) => changeItem({
              field: `${REPORT_TECHNOLOGIES_NAME}.${objectIndex}.${field}`,
              value,
              reportTechnologyIndex: objectIndex,
            });

            const onDeleteObject = async () => {
              if (object?.id) {
                const deletedObject = await deleteForm7Entity({ id: object.id, apiType: REPORT_TECHNOLOGIES_NAME });

                if (!deletedObject) return;
              }

              const cloneArray = reportTechnologies.slice();
              cloneArray.splice(objectIndex, 1);

              changeItem({
                field: REPORT_TECHNOLOGIES_NAME,
                value: cloneArray,
                type: 'delete',
              });
            };

            const isZeroObject = compareId(object.objective_id?.name, 0);

            return (
              <Box key={object.randId} sx={flexGap2}>
                {!isDemo && reportTechnologies.length > 1 && (
                  <DeleteButton onClick={() => openDeleteModal(onDeleteObject, objectIndex, 'Объект')}>
                    Удалить объект
                  </DeleteButton>
                )}
                <CustomAutocomplete
                  canBeZero
                  validImmediately={!isZeroObject}
                  useDefaultFilter
                  required
                  debounceTime={0}
                  disabled={isDemo}
                  label="Наименование объекта"
                  sx={fullWidth}
                  options={form1ObjectsOptions}
                  noOptionComponent={(
                    <AddButton
                      variant="outlined"
                      onClick={() => setCreateObjectIndex(objectIndex)}
                    >
                      Создать объект
                    </AddButton>
                  )}
                  value={object.objective_id}
                  onChange={(e, value) => changeObject('objective_id', value)}
                />
                <CustomAutocomplete
                  validImmediately={!isZeroObject}
                  useDefaultFilter
                  required
                  debounceTime={0}
                  disabled={isDemo || isZeroObject}
                  label="Фактический адрес"
                  sx={fullWidth}
                  options={form1ObjectsAddressOptions}
                  noOptionComponent={(
                    <AddButton
                      variant="outlined"
                      onClick={() => setCreateObjectIndex(objectIndex)}
                    >
                      Создать объект
                    </AddButton>
                  )}
                  value={object.objective_id}
                  onChange={(e, value) => changeObject('objective_id', value)}
                />
                <CustomInput
                  disabled
                  label="Код ОКТМО"
                  value={object.objective_id?.address_attributes?.attributes?.oktmo_attributes?.attributes?.name}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={flexGap2}>
        <NavTabs tab={tab} setTab={setTab} tabsComponents={REPORT_OBJECT_TABS} />
        {reportTechnologies.map((object, objectIndex) => {
          if (objectTab !== object.randId) return null;

          const changeObject = (field, value, type, innerIndexProp) => changeItem({
            field: `${REPORT_TECHNOLOGIES_NAME}.${objectIndex}.${field}`,
            value,
            type,
            reportTechnologyIndex: objectIndex,
            ...innerIndexProp,
          });

          const objectiveForm1 = findForm1Object(object?.objective_id?.id);

          const wasteManagementTypes = objectiveForm1?.enterprise_activity_types || [];

          return (
            <Box key={object.randId} sx={flexGap2}>
              {tab === TECHNOLOGIES_NAME && (
                <Technologies
                  isDemo={isDemo}
                  changeItem={changeObject}
                  openDeleteModal={openDeleteModal}
                  technologies={object[TECHNOLOGIES_NAME]}
                />
              )}
              {tab === 'object_info' && (
                <Box sx={flexGap2}>
                  <ObjectPower
                    isDemo={isDemo}
                    data={object}
                    change={changeObject}
                    field_fact="actual_power"
                    field_project="design_power"
                  />
                  <AsyncAutocomplete
                    multiple
                    disabled
                    request={(params) => getReferencesRequest({
                      url: ENTERPRISE_ACTIVITY_TYPES_ENUM,
                      params,
                      filter: (options) => {
                        const filteredOptions = options
                          .filter((activityType) => subjectAttributes?.enterprise_activity_type_ids?.includes?.(Number(activityType.id)));
                        return formatEnumToSelectOption(filteredOptions);
                      },
                    })}
                    optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                    sx={fullWidth}
                    label="Назначение объекта"
                    value={wasteManagementTypes}
                  />
                  <Box sx={flexRowGap2}>
                    <CustomDatePicker
                      canBeZero
                      validImmediately
                      disabled={isDemo}
                      label="Дата ввода в эксплуатацию"
                      sx={fitContent}
                      value={object.exploitation_in_date}
                      onChange={(e, value) => changeObject('exploitation_in_date', value)}
                    />
                    <CustomDatePicker
                      canBeZero
                      validImmediately
                      disabled={isDemo}
                      sx={{ width: '280px' }}
                      label="Дата вывода из эксплуатации"
                      value={object.exploitation_out_date}
                      onChange={(e, value) => changeObject('exploitation_out_date', value)}
                    />
                  </Box>
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    label="Основания"
                    value={object.reason_for_decommissioning}
                    onChange={(e, value) => changeObject('reason_for_decommissioning', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    label="Наименование органа заключения ГЭЭ"
                    value={object.body_name_conclusion}
                    onChange={(e, value) => changeObject('body_name_conclusion', value)}
                  />
                  <CustomDatePicker
                    validImmediately
                    canBeZero
                    disabled={isDemo}
                    label="Дата заключения ГЭЭ"
                    sx={fitContent}
                    value={object.date_conclusion}
                    onChange={(e, value) => changeObject('date_conclusion', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    label="Номер заключения ГЭЭ"
                    value={object.number_conclusion}
                    onChange={(e, value) => changeObject('number_conclusion', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    type="number"
                    label="С33, м"
                    value={object.szz}
                    onChange={(e, value) => changeObject('szz', value)}
                  />
                  <Box sx={flexGap2}>
                    <CustomAccordion title="Лицензия">
                      <CadastreForm7License
                        isDemo={isDemo}
                        isHideForm5={isHideForm5}
                        license={object.license}
                        is_license_unneeded={is_license_unneeded}
                        changeLicenseUnneeded={changeLicenseUnneeded}
                        form5Licenses={form5Licenses}
                        changeObject={changeObject}
                      />
                    </CustomAccordion>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
