import { ArrowBack } from '@mui/icons-material';
import { Fab } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const iconStyle = { position: 'absolute', top: 10, left: 10 };

export default function BackButton({ path = '/', onClick }) {
  const navigate = useNavigate();

  return (
    <Fab
      size="small"
      onClick={() => (onClick ? onClick() : navigate(path))}
      sx={iconStyle}
    >
      <ArrowBack />
    </Fab>
  );
}
