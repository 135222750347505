import { jsPDF as JsPDF } from 'jspdf';
import React from 'react';
import { TEXT_NO_VALUE } from '../constants';
import { getDayMonthName, getDayMonthNameYearTime } from '../helpers/dateHelper';
import { formatPDFAddressString } from '../helpers/stringHelper';
import CustomButton from './Buttons/CustomButton';

const addText = ({
  xPlace = 15, yPlace = 15, index, text, doc,
}) => {
  const indexYPlace = yPlace + ((index + 1) * 6);
  if (typeof text === 'string') {
    doc.text(text, xPlace, indexYPlace);
  } else if (typeof text === 'function') {
    text(index);
  } else if (text.title) {
    doc.setFont('RobotoBold');
    doc.text(text.title, xPlace, indexYPlace);
    doc.setFont('Roboto');
  }
};

const formatPdfMaxLength = (string) => (string?.length > 60
  ? string.slice(0, 60) + string.slice(60, string.length).replace(' ', '\n')
  : string);

export default function ApplicationPdf({ application, mapImage, localQrCodeUrl }) {
  const openPdf = async () => {
    const doc = new JsPDF();

    try {
      if (localQrCodeUrl || application.qr_code_url) {
        doc.addImage(localQrCodeUrl || application.qr_code_url, 'png', 130, 0, 90, 90);
      }

      if (mapImage) {
        doc.addImage(mapImage, 'png', 105, 90, 100, 120);
      }

      const subjectAttributes = application?.subject_attributes?.attributes;
      const vehicle = application?.vehicle_attributes?.attributes;

      const waste_recipient = application.objective_attributes;
      const recipient_object = application.objective_attributes?.attributes;
      const recipient_subject = recipient_object
        ?.subject_attributes
        ?.attributes;

      doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Regular.ttf', 'Roboto', 'normal');
      doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Bold.ttf', 'RobotoBold', 'normal');
      doc.setFont('RobotoBold'); // set font
      doc.setFontSize(16);

      const pdfTextsRow1 = [
        { title: 'Субъект перевозчик:' },
        formatPdfMaxLength(subjectAttributes?.name) || TEXT_NO_VALUE,
        '',
        { title: 'Дата и время получения разрешения:' },
        getDayMonthNameYearTime(application.created_at),
        '',
        { title: 'Класс отходов:' },
        (outerIndex) => {
          if (application.hazard_class_text?.length === 0) {
            doc.text('Нет', 15, 15 + ((outerIndex + 1) * 6));
          } else {
            doc.text(application.hazard_class_text.join(','), 15, 15 + ((outerIndex + 1) * 6));
          }
        },
        '',
        { title: `Информация о ТС${vehicle.is_inactive ? ' (В архиве)' : ''}` },
        `${vehicle.is_connected_rins ? '' : 'Не '}подключено к РИНС ЛО`,
        `ID БНСО: ${vehicle.guid_bnso || TEXT_NO_VALUE}`,
        { title: 'ЕГРЗ ТС (Номер ТС):' },
        vehicle.egrz || TEXT_NO_VALUE,
        '',
        { title: 'ЕГРЗ прицепа:' },
        vehicle.egrz_trailer || 'Не выбран',
        '',
        { title: 'Марка и модель ТС:' },
        `${vehicle.brand} ${vehicle.model}` || TEXT_NO_VALUE,
        '',
      ];

      pdfTextsRow1.forEach((text, index) => {
        addText({ index, text, doc });
      });

      if (application?.application_objectives_attributes?.length) {
        doc.addPage();
        doc.setFontSize(12);
        application?.application_objectives_attributes.forEach(({
          attributes: {
            created_at,
            objective_attributes,
          },
        }, index) => {
          const wasteGeneratorTextsRow = [
            { title: 'Отходообразователи' },
            getDayMonthName(created_at) || TEXT_NO_VALUE,
            `${index + 1}. Объект: ${formatPdfMaxLength(objective_attributes?.attributes?.name)} ${index === 0 && '(Начальная точка)'}`,
            { title: 'Субъект:' },
            formatPdfMaxLength(
              objective_attributes?.attributes?.subject_attributes?.attributes?.name,
            ) || TEXT_NO_VALUE,
            '',
            { title: 'ИНН:' },
            objective_attributes?.attributes?.subject_attributes?.attributes?.inn || TEXT_NO_VALUE,
            { title: 'ОГРН:' },
            objective_attributes?.attributes?.subject_attributes?.attributes?.ogrn || TEXT_NO_VALUE,
            { title: 'Адрес:' },
            objective_attributes
              ?.attributes
              ?.address_attributes
              ?.attributes ? formatPdfMaxLength(formatPDFAddressString(
                objective_attributes
                  ?.attributes
                  ?.address_attributes
                  ?.attributes,
              )) : TEXT_NO_VALUE,
            '',
          ];
          wasteGeneratorTextsRow.forEach((text, rowIndex) => addText({
            index: rowIndex + index, text, doc,
          }));
        });
      }

      doc.addPage();
      doc.setFontSize(12);
      const wasteGeneratorTextsRow = [
        { title: 'Субъект обращения с отходами' },
        getDayMonthName(waste_recipient?.created_at) || TEXT_NO_VALUE,
        `Объект: ${recipient_object?.name}`,
        { title: 'Субъект:' },
        formatPdfMaxLength(recipient_subject?.name) || TEXT_NO_VALUE,
        '',
        { title: 'ИНН:' },
        recipient_subject?.inn || TEXT_NO_VALUE,
        { title: 'ОГРН:' },
        recipient_subject?.ogrn || TEXT_NO_VALUE,
        { title: 'Адрес:' },
        recipient_object?.address_attributes?.attributes ? (
          formatPDFAddressString(recipient_object?.address_attributes?.attributes)
        ) : TEXT_NO_VALUE,
      ];
      wasteGeneratorTextsRow.forEach((text, index) => addText({ index, text, doc }));
      doc.save('Экспорт разрешения.pdf');
    } catch (e) {
      console.error(e, e?.message);
    }
  };

  return (
    <CustomButton
      isLoading={mapImage === undefined}
      onClick={openPdf}
    >
      Экспорт в PDF
    </CustomButton>
  );
}
