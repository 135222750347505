import { useState } from 'react';
import { useApi } from './useApi';
import { getRolePrivilegesRequest } from '../api/rolesApi';
import { useUserStore } from '../store';
import { useAdminRole } from './useAdminRole';

export function usePrivilegeRole() {
  const { userStore } = useUserStore();
  const [privileges, setPrivileges] = useState();

  const { isUserMainAdmin } = useAdminRole();

  useApi({
    setter: (newPrivileges) => setPrivileges(newPrivileges.map((item) => item?.privilege_attributes?.attributes?.code)),
    shouldRequest: userStore?.role_id,
    request: () => getRolePrivilegesRequest(userStore?.role_id, { total: 10000 }),
  });

  const checkPrivilege = (comparePrivilege) => Boolean(privileges?.includes?.(comparePrivilege));

  const checkIsSuperAdmin = () => true;

  return isUserMainAdmin ? checkIsSuperAdmin : checkPrivilege;
}
