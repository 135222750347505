import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useUserStore } from '../../store';
import { formValidator } from '../../helpers';
import { VALIDATION_TEMPLATE } from '../../helpers/formValidator';
import { useApi } from '../../hooks';
import CommitteeRegistrationData from '../../components/Committee/CarrierRegistrationData';
import { getMemberRequest, updateUserRequest } from '../../api/userApi';

const initialUserInfo = {
  email: '',
  phone: '',
  password: '',
  old_password: '',
  role: '',
  password_confirmation: '',
  first_name: '',
  second_name: '',
  last_name: '',
};

const initialEditField = {
  phone: false,
  first_name: false,
  middle_name: false,
  last_name: false,
  email: false,
};

export default function CommitteeProfilePage() {
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [editField, setEditField] = useState(initialEditField);
  const { userStore, setUserStore } = useUserStore();
  const [error, setError] = useState({});

  const { makeRequest: getMember } = useApi({
    request: async () => {
      const userData = await getMemberRequest();

      setUserStore(userData);
    },
  });

  const translateCommittee = () => {
    setUserInfo({ ...userStore, role: userStore?.role_attributes?.attributes?.name });
  };

  const updateCommitteeProfile = async (fields) => {
    const { hasErrors, validField } = formValidator({
      form: userInfo,
      checkOnlyFields: fields,
      customRules: [
        { name: VALIDATION_TEMPLATE.password },
        { name: VALIDATION_TEMPLATE.password_confirmation },
        { name: VALIDATION_TEMPLATE.email },
      ],
      inputRule: {
        password: [VALIDATION_TEMPLATE.password],
        password_confirmation: [VALIDATION_TEMPLATE.password_confirmation],
        old_password: [VALIDATION_TEMPLATE.password],
      },
    });

    setError(validField);

    if (hasErrors) return;

    const form = {};
    const newEditFields = {};

    fields.forEach((innerField) => {
      if (userInfo[innerField]?.label !== undefined) {
        form[innerField] = userInfo[innerField].value;
        return;
      }
      form[innerField] = userInfo[innerField];
      newEditFields[innerField] = false;
    });

    setEditField((prevState) => ({
      ...prevState,
      ...newEditFields,
    }));

    await updateUserRequest(userInfo.id, { user: form });
    await getMember();
  };

  const changeEdit = (field, value) => {
    if (!value) translateCommittee();

    setEditField((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const {
    makeRequest: updateSingleSubjectField,
    isLoading: updateSubjectLoading,
  } = useApi({
    request: updateCommitteeProfile,
    errorCallback: () => translateCommittee(),
  });

  const onChange = ({ target: { name } }, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    translateCommittee();
    /* eslint-disable-next-line */
  }, [userStore]);

  return (
    <Box py={2}>
      <Typography mb={5} fontSize="26px">{userStore?.name}</Typography>
      <CommitteeRegistrationData
        isLoading={updateSubjectLoading}
        onChange={onChange}
        userInfo={userInfo}
        editField={editField}
        changeEdit={changeEdit}
        makeRequest={updateSingleSubjectField}
        error={error}
      />
    </Box>
  );
}
