import { Box } from '@mui/material';
import React from 'react';
import { TextTitle } from '../../../Text';
import { flexGap2, flexRowGap2 } from '../../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../../Inputs';

const FACT_OPTIONS = [
  { label: 'Стационарная', value: 'Стационарная', id: 'Стационарная' },
  { label: 'Передвижная', value: 'Передвижная', id: 'Передвижная' },
];

export function ObjectPower({
  data, field_fact, field_project, change, isDemo,
}) {
  return (
    <Box sx={flexGap2}>
      <TextTitle>Мощность, т/год</TextTitle>
      <Box sx={flexRowGap2}>
        {field_project && (
          <CustomInput
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            type="number"
            label="Проектная"
            value={data[field_project]}
            onChange={(e, value) => change(field_project, value)}
          />
        )}
        {field_fact && (
          <CustomInput
            validImmediately
            debounceTime={0}
            disabled={isDemo}
            type="number"
            label="Фактическая"
            value={data[field_fact]}
            options={FACT_OPTIONS}
            onChange={(e, value) => change(field_fact, value)}
          />
        )}
      </Box>
    </Box>
  );
}
