import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, FormHelperText } from '@mui/material';
import { getFileExtensions } from '../../helpers';
import UrlFromFile from './UrlFromFile';
import { blobToFile, removeAllInputFiles, removeFileFromFileList } from '../../helpers/fileHelper';
import api from '../../api/api';
import CustomButton from '../Buttons/CustomButton';
import { usePushNotification } from '../../hooks';
import { Text } from '../Text';

export const SIZE_TEN_MEGABYTE = 10485760;

export function FileUpload({
  required,
  extensions = ['pdf', 'docx', 'doc'],
  className = '',
  onChange = (event, newFiles) => { },
  limitSize,
  error,
  name,
  setFile,
  endIcon,
  fileName,
  files = [],
  id = 'file-upload',
  label = 'Выбрать файл',
  buttonVariant = 'text',
  isFileReadyToShow = false,
  openImmediately = false,
}) {
  const pushNotification = usePushNotification();

  const fileRef = useRef();

  const changeInput = (e) => {
    let { files: newFiles } = e.target;

    let hasLimitFile = false;

    if (limitSize) {
      if (newFiles?.[0]) {
        newFiles = Array.from(newFiles);
      }

      newFiles = newFiles.filter((file) => {
        const hasUpperLimitFile = file.size > limitSize;

        if (hasUpperLimitFile) hasLimitFile = hasUpperLimitFile;

        return !hasUpperLimitFile;
      });

      if (hasLimitFile) {
        pushNotification({ message: 'Размер файла превышает лимит' });
      }

      if (newFiles.length === 0) {
        removeAllInputFiles(fileRef.current);
        return;
      }
    }

    if (setFile) return setFile(e, newFiles[0], () => removeAllInputFiles(fileRef.current));

    return onChange(e, newFiles);
  };

  const removeFile = (index) => {
    removeFileFromFileList(index, fileRef.current);
    changeInput({ target: fileRef.current });
  };

  const hasStringFile = files.some((file) => typeof file === 'string' && file);

  useEffect(() => {
    if (hasStringFile && !isFileReadyToShow) {
      (async () => {
        const clonedFiles = files.slice();
        let index = 0;
        // eslint-disable-next-line no-restricted-syntax
        for await (const file of files) {
          if (typeof file === 'string') {
            try {
              const { data: fileData } = await api({
                url: file,
                responseType: 'blob',
              }, { restUrl: '' });
              clonedFiles.splice(index, 1, {
                document_name: fileName || index,
                file: blobToFile(fileData, `Документ ${index + 1}`),
              });
            } catch (e) {
              clonedFiles.splice(index, 1, { document_name: '', file: '' });
            }
          }
          index += 1;
        }
        onChange({ target: { name } }, clonedFiles);
      })();
    }
    /* eslint-disable-next-line */
  }, [files, isFileReadyToShow]);

  useEffect(() => {
    if (openImmediately && fileRef?.current) {
      fileRef.current?.click();
    }
  }, [openImmediately, fileRef]);

  if (hasStringFile && !isFileReadyToShow) return <CircularProgress />;

  return (
    <Box display="flex" alignItems="center" gap={3} className={`container-file-upload ${className}`}>
      <Box>
        {
          files.map((file, index) => file && (
            <UrlFromFile
              showRemove={files?.length > 1}
              removeFile={() => removeFile(index)}
              key={file.id || file.name || index}
              download={false}
              isFileReadyToShow={isFileReadyToShow}
              file={file}
            />
          ))
        }
      </Box>
      <Box>
        {label && (
          <CustomButton
            endIcon={endIcon}
            variant={buttonVariant}
            sx={{ pl: buttonVariant === 'text' ? 0 : '' }}
            component="label"
            htmlFor={id}
          >
            {`${label}${required ? ' *' : ''}`}
            {limitSize && <Text sx={{ color: 'orange' }}>{`(Размер файла не должен превышать ${limitSize / 1024 / 1024} мб)`}</Text>}
          </CustomButton>
        )}
        {error && <FormHelperText error={!!error}>Обязательное поле</FormHelperText>}
        <input
          ref={fileRef}
          accept={extensions ? getFileExtensions(extensions) : undefined}
          onChange={changeInput}
          size={limitSize}
          className="input-file-upload"
          id={id}
          name={name || id}
          type="file"
          hidden
        />
      </Box>
    </Box>
  );
}
