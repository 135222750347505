// @ts-nocheck
import React from 'react';
import { Box, Typography } from '@mui/material';
import { AddressAutocomplete } from '../AutoCompletes';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { getSubjectRequest, getSubjectsRequest, updateSubjectRequest } from '../../api/subjectApi';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import TitleBoxStyled from '../CreateApllication/TitleBoxStyled';
import InputsBoxStyled from '../CreateApllication/InputsBoxStyled';
import { getObjectivesRequest } from '../../api/objectivesApi';
import CustomInput from '../Inputs';
import { FileUpload, SIZE_TEN_MEGABYTE } from '../File/FileUpload';
import { OBJECTIVES_URL } from '../../constants/urls';
import { SUBJECT_TYPES } from '../../constants/enums';
import { getReferencesRequest } from '../../api/referenceApi';

export const OBJECTS_FIELD = 'objects';

const getSubject = () => (params) => getSubjectsRequest({
  params: {
    ...params,
    // [ENTERPRISE_ACTIVITY_TYPE_IDS]: typeIDs.join(','),
    total: 100,
  },
  shouldCache: true,
});

export default function WasteGenerator({
  wasteGenerator: {
    name: subject,
    inn,
    subject_type,
    ogrn,
    objects,
    waste_generator_documents,
  },
  generatingWasteManagementTypes,
  generatingEnterpriseTypes,
  deleteDocument,
  error,
  onChange,
  number,
  deleteItem,
  addItem,
  openNewSubjectModal,
  openNewObjectModal,
  deleteWasteGenerator,
}) {
  const generatorIndex = number - 1;

  const onChangeObject = (index, field) => (e, value) => {
    onChange(e, value, { index, generatorIndex, field });
  };

  const onChangeOrganisation = (e, value, index) => {
    onChange(e, value, { generatorIndex, index });
  };

  const hasSubject = subject?.id;

  const getSubjectApi = getSubject(generatingEnterpriseTypes);

  return (
    <Box>
      <TitleBoxStyled>
        <Typography fontSize={20} fontWeight="bold">
          Отходообразователь
          №
          {number}
          :
        </Typography>
        {deleteWasteGenerator && (
          <DeleteButton onClick={deleteWasteGenerator}>
            Удалить отходообразователя
          </DeleteButton>
        )}
      </TitleBoxStyled>
      <Box mb={1}>
        <TitleBoxStyled>
          <Typography fontWeight="bold">Организация</Typography>
        </TitleBoxStyled>
        <InputsBoxStyled>
          <AsyncAutocomplete
            required
            value={subject}
            optionField={{
              value: 'id',
              id: 'id',
              label: 'name',
            }}
            label="Наименование субъекта"
            request={getSubjectApi}
            onChange={onChangeOrganisation}
            noOptionComponent={(
              <AddButton variant="outlined" onClick={openNewSubjectModal}>
                Создать субъект
              </AddButton>
            )}
            error={error?.name?.id}
            name="name"
          />
          <AsyncAutocomplete
            required
            value={subject_type}
            name="subject_type"
            label="Тип субъекта"
            request={(params) => getReferencesRequest({ url: SUBJECT_TYPES, params })}
            error={error?.subject_type?.name || error?.subject_type?.code}
            optionField={{
              id: 'code',
              label: 'name',
              value: 'code',
            }}
            onChange={async (e, value) => {
              const newSubject = await updateSubjectRequest(subject.id, { subject_type_id: value.id });

              return onChangeOrganisation(e, newSubject);
            }}
            disabled={subject_type?.id || (!hasSubject && !subject_type?.id)}
          />
          <AsyncAutocomplete
            required
            value={inn}
            error={error?.inn?.inn}
            name="inn"
            onChange={onChangeOrganisation}
            label="ИНН"
            optionField={{
              label: 'inn',
              value: 'inn',
              id: 'inn',
            }}
            request={getSubjectApi}
            disabled={hasSubject && inn.inn}
          />
          <AsyncAutocomplete
            required
            value={ogrn}
            error={error?.ogrn?.ogrn}
            name="ogrn"
            onChange={onChangeOrganisation}
            label="ОГРН"
            disabled={hasSubject && ogrn.ogrn}
            optionField={{
              label: 'ogrn',
              value: 'ogrn',
              id: 'ogrn',
            }}
            request={getSubjectApi}
          />
        </InputsBoxStyled>
      </Box>
      {objects.map(({ id, name: objectName, address }, index) => {
        const innerError = error?.[OBJECTS_FIELD]?.[index];
        return (
          <Box key={id}>
            <TitleBoxStyled>
              <Typography fontWeight="bold">
                Объект отходообразователя
                №
                {number}
              </Typography>
              {objects.length > 1 && (
                <DeleteButton onClick={() => deleteItem(OBJECTS_FIELD, index)}>
                  Удалить объект
                </DeleteButton>
              )}
            </TitleBoxStyled>
            <InputsBoxStyled>
              <AsyncAutocomplete
                required
                useDefaultFilter
                label="Объект"
                shouldRequest={generatingWasteManagementTypes.length}
                request={(params) => {
                  const finalParams = {
                    ...params,
                    waste_management_type_ids: generatingWasteManagementTypes.join(','),
                  };
                  if (subject.id !== '') {
                    return getSubjectRequest({
                      shouldCache: true,
                      id: subject.id,
                      url: OBJECTIVES_URL,
                      params: finalParams,
                    });
                  }
                  return getObjectivesRequest({ params: finalParams });
                }}
                optionField={{
                  value: 'id',
                  id: 'id',
                  label: 'name',
                }}
                deps={[subject]}
                noOptionComponent={(
                  <AddButton variant="outlined" onClick={() => openNewObjectModal(number - 1, index)}>
                    Создать объект
                  </AddButton>
                )}
                error={innerError?.name?.id}
                value={objectName}
                name="name"
                onChange={onChangeObject(index, OBJECTS_FIELD)}
              />
              <AddressAutocomplete
                required
                disabled
                label="Адрес"
                setNewAddress={(newAddress) => onChangeObject(index, OBJECTS_FIELD)({ target: { name: 'address' } }, newAddress)}
                error={innerError?.address?.value}
                value={address}
                onChange={onChangeObject(index, OBJECTS_FIELD)}
              />
            </InputsBoxStyled>
          </Box>
        );
      })}
      <AddButton onClick={() => addItem(OBJECTS_FIELD)}>
        Добавить объект
      </AddButton>
      <Typography mb={2} fontWeight="bold">Договор с отходообразователем:</Typography>
      <Box display="flex" flexDirection="column" flexWrap="flex" gap={3}>
        {waste_generator_documents.map(({ file, document_name }, index) => (
          <Box
            key={`waste_generator_document_${String(file?.name) + index}`}
            display="flex"
            alignItems="center"
            gap={2}
          >
            {waste_generator_documents.length > 1 && (
              <DeleteButton
                name="waste_generator_document"
                onClick={() => deleteDocument({ generatorIndex: number - 1, index })}
              />
            )}
            <CustomInput
              required
              label="Номер договора"
              value={document_name}
              name="document_name"
              error={error?.waste_generator_documents?.[index]?.document_name}
              onChange={(e, value) => onChangeOrganisation(e, value, index)}
            />
            <FileUpload
              required
              isFileReadyToShow
              extensions={['*']}
              fileName={document_name}
              error={error?.waste_generator_documents?.[index]?.file}
              label="Прикрепить договор с отходообразователем"
              limitSize={SIZE_TEN_MEGABYTE}
              setFile={(e, value) => onChangeOrganisation(e, value, index)}
              onChange={(e, files) => onChangeOrganisation({ target: { name: 'waste_generator_documents' } }, files)}
              id={`waste_generator_document_${index}${number}`}
              name="file"
              files={[{ file, name: document_name }]}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
