import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';

export default function CustomAccordion({
  title, children, titleComponent, sx,
}) {
  return (
    <Accordion
      key={title}
      sx={sx}
      TransitionProps={{
        timeout: 0,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {titleComponent}
        {title && <Typography>{title}</Typography>}
      </AccordionSummary>
      <AccordionDetails sx={{ position: 'relative' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
