import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useUserStore } from '../../store';
import CarrierRegistrationData from '../../components/Carrier/CarrierRegistrationData';
import { formValidator } from '../../helpers';
import { VALIDATION_TEMPLATE } from '../../helpers/formValidator';
import {
  updateSubjectEmailPasswordRequest,
  updateSubjectRequest,
} from '../../api/subjectApi';
import { useApi, usePushNotification, useTranslateSubjectAttributes } from '../../hooks';
import { DEFAULT_ENUM_OPTION } from '../../constants/enums';
import { getMemberRequest } from '../../api/userApi';

const initialUserInfo = {
  email: '',
  phone: '',
  password: '',
  old_password: '',
  password_confirmation: '',
  registry_address: '',
  actual_address: '',
  subject_type: { ...DEFAULT_ENUM_OPTION },
  enterprise_activity_types: [],
  waste_management_type_id: { ...DEFAULT_ENUM_OPTION },
  first_name: '',
  full_name: '',
  last_name: '',
  member_name: '',
  member_phone: '',
  job_title: '',
};

const initialEditField = {
  phone: false,
  email: false,
  registry_address: false,
  actual_address: false,
  subject_type: false,
  member_name: false,
  job_title: false,
  member_phone: false,
};

export default function CarrierGeneralInfoPage() {
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [editField, setEditField] = useState(initialEditField);
  const { userStore, setUserStore } = useUserStore();
  const [error, setError] = useState({});
  const pushNotification = usePushNotification();

  const { translateSubject } = useTranslateSubjectAttributes({ setter: setUserInfo });

  const updateCarrier = async (fields) => {
    const { hasErrors, validField } = formValidator({
      form: userInfo,
      checkOnlyFields: fields,
      ignoreInputs: ['full_name'],
      customRules: [
        { name: VALIDATION_TEMPLATE.password },
        { name: VALIDATION_TEMPLATE.password_confirmation },
        { name: VALIDATION_TEMPLATE.email },
      ],
      inputRule: {
        password: [VALIDATION_TEMPLATE.password],
        password_confirmation: [VALIDATION_TEMPLATE.password_confirmation],
        old_password: [VALIDATION_TEMPLATE.password],
      },
    });

    setError(validField);

    if (hasErrors) return;

    const form = {};
    const newEditFields = {};

    fields.forEach((innerField) => {
      if (userInfo[innerField]?.label !== undefined) {
        form[innerField] = userInfo[innerField].value;
        return;
      }
      form[innerField] = userInfo[innerField];
      newEditFields[innerField] = false;
    });

    setEditField((prevState) => ({
      ...prevState,
      ...newEditFields,
    }));

    if (fields.includes('password') || fields.includes('email')) {
      const newSubject = await updateSubjectEmailPasswordRequest(userInfo.id, form);

      if (newSubject?.data?.data?.id) {
        pushNotification({ message: 'Пароль успешно обновлён', variant: 'success' });
      } else {
        pushNotification({ message: 'Не удалось обновить пароль' });
      }

      return;
    }

    await updateSubjectRequest(userInfo.id, form);
  };

  const changeEdit = (field, value) => {
    if (!value) translateSubject();

    setEditField((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const {
    makeRequest: updateSingleSubjectField,
    isLoading: updateSubjectLoading,
  } = useApi({
    request: updateCarrier,
    errorCallback: () => translateSubject(),
  });

  const onChange = ({ target: { name } }, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { makeRequest: getMember, isLoading: isLoadingMember } = useApi({
    request: async (shouldSetValue = true) => {
      const userData = await getMemberRequest();

      if (shouldSetValue) {
        setUserStore(userData);

        translateSubject(userData);
      }
    },
  });

  useEffect(() => {
    getMember();
    /* eslint-disable-next-line */
  }, []);

  const subject_type = useMemo(() => userStore.subject_type_attributes?.attributes?.code, [userStore]);

  return (
    <Box py={2}>
      <Typography mb={5} fontSize="26px">{userStore?.name}</Typography>
      <CarrierRegistrationData
        isLoading={isLoadingMember || updateSubjectLoading}
        onChange={onChange}
        userInfo={userInfo}
        editField={editField}
        changeEdit={changeEdit}
        getMember={getMember}
        subject_type={subject_type}
        makeRequest={updateSingleSubjectField}
        error={error}
      />
    </Box>
  );
}
