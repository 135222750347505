// @ts-nocheck
import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TEXT_TITLE } from '../constants/colors';

function NavTabs({
  tab, setTab, tabsComponents, sx, getErrorTab,
}) {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getTabSx = (id) => {
    const hasError = getErrorTab?.(`${id}`);

    return ({ color: hasError ? 'red !important' : TEXT_TITLE, fontSize: '16px' });
  };

  if (!tabsComponents.length) return null;

  return (
    <Box sx={sx}>
      <Tabs
        visibleScrollbar
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={tab || false}
        onChange={handleChange}
        aria-label="nav tabs example"
      >
        {tabsComponents.map((item) => (item?.hide?.() ? null : (
          <Tab
            key={item.name}
            label={item.label}
            sx={getTabSx(item.name)}
            value={item.name}
          />
        )))}
      </Tabs>

      {tabsComponents.map(({ value, name }) => {
        if (name !== tab || !value) return null;

        return <React.Fragment key={name}>{value}</React.Fragment>;
      })}
    </Box>
  );
}

export default memo(NavTabs);
