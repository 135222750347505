// @ts-nocheck
import { Book } from '@mui/icons-material';
import { Alert, Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { METHODS_API } from '../../api/api';
import { notificationsRequest } from '../../api/emailsSubscriptionsApi';
import { loginSubjectRequest } from '../../api/subjectApi';
import CustomButton from '../../components/Buttons/CustomButton';
import ModalWithTitle from '../../components/Modals/ModalWithTitle';
import { checkCadastrReady } from '../../constants/lkCadastr/cadastrStorage';
import { CADASTR_GENERAL_INFO_ROUTE, CADASTR_PERSONAL_ACCOUNT_ROUTE } from '../../constants/routes';
import { useApi, useLoadCadastreRecommendation } from '../../hooks';
import LoginPage from '../Public/LoginPage';
import CadastrNormativeBase from './CadastrNormativeBase';

function ModalRecommendation({ isOpen, close }) {
  const [files, setFiles] = useState([]);

  const { isLoading } = useLoadCadastreRecommendation({ setFiles });

  if (isLoading) return <CircularProgress />;

  return (
    <ModalWithTitle containerSx={{ maxHeight: '100vh', height: '100vh' }} open={isOpen} close={close} mainSx={{ height: '85vh' }}>
      <Box flex="1 1 0" height="100%">
        <Box component="iframe" width="100%" height="100%" title="Приказ 3" src={files[0]?.file_url} />
      </Box>
    </ModalWithTitle>
  );
}

function ModalNormativeBase({ isOpen, close }) {
  return (
    <ModalWithTitle containerSx={{ maxHeight: '100vh', height: '100vh' }} open={isOpen} close={close} mainSx={{ height: '85vh' }}>
      <Box flex="1 1 0" height="100%">
        <CadastrNormativeBase />
      </Box>
    </ModalWithTitle>
  );
}

export default function CadastrLoginPage() {
  const navigate = useNavigate();
  const [isOpenRecommendation, setIsOpenRecommendation] = useState(false);
  const [openedNormativeBase, openNormativeBase] = useState(false);
  const [recommendationText, setRecommendationText] = useState('');

  useApi({
    request: () => notificationsRequest({ method: METHODS_API.GET, params: { code: 'cadastre_notification' } }),
    setter: (data) => {
      setRecommendationText(data?.[0]?.name);
    },
    shouldRequest: true,
  });

  const recommendationButton = (
    <CustomButton
      color="warning"
      variant="text"
      startIcon={<Book />}
      sx={{ position: 'absolute', bottom: '-80px' }}
      onClick={() => setIsOpenRecommendation(true)}
    >
      Рекомендации по заполнению Кадастр отходов
    </CustomButton>
  );

  const recommendationTextComponent = !recommendationText ? null : <Alert sx={{ position: 'absolute', top: '-140px' }} severity="warning">{recommendationText}</Alert>;

  const restComponent = (
    <>
      {recommendationTextComponent}
      {recommendationButton}
      <CustomButton
        color="warning"
        variant="text"
        startIcon={<Book />}
        sx={{ position: 'absolute', bottom: '-120px' }}
        onClick={() => openNormativeBase(true)}
      >
        Нормативная база
      </CustomButton>
    </>
  );

  return (
    <>
      <ModalRecommendation isOpen={isOpenRecommendation} close={() => setIsOpenRecommendation(false)} />
      <ModalNormativeBase isOpen={openedNormativeBase} close={() => openNormativeBase(false)} />
      <LoginPage
        loginRequest={loginSubjectRequest}
        restComponent={restComponent}
        forgotPasswordParams="cadastre"
        redirectUrl={(subjectData) => {
          if (checkCadastrReady(subjectData)) {
            navigate(CADASTR_PERSONAL_ACCOUNT_ROUTE);
          } else {
            navigate(CADASTR_GENERAL_INFO_ROUTE);
          }
        }}
      />
    </>
  );
}
