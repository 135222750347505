// @ts-nocheck
import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomInput from '../../../components/Inputs';
import { useApi } from '../../../hooks';
import {
  PUBLIC_FORGOT_PASSWORD_ROUTE,
} from '../../../constants/routes';
import { compareId, formValidator } from '../../../helpers';
import BackButton from '../../../components/BackButton';
import CustomButton from '../../../components/Buttons/CustomButton';
import { resetPasswordRequest } from '../../../api/subjectApi';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../../helpers/formValidator';
import { SUBJECTS_URL, USERS_URL } from '../../../constants/urls';
import { subjectTypes, userTypes } from './ForgotPasswordPage';

const paperStyle = {
  width: '420px', p: '56px', display: 'flex', flexDirection: 'column', position: 'relative',
};

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user_type = searchParams.get('user_type');
  const reset_password_token = searchParams.get('reset_password_token');
  const [newPassword, setNewPassword] = useState({
    password: '',
    password_confirmation: '',
  });
  const [error, setError] = useState('');

  const changeEmail = ({ target: { name } }, value) => {
    setNewPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError('');
  };

  const isUser = userTypes.some(({ name }) => compareId(name, user_type));

  const mainItem = isUser
    ? userTypes.find((item) => compareId(item.name, user_type))
    : subjectTypes.find((item) => compareId(item.name, user_type));

  const resetPassword = async () => {
    const { hasErrors, validField } = formValidator({
      form: newPassword,
      customRules: [
        { name: 'password' },
        { name: 'password_confirmation' },
      ],
      inputRule: {
        password: ['password'],
        password_confirmation: ['password_confirmation'],
      },
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    await resetPasswordRequest({
      reset_password_token,
      ...newPassword,
      url: isUser ? USERS_URL : SUBJECTS_URL,
      user_type: isUser ? 'user' : 'subject',
    });
    navigate(mainItem.url || -1);
  };

  const { makeRequest: onResetPassword } = useApi({
    request: resetPassword,
    errorCallback: () => navigate(PUBLIC_FORGOT_PASSWORD_ROUTE),
    successMessage: 'Пароль сохранён',
  });

  return (
    <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Paper sx={paperStyle}>
        <BackButton path={mainItem.url || -1} />
        <CustomInput
          sx={{ mb: 2 }}
          name="password"
          type="password"
          label="Введите новый пароль"
          value={newPassword.password}
          error={error.password}
          onChange={changeEmail}
        />
        <CustomInput
          sx={{ mb: 2 }}
          label="Повторите новый пароль"
          type="password"
          name="password_confirmation"
          value={newPassword.password_confirmation}
          error={error.password_confirmation}
          onChange={changeEmail}
        />
        <CustomButton variant="contained" onClick={onResetPassword}>Сохранить новый пароль</CustomButton>
      </Paper>
    </Box>
  );
}
