import React, { useState } from 'react';
import ModalWithTitle from '../ModalWithTitle';
import BlockModalWasteCarrier from './BlockModalWasteCarrier';
import ModalVehicleInfo from '../Carrier/ModalVehicleInfo';
import { ModalApplicationInfo } from '../ModalApplication/ModalApplication';
import ModalViolation from './ModalViolation';
import { SUBJECT_TYPE_ENUM, WASTE_MANAGEMENT_ENUM } from '../../../constants/enums';

const initialSubjectInfo = [
  {
    title: 'Вид деятельности:',
    field: WASTE_MANAGEMENT_ENUM,
    getField: (({
      waste_management_type_attributes,
    }) => waste_management_type_attributes?.attributes?.name),
  },
  {
    title: 'Тип субъекта:',
    field: SUBJECT_TYPE_ENUM,
    getField: (({ subject_type_attributes }) => subject_type_attributes?.attributes?.name),
  },
  { title: 'Вид предприятия:', field: 'enterprise_type_text' },
  { title: 'Адрес регистрации:', field: 'registry_address' },
  { title: 'Фактический адрес:', field: 'actual_address' },
  { title: 'Номер телефона:', field: 'phone' },
  { title: 'E-mail:', field: 'email' },
  { title: 'ИНН:', field: 'inn' },
  { title: 'ОГРН:', field: 'ogrn' },
  { title: 'КПП:', field: 'kpp' },
  { title: 'ОКВЭД ', field: 'okved' },
];

export default function ModalWasteCarrier({ close, subject }) {
  const [modal, setModal] = useState({});
  const [violations, setViolations] = useState();

  const openModal = (rowInfo) => {
    setModal(rowInfo);
  };

  const closeModal = () => {
    setModal({});
  };

  return (
    <ModalWithTitle
      open
      title={`id ${subject.id}`}
      subTitle={subject.name}
      containerSx={{ width: '75vw' }}
      close={close}
    >
      <BlockModalWasteCarrier
        violations={violations}
        setViolations={setViolations}
        subject={subject}
        open={openModal}
        fields={initialSubjectInfo}
      />
      {modal.type === 'vehicle' && <ModalVehicleInfo close={closeModal} vehicle={modal} />}
      {modal.type === 'application' && <ModalApplicationInfo close={closeModal} id={modal.id} />}
      {modal.type === 'violation' && (
        <ModalViolation close={close} violation={modal} />
      )}
    </ModalWithTitle>

  );
}
