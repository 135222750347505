import React from 'react';
import { Button } from '@mui/material';

export default function Submit({
  value, sx, onClick, type = 'button',
}) {
  return (
    <Button
      sx={sx}
      variant="contained"
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
      type={type}
    >
      {value}
    </Button>
  );
}
