import {
  FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup,
} from '@mui/material';
import React from 'react';
import { BUTTON_BLUE } from '../constants/colors';
import { getError } from '../helpers/formValidator';
import { TextMain, TextSignature } from './Text';

export default function CustomRadioGroup({
  value, onChange, options, label, subTitle, name, error, required = false, sx = {}, disabled = false, validImmediately = false,
}) {
  const errorText = error || getError({ value, validImmediately });
  const errorClassname = errorText ? 'error' : '';
  const requiredClassName = required ? ' required' : '';

  return (
    <FormControl disabled={disabled} sx={sx}>
      {label && <FormLabel error={Boolean(errorText)}>{label}</FormLabel>}
      {subTitle && <TextSignature>{subTitle}</TextSignature>}
      <RadioGroup
        row
        value={value}
        className={`${errorClassname}${requiredClassName}`}
        onChange={(e) => {
          onChange(e, e.target.value);
        }}
      >
        {options.map(({ label: optionLabel, value: radioValue }) => (
          <FormControlLabel
            name={name}
            disabled={disabled}
            key={optionLabel}
            value={radioValue}
            label={<TextMain medium>{optionLabel}</TextMain>}
            sx={{
              width: 'fit-content',
              '.MuiSvgIcon-root:first-child': {
                color: '#E9EDF3',
              },
              '.Mui-checked .MuiSvgIcon-root:first-child': {
                color: BUTTON_BLUE,
              },
              '&.error': {
                '& .MuiFormControlLabel-label': {
                  color: ({ palette }) => palette.error.main,
                },
              },
            }}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      {errorText && <FormHelperText sx={{ ml: '29px' }} error={Boolean(errorText)}>{errorText}</FormHelperText>}
    </FormControl>
  );
}
