import React from 'react';
import { Box } from '@mui/material';
import style from './slider.module.css';
import CloseModalButton from '../../../Buttons/CloseModalButton';

function InfoSlider({ onClose, children }) {
  return (
    <Box className={style.info_wrapper}>
      {onClose && <CloseModalButton close={onClose} />}
      <Box display="flex" flexDirection="column" gap={1}>
        {children}
      </Box>
    </Box>
  );
}

export default InfoSlider;
