export const sortByField = ({
  items, field, isASC, isDate, hasAttributes,
}) => (!items ? [] : items?.sort?.((first, second) => {
  const a = hasAttributes ? first?.attributes : first;
  const b = hasAttributes ? second?.attributes : second;

  const finalFirst = isDate ? new Date(a[field]) : a[field];
  const finalSecond = isDate ? new Date(b[field]) : b[field];

  if (isASC) {
    return finalFirst - finalSecond;
  }
  return finalSecond - finalFirst;
}) || []);

export const sortByCreatedAt = (items, hasAttributes) => sortByField({
  items,
  isDate: true,
  isASC: true,
  field: 'created_at',
  hasAttributes,
});
