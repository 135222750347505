export const DISTRICTS_NAMES = [
  'Бокситогорский',
  'Волосовский',
  'Волховский',
  'Всеволожский',
  'Выборгский',
  'Гатчинский',
  'Кингисеппский',
  'Киришский',
  'Кировский',
  'Лодейнопольский',
  'Ломоносовский',
  'Лужский',
  'Подпорожский',
  'Приозерский',
  'Сланцевский',
  'Сосновоборский',
  'Тихвинский',
  'Тосненский',
];

export const DISTRICTS_OPTIONS = DISTRICTS_NAMES.map((item) => ({ label: item, id: item, value: item }));
