import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../Text';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import { AsyncAutocomplete } from '../../../AutoCompletes/AsyncAutocomplete';
import AddButton from '../../../Buttons/AddButton';
import {
  EQUIPMENTS_NAME,
  getInitialTechnology,
  MANUFACTURED_PRODUCTS_NAME,
  MONITORING_KINDS_NAME,
  SECONDARY_PRODUCTS_NAME,
  TECHNOLOGIES_NAME,
  WASTE_ITEMS_NAME,
} from '../../../../constants/lkCadastr/cadastrForm7';
import {
  fitContent,
  flexGap2,
  flexRowGap2,
  fullWidth,
  SCROLL_CONTAINER_SX,
} from '../../../../constants/lkCadastr/cadastrStorage';
import { Equipments } from './Equipments';
import { WasteItems } from './WasteItems';
import { ManufacturedProducts } from './ManufacturedProducts';
import { SecondaryProducts } from './SecondaryProducts';
import { MonitoringKinds } from './MonitoringKinds';
import NavTabs from '../../../Tabs';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import CustomAccordion from '../../../CustomAccordion';
import { getTechnologyEnumsRequest } from '../../../../api/cadastreApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../Inputs/CustomAutocomplete';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';
import { compareId } from '../../../../helpers';

export function Technologies({
  technologies, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: technologies, tabName: 'Технология' });

  return (
    <>
      <Box sx={flexRowGap2}>
        <TextTitle h3>Технологии</TextTitle>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeItem(TECHNOLOGIES_NAME, [...technologies, getInitialTechnology()])}
          >
            Добавить технологию
          </AddButton>
        )}
      </Box>
      <NavTabs tabsComponents={tabs} tab={tab} setTab={setTab} />
      <Box sx={{ ...SCROLL_CONTAINER_SX, height: '55vh' }}>
        {technologies.map((technology, technologyIndex) => {
          if (tab !== technology.randId) return null;

          const changeTechnology = (field, value, type, innerIndexProp) => changeItem(
            `${TECHNOLOGIES_NAME}.${technologyIndex}.${field}`,
            value,
            type,
            {
              technologyIndex,
              ...innerIndexProp,
            },
          );

          const onDeleteTechnology = async () => {
            if (technology?.id) {
              const deletedTechnology = await deleteForm7Entity({ id: technology.id, apiType: TECHNOLOGIES_NAME });

              if (!deletedTechnology) return;
            }

            const cloneArray = technologies.slice();
            cloneArray.splice(technologyIndex, 1);

            changeItem(
              TECHNOLOGIES_NAME,
              cloneArray,
              'delete',
            );
          };

          return (
            <Box key={technology.randId} sx={flexGap2}>
              <Box display="flex" alignItems="center" mb={1} gap={3}>
                {!isDemo && technologies.length > 1 && (
                  <DeleteButton onClick={() => openDeleteModal(onDeleteTechnology, technologyIndex, 'Технологию')}>
                    Удалить технологию
                  </DeleteButton>
                )}
              </Box>
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="Наименование технологии"
                value={technology.name}
                onChange={(e, value) => changeTechnology('name', value)}
              />
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="Год разработки"
                value={technology.develop_year}
                onChange={(e, value) => changeTechnology('develop_year', value)}
              />
              <AsyncAutocomplete
                canBeZero
                validImmediately={!compareId(technology.technology_purpose?.label, 0)}
                debounceTime={0}
                disabled={isDemo}
                sx={fullWidth}
                label="Назначение технологии"
                value={technology.technology_purpose}
                optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                request={(params) => getTechnologyEnumsRequest('technology_purpose', params)}
                onChange={(e, value) => changeTechnology('technology_purpose', value)}
              />
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                label="Краткое описание технологии"
                value={technology.short_description}
                onChange={(e, value) => changeTechnology('short_description', value)}
              />
              <Box sx={flexGap2}>
                <TextTitle h3>Разработчик технологии</TextTitle>
                <TextTitle>
                  Наименование юридического лица
                  (ФИО индивидуального предпринимателя)
                </TextTitle>
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  value={technology.developer_name}
                  label="Наименование или ФИО"
                  sx={fitContent}
                  onChange={(e, value) => changeTechnology('developer_name', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="Юридический адрес"
                  sx={fullWidth}
                  value={technology.legal_address}
                  onChange={(e, value) => changeTechnology('legal_address', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  sx={fullWidth}
                  label="Фактический адрес"
                  value={technology.actual_address}
                  onChange={(e, value) => changeTechnology('actual_address', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="ИНН"
                  value={technology.developer_inn}
                  onChange={(e, value) => changeTechnology('developer_inn', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="КПП"
                  value={technology.developer_kpp}
                  onChange={(e, value) => changeTechnology('developer_kpp', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="Код ОКТМО"
                  value={technology.developer_oktmo}
                  sx={{ width: '100%' }}
                  onChange={(e, value) => changeTechnology('developer_oktmo', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  value={technology.developer_ogrn}
                  onChange={(e, value) => changeTechnology('developer_ogrn', value)}
                  sx={fullWidth}
                  label="ОГРН"
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="ОКВЭД"
                  value={technology.developer_okved}
                  onChange={(e, value) => changeTechnology('developer_okved', value)}
                />
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="ОКПО"
                  value={technology.developer_okpo}
                  onChange={(e, value) => changeTechnology('developer_okpo', value)}
                />
              </Box>
              <Box sx={flexGap2}>
                <TextTitle h3>Назначение объекта</TextTitle>
                <CustomAccordion title="Используемые установки">
                  <Equipments
                    isDemo={isDemo}
                    openDeleteModal={openDeleteModal}
                    equipments={technology[EQUIPMENTS_NAME]}
                    changeItem={changeTechnology}
                  />
                </CustomAccordion>
                <CustomAccordion title="Обрабатываемые/обезвреживаемые/утилизируемые отходы">
                  <WasteItems
                    isDemo={isDemo}
                    openDeleteModal={openDeleteModal}
                    wasteItems={technology[WASTE_ITEMS_NAME]}
                    changeItem={changeTechnology}
                  />
                </CustomAccordion>
                <CustomAccordion title="Производимая продукция">
                  <ManufacturedProducts
                    isDemo={isDemo}
                    openDeleteModal={openDeleteModal}
                    manufacturedProducts={technology[MANUFACTURED_PRODUCTS_NAME]}
                    changeItem={changeTechnology}
                  />
                </CustomAccordion>
                <CustomAccordion title="Вторичные отходы">
                  <SecondaryProducts
                    isDemo={isDemo}
                    openDeleteModal={openDeleteModal}
                    secondaryProducts={technology[SECONDARY_PRODUCTS_NAME]}
                    changeItem={changeTechnology}
                  />
                </CustomAccordion>
                <CustomAccordion title="Виды мониторинга окружающей среды на объекте">
                  <MonitoringKinds
                    isDemo={isDemo}
                    openDeleteModal={openDeleteModal}
                    monitoringKinds={technology[MONITORING_KINDS_NAME]}
                    changeItem={changeTechnology}
                  />
                </CustomAccordion>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
