// @ts-nocheck
import { Box } from '@mui/material';
import React from 'react';
import { getObjectivesRequest } from '../../api/objectivesApi';
import { ENTERPRISE_ACTIVITY_TYPE_IDS } from '../../constants/enums';
import { CONTAINER_MATERIALS, getInitialContainer } from '../../constants/lkCadastr/cadastrForm1';
import { fitContent, flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { formatObjectWithAddress } from '../../helpers/stringHelper';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import CustomInput from '../Inputs';
import Selector from '../Selectors/Selector';
import { Text } from '../Text';

export function CadastreForm1Platform({
  platform,
  index,
  onDeletePlatform,
  buildingIndex,
  openDeleteModal,
  isDemo,
  building,
  fullWidth,
  enterpriseAccumulationParam,
  objectTypeIds,
  setCreateObjectIndex,
  changePlatform,
  changeItemFormByField,
  deleteForm1Entity,
}) {
  return (
    <Box sx={flexGap2} key={platform.randId}>
      <Box display="flex" alignItems="center" mb={1} gap={2}>
        <Text>
          Площадка №
          {index + 1}
        </Text>
        {!isDemo && building.platforms.length > 0 && (
          <DeleteButton
            onClick={() => openDeleteModal(onDeletePlatform, index, 'Площадку')}
          >
            Удалить площадку
          </DeleteButton>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" gap={3}>
        <AsyncAutocomplete
          validImmediately
          required
          debounceTime={0}
          disabled={isDemo}
          useDefaultFilter
          label="Адрес"
          sx={fullWidth}
          containerSx={fullWidth}
          request={(params) => getObjectivesRequest({
            params: {
              ...params,
              [ENTERPRISE_ACTIVITY_TYPE_IDS]: enterpriseAccumulationParam,
              objective_type_ids: objectTypeIds?.join?.(','),
            },
          })}
          additionalFilter={(options) => options.map((option) => ({
            ...option,
            label: formatObjectWithAddress({ objective: option }),
          }))}
          optionField={{
            label: 'label',
          }}
          noOptionComponent={(
            <AddButton
              variant="outlined"
              key="add-button"
              onClick={() => setCreateObjectIndex({ buildingIndex, platformIndex: index })}
            >
              Создать объект
            </AddButton>
          )}
          value={platform.objective_id}
          onChange={(e, value) => changePlatform('objective_id', value)}
        />
        <CustomInput
          validImmediately
          debounceTime={0}
          disabled={isDemo}
          label="Номер площадки"
          sx={fitContent}
          value={platform.area_number}
          onChange={(e, value) => changePlatform('area_number', value)}
        />
      </Box>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => {
            changeItemFormByField({
              field: `form1.items.${buildingIndex}.platforms.${index}.containers`,
              value: [...platform.containers, getInitialContainer()],
            });
          }}
        >
          Добавить контейнер
        </AddButton>
      )}
      {platform.containers.map((container, containerIndex) => {
        const onDeleteContainer = async () => {
          if (container.id) {
            const deletedId = await deleteForm1Entity({ containerId: container.id });

            if (!deletedId) return;
          }

          const clonePlatforms = platform.containers.slice();
          clonePlatforms.splice(containerIndex, 1);

          changeItemFormByField({
            field: `form1.items.${buildingIndex}.platforms.${index}.containers`,
            value: clonePlatforms,
            type: 'delete',
          });
        };

        const changeContainer = (field, value) => changePlatform(`containers.${containerIndex}.${field}`, value);

        return (
          <Box sx={flexRowGap2} key={container.randId}>
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Количество контейнеров"
              sx={fitContent}
              value={container.container_quantity}
              onChange={(e, value) => changeContainer('container_quantity', value)}
            />
            <Selector
              validImmediately
              disabled={isDemo}
              label="Материал контейнера"
              sx={{ width: '220px' }}
              value={container.container_material}
              options={CONTAINER_MATERIALS}
              onChange={(e, value) => changeContainer('container_material', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              type="number"
              label="Вместимость контейнера"
              sx={fitContent}
              value={container.container_capacity}
              onChange={(e, value) => changeContainer('container_capacity', value)}
            />
            <Box display="flex" alignItems="center" mb={1} gap={2}>
              {!isDemo && platform.containers.length > 1 && (
                <DeleteButton
                  onClick={() => openDeleteModal(onDeleteContainer, containerIndex, 'Контейнер')}
                >
                  Удалить контейнеры
                </DeleteButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
