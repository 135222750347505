import React from 'react';
import { Box } from '@mui/material';
import { TextMain, TextSignature } from './Text';
import useCustomMediaQuery from '../hooks/useCustomMediaQuery';

export default function TitleSubtitle({
  title, subTitle, sx, subTitleSx, noValueText = 'Нет',
}) {
  const { isMobileTablet } = useCustomMediaQuery();

  const mobileTabletDirection = isMobileTablet ? { display: 'flex', gap: 1 } : {};

  return (
    <Box sx={{ ...sx, ...mobileTabletDirection }}>
      {typeof title === 'string' ? <TextSignature medium>{title}</TextSignature> : title}
      {typeof subTitle === 'string' ? <TextMain big sx={subTitleSx}>{subTitle}</TextMain> : subTitle || noValueText}
    </Box>
  );
}
