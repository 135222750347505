import React from 'react';
import { Box } from '@mui/material';
import { PETROSOFT_BEARER_TOKEN } from '../../constants/urls';

export default function AdminWasteCadastrePage() {
  return (
    <Box sx={{ width: '100%', height: '100%', pt: 3 }}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        component="iframe"
        title="waste_cadastre"
        src={`http://tsoo.dev.plicante.ru/operator-ui/?aistoken=${PETROSOFT_BEARER_TOKEN}`}
      />
    </Box>
  );
}
