import React from 'react';
import { Button, Tooltip, Typography } from '@mui/material';

export default function CustomIconButton({
  children, onClick, sx, color, Icon, variant, name, href, download, target, title = '',
}) {
  return (
    <Tooltip disableInteractive title={title}>
      <Button
        download={download}
        target={target}
        name={name}
        variant={variant}
        href={href}
        color={color}
        sx={{
          display: 'flex', alignItems: 'center', '& *': { pointerEvents: 'none' }, borderRadius: '8px', ...sx,
        }}
        onClick={onClick}
      >
        <Icon />
        {children && <Typography ml={1}>{children}</Typography>}
      </Button>
    </Tooltip>
  );
}
