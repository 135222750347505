// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Place } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { subMinutes } from 'date-fns';
import CustomTable from '../../components/Tables/CustomTable';
import NavTabs from '../../components/Tabs';
import { useApi, useTableSearch } from '../../hooks';
import CustomIconButton from '../../components/Buttons/CustomIconButton';
import LeafletMap from '../../components/Map/LeafletMap';
import { TextTitle } from '../../components/Text';
import CustomDatePicker from '../../components/CustomDatePicker';
import { formatToServerDate, getDayMonthNameYearTime, getDistanceDate } from '../../helpers/dateHelper';
import { formatAddressString } from '../../helpers/stringHelper';
import CustomButton from '../../components/Buttons/CustomButton';
import { formValidator } from '../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import {
  deleteConcentrationPointRequest,
  getConcentrationPointsRequest,
  getParkingDeviationsRequest,
  getRouteDeviationsRequest,
} from '../../api/deviationsApi';
import RedHouse from '../../assets/map-marker-red.svg';
import { getApplicationsTerSchemeRequest } from '../../api/applicationsApi';
import ModalApplication from '../../components/Modals/ModalApplication/ModalApplication';
import { getParamsFromObject } from '../../helpers/objectHelper';
import BackdropLoading from '../../components/BackdropLoading';
import { useTableStore } from '../../store';
import CustomCheckbox from '../../components/CustomCheckbox';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { useAdminRole } from '../../hooks/useAdminRole';

const initialTableConcentrationPoints = [
  {
    headerTitle: 'Код',
    field: 'id',
  },
  {
    headerTitle: 'Дата и время появления концентрации',
    field: 'created_at',
    getField: ({ created_at }) => getDayMonthNameYearTime(created_at),
  },
  {
    headerTitle: 'Координаты места концентрации',
    field: 'deviation_place',
    getField: (row) => (row.place?.[0] ? `${row.place?.[0]} ${row.place?.[1]}` : ''),
  },
];

const initialTableParkingDeviations = [
  { headerTitle: 'ЕГРЗ', field: 'EGRZ', getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz },
  {
    headerTitle: 'Владелец ТС',
    field: 'subject',
    getField: ({
      vehicle_attributes,
    }) => vehicle_attributes?.attributes?.subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Адрес места остановки',
    field: 'address_parking',
    getField: ({
      vehicle_attributes,
    }) => vehicle_attributes?.attributes?.subject_attributes?.attributes?.actual_address,
  },
  {
    headerTitle: 'Кадастровый номер участка',
    field: 'cadastral_address',
    getField: ({
      vehicle_attributes,
    }) => vehicle_attributes?.attributes?.subject_attributes?.attributes?.actual_address,
  },
  {
    headerTitle: 'Муниципальное образование',
    field: 'address_municipality',
    getField: ({
      vehicle_attributes,
    }) => vehicle_attributes?.attributes?.subject_attributes?.attributes?.registry_address,
  },
  { headerTitle: 'Дата и время остановки', field: 'date_time', getField: ({ time }) => getDayMonthNameYearTime(time) },
  {
    headerTitle: 'Координаты места остановки',
    field: 'deviation_place',
    getField: (row) => (row.deviation_place?.[0] ? `${Number(row.deviation_place?.[0])?.toFixed?.(2)} ${Number(row.deviation_place?.[1])?.toFixed?.(2)}` : ''),
  },
];

const trianglePartLength = 0.00500;

const getTriangleCoords = (coords) => {
  const place = coords.slice().reverse();

  const topCorner = [place[0] + (trianglePartLength / 2), place[1]];
  const rightCorner = [place[0] - (trianglePartLength / 2), place[1] - trianglePartLength];
  const leftCorner = [place[0] - (trianglePartLength / 2), place[1] + trianglePartLength];

  return [topCorner, rightCorner, leftCorner];
};

const initialTableTerScheme = [
  { headerTitle: 'ЕГРЗ', field: 'EGRZ', getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz },
  {
    headerTitle: 'Субъект, отсутствующий в территориальной схеме',
    field: 'no_ter_scheme_subject_name',
    getField: ({ objective_attributes }) => objective_attributes
      ?.attributes
      ?.subject_attributes
      ?.attributes?.name,
  },
  {
    headerTitle: 'Субъект, транспортирующий отходы',
    field: 'recipient_subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Адрес объекта получателя',
    field: 'receiver_object',
    getField: ({ objective_attributes }) => formatAddressString(
      objective_attributes
        ?.attributes?.address_attributes?.attributes,
    ),
  },
  {
    headerTitle: 'Дата и время',
    field: 'date_time',
    getField: ({ created_at }) => getDayMonthNameYearTime(created_at),
  },
];

const tabsComponents = [
  { name: 'route_deviations', label: 'Съезд с трассы' },
  { name: 'parking_deviations', label: 'Места концентрации' },
  { name: 'ter_scheme', label: 'Объекты вне тер.схемы.' },
];

const getDeviationLngLat = (data) => data.map((info) => ({
  ...info,
  place: info?.deviation_place,
  longitude: info?.deviation_place?.[0],
  latitude: info?.deviation_place?.[1],
  deviation_track: info?.deviation_track,
  tooltip: info.vehicle_attributes?.attributes?.egrz || getDayMonthNameYearTime(info.time),
}));

// eslint-disable-next-line func-names
const routeDeviationIcon = (boundsDeviation) => function (info) {
  return (
    <CustomIconButton
      Icon={Place}
      onClick={(e) => {
        e.stopPropagation();
        boundsDeviation(info, true);
      }}
    />
  );
};

const getConcentrationPageInfo = (getParkingPoints) => async (params, setTable) => {
  getParkingPoints({ search: params.search || undefined }, setTable);

  return getConcentrationPointsRequest(params, setTable);
};

const initialDateFilter = { date_from: undefined, date_to: undefined };

export default function DeviationPage() {
  const { setTableStore, tableStore } = useTableStore();
  const navigate = useNavigate();
  const [terSchemeObject, setTerSchemeObject] = useState();
  const [tableData, setTableData] = useState([]);
  const [tab, setTab] = useState(tabsComponents[0].name);
  const [outerMap, setOuterMap] = useState();
  const [parkingPoints, setParkingPoints] = useState([]);
  /* eslint-disable-next-line */
  const [totalParkingPoints, setTotalParkingPoints] = useState(0);
  const [pageParkingPoints, setPageParkingPoints] = useState(1);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [with_deleted, setWithDeleted] = useState(false);
  const [error, setError] = useState({});
  const [selectedConcentrationPlace, setSelectedConcentrationPlace] = useState();
  const [selectedParkingPlace, setSelectedParkingPlace] = useState();
  const [deviationRoutes, setDeviationRoutes] = useState();
  const [plannedRoutes, setPlannedRoutes] = useState();
  const [applicationDeviationRoutes, setApplicationDeviationRoutes] = useState();
  const [dateFilter, setDateFilter] = useState({ ...initialDateFilter });

  const isRouteDeviation = tab === 'route_deviations';
  const isParkingDeviation = tab === 'parking_deviations';
  const isTerScheme = tab === 'ter_scheme';

  const { isUserAdmin } = useAdminRole();

  const polygonOptions = useMemo(() => {
    if (isParkingDeviation && tableData.length) {
      const options = [];
      tableData
        .forEach(({ place, parking_deviations_ids }) => {
          const currentPlace = selectedConcentrationPlace?.place;
          if ((place && !selectedConcentrationPlace)
            || (
              currentPlace && currentPlace?.[0] === place?.[0] && currentPlace?.[1] === place?.[1]
            )
          ) {
            if (parking_deviations_ids?.length > 1) {
              options.push({
                polygonPoints: getTriangleCoords(place),
                color: parking_deviations_ids?.length > 5 ? 'red' : 'yellow',
              });
            }
          }
        });
      return options;
    }
  }, [isParkingDeviation, tableData, selectedConcentrationPlace]);

  const boundsDeviation = (info, shouldSetPlannedRoutes = false, setter = setDeviationRoutes) => {
    if (info?.deviation_track && outerMap?.fitBounds) {
      setter(info.deviation_track);

      if (shouldSetPlannedRoutes && info.application?.planned_routes?.length) {
        setPlannedRoutes(info.application.planned_routes);
      }
    }
  };

  const initialTableRouteDeviation = useMemo(() => [
    {
      headerTitle: 'Карта',
      field: 'map',
      getField: routeDeviationIcon(boundsDeviation),
    },
    {
      headerTitle: 'Дата выдачи разрешения',
      field: 'date',
      getField: ({ created_at }) => getDayMonthNameYearTime(created_at),
    },
    {
      headerTitle: 'Наименование субъекта',
      field: 'subject_name',
      getField: ({
        vehicle_attributes,
      }) => vehicle_attributes?.attributes?.subject_attributes?.attributes?.name,
    },
    {
      headerTitle: 'ЕГРЗ',
      field: 'EGRZ',
      getField: ({
        vehicle_attributes,
      }) => vehicle_attributes?.attributes?.egrz,
    },
    {
      headerTitle: 'Адрес объекта обращения с отходами',
      field: 'address_object',
      getField: ({
        vehicle_attributes,
      }) => vehicle_attributes?.attributes?.subject_attributes
        ?.attributes?.registry_address,
    },
    {
      headerTitle: 'Дата и время фиксации отклонения',
      field: 'date_time',
      getField: ({ time }) => getDayMonthNameYearTime(time),
    },
    {
      headerTitle: 'Продолжительность стоянки',
      field: 'parking_time',
      getField: () => getDistanceDate(subMinutes(new Date(), 5)),
    },
    {
      headerTitle: 'Координаты места остановки',
      field: 'deviation_place',
      getField: (row) => (row.deviation_track?.[0]?.[0] ? `${Number(row.deviation_track?.[0][0])?.toFixed?.(2)} ${Number(row.deviation_track?.[0].at(-1))?.toFixed?.(2)}` : ''),
    },
    /* eslint-disable-next-line */
  ], [outerMap]);

  const getColumns = () => {
    if (isRouteDeviation) return initialTableRouteDeviation;
    if (isParkingDeviation) return initialTableConcentrationPoints;
    if (isTerScheme) return initialTableTerScheme;
  };

  const {
    makeRequest: getParkingPoints,
    isLoading: isLoadingParkingPoints,
  } = useApi({
    setter: (parkingPlaces) => {
      setParkingPoints(getDeviationLngLat(parkingPlaces));
    },
    request: async ({
      concentration_point_id,
      search,
    } = {}) => getParkingDeviationsRequest(
      {
        concentration_point_id: concentration_point_id || undefined,
        search: search || undefined,
        total: concentration_point_id ? 10000 : undefined,
        page: pageParkingPoints,
        ...dateFilter,
      },
      (response) => setTotalParkingPoints(response?.meta?.total || 0),
    ),
    shouldRequest: isParkingDeviation,
    deps: [tab, pageParkingPoints, dateFilter],
  });

  const currentTabSearch = useMemo(() => {
    if (isRouteDeviation) return getRouteDeviationsRequest;
    if (isParkingDeviation) return getConcentrationPageInfo(getParkingPoints);
    if (isTerScheme) return getApplicationsTerSchemeRequest;
  }, [tab]);

  const nonTerSchemeParams = !isTerScheme ? dateFilter : {};
  const parkingDeviationParams = isParkingDeviation
    ? { with_deleted: with_deleted || undefined }
    : {};

  const { isLoading, makeRequest } = useTableSearch({
    search: currentTabSearch,
    setter: async (newData) => {
      const newTableData = isRouteDeviation ? getDeviationLngLat(newData) : newData;
      setTableData(newTableData);
    },
    params: { ...nonTerSchemeParams, ...parkingDeviationParams },
    deps: [tab, with_deleted, dateFilter],
  });

  const onGetDeviations = () => {
    const { hasErrors, validField } = formValidator({
      form: {
        dateTo,
        dateFrom,
      },
      generalRules: ['null', 'empty'],
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    setDateFilter({
      date_from: formatToServerDate(dateFrom),
      date_to: formatToServerDate(dateTo),
    });
  };

  const hasMap = useMemo(
    () => isRouteDeviation || isParkingDeviation,
    [isRouteDeviation, isParkingDeviation],
  );

  const changeWithDeleted = () => setWithDeleted(!with_deleted);

  const changeTab = (newTab) => {
    setSelectedConcentrationPlace();
    setSelectedParkingPlace();
    setWithDeleted(false);
    setDateTo(null);
    setDateFrom(null);
    setTab(newTab);
    setTableData([]);

    if (plannedRoutes?.length) setPlannedRoutes();
    if (deviationRoutes?.length) setDeviationRoutes();

    setParkingPoints([]);
  };

  const boundsItem = (row) => {
    if (isParkingDeviation) {
      if (row?.place && !row?.latitude) {
        setSelectedConcentrationPlace(row);
        outerMap.fitBounds(getTriangleCoords(row.place));
        setPageParkingPoints(1);
        getParkingPoints({ concentration_point_id: row.id });
      } else if (row?.longitude) {
        setSelectedParkingPlace(row);
        const parkingDeviation = [row.latitude, row.longitude];
        outerMap.fitBounds([parkingDeviation, parkingDeviation]);
      } else {
        setSelectedConcentrationPlace();
        setSelectedParkingPlace();
      }
    }
    if (isRouteDeviation) {
      boundsDeviation(row, false, setApplicationDeviationRoutes);
      // const deviationRouteDate = row.time ? new Date(row.time) : new Date();
      // const clearDate = new Date(
      //   deviationRouteDate.getFullYear(),
      //   deviationRouteDate.getMonth(),
      //   deviationRouteDate.getDate(),
      // );
      // navigate(getParamsFromObject({
      //   object: {
      //     modal: 'application',
      //     modalId: row.vehicle_attributes?.application_id,
      //     date_to: deviationRouteDate,
      //     date_from: clearDate,
      //   },
      // }));
    }
    if (isTerScheme) {
      setTerSchemeObject(row);
      navigate(getParamsFromObject({
        object: {
          modal: 'application',
          modalId: row.id,
        },
      }));
    }
  };

  const onCloseApplicationModal = () => setApplicationDeviationRoutes();

  useEffect(() => {
    if (dateTo === null && dateFrom === null) {
      setDateFilter({ ...initialDateFilter });
    }
  }, [dateTo, dateFrom]);

  const unSelectConcentration = () => setSelectedConcentrationPlace();

  const deleteRow = async ({ id }) => {
    await deleteConcentrationPointRequest(id);
    setTableStore({ page: 1 });
    makeRequest({ page: 1 });
  };

  const { isMobileTablet } = useCustomMediaQuery();

  const mainTableWidth = useMemo(() => {
    let mainWidth = '100%';

    if (hasMap && !isMobileTablet) mainWidth = '55vw';
    if (hasMap && isUserAdmin && !isMobileTablet) mainWidth = '47%';

    return mainWidth;
  }, [hasMap, isMobileTablet, isUserAdmin]);

  useEffect(() => {
    setTableData([]);
  }, [tableStore.page]);

  return (
    <Box>
      <BackdropLoading position="fixed" isLoading={isLoading || isLoadingParkingPoints} />
      <ModalApplication
        noTerSchemeObjects={terSchemeObject ? [terSchemeObject] : undefined}
        deviationRoutes={applicationDeviationRoutes}
        afterClose={onCloseApplicationModal}
      />
      <NavTabs tabsComponents={tabsComponents} tab={tab} setTab={changeTab} />
      <Box>
        {!isTerScheme && (
          <Box mb={3} mt={3}>
            <TextTitle sx={{ mb: 1 }}>Фильтрация отклонений</TextTitle>
            {isParkingDeviation && (
              <CustomCheckbox
                label="Включать архивные"
                onChange={changeWithDeleted}
                value={with_deleted}
              />
            )}
            <Box display="flex" gap={2} alignItems="flex-start" flexWrap="wrap">
              <CustomDatePicker
                error={error.dateFrom}
                placeholder="Период с:"
                name="formation_period_from"
                value={dateFrom}
                onChange={(e, value) => {
                  setError((prevState) => ({
                    ...prevState,
                    dateFrom: '',
                  }));
                  setDateFrom(value);
                }}
              />
              <CustomDatePicker
                name="formation_period_to"
                error={error.dateTo}
                placeholder="Период по:"
                value={dateTo}
                onChange={(e, value) => {
                  setError((prevState) => ({
                    ...prevState,
                    dateTo: '',
                  }));
                  setDateTo(value);
                }}
              />
              <CustomButton
                isLoading={isLoading || isLoadingParkingPoints}
                onClick={onGetDeviations}
                variant="outlined"
              >
                Показать отклонения в выбранном диапазоне дат
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
        <Box display="flex" flexDirection="column" width={mainTableWidth}>
          <>
            {isParkingDeviation && (
              <Box display="flex" alignItems="center" gap={3}>
                <TextTitle h2>Места концентрации</TextTitle>
                {selectedConcentrationPlace?.id && (
                  <CustomButton onClick={unSelectConcentration}>Показать всё</CustomButton>
                )}
              </Box>
            )}
            <CustomTable
              showRowsNumber
              data={tableData}
              selectedRowItemId={selectedConcentrationPlace?.id}
              onClick={boundsItem}
              columns={getColumns()}
              deleteRow={isParkingDeviation && {
                deleteRequest: deleteRow,
                deleteMessage: 'Место концентрации удалено',
                getDeleteObjectName: (row, index) => `Место концентрации № ${index + 1}`,
              }}
            />
          </>
          {isParkingDeviation && parkingPoints && (
            <>
              <TextTitle h2 sx={{ mt: 4 }}>Места остановок</TextTitle>
              <CustomTable
                sx={{ width: '100%' }}
                data={parkingPoints}
                total={totalParkingPoints}
                columns={initialTableParkingDeviations}
                page={pageParkingPoints}
                onClick={boundsItem}
                selectedRowItemId={selectedParkingPlace?.id}
                setPage={(e, value) => setPageParkingPoints(value)}
                perPage={null}
              />
            </>
          )}
        </Box>
        {hasMap && (
          <LeafletMap
            concentrationPoints={polygonOptions}
            setOuterMap={setOuterMap}
            planned_routes={isRouteDeviation && plannedRoutes}
            outerMap={outerMap}
            width={isMobileTablet ? '100%' : '47%'}
            height="60vh"
            sx={{ position: isMobileTablet ? 'relative' : 'sticky', top: isMobileTablet ? '' : '300px', zIndex: 10 }}
            deviationRoutes={deviationRoutes}
            clusters={Boolean(parkingPoints.length) && isParkingDeviation && [
              {
                points: parkingPoints,
                disableClusteringAtZoom: 0,
                spiderfyOnMaxZoom: true,
                polygonColor: 'red',
                markerIcon: RedHouse,
                clusterColor: 'red',
                legendText: 'Места остановок',
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
}
