import { compareId } from '../../helpers';
import { addTotalCadastreCount } from '../../helpers/cadastreForms/getFormField';

export const EQUIPMENTS_NAME = 'equipments';
export const WASTE_ITEMS_NAME = 'wasteItems';
export const MANUFACTURED_PRODUCTS_NAME = 'manufactured_products';
export const SECONDARY_PRODUCTS_NAME = 'secondary_products';
export const MONITORING_KINDS_NAME = 'monitoring_kinds';
export const REPORT_TECHNOLOGIES_NAME = 'reportTechnologies';
export const TECHNOLOGIES_NAME = 'technologies';

const objectFields = [
  { name: 'objective_id', valid: (object) => object?.id || compareId(object?.label, 0) },
  'exploitation_in_date',
  'exploitation_out_date',
  'szz',
  'body_name_conclusion',
  'date_conclusion',
  'number_conclusion',
  'actual_power',
  'design_power',
  'reason_for_decommissioning',
];

const technologyFields = [
  'name',
  'developer_name',
  'legal_address',
  'actual_address',
  'developer_inn',
  'developer_kpp',
  'developer_ogrn',
  'developer_okved',
  'developer_okpo',
  'develop_year',
  { name: 'technology_purpose', valid: (technology_purpose) => technology_purpose?.value || compareId(technology_purpose?.label, 0) },
  'short_description',
];

const licenseFields = [
  { name: 'license', valid: (license) => license?.id || compareId(license?.license_number, 0) },
];

const equipmentFields = [
  'manufacturers_name',
  { name: 'kind', valid: (kind) => kind?.id || compareId(kind?.label, 0) },
  'name',
  'project_capacity',
  'exploitation_mode',
  'exploitation_temperature_from',
  'exploitation_temperature_to',
];

const wasteFields = [
  { name: 'waste_type_id', valid: (waste_type_id) => waste_type_id?.id || compareId(waste_type_id?.name, 0) },
  'tons_per_yer',
];

const monitoringKindsFields = [
  'name',
];

const secondaryProductFields = [
  {
    name: 'waste_type_id',
    valid: (waste_type_id) => (waste_type_id?.name === '0' && waste_type_id?.code === '0') || waste_type_id?.id,
  },
  'tons_per_yer',
];

const manufactoredProductFields = [
  'product_name',
  'code_okp',
  'certificate_available',
  'tons_per_yer',
];

export const getInitialEquipmentItem = () => ({
  randId: Math.random(),
  manufacturers_name: '',
  name: '',
  project_capacity: '',
  exploitation_mode: '',
  kind: '',
  exploitation_temperature_from: '',
  exploitation_temperature_to: '',
});

export const getInitialWasteItem = () => ({
  randId: Math.random(),
  waste_type_id: '',
  tons_per_yer: '',
});

export const getInitialManufacturedProduct = () => ({
  randId: Math.random(),
  product_name: '',
  code_okp: '',
  certificate_available: '',
  count: '',
});

export const getInitialSecondaryProducts = () => ({
  randId: Math.random(),
  waste_type_id: '',
  tons_per_yer: '',
});

export const getInitialMonitoringKinds = () => ({
  randId: Math.random(),
  name: '',
  is_observation_standard: false,
});

export const getInitialTechnology = () => ({
  randId: Math.random(),
  name: '',
  developer_name: '',
  legal_address: '',
  actual_address: '',
  developer_inn: '',
  developer_kpp: '',
  developer_oktmo: '',
  developer_ogrn: '',
  developer_okved: '',
  developer_okpo: '',
  develop_year: '',
  technology_purpose: '',
  short_description: '',
  [WASTE_ITEMS_NAME]: [getInitialWasteItem()],
  manufactured_products: [getInitialManufacturedProduct()],
  secondary_products: [getInitialSecondaryProducts()],
  monitoring_kinds: [getInitialMonitoringKinds()],
  equipments: [getInitialEquipmentItem()],
});

export const getInitialReportObject = () => ({
  randId: Math.random(),
  objective_id: '',
  exploitation_in_date: null,
  exploitation_out_date: null,
  szz: '',
  body_name_conclusion: '',
  date_conclusion: '',
  number_conclusion: '',
  license: '',
  actual_power: '',
  design_power: '',
  reason_for_decommissioning: '',
  [TECHNOLOGIES_NAME]: [getInitialTechnology()],
});

export const getInitialForm7 = () => ({
  id: '',
  [REPORT_TECHNOLOGIES_NAME]: [getInitialReportObject()],
});

export const form7TechnologyCount = (technology, sumAllDataLength, sumFilledCount) => {
  addTotalCadastreCount(technologyFields, technology, sumFilledCount);

  technology.equipments.forEach((equipment) => {
    sumAllDataLength(equipmentFields.length);

    addTotalCadastreCount(equipmentFields, equipment, sumFilledCount);
  });

  technology[WASTE_ITEMS_NAME].forEach((wasteItem) => {
    sumAllDataLength(wasteFields.length);

    addTotalCadastreCount(wasteFields, wasteItem, sumFilledCount);
  });

  technology.manufactured_products.forEach((manufacturedProduct) => {
    sumAllDataLength(manufactoredProductFields.length);

    addTotalCadastreCount(manufactoredProductFields, manufacturedProduct, sumFilledCount);
  });

  technology.secondary_products.forEach((secondaryProduct) => {
    sumAllDataLength(secondaryProductFields.length);

    addTotalCadastreCount(secondaryProductFields, secondaryProduct, sumFilledCount);
  });

  technology.monitoring_kinds.forEach((monitoringKinds) => {
    sumAllDataLength(monitoringKindsFields.length);

    addTotalCadastreCount(monitoringKindsFields, monitoringKinds, sumFilledCount);
  });
};

export const form7ObjectCount = (object, sumAllDataLength, sumFilledCount, ignoreLicense) => {
  if (!ignoreLicense) {
    sumAllDataLength(licenseFields.length);

    addTotalCadastreCount(licenseFields, object, sumFilledCount);
  }

  object[TECHNOLOGIES_NAME].forEach((technology) => {
    sumAllDataLength(technologyFields.length);

    form7TechnologyCount(technology, sumAllDataLength, sumFilledCount);
  });
};

export const form7PercentGetter = (forms, { ignoreLicense }) => {
  const data = forms.form7;

  let allDataLength = 0;
  let allFilledCount = 0;

  const sumAllDataLength = (count) => {
    allDataLength += count;
  };

  const sumFilledCount = () => {
    allFilledCount += 1;
  };

  data[REPORT_TECHNOLOGIES_NAME].forEach((object) => {
    sumAllDataLength(objectFields.length);

    addTotalCadastreCount(objectFields, object, sumFilledCount);

    form7ObjectCount(object, sumAllDataLength, sumFilledCount, ignoreLicense);
  });

  return Math.floor((allFilledCount / allDataLength) * 100);
};
