/* eslint-disable */
import React, { memo, useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDebouncedCallback } from 'use-debounce';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { getError, HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';

const decimalStringNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ',', '', '.'].map(item => item.toString());

const inputSx = {
  fieldset: {
    border: 'none',
  },
  '&.error': {
    fieldset: {
      border: ({ palette }) => `1px solid ${palette.error.main}`,
    },
    '& .MuiInputLabel-root': {
      color: ({ palette }) => palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      color: ({ palette }) => palette.error.main,
    },
  },
  '.MuiInputBase-root.MuiInputBase-formControl': {
    borderRadius: '8px',
    background: BACKGROUND_LIGHT,
  },
};

function CustomInput({
  className,
  sx,
  value,
  onChange,
  error,
  disabled,
  label,
  onKeyEnter,
  min,
  max,
  maxLength,
  placeholder,
  required,
  type = 'text',
  multiline,
  valid,
  validAfterTouch = false,
  validImmediately = false,
  debounceTime = 300,
  ...props
}) {
  const [innerValue, setInnerValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const errorClass = (!disabled && (error || getError({ value, validImmediately }))) ? 'error' : '';
  const errorText = error || getError({ value, validImmediately });

  const minMaxProps = min || max ? { min, max } : {};

  const debounceChangeValue = useDebouncedCallback((e, value) => {
    onChange?.(e, value);
  }, debounceTime);

  const changeInputValue = (e) => {
    const isDecimalType = (e.target.value?.split('')?.every(item => decimalStringNumbers.includes(String(item))));

    if (type === 'number' && !isDecimalType) {
      return false;
    } else if (type === 'number' && isDecimalType) {
      const commaCounts = e.target.value?.split?.(',')?.length;
      const dotCounts = e.target.value?.split?.('.')?.length;

      if (commaCounts > 2 || e.target.value?.[0] === ',') {
        return false;
      }
      if (dotCounts > 2 || e.target.value?.[0] === '.') {
        return false;
      }
      if (dotCounts === 2 && commaCounts === 2) {
        return false;
      }
    }

    const newInnerValue = type === 'number' && typeof e.target.value === 'string' ? e.target.value.replace('.', ',') : e.target.value;

    if (debounceTime) {
      debounceChangeValue(e, type === 'number' && typeof e.target.value === 'string' ? e.target.value.replace(',', '.') : e.target.value);
    } else {
      onChange(e, type === 'number' && typeof e.target.value === 'string' ? e.target.value.replace(',', '.') : e.target.value);
    }
    setInnerValue(newInnerValue);
  };

  const { isMobileTablet } = useCustomMediaQuery();

  useEffect(() => {
    if (type === 'number') {
      if (typeof value === 'string' && value.replace('.', ',') !== innerValue) {
        setInnerValue(value.replace('.', ','));
      }
    } else if (value !== innerValue) setInnerValue(value);
  }, [value]);

  const PasswordComponent = type === 'password' && (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        disabled={disabled}
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
        sx={{ backgroundColor: showPassword ? '#e3e3e3' : '' }}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const onKeyPress = (e) => {
    if (onKeyEnter && e.key === 'Enter') onKeyEnter(e, e?.target?.value);
  };

  const isDecimalType = type === 'number' ? 'text' : type;

  return (
    <TextField
      placeholder={placeholder}
      type={showPassword ? 'text' : isDecimalType}
      label={label}
      helperText={errorText}
      error={Boolean(errorText)}
      onBlur={(e) => {
        if (validAfterTouch && !innerValue) {
          setInnerError(HAS_REQUIRED_FIELDS_TEXT);
        }

        props?.onBlur?.(e);
      }}
      size={isMobileTablet ? 'small' : undefined}
      onKeyPress={onKeyPress}
      inputProps={{ maxLength, ...minMaxProps }}
      InputProps={{ endAdornment: PasswordComponent }}
      onWheel={(e) => e.target.blur()}
      onChange={changeInputValue}
      disabled={disabled}
      sx={{ ...inputSx, ...sx }}
      className={`${className} ${errorClass}`}
      value={innerValue || ''}
      multiline={multiline}
      {...props}
      required={required}
    />
  );
}

export default memo(CustomInput);
