// @ts-nocheck
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { CREATE_APPLICATION, CREATE_VEHICLE } from '../../constants/routes';
import { getParamsFromObject } from '../../helpers/objectHelper';
import { useApi } from '../../hooks';
import { useUserStore } from '../../store';
import CustomButton from '../Buttons/CustomButton';
import SaveButton from '../Buttons/SaveButton';
import CustomCheckbox from '../CustomCheckbox';
import SearchInput from '../Inputs/SearchInput';
import ModalVehicleInfo from '../Modals/Carrier/ModalVehicleInfo';
import ModalAddVehicle from '../Modals/ModalAddVehicle';
import ModalApplication from '../Modals/ModalApplication/ModalApplication';
import ModalCreateApplication from '../Modals/ModalApplication/ModalCreateApplication';
import CustomTable from '../Tables/CustomTable';
import { TextTitle } from '../Text';

export default function CarrierTable({
  tableColumns,
  title,
  buttonText,
  search,
  deleteItem,
  type,
  getDeleteObjectName,
  deleteMessage,
}) {
  const { userStore } = useUserStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [copiedApplication, setCopiedApplication] = useState();
  const [rowInfo, setRowInfo] = useState({});
  const [editRow, setEditRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [withDeleted, setWithDeleted] = useState(false);

  const copyRowControl = (row) => (
    <SaveButton
      title="Создать новое"
      onClick={(e) => {
        e.stopPropagation();
        setCopiedApplication(row);
      }}
    />
  );

  const isVehicle = type === 'vehicle';
  const isApplication = type === 'application';

  const hasInactive = isVehicle ? withDeleted : undefined;

  const openModal = (info) => setEditRow(info);

  const changeSearchText = (e, value) => setSearchText(value);

  const { makeRequest, isLoading } = useApi({
    request: async () => search(
      {
        page,
        total: perPage,
        search: searchText,
        is_inactive: hasInactive,
      },
      ({ meta: { total: totalPageCount } }) => setTotal(totalPageCount),
    ),
    setter: setTableData,
    shouldRequest: userStore.id !== undefined,
    deps: [page, perPage, hasInactive],
  });

  const closeModal = (shouldRequest = false) => {
    if (shouldRequest) makeRequest();

    navigate(pathname);
    setEditRow(null);
    setCopiedApplication();
  };

  const deleteRequest = async ({ id }) => {
    await deleteItem(id);

    makeRequest();
  };

  const openCreateModal = () => {
    if (isApplication) {
      navigate(CREATE_APPLICATION);
    } else {
      navigate(CREATE_VEHICLE);
    }
    openModal({});
  };

  const closeVehicleInfo = () => setRowInfo({});

  const onClickRow = (row) => {
    if (row.type === 'application') {
      navigate(getParamsFromObject({
        object: { modal: 'application', modalId: row.id },
      }));
    } else {
      setRowInfo(row);
    }
  };

  const changeWithDeleted = () => {
    setTableData([]);
    setWithDeleted(!withDeleted);
  };

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box position="relative" width={tabletSizeAndLower ? '100%' : '50%'} background={BACKGROUND_LIGHT}>
      {isVehicle && Boolean(editRow) && (
        <ModalAddVehicle
          disabledFields={editRow.id && ['egrz', 'model', 'brand']}
          updateVehicleInfo={setRowInfo}
          vehicle={editRow}
          close={closeModal}
        />
      )}
      {isApplication && (Boolean(editRow) || Boolean(copiedApplication)) && (
        <ModalCreateApplication applicationData={copiedApplication} close={closeModal} />
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={3} mb={3}>
        <TextTitle h2>{title}</TextTitle>
        <CustomButton variant="contained" onClick={openCreateModal}>{buttonText}</CustomButton>
      </Box>
      {rowInfo.egrz !== undefined && (
        <ModalVehicleInfo
          hasMap={false}
          vehicle={rowInfo}
          onEdit={() => openModal(rowInfo)}
          close={closeVehicleInfo}
        />
      )}
      {isApplication && <ModalApplication sx={{ width: '55vw' }} hasPublicLink={false} hasMap={false} onCopy={setCopiedApplication} />}
      <Box
        overflow="auto"
        gap={3}
        p={1}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" gap={3}>
          <SearchInput
            sx={{ width: 320, background: '#EDF2F8' }}
            label="Поиск"
            onKeyEnter={makeRequest}
            value={searchText}
            onChange={changeSearchText}
          />
          {isVehicle && (
            <Box display="flex" alignItems="center">
              <CustomCheckbox
                label="Архивные"
                onChange={changeWithDeleted}
                value={withDeleted}
              />
            </Box>
          )}
        </Box>
        <CustomTable
          controlHeaderCellName="Редактирование"
          additionalControls={isApplication && [{ render: copyRowControl, id: Math.random() }]}
          perPage={perPage}
          total={total}
          page={page}
          onClick={onClickRow}
          setPage={(e, value) => setPage(value)}
          setPerPage={(e, value) => {
            setPerPage(value);
            setPage(1);
          }}
          data={tableData}
          isLoading={isLoading}
          columns={tableColumns}
          containerSx={{ maxHeight: '480px' }}
          deleteRow={isVehicle && !withDeleted && {
            deleteRequest,
            deleteMessage,
            getDeleteObjectName,
          }}
          editRow={isVehicle && !withDeleted && openModal}
        />
      </Box>
    </Box>
  );
}
