// @ts-nocheck
import React from 'react';
import { Alert, Box } from '@mui/material';
import { INPUT_EMPTY_TEXT, USER_ENTITY_TYPE } from '../constants';
import CustomButton from './Buttons/CustomButton';
import { useApi } from '../hooks';
import { removeMaskSymbols, removeSubjectNameSymbols } from '../helpers/stringHelper';
import { getSubjectIsSMEVRequest, getSubjectSMEVRequest, updateSubjectRequest } from '../api/subjectApi';
import { compareId } from '../helpers';
import useGetReferenceItems from '../hooks/useGetRefferenceItems';
import { SUBJECT_TYPES } from '../constants/enums';

export function FNSLoadData({ subject_type, userInfo, callback }) {
  const { referenceTypes: subjectTypes } = useGetReferenceItems({ referenceUrl: SUBJECT_TYPES });

  const { makeRequest: loadFNS, isLoading: isLoadingFNS } = useApi({
    request: async () => {
      const paramsData = { inn: removeMaskSymbols(userInfo.inn) };

      try {
        const { data: { is_match } } = await getSubjectIsSMEVRequest(paramsData);

        if (is_match) {
          const { data: subjectByInnData } = await getSubjectSMEVRequest(paramsData);

          const subjectByInnType = subjectByInnData.data.inn.length === 10
            ? USER_ENTITY_TYPE.legal_entity.name
            : USER_ENTITY_TYPE.individual_entrepreneur.name;

          await updateSubjectRequest(userInfo.id, {
            kpp: subjectByInnData.data.kpp,
            inn: subjectByInnData.data.inn,
            ogrn: subjectByInnData.data.ogrn,
            name: removeSubjectNameSymbols(subjectByInnData.value),
            okato: subjectByInnData.data.okato,
            okved: subjectByInnData.data.okved,
            okpo: subjectByInnData.data.okpo,
            full_name: subjectByInnData?.data?.name?.full_with_opf || INPUT_EMPTY_TEXT,
            boss_name: subjectByInnData.data?.management?.name || '',
            registry_address: subjectByInnData.data.address.data.source,
            subject_type_id: subjectTypes.find(({ code }) => compareId(code, subjectByInnType))?.id,
          });

          callback?.();
        }

        if (!is_match) {
          throw { frontendError: 'Данные в ЕГРЮЛ/ЕГРИП не обнаружены.' };
        }
      } catch (e) {
        throw { frontendError: 'Данные в ЕГРЮЛ/ЕГРИП не обнаружены.' };
      }
    },
  });

  if (USER_ENTITY_TYPE.self_employed.name === subject_type) return null;

  return (
    <Box display="flex" flex={1} flexDirection="column" gap={3}>
      <Alert severity="warning" sx={{ width: '300px' }}>
        Проверка сведений может занимать до 10 минут. Дождитесь получения сведений, не закрывайте и не обновляйте
        страницу
      </Alert>
      <CustomButton isLoading={isLoadingFNS} onClick={loadFNS} sx={{ mb: 3, width: 'fit-content' }}>
        Обновить данные через ФНС
      </CustomButton>
    </Box>
  );
}
