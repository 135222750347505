import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../Text';
import CustomInput from '../Inputs';
import CustomDatePicker from '../CustomDatePicker';
import CustomCheckbox from '../CustomCheckbox';
import NavTabs from '../Tabs';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../constants/lkCadastr/cadastrStorage';
import { ACTIVITY_KINDS_NAMES } from '../../constants/subjectConstants';
import { getInitialForm5IndustrialArea } from '../../constants/lkCadastr/cadastrForm5';
import { getOktmoLabel } from '../../helpers/stringHelper';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import { compareId } from '../../helpers';

export default function CadastreForm5License({
  license,
  onChange,
  isDemo,
  changeItemFormByField,
  openDeleteModal,
  licenseIndex,
  licenses,
  form1Objects,
  deleteForm5Entity,
}) {
  const { tabs, tab, setTab } = useCadastreTabs({ items: license.industrialAreas, tabName: 'Промплощадка' });

  const changeLicense = (field, value, industrialAreaIndex) => onChange({
    field: `licenses.${licenseIndex}.${field}`,
    value,
    reportLicenseIndex: licenseIndex,
    industrialAreaIndex,
  });

  const addNewIndustrialArea = () => {
    setTab(tabs[0].name);
    changeItemFormByField({
      field: `form5.licenses.${licenseIndex}.industrialAreas`,
      value: [...license.industrialAreas, getInitialForm5IndustrialArea()],
    });
  };

  const form1ObjectsOptions = form1Objects
    .map((item) => ({
      ...item.objective_id,
    }));

  const { industrialAreas } = license;

  const onDeleteLicense = async () => {
    if (license?.id) {
      const deletedLicense = await deleteForm5Entity({ reportLicenseId: license.id });

      if (!deletedLicense) return;
    }

    const cloneArray = licenses.slice();
    cloneArray.splice(licenseIndex, 1);

    changeItemFormByField({
      field: 'form5.licenses',
      value: cloneArray,
      type: 'delete',
    });
  };

  return (
    <Box sx={flexGap2}>
      {licenses.length > 1 && (
        <DeleteButton
          onClick={() => openDeleteModal(onDeleteLicense, licenseIndex, 'Лицензию')}
        >
          Удалить лицензию
        </DeleteButton>
      )}
      <Box sx={flexRowGap2}>
        <CustomInput
          validImmediately
          debounceTime={0}
          disabled={isDemo}
          label="Номер лицензии"
          value={license.license_number}
          onChange={(e, value) => changeLicense('license_number', value)}
        />
        <CustomDatePicker
          canBeZero
          validImmediately
          disabled={isDemo}
          label="Дата выдачи лицензии"
          value={license.license_issue_date}
          onChange={(e, value) => changeLicense('license_issue_date', value)}
        />
        <Box display="flex" alignItems="center" gap={3}>
          <CustomDatePicker
            canBeZero
            validImmediately={!license.is_forever}
            disabled={isDemo || license.is_forever}
            label="Срок действия лицензии"
            value={license.license_expiration}
            onChange={(e, value) => changeLicense('license_expiration', value)}
          />
          <CustomCheckbox
            disabled={isDemo}
            value={license.is_forever}
            label="Бессрочно"
            onChange={(e, value) => changeLicense('is_forever', value)}
          />
        </Box>
      </Box>
      {!isDemo && <AddButton variant="outlined" onClick={addNewIndustrialArea}>Добавить промплощадку</AddButton>}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {industrialAreas.map((industrialArea, industrialAreaIndex) => {
        if (industrialArea.randId !== tab) return null;

        const changeIndustrialArea = (field, value) => changeLicense(
          `industrialAreas.${industrialAreaIndex}.${field}`,
          value,
          industrialAreaIndex,
        );

        const changeIndustrialAreaCheckbox = (checkboxesType, field) => {
          if (industrialArea[checkboxesType].includes(field)) {
            changeIndustrialArea(checkboxesType, industrialArea[checkboxesType].filter((item) => item !== field));
          } else {
            changeIndustrialArea(checkboxesType, [...industrialArea[checkboxesType], field]);
          }
        };

        const changeWorksSelfWaste = (field) => changeIndustrialAreaCheckbox('works_self_waste', field);
        const changeWorksAnotherWaste = (field) => changeIndustrialAreaCheckbox('works_another_waste', field);

        const onDeleteIndustrialArea = async () => {
          if (industrialArea?.id) {
            const deletedIndustrialArea = await deleteForm5Entity({ industrialAreaId: industrialArea.id });

            if (!deletedIndustrialArea) return;
          }

          const cloneArray = industrialAreas.slice();
          cloneArray.splice(industrialAreaIndex, 1);

          changeItemFormByField({
            field: `form5.licenses.${licenseIndex}.industrialAreas`,
            value: cloneArray,
            type: 'delete',
          });
        };

        return (
          <Box key={industrialArea.randId} sx={flexGap2}>
            {!isDemo && industrialAreas.length > 1 && (
              <DeleteButton
                onClick={() => openDeleteModal(onDeleteIndustrialArea, industrialAreaIndex, 'Площадку')}
              >
                Удалить площадку
              </DeleteButton>
            )}
            <Box display="flex" flexDirection="column" gap={3}>
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                sx={{ width: '100%' }}
                label="Наименование промплощадки"
                value={industrialArea.name}
                onChange={(e, value) => changeIndustrialArea('name', value)}
              />
              <CustomAutocomplete
                canBeZero
                validImmediately={!compareId(industrialArea.objective_id?.label, 0)}
                debounceTime={0}
                required
                useDefaultFilter
                disabled={isDemo}
                label="Адрес промплощадки"
                sx={fullWidth}
                options={form1ObjectsOptions}
                value={industrialArea.objective_id}
                onChange={(e, value) => changeIndustrialArea('objective_id', value)}
              />
              <CustomInput
                disabled
                label="Код ОКТМО"
                value={getOktmoLabel(industrialArea.objective_id?.address_attributes?.attributes?.oktmo_attributes?.attributes)}
                sx={{ width: '100%' }}
              />
              <CustomInput
                validImmediately
                debounceTime={0}
                disabled={isDemo}
                sx={{ width: '100%' }}
                label="Номер промплощадки"
                value={industrialArea.area_number}
                onChange={(e, value) => changeIndustrialArea('area_number', value)}
              />
              <Box sx={flexGap2}>
                <TextTitle h3>
                  Виды работ
                </TextTitle>
                <Box sx={{ ...flexRowGap2, flexWrap: 'wrap' }}>
                  <Box sx={flexGap2}>
                    <TextTitle>
                      С собственными отходами
                    </TextTitle>
                    <Box sx={flexRowGap2}>
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Транспортирование"
                        value={industrialArea.works_self_waste.includes(ACTIVITY_KINDS_NAMES.transporting)}
                        onChange={() => changeWorksSelfWaste(ACTIVITY_KINDS_NAMES.transporting)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Обработка"
                        value={industrialArea.works_self_waste.includes(ACTIVITY_KINDS_NAMES.processing)}
                        onChange={() => changeWorksSelfWaste(ACTIVITY_KINDS_NAMES.processing)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Утилизация"
                        value={industrialArea.works_self_waste.includes(ACTIVITY_KINDS_NAMES.utilization)}
                        onChange={() => changeWorksSelfWaste(ACTIVITY_KINDS_NAMES.utilization)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Обезвреживание"
                        value={industrialArea.works_self_waste.includes(ACTIVITY_KINDS_NAMES.neutralization)}
                        onChange={() => changeWorksSelfWaste(ACTIVITY_KINDS_NAMES.neutralization)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Размещение"
                        value={industrialArea.works_self_waste.includes(ACTIVITY_KINDS_NAMES.placing)}
                        onChange={() => changeWorksSelfWaste(ACTIVITY_KINDS_NAMES.placing)}
                      />
                    </Box>
                  </Box>
                  <Box sx={flexGap2}>
                    <TextTitle>
                      С отходами сторонних организаций
                    </TextTitle>
                    <Box sx={flexRowGap2}>
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Сбор"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.collection)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.collection)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Транспортирование"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.transporting)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.transporting)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Обработка"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.processing)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.processing)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Утилизация"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.utilization)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.utilization)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Обезвреживание"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.neutralization)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.neutralization)}
                      />
                      <CustomCheckbox
                        disabled={isDemo}
                        sx={fitContent}
                        label="Размещение"
                        value={industrialArea.works_another_waste.includes(ACTIVITY_KINDS_NAMES.placing)}
                        onChange={() => changeWorksAnotherWaste(ACTIVITY_KINDS_NAMES.placing)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
