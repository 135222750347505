// @ts-nocheck
import React, { useMemo, useState } from 'react';
import {
  Box, Paper, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Submit from '../../components/Buttons/SubmitButton';
import {
  PUBLIC_FORGOT_PASSWORD_ROUTE,
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
  PUBLIC_REGISTRATION_SUBJECT_ROUTE,
} from '../../constants/routes';
import { formValidator } from '../../helpers';
import { ReactComponent as LogoBig } from '../../assets/logo_big_icon.svg';
import { useApi } from '../../hooks';
import { useUserStore } from '../../store';
import CustomInput from '../../components/Inputs';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import BackdropLoading from '../../components/BackdropLoading';
import { TextMain } from '../../components/Text';
import { getMemberRequest } from '../../api/userApi';

const inputStyle = { mb: 3 };

const buttonStyle = {
  mt: 2,
  padding: '17px, 24px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '235px',
  height: '58px',
  fontSize: '16px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px',
  gap: '30px',
  position: 'absolute',
  width: '480px',
  boxShadow: '0px 8px 30px rgba(10, 30, 44, 0.1)',
  borderRadius: '14px',

  '& .MuiFormControl-root': {
    minWidth: '260px',
  },
  '& a': {
    color: '#1B80C4',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0',
  },
};

const initialLoginData = {
  password: '',
  email: '',
};

const REGISTRATION_ROUTES = [PUBLIC_LOGIN_CARRIER_PAGE_ROUTE, PUBLIC_LOGIN_CADASTR_PAGE_ROUTE];

export default function LoginPage({
  loginRequest, redirectUrl, restComponent, forgotPasswordParams = '',
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setUserStore } = useUserStore();
  const [loginData, setLoginData] = useState(initialLoginData);
  const [error, setError] = useState(initialLoginData);

  const { makeRequest: getMember } = useApi({
    request: async () => {
      const userData = await getMemberRequest();

      setUserStore(userData);

      if (typeof redirectUrl === 'string') navigate(redirectUrl);
      else redirectUrl?.(userData);
    },
  });

  const login = async (e) => {
    e.preventDefault();

    const { hasErrors, validField } = formValidator({ form: loginData });

    if (hasErrors) {
      setError(validField);
      throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };
    }

    await loginRequest(loginData);

    getMember();
  };

  const { makeRequest: onLogin, isLoading } = useApi({
    request: login,
    successMessage: 'Успешный вход',
  });

  const changeLoginData = ({ target: { name } }, value) => {
    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const title = useMemo(() => {
    switch (pathname) {
      case PUBLIC_LOGIN_ADMIN_PAGE_ROUTE:
        return 'Администрирование Регионального кадастра отходов ЛО';
      case PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE:
        return 'Администратор электронной модели территориальной схемы Ленинградской области';
      case PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE:
        return 'Администратор кадастра отходов Ленинградской области';
      case PUBLIC_LOGIN_CADASTR_PAGE_ROUTE:
        return 'Кадастр отходов Ленинградской области';
      case PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE:
        return 'Личный кабинет комитета государственного экологического надзора Ленинградской области';
      default:
        return 'Контроль перевозчиков строительных отходов Ленинградской области';
    }
  }, [pathname]);

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      component="form"
    >
      <BackdropLoading isLoading={isLoading} />
      <Paper sx={{ ...formStyle, width: tabletSizeAndLower ? '100vw' : formStyle.width }}>
        <Typography
          textAlign="center"
          fontSize="32px"
          fontWeight={600}
          marginBottom="10px"
        >
          <LogoBig />
        </Typography>
        <TextMain big sx={{ textAlign: 'center' }}>
          {title}
        </TextMain>
        <Box
          mx="auto"
          display="flex"
          alignItems="center"
          width="fit-content"
          flexDirection="column"
        >
          <CustomInput
            sx={inputStyle}
            helperText={error.email}
            value={loginData.email}
            name="email"
            debounceTime={0}
            error={error.email}
            onChange={changeLoginData}
            label="Имя пользователя"
          />
          <CustomInput
            name="password"
            type="password"
            debounceTime={0}
            sx={inputStyle}
            error={error.password}
            value={loginData.password}
            onChange={changeLoginData}
            label="Пароль"
          />
          <Box display="flex" justifyContent="flex-end">
            <Link to={`${PUBLIC_FORGOT_PASSWORD_ROUTE}${forgotPasswordParams ? `?type=${forgotPasswordParams}` : ''}`} underline="hover">
              Забыли пароль?
            </Link>
          </Box>
          <Submit sx={buttonStyle} onClick={onLogin} type="submit" value="Войти в личный кабинет" />
          {REGISTRATION_ROUTES.includes(pathname) && (
            <Typography
              className="color-blue cursor-pointer"
              sx={{ fontSize: '12px', textAlign: 'center', mt: '30px' }}
              onClick={() => navigate(`${PUBLIC_REGISTRATION_SUBJECT_ROUTE}${pathname.includes(PUBLIC_LOGIN_CADASTR_PAGE_ROUTE) ? '?isCadastr=true' : ''}`)}
            >
              Регистрация нового пользователя
            </Typography>
          )}
          {restComponent}
        </Box>
      </Paper>
    </Box>
  );
}
