import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as ConnectedRINSIcon } from '../../assets/connected_rins_icon.svg';
import { ReactComponent as NotConnectedRINSIcon } from '../../assets/not_connected_rins_icon.svg';
import { RED } from '../../constants/colors';
import { TextMain, TextSignature } from '../Text';
import TitleSubtitle from '../TitleSubtitle';
import VehicleTrackControl from '../VehicleTrackControl';

export default function VehicleInformationContainer({
  guid_bnso,
  egrz,
  egrz_trailer,
  brand,
  model,
  is_connected_rins,
  boundsVehicle,
  setVehicleTracks,
  vehiclePosition,
  ownership_type_text,
  isLoading,
  hasTrackControl = true,
  isPrintMoment,
  deleted_at,
  is_inactive,
}) {
  return (
    <Box borderRadius="14px">
      <Box display="flex" alignItems="center" mb={2}>
        <Typography fontSize="20px" fontWeight="bold" mr={2}>
          Информация о ТС
        </Typography>
      </Box>
      {(deleted_at || is_inactive) && <TextSignature big sx={{ color: RED, mb: 1 }}> (В архиве)</TextSignature>}
      {hasTrackControl && is_connected_rins && !isPrintMoment && (
        <VehicleTrackControl
          boundVehicle={boundsVehicle}
          setter={setVehicleTracks}
          vehiclePosition={vehiclePosition}
          guid_bnso={guid_bnso}
          isLoading={isLoading}
        />
      )}
      <Box mb={2} display="flex" alignItems="flex-start" flexWrap="wrap" gap={1}>
        {is_connected_rins ? <ConnectedRINSIcon /> : <NotConnectedRINSIcon />}
        <TitleSubtitle
          title={(
            <TextMain>
              ТС
              {' '}
              <strong>
                {is_connected_rins ? '' : 'Не '}
                подключено
                {' '}
              </strong>
              {' '}
              к РИНС ЛО
            </TextMain>
          )}
          subTitle={(
            <TextSignature>
              ID БНСО:
              {' '}
              {guid_bnso || 'Нет'}
            </TextSignature>
          )}
        />
        <Box ml={5}>
          <TitleSubtitle title="ЕГРЗ ТС (Номер ТС):" subTitle={egrz} />
          <TitleSubtitle noValueText="Не выбран" title="ЕГРЗ прицепа:" subTitle={egrz_trailer} />
        </Box>
        <Box>
          <TitleSubtitle title="Марка и модель ТС:" subTitle={`${brand} ${model}`} />
          <TitleSubtitle title="Тип владения" subTitle={ownership_type_text} />
        </Box>
      </Box>
    </Box>
  );
}
