// @ts-nocheck
import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomTable from '../../../components/Tables/CustomTable';
import { useTableSearch } from '../../../hooks';
import { getSubjectEnumsRequest } from '../../../api/subjectApi';
import { ENTERPRISE_TYPE_ENUM } from '../../../constants/enums';

const initialTableTypeGuide = [
  { headerTitle: 'Наименование вида предприятия', field: 'label' },
  { headerTitle: 'Идентификатор вида предприятия', field: 'id' },
];

const getEnterpriseType = (params) => getSubjectEnumsRequest({ enum_field: ENTERPRISE_TYPE_ENUM, params });

export default function TypeEnterpriseGuide() {
  const [tableData, setTableData] = useState([]);

  const { isLoading } = useTableSearch({
    search: getEnterpriseType,
    setter: setTableData,
  });

  return (
    <Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={initialTableTypeGuide}
        total={tableData.length}
      />
    </Box>
  );
}
