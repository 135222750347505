import { colors } from '@mui/material';
import { compareId } from '../../helpers';
import {
  ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES,
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_TYPE_ENUM,
  ENTERPRISE_TYPE_TEXT_ENUM,
  HAZARD_CLASS_ENUM,
  HAZARD_CLASS_ENUM_TEXT,
} from '../enums';
import { form1PercentGetter, getInitialForm1 } from './cadastrForm1';
import { form2PercentGetter } from './cadastrForm2';
import { form3PercentGetter, getInitialForm3 } from './cadastrForm3';
import { form4PercentGetter, getInitialForm4 } from './cadastrForm4';
import { form5PercentGetter, getInitialForm5 } from './cadastrForm5';
import { form6PercentGetter, getInitialForm6 } from './cadastrForm6';
import { form7PercentGetter, getInitialForm7 } from './cadastrForm7';
import { form8PercentGetter, getInitialForm8 } from './cadastrForm8';

export const REPORT_STATUS_NAMES = {
  formation: 'formation',
  sent: 'sent',
  checking: 'checking',
  checked: 'checked',
  has_warnings: 'has_warnings',
  paper_document_printed: 'paper_document_printed',
  paper_document_accepted: 'paper_document_accepted',
};

export const REPORTS_STATUSES = [
  { code: 'formation', label: 'Формируется' },
  { code: 'sent', label: 'Отправлен' },
  { code: 'checking', label: 'На проверке' },
  { code: 'checked', label: 'Проверен, ожидается бумажный документ' },
  { code: 'has_warnings', label: 'Есть замечания' },
  { code: 'paper_document_printed', label: 'Бумажный документ распечатан' },
  { code: 'paper_document_accepted', label: 'Бумажный документ принят' },
];

export const getReportFormIdString = ({ code, id, created_at }) => {
  const year = new Date(created_at)?.getFullYear();
  return (code === REPORT_STATUS_NAMES.formation ? id : `${id}/${year ? year - 1 : 0}`);
};

export const DIGIT_TO_SIGN = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
};

export const getPercentFormName = (formId) => `percent_form_${DIGIT_TO_SIGN[formId]}`;

export const formsPercentGetter = {
  form1: form1PercentGetter,
  form2: form2PercentGetter,
  form3: form3PercentGetter,
  form4: form4PercentGetter,
  form5: form5PercentGetter,
  form6: form6PercentGetter,
  form7: form7PercentGetter,
  form8: form8PercentGetter,
};

export const getInitialForm = () => ({
  form1: getInitialForm1(),
  form3: getInitialForm3(),
  form4: getInitialForm4(),
  form5: getInitialForm5(),
  form6: getInitialForm6(),
  form7: getInitialForm7(),
  form8: getInitialForm8(),
});

export const flexGap2 = { display: 'flex', flexDirection: 'column', gap: 2 };
export const flexRowGap2 = { display: 'flex', gap: 2, flexWrap: 'wrap' };
export const fitContent = { width: 'fit-content' };
export const fullWidth = { width: '100%' };
export const SCROLL_CONTAINER_SX = {
  ...flexGap2, padding: 1, height: '67vh', overflowY: 'auto',
};

const showEnterpriseActivityType = (data, types) => types
  .some((type) => data[ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES]
    ?.some?.(({ attributes }) => attributes.name === type));

const showSingleEnterpriseActivityType = (data, code) => data[ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES]?.length === 1 && (
  data[ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES][0].attributes.code === code
);

const showHazardClasses = (data, classes) => classes
  .some((item) => data[HAZARD_CLASS_ENUM_TEXT]?.includes?.(item));

const showEnterpriseType = (data, type) => data[ENTERPRISE_TYPE_TEXT_ENUM] === type;

const show1to4HazardClass = (userStore) => showHazardClasses(userStore, ['первый', 'второй', 'третий', 'четвертый']);

export const SHOW_FORMS = {
  form1: () => true,
  form2: () => true,
  form3: () => true,
  form4: (userStore) => (
    !showSingleEnterpriseActivityType(userStore, 'generating')
    && showEnterpriseType(userStore, 'Крупное предприятие')
  ),

  form5: (userStore) => !showSingleEnterpriseActivityType(userStore, 'generating') && show1to4HazardClass(userStore),

  form6: (userStore) => !showSingleEnterpriseActivityType(userStore, 'generating') && showEnterpriseActivityType(userStore, ['Транспортирование']),

  form7: (userStore) => !showSingleEnterpriseActivityType(userStore, 'generating') && showEnterpriseActivityType(userStore, ['Обработка', 'Утилизация', 'Обезвреживание']),

  form8: (userStore) => !showSingleEnterpriseActivityType(userStore, 'generating') && showEnterpriseActivityType(userStore, ['Размещение']),
};

export const FORMS_NAME = [
  { id: 1, label: 'Здания, помещения, сооружения', show: SHOW_FORMS.form1 },
  { id: 2, label: 'Исходные данные об отходе', show: SHOW_FORMS.form2 },
  {
    id: 3,
    label: 'Сведения об образовании, использовании, обезвреживании, транспортировании и размещении отходов производства и потребления',
    show: SHOW_FORMS.form3,
  },
  {
    id: 4,
    label: 'Документ об утверждении нормативов образования и лимитов на их размещение',
    show: SHOW_FORMS.form4,
  },
  {
    id: 5,
    label: 'Сведения о лицензии',
    show: SHOW_FORMS.form5,
  },
  {
    id: 6,
    label: 'Данные о фактических объемах транспортируемых отходов',
    show: SHOW_FORMS.form6,
  },
  {
    id: 7,
    label: 'Данные о технологиях обработки, утилизации, обезвреживании отходов',
    show: SHOW_FORMS.form7,
  },
  {
    id: 8,
    label: 'Виды, количество и способы размещения отходов на объекте',
    show: SHOW_FORMS.form8,
  },
];

export const calcBlockedForm = ({ userStore, completedForms, reportForm }) => {
  let index;
  FORMS_NAME.some(({ id, show }) => {
    if (show?.(userStore) && (!compareId(completedForms[`form${id}`], 100) || (reportForm && !compareId(reportForm?.[getPercentFormName(id)], 100)))) {
      index = id;
      return true;
    }
    return false;
  });
  return index;
};

export const calcReportPercentage = ({ userStore, completedForms }) => {
  let totalFormCounter = 0;

  const reportPercentage = FORMS_NAME.reduce((prevCount, { id, show }) => {
    if (show(userStore)) {
      totalFormCounter += 1;
      const formName = `form${id}`;

      return prevCount + +completedForms[formName];
    }
    return prevCount;
  }, 0);

  if (reportPercentage === 0) return 0;

  const newReportPercentage = (reportPercentage / (totalFormCounter * 100)) * 100;

  return Number(newReportPercentage?.toFixed(1)) || 0;
};

export const getCompleteColor = (complete) => {
  if (+complete >= 100) return colors.green['400'];
  if (+complete > 50) return colors.yellow['700'];
  return colors.red[400];
};

export const checkCadastrReady = (subjectData) => (
  subjectData[HAZARD_CLASS_ENUM].length !== 0
  && subjectData[ENTERPRISE_ACTIVITY_TYPE_IDS.length !== 0]
  && subjectData[ENTERPRISE_TYPE_ENUM]?.id !== undefined
);

export const prepareFormTable = (report) => [...new Array(8).keys()].map((keyNumber) => ({
  id: `${keyNumber + 1}`,
  complete: report[getPercentFormName(keyNumber + 1)],
  label: FORMS_NAME[keyNumber].label,
}));

const initialFormsData = [
  { id: '1', complete: 0, label: FORMS_NAME[0].label },
  { id: '2', complete: 0, label: FORMS_NAME[1].label },
  { id: '3', complete: 0, label: FORMS_NAME[2].label },
  { id: '4', complete: 0, label: FORMS_NAME[3].label },
  { id: '5', complete: 0, label: FORMS_NAME[4].label },
  { id: '6', complete: 0, label: FORMS_NAME[5].label },
  { id: '7', complete: 0, label: FORMS_NAME[6].label },
  { id: '8', complete: 0, label: FORMS_NAME[7].label },
];

export const getFormsData = () => initialFormsData;
