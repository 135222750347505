// @ts-nocheck
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { sendEmailRequest } from '../../api/emailsSubscriptionsApi';
import { getRolesRequest } from '../../api/rolesApi';
import { getCadastreReportStatusesRequest } from '../../api/cadastreApi';
import CustomCheckbox from '../../components/CustomCheckbox';
import CustomInput from '../../components/Inputs';
import { DEFAULT_AUTOCOMPLETE_OPTION } from '../../components/Inputs/CustomAutocomplete';
import Selector from '../../components/Selectors/Selector';
import EmailUserInputs from '../../components/SendEmail/EmailUserInputs';
import { formValidator } from '../../helpers';
import { ERROR_REQUIRED_FIELD, HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { usePushNotification } from '../../hooks';

const INITIAL_EMAIL_USER = {
  inn: { ...DEFAULT_AUTOCOMPLETE_OPTION },
  user: { ...DEFAULT_AUTOCOMPLETE_OPTION },
  subject: { ...DEFAULT_AUTOCOMPLETE_OPTION },
};

const getInitialEmailUser = () => ({ ...INITIAL_EMAIL_USER, id: Math.random() });

export default function AdminSendMailPage() {
  const pushNotification = usePushNotification();
  const [errors, setErrors] = useState({});
  const [isForAllError, setIsForAllError] = useState('');
  const [isForAll, setIsForAll] = useState({
    for_users: false,
    for_subjects: false,
  });

  const [emailUserErrors, setEmailUserErrors] = useState([]);
  const [usersRolesError, setUsersRolesError] = useState('');
  const [reportStatusError, setReportStatusError] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [usersRoles, setUsersRoles] = useState([]);
  const [reportStatuses, setReportStatus] = useState([]);
  const [emailHeader, setEmailHeader] = useState('');
  const [emailUsers, setEmailUsers] = useState([getInitialEmailUser()]);

  const changeUsersRole = (e, value) => {
    setEmailUserErrors([]);
    setEmailUsers([getInitialEmailUser()]);
    setUsersRolesError('');
    setReportStatusError('');
    setUsersRoles(value);
  };

  const changeReportStatus = (e, value) => {
    setReportStatusError('');
    setEmailUserErrors([]);
    setEmailUsers([getInitialEmailUser()]);
    setUsersRoles([]);
    setReportStatus(value);
  };

  const changeEmailUser = (index) => ({ target: { name } }, value) => {
    let additionalValue = {};

    if (name === 'subject') additionalValue = { inn: value };
    if (name === 'inn') additionalValue = { subject: value };

    const currentEmailUser = { ...emailUsers[index], [name]: value, ...additionalValue };
    const newEmailUsers = emailUsers.slice();
    newEmailUsers.splice(index, 1, currentEmailUser);
    setEmailUsers(newEmailUsers);

    const newEmailUserErrors = emailUserErrors.slice();
    newEmailUserErrors.splice(index, 1);
    setEmailUserErrors(newEmailUserErrors);

    setUsersRolesError('');
    setUsersRoles([]);
    setReportStatus([]);
  };

  const addEmailUser = () => setEmailUsers((prevState) => ([
    getInitialEmailUser(),
    ...prevState,
  ]));

  const deleteEmailUser = (index) => () => {
    const newEmailUsers = emailUsers.slice();
    newEmailUsers.splice(index, 1);

    setEmailUsers(newEmailUsers);
  };

  const sendEmail = async () => {
    const { validField, hasErrors } = formValidator({
      form: { emailHeader, emailMessage },
    });

    let hasSomeEmailUserError = false;

    const newEmailUserErrors = usersRoles.length || reportStatuses.length ? [] : emailUsers.map(({
      inn, user, subject, id,
    }) => {
      const ignoreInputs = [];
      const form = {
        inn: inn.id,
        subject: subject.id,
        user: user.id,
      };

      if (subject.id) ignoreInputs.push(...['user']);
      else if (user.id) ignoreInputs.push(...['inn', 'subject']);

      const { hasErrors: emailUserHasErrors, validField: emailUserValidFields } = formValidator({
        form,
        ignoreInputs,
      });

      if (emailUserHasErrors) hasSomeEmailUserError = true;

      return { ...emailUserValidFields, id };
    });

    setErrors(validField);
    setEmailUserErrors(newEmailUserErrors);

    const noEmailUsers = hasSomeEmailUserError && !usersRoles.length && !isForAll.for_users && !isForAll.for_subjects && !reportStatuses.length;

    if (noEmailUsers) {
      setUsersRolesError(ERROR_REQUIRED_FIELD);
      setReportStatusError(ERROR_REQUIRED_FIELD);
      setIsForAllError(ERROR_REQUIRED_FIELD);
    }

    if (hasErrors || hasSomeEmailUserError || noEmailUsers) {
      pushNotification({ message: HAS_REQUIRED_FIELDS_TEXT });
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const sure = confirm('Вы уверены что хотите отправить сообщение?');

    if (!sure) return;

    const userEmails = emailUsers
      .filter(({ subject, user }) => subject.email || user.email)
      .map(({ subject, user }) => [subject.email || user.email, subject.email || user.email]);

    const mapUserEmails = new Map(userEmails);
    const uniqUserEmails = [...mapUserEmails.keys()];

    const requestData = {
      from: 'control.waste@lenreg.ru',
      to: uniqUserEmails,
      subject: emailHeader,
      body: emailMessage,
    };

    // todo no backend
    if (usersRoles.length) requestData.role_ids = usersRoles.map((item) => item.id);
    if (reportStatuses.length) requestData.report_statuses = reportStatuses.map((item) => item.id);
    if (isForAll.for_users) requestData.is_for_all_users = true;
    if (isForAll.for_subjects) requestData.is_for_all_subjects = true;

    await sendEmailRequest(requestData);
    setEmailUsers([getInitialEmailUser()]);
  };

  const changeEmailHeader = (e, value) => {
    setEmailHeader(value);
    setErrors((prevState) => ({
      ...prevState,
      emailHeader: '',
    }));
  };

  const changeEmailMessage = (e, value) => {
    setEmailMessage(value);
    setErrors((prevState) => ({
      ...prevState,
      emailMessage: '',
    }));
  };

  const changeForAll = (forALlType) => (e, value) => {
    setIsForAll((prev) => ({
      ...prev,
      [forALlType]: value,
    }));
    setIsForAllError('');
  };

  const hasAll = !isForAll.for_users && !isForAll.for_subjects;

  return (
    <Box width="100%" my="20px" gap={4} display="flex" alignItems="flex-end">
      <Box width="100%">
        <Box width="100%" display="flex" flexDirection="column" gap={3} mb={3}>
          <CustomInput
            required
            label="Тема"
            error={errors.emailHeader}
            name="emailHeader"
            multiline
            value={emailHeader}
            onChange={changeEmailHeader}
          />
          <CustomInput
            required
            label="Текст сообщения"
            multiline
            error={errors.emailMessage}
            name="emailMessage"
            minRows={3}
            value={emailMessage}
            onChange={changeEmailMessage}
          />
          <Box>
            <CustomCheckbox
              label="Для всех пользователей"
              value={isForAll.for_users}
              onChange={changeForAll('for_users')}
              error={isForAllError}
            />
            <CustomCheckbox
              label="Для всех субъектов"
              value={isForAll.for_subjects}
              onChange={changeForAll('for_subjects')}
              error={isForAllError}
            />
          </Box>
          {hasAll && !reportStatuses.length && (
            <Selector
              multiple
              label="Роли пользователей"
              name="usersRoles"
              sx={{ minWidth: '220px' }}
              optionField={{ value: 'code', label: 'name' }}
              request={getRolesRequest}
              error={usersRolesError}
              value={usersRoles}
              onChange={changeUsersRole}
            />
          )}
          {hasAll && !usersRoles.length && (
            <Selector
              multiple
              label="Имеющие отчет в статусе"
              name="reportStatuses"
              sx={{ minWidth: '220px' }}
              optionField={{ value: 'code', label: 'name' }}
              request={getCadastreReportStatusesRequest}
              error={reportStatusError}
              value={reportStatuses}
              onChange={changeReportStatus}
            />
          )}
        </Box>
        {!usersRoles.length && !isForAll.for_users && !isForAll.for_subjects && !reportStatuses.length && (
          <>
            <Button sx={{ mb: 3 }} variant="contained" onClick={addEmailUser}>Добавить получателя</Button>
            <EmailUserInputs
              changeEmailUser={changeEmailUser}
              deleteEmailUser={deleteEmailUser}
              emailUserErrors={emailUserErrors}
              emailUsers={emailUsers}
            />
          </>
        )}
        <Button color="warning" variant="contained" onClick={sendEmail}>Отправить сообщение</Button>
      </Box>
    </Box>
  );
}
