import React, { memo, useEffect, useState } from 'react';
import { Circle, Polyline } from 'react-leaflet';

const shouldMemo = (prevProps, nextProps) => {
  if (prevProps.planned_routes?.length === 0 && nextProps.planned_routes?.length === 0) return true;

  if (
    prevProps.map?.fitBounds === undefined || nextProps.map?.fitBounds === undefined
  ) return false;

  if (prevProps.shouldFitBounds !== nextProps.shouldFitBounds) return false;

  if (prevProps.zoomOutTime !== nextProps.zoomOutTime) return false;
};

const PlannedRoutesRender = memo(({
  planned_routes,
  getTrackCircleChildren,
  map,
  zoomOutTime,
  shouldFitBounds = true,
  pathOptions,
  shouldShowPoints = false,
}) => {
  const [plannedRoutes, setPlannedRoutes] = useState([]);

  useEffect(() => {
    if (shouldFitBounds && plannedRoutes?.length > 0) {
      map?.fitBounds?.(plannedRoutes);
      if (zoomOutTime !== null) setTimeout(() => map?.zoomOut?.(), 100);
    }
    /* eslint-disable-next-line */
  }, [plannedRoutes, zoomOutTime, shouldFitBounds, map]);

  useEffect(() => {
    if (planned_routes?.length) setPlannedRoutes(planned_routes.map((item) => [item[1], item[0]]));
    else setPlannedRoutes([]);
  }, [planned_routes]);

  if (!plannedRoutes?.length) return null;

  return (
    <>
      {shouldShowPoints
        && plannedRoutes.map((coordinates, index) => (
          <Circle center={coordinates} radius={3}>
            {getTrackCircleChildren && getTrackCircleChildren(index)}
          </Circle>
        ))}
      <Polyline
        pathOptions={pathOptions}
        positions={plannedRoutes}
      />
    </>
  );
}, shouldMemo);

export default PlannedRoutesRender;
