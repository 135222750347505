import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomTable from '../../../components/Tables/CustomTable';
import { useTableSearch } from '../../../hooks';
import { getApplicationEnumsRequest } from '../../../api/applicationsApi';
import { HAZARD_CLASS_ENUM } from '../../../constants/enums';

const initialTableTypeGuide = [
  { headerTitle: 'Наименование класса опасности', field: 'label' },
  { headerTitle: 'Идентификатор класса опасности', field: 'id' },
];

const getHazardClass = (params) => getApplicationEnumsRequest(HAZARD_CLASS_ENUM, params);

export default function HazardClassesGuide() {
  const [tableData, setTableData] = useState([]);

  const { isLoading } = useTableSearch({
    search: getHazardClass,
    setter: setTableData,
  });

  return (
    <Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={initialTableTypeGuide}
        total={tableData.length}
      />
    </Box>
  );
}
