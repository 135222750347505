// @ts-nocheck
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import cloneDeep from 'lodash.clonedeep';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { METHODS_API } from '../../../api/api';
import {
  cadastreReportHistoryPreviousRequest,
  cadastreReportHistoryRequest,
  getCadastreReportStatusesRequest,
} from '../../../api/cadastreApi';
import { getReferencesRequest } from '../../../api/referenceApi';
import { getSubjectRequest } from '../../../api/subjectApi';
import { WASTE_MANAGEMENT_TYPE_IDS } from '../../../constants/enums';
import { getInitialForm1Item } from '../../../constants/lkCadastr/cadastrForm1';
import { initialCadastreErrorFormStore } from '../../../store/cadastreStore';
import {
  FORMS_NAME,
  REPORT_STATUS_NAMES,
  calcBlockedForm,
  calcReportPercentage,
  flexGap2,
  flexRowGap2,
  formsPercentGetter,
  getCompleteColor,
  getInitialForm,
  getPercentFormName,
  getReportFormIdString,
} from '../../../constants/lkCadastr/cadastrStorage';
import {
  CADASTRE_DUPLICATE_PREVIOUS_REPORT_URL,
  ENTERPRISE_ACTIVITY_TYPES_URL,
  OBJECTIVES_URL,
} from '../../../constants/urls';
import { compareId } from '../../../helpers';
import cadastreFormsPrepareFrontend from '../../../helpers/cadastreForms/cadastreFormsPrepareFrontend';
import {
  deleteForm1Area,
  deleteForm1Building,
  deleteForm1Container,
  deleteForm1Waste,
  saveForm1Buildings,
} from '../../../helpers/cadastreForms/form1Helper';
import {
  deleteForm2ComponentComposition,
  deleteForm2ReportWaste,
  saveForm2ReportWaste,
} from '../../../helpers/cadastreForms/form2Helper';
import {
  saveForm3ReportGeneration,
} from '../../../helpers/cadastreForms/form3Helper';
import {
  deleteForm4ApprovalStandard,
  deleteForm4ApprovalWaste,
  deleteForm4LimitWaste,
  saveForm4ReportApprovalStandards,
} from '../../../helpers/cadastreForms/form4Helper';
import {
  deleteForm5IndustrialArea,
  deleteForm5ReportLicense,
  saveForm5ReportLicense,
} from '../../../helpers/cadastreForms/form5Helper';
import {
  deleteForm6ActualVolume,
  deleteForm6TransportedWaste,
  saveForm6ReportActualVolumes,
} from '../../../helpers/cadastreForms/form6Helper';
import {
  saveForm7ReportTechnologies,
} from '../../../helpers/cadastreForms/form7Helper';
import {
  saveForm8ReportPlacingObjects,
} from '../../../helpers/cadastreForms/form8Helper';
import { getDayMonthNameYearTime } from '../../../helpers/dateHelper';
import { objectChangeDotProperty } from '../../../helpers/objectHelper';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import { useApi, usePushNotification } from '../../../hooks';
import useGetCadastreForms from '../../../hooks/cadastre/useGetCadastreForms';
import { useCadastreStore, useUserStore } from '../../../store';
import BackdropLoading from '../../BackdropLoading';
import CustomButton from '../../Buttons/CustomButton';
import DeleteButton from '../../Buttons/DeleteButton';
import CustomCheckbox from '../../CustomCheckbox';
import CustomInput from '../../Inputs';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete';
import { Text, TextMain } from '../../Text';
import ModalWithTitle from '../ModalWithTitle';
import ModalCadastrForm1 from './ModalCadastrForm1';
import ModalCadastrForm2 from './ModalCadastrForm2';
import ModalCadastrForm3 from './ModalCadastrForm3';
import ModalCadastrForm4 from './ModalCadastrForm4';
import ModalCadastrForm5 from './ModalCadastrForm5';
import ModalCadastrForm6 from './ModalCadastrForm6';
import ModalCadastrForm7 from './ModalCadastrForm7';
import ModalCadastrForm8 from './ModalCadastrForm8';
import CenteredModal from '../CenteredModal';
import ModalColumnTitle from '../components/ModalColumnTitle';

const buttonStyle = { height: '32px', width: 'fit-content' };

export default function ModalCadastrForms({
  close,
  isCreating = false,
  initialForm = 'form1',
  isDemo,
  isReview,
  saveAllComments,
  report,
  isLoading,
  allRerorts = [],
  isAdminEditor,
}) {
  const pushNotification = usePushNotification();
  const [reportForm, setReportForm] = useState(report);
  const { userStore } = useUserStore();
  const formRef = useRef();
  const { setCadastreStore } = useCadastreStore();
  const [forms, setForms] = useState(getInitialForm());
  const [initialForms, setInitialForms] = useState(getInitialForm());
  const [formsLoading, setLoading] = useState(false);
  const [isFieldLoading, setChangeFieldLoading] = useState(false);
  const [sureModalInfo, setSureModalInfo] = useState('');
  const [confirmAuthenticityError, setConfirmAuthenticityError] = useState('');
  const [completedForms, setCompletedForms] = useState({});
  const [isOpenSendReport, openSendReportModal] = useState(false);
  const [changedForm, setChangedForm] = useState('');
  const [formsCommentary, setFormsCommentary] = useState([]);
  const [enterpriseActivityTypes, setEnterpriseActivityTypes] = useState([]);
  const [visibleForm, setVisibleForm] = useState(initialForm);
  const [previousReportsModal, setPreviousReportsModal] = useState(null);
  const [selectedPreviousReport, setSelectedPreviousReport] = useState({});
  const [reportStatuses, setReportStatuses] = useState([]);

  const subjectAttributes = reportForm?.subject_attributes?.attributes
    ? { ...reportForm?.subject_attributes?.attributes, ...reportForm?.subject_attributes }
    : userStore;

  const isHideForm5 = !FORMS_NAME.find((item) => compareId(item.id, 5)).show(subjectAttributes);
  const isLicenseUnneeded = reportForm?.is_license_unneeded;

  const excludeLicense = isHideForm5 || isLicenseUnneeded;

  const { isLoading: statusLoading } = useApi({
    setter: setReportStatuses,
    request: getCadastreReportStatusesRequest,
    shouldRequest: true,
  });

  const findReportStatuses = (statusName) => reportStatuses.find((status) => status.code === statusName);

  const isDisabledMode = !isAdminEditor && (isDemo
    || (
      reportForm?.report_status_attributes?.attributes?.code
      && ![REPORT_STATUS_NAMES.formation, REPORT_STATUS_NAMES.has_warnings]
        .some((status) => status === reportForm?.report_status_attributes?.attributes?.code)
    ));

  useEffect(() => {
    if (!isDisabledMode) {
      pushNotification({ message: 'В случае, если данных для заполнения поля нет, укажите 0', variant: 'warning' });
    }
  }, [isDisabledMode]);

  const openForm = (formType) => setVisibleForm(formType);

  const createReport = async (shouldSaveReport) => {
    let reportId = reportForm?.id;

    if (!reportId) {
      const responseData = await cadastreReportHistoryRequest({
        method: METHODS_API.POST,
        data: {
          subject_id: subjectAttributes.id,
        },
      });

      reportId = responseData?.id;

      if (shouldSaveReport) {
        setReportForm((prev) => ({
          ...prev,
          id: reportId,
        }));
      }
    }

    return reportId;
  };

  const saveFormsAfterImport = async (previousForms, reportId, getAllFormsCallback) => {
    await saveForm1Buildings({ forms: previousForms, reportId, isImportToExistingReport: true });
    const { newForms: updatedForms1 } = await getAllFormsCallback(reportId, { returnInfo: true });

    await saveForm2ReportWaste({
      forms: updatedForms1, reportId, isImportToExistingReport: true, previousForms,
    });
    const { newForms: updatedForms2 } = await getAllFormsCallback(reportId, { returnInfo: true });

    await saveForm3ReportGeneration({
      forms: updatedForms2, reportId, isImportToExistingReport: true, previousForms,
    });

    await saveForm4ReportApprovalStandards({
      forms: previousForms, reportId, isImportToExistingReport: true,
    });

    await saveForm5ReportLicense({ forms: previousForms, reportId, isImportToExistingReport: true });

    await saveForm6ReportActualVolumes({ forms: previousForms, reportId, isImportToExistingReport: true });
    const { newForms: updatedForms6 } = await getAllFormsCallback(reportId, { returnInfo: true });

    await saveForm7ReportTechnologies({
      forms: updatedForms6, reportId, isImportToExistingReport: true, previousForms,
    });
    const { newForms: updatedForms7 } = await getAllFormsCallback(reportId, { returnInfo: true });

    await saveForm8ReportPlacingObjects({
      forms: updatedForms7, reportId, isImportToExistingReport: true, previousForms,
    });

    setChangedForm('');
  };

  const updateReport = async ({
    newCompletedForms, data, reportId, isImportToExistingReport, newForms, getAllFormsCallback,
  }) => {
    const freshReport = await cadastreReportHistoryRequest({
      method: METHODS_API.PUT,
      data: {
        filling_percentage: calcReportPercentage({ userStore: subjectAttributes, completedForms: newCompletedForms }),
        [getPercentFormName(1)]: newCompletedForms.form1,
        [getPercentFormName(2)]: newCompletedForms.form2,
        [getPercentFormName(3)]: newCompletedForms.form3,
        [getPercentFormName(4)]: newCompletedForms.form4,
        [getPercentFormName(5)]: newCompletedForms.form5,
        [getPercentFormName(6)]: newCompletedForms.form6,
        [getPercentFormName(7)]: newCompletedForms.form7,
        [getPercentFormName(8)]: newCompletedForms.form8,
        ...data,
      },
      id: reportId || reportForm.id,
    });

    if (isImportToExistingReport) {
      await saveFormsAfterImport(newForms, reportId, getAllFormsCallback);

      return;
    }

    setReportForm(freshReport);
  };

  const { getAllForms, getDebouncedCallback: getAllFormsDebounced } = useGetCadastreForms({
    report: reportForm,
    forms,
    setForms: (newForms) => {
      formRef.current = newForms;
      setForms(newForms);
    },
    setReport: setReportForm,
    setFormsCommentary,
    setInitialForms,
    setLoading,
    setCadastreStore,
    setCompletedForms,
    excludeLicense,
    findReportStatuses,
    updateReport,
  });

  useEffect(() => {
    getAllFormsDebounced();

    return () => {
      setCadastreStore((prev) => ({
        ...prev,
        ...initialCadastreErrorFormStore,
      }));
    };
  }, []);

  const calcForm2and3CompletePercent = (newForms) => {
    const newCompletedForms = {};

    ['form2', 'form3'].forEach((formName) => {
      newCompletedForms[formName] = formsPercentGetter[formName](newForms, { errorSetter: setCadastreStore });
    });

    return newCompletedForms;
  };

  const changeItemFormByField = async ({
    field, value, type,
  }) => {
    const newForms = objectChangeDotProperty({ field, value, object: formRef.current || forms });

    let formName = field.split('.')[0];

    if (field.includes('form2Waste')) formName = 'form2';
    else if (field.includes('form3Waste')) formName = 'form3';

    let newCompletedForms = {
      ...completedForms,
    };

    newCompletedForms[formName] = formsPercentGetter[formName](newForms, {
      ignoreLicense: excludeLicense,
      errorSetter: setCadastreStore,
    });

    if (field.includes('form1') && !field.includes('form2') && !field.includes('form3')) {
      newCompletedForms = { ...newCompletedForms, ...calcForm2and3CompletePercent(newForms) };
    }

    setChangedForm(formName);

    setForms(newForms);

    formRef.current = newForms;

    setCompletedForms(newCompletedForms);

    if (['delete', 'import'].includes(type)) {
      setInitialForms(newForms);
      updateReport({ newCompletedForms });
    }
  };

  const deleteForm1Entity = async ({
    buildingId,
    wasteId,
    areaId,
    containerId,
  }) => {
    let form1DeleteRequest = deleteForm1Building;

    if (containerId) form1DeleteRequest = deleteForm1Container;
    if (wasteId) form1DeleteRequest = deleteForm1Waste;
    if (areaId) form1DeleteRequest = deleteForm1Area;

    const deletedId = await form1DeleteRequest({
      buildingId,
      wasteId,
      areaId,
      containerId,
    });

    if (deletedId) return deletedId;
  };

  const deleteForm2Entity = ({
    reportWasteId,
    componentCompositionId,
  }) => {
    const mainDeleteRequest = reportWasteId ? deleteForm2ReportWaste : deleteForm2ComponentComposition;

    return mainDeleteRequest({ reportWasteId, componentCompositionId });
  };

  const deleteForm4Entity = ({
    limitWasteId,
    approvalWasteId,
    approvalStandardId,
  }) => {
    let mainDeleteRequest = deleteForm4ApprovalStandard;

    if (limitWasteId) mainDeleteRequest = deleteForm4LimitWaste;
    else if (approvalWasteId) mainDeleteRequest = deleteForm4ApprovalWaste;

    return mainDeleteRequest({ limitWasteId, approvalWasteId, approvalStandardId });
  };

  const deleteForm5Entity = ({ industrialAreaId, reportLicenseId }) => {
    let mainDeleteRequest = deleteForm5ReportLicense;

    if (industrialAreaId) mainDeleteRequest = deleteForm5IndustrialArea;

    return mainDeleteRequest({ industrialAreaId, reportLicenseId });
  };

  const deleteForm6Entity = ({ actualVolumeId, transportedWasteId }) => {
    let mainDeleteRequest = deleteForm6ActualVolume;

    if (transportedWasteId) mainDeleteRequest = deleteForm6TransportedWaste;

    return mainDeleteRequest({ actualVolumeId, transportedWasteId });
  };

  const calculateReportPercent = (formName = '') => {
    const newReportFormsPercent = {};

    const formId = formName.replace('form', '');

    FORMS_NAME.forEach((form) => {
      if (formName && !compareId(formId, form.id)) return;

      if (form.show(subjectAttributes)) {
        newReportFormsPercent[getPercentFormName(form.id)] = +completedForms[`form${form.id}`] || 0;
      }
    });

    return newReportFormsPercent;
  };

  const changeReportPercent = async (reportId, formName) => {
    const formationStatusId = reportStatuses
      .find((reportStatus) => reportStatus.code === REPORT_STATUS_NAMES.formation)?.id;

    const newCompletedForms = formName ? cadastreFormsPrepareFrontend({
      excludeLicense,
      errorSetter: setCadastreStore,
      forms: ['form1', 'form2', 'form3'].includes(formName) ? (
        cloneDeep({
          ...initialForms,
          form1: {
            ...initialForms.form1,
            ...forms.form1,
          },
        })
      ) : (
        cloneDeep({
          ...initialForms,
          [formName]: { ...forms[formName] },
        })
      ),
    }) : completedForms;

    const statusParams = {};

    if (!isAdminEditor) statusParams.report_status_id = formationStatusId;

    const newReportHistory = await cadastreReportHistoryRequest({
      method: METHODS_API.PUT,
      data: {
        ...calculateReportPercent(formName),
        filling_percentage: calcReportPercentage({ userStore: subjectAttributes, completedForms: newCompletedForms }),
        confirm_accuracy_information: reportForm.confirm_accuracy_information,
        ...statusParams,
      },
      id: reportId,
    });

    if (formName) {
      const reportPercentName = getPercentFormName(formName.replace('form', ''));

      if (!compareId(newReportHistory[reportPercentName], 100)) {
        pushNotification({ message: `Форма №${formName.replace('form', '')} заполнена не на 100%` });
      }
    }
  };

  const { isLoading: activityTypesLoading } = useApi({
    request: () => getReferencesRequest({ url: ENTERPRISE_ACTIVITY_TYPES_URL }),
    setter: (options) => {
      setEnterpriseActivityTypes(options.filter(({ code }) => code !== 'management'));
    },
    shouldRequest: !isReview,
  });

  const enterpriseAccumulationParam = enterpriseActivityTypes
    .filter((item) => item.code === 'accumulation')
    .map((item) => item.id)
    .join(',');

  const wasteExcludeAccumulationParams = {
    [WASTE_MANAGEMENT_TYPE_IDS]: enterpriseActivityTypes
      .filter((item) => item.code !== 'accumulation')
      .map((item) => item.id)
      .join(','),
  };

  const wasteExcludeAccumulationCodes = enterpriseActivityTypes
    .filter((item) => item.code !== 'accumulation')
    .map((item) => item.code);

  const getSubjectObjects = () => getSubjectRequest({
    id: subjectAttributes.id,
    url: OBJECTIVES_URL,
    params: wasteExcludeAccumulationParams,
  });

  const getFullCompletedForm1 = (newForms) => ({
    ...{
      ...completedForms,
      form1: formsPercentGetter.form1(newForms, { errorSetter: setCadastreStore }),
    },
    ...calcForm2and3CompletePercent(newForms),
  });

  const { isLoading: subjectObjectsLoading } = useApi({
    request: getSubjectObjects,
    setter: (subjectObjects) => {
      subjectObjects.forEach((object, objectIndex) => {
        let newFormsBuilding = {};

        setReportForm((prevState) => ({ ...prevState, confirm_accuracy_information: true }));

        if (objectIndex === 0) {
          newFormsBuilding = objectChangeDotProperty({
            field: `form1.items.${objectIndex}.objective_id`,
            value: {
              ...object,
              label: formatObjectWithAddress({ objective: object }),
            },
            object: forms,
          });
        } else {
          newFormsBuilding = objectChangeDotProperty({
            field: 'form1.items',
            value: [...forms.form1.items, {
              ...getInitialForm1Item(),
              objective_id: { ...object, label: formatObjectWithAddress({ objective: object }) },
            }],
            object: forms,
          });
        }

        const newCompletedForms = getFullCompletedForm1(newFormsBuilding);

        setForms(newFormsBuilding);
        setCompletedForms(newCompletedForms);
      });
    },
    // todo remove false after test
    shouldRequest: false && !isReview && !reportForm?.id && enterpriseActivityTypes.length,
  });

  const loading = statusLoading || formsLoading || subjectObjectsLoading || activityTypesLoading;

  const closeSureModal = () => setSureModalInfo(null);

  const openConfirmationModal = () => {
    setSureModalInfo({
      text: 'Вы уверены что хотите выйти?',
      callback: (confirmed) => {
        closeSureModal();
        if (confirmed) close();
      },
    });
  };

  const saveFormApi = async () => {
    closeSureModal();

    const reportId = await createReport();

    if (visibleForm === 'form1') {
      await saveForm1Buildings({ forms, reportId });
    } else if (visibleForm === 'form2') {
      await saveForm2ReportWaste({ forms, reportId });
    } else if (visibleForm === 'form3') {
      await saveForm3ReportGeneration({ forms, reportId });
    } else if (visibleForm === 'form4') {
      await saveForm4ReportApprovalStandards({ forms, reportId });
    } else if (visibleForm === 'form5') {
      await saveForm5ReportLicense({ forms, reportId });
    } else if (visibleForm === 'form6') {
      await saveForm6ReportActualVolumes({ forms, reportId });
    } else if (visibleForm === 'form7') {
      await saveForm7ReportTechnologies({ forms, reportId });
    } else if (visibleForm === 'form8') {
      await saveForm8ReportPlacingObjects({ forms, reportId });
    }

    await changeReportPercent(reportId, visibleForm);
    await getAllForms(reportId);
    setChangedForm('');
  };

  const { defaultRequest: saveForm } = useApi({
    request: saveFormApi,
    setIsLoading: setLoading,
  });

  const saveCommentary = async (newComments = formsCommentary) => {
    setLoading(true);
    await saveAllComments({
      id: reportForm.id,
      comments: newComments,
    });
    setLoading(false);
  };

  const changeIsConfirmAuthenticity = () => {
    setReportForm((prevState) => ({ ...prevState, confirm_accuracy_information: !prevState.confirm_accuracy_information }));
    setConfirmAuthenticityError('');
  };

  const isReportReadyToSend = !isAdminEditor
    && reportForm.confirm_accuracy_information
    && compareId(reportForm?.filling_percentage, 100)
    && compareId(calcReportPercentage({
      userStore: subjectAttributes,
      completedForms,
    }), 100);

  const onSendReport = async () => {
    const sentStatusId = reportStatuses
      .find((reportStatus) => reportStatus.code === REPORT_STATUS_NAMES.sent)?.id;

    await cadastreReportHistoryRequest({
      data: { report_status_id: sentStatusId },
      method: METHODS_API.PUT,
      id: reportForm.id,
    });
  };

  const changeReportToChecked = async () => {
    await cadastreReportHistoryRequest({
      data: {
        report_status_id: reportStatuses
          .find((reportStatus) => reportStatus?.code === REPORT_STATUS_NAMES.checked)?.id,
      },
      method: METHODS_API.PUT,
      id: reportForm.id,
    });
  };

  const { defaultRequest: sendReport } = useApi({
    request: onSendReport,
    setter: () => {
      close();
    },
  });

  const { defaultRequest: onChangeReportToChecked } = useApi({
    request: changeReportToChecked,
    setter: () => {
      close();
    },
  });

  const openPreviousReports = () => {
    setPreviousReportsModal(allRerorts);
  };

  const closePreviousReports = () => {
    setPreviousReportsModal();
  };

  const isCheckingReport = reportForm?.report_status_attributes?.attributes?.code
    === REPORT_STATUS_NAMES.checking;

  const changeLicenseUnneeded = async () => {
    const calcUnneedLicense = (formName) => formsPercentGetter[formName](forms, {
      ignoreLicense: isHideForm5 || !reportForm?.is_license_unneeded,
      errorSetter: setCadastreStore,
    });

    setCompletedForms((prev) => ({
      ...prev,
      form5: calcUnneedLicense('form5'),
      form7: calcUnneedLicense('form7'),
      form8: calcUnneedLicense('form8'),
    }));
    setReportForm((prev) => ({
      ...prev,
      is_license_unneeded: !prev?.is_license_unneeded,
    }));
    setChangeFieldLoading(true);

    try {
      await updateReport({
        newCompletedForms: {
          ...completedForms,
          form5: calcUnneedLicense('form5'),
          form7: calcUnneedLicense('form7'),
          form8: calcUnneedLicense('form8'),
        },
        data: { is_license_unneeded: !reportForm?.is_license_unneeded },
      });
    } catch (e) {
      console.error(e);
    }

    setChangeFieldLoading(false);
  };

  const getStatusTextColor = ({ palette }) => {
    switch (reportForm?.report_status_attributes?.attributes?.name) {
      case 'Есть замечания':
        return palette.error.main;
      case 'Формируется':
        return palette.primary.main;
      case 'Отправлен':
        return palette.warning.main;
      case 'На проверке':
        return palette.warning.main;
      case 'Проверен, ожидается бумажный документ':
        return palette.primary.main;
      case 'Бумажный документ распечатан':
        return palette.warning.main;
      case 'Бумажный документ принят':
        return palette.success.main;
      default:
        return palette.primary.main;
    }
  };

  const findForm1Object = (id) => forms.form1.items.find(({ objective_id }) => compareId(id, objective_id?.id));

  const form5Licenses = forms.form5.licenses.filter((item) => item?.id !== undefined);

  const notFullFormIndex = useMemo(() => calcBlockedForm({
    userStore: subjectAttributes,
    reportForm,
    completedForms,
  }), [completedForms, userStore, reportForm]);

  const previousReports = useMemo(
    () => allRerorts.map((item) => ({
      id: item.id,
      label: `Отчёт №${item.id} / ${new Date(item.approved_date).getFullYear() - 1}`,
      value: item.id,
    })),
    [allRerorts],
  );

  const changePreviousReport = (e, value) => setSelectedPreviousReport(value);

  const importPreviousReport = async () => {
    setLoading(true);

    const selectedId = selectedPreviousReport.id;

    if (reportForm.id) {
      getAllForms(selectedId, { reportId: reportForm.id });
    } else {
      const duplicatedReport = await cadastreReportHistoryPreviousRequest({
        method: METHODS_API.POST,
        rest: CADASTRE_DUPLICATE_PREVIOUS_REPORT_URL,
        id: selectedId,
      });

      if (duplicatedReport?.id) getAllForms(duplicatedReport?.id);
    }

    setSelectedPreviousReport({});
    closePreviousReports();
  };

  const closeSendReport = () => openSendReportModal(false);

  const isFormation = REPORT_STATUS_NAMES.formation === reportForm?.report_status_attributes?.attributes?.code;

  return (
    <>
      <BackdropLoading
        alertText="Идёт сохранение данных"
        isLoading={isFieldLoading}
        position="fixed"
        sx={{ zIndex: 99999 }}
      />
      <ModalWithTitle
        open={sureModalInfo?.text}
        mainSx={{ ...flexRowGap2, justifyContent: 'center' }}
        containerSx={{ width: 'fit-content' }}
        title={sureModalInfo?.text}
      >
        <CustomButton sx={{ width: 100 }} onClick={() => sureModalInfo.callback(true)}>Да</CustomButton>
        <CustomButton sx={{ width: 100 }} color="error" onClick={() => sureModalInfo.callback(false)}>Нет</CustomButton>
      </ModalWithTitle>
      <ModalWithTitle
        mainSx={{ ...flexRowGap2, justifyContent: 'center' }}
        containerSx={{ width: 'fit-content' }}
        title="Импорт из предыдущих отчётов"
        open={previousReportsModal}
        close={closePreviousReports}
      >
        <CustomAutocomplete label="Выбрать предыдущий отчёт" value={selectedPreviousReport} onChange={changePreviousReport} options={previousReports} />
        <CustomButton disabled={!selectedPreviousReport?.id || loading} onClick={importPreviousReport}>Импортировать</CustomButton>
        <CustomButton color="error" onClick={closePreviousReports}>Отмена</CustomButton>
      </ModalWithTitle>
      <ModalWithTitle
        open
        containerSx={{ width: '100%', height: '100vh', maxHeight: '100vh' }}
        mainSx={{ p: '0 32px 0' }}
        titleContainerSx={{ paddingBottom: 0 }}
        title={`${isReview ? 'Проверка' : 'Создание'} отчёта`}
        additionalTitleComponent={(
          <Alert sx={{ alignItems: 'center' }} severity="warning">
            <Text medium>
              В случае, если данных для заполнения поля нет, укажите
              {' '}
              <Text component="span" medium sx={{ fontWeight: 'bold' }}>0.</Text>
            </Text>
            <Text medium>
              Обращаем внимание на необходимость сохранения каждой формы, во избежание потери данных.
            </Text>
          </Alert>
        )}
        subTitle={(
          <>
            <Text big component="span">
              <Text medium>
                {reportForm?.id ? `${getReportFormIdString({
                  id: reportForm.id,
                  code: reportForm.report_status_attributes?.attributes?.code,
                  created_at: reportForm.created_at,
                })} ${reportForm.subject_attributes?.attributes?.name}` : ''}
              </Text>
              Заполнен на:
              {' '}
              <Text big component="span" sx={{ color: getCompleteColor(reportForm?.filling_percentage) }}>
                {reportForm?.filling_percentage ? `${reportForm?.filling_percentage}%` : '0%'}
              </Text>
              <Text big sx={{ color: getStatusTextColor }}>
                Статус:
                {' '}
                {reportForm?.report_status_attributes?.attributes?.name || 'Формируется'}
              </Text>
            </Text>
            {Boolean(previousReports?.length) && (isCreating || isFormation) && (
              <CustomButton color="warning" isLoading={loading} onClick={openPreviousReports}>
                Импорт сведений из предыдущего отчетного периода
              </CustomButton>
            )}
          </>
        )}
        close={close ? openConfirmationModal : undefined}
      >
        <Box display="flex" position="relative" height="100%">
          <Box
            width="50rem"
            display="flex"
            flexDirection="column"
            paddingTop={3}
            gap={3}
            pr={3}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            overflow="auto"
          >
            {FORMS_NAME.map(({ label, show }, index) => {
              if (!show?.(subjectAttributes)) return null;

              const key = `form${index + 1}`;

              const isActiveButton = key === visibleForm;
              const currentCommentary = formsCommentary.filter((comment) => comment.comment_type === key);

              const hasComments = Boolean(currentCommentary?.length);

              const isReviewCommentary = isReview && (isActiveButton || hasComments);
              const isCadastreSubjectCommentary = !isReview && hasComments;

              const notCompletedForm = (changedForm && key !== changedForm) || (notFullFormIndex !== undefined && index + 1 > notFullFormIndex);

              const disabledFormButton = (!isReview && !isDemo && notCompletedForm);

              return (
                <Box key={key}>
                  <CustomButton
                    sx={{ height: 'fit-content', width: 'fit-content', textAlign: 'left' }}
                    variant={isActiveButton ? 'contained' : 'outlined'}
                    disabled={disabledFormButton}
                    onClick={() => openForm(key)}
                  >
                    {index + 1}
                    .
                    {' '}
                    {label}
                    {' '}
                    -
                    {' '}
                    <Text component="span" sx={{ fontWeight: '600', color: getCompleteColor(completedForms[key]) }}>
                      {completedForms[key] || 0}
                      %
                    </Text>
                  </CustomButton>
                  {(isReviewCommentary || isCadastreSubjectCommentary) && (
                    <Box sx={{ ...flexGap2, my: 3 }}>
                      {isReviewCommentary && (
                        <CustomButton
                          onClick={() => {
                            const newComments = [
                              ...formsCommentary,
                              {
                                created_at: new Date(),
                                comment: '',
                                comment_type: key,
                                id: Math.random(),
                              },
                            ];
                            setFormsCommentary(newComments);
                          }}
                          color="warning"
                          sx={{ height: '42px', width: 'fit-content' }}
                        >
                          Добавить коментарий
                        </CustomButton>
                      )}
                      {currentCommentary?.map((item) => {
                        const foundIndex = formsCommentary.findIndex((comment) => item.id === comment.id);

                        const deleteCommentary = async () => {
                          const newComments = formsCommentary.slice();
                          newComments.splice(foundIndex, 1);

                          setFormsCommentary(newComments);

                          saveCommentary(newComments);
                        };

                        return (
                          <Box sx={{ ...flexRowGap2, alignItems: 'center' }}>
                            <TextMain>{getDayMonthNameYearTime(item?.created_at)}</TextMain>
                            <CustomInput
                              multiline
                              key={item.commentable_id}
                              disabled={!isReview}
                              sx={{ flex: '1 1 0' }}
                              onChange={(e, value) => {
                                const newComments = objectChangeDotProperty({ field: `${foundIndex}.comment`, value, object: formsCommentary });

                                setFormsCommentary(newComments);
                              }}
                              debounceTime={0}
                              label="Комментарий"
                              value={item.comment}
                            />
                            {isReviewCommentary && <DeleteButton onClick={deleteCommentary}>Удалить комментарий</DeleteButton>}
                          </Box>
                        );
                      })}

                      {isReview && currentCommentary && (
                        <CustomButton
                          variant="link"
                          isLoading={loading}
                          sx={{ height: '32px', color: 'orange' }}
                          onClick={() => saveCommentary()}
                        >
                          Сохранить комментарии
                        </CustomButton>
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
            {!isDisabledMode && (
              <>
                <CustomCheckbox
                  required
                  formControlSx={{ pl: '2px' }}
                  error={confirmAuthenticityError}
                  label="Достоверность представленных сведений подтверждаю"
                  onChange={changeIsConfirmAuthenticity}
                  value={reportForm.confirm_accuracy_information}
                />
                <CenteredModal
                  close={closeSendReport}
                  open={isOpenSendReport}
                  containerSx={{
                    width: 'fit-content', maxWidth: 640, p: '40px', borderRadius: '16px',
                  }}
                >
                  <ModalColumnTitle>
                    Вы уверены, что хотите отправить отчёт на проверку?
                  </ModalColumnTitle>
                  <Alert severity="warning">После отправки отчета вы не сможете его редактировать</Alert>
                  <Box display="flex" justifyContent="center" gap={2} mt="40px">
                    <CustomButton variant="contained" onClick={sendReport}>Да, отправить</CustomButton>
                    <CustomButton variant="contained" color="error" onClick={closeSendReport}>Отмена</CustomButton>
                  </Box>
                </CenteredModal>
                <Box sx={{ ...flexRowGap2, alignItems: 'center' }}>
                  <CustomButton
                    isLoading={loading}
                    color="warning"
                    onClick={saveForm}
                    sx={buttonStyle}
                  >
                    Сохранить форму
                  </CustomButton>
                  {isReportReadyToSend && !changedForm && (
                    <CustomButton sx={{ height: '46px' }} isLoading={loading || isLoading} onClick={() => openSendReportModal(true)}>
                      Отправить отчёт на проверку
                    </CustomButton>
                  )}
                </Box>
              </>
            )}
            {isReview && isCheckingReport && (
              <CustomButton
                sx={{ height: '46px' }}
                color="warning"
                onClick={onChangeReportToChecked}
              >
                Проверено
              </CustomButton>
            )}
          </Box>
          {loading ? <BackdropLoading isLoading /> : (
            <Box
              left="50rem"
              sx={{ overflowY: 'auto', overflowX: 'hidden' }}
              position="absolute"
              top={0}
              bottom={0}
              right={0}
              p={3}
            >
              {visibleForm === 'form1' && (
                <ModalCadastrForm1
                  subjectAttributes={subjectAttributes}
                  isDemo={isDisabledMode}
                  deleteForm1Entity={deleteForm1Entity}
                  reportId={reportForm?.id}
                  enterpriseAccumulationParam={enterpriseAccumulationParam}
                  wasteExcludeAccumulationCodes={wasteExcludeAccumulationCodes}
                  wasteExcludeAccumulationParams={wasteExcludeAccumulationParams}
                  changeItemFormByField={changeItemFormByField}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form1.${fieldProps.field}`,
                  })}
                  data={forms.form1}
                />
              )}
              {visibleForm === 'form2' && (
                <ModalCadastrForm2
                  isDemo={isDisabledMode}
                  deleteForm2Entity={deleteForm2Entity}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form1.${fieldProps.field}`,
                  })}
                  changeItemFormByField={changeItemFormByField}
                  data={forms.form1}
                />
              )}
              {visibleForm === 'form3' && (
                <ModalCadastrForm3
                  isDemo={isDisabledMode}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form1.${fieldProps.field}`,
                  })}
                  onChangeForm3={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form3.${fieldProps.field}`,
                  })}
                  changeItemFormByField={changeItemFormByField}
                  form3Items={forms.form3}
                  data={forms.form1}
                />
              )}
              {visibleForm === 'form4' && (
                <ModalCadastrForm4
                  subjectAttributes={subjectAttributes}
                  isDemo={isDisabledMode}
                  deleteForm4Entity={deleteForm4Entity}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form4.${fieldProps.field}`,
                  })}
                  form1Objects={forms.form1.items}
                  changeItemFormByField={changeItemFormByField}
                  data={forms.form4}
                />
              )}
              {visibleForm === 'form5' && (
                <ModalCadastrForm5
                  deleteForm5Entity={deleteForm5Entity}
                  isDemo={isDisabledMode}
                  form1Objects={forms.form1.items}
                  changeLicenseUnneeded={changeLicenseUnneeded}
                  is_license_unneeded={isLicenseUnneeded}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form5.${fieldProps.field}`,
                  })}
                  changeItemFormByField={changeItemFormByField}
                  data={forms.form5}
                />
              )}
              {visibleForm === 'form6' && (
                <ModalCadastrForm6
                  isDemo={isDisabledMode}
                  deleteForm6Entity={deleteForm6Entity}
                  report={reportForm}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form6.${fieldProps.field}`,
                  })}
                  changeItemFormByField={changeItemFormByField}
                  data={forms.form6}
                />
              )}
              {visibleForm === 'form7' && (
                <ModalCadastrForm7
                  isHideForm5={isHideForm5}
                  isDemo={isDisabledMode}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form7.${fieldProps.field}`,
                  })}
                  wasteExcludeAccumulationParams={wasteExcludeAccumulationParams}
                  form1Objects={forms.form1.items}
                  data={forms.form7}
                  form5Licenses={form5Licenses}
                  findForm1Object={findForm1Object}
                  changeLicenseUnneeded={changeLicenseUnneeded}
                  is_license_unneeded={isLicenseUnneeded}
                />
              )}
              {visibleForm === 'form8' && (
                <ModalCadastrForm8
                  isHideForm5={isHideForm5}
                  isDemo={isDisabledMode}
                  wasteExcludeAccumulationParams={wasteExcludeAccumulationParams}
                  findForm1Object={findForm1Object}
                  form1Objects={forms.form1.items}
                  onChange={(fieldProps) => changeItemFormByField({
                    ...fieldProps,
                    field: `form8.${fieldProps.field}`,
                  })}
                  subjectAttributes={subjectAttributes}
                  form5Licenses={form5Licenses}
                  changeLicenseUnneeded={changeLicenseUnneeded}
                  is_license_unneeded={isLicenseUnneeded}
                  data={forms.form8}
                />
              )}
            </Box>
          )}
        </Box>
      </ModalWithTitle>
    </>
  );
}
