import React from 'react';
import { Box } from '@mui/material';
import CenteredModal from './CenteredModal';
import BackdropLoading from '../BackdropLoading';
import { TextTitle } from '../Text';
import CloseModalButton from '../Buttons/CloseModalButton';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';

export default function ModalWithTitle({
  children,
  open,
  title,
  subTitle,
  close,
  isLoading,
  additionalTitleComponent,
  containerSx,
  mainSx,
  titleContainerSx,
  subTitleProps,
}) {
  const { isMobileTablet } = useCustomMediaQuery();

  return (
    <CenteredModal
      close={close}
      open={open}
      containerSx={containerSx}
    >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box p={isMobileTablet ? 1 : '32px'} sx={titleContainerSx}>
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {title && (
              <Box sx={{ ...flexRowGap2, alignItems: 'center' }}>
                <TextTitle h1>{title}</TextTitle>
                {additionalTitleComponent}
              </Box>
            )}
            {close && <CloseModalButton close={close} />}
          </Box>
          {subTitle && <TextTitle h2 {...subTitleProps}>{subTitle}</TextTitle>}
        </Box>
        <Box height="100%" position="relative" p={isMobileTablet ? '0 8px 8px ' : '0 32px 32px'} sx={{ ...mainSx }}>
          <BackdropLoading isLoading={isLoading} />
          {children}
        </Box>
      </Box>
    </CenteredModal>
  );
}
