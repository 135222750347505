// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  updateSubjectEmailPasswordRequest,
  updateSubjectRequest,
} from '../../api/subjectApi';
import { getMemberRequest } from '../../api/userApi';
import CadastrRegistrationData from '../../components/Cadastr/CadastrRegistrationData';
import {
  DEFAULT_ENUM_OPTION,
  ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES,
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_TYPE_ENUM,
  ENTERPRISE_TYPE_TEXT_ENUM,
  HAZARD_CLASS_ENUM,
  HAZARD_CLASS_ENUM_TEXT,
} from '../../constants/enums';
import { formValidator } from '../../helpers';
import { VALIDATION_TEMPLATE } from '../../helpers/formValidator';
import { upperCaseFirstLetter } from '../../helpers/stringHelper';
import { useApi, usePushNotification } from '../../hooks';
import { useUserStore } from '../../store';

const initialUserInfo = {
  email: '',
  phone: '',
  password: '',
  old_password: '',
  name: '',
  password_confirmation: '',
  registry_address: '',
  actual_address: '',
  subject_type: { ...DEFAULT_ENUM_OPTION },
  [ENTERPRISE_ACTIVITY_TYPE_IDS]: [],
  [HAZARD_CLASS_ENUM]: [],
  [ENTERPRISE_TYPE_ENUM]: '',
  first_name: '',
  full_name: '',
  last_name: '',
  boss_email: '',
  boss_phone: '',
  boss_name: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  job_title: '',
};

const initialEditField = {
  phone: false,
  email: false,
  name: '',
  boss_email: false,
  boss_phone: false,
  boss_name: false,
  contact_name: false,
  contact_email: false,
  contact_phone: false,
  registry_address: false,
  actual_address: false,
  [ENTERPRISE_ACTIVITY_TYPE_IDS]: false,
  [ENTERPRISE_TYPE_ENUM]: false,
  [HAZARD_CLASS_ENUM]: false,
  subject_type: false,
  job_title: false,
};

export default function CadastrGeneralInfoPage() {
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [editField, setEditField] = useState(initialEditField);
  const { userStore, setUserStore } = useUserStore();
  const [error, setError] = useState({});
  const pushNotification = usePushNotification();

  const translateSubject = (subjectData) => {
    let newSubjectData = subjectData;
    newSubjectData = {
      ...newSubjectData,
      [HAZARD_CLASS_ENUM]: newSubjectData[HAZARD_CLASS_ENUM].map((item, index) => ({
        label: upperCaseFirstLetter(newSubjectData[HAZARD_CLASS_ENUM_TEXT][index]),
        value: item,
        id: item,
      })),
      [ENTERPRISE_ACTIVITY_TYPE_IDS]: newSubjectData[ENTERPRISE_ACTIVITY_TYPE_IDS]
        .map((item, index) => ({
          label: upperCaseFirstLetter(
            newSubjectData[ENTERPRISE_ACTIVITY_TYPE_ATTRIBUTES]?.[index]?.attributes?.name,
          ),
          value: String(item),
          id: String(item),
        })),
      [ENTERPRISE_TYPE_ENUM]: {
        label: upperCaseFirstLetter(
          newSubjectData[ENTERPRISE_TYPE_TEXT_ENUM] || '',
        ),
        value: newSubjectData[ENTERPRISE_TYPE_ENUM] || '',
        id: newSubjectData[ENTERPRISE_TYPE_ENUM] || '',
      },
    };

    setUserInfo(newSubjectData);
  };

  const { makeRequest: getMember, isLoading } = useApi({
    request: async (shouldSetValue = true) => {
      const userData = await getMemberRequest();

      if (shouldSetValue) {
        setUserStore(userData);

        translateSubject(userData);
      }
    },
  });

  const updateCarrier = async (fields) => {
    const { hasErrors, validField } = formValidator({
      form: userInfo,
      ignoreInputs: ['full_name'],
      checkOnlyFields: fields,
      customRules: [
        { name: VALIDATION_TEMPLATE.password },
        { name: VALIDATION_TEMPLATE.password_confirmation },
        { name: VALIDATION_TEMPLATE.email },
        { name: VALIDATION_TEMPLATE.phone },
      ],
      inputRule: {
        password: [VALIDATION_TEMPLATE.password],
        password_confirmation: [VALIDATION_TEMPLATE.password_confirmation],
        old_password: [VALIDATION_TEMPLATE.password],
        boss_phone: [VALIDATION_TEMPLATE.phone],
        phone: [VALIDATION_TEMPLATE.phone],
      },
    });

    setError(validField);

    if (hasErrors) return;

    const form = {};
    const newEditFields = {};

    fields.forEach((innerField) => {
      newEditFields[innerField] = false;

      if (userInfo[innerField]?.label !== undefined) {
        form[innerField] = userInfo[innerField].value;
        return;
      }
      if (Array.isArray(userInfo[innerField])) {
        userInfo[innerField].forEach((item) => {
          if (item?.label !== undefined) {
            if (!Array.isArray(form[innerField])) form[innerField] = [];

            form[innerField].push(item.value);
          }
        });
        return;
      }
      form[innerField] = userInfo[innerField];
    });

    setEditField((prevState) => ({
      ...prevState,
      ...newEditFields,
    }));

    if (fields.includes('password') || fields.includes('email')) {
      const newSubject = await updateSubjectEmailPasswordRequest(userInfo.id, form);

      if (fields.includes('password')) {
        if (newSubject?.data?.data?.id) {
          pushNotification({ message: 'Пароль успешно обновлён', variant: 'success' });
        } else {
          pushNotification({ message: 'Не удалось обновить пароль' });
        }
      } else if (fields.includes('email')) {
        if (newSubject?.data?.data?.id) {
          pushNotification({ message: 'Email успешно обновлён', variant: 'success' });
        } else {
          pushNotification({ message: 'Не удалось обновить Email' });
        }
      }

      return;
    }

    const data = await updateSubjectRequest(userInfo.id, form);

    if (fields.includes('name')) setUserStore({ name: data.name });
    if (fields.includes('full_name')) setUserStore({ full_name: data.full_name });
    if (fields.includes('boss_phone')) setUserStore({ boss_phone: data.boss_phone });
    if (fields.includes(HAZARD_CLASS_ENUM)) {
      setUserStore({ [HAZARD_CLASS_ENUM]: data[HAZARD_CLASS_ENUM] });
    }
    if (fields.includes(ENTERPRISE_ACTIVITY_TYPE_IDS)) {
      setUserStore({ [ENTERPRISE_ACTIVITY_TYPE_IDS]: data[ENTERPRISE_ACTIVITY_TYPE_IDS] });
    }
    if (fields.includes(ENTERPRISE_TYPE_ENUM)) {
      setUserStore({ [ENTERPRISE_TYPE_ENUM]: data[ENTERPRISE_TYPE_ENUM] });
    }
  };

  const changeEdit = (field, value) => {
    if (!value) translateSubject(userStore);

    setEditField((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const {
    makeRequest: updateSingleSubjectField,
    isLoading: updateSubjectLoading,
  } = useApi({
    request: updateCarrier,
    errorCallback: () => translateSubject(userStore),
  });

  const onChange = ({ target: { name } }, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getMember();
    /* eslint-disable-next-line */
  }, []);

  const subject_type = useMemo(() => userStore.subject_type_attributes?.attributes?.code, [userStore]);

  return (
    <Box py={2}>
      <CadastrRegistrationData
        isLoading={updateSubjectLoading || isLoading}
        onChange={onChange}
        userInfo={userInfo}
        editField={editField}
        changeEdit={changeEdit}
        subject_type={subject_type}
        getMember={getMember}
        makeRequest={updateSingleSubjectField}
        error={error}
      />
    </Box>
  );
}
