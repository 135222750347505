// @ts-nocheck
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CenteredModal from './CenteredModal';
import Selector, { DEFAULT_SELECTOR_FIELDS } from '../Selectors/Selector';
import CustomCheckbox from '../CustomCheckbox';
import BackdropLoading from '../BackdropLoading';
import CustomInput from '../Inputs';
import PhoneInput from '../Inputs/PhoneInput';
import ModalColumnTitle from './components/ModalColumnTitle';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { TextSignature, TextTitle } from '../Text';
import CloseModalButton from '../Buttons/CloseModalButton';
import CustomButton from '../Buttons/CustomButton';
import CustomRadioGroup from '../CustomRadio';
import InnInput from '../Inputs/InnInput';
import InnLegalInput from '../Inputs/InnLegalInput';
import { compareId } from '../../helpers';
import { upperCaseFirstLetter } from '../../helpers/stringHelper';
import CustomDatePicker from '../CustomDatePicker';

const columnContainer = {
  display: 'flex',
  flexDirection: 'column',
};

const inputStyle = { width: '350px' };

const textStyle = { my: 1, color: 'black' };

function Inputs({
  inputFields, handleChange, selectorOption, inputData, error, canEdit,
}) {
  return inputFields?.map(({
    id,
    label,
    type,
    multiply,
    multiple,
    translate,
    fields,
    disabled,
    visible,
    hide,
    request,
    error: inputError,
    useDefaultFilter,
    debounceTime,
    selectFirstOption,
    additionalFilter,
    noOptionComponent,
    component,
    multiline,
    required,
    isDateTime,
    text,
    inputSx,
    sx,
    filterOptions,
  }) => {
    if (!(visible === undefined || visible?.(inputData))) return null;
    if (hide?.(inputData)) return null;

    if (component) return component;

    if (text) return <TextSignature sx={{ ...textStyle, ...sx }}>{text}</TextSignature>;

    const computedId = typeof id === 'function' ? id(inputData) : id;
    const computedRequired = typeof required === 'function' ? required(inputData) : required;
    const autocompleteError = typeof inputError === 'function' ? inputError(error) : error?.[computedId]?.[fields?.id || 'id'];
    const computedLabel = typeof label === 'function' ? upperCaseFirstLetter(label(inputData)) : upperCaseFirstLetter(label);
    const computedValue = inputData[computedId];
    const computedDisabled = !canEdit || (typeof disabled === 'function' ? disabled(inputData) : disabled);
    const computedSx = inputSx || inputStyle;
    const onChange = handleChange(computedId);

    if (type === 'autocomplete') {
      return (
        <AsyncAutocomplete
          filterOptions={filterOptions}
          required={computedRequired}
          multiline={multiline}
          multiple={multiple}
          selectFirstOption={selectFirstOption}
          useDefaultFilter={useDefaultFilter}
          key={computedId}
          label={computedLabel}
          debounceTime={debounceTime}
          noOptionComponent={noOptionComponent}
          id={computedId}
          error={autocompleteError}
          sx={computedSx}
          additionalFilter={additionalFilter}
          value={computedValue}
          onChange={onChange}
          optionField={fields}
          disabled={computedDisabled}
          request={request}
          name={computedId}
        />
      );
    }

    if (type === 'select') {
      return (
        <Selector
          required={computedRequired}
          key={computedId}
          optionField={fields || DEFAULT_SELECTOR_FIELDS}
          label={computedLabel}
          error={error[computedId]?.[fields?.value || DEFAULT_SELECTOR_FIELDS.value]}
          disabled={computedDisabled}
          onChange={onChange}
          value={computedValue}
          options={selectorOption[computedId]}
          sx={computedSx}
        />
      );
    }
    if (type === 'innMask') {
      const InnComponent = compareId(inputData.subject_type?.code, 'legal_entity')
        ? InnLegalInput
        : InnInput;

      // need fix for mask with _ value
      if (computedValue.includes('_') && computedDisabled) {
        return (
          <CustomInput
            required={computedRequired}
            key={computedId}
            sx={computedSx}
            id={computedId}
            disabled={computedDisabled}
            error={error[computedId]}
            label={computedLabel}
            type={type}
            value={computedValue}
            onChange={onChange}
          />
        );
      }

      return (
        <InnComponent
          required={computedRequired}
          key={computedId}
          sx={computedSx}
          id={computedId}
          disabled={computedDisabled}
          error={error[computedId]}
          label={computedLabel}
          type={type}
          value={computedValue}
          onChange={onChange}
        />
      );
    }
    if (type === 'datepicker') {
      return (
        <CustomDatePicker
          disabled={disabled}
          required={computedRequired}
          key={computedId}
          onChange={onChange}
          value={computedValue}
          error={error[computedId]}
          isDateTime={isDateTime}
          label={computedLabel}
          sx={computedSx}
        />
      );
    }
    if (type === 'radio') {
      return (
        <CustomRadioGroup
          required={computedRequired}
          key={computedId}
          onChange={onChange}
          value={computedValue}
          error={error[computedId]}
          label={computedLabel}
          options={selectorOption[computedId]}
          sx={sx}
        />
      );
    }
    if (type === 'checkbox') {
      if (multiply && computedValue !== undefined) {
        return Object.keys(computedValue).map((permission) => (
          <CustomCheckbox
            required={computedRequired}
            key={permission}
            sx={computedSx}
            error={error[computedId]}
            disabled={computedDisabled}
            label={translate[permission]}
            value={computedValue[permission]}
            onChange={(event, value) => onChange(event, {
              ...computedValue, [permission]: value,
            })}
          />
        ));
      }

      return (
        <CustomCheckbox
          required={computedRequired}
          key={computedId}
          label={computedLabel}
          sx={computedSx}
          disabled={computedDisabled}
          error={error[computedId]}
          value={computedValue}
          onChange={onChange}
        />
      );
    }
    const Component = type === 'tel' ? PhoneInput : CustomInput;
    return (
      <Component
        multiline={multiline}
        required={computedRequired}
        key={computedId}
        sx={computedSx}
        id={computedId}
        disabled={computedDisabled}
        error={error[computedId]}
        label={computedLabel}
        type={type}
        value={computedValue}
        onChange={onChange}
      />
    );
  });
}

export default function ModalCard({
  singleColumn,
  title,
  subTitle,
  inputs,
  open,
  sx,
  containerSx,
  close,
  selectorOption,
  error = {},
  handleChange,
  inputData,
  isLoading,
  additionalComponents,
  firstButtonProp,
  secondButtonProp,
  additionalContainer,
  additionalTopContainer,
  canEdit = true,
}) {
  const inputsContainer = {};

  if (singleColumn) inputsContainer.width = '100%';

  return (
    <CenteredModal sx={sx} close={close} containerSx={containerSx} open={open}>
      <Box m={4}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {title && <TextTitle h1>{title}</TextTitle>}

          <CloseModalButton close={close} />
        </Box>
        {subTitle && (
          <Typography mb={4} fontWeight="bold" fontSize="26px">
            {subTitle}
          </Typography>
        )}

        {additionalTopContainer}

        <Box position="relative">
          <BackdropLoading position="absolute" isLoading={isLoading} />
          <Box position="relative" display="flex" flexDirection="row" gap={5} sx={inputsContainer}>
            {inputs?.map(
              ({
                id, title: inputTitle, inputFields, containers, visible, hide,
              }) => {
                if (!(visible === undefined || visible?.(inputData))) return null;
                if (hide?.(inputData)) return null;

                let inputContainer = {};

                if (singleColumn) inputContainer.width = '100%';
                if (containers?.length) inputContainer = { ...inputContainer, ...columnContainer };

                return (
                  <Box display="flex" flexDirection="column" key={id} gap={3} sx={inputContainer}>
                    {inputTitle && <ModalColumnTitle>{inputTitle}</ModalColumnTitle>}
                    <Inputs
                      canEdit={canEdit}
                      error={error}
                      inputFields={inputFields}
                      handleChange={handleChange}
                      selectorOption={selectorOption}
                      inputData={inputData}
                    />

                    {containers?.map(({
                      id: containerId,
                      title: containerTitle,
                      inputFields: containerInputFields,
                      visible: containerVisible,
                      hide: containerHide,
                    }) => {
                      if (!(containerVisible === undefined || containerVisible?.(inputData))) {
                        return null;
                      }
                      if (containerHide?.(inputData)) return null;

                      return (
                        <Box
                          key={containerId}
                          width="403px"
                          maxWidth="100%"
                          flexDirection="column"
                          gap={3}
                          sx={inputContainer}
                        >
                          <ModalColumnTitle>{containerTitle}</ModalColumnTitle>
                          <Inputs
                            canEdit={canEdit}
                            error={error}
                            inputFields={containerInputFields}
                            handleChange={handleChange}
                            selectorOption={selectorOption}
                            inputData={inputData}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                );
              },
            )}
            {additionalComponents}
          </Box>

          {additionalContainer}

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Stack direction="row" spacing={4}>
              {firstButtonProp && (
                <CustomButton
                  variant="text"
                  color="error"
                  onClick={firstButtonProp.onClick}
                  startIcon={<DeleteIcon />}
                >
                  {firstButtonProp.label}
                </CustomButton>
              )}
              {secondButtonProp && (
                <CustomButton
                  onClick={secondButtonProp.onClick}
                  variant="contained"
                >
                  {secondButtonProp.label}
                </CustomButton>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </CenteredModal>
  );
}
