// @ts-nocheck
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { METHODS_API } from '../../api/api';
import { cadastreContactsRequest } from '../../api/cadastreApi';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomInput from '../../components/Inputs';
import PhoneInput from '../../components/Inputs/PhoneInput';
import { TextTitle } from '../../components/Text';
import { INPUT_EMPTY_TEXT } from '../../constants';
import { flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { formValidator } from '../../helpers';
import { VALIDATION_TEMPLATE } from '../../helpers/formValidator';
import { stringToPhone } from '../../helpers/stringHelper';
import { useApi } from '../../hooks';

const initialForm = {
  address: '',
  contact: '',
  email: '',
  link_committee: '',
  link_cloodoo: '',
};

export default function CadastrAdminContactInfo() {
  const [contactInfo, setContactInfo] = useState(initialForm);
  const [form, setForm] = useState(contactInfo);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');

  const { defaultRequest: contactApi, isLoading } = useApi({
    request: cadastreContactsRequest,
  });

  useEffect(() => {
    contactApi()
      .then((responseContactInfo) => {
        setContactInfo(responseContactInfo?.[0] || initialForm);
        setForm(responseContactInfo?.[0] || initialForm);
      });
  }, []);

  const onChange = ({ target: { name } }, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  const saveContactInfo = async () => {
    const { hasErrors, validField } = formValidator({
      form: {
        address: form.address,
        contact: form.contact,
        email: form.email,
        link_committee: form.link_committee,
        link_cloodoo: form.link_cloodoo,
      },
      customRules: [
        { name: VALIDATION_TEMPLATE.phone },
        { name: VALIDATION_TEMPLATE.email },
      ],
      inputRule: {
        contact: [VALIDATION_TEMPLATE.phone],
        email: [VALIDATION_TEMPLATE.email],
      },
    });

    setError(validField);

    if (hasErrors) return;

    setError({});
    setIsEdit(false);

    const newContact = await contactApi({
      method: form.id ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        address: form.address,
        contact: form.contact,
        email: form.email,
        link_committee: form.link_committee,
        link_cloodoo: form.link_cloodoo,
      },
      id: form.id,
    });

    if (newContact?.id) {
      setContactInfo(newContact);
      setForm(newContact);
    } else {
      setContactInfo(form);
      setForm(form);
    }
  };

  const cancelChange = () => {
    setIsEdit(false);
    setError({});
    setForm({ ...contactInfo });
  };

  const changeEdit = () => {
    setIsEdit(true);
  };

  if (isLoading) return <CircularProgress sx={{ mt: 3 }} />;

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      {isEdit ? (
        <Box sx={flexGap2}>
          <Box sx={flexGap2}>
            <CustomInput label="Адрес" error={error.address} value={form.address} onChange={onChange} name="address" />
            <PhoneInput error={error.contact} value={form.contact} onChange={onChange} name="contact" />
            <CustomInput label="E-mail" error={error.email} value={form.email} onChange={onChange} name="email" />
            <CustomInput
              label="Комитет Ленинградской области по обращению с отходами"
              error={error.link_committee}
              value={form.link_committee}
              onChange={onChange}
              name="link_committee"
            />
            <CustomInput
              label="ЛОГКУ «Центр Ленинградской области по организации деятельности по обращению с отходами»"
              error={error.link_cloodoo}
              value={form.link_cloodoo}
              onChange={onChange}
              name="link_cloodoo"
            />
          </Box>
          <Box sx={flexRowGap2}>
            <CustomButton onClick={saveContactInfo}>Сохранить</CustomButton>
            <CustomButton color="error" onClick={cancelChange}>Отменить</CustomButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <TextTitle>
            Адрес:
            {' '}
            {form.address || INPUT_EMPTY_TEXT}
          </TextTitle>
          <TextTitle>
            Телефон:
            {' '}
            {stringToPhone(form.contact) || INPUT_EMPTY_TEXT}
          </TextTitle>
          <TextTitle>
            E-mail:
            {' '}
            {form.email || INPUT_EMPTY_TEXT}
          </TextTitle>
          <TextTitle>
            Комитет Ленинградской области по обращению с отходами
            <CustomButton sx={{ height: 'fit-content' }} variant="text" href={form.link_committee}>
              {form.link_committee}
            </CustomButton>
          </TextTitle>
          <TextTitle>
            ЛОГКУ «Центр Ленинградской области по организации деятельности по обращению с отходами»
            <CustomButton sx={{ height: 'fit-content' }} variant="text" href={form.link_cloodoo}>
              {form.link_cloodoo}
            </CustomButton>
          </TextTitle>
        </Box>
      )}
      {!isEdit && <CustomButton sx={{ width: 'fit-content' }} onClick={changeEdit}>Изменить</CustomButton>}
    </Box>
  );
}
