import React from 'react';
import { Box } from '@mui/material';
import { PETROSOFT_BEARER_TOKEN } from '../../constants/urls';

export default function AdminTerSchemePage() {
  return (
    <Box sx={{ width: '100%', height: '100%', pt: 3 }}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        component="iframe"
        title="ter_scheme"
        src={`http://192.168.25.149:8080/operator-ui/?aistoken=${PETROSOFT_BEARER_TOKEN}#template/3913`}
      />
    </Box>
  );
}
