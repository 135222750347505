import { compareId } from '../../helpers';

export const getInitialLimitYear = () => ({
  randId: Math.random(),
  year: '',
  tons: '',
});

export const APPROVAL_STANDARD_YEAR_KINDS = { transferred: 'transferred', placed: 'placed' };

export const getInitialForm4Standard = () => ({
  randId: Math.random(),
  code: '',
  standard_tons: 0,
  transfer_subject_id: '',
  transfer_objective_id: '',
  limit_waste_transferred_years: [getInitialLimitYear()],
  place_objective_id: '',
  limit_waste_placed_years: [getInitialLimitYear()],
});

export const getInitialForm4Document = () => ({
  randId: Math.random(),
  objective_id: '',
  document_number: '',
  document_date: null,
  document_expiration: null,
  standards: [getInitialForm4Standard()],
});

export const getInitialForm4 = () => ({
  documents: [getInitialForm4Document()],
});

export const form4FilledCount = ({
  code,
  standard_tons,
  limit_waste_transferred_years,
  limit_waste_placed_years,
  place_objective_id,
  transfer_subject_id,
  transfer_objective_id,
}, sumAllDataLimitsLength) => {
  let totalStandardCount = 0;

  const noTransferSubjectId = compareId(transfer_subject_id?.name, 0);
  const noPlaceObjectiveId = compareId(place_objective_id?.label, 0);

  if (code?.value) totalStandardCount += 1;
  if (code?.hazard_class) totalStandardCount += 1;
  if (standard_tons) totalStandardCount += 1;
  if (place_objective_id?.id || noPlaceObjectiveId) totalStandardCount += 1;
  if (transfer_subject_id?.id || noTransferSubjectId) totalStandardCount += 1;
  if (transfer_objective_id?.id || noTransferSubjectId) totalStandardCount += 1;

  totalStandardCount += limit_waste_placed_years.reduce((prevYear, { year, tons }) => {
    sumAllDataLimitsLength();

    let totalYearCount = 0;
    if (year) totalYearCount += 1;
    if (tons) totalYearCount += 1;

    return prevYear + totalYearCount;
  }, 0);

  totalStandardCount += limit_waste_transferred_years.reduce((prevYear, { year, tons }) => {
    sumAllDataLimitsLength();

    let totalYearCount = 0;
    if (year) totalYearCount += 1;
    if (tons) totalYearCount += 1;

    return prevYear + totalYearCount;
  }, 0);

  return totalStandardCount;
};

export const form4PercentGetter = (forms) => {
  const {
    documents,
    requiredForm,
  } = forms.form4;

  if (requiredForm) return 100;

  const documentLength = Object.keys([
    'objective_id',
    'document_number',
    'document_date',
    'document_expiration',
  ]).length;

  const yearLimitLength = ['year', 'tons'].length;

  const standardLength = [
    'code',
    'hazard_class',
    'standard_tons',
    'transfer_subject_id',
    'transfer_objective_id',
    'place_objective_id',
  ].length;

  let allDataLength = 0;

  const sumAllDataLimitsLength = () => {
    allDataLength += yearLimitLength;
  };

  const totalFilledCount = documents.reduce((prevDocument, {
    standards, objective_id, document_number, document_date, document_expiration,
  }) => {
    allDataLength += documentLength;

    let totalDocumentFilledCount = standards.reduce((prevStandard, standard) => {
      allDataLength += standardLength;

      const totalStandardFilledCount = form4FilledCount(standard, sumAllDataLimitsLength);
      return prevStandard + +totalStandardFilledCount;
    }, 0);
    if (objective_id?.id) totalDocumentFilledCount += 1;
    if (document_number) totalDocumentFilledCount += 1;
    if (document_date) totalDocumentFilledCount += 1;
    if (document_expiration) totalDocumentFilledCount += 1;

    return prevDocument + +totalDocumentFilledCount;
  }, 0);

  return Math.floor((totalFilledCount / allDataLength) * 100);
};
