import { Alert, Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

export default function BackdropLoading({
  isLoading = false, position = 'absolute', sx = {}, alertText = '',
}) {
  if (!isLoading) return null;

  return (
    <Backdrop
      sx={{
        color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position, ...sx,
      }}
      open={isLoading}
    >
      {alertText ? (
        <Alert severity="warning">
          {alertText}
        </Alert>
      ) : <CircularProgress size="1rem" color="inherit" />}
    </Backdrop>
  );
}
