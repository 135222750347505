// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PUBLIC_LOGIN_CADASTR_PAGE_ROUTE, PUBLIC_LOGIN_CARRIER_PAGE_ROUTE } from '../constants/routes';
import { useApi, usePushNotification } from '../hooks';
import { compareId, formValidator } from '../helpers';
import { INPUT_EMPTY_TEXT, USER_ENTITY_TYPE } from '../constants';
import CustomInput from './Inputs';
import CustomCheckbox from './CustomCheckbox';
import PhoneInput from './Inputs/PhoneInput';
import { HAS_REQUIRED_FIELDS_TEXT, VALIDATION_TEMPLATE } from '../helpers/formValidator';
import {
  changeSubjectEmailByInnRequest,
  createSubjectRequest,
  getSubjectIsSMEVRequest,
  getSubjectSMEVRequest,
} from '../api/subjectApi';
import CustomButton from './Buttons/CustomButton';
import InnInput from './Inputs/InnInput';
import { removeMaskSymbols, removeSubjectNameSymbols } from '../helpers/stringHelper';
import InnLegalInput from './Inputs/InnLegalInput';
import { ENTERPRISE_ACTIVITY_TYPE_IDS, ENTERPRISE_ACTIVITY_TYPES_ENUM, SUBJECT_TYPES } from '../constants/enums';
import { getReferencesRequest } from '../api/referenceApi';
import BackdropLoading from './BackdropLoading';
import useCustomMediaQuery from '../hooks/useCustomMediaQuery';
import useGetReferenceItems from '../hooks/useGetRefferenceItems';
import { checkIsPublicCadastreOrigin, checkIsControlOrigin } from '../helpers/browserLocation';
import ModalWithTitle from './Modals/ModalWithTitle';
import { flexRowGap2 } from '../constants/lkCadastr/cadastrStorage';

const RESTORE_EMAIL_TEXT = 'Субъект не может быть зарегистрирован, так как ИНН уже существует. Хотите восстановить пароль на основании данных, указанных в регистрационной форме?';

const initialValues = {
  inn: '',
  ogrn: '',
  kpp: '',
  name: '',
  actual_address: '',
  registry_address: '',
  email: '',
  password: '',
  password_confirmation: '',
  isPersonalData: false,
  phone: '',
};

const nameType = {
  [USER_ENTITY_TYPE.individual_entrepreneur.name]: {
    label: 'ИП ФИО',
  },
  [USER_ENTITY_TYPE.legal_entity.name]: {
    label: 'Наименование юридического лица',
  },
  [USER_ENTITY_TYPE.self_employed.name]: {
    label: 'ФИО',
  },
};

const ogrnType = {
  [USER_ENTITY_TYPE.individual_entrepreneur.name]: {
    label: 'ОГРНИП',
  },
  [USER_ENTITY_TYPE.legal_entity.name]: {
    label: 'ОГРН',
  },
  [USER_ENTITY_TYPE.self_employed.name]: {
    label: 'ОГРН',
  },
};

export default function RegistrationCarrier({
  subject_type, isMatch, setIsMatch, setSubjectType,
}) {
  const [searchParams] = useSearchParams();
  const isCadastr = searchParams.get('isCadastr');

  const isSelfEmployed = USER_ENTITY_TYPE.self_employed.name === subject_type;

  const navigate = useNavigate();
  const pushNotification = usePushNotification();
  const [formValues, setFormValues] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [subjectTypes, setSubjectTypes] = useState([]);
  const [openedInnModal, toggleInnModal] = useState(false);
  const ogrnSubjectLabel = ogrnType[subject_type]?.label;

  const { getReferenceTypeIdsByCode: getEnterpriseTypeIdsByCode } = useGetReferenceItems({ referenceUrl: ENTERPRISE_ACTIVITY_TYPES_ENUM });

  const isOrganisation = [
    USER_ENTITY_TYPE.legal_entity.name,
    USER_ENTITY_TYPE.individual_entrepreneur.name,
  ].includes(subject_type);

  const nameTypeLabel = nameType[subject_type].label;

  const changeSameAddress = (e, value) => setIsSameAddress(value);

  useEffect(() => {
    getReferencesRequest({ url: SUBJECT_TYPES })
      .then(((responseSubjectsTypes) => setSubjectTypes(responseSubjectsTypes)));
  }, []);

  const getFinalFormInputs = () => {
    const subject = {
      ...formValues,
      inn: removeMaskSymbols(formValues.inn),
      actual_address: isSameAddress ? formValues.registry_address : formValues.actual_address,
      full_name: formValues.full_name,
      subject_type_id: subjectTypes.find(({ code }) => compareId(code, subject_type))?.id,
    };

    if (checkIsControlOrigin()) {
      subject.partition = 'control';
    } else if (checkIsPublicCadastreOrigin()) {
      subject.partition = 'cadastre';
    }

    delete subject.isPersonalData;

    return subject;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ignoreInputs = ['kpp'];

    if (USER_ENTITY_TYPE.self_employed.name === subject_type) {
      ignoreInputs.push('ogrn');
    }

    if (isSameAddress) ignoreInputs.push('actual_address');

    const finalForm = {
      inn: formValues.inn,
      ogrn: formValues.ogrn,
      kpp: formValues.kpp,
      name: formValues.name,
      actual_address: formValues.actual_address,
      registry_address: formValues.registry_address,
      email: formValues.email,
      password: formValues.password,
      password_confirmation: formValues.password_confirmation,
      isPersonalData: formValues.isPersonalData,
      phone: formValues.phone,
    };

    const { hasErrors, validField } = formValidator({
      form: { ...finalForm, subject_type },
      customRules: [
        { name: VALIDATION_TEMPLATE.email },
        { name: 'selector', text: 'Обязательное поле', valid: (item) => item.value !== undefined && item.value !== '' },
        { name: VALIDATION_TEMPLATE.phone },
        { name: 'inn' },
        { name: VALIDATION_TEMPLATE.password },
        { name: VALIDATION_TEMPLATE.password_confirmation },
      ],

      inputRule: {
        email: [VALIDATION_TEMPLATE.email],
        password: [VALIDATION_TEMPLATE.password],
        password_confirmation: [VALIDATION_TEMPLATE.password_confirmation],
        phone: [VALIDATION_TEMPLATE.phone],
        inn: ['inn'],
      },
      ignoreInputs,
    });

    setFormError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const subject = getFinalFormInputs();

    if (!isCadastr) {
      subject[ENTERPRISE_ACTIVITY_TYPE_IDS] = getEnterpriseTypeIdsByCode(['transporting']);
    }

    await createSubjectRequest({ ...subject, division: 'external', email_confirmation: true });

    pushNotification({
      message: `Вам необходимо подтвердить адрес электронной почты ${subject.email}`,
      variant: 'warning',
    });
    navigate(isCadastr ? PUBLIC_LOGIN_CADASTR_PAGE_ROUTE : PUBLIC_LOGIN_CARRIER_PAGE_ROUTE);
  };

  const { makeRequest: onRegistrationSubject } = useApi({
    request: handleSubmit,
    successMessage: 'Регистрация прошла успешно',
    errorCallback: (e) => {
      e.frontendError = null;

      const arrayErrors = e?.response?.data;

      if (arrayErrors?.inn) {
        toggleInnModal(true);
      } else {
        delete e.frontendError;
      }
    },
  });

  const resetError = (name) => {
    setFormError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  const handleChange = (e, value) => {
    const { name } = e.target;
    resetError(name);
    setFormValues({ ...formValues, [name]: value });
  };

  const { makeRequest: loadFNS, isLoading: isLoadingFNS } = useApi({
    request: async () => {
      const paramsData = { inn: removeMaskSymbols(formValues.inn) };

      try {
        const { data: { is_match } } = await getSubjectIsSMEVRequest(paramsData);

        if (is_match) {
          const { data: subjectByInnData } = await getSubjectSMEVRequest(paramsData);

          const subjectByInnType = subjectByInnData.data.inn.length === 10
            ? USER_ENTITY_TYPE.legal_entity.name
            : USER_ENTITY_TYPE.individual_entrepreneur.name;

          if (subject_type !== subjectByInnType) {
            setSubjectType(subjectByInnType);
          }

          setTimeout(() => {
            setFormValues((prevState) => ({
              ...prevState,
              kpp: subjectByInnData.data.kpp,
              inn: subjectByInnData.data.inn,
              ogrn: subjectByInnData.data.ogrn,
              name: removeSubjectNameSymbols(subjectByInnData.value),
              okato: subjectByInnData.data.okato,
              okved: subjectByInnData.data.okved,
              okpo: subjectByInnData.data.okpo,
              full_name: subjectByInnData?.data?.name?.full_with_opf || INPUT_EMPTY_TEXT,
              boss_name: subjectByInnData.data?.management?.name || '',
              registry_address: subjectByInnData.data.address.data.source,
              subject_type_id: subjectTypes.find(({ code }) => compareId(code, subjectByInnType))?.id,
            }));
          }, 300);
        }

        setIsMatch(is_match);

        if (!is_match) {
          throw { frontendError: 'Данные в ЕГРЮЛ/ЕГРИП не обнаружены.' };
        }
      } catch (e) {
        throw { frontendError: 'Данные в ЕГРЮЛ/ЕГРИП не обнаружены.' };
      }
    },
  });

  useEffect(() => {
    setFormValues(initialValues);
    setFormError({});
  }, [subject_type]);

  const { isMobileTablet } = useCustomMediaQuery();

  const inputsContainerSx = isMobileTablet ? { width: '100%' } : {};

  const closeInnModal = () => toggleInnModal(false);

  const changeEmailByInn = async () => {
    const subject = getFinalFormInputs();

    const response = await changeSubjectEmailByInnRequest(subject);

    if (compareId(response?.status, 200)) {
      pushNotification({
        message: `Вам необходимо подтвердить адрес электронной почты ${formValues.email}`,
        variant: 'warning',
      });

      navigate(PUBLIC_LOGIN_CADASTR_PAGE_ROUTE);
    }
  };

  const { defaultRequest: changeEmailByInnApi } = useApi({
    request: changeEmailByInn,
  });

  const isLegalEntity = USER_ENTITY_TYPE.legal_entity.name === subject_type;

  return (
    <>
      <ModalWithTitle
        open={openedInnModal}
        close={closeInnModal}
        title="Восстановить почту?"
        subTitle={RESTORE_EMAIL_TEXT}
        mainSx={{ ...flexRowGap2, justifyContent: 'center' }}
      >
        <CustomButton sx={{ width: 110 }} onClick={changeEmailByInnApi}>Да</CustomButton>
        <CustomButton sx={{ width: 110 }} onClick={closeInnModal} color="error">Нет</CustomButton>
      </ModalWithTitle>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={3} sx={{ ...inputsContainerSx }}>
        <BackdropLoading isLoading={isLoadingFNS} />
        <Box display="flex" flexDirection="column" gap={2} sx={{ ...inputsContainerSx }}>
          <Typography fontSize={20} fontWeight="bold" mb={1}>
            Данные субъекта
          </Typography>
          {!isSelfEmployed && (
            <Alert severity="warning" sx={{ width: '300px' }}>
              Проверка сведений может занимать до 10 минут. Дождитесь получения сведений, не закрывайте и не обновляйте
              страницу
            </Alert>
          )}
          {isLegalEntity ? (
            <InnLegalInput
              required
              id="inn"
              placeholder="Введите ИНН. Прим. 4706042112"
              label="ИНН"
              name="inn"
              value={formValues.inn}
              error={formError.inn}
              onChange={handleChange}
            />
          ) : (
            <InnInput
              required
              id="inn"
              placeholder="Введите ИНН. Прим. 4706042112"
              label="ИНН"
              name="inn"
              value={formValues.inn}
              error={formError.inn}
              onChange={handleChange}
            />
          )}
          {
            USER_ENTITY_TYPE.self_employed.name !== subject_type && (
              <CustomButton isLoading={isLoadingFNS} onClick={loadFNS} sx={{ mb: 3, width: 'fit-content' }}>
                Поиск в
                ФНС
              </CustomButton>
            )
          }
          <CustomInput
            required={USER_ENTITY_TYPE.self_employed.name !== subject_type}
            disabled={isOrganisation}
            id="ogrn"
            placeholder="Введите ОГРН. Прим. 1214700004045"
            label={ogrnSubjectLabel}
            name="ogrn"
            value={formValues.ogrn}
            error={formError.ogrn}
            onChange={handleChange}
          />
          {
            isOrganisation && (
              <CustomInput
                id="kpp"
                disabled
                placeholder="Введите КПП. Прим. 123456789"
                label="КПП"
                name="kpp"
                value={formValues.kpp}
                error={formError.kpp}
                onChange={handleChange}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            )
          }
          <CustomInput
            required
            disabled={isOrganisation}
            id="legalEntryName"
            label={nameTypeLabel}
            name="name"
            value={formValues.name}
            error={formError.name}
            onChange={handleChange}
          />
          <CustomInput
            multiline
            required
            disabled={isOrganisation}
            id="registry_address"
            label="Адрес регистрации"
            name="registry_address"
            value={formValues.registry_address}
            error={formError.registry_address}
            onChange={handleChange}
          />
          <CustomCheckbox
            onChange={changeSameAddress}
            value={isSameAddress}
            label="Совпадает с адресом регистрации"
          />
          <CustomInput
            multiline
            required
            id="actual_address"
            disabled={isSameAddress}
            onChange={handleChange}
            name="actual_address"
            label="Фактический адрес"
            value={isSameAddress ? formValues.registry_address : formValues.actual_address}
            error={formError.actual_address}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} sx={{ ...inputsContainerSx }}>
          <Typography fontSize={20} fontWeight="bold" mb={1}>
            Контактные данные
          </Typography>
          <PhoneInput
            required
            type="tel"
            name="phone"
            label="Контактный номер телефона"
            onChange={handleChange}
            value={formValues.phone}
            error={formError.phone}
          />
          <CustomInput
            required
            type="email"
            name="email"
            label="Контактный E-mail"
            placeholder="email@email.email"
            onChange={handleChange}
            value={formValues.email}
            error={formError.email}
          />
          <CustomInput
            required
            type="password"
            name="password"
            label="Пароль"
            onChange={handleChange}
            value={formValues.password}
            error={formError.password}
          />
          <CustomInput
            required
            type="password"
            name="password_confirmation"
            label="Повторите пароль"
            onChange={handleChange}
            value={formValues.password_confirmation}
            error={formError.password_confirmation}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <CustomCheckbox
          required
          value={formValues.isPersonalData}
          error={formError.isPersonalData}
          name="isPersonalData"
          onChange={handleChange}
          label="Согласие на обработку персональных данных"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <CustomButton disabled={!isMatch} sx={{ height: 50 }} onClick={onRegistrationSubject}>
          Зарегистрироваться
        </CustomButton>
      </Box>
    </>
  );
}
