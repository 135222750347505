import React from 'react';
import { Box } from '@mui/material';
import ModalWithTitle from '../ModalWithTitle';
import TitleSubtitle from '../../TitleSubtitle';
import { TextTitle } from '../../Text';
import { getDayMonthYear, getHourMinute } from '../../../helpers/dateHelper';
import {
  OWNERSHIP_TYPE_ENUM,
  OWNERSHIP_TYPE_ENUM_TEXT,
  SUBJECT_TYPE_ATTRIBUTES,
} from '../../../constants/enums';
import PermissionTable from '../../Tables/PermissionTable';

const violationContainers = [
  {
    title: 'ТС нарушителя',
    texts: [
      { subTitle: 'ЕГРЗ ТС (Номер ТС)', field: 'egrz', getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz },
      { subTitle: 'Марка', field: 'brand', getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.brand },
      { subTitle: 'Модель', field: 'model', getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.model },
      {
        subTitle: 'Тип владения',
        field: OWNERSHIP_TYPE_ENUM,
        getField: ({
          vehicle_attributes,
        }) => vehicle_attributes?.attributes?.[OWNERSHIP_TYPE_ENUM_TEXT],
      },
      {
        subTitle: 'Подключено к РИНС',
        field: 'is-connected-rins',
        getField: ({
          vehicle_attributes,
        }) => (vehicle_attributes?.attributes?.is_connected_rins ? 'Да' : 'Нет'),
      },
    ],
  },
  {
    title: 'Информация о нарушителе',
    texts: [
      {
        subTitle: 'Наименование субъекта',
        field: 'subject_name',
        getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
      },
      {
        subTitle: 'Тип субъекта',
        field: 'subject_type',
        getField: ({ subject_attributes }) => (
          subject_attributes?.attributes?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name
        ),
      },
      {
        subTitle: 'ИНН субъекта',
        field: 'inn',
        getField: ({ subject_attributes }) => subject_attributes?.attributes?.inn,
      },
      {
        subTitle: 'ОГРН/-ИП',
        field: 'ogrn',
        getField: ({ subject_attributes }) => subject_attributes?.attributes?.ogrn,
      },
    ],
  },
  {
    title: 'Информация о нарушении',
    texts: [
      { subTitle: 'Наименование нарушения', field: 'name' },
      { subTitle: 'Тип нарушения', field: 'kind_text' },
      {
        subTitle: 'Дата нарушения',
        field: 'date_violation',
        getField: ({ committed_at }) => getDayMonthYear(committed_at),
      },
      {
        subTitle: 'Время нарушения',
        field: 'violation_time',
        getField: ({ committed_at }) => getHourMinute(committed_at),
      },
      { subTitle: 'Основание нарушения', field: 'reason' },
    ],
  },
];

export default function ModalViolation({ close, violation = {} }) {
  return (
    <ModalWithTitle
      open
      close={close}
      title="Просмотр сведений о нарушителе"
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2} mb={2}>
        {violationContainers.map(({ title, texts }) => (
          <Box key={title}>
            <TextTitle h3 sx={{ maxWidth: 320 }}>{title}</TextTitle>
            <Box display="flex" flexDirection="column" flexWrap="wrap" gap={2}>
              {texts.map(({ subTitle, field, getField }) => (
                <TitleSubtitle
                  key={subTitle}
                  title={subTitle}
                  subTitle={getField?.(violation) || violation[field]}
                  sx={{ maxWidth: 320 }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <TextTitle h3>Информация о разрешении</TextTitle>
      <PermissionTable vehicle_id={violation?.vehicle_attributes?.id} />
    </ModalWithTitle>
  );
}
