import { compareId } from '../compareId';
import { METHODS_API } from '../../api/api';
import {
  cadastreApprovalStandardWastesRequest, cadastreForm4ReportApprovalStandardsRequest,
  cadastreLimitWastesRequest,
  cadastreReportApprovalStandardRequest,
  cadastreReportHistoryRequest,
} from '../../api/cadastreApi';
import {
  APPROVAL_STANDARD_YEAR_KINDS,
  getInitialForm4Document,
  getInitialForm4Standard,
  getInitialLimitYear,
} from '../../constants/lkCadastr/cadastrForm4';
import { formatObjectWithAddress, valueToString } from '../stringHelper';
import { getHazardClassByName } from '../entityHelper';
import { sortByCreatedAt } from '../sortHelper';

export const getFormReportApprovalStandardWastesHelper = async (report) => {
  if (report?.is_document_unneeded) {
    return {
      documents: [getInitialForm4Document()],
      requiredForm: report?.is_document_unneeded,
    };
  }

  if (report?.report_approval_standard_ids?.length) {
    const allReportApprovalStandards = [];

    const reportApprovalStandardByIds = await cadastreReportApprovalStandardRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    reportApprovalStandardByIds.forEach((reportApprovalStandard) => {
      const additionalFormItems = {};

      const currentApprovalWastes = reportApprovalStandard.approval_standard_wastes_attributes;

      if (currentApprovalWastes.length) {
        additionalFormItems.standards = sortByCreatedAt(currentApprovalWastes, true)
          .map((approvalWaste) => {
            const approvalWasteAttributes = approvalWaste?.attributes;
            const limitYears = approvalWasteAttributes?.limit_wastes_attributes
              .map((item) => ({ ...item, ...item?.attributes }));

            const limitTransferredYears = limitYears
              .filter((item) => item.kind === APPROVAL_STANDARD_YEAR_KINDS.transferred)
              .map((item) => ({ ...item, tons: valueToString(item.tons), year: valueToString(item.year) }));

            const limitPlacedYears = limitYears
              .filter((item) => item.kind === APPROVAL_STANDARD_YEAR_KINDS.placed)
              .map((item) => ({ ...item, tons: valueToString(item.tons), year: valueToString(item.year) }));

            const wasteType = approvalWasteAttributes?.waste_type_attributes;

            return ({
              ...getInitialForm4Standard(),
              ...approvalWaste,
              ...approvalWasteAttributes,
              standard_tons: valueToString(approvalWasteAttributes.standard_tons),
              transfer_subject_id: {
                ...approvalWasteAttributes?.transfer_subject_attributes,
                ...approvalWasteAttributes?.transfer_subject_attributes?.attributes,
                name: approvalWasteAttributes?.transfer_subject_attributes?.attributes?.name || '0',
              },
              transfer_objective_id: {
                ...approvalWasteAttributes?.transfer_objective_attributes,
                ...approvalWasteAttributes?.transfer_objective_attributes?.attributes,
              },
              place_objective_id: {
                ...approvalWasteAttributes?.place_objective_attributes,
                ...approvalWasteAttributes?.place_objective_attributes?.attributes,
                label: formatObjectWithAddress({ objective: approvalWasteAttributes?.place_objective_attributes?.attributes }) || '0',
              },
              code: {
                id: wasteType?.id,
                value: wasteType?.id,
                label: `${wasteType?.attributes?.code} ${wasteType?.attributes?.name}`,
                ...wasteType?.attributes,
                ...getHazardClassByName(approvalWasteAttributes?.hazard_class),
              },
              limit_waste_transferred_years: limitTransferredYears?.length
                ? limitTransferredYears
                : [getInitialLimitYear()],
              limit_waste_placed_years: limitPlacedYears?.length
                ? limitPlacedYears
                : [getInitialLimitYear()],
            });
          });
      }

      allReportApprovalStandards.push({
        ...getInitialForm4Document(),
        ...reportApprovalStandard,
        ...additionalFormItems,
        objective_id: {
          ...reportApprovalStandard?.objective_attributes,
          ...reportApprovalStandard?.objective_attributes?.attributes,
        },
      });
    });

    return { documents: allReportApprovalStandards, requiredForm: report?.is_document_unneeded };
  }
};

export const deleteForm4ApprovalStandard = async ({ approvalStandardId }) => {
  const deletedApprovalStandard = await cadastreReportApprovalStandardRequest({
    method: METHODS_API.DELETE,
    id: approvalStandardId,
  });

  return deletedApprovalStandard?.id;
};

export const deleteForm4ApprovalWaste = async ({ approvalWasteId }) => {
  const deletedApprovalWaste = await cadastreApprovalStandardWastesRequest({
    method: METHODS_API.DELETE,
    id: approvalWasteId,
  });

  return deletedApprovalWaste?.id;
};

export const deleteForm4LimitWaste = async ({ limitWasteId }) => {
  const deletedLimitWaste = await cadastreLimitWastesRequest({
    method: METHODS_API.DELETE,
    id: limitWasteId,
  });

  return deletedLimitWaste?.id;
};

export const deleteForm4Entities = async ({
  initialForms,
  forms,
  isRequired,
}) => {
  for await (const initialDocument of initialForms.form4.documents) {
    const reportApprovalId = initialDocument?.id;

    if (reportApprovalId === undefined) continue;

    const existingActualDocument = forms.form4.documents
      .find((actualDocument) => compareId(actualDocument.id, reportApprovalId));

    if (!existingActualDocument || isRequired) {
      await cadastreReportApprovalStandardRequest({
        method: METHODS_API.DELETE,
        id: reportApprovalId,
      });
    } else {
      for await (const initialApprovalWaste of initialDocument.standards) {
        const approvalWasteId = initialApprovalWaste?.id;

        if (!approvalWasteId) continue;

        const actualApprovalWaste = existingActualDocument.standards
          .find((approvalWaste) => compareId(approvalWaste.id, approvalWasteId));

        if (!actualApprovalWaste) {
          await cadastreApprovalStandardWastesRequest({
            method: METHODS_API.DELETE,
            id: approvalWasteId,
          });
        } else {
          for await (const initialLimitWaste of initialApprovalWaste.limit_waste_transferred_years) {
            const removedLimitWaste = !initialApprovalWaste.limit_waste_transferred_years
              .find((actualLimitWaste) => compareId(
                actualLimitWaste.id,
                initialLimitWaste.id,
              ));

            if (removedLimitWaste) {
              await cadastreLimitWastesRequest({
                method: METHODS_API.DELETE,
                id: initialLimitWaste.id,
              });
            }
          }

          for await (const initialLimitWaste of initialApprovalWaste.limit_waste_placed_years) {
            const removedLimitWaste = !initialApprovalWaste.limit_waste_placed_years
              .find((actualLimitWaste) => compareId(
                actualLimitWaste.id,
                initialLimitWaste.id,
              ));

            if (removedLimitWaste) {
              await cadastreLimitWastesRequest({
                method: METHODS_API.DELETE,
                id: initialLimitWaste.id,
              });
            }
          }
        }
      }
    }
  }
};

export const saveOneForm4ApprovalStandard = async ({ forms, reportId, reportApprovalStandard }) => {
  const form4Info = forms.form4;

  if (form4Info.requiredForm !== undefined) {
    await cadastreReportHistoryRequest({
      data: { is_document_unneeded: form4Info.requiredForm },
      method: METHODS_API.PUT,
      id: reportId,
    });
  }

  if (form4Info.requiredForm || !reportApprovalStandard) return {};

  const object = reportApprovalStandard.objective_id;

  const form4ApprovalStandardData = {
    objective_id: object?.id || '',
    oktmo_id: object?.address_attributes?.attributes?.oktmo_attributes?.id || '',
    document_number: reportApprovalStandard.document_number,
    document_date: valueToString(reportApprovalStandard.document_date),
    document_expiration: reportApprovalStandard.document_expiration,
    report_history_id: reportId,
  };

  let reportApprovalStandardId = reportApprovalStandard?.id;

  const createdReportApprovalStandard = await cadastreReportApprovalStandardRequest({
    data: form4ApprovalStandardData,
    method: reportApprovalStandardId ? METHODS_API.PUT : METHODS_API.POST,
    id: reportApprovalStandardId,
  });

  reportApprovalStandardId = createdReportApprovalStandard?.id;

  return { reportApprovalStandardId };
};

export const saveOneForm4ApprovalWaste = async ({
  forms, reportId, approvalWaste, reportApprovalStandard,
}) => {
  let approvalWasteId = approvalWaste?.id;
  let reportApprovalStandardId = reportApprovalStandard?.id;

  if (!reportApprovalStandardId) {
    const { reportApprovalStandardId: newReportApprovalStandardId } = await saveOneForm4ApprovalStandard({
      forms,
      reportId,
      reportApprovalStandard,
    });

    reportApprovalStandardId = newReportApprovalStandardId;
  }

  if (!reportApprovalStandardId) return {};

  try {
    const form4Data = {
      waste_type_id: approvalWaste.code?.id || '',
      hazard_class: approvalWaste.code?.hazard_class || '',
      standard_tons: approvalWaste.standard_tons,
      transfer_subject_id: approvalWaste?.transfer_subject_id?.id || '',
      transfer_objective_id: approvalWaste?.transfer_objective_id?.id || '',
      place_objective_id: approvalWaste?.place_objective_id?.id || '',
      report_approval_standard_id: reportApprovalStandardId,
    };

    const newApprovalWaste = await cadastreApprovalStandardWastesRequest({
      data: form4Data,
      method: approvalWasteId ? METHODS_API.PUT : METHODS_API.POST,
      id: approvalWasteId,
    });

    approvalWasteId = newApprovalWaste.id;
  } catch (e) {
    console.error(e);
  }

  return { approvalWasteId, reportApprovalStandardId };
};

export const saveOneForm4LimitWaste = async ({
  forms, reportId, approvalWaste, limitWaste, reportApprovalStandard, limitWasteKind,
}) => {
  let limitWasteId = limitWaste?.id;
  let approvalWasteId = approvalWaste?.id;
  let reportApprovalStandardId = reportApprovalStandard?.id;

  if (!approvalWasteId) {
    const {
      approvalWasteId: newApprovalWasteId,
      reportApprovalStandardId: reportApprovalId,
    } = await saveOneForm4ApprovalWaste({
      forms,
      reportId,
      approvalWaste,
      reportApprovalStandard,
    });

    approvalWasteId = newApprovalWasteId;
    reportApprovalStandardId = reportApprovalId;
  }

  if (!reportApprovalStandardId || !approvalWasteId) return;

  try {
    const form4LimitWasteTransferredData = {
      kind: limitWasteKind,
      year: limitWaste.year,
      tons: valueToString(limitWaste.tons),
      approval_standard_waste_id: approvalWasteId,
    };

    const newLimitWaste = await cadastreLimitWastesRequest({
      data: form4LimitWasteTransferredData,
      method: limitWasteId ? METHODS_API.PUT : METHODS_API.POST,
      id: limitWasteId,
    });

    limitWasteId = newLimitWaste?.id;
  } catch (e) {
    console.error(e);
  }

  return { approvalWasteId, reportApprovalStandardId, limitWasteId };
};

export const createUpdateForm4Entities = async ({ forms, reportId }) => {
  const form4Info = forms.form4;

  if (form4Info.requiredForm !== undefined) {
    await cadastreReportHistoryRequest({
      data: { is_document_unneeded: form4Info.requiredForm },
      method: METHODS_API.PUT,
      id: reportId,
    });
  }

  if (form4Info.requiredForm) return;

  for await (const reportApprovalStandard of form4Info.documents) {
    try {
      const object = reportApprovalStandard.objective_id;

      const form4ApprovalStandardData = {
        objective_id: object?.id || '',
        oktmo_id: object?.address_attributes?.attributes?.oktmo_attributes?.id || '',
        document_number: reportApprovalStandard.document_number,
        document_date: valueToString(reportApprovalStandard.document_date),
        document_expiration: reportApprovalStandard.document_expiration,
        report_history_id: reportId,
      };

      let approvalStandardId = reportApprovalStandard?.id;

      const createdReportApprovalStandard = await cadastreReportApprovalStandardRequest({
        data: form4ApprovalStandardData,
        method: approvalStandardId ? METHODS_API.PUT : METHODS_API.POST,
        id: approvalStandardId,
      });

      approvalStandardId = createdReportApprovalStandard?.id;

      if (!approvalStandardId) return;

      for await (const approvalWaste of reportApprovalStandard.standards) {
        try {
          const form4Data = {
            waste_type_id: approvalWaste.code?.id || '',
            hazard_class: approvalWaste.code?.hazard_class || '',
            standard_tons: approvalWaste.standard_tons,
            transfer_subject_id: approvalWaste?.transfer_subject_id?.id || '',
            transfer_objective_id: approvalWaste?.transfer_objective_id?.id || '',
            place_objective_id: approvalWaste?.place_objective_id?.id || '',
            report_approval_standard_id: approvalStandardId,
          };

          let approvalWasteId = approvalWaste.id;

          const newApprovalWaste = await cadastreApprovalStandardWastesRequest({
            data: form4Data,
            method: approvalWasteId ? METHODS_API.PUT : METHODS_API.POST,
            id: approvalWasteId,
          });

          approvalWasteId = newApprovalWaste.id;

          for await (const limitWasteTransferred of approvalWaste.limit_waste_transferred_years) {
            const form4LimitWasteTransferredData = {
              kind: APPROVAL_STANDARD_YEAR_KINDS.transferred,
              year: limitWasteTransferred.year,
              tons: valueToString(limitWasteTransferred.tons),
              approval_standard_waste_id: approvalWasteId,
            };

            const limitWasteId = limitWasteTransferred.id;

            await cadastreLimitWastesRequest({
              data: form4LimitWasteTransferredData,
              method: limitWasteId ? METHODS_API.PUT : METHODS_API.POST,
              id: limitWasteId,
            });
          }

          for await (const limitWastePlaced of approvalWaste.limit_waste_placed_years) {
            const form4LimitWasteTransferredData = {
              kind: APPROVAL_STANDARD_YEAR_KINDS.placed,
              year: limitWastePlaced.year,
              tons: valueToString(limitWastePlaced.tons),
              approval_standard_waste_id: approvalWasteId,
            };

            const limitWasteId = limitWastePlaced.id;

            await cadastreLimitWastesRequest({
              data: form4LimitWasteTransferredData,
              method: limitWasteId ? METHODS_API.PUT : METHODS_API.POST,
              id: limitWasteId,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveForm4ReportApprovalStandards = async ({
  forms,
  reportId,
  isImportToExistingReport,
}) => {
  const form4Info = forms.form4;

  if (form4Info.requiredForm !== undefined) {
    await cadastreReportHistoryRequest({
      data: { is_document_unneeded: form4Info.requiredForm },
      method: METHODS_API.PUT,
      id: reportId,
    });
  }

  if (form4Info.requiredForm) return;

  const reportApprovals = form4Info.documents.map((reportApprovalStandard) => {
    const object = reportApprovalStandard.objective_id;

    const reportApprovalStandardId = reportApprovalStandard?.id;

    const form4ApprovalStandardData = {
      objective_id: object?.id || '',
      oktmo_id: object?.address_attributes?.attributes?.oktmo_attributes?.id || '',
      document_number: reportApprovalStandard.document_number,
      document_date: valueToString(reportApprovalStandard.document_date),
      document_expiration: reportApprovalStandard.document_expiration,
      approval_standard_wastes: reportApprovalStandard.standards.map((approvalWaste) => {
        const approvalWasteId = approvalWaste.id;

        const form4ApprovalWasteData = {
          waste_type_id: approvalWaste.code?.id || '',
          hazard_class: approvalWaste.code?.hazard_class || '',
          standard_tons: approvalWaste.standard_tons,
          transfer_subject_id: approvalWaste?.transfer_subject_id?.id || '',
          transfer_objective_id: approvalWaste?.transfer_objective_id?.id || '',
          place_objective_id: approvalWaste?.place_objective_id?.id || '',

          limit_wastes: [
            ...approvalWaste.limit_waste_transferred_years.map((limitWasteTransferred) => {
              const limitWasteId = limitWasteTransferred.id;

              const form4LimitWasteTransferredData = {
                kind: APPROVAL_STANDARD_YEAR_KINDS.transferred,
                year: limitWasteTransferred.year,
                tons: valueToString(limitWasteTransferred.tons),
              };

              if (!isImportToExistingReport && approvalWasteId) form4LimitWasteTransferredData.approval_standard_waste_id = approvalWasteId;
              if (!isImportToExistingReport && limitWasteId) form4LimitWasteTransferredData.id = limitWasteId;

              return form4LimitWasteTransferredData;
            }),
            ...approvalWaste.limit_waste_placed_years.map((limitWastePlaced) => {
              const limitWasteId = limitWastePlaced.id;

              const form4LimitWastePlacedData = {
                kind: APPROVAL_STANDARD_YEAR_KINDS.placed,
                year: limitWastePlaced.year,
                tons: valueToString(limitWastePlaced.tons),
              };

              if (!isImportToExistingReport && approvalWasteId) form4LimitWastePlacedData.approval_standard_waste_id = approvalWasteId;
              if (!isImportToExistingReport && limitWasteId) form4LimitWastePlacedData.id = limitWasteId;

              return form4LimitWastePlacedData;
            }),
          ],
        };

        if (!isImportToExistingReport && reportApprovalStandardId) form4ApprovalWasteData.report_approval_standard_id = reportApprovalStandardId;
        if (!isImportToExistingReport && approvalWasteId) form4ApprovalWasteData.id = approvalWasteId;

        return form4ApprovalWasteData;
      }),
      report_history_id: reportId,
    };

    if (!isImportToExistingReport && reportApprovalStandardId) form4ApprovalStandardData.id = reportApprovalStandardId;

    return form4ApprovalStandardData;
  });

  const responseReportApprovals = await cadastreForm4ReportApprovalStandardsRequest({
    method: METHODS_API.POST,
    data: reportApprovals,
  });

  return responseReportApprovals?.data;
};
