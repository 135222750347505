import { compareId } from '../compareId';
import { METHODS_API } from '../../api/api';
import {
  cadastreForm5ReportLicensesRequest,
  cadastreIndustrialAreasRequest,
  cadastreReportLicenseRequest,
} from '../../api/cadastreApi';
import { getInitialForm5IndustrialArea, getInitialForm5License } from '../../constants/lkCadastr/cadastrForm5';
import { formatObjectWithAddress, valueToString } from '../stringHelper';
import { sortByCreatedAt } from '../sortHelper';
import { objectChangeDotProperty } from '../objectHelper';

export const getLicensesHelper = async (report) => {
  if (report?.report_license_ids?.length) {
    const allLicenses = [];

    const reportLicensesByIds = await cadastreReportLicenseRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    reportLicensesByIds.forEach((reportLicense) => {
      const additionalFormItems = {};

      const currentIndustrialAreas = reportLicense.industrial_areas_attributes;

      if (currentIndustrialAreas.length) {
        additionalFormItems.industrialAreas = sortByCreatedAt(currentIndustrialAreas, true)
          .map((industrialArea) => {
            const industrialAreaAttributes = industrialArea?.attributes;

            return ({
              ...getInitialForm5IndustrialArea(),
              ...industrialArea,
              ...industrialAreaAttributes,
              objective_id: {
                ...industrialAreaAttributes?.objective_attributes?.attributes,
                ...industrialAreaAttributes?.objective_attributes,
                label: formatObjectWithAddress({ objective: industrialAreaAttributes }),
              },
            });
          });
      }

      allLicenses.push({
        ...getInitialForm5License(),
        ...reportLicense,
        license_number: valueToString(reportLicense?.license_number) || '0',
        license_issue_date: reportLicense?.license_issue_date || '0',
        license_expiration: reportLicense?.license_expiration || '0',
        ...additionalFormItems,
      });
    });

    return { licenses: allLicenses };
  }
};

export const deleteForm5ReportLicense = async ({ reportLicenseId }) => {
  try {
    const deletedReportLicense = await cadastreReportLicenseRequest({
      method: METHODS_API.DELETE,
      id: reportLicenseId,
    });

    return deletedReportLicense?.id;
  } catch (e) {
    console.error(e);
  }
};

export const deleteForm5IndustrialArea = async ({ industrialAreaId }) => {
  try {
    const deletedIndustrialArea = await cadastreIndustrialAreasRequest({
      method: METHODS_API.DELETE,
      id: industrialAreaId,
    });

    return deletedIndustrialArea?.id;
  } catch (e) {
    console.error(e);
  }
};

export const deleteForm5Entities = async ({
  initialForms,
  forms,
}) => {
  for await (const initialLicense of initialForms.form5.licenses) {
    const licenseId = initialLicense.id;

    if (!licenseId) continue;

    const existingActualLicense = forms.form5.licenses
      .find((actualLicense) => compareId(actualLicense.id, licenseId));

    if (!existingActualLicense) {
      await cadastreReportLicenseRequest({
        method: METHODS_API.DELETE,
        id: licenseId,
      });
    } else {
      for await (const initialIndustrialArea of initialLicense.industrialAreas) {
        const industrialAreaId = initialIndustrialArea?.id;

        if (industrialAreaId) {
          const actualIndustrialArea = existingActualLicense.industrialAreas
            .find((industrialArea) => compareId(industrialArea.id, industrialAreaId));

          if (!actualIndustrialArea) {
            await cadastreIndustrialAreasRequest({
              method: METHODS_API.DELETE,
              id: industrialAreaId,
            });
          }
        }
      }
    }
  }
};

export const saveOneForm5ReportLicense = async ({ reportId, reportLicense }) => {
  let reportLicenseId = reportLicense?.id;

  try {
    const form5LicenseData = {
      license_number: reportLicense?.license_number,
      license_issue_date: valueToString(reportLicense?.license_issue_date),
      license_expiration: reportLicense?.is_forever ? '' : valueToString(reportLicense?.license_expiration),
      is_forever: reportLicense?.is_forever,
      report_history_id: reportId,
    };

    const newLicense = await cadastreReportLicenseRequest({
      data: form5LicenseData,
      method: reportLicenseId ? METHODS_API.PUT : METHODS_API.POST,
      id: reportLicenseId,
    });

    reportLicenseId = newLicense?.id;
  } catch (e) {
    console.error(e);
  }

  return { reportLicenseId };
};

export const saveOneForm5IndustrialArea = async ({ reportId, reportLicense, industrialArea }) => {
  let reportLicenseId = reportLicense?.id;
  let industrialAreaId = industrialArea?.id;

  try {
    if (!reportLicenseId) {
      const { reportLicenseId: newReportLicenseId } = await saveOneForm5ReportLicense({ reportLicense, reportId });

      if (reportLicenseId) reportLicenseId = newReportLicenseId;
    }

    if (reportLicenseId) {
      const form5Data = {
        name: industrialArea.name,
        area_number: industrialArea.area_number,
        objective_id: industrialArea.objective_id?.id || '',
        works_self_waste: industrialArea.works_self_waste,
        works_another_waste: industrialArea.works_another_waste,
        report_license_id: reportLicenseId,
      };

      const newIndustrialArea = await cadastreIndustrialAreasRequest({
        data: form5Data,
        method: industrialArea?.id ? METHODS_API.PUT : METHODS_API.POST,
        id: industrialArea?.id,
      });

      industrialAreaId = newIndustrialArea?.id;
    }
  } catch (e) {
    console.error(e);
  }

  return { reportLicenseId, industrialAreaId };
};

export const createUpdateForm5Entities = async ({ forms, reportId }) => {
  const form5Info = forms.form5;

  for await (const license of form5Info.licenses) {
    try {
      const form5LicenseData = {
        license_number: license?.license_number,
        license_issue_date: valueToString(license?.license_issue_date),
        license_expiration: license?.is_forever ? '' : license?.license_expiration,
        is_forever: license?.is_forever,
        report_history_id: reportId,
      };

      let licenseId = license?.id;

      const createdIndustrialArea = await cadastreReportLicenseRequest({
        data: form5LicenseData,
        method: licenseId ? METHODS_API.PUT : METHODS_API.POST,
        id: licenseId,
      });

      licenseId = createdIndustrialArea?.id;

      if (!licenseId) return;

      for await (const industrial_area of license.industrialAreas) {
        try {
          const form5Data = {
            name: industrial_area.name,
            area_number: industrial_area.area_number,
            objective_id: industrial_area.objective_id?.id || '',
            works_self_waste: industrial_area.works_self_waste,
            works_another_waste: industrial_area.works_another_waste,
            report_license_id: licenseId,
          };

          const industrial_area_id = industrial_area.id;

          await cadastreIndustrialAreasRequest({
            data: form5Data,
            method: industrial_area_id ? METHODS_API.PUT : METHODS_API.POST,
            id: industrial_area_id,
          });
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveForm5ReportLicense = async ({
  forms,
  reportId,
  isImportToExistingReport,
}) => {
  const form5Info = forms.form5;

  const reportLicensesData = form5Info.licenses.map((reportLicense) => {
    const licenseId = reportLicense?.id;

    const form5LicenseData = {
      license_number: reportLicense?.license_number,
      license_issue_date: valueToString(reportLicense?.license_issue_date),
      license_expiration: reportLicense?.is_forever ? '' : reportLicense?.license_expiration,
      is_forever: reportLicense?.is_forever,
      report_history_id: reportId,
      industrial_areas: reportLicense.industrialAreas.map((industrialArea) => {
        const industrial_area_id = industrialArea.id;

        const form5IndustrialAreaData = {
          name: industrialArea.name,
          area_number: industrialArea.area_number,
          objective_id: industrialArea.objective_id?.id || '',
          works_self_waste: industrialArea.works_self_waste,
          works_another_waste: industrialArea.works_another_waste,
        };

        if (!isImportToExistingReport && licenseId) form5IndustrialAreaData.report_license_id = licenseId;
        if (!isImportToExistingReport && industrial_area_id) form5IndustrialAreaData.id = industrial_area_id;

        return form5IndustrialAreaData;
      }),
    };

    if (!isImportToExistingReport && licenseId) form5LicenseData.id = licenseId;

    return form5LicenseData;
  });

  const responseReportLicenses = await cadastreForm5ReportLicensesRequest({
    method: METHODS_API.POST,
    data: reportLicensesData,
  });

  return responseReportLicenses?.data;
};

export const changeForm5Entity = async ({
  reportLicenseIndex,
  industrialAreaIndex,
  newCompleteForms,
  reportForm,
  setLoading,
  forms,
  setForms,
  setInitialForms,
  updateReport,
}) => {
  setLoading(true);

  const foundReportLicense = forms.form5.licenses[reportLicenseIndex];
  const foundIndustrialArea = foundReportLicense?.industrialAreas?.[industrialAreaIndex];

  let saveRequest = saveOneForm5ReportLicense;

  if (foundIndustrialArea) saveRequest = saveOneForm5IndustrialArea;

  const { reportLicenseId, industrialAreaId } = await saveRequest({
    reportId: reportForm?.id, reportLicense: foundReportLicense, industrialArea: foundIndustrialArea,
  });

  if (!reportLicenseId) {
    setLoading(false);
    return;
  }

  let newForms = forms;

  if (industrialAreaId) {
    if (!foundIndustrialArea?.id) {
      newForms = objectChangeDotProperty({
        field: `form5.licenses.${reportLicenseIndex}.industrialAreas.${industrialAreaIndex}.id`,
        value: industrialAreaId,
        object: newForms,
      });
    }
  }

  if (!foundReportLicense?.id) {
    newForms = objectChangeDotProperty({
      field: `form5.licenses.${reportLicenseIndex}.id`,
      value: reportLicenseId,
      object: newForms,
    });
  }

  setForms(newForms);
  setInitialForms(newForms);

  await updateReport({ newCompleteForms });

  setLoading(false);

  return { reportLicenseId, industrialAreaId };
};
