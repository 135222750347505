import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { getVehiclesLastPosition } from '../api/vehiclesApi';

let interval;
let loading;

const removeInterval = () => {
  if (interval) clearInterval(interval);
  interval = undefined;
};

function getInterval(callback) {
  interval = setInterval(() => {
    if (loading) return;

    callback();
  }, 10000);
}

export default function useTrackVehiclesPositions({
  vehicles, setVehicles, shouldRequest, request = getVehiclesLastPosition,
}) {
  const [hasMountRequest, setHasMountRequest] = useState(false);
  const { makeRequest } = useApi({
    request: async () => request()
      .then?.((data) => {
        if (data?.data?.length) setVehicles(data?.data);
      })
      .catch(() => {
        throw { frontendError: 'Не удалось получить координаты' };
      })
      .finally(() => {
        loading = false;
      }),
  });

  useEffect(() => {
    if (shouldRequest && !hasMountRequest) {
      makeRequest();

      setHasMountRequest(true);
    }

    if (shouldRequest && !interval) {
      return getInterval(() => {
        loading = true;

        makeRequest();
      });
    }

    if (!shouldRequest) {
      setHasMountRequest(false);
      removeInterval();
    }
    /* eslint-disable-next-line */
  }, [shouldRequest, vehicles]);

  useEffect(() => () => {
    removeInterval();
    setHasMountRequest(false);
  }, []);
}
