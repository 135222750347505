import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../../Text';
import DeleteButton from '../../../../Buttons/DeleteButton';
import AddButton from '../../../../Buttons/AddButton';
import {
  getInitialWastePlacementMunicipality,
  WASTE_PLACEMENT_MUNICIPALITIES_NAME,
} from '../../../../../constants/lkCadastr/cadastrForm8';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../../../Inputs';
import { AsyncAutocomplete } from '../../../../AutoCompletes/AsyncAutocomplete';
import { getReferencesRequest } from '../../../../../api/referenceApi';
import { OKTMOS_URL } from '../../../../../constants/urls';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import NavTabs from '../../../../Tabs';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';
import { compareId } from '../../../../../helpers';

export function WastePlacementMunicipalities({
  placingObject, changeObject, isDemo, openDeleteModal,
}) {
  const municipals = placingObject[WASTE_PLACEMENT_MUNICIPALITIES_NAME];

  const { tab, setTab, tabs } = useCadastreTabs({ items: municipals, tabName: 'Муниципальное образование' });

  return (
    <Box sx={flexGap2}>
      <Box sx={flexRowGap2}>
        <TextTitle h3>
          Муниципальные образования, размещающие отходы на объекте
        </TextTitle>
        {!isDemo && (
          <AddButton
            variant="outlined"
            onClick={() => changeObject(
              WASTE_PLACEMENT_MUNICIPALITIES_NAME,
              [...municipals, getInitialWastePlacementMunicipality()],
            )}
          >
            Добавить муниципальное образование
          </AddButton>
        )}
      </Box>
      <NavTabs tab={tab} setTab={setTab} tabsComponents={tabs} />
      {municipals.map((municipal, index) => {
        if (tab !== municipal.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${WASTE_PLACEMENT_MUNICIPALITIES_NAME}.${index}.${field}`, value, undefined, { wastePlacementMunicipalityIndex: index });
        };

        const onDelete = async () => {
          const deletedWastePlacementMunicipality = await deleteForm8Entity({
            id: municipal.id,
            apiType: WASTE_PLACEMENT_MUNICIPALITIES_NAME,
          });

          if (!deletedWastePlacementMunicipality) return;

          const cloneArray = municipals.slice();
          cloneArray.splice(index, 1);

          changeObject(
            WASTE_PLACEMENT_MUNICIPALITIES_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box key={municipal.randId} sx={flexGap2}>
            <Box display="flex" alignItems="center" mb={1} gap={3}>
              <TextTitle>
                Муниципальное образование
                №
                {index + 1}
              </TextTitle>
              {!isDemo && municipals.length > 1 && municipal?.id && (
                <DeleteButton
                  onClick={() => openDeleteModal(onDelete, index, 'Муниципальное образование')}
                >
                  Удалить муниципальное образование
                </DeleteButton>
              )}
            </Box>
            <Box sx={flexGap2}>
              <AsyncAutocomplete
                canBeZero
                validImmediately={!compareId(municipal.oktmo_id?.label, 0)}
                debounceTime={0}
                disabled={isDemo}
                label="ОКТМО"
                value={municipal.oktmo_id}
                sx={fullWidth}
                optionField={{ label: 'label' }}
                additionalFilter={(options) => options.map((option) => ({ ...option, label: `${option.code} ${option.name}` }))}
                onChange={(e, value) => onChange('oktmo_id', value)}
                request={(params) => getReferencesRequest({ url: OKTMOS_URL, params })}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                type="number"
                label="Объем отходов, т"
                sx={fitContent}
                value={municipal.waste_volume}
                onChange={(e, value) => onChange('waste_volume', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
