import React from 'react';
import { Box } from '@mui/material';

export default function EmailConfirmationContainer({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={3}
      height="100vh"
    >
      {children}
    </Box>
  );
}
