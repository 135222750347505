import React, { memo, useEffect, useState } from 'react';
import { Circle, Polyline } from 'react-leaflet';

const shouldMemo = (prevProps, nextProps) => {
  if (prevProps.planned_routes?.length === 0 && nextProps.planned_routes?.length === 0) return true;

  if (
    prevProps.map?.fitBounds === undefined
    || nextProps.map?.fitBounds === undefined
  ) return false;

  return prevProps.planned_routes?.length === nextProps.planned_routes?.length;
};

const getCoordinates = ((routes) => routes.map(({ place }) => place));

const TrackRoutes = memo(({
  planned_routes,
  getTrackCircleChildren,
  map,
  pathOptions,
  shouldShowPoints = false,
}) => {
  const [plannedRoutes, setPlannedRoutes] = useState([]);

  useEffect(() => {
    if (plannedRoutes?.length > 0) {
      map?.fitBounds?.(getCoordinates(plannedRoutes));
    }
    /* eslint-disable-next-line */
  }, [plannedRoutes, map]);

  useEffect(() => {
    if (planned_routes?.length) {
      setPlannedRoutes(planned_routes.map((item) => ({
        ...item,
        place: [item?.place?.x, item?.place?.y],
      })));
    } else setPlannedRoutes([]);
  }, [planned_routes]);

  if (!plannedRoutes?.length) return null;

  return (
    <>
      <Polyline pathOptions={pathOptions} positions={getCoordinates(plannedRoutes)} />
      {shouldShowPoints
        && plannedRoutes.map(({ place }, index) => (
          <Circle center={[place?.[0], place?.[1]]} radius={1}>
            {getTrackCircleChildren && getTrackCircleChildren(index)}
          </Circle>
        ))}
    </>
  );
}, shouldMemo);

export default TrackRoutes;
