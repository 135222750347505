import API from './api';
import {
  FORGET_PASSWORD_URL,
  MEMBER_URL, REGISTRATION_SUBJECT_URL, SIGN_IN_USER_URL, USERS_URL,
} from '../constants/urls';
import { readToken } from '../helpers';

export const loginUserRequest = (user) => API({
  method: 'post',
  url: SIGN_IN_USER_URL,
  data: { user },
}, { isEntityAttributes: true });

export const logoutRequest = (url) => API({ method: 'delete', url });

export const getUsersRequest = (params, setTableStore) => API(
  { method: 'get', url: USERS_URL, params },
  { isEntityAttributes: true, setTableStore },
);
export const getUserRequest = (id) => API({ method: 'get', url: `${USERS_URL}/${id}` });

export const createUserRequest = (user) => API({ method: 'post', url: USERS_URL, data: { user } });

export const updateUserRequest = (id, user) => API({ method: 'put', url: `${USERS_URL}/${id}`, data: { user } });

export const deleteUserRequest = (id) => API({ method: 'delete', url: `${USERS_URL}/${id}` });

export const getMemberRequest = () => {
  const token = readToken();
  if (typeof token === 'string') return API({ url: MEMBER_URL }, { isEntityAttributes: true });
};

export const registrationRequest = (data) => API({ method: 'post', url: REGISTRATION_SUBJECT_URL, data });

export const forgotPasswordUserRequest = (email) => API(
  { url: `${USERS_URL}${FORGET_PASSWORD_URL}`, method: 'POST', data: { user: email } },
  { isEntityAttributes: true },
);
