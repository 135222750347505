import { useUserStore } from '../store';
import { checkIsSuperAdmin } from '../helpers/roleHelper';

export function useAdminRole() {
  const { userStore } = useUserStore();

  if (!userStore.id) return {};

  const isUserAdmin = ['admin', 'admin_cadastre'].includes(userStore?.role_attributes?.attributes?.code);
  const isUserMainAdmin = checkIsSuperAdmin(userStore);

  return { isUserAdmin, isUserMainAdmin };
}
