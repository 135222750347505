import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { Button } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { usePushNotification } from '../hooks';

export default function CopyClipboard({ text }) {
  const pushNotification = usePushNotification();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => pushNotification({
        message: 'Скопировано',
        variant: 'success',
        autoHideDuration: 1000,
      })}
    >
      <Button variant="contained">
        <CopyAll />
        Скопировать публичную ссылку
      </Button>
    </CopyToClipboard>
  );
}
