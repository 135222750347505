import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomPagination from '../CustomPagination';
import BackdropLoading from '../BackdropLoading';
import { TEXT_NO_VALUE } from '../../constants';
import EditButton from '../Buttons/EditButton';
import DeleteButton from '../Buttons/DeleteButton';
import useDeleteModal from '../../hooks/useDeleteModal';
import { TextSignature, TextTitle } from '../Text';
import { useTableStore } from '../../store';
import { sliceLongString } from '../../helpers/stringHelper';
import { TEXT_SIGNATURE } from '../../constants/colors';

const innerContainerSx = {
  mb: 1,
  position: 'relative',
  maxHeight: 580,
  '.MuiTableCell-root': { border: 'none' },
  tbody: {
    '.MuiTableCell-root:first-of-type': { borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' },
    '.MuiTableCell-root:last-of-type': { borderTopRightRadius: '8px', borderBottomRightRadius: '8px' },
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 6px',
  },
};

export default function CustomTable({
  hideHeader,
  hidePagination,
  disabled,
  disabledRow = () => false,
  showRowsNumber = false,
  hideTotal = false,
  columns,
  data,
  onClick,
  page,
  setPage,
  total,
  sx,
  perPage,
  setPerPage,
  isLoading,
  perPageOptions,
  deleteRow,
  selectedRowItemId,
  editRow,
  containerSx,
  tableBodySx,
  hideBodyCell,
  additionalRows = [],
  additionalControls,
  controlHeaderCellName = 'Управление',
}) {
  const { tableStore } = useTableStore();
  const mainTotal = total !== undefined ? total : tableStore.meta?.total || 0;
  const mainPage = page !== undefined ? page : tableStore.page || 0;
  const mainPerPage = perPage !== undefined ? perPage : tableStore.perPage || 0;

  const openDeleteModal = useDeleteModal();

  const onDeleteRow = (e, row, index) => {
    e.stopPropagation();

    openDeleteModal({
      objectName: deleteRow.getDeleteObjectName(row, index),
      deleteItem: () => deleteRow.deleteRequest(row),
      id: row.id,
      message: deleteRow.deleteMessage,
    });
  };

  const onEditRow = (e, row) => {
    e.stopPropagation();
    editRow(row);
  };

  const mainTheme = useTheme();
  const tabletSizeAndLower = useMediaQuery(mainTheme.breakpoints.down('md'));

  return (
    <Box position="relative" sx={sx}>
      {!hideTotal && (
        <TextTitle sx={{ fontWeight: 'bold', mr: 2 }} component="span">
          Всего элементов:
          {' '}
          {mainTotal}
        </TextTitle>
      )}
      <TableContainer sx={{ ...innerContainerSx, ...containerSx }}>
        <BackdropLoading position="absolute" isLoading={isLoading} />
        <Table
          stickyHeader
          sx={{ minWidth: 650, '.MuiTableCell-root': { padding: tabletSizeAndLower ? '4px' : '8px' } }}
        >
          {!hideHeader && (
            <TableHead className="background-main">
              <TableRow>
                {showRowsNumber && (
                  <TableCell sx={{ fontWeight: 'bold' }} align="center">
                    <TextSignature>№</TextSignature>
                  </TableCell>
                )}
                {columns.map(({ headerTitle, getHeaderTitle }, index) => (
                  <TableCell sx={{ fontWeight: 'bold' }} key={headerTitle} align="center">
                    <TextSignature>
                      {getHeaderTitle ? getHeaderTitle(data[index]) : headerTitle}
                    </TextSignature>
                  </TableCell>
                ))}
                {(editRow || deleteRow || additionalControls) && data?.length > 0 && (
                  <TableCell sx={{ fontWeight: 'bold', color: (theme) => theme.palette.warning.main }} align="center">
                    {controlHeaderCellName}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody sx={tableBodySx}>
            {isLoading ? [] : data.map((row, bodyIndex) => {
              if (hideBodyCell && hideBodyCell(row, bodyIndex)) return null;

              return (
                <TableRow
                  selected={disabled || disabledRow(row, bodyIndex)}
                  onClick={(e) => {
                    if (disabled || disabledRow(row)) return;
                    if (e.target.nodeName == 'INPUT' || e.target.nodeName == 'path' || e.target.nodeName == 'svg' || e.target.nodeName == 'BUTTON') { return; }

                    if (window.getSelection().toString()?.length) {
                      return;
                    }

                    if (selectedRowItemId && row.id === selectedRowItemId) return onClick?.();

                    onClick?.(row);
                  }}
                  key={row.id}
                  hover={!disabled && !disabledRow(row)}
                  sx={{
                    cursor: !disabled && !disabledRow(row) && 'pointer',
                    background: row.id === selectedRowItemId ? TEXT_SIGNATURE : 'white',
                    opacity: row?.deleted_at && 0.3,
                  }}
                >
                  {showRowsNumber && (
                    <TableCell align="center">
                      <TextTitle className="text-small custom-text">
                        {(mainPage * mainPerPage - mainPerPage + 1) + bodyIndex}
                      </TextTitle>
                    </TableCell>
                  )}
                  {columns.map(({ field, getField, getComponent }) => (
                    <TableCell key={field} align="center">
                      {getComponent ? sliceLongString(
                        getComponent({ ...row, rowIndex: bodyIndex }),
                        tabletSizeAndLower,
                      ) : (
                        <TextTitle className="text-small custom-text">
                          {getField
                            ? (
                              sliceLongString(
                                getField({ ...row, rowIndex: bodyIndex }),
                                tabletSizeAndLower,
                              )
                              || TEXT_NO_VALUE
                            )
                            : (
                              sliceLongString(row[field]?.label, tabletSizeAndLower)
                              || sliceLongString(row[field], tabletSizeAndLower)
                              || TEXT_NO_VALUE
                            )}
                        </TextTitle>
                      )}
                    </TableCell>
                  ))}
                  {(editRow || deleteRow || additionalControls) && (
                    <TableCell
                      sx={{
                        fontWeight: 'bold', display: 'flex', alignItems: 'center', flexDirection: 'column',
                      }}
                      align="center"
                    >
                      {!row?.deleted_at && (
                        <>
                          {editRow
                            && <EditButton variant="text" title="Редактировать" onClick={(e) => onEditRow(e, row)} />}
                          {deleteRow
                            && <DeleteButton title="Удалить" onClick={(e) => onDeleteRow(e, row, bodyIndex)} />}
                          {additionalControls?.map?.(({ render, id }) => (
                            <React.Fragment key={id}>
                              {render(row, bodyIndex)}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </TableCell>
                  )}
                  {additionalRows?.map?.(({ render = () => { }, id = 1 }) => (
                    <TableCell key={id} align="center">
                      {render(row)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && data.length === 0 && (
        <TextTitle sx={{ textAlign: 'center', background: 'antiquewhite', py: 2 }}>Нет данных</TextTitle>
      )}
      {!hidePagination && (
        <CustomPagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          perPage={perPage}
          total={total}
          perPageOptions={perPageOptions}
        />
      )}
    </Box>
  );
}
