// @ts-nocheck
import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../Text';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { getSubjectsRequest } from '../../api/subjectApi';
import { getFullName } from '../../helpers/stringHelper';
import { getUsersRequest } from '../../api/userApi';
import DeleteButton from '../Buttons/DeleteButton';

const autocompleteSx = { width: '100%' };
const containerSx = { flex: '1 1 0' };

export default function EmailUserInputs({
  emailUsers, emailUserErrors, changeEmailUser, deleteEmailUser,
}) {
  return (
    <Box display="flex" flexDirection="column" gap={3} mb={3}>
      {emailUsers.map((item, index) => {
        const currentError = emailUserErrors.find(({ id }) => id === item.id);

        return (
          <Box key={item.id}>
            <TextTitle sx={{ mb: 2 }}>
              Получатель
              {' '}
              {index + 1}
              {' '}
              - Почта:
              {item?.inn?.email || item?.user?.email || 'Нет почты'}
            </TextTitle>
            <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
              {!item.user.id && (
                <>
                  <AsyncAutocomplete
                    value={item.subject}
                    name="subject"
                    containerSx={containerSx}
                    sx={autocompleteSx}
                    error={currentError?.subject}
                    onChange={changeEmailUser(index)}
                    label="Субьект"
                    optionField={{
                      label: 'name',
                      value: 'id',
                    }}
                    request={(params) => getSubjectsRequest({ params })}
                  />
                  <AsyncAutocomplete
                    value={item.inn}
                    name="inn"
                    containerSx={containerSx}
                    sx={autocompleteSx}
                    error={currentError?.inn}
                    onChange={changeEmailUser(index)}
                    label="ИНН"
                    optionField={{
                      label: 'inn',
                      value: 'inn',
                    }}
                    request={(params) => getSubjectsRequest({ params })}
                  />
                </>
              )}
              {!item.subject.id && (
                <AsyncAutocomplete
                  disabled={item.subject.id}
                  label="Пользователь"
                  name="user"
                  containerSx={containerSx}
                  sx={autocompleteSx}
                  value={item.user}
                  error={currentError?.user}
                  onChange={changeEmailUser(index)}
                  additionalFilter={(users) => users.map((user) => ({
                    ...user,
                    name: getFullName(user),
                  }))}
                  optionField={{
                    label: 'name',
                    value: 'id',
                  }}
                  request={(params) => getUsersRequest(params)}
                />
              )}
              {emailUsers.length > 1 && (
                <DeleteButton onClick={deleteEmailUser(index)}>Удалить</DeleteButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
