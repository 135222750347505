import React from 'react';
import { Box, Typography } from '@mui/material';
import EditButton from '../Buttons/EditButton';
import { ReactComponent as EditGrayIcon } from '../../assets/edit_gray_icon.svg';
import CadastrRowEdit from './CadastrRowEdit';

export default function CadastrEdit({
  field,
  error,
  title,
  onChange,
  userInfo,
  editField,
  changeEdit,
  makeRequest,
  request,
  multiline,
  getField,
  multiple,
  type,
  sx,
}) {
  return (
    <Box key={field} sx={sx}>
      <Box display="flex" alignItems="center">
        <Typography fontWeight="bold" fontSize={16}>
          {title}
          {editField[field] !== undefined && ' *'}
        </Typography>
        {!editField[field] && editField[field] !== undefined && (
          <EditButton Icon={EditGrayIcon} variant="text" onClick={() => changeEdit(field, true)}>
            Изменить
          </EditButton>
        )}
      </Box>
      <CadastrRowEdit
        error={error}
        request={request}
        field={field}
        type={type}
        onChange={onChange}
        userInfo={userInfo}
        getField={getField}
        editField={editField}
        changeEdit={changeEdit}
        makeRequest={makeRequest}
        multiline={multiline}
        multiple={multiple}
      />
    </Box>
  );
}
