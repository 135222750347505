// @ts-nocheck
import React, { memo, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../Inputs';
import CustomDatePicker from '../../CustomDatePicker';
import { Form8Areas } from './components/Form8/Form8Areas';
import { TextMain, TextTitle } from '../../Text';
import { Form8PlacedWastes } from './components/Form8/Form8PlacedWastes';
import { EnvironmentMonitoringTypes } from './components/Form8/EnvironmentMonitoringTypes';
import { ObjectRights } from './components/Form8/ObjectRights';
import { WasteTransporters } from './components/Form8/WasteTransproters';
import { WastePlacementMunicipalities } from './components/Form8/WastePlacementMunicipalities';
import AddButton from '../../Buttons/AddButton';
import CustomAccordion from '../../CustomAccordion';
import DeleteButton from '../../Buttons/DeleteButton';
import { AsyncAutocomplete } from '../../AutoCompletes/AsyncAutocomplete';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import {
  ENVIRONMENT_MONITORING_TYPE_NAME,
  ENVIRONMENTAL_SYSTEM_TYPE,
  FORM8_AREA_DOCUMENTS_NAME,
  FORM8_LICENSES,
  FORM8_PLACING_OBJECTS_NAME,
  getInitialForm8PlacedObject,
  NEAREST_TOWN_NAME,
  NEAREST_WATER_OBJECT_NAME,
  OBJECT_RIGHT_TYPES_NAME,
  OBJECT_SUBJECT_RELATIONSHIPS_NAME,
  PLACED_WASTES_NAME,
  PROJECT_DOCUMENT_NAME,
  RESTRICTED_AREA_TYPE_NAME,
  WASTE_PLACEMENT_MUNICIPALITIES_NAME,
} from '../../../constants/lkCadastr/cadastrForm8';
import ModalAddObject from '../ModalAddObject';
import { OBJECT_STATUS } from '../../../constants/objectConstants';
import {
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  SUBJECT_TYPE_ATTRIBUTES,
  WASTE_MANAGEMENT_TYPE_IDS,
} from '../../../constants/enums';
import NavTabs from '../../Tabs';
import CadastreLicensesContainer from '../../Cadastr/CadastreLicensesContainer';
import { getNearestTownDirectionEnumsRequest, getObjectiveStateEnumsRequest } from '../../../api/cadastreApi';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_FIELD } from '../../Inputs/CustomAutocomplete';
import ModalAddSubject from '../ModalAddSubject';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import { getReferencesRequest } from '../../../api/referenceApi';
import { formatEnumToSelectOption } from '../../../helpers/entityHelper';
import CustomCheckbox from '../../CustomCheckbox';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import { deleteForm8Entity } from '../../../helpers/cadastreForms/form8Helper';
import { compareId } from '../../../helpers';

const REPORT_OBJECT_TABS = [
  { label: 'Информация об объекте', name: 'object_info' },
  { label: 'Отходы', name: PLACED_WASTES_NAME },
  { label: 'Лицензии', name: 'licenses' },
  { label: 'Правовые документы', name: FORM8_AREA_DOCUMENTS_NAME },
  { label: 'Мониторинг окружающей среды', name: ENVIRONMENT_MONITORING_TYPE_NAME },
  { label: 'Права на объект', name: OBJECT_RIGHT_TYPES_NAME },
  { label: 'Юридическое лицо транспортирующее отходы', name: OBJECT_SUBJECT_RELATIONSHIPS_NAME },
  { label: 'Муниципальные образования', name: WASTE_PLACEMENT_MUNICIPALITIES_NAME },
];

function ModalCadastrForm8({
  data: { placing_objects },
  isHideForm5,
  onChange,
  isDemo,
  wasteExcludeAccumulationParams,
  findForm1Object,
  form1Objects,
  form5Licenses,
  changeLicenseUnneeded,
  is_license_unneeded,
  subjectAttributes,
}) {
  const [tab, setTab] = useState(REPORT_OBJECT_TABS[0].name);
  const { tab: objectTab, setTab: setObjectTab, tabs: objectTabs } = useCadastreTabs({
    items: placing_objects,
    tabName: 'Размещаемый объект ',
  });
  const [createObjectIndex, setCreateObjectIndex] = useState(null);
  const [createSubjectField, setCreateSubjectField] = useState(null);

  const closeNewSubjectModal = () => setCreateSubjectField(null);

  const setNewSubject = (newSubject) => {
    onChange({
      field: `${FORM8_PLACING_OBJECTS_NAME}.${createSubjectField.field}`,
      value: { ...newSubject, ...newSubject?.attributes },
      objectSubjectRelationshipIndex: createSubjectField.objectSubjectRelationshipIndex,
    });
    closeNewSubjectModal();
  };

  const newObjectName = useMemo(() => {
    if (createObjectIndex !== null) return placing_objects[createObjectIndex].objective_id.name;
  }, [createObjectIndex, placing_objects]);

  const setNewObject = (newObject) => {
    onChange({
      field: `${FORM8_PLACING_OBJECTS_NAME}.${createObjectIndex}.objective_id`,
      value: {
        ...newObject,
        label: formatObjectWithAddress({ objective: newObject }),
      },
      placingObjectIndex: createObjectIndex,
    });
  };

  const { openDeleteModal } = useDeleteCadastreEntity();

  const form1ObjectsOptions = form1Objects
    .map((item) => ({
      ...item.objective_id,
    }));

  const form1ObjectsAddressOptions = form1Objects
    .map((item) => ({
      ...item.objective_id,
      label: formatObjectWithAddress({ objective: item.objective_id }),
    }));

  return (
    <>
      {createSubjectField !== null && (
        <ModalAddSubject
          division="internal"
          close={closeNewSubjectModal}
          setNewSubject={setNewSubject}
        />
      )}
      {createObjectIndex !== null && (
        <ModalAddObject
          objectTypeTitle=""
          close={() => setCreateObjectIndex(null)}
          division="internal"
          helpComponent={(
            <Box mb={2}>
              <TextMain>
                Примеры наименования объекта
              </TextMain>
              <TextMain>
                1) Объект образования отходов ...
              </TextMain>
              <TextMain>
                2) Объекта обращения с отходами ...
              </TextMain>
              <TextMain>
                3) Производственная площадка ...
              </TextMain>
            </Box>
          )}
          status={OBJECT_STATUS.DRAFT}
          subjectData={{
            subject: subjectAttributes,
            inn: { inn: subjectAttributes.inn },
            subject_type: subjectAttributes?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
            name: newObjectName,
          }}
          wasteManagementTypes={wasteExcludeAccumulationParams[WASTE_MANAGEMENT_TYPE_IDS].split(',')}
          setNewObject={setNewObject}
        />
      )}
      <Box sx={flexGap2}>
        <Box sx={flexGap2}>
          <Box sx={flexRowGap2}>
            <TextTitle h3>Объекты</TextTitle>
            {!isDemo && (
              <AddButton
                variant="outlined"
                onClick={() => onChange({
                  field: FORM8_PLACING_OBJECTS_NAME,
                  value: [...placing_objects, getInitialForm8PlacedObject()],
                })}
              >
                Добавить размещаемый объект
              </AddButton>
            )}
          </Box>
          <NavTabs tab={objectTab} setTab={setObjectTab} tabsComponents={objectTabs} />
          <Box sx={flexGap2}>
            {placing_objects.map((placingObject, objectIndex) => {
              if (objectTab !== placingObject.randId) return null;

              const placingObjectField = `${FORM8_PLACING_OBJECTS_NAME}.${objectIndex}`;

              const changeObject = (field, value) => onChange({
                field: `${placingObjectField}.${field}`,
                value,
                placingObjectIndex: objectIndex,
              });

              const onDeleteObject = async () => {
                if (placingObject?.id) {
                  const deletedObject = await deleteForm8Entity({
                    id: placingObject.id,
                    apiType: FORM8_PLACING_OBJECTS_NAME,
                  });

                  if (!deletedObject) return;
                }

                const cloneArray = placing_objects.slice();
                cloneArray.splice(objectIndex, 1);

                onChange({
                  field: FORM8_PLACING_OBJECTS_NAME,
                  value: cloneArray,
                  type: 'delete',
                });
              };

              return (
                <Box key={placingObject.randId} sx={flexGap2}>
                  {!isDemo && placing_objects.length > 1 && (
                    <DeleteButton onClick={() => openDeleteModal(onDeleteObject, objectIndex, 'Размещаемый объект')}>
                      Удалить размещаемый объект
                    </DeleteButton>
                  )}
                  <CustomAutocomplete
                    canBeZero
                    validImmediately={!compareId(placingObject.objective_id?.name, 0)}
                    required
                    debounceTime={0}
                    disabled={isDemo}
                    useDefaultFilter
                    label="Наименование объекта"
                    sx={fullWidth}
                    noOptionComponent={(
                      <AddButton
                        variant="outlined"
                        onClick={() => setCreateObjectIndex(objectIndex)}
                      >
                        Создать объект
                      </AddButton>
                    )}
                    fields={{ label: 'name' }}
                    value={placingObject.objective_id}
                    options={form1ObjectsOptions}
                    onChange={(e, value) => changeObject('objective_id', value)}
                  />
                  <CustomAutocomplete
                    canBeZero
                    required
                    useDefaultFilter
                    validImmediately={!compareId(placingObject.objective_id?.name, 0)}
                    debounceTime={0}
                    disabled={isDemo || compareId(placingObject.objective_id?.name, 0)}
                    label="Фактический адрес"
                    sx={fullWidth}
                    options={form1ObjectsAddressOptions}
                    noOptionComponent={(
                      <AddButton
                        variant="outlined"
                        onClick={() => setCreateObjectIndex(objectIndex)}
                      >
                        Создать объект
                      </AddButton>
                    )}
                    value={placingObject.objective_id}
                    onChange={(e, value) => changeObject('objective_id', value)}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={flexGap2}>
          <NavTabs tab={tab} setTab={setTab} tabsComponents={REPORT_OBJECT_TABS} />
          <Box sx={flexGap2}>
            {placing_objects.map((placingObject, placingObjectIndex) => {
              const randId = placingObject?.randId;

              if (objectTab !== randId) return null;

              const placingObjectField = `${FORM8_PLACING_OBJECTS_NAME}.${placingObjectIndex}`;

              const changeObject = (field, value, type, innerIndexProp) => {
                onChange({
                  field: `${placingObjectField}.${field}`,
                  value,
                  type,
                  placingObjectIndex,
                  ...innerIndexProp,
                });
              };

              const changeProjectDocument = (field, value) => {
                changeObject(`${PROJECT_DOCUMENT_NAME}.${field}`, value, undefined, { projectDocumentIndex: PROJECT_DOCUMENT_NAME });
              };

              const changeNearestTown = (field, value) => {
                changeObject(`${NEAREST_TOWN_NAME}.${field}`, value, undefined, { nearestTownIndex: NEAREST_TOWN_NAME });
              };

              const changeNearestWaterObject = (field, value) => {
                changeObject(`${NEAREST_WATER_OBJECT_NAME}.${field}`, value, undefined, { nearestWaterObjectIndex: NEAREST_WATER_OBJECT_NAME });
              };

              const arrayProps = {
                placingObject, isDemo, changeObject, openDeleteModal,
              };

              const form1Object = findForm1Object(placingObject?.objective_id?.id);

              if (tab === FORM8_LICENSES) {
                return (
                  <CadastreLicensesContainer
                    {...arrayProps}
                    key={randId}
                    openDeleteModal={openDeleteModal}
                    isHideForm5={isHideForm5}
                    changeLicenseUnneeded={changeLicenseUnneeded}
                    is_license_unneeded={is_license_unneeded}
                    form5Licenses={form5Licenses}
                  />
                );
              }
              if (tab === PLACED_WASTES_NAME) return <Form8PlacedWastes key={randId} {...arrayProps} />;
              if (tab === FORM8_AREA_DOCUMENTS_NAME) return <Form8Areas key={randId} {...arrayProps} />;
              if (tab === ENVIRONMENT_MONITORING_TYPE_NAME) return <EnvironmentMonitoringTypes key={randId} {...arrayProps} />;
              if (tab === OBJECT_RIGHT_TYPES_NAME) return <ObjectRights key={randId} {...arrayProps} />;
              if (tab === OBJECT_SUBJECT_RELATIONSHIPS_NAME) {
                return (
                  <WasteTransporters
                    {...arrayProps}
                    key={randId}
                    placingObjectField={placingObjectField}
                    setCreateSubjectField={setCreateSubjectField}
                  />
                );
              }
              if (tab === WASTE_PLACEMENT_MUNICIPALITIES_NAME) return <WastePlacementMunicipalities key={randId} {...arrayProps} />;

              const wasteManagementTypes = form1Object?.enterprise_activity_types || [];

              return (
                <Box key={randId} sx={flexGap2}>
                  <AsyncAutocomplete
                    multiple
                    disabled
                    debounceTime={0}
                    request={(params) => getReferencesRequest({
                      url: ENTERPRISE_ACTIVITY_TYPES_ENUM,
                      params,
                      filter: (options) => {
                        const filteredOptions = options
                          .filter((activityType) => subjectAttributes?.enterprise_activity_type_ids?.includes?.(Number(activityType.id)));
                        return formatEnumToSelectOption(filteredOptions);
                      },
                    })}
                    optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                    label="Назначение объекта"
                    value={wasteManagementTypes}
                  />
                  <AsyncAutocomplete
                    canBeZero
                    validImmediately={!compareId(placingObject.object_state?.label, 0)}
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    request={(params) => getObjectiveStateEnumsRequest('object_state', params)}
                    optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                    label="Состояние объекта"
                    onChange={(e, value) => changeObject('object_state', value)}
                    value={placingObject.object_state}
                  />
                  <Box>
                    <TextMain>Наименование и реквизиты документа, подтверждающего состояние объекта</TextMain>
                    <CustomInput
                      validImmediately
                      debounceTime={0}
                      disabled={isDemo}
                      sx={fullWidth}
                      value={placingObject.document_name}
                      onChange={(e, value) => changeObject('document_name', value)}
                    />
                  </Box>
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    label="Год ввода в эксплуатацию"
                    value={placingObject.commissioning_year}
                    onChange={(e, value) => changeObject('commissioning_year', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    label="Год окончания эксплуатации"
                    value={placingObject.operating_year_end}
                    onChange={(e, value) => changeObject('operating_year_end', value)}
                  />
                  <CustomCheckbox
                    debounceTime={0}
                    disabled={isDemo}
                    value={placingObject.noReclamation}
                    label="Рекультивация отсутствует"
                    onChange={(e, value) => changeObject('noReclamation', value)}
                  />
                  {!placingObject.noReclamation && (
                    <>
                      <CustomDatePicker
                        canBeZero
                        validImmediately
                        disabled={isDemo}
                        sx={fullWidth}
                        label="Дата проведения рекультивации"
                        value={placingObject.reclamation_date}
                        onChange={(e, value) => changeObject('reclamation_date', value)}
                      />
                      <CustomInput
                        canBeZero
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Вид рекультивации"
                        value={placingObject.reclamation_type}
                        onChange={(e, value) => changeObject('reclamation_type', value)}
                      />
                    </>
                  )}
                  <CustomAccordion title="Ближайший населенный пункт">
                    <Box sx={flexGap2}>
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Наименование ближайшего населенного пункта"
                        value={placingObject[NEAREST_TOWN_NAME].name}
                        sx={fullWidth}
                        onChange={(e, value) => changeNearestTown('name', value)}
                      />
                      <AsyncAutocomplete
                        canBeZero
                        debounceTime={0}
                        validImmediately={!compareId(placingObject[NEAREST_TOWN_NAME].direction?.label, 0)}
                        disabled={isDemo}
                        sx={fullWidth}
                        request={getNearestTownDirectionEnumsRequest}
                        onChange={(e, value) => changeNearestTown('direction', value)}
                        optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                        label="Направление"
                        value={placingObject[NEAREST_TOWN_NAME].direction}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        type="number"
                        label="Расстояние, км"
                        sx={fitContent}
                        value={placingObject[NEAREST_TOWN_NAME].distance}
                        onChange={(e, value) => changeNearestTown('distance', value)}
                      />
                    </Box>
                  </CustomAccordion>
                  <CustomAccordion title="Ближайший водный объект">
                    <Box sx={flexRowGap2}>
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        sx={fullWidth}
                        label="Наименование ближайшего водного объекта"
                        value={placingObject[NEAREST_WATER_OBJECT_NAME].name}
                        onChange={(e, value) => changeNearestWaterObject('name', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        type="number"
                        label="Расстояние, км"
                        sx={fitContent}
                        value={placingObject[NEAREST_WATER_OBJECT_NAME].distance}
                        onChange={(e, value) => changeNearestWaterObject('distance', value)}
                      />
                    </Box>
                  </CustomAccordion>
                  <CustomAccordion title="Проектная документация">
                    <Box sx={flexGap2}>
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Наименование документа"
                        value={placingObject[PROJECT_DOCUMENT_NAME].name}
                        onChange={(e, value) => changeProjectDocument('name', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Наименование разработчика"
                        value={placingObject[PROJECT_DOCUMENT_NAME].developer_name}
                        onChange={(e, value) => changeProjectDocument('developer_name', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Год"
                        value={placingObject[PROJECT_DOCUMENT_NAME].develope_year}
                        onChange={(e, value) => changeProjectDocument('develope_year', value)}
                      />
                      <TextTitle>
                        Положительное заключение государственной экологической
                        эспертизы (ГЭЭ) на проектную документацию
                      </TextTitle>
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Положительное заключение"
                        value={placingObject[PROJECT_DOCUMENT_NAME].positive_result_gee}
                        onChange={(e, value) => changeProjectDocument('positive_result_gee', value)}
                      />
                      <CustomDatePicker
                        canBeZero
                        validImmediately
                        disabled={isDemo}
                        label="Дата"
                        sx={fullWidth}
                        value={placingObject[PROJECT_DOCUMENT_NAME].issue_date}
                        onChange={(e, value) => changeProjectDocument('issue_date', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Номер"
                        value={placingObject[PROJECT_DOCUMENT_NAME].document_number}
                        onChange={(e, value) => changeProjectDocument('document_number', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Наименование органа утвердившего заключение ГЭЭ"
                        value={placingObject[PROJECT_DOCUMENT_NAME].organisation_approved_gee}
                        onChange={(e, value) => changeProjectDocument('organisation_approved_gee', value)}
                      />
                      <CustomDatePicker
                        canBeZero
                        validImmediately
                        disabled={isDemo}
                        label="Дата выдачи заключения ГЭЭ"
                        sx={fullWidth}
                        value={placingObject[PROJECT_DOCUMENT_NAME].issue_date_gee}
                        onChange={(e, value) => changeProjectDocument('issue_date_gee', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Номер заключения ГЭЭ"
                        value={placingObject[PROJECT_DOCUMENT_NAME].result_number_gee}
                        onChange={(e, value) => changeProjectDocument('result_number_gee', value)}
                      />
                    </Box>
                  </CustomAccordion>
                  <Box sx={flexRowGap2}>
                    <CustomInput
                      debounceTime={0}
                      validImmediately
                      disabled={isDemo}
                      type="number"
                      label="Площадь объекта"
                      value={placingObject.object_area}
                      onChange={(e, value) => changeObject('object_area', value)}
                    />
                    <CustomInput
                      debounceTime={0}
                      validImmediately
                      disabled={isDemo}
                      type="number"
                      label="Размер С33, м"
                      value={placingObject.szz_size}
                      onChange={(e, value) => changeObject('szz_size', value)}
                    />
                  </Box>
                  <CustomInput
                    debounceTime={0}
                    validImmediately
                    disabled={isDemo}
                    type="number"
                    label="Вместимость объекта, т"
                    value={placingObject.object_capacity}
                    onChange={(e, value) => changeObject('object_capacity', value)}
                  />
                  <Box sx={flexGap2}>
                    <TextTitle>Мощность, т/год</TextTitle>
                    <Box sx={flexRowGap2}>
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        type="number"
                        label="Проектная"
                        value={placingObject.object_design_power}
                        onChange={(e, value) => changeObject('object_design_power', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        type="number"
                        label="Фактическая"
                        value={placingObject.object_actual_power}
                        onChange={(e, value) => changeObject('object_actual_power', value)}
                      />
                    </Box>
                  </Box>
                  <CustomInput
                    debounceTime={0}
                    validImmediately
                    disabled={isDemo}
                    type="number"
                    label="Размещено всего, т"
                    value={placingObject.placed_total}
                    onChange={(e, value) => changeObject('placed_total', value)}
                  />
                  <CustomInput
                    debounceTime={0}
                    validImmediately
                    disabled={isDemo}
                    label="Виды территорий, для которых введены ограничения по размещению отходов"
                    value={placingObject[RESTRICTED_AREA_TYPE_NAME].name}
                    onChange={(e, value) => changeObject(`${RESTRICTED_AREA_TYPE_NAME}.name`, value, undefined, { restrictedAreaTypeIndex: RESTRICTED_AREA_TYPE_NAME })}
                  />
                  <CustomInput
                    debounceTime={0}
                    validImmediately
                    disabled={isDemo}
                    label="Виды систем защиты окружающей среды на объекте"
                    value={placingObject[ENVIRONMENTAL_SYSTEM_TYPE].name}
                    onChange={(e, value) => changeObject(`${ENVIRONMENTAL_SYSTEM_TYPE}.name`, value, undefined, { environmentalSystemTypeIndex: ENVIRONMENTAL_SYSTEM_TYPE })}
                  />
                  <Box sx={flexGap2}>
                    <TextTitle>Регистрация ГРОРО</TextTitle>
                    <Box sx={flexRowGap2}>
                      <CustomDatePicker
                        canBeZero
                        validImmediately
                        disabled={isDemo}
                        label="Дата"
                        value={placingObject.registration_date_groro}
                        onChange={(e, value) => changeObject('registration_date_groro', value)}
                      />
                      <CustomInput
                        debounceTime={0}
                        validImmediately
                        disabled={isDemo}
                        label="Номер"
                        value={placingObject.registration_number_groro}
                        onChange={(e, value) => changeObject('registration_number_groro', value)}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default memo(ModalCadastrForm8);
