import TextField from '@mui/material/TextField';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import * as React from 'react';
import { memo, useMemo, useState } from 'react';
import { BACKGROUND_LIGHT } from '../constants/colors';
import { compareId } from '../helpers';
import { getError } from '../helpers/formValidator';
import Selector from './Selectors/Selector';

const inputSx = {
  '.MuiInputBase-root.MuiInputBase-formControl': {
    borderRadius: '8px',
    background: BACKGROUND_LIGHT,
  },
  '&.error': {
    fieldset: {
      border: ({ palette }) => `1px solid ${palette.error.main}`,
    },
  },
  fieldset: {
    border: 'none',
  },
};

const YEAR_ITEMS_COUNT = 35;

const getCurrentDate = () => new Date();

const getCurrentYear = () => getCurrentDate().getFullYear();

function CustomDatePicker({
  required,
  disabled,
  className = '',
  value,
  onChange,
  name,
  label,
  yearFrom = getCurrentYear(),
  yearTo = getCurrentYear() + YEAR_ITEMS_COUNT,
  placeholder = 'ДД.ММ.ГГГГ',
  minDate,
  maxDate,
  error,
  validImmediately = false,
  canBeZero = false,
  isDateTime = false,
  isYearPicker = false,
  sx
}) {
  const Component = useMemo(() => {
    if (isDateTime) return DateTimePicker;
    return DatePicker;
  }, [isDateTime]);

  const years = [...new Array(yearTo - yearFrom + 1).keys()].map((item, index) => ({
    label: yearFrom + index,
    value: yearFrom + index,
  }));

  const [invalidDate, setInvalidDate] = useState();
  const errorText = invalidDate || error || getError({ value, validImmediately });
  const errorClass = (!disabled && errorText) ? 'error' : '';
  
  

  const changeValue = (newValue, keyValue) => {
    const isInvalidDate = newValue?.toString() && newValue?.toString() === 'Invalid Date';
    let finalValue = newValue !== null ? newValue.toString() : null;

    const isZeroKeyValue = canBeZero && compareId(keyValue, 0);

    if (isInvalidDate) finalValue = '';

    if (isZeroKeyValue) finalValue = '0';

    if (isZeroKeyValue || keyValue === undefined || keyValue?.length === 10) onChange({ target: { name } }, finalValue);

    setInvalidDate(!isZeroKeyValue && isInvalidDate ? 'Неверный формат даты' : '');
  };

  const changeYear = (e, newValue) => {
    let formattedValue = null;

    if (newValue?.value) {
      const newDate = getCurrentDate();
      newDate.setFullYear(newValue.value);

      formattedValue = newDate;
    }
    onChange(e, formattedValue);
  };

  const yearValue = useMemo(() => {
    if (isYearPicker && value) {
      let currentFullYear = value?.getFullYear?.();

      if (!currentFullYear) {
        if (value?.includes?.(':')) {
          currentFullYear = (new Date(value)).getFullYear();
        } else {
          const newDate = getCurrentDate();
          newDate.setFullYear(value);
          currentFullYear = newDate.getFullYear();
        }
      }

      return { value: currentFullYear, label: currentFullYear };
    }
    return value;
  }, [isYearPicker, value]);

  if (isYearPicker) {
    return (
      <Selector
        validImmediately={validImmediately}
        value={yearValue}
        name={name}
        error={error}
        sx={sx}
        options={years}
        label={label}
        onChange={changeYear}
      />
    );
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
    >
      <Component                 
        label={label}
        minDate={minDate}
        className={`${className} ${errorClass}`}
        maxDate={maxDate}
        toolbarTitle="Выберите дату"
        disabled={disabled}
        value={canBeZero && value === '0' ? '' : value || null}
        onChange={changeValue}
        renderInput={(params) => {
          if (placeholder) params.inputProps.placeholder = placeholder;

          if (canBeZero && value === '0' && (params.inputProps.value === '0' || params.inputProps.value === '')) {
            params.inputProps.value = '0';
          }

          return (
            <TextField
              className={`${className} ${errorClass}`}
              {...params}
              required={required}
              name={name}
              error={Boolean(errorText)}
              helperText={errorText}
              sx={{ ...inputSx, ...sx }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export default memo(CustomDatePicker);
