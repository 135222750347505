import React from 'react';
import LoginPage from '../Public/LoginPage';
import { readToken } from '../../helpers';
import { loginUserRequest } from '../../api/userApi';

export default function AdminTerSchemeLoginPage() {
  return (
    <LoginPage
      title="администратора"
      loginRequest={loginUserRequest}
      redirectUrl={() => {
        const token = readToken();

        if (token) window.location.href = `http://rko-ts.lenreg.ru/operator-ui/?aistoken=${token}#`;
      }}
      forgotPasswordParams="ter-scheme"
    />
  );
}
