// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React, { useCallback } from 'react';
import { getApplicationEnumsRequest } from '../../api/applicationsApi';
import { getWasteTypesRequest } from '../../api/wasteTypeApi';
import { HAZARD_CLASS_ENUM } from '../../constants/enums';
import { getInitialForm3Item } from '../../constants/lkCadastr/cadastrForm3';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../constants/lkCadastr/cadastrStorage';
import { compareId } from '../../helpers';
import { deleteForm3ReportGeneration } from '../../helpers/cadastreForms/form3Helper';
import { formatAddressString, getOktmoLabel, upperCaseFirstLetter } from '../../helpers/stringHelper';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import CustomCheckbox from '../CustomCheckbox';
import CustomInput from '../Inputs';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import Selector from '../Selectors/Selector';
import NavTabs from '../Tabs';
import { TextTitle } from '../Text';
import { CadastreForm3WasteTransfer } from './CadastreForm3WasteTransfer';
import { useCadastreStore } from '../../store';

export default function CadastreForm3Wastes({
  building, onChange, isDemo, buildingIndex, setCreateSubjectField, form3Items, changeItemFormByField, openDeleteModal,
}) {
  const { cadastreStore } = useCadastreStore();
  const mainItems = form3Items?.items || building?.waste_products;

  const { tab, tabs, setTab } = useCadastreTabs({ items: mainItems, tabName: 'Отход' });

  const addNewWaste = () => {
    changeItemFormByField({
      field: 'form3.items',
      value: [...form3Items.items, getInitialForm3Item(true)],
    });
  };

  const getMainItem = useCallback((tabId) => mainItems.find((mainItem) => compareId(mainItem.randId, tabId)) || {}, [mainItems]);

  return (
    <Box sx={flexGap2}>
      {!building && (
        <CustomCheckbox
          label="Указать принимаемые отходы"
          value={form3Items.hasAcceptingWastes}
          onChange={(e, value) => changeItemFormByField({ field: 'form3.hasAcceptingWastes', value })}
        />
      )}
      {(building || form3Items?.hasAcceptingWastes) && (
      <>
        {form3Items?.hasAcceptingWastes && (
          <AddButton variant="outlined" onClick={addNewWaste}>
            Добавить отход
          </AddButton>
        )}
        <NavTabs
          getErrorTab={(tabId) => {
            const mainItem = getMainItem(tabId);

            return cadastreStore.errorForm3?.some?.((item) => item.includes(mainItem.id));
          }}
          tabsComponents={tabs}
          setTab={setTab}
          tab={tab}
        />
        {building && (
        <>
          <CustomInput
            disabled
            debounceTime={0}
            sx={fullWidth}
            label="Адрес"
            value={formatAddressString(building.objective_id?.address_attributes?.attributes)}
          />
          <CustomInput
            disabled
            debounceTime={0}
            sx={fullWidth}
            label="ОКТМО"
            value={getOktmoLabel(building.objective_id?.address_attributes?.attributes?.oktmo_attributes?.attributes)}
          />
        </>
        )}

        {mainItems.map((waste_product, index) => {
          if (waste_product.randId !== tab) return null;

          const mainField = building ? `items.${buildingIndex}.waste_products.${index}.form3Waste.` : `items.${index}.`;

          const changeItem = (field, value, props) => {
            onChange({
              field: `${mainField}${field}`,
              value,
              buildingForm3Index: buildingIndex,
              reportGenerationIndex: index,
              ...props,
            });
          };

          const form3Waste = waste_product?.form3Waste || waste_product;

          const onDeleteWaste = async () => {
            const cloneArray = form3Items.items.slice();

            if (waste_product.id) {
              const deletedId = await deleteForm3ReportGeneration({ wasteId: waste_product.id });

              if (!deletedId) return;
            }

            cloneArray.splice(index, 1);

            changeItemFormByField({
              field: 'form3.items',
              value: cloneArray,
              type: 'delete',
            });
          };

          return (
            <Box key={waste_product.randId} sx={flexGap2}>
              <Box sx={flexRowGap2}>
                <TextTitle h2>
                  Отход №
                  {index + 1}
                </TextTitle>
                {!isDemo && form3Items?.hasAcceptingWastes && form3Items.items.length > 0 && (
                  <DeleteButton onClick={() => openDeleteModal(onDeleteWaste, index, 'Отход')}>
                    Удалить Отход
                  </DeleteButton>
                )}
              </Box>
              <AsyncAutocomplete
                disabled={building}
                validImmediately={!building}
                sx={fullWidth}
                request={getWasteTypesRequest}
                value={waste_product?.waste_type_id?.id ? waste_product?.waste_type_id : waste_product.code}
                optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                onChange={(e, value) => changeItem('waste_type_id', value)}
                label="Код отхода по ФККО"
              />
              {building && (
                <CustomInput
                  disabled
                  validImmediately={!building}
                  sx={fullWidth}
                  label="Класс опасности отходов"
                  onChange={(e, value) => changeItem('waste_type_id', value)}
                  value={upperCaseFirstLetter(waste_product?.waste_type_id?.hazard_class_text || waste_product.code?.hazard_class_text)}
                />
              )}
              {!building && (
              <>
                {!waste_product.waste_type_id?.hazard_class
                && <Alert severity="error">Необходимо указать класс опасности в поле ниже</Alert>}
                <Selector
                  validImmediately
                  disabled={isDemo || !waste_product.waste_type_id?.id || (waste_product.waste_type_id?.hazard_class && !compareId(waste_product?.waste_type_id?.code?.at(-1), 0))}
                  sx={fullWidth}
                  request={(params) => getApplicationEnumsRequest(HAZARD_CLASS_ENUM, params)}
                  value={{
                    label: waste_product.waste_type_id?.hazard_class_text,
                    value: waste_product.waste_type_id?.hazard_class,
                    id: waste_product.waste_type_id?.hazard_class,
                  }}
                  optionField={DEFAULT_AUTOCOMPLETE_FIELD}
                  label="Класс опасности"
                  onChange={(e, value) => changeItem('waste_type_id', {
                    ...waste_product.waste_type_id,
                    hazard_class: value.id,
                    hazard_class_text: value.label,
                  })}
                />
              </>
              )}
              <Box sx={flexGap2}>
                {building && (
                  <CustomInput
                    disabled
                    sx={fullWidth}
                    type="number"
                    label="Образование отходов за отчётный период"
                    value={waste_product.quantity}
                  />
                )}
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  sx={fullWidth}
                  type="number"
                  label="Наличие отходов на начало отчётного периода"
                  value={form3Waste.availability_waste_period}
                  onChange={(e, value) => changeItem('availability_waste_period', value)}
                />
                <Box sx={flexRowGap2}>
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    label="Обработка отходов на собственных объектах"
                    type="number"
                    value={form3Waste.get_processing}
                    onChange={(e, value) => changeItem('get_processing', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fullWidth}
                    type="number"
                    label="Утилизация отходов на собственных объектах"
                    value={form3Waste.get_utilization}
                    onChange={(e, value) => changeItem('get_utilization', value)}
                  />
                  <CustomInput
                    validImmediately
                    disabled={isDemo}
                    sx={fullWidth}
                    type="number"
                    label="Обезвреживание отходов на собственных объектах"
                    value={form3Waste.get_neutralization}
                    onChange={(e, value) => changeItem('get_neutralization', value)}
                  />
                </Box>
                <Box sx={{ ...flexGap2, display: building ? 'none' : 'flex' }}>
                  <TextTitle h3>
                    Поступление отходов от других организаций
                  </TextTitle>
                  <Box sx={flexRowGap2}>
                    <CustomInput
                      validImmediately
                      debounceTime={0}
                      disabled={isDemo}
                      sx={fitContent}
                      label="Всего"
                      type="number"
                      value={form3Waste.get_all}
                      onChange={(e, value) => changeItem('get_all', value)}
                    />
                    <CustomInput
                      validImmediately
                      debounceTime={0}
                      disabled={isDemo}
                      sx={fitContent}
                      type="number"
                      label="в т.ч. по импорту"
                      value={form3Waste.get_import}
                      onChange={(e, value) => changeItem('get_import', value)}
                    />
                  </Box>
                </Box>
                <CadastreForm3WasteTransfer
                  isDemo={isDemo}
                  changeItem={changeItem}
                  buildingIndex={buildingIndex}
                  index={index}
                  openDeleteModal={openDeleteModal}
                  setCreateSubjectField={setCreateSubjectField}
                  items={form3Waste?.waste_transfers}
                  field={mainField}
                  f3waste={form3Waste}
                  wastequant={waste_product.quantity}
                  isBuilding={building != null}
                />
              </Box>
            </Box>
          );
        })}

      </>
      )}
    </Box>
  );
}
