import React, { useEffect, useState } from 'react';
import ModalCard from '../ModalCard';
import UsersInfo from './components/UsersInfo';
import { useApi } from '../../../hooks';
import { getUsersRequest } from '../../../api/userApi';
import {
  addPrivilegeRoleRequest,
  createRoleRequest,
  deleteRoleRequest, getRolePrivilegesRequest, removeRolePrivilegeRequest,
  updateRoleRequest,
} from '../../../api/rolesApi';
import { compareId, formValidator } from '../../../helpers';
import { useModalStore } from '../../../store';
import { getPrivilegesRequest } from '../../../api/privilegeApi';
import PrivilegeCheckboxes from '../../PrivilegeCheckboxes';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../../helpers/formValidator';

const roleFrame = [
  {
    id: 1,
    title: 'Наименование',
    inputFields: [
      { id: 'name', label: 'Наименование' },
      { id: 'code', label: 'Код' },
    ],
  },
];

const initialRoleData = {
  name: '',
  code: '',
  privilege: {},
};

export default function ModalRole({
  closeModal, onClick, roleData, updateRoles, isCreateRoleModal,
}) {
  const { modalStore, setModalStore } = useModalStore();
  const [role, setRole] = useState(initialRoleData);
  const [initialPrivileges, setInitialPrivileges] = useState(null);
  const [currentRoleUsers, setCurrentRoleUsers] = useState([]);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [privileges, setPrivileges] = useState(null);

  useApi({
    setter: (rolePrivileges) => {
      const newPrivilegeData = {};
      privileges.forEach((privilege) => {
        const currentPrivilege = rolePrivileges
          ?.find((
            rolePrivilege,
          ) => rolePrivilege.privilege_attributes?.attributes?.code === privilege.code);

        newPrivilegeData[privilege.code] = {
          value: Boolean(currentPrivilege),
          ...currentPrivilege,
          ...privilege,
          privilege_roles_id: currentPrivilege?.id,
        };
      });
      setRole((prevState) => {
        setInitialPrivileges({ ...newPrivilegeData });

        return {
          ...prevState,
          privilege: { ...newPrivilegeData },
        };
      });
    },
    setIsLoading,
    shouldRequest: privileges?.length,
    request: () => getRolePrivilegesRequest(role.id, { total: 10000 }),
  });

  useApi({
    setter: setPrivileges,
    setIsLoading,
    shouldRequest: true,
    request: () => getPrivilegesRequest({ total: 10000 }),
  });

  const handleChange = (field) => (event, value) => {
    setRole((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const getCurrentRoleUser = async () => {
    const newUsers = await getUsersRequest({ role_id: role.id });

    setCurrentRoleUsers(newUsers.filter(({ role_id }) => compareId(role_id, role.id)));
  };

  const { makeRequest: onGetRoleUser } = useApi({
    request: getCurrentRoleUser,
  });

  const changePrivilegeCheckbox = (e, changedPrivilegeCheckbox) => {
    const newPrivilege = {
      ...role.privilege,
      [e.target.name]: {
        ...role.privilege[e.target.name],
        value: changedPrivilegeCheckbox,
      },
    };

    handleChange('privilege')(e, newPrivilege);
  };

  const createUpdateRole = async (requestType) => {
    const { name, code, id } = role;

    const newRole = { name, code };

    const { hasErrors, validField } = formValidator({
      form: { name },
    });

    setError(validField);

    if (hasErrors) {
      throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };
    }

    const addedPrivileges = [];
    const removedPrivileges = [];

    Object.values(role.privilege).forEach((privilege) => {
      const initialValues = requestType === 'update' ? Object.values(initialPrivileges) : privileges;

      const changedPrivilege = initialValues
        .find((initialPrivilege) => initialPrivilege.id === privilege.id);

      if (changedPrivilege.value !== privilege.value) {
        const privilegeId = privilege.id;

        if (privilege.value) addedPrivileges.push(privilegeId);
        else removedPrivileges.push(privilege.privilege_roles_id);
      }
    });

    let role_id = id;

    if (requestType === 'update') {
      await updateRoleRequest(id, newRole);
    } else if (requestType === 'create') {
      const createdRole = await createRoleRequest(newRole);
      role_id = createdRole?.id;
    }

    if (addedPrivileges.length) {
      await addPrivilegeRoleRequest(
        addedPrivileges.map((privilegeId) => ({ privilege_id: privilegeId, role_id })),
      );
    }
    if (removedPrivileges.length) await removeRolePrivilegeRequest(removedPrivileges);

    await updateRoles();
    closeModal();
  };

  const { makeRequest: onCreateUpdateRole } = useApi({
    request: createUpdateRole,
    successMessage: `Роль ${isCreateRoleModal ? 'создана' : 'обновлена'}`,
  });

  const onDeleteRole = () => {
    setModalStore({
      ...modalStore,
      deleteModal: {
        isOpen: true,
        callback: async () => {
          await updateRoles();
          closeModal();
        },
        deleteRequest: deleteRoleRequest,
        id: role.id,
        objectName: 'роль',
        message: 'Роль удалена',
      },
    });
  };

  useEffect(() => {
    if (roleData?.id) {
      setRole((prevState) => ({
        ...prevState,
        ...roleData,
      }));
    }
  }, [roleData]);

  useEffect(() => {
    if (role.id) onGetRoleUser();
    /* eslint-disable-next-line */
  }, [role?.id]);

  return (
    <ModalCard
      inputs={roleFrame}
      open
      close={closeModal}
      isLoading={isLoading}
      handleChange={handleChange}
      containerMaxWidth="lg"
      inputData={role}
      error={error}
      firstButtonProp={!isCreateRoleModal && {
        label: 'Удалить роль',
        onClick: onDeleteRole,
      }}
      secondButtonProp={{
        label: 'Сохранить',
        onClick: () => onCreateUpdateRole(isCreateRoleModal ? 'create' : 'update'),
      }}
      additionalComponents={(
        <UsersInfo users={currentRoleUsers} openModal={onClick} />
      )}
      additionalContainer={(
        Boolean(Object.keys(role.privilege).length) && (
          <PrivilegeCheckboxes
            privilegeData={role.privilege}
            setRole={setRole}
            onChange={changePrivilegeCheckbox}
          />
        )
      )}
    />
  );
}
