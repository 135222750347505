// @ts-nocheck
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { METHODS_API } from '../../api/api';
import { cadastreReportHistoryRequest } from '../../api/cadastreApi';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { REPORT_STATUS_NAMES } from '../../constants/lkCadastr/cadastrStorage';
import { USER_TEST_ADMIN } from '../../constants/testAdminContants';
import { compareId } from '../../helpers';
import { useApi, usePushNotification } from '../../hooks';
import { useUserStore } from '../../store';
import CustomButton from '../Buttons/CustomButton';
import CustomDatePicker from '../CustomDatePicker';
import SearchInput from '../Inputs/SearchInput';
import ModalSignatureMail from '../Modals/Cadastr/ModalSignatureMail';
import ModalCadastrForms from '../Modals/ModalCadastrForms/ModalCadastrForms';
import Selector from '../Selectors/Selector';
import CustomTable from '../Tables/CustomTable';
import { TextTitle } from '../Text';

const sortOptions = [
  { value: 'bySendStatus', label: 'Сначала статус отправлен' },
  { value: 'byNumber', label: 'По номеру' },
];

export default function CadastrAdminTable({
  tableColumns,
  title,
  filter,
  changeFilter,
  tableData,
  getReports,
  setReports,
  reportStatuses,
  clearFilter,
  isLoading,
}) {
  const { userStore } = useUserStore();
  const pushNotification = usePushNotification();
  const [report, setReport] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [modalSignature, setModalSignature] = useState({});
  const [sort, setSort] = useState(sortOptions[0]);
  const [isOpenedScanModal, setIsOpenedScanModal] = useState(false);

  const openScanModal = () => setIsOpenedScanModal(true);

  const closeScanModal = () => {
    setModalSignature({});
    setIsOpenedScanModal(false);
  };

  const closeCreate = () => {
    getReports();

    setReport({});
  };

  const { defaultRequest: reportHistoryApi, isReportHistoryLoading } = useApi({
    request: cadastreReportHistoryRequest,
  });

  const findStatusIdByCode = (code) => reportStatuses.find((item) => item.code === code)?.id;

  const searchReportByCode = async (code) => {
    changeFilter('search')(code, true);

    const reportsByCode = await reportHistoryApi({ params: { code } });

    const reportByCode = reportsByCode?.find((foundReport) => compareId(foundReport?.code, code));

    if (reportByCode?.code) {
      pushNotification({ message: 'Отчёт найдён', variant: 'warning' });

      const newReport = await reportHistoryApi({
        method: METHODS_API.PUT,
        data: {
          report_status_id: findStatusIdByCode(REPORT_STATUS_NAMES.paper_document_accepted),
        },
        id: reportByCode.id,
      });

      setReports([newReport]);

      closeScanModal();

      pushNotification({ message: 'Бумажный документ принят', variant: 'success' });
    } else {
      pushNotification({ message: 'Отчёт не найдён' });
    }
  };

  const openReport = async (tableReport) => {
    let openedReport = tableReport;

    if (tableReport?.report_status_attributes?.attributes?.name === 'Отправлен') {
      const checkingStatusId = findStatusIdByCode(REPORT_STATUS_NAMES.checking);

      openedReport = await reportHistoryApi({
        method: METHODS_API.PUT,
        id: tableReport.id,
        data: { report_status_id: checkingStatusId },
      });
    }

    setReport(openedReport);
  };

  const saveAllComments = async ({ id, comments }) => {
    await reportHistoryApi({
      method: METHODS_API.PUT,
      id,
      data: {
        comments,
        report_status_id: findStatusIdByCode(REPORT_STATUS_NAMES.has_warnings),
      },
    });
  };

  const openModalSignature = (tableReport) => {
    setModalSignature(tableReport);
  };

  const additionalControls = useMemo(() => {
    const showEditRow = filteredData.some((row) => {
      const reportStatusCode = row?.report_status_attributes?.attributes?.code;

      return ([
        REPORT_STATUS_NAMES.paper_document_printed,
        REPORT_STATUS_NAMES.paper_document_accepted,
      ].includes(reportStatusCode) && (row?.transmittal_mail_signed_url || row?.transmittal_mail_url));
    });

    if (!showEditRow) return null;

    return [
      {
        render: (tableReport) => {
          const reportStatusCode = tableReport?.report_status_attributes?.attributes?.code;

          if (![
            REPORT_STATUS_NAMES.checked,
            REPORT_STATUS_NAMES.paper_document_printed,
            REPORT_STATUS_NAMES.paper_document_accepted,
          ].includes(reportStatusCode) || (!tableReport?.transmittal_mail_signed_url && !tableReport?.transmittal_mail_url)) return null;

          return (
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
                openModalSignature(tableReport);
              }}
            >
              Сопроводительное письмо
            </CustomButton>
          );
        },
        id: Math.random(),
      },
    ];
  }, [filteredData]);

  const changeSort = (e, newSort) => {
    setSort(newSort);
  };

  const isAdminEditor = userStore?.email === USER_TEST_ADMIN;

  useEffect(() => {
    const newTableData = tableData.slice();

    if (sort.value === 'byNumber') newTableData.sort((a, b) => Number(a.id) - Number(b.id));
    else if (sort.value === 'bySendStatus') {
      newTableData.sort((a, b) => {
        if (a.report_status_id === b.report_status_id) {
          return new Date(a.application_date) - new Date(b.application_date);
        }

        return a.report_status_id - b.report_status_id;
      });
    }

    setFilteredData(newTableData);
  }, [sort, tableData]);

  return (
    <Box position="relative" background={BACKGROUND_LIGHT} sx={{ width: '100%' }}>
      {(Boolean(modalSignature.created_at) || isOpenedScanModal) && (
        <ModalSignatureMail
          searchReportByCode={searchReportByCode}
          report={modalSignature}
          isModalScan={isOpenedScanModal}
          close={closeScanModal}
        />
      )}
      {report?.id && (
        <ModalCadastrForms
          isDemo={!isAdminEditor}
          isAdminEditor={isAdminEditor}
          isReview={!isAdminEditor}
          isLoading={isReportHistoryLoading || isLoading}
          report={report}
          saveAllComments={saveAllComments}
          close={closeCreate}
        />
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={3} mb={3}>
        <TextTitle h2>{title}</TextTitle>
      </Box>
      <Box
        overflow="auto"
        gap={3}
        p={1}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" flexWrap="wrap" gap={3}>
          <CustomButton onClick={openScanModal}>Сканировать документ</CustomButton>
          <Selector
            label="Сортировка"
            options={sortOptions}
            value={sort}
            onChange={changeSort}
          />
          <SearchInput
            sx={{ width: 320, background: '#EDF2F8' }}
            label="Поиск"
            onKeyEnter={(e, value) => changeFilter('search')(value)}
            value={filter.search}
            onChange={(e, value) => changeFilter('search')(value)}
          />
          <Selector
            value={filter.report_status_id}
            label="Статус"
            optionField={{ value: 'code', id: 'code', label: 'name' }}
            onChange={(e, value) => changeFilter('report_status_id')(value)}
            options={reportStatuses}
          />
          <CustomDatePicker
            label="Начало периода"
            value={filter.period_date_from}
            onChange={(e, value) => changeFilter('period_date_from')(value)}
          />
          <CustomDatePicker
            label="Окончание периода"
            value={filter.period_date_to}
            onChange={(e, value) => changeFilter('period_date_to')(value)}
          />
          <Box display="flex" gap={3}>
            <CustomDatePicker
              label="Дата подачи с"
              value={filter.send_date_from}
              onChange={(e, value) => changeFilter('send_date_from')(value)}
            />
            <CustomDatePicker
              label="Дата подачи по"
              value={filter.send_date_to}
              onChange={(e, value) => changeFilter('send_date_to')(value)}
            />
            <CustomDatePicker
              label="Дата подтверждения с"
              value={filter.confirmed_date_from}
              onChange={(e, value) => changeFilter('confirmed_date_from')(value)}
            />
            <CustomDatePicker
              label="Дата подтверждения по"
              value={filter.confirmed_date_to}
              onChange={(e, value) => changeFilter('confirmed_date_to')(value)}
            />
            <CustomDatePicker
              label="Дата просрочки с"
              value={filter.delay_date_from}
              onChange={(e, value) => changeFilter('delay_date_from')(value)}
            />
            <CustomDatePicker
              label="Дата просрочки по"
              value={filter.delay_date_to}
              onChange={(e, value) => changeFilter('delay_date_to')(value)}
            />
          </Box>
          {Object.values(filter).some((item) => Boolean(item)) && (
            <CustomButton sx={{ height: '46px' }} onClick={clearFilter}>Очистить фильтр</CustomButton>
          )}
        </Box>
        <CustomTable
          showRowsNumber
          isLoading={isLoading}
          onClick={openReport}
          additionalControls={additionalControls}
          data={filteredData}
          columns={tableColumns}
          containerSx={{ maxHeight: '400px' }}
        />
      </Box>
    </Box>
  );
}
