// @ts-nocheck
import { Alert, Box } from '@mui/material';
import React from 'react';
import { getSubjectsRequest } from '../../api/subjectApi';
import { FORM6_TRANSPORTED_WASTE } from '../../constants/lkCadastr/cadastrForm6';
import { flexGap2, fullWidth } from '../../constants/lkCadastr/cadastrStorage';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import DeleteButton from '../Buttons/DeleteButton';
import CustomDatePicker from '../CustomDatePicker';
import CustomInput from '../Inputs';
import { Form6TransportedWaste } from '../Modals/ModalCadastrForms/components/Form6/Form6TransportedWaste';
import NavTabs from '../Tabs';
import { TextTitle } from '../Text';
import { compareId } from '../../helpers';
import { useCadastreStore } from '../../store';

export default function CadastreForm6ActualVolume({
  actualVolume,
  actualVolumes,
  onChange,
  isDemo,
  changeItemFormByField,
  setCreateSubjectInfo,
  openDeleteModal,
  actualVolumeIndex,
  deleteForm6Entity,
}) {
  const transportedWastes = actualVolume[FORM6_TRANSPORTED_WASTE];

  const { tabs, tab, setTab } = useCadastreTabs({ items: transportedWastes, tabName: 'Транспортируемый отход' });
  const { cadastreStore } = useCadastreStore();

  const changeItem = (field, value, transportedWasteIndex) => {
    onChange({
      field: `actualVolumes.${actualVolumeIndex}.${field}`,
      value,
      reportActualVolumeIndex: actualVolumeIndex,
      transportedWasteIndex,
    });
  };

  const onDeleteActualVolume = async () => {
    if (actualVolume?.id) {
      const deletedActualVolume = await deleteForm6Entity({ actualVolumeId: actualVolume.id });

      if (!deletedActualVolume) return;
    }

    const cloneArray = actualVolumes.slice();
    cloneArray.splice(actualVolumeIndex, 1);

    changeItemFormByField({
      field: 'form6.actualVolumes',
      value: cloneArray,
      type: 'delete',
    });
  };

  const isDisabledTransferSubject = compareId(actualVolume.transfer_subject_id?.name, 0);
  const isDisabledAcceptingSubject = compareId(actualVolume.accept_subject_id?.name, 0);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={3}>
        <TextTitle h2>
          Фактический объём №
          {actualVolumeIndex + 1}
        </TextTitle>
        {!isDemo && actualVolumes.length > 1 && (
          <DeleteButton
            onClick={() => openDeleteModal(onDeleteActualVolume, actualVolumeIndex, 'Фактический объём')}
          >
            Удалить Фактический объём
          </DeleteButton>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box sx={flexGap2}>
          <Alert severity="info">
            Для поиска организации в справочнике введите наименование или ИНН в графу «Юридическое лицо (индивидуальный предприниматель), передавшее для транспортирования отходы»,  в случае отсутствия нажмите кнопку «+Создать субъект» и добавьте организацию в справочник.
          </Alert>
          <TextTitle h3>
            Юридическое лицо (индивидуальный предприниматель),
            передавшее для транспортирования отходы
          </TextTitle>
          <AsyncAutocomplete
            canBeZero
            validImmediately={!isDisabledTransferSubject}
            debounceTime={0}
            disabled={isDemo}
            name="transfer_subject_id"
            sx={fullWidth}
            label="Наименование (ФИО)"
            onChange={(e, value) => { changeItem('transfer_subject_id', value); actualVolume.importfromxlsx = false; }}
            noOptionComponent={(
              <AddButton
                variant="outlined"
                onClick={() => setCreateSubjectInfo({ index: actualVolumeIndex, fieldName: 'transfer_subject_id' })}
              >
                Создать субъект
              </AddButton>
            )}
            request={(params) => getSubjectsRequest({ params })}
            value={actualVolume.transfer_subject_id}
          />
          <CustomInput
            disabled
            label="ИНН"
            value={actualVolume.transfer_subject_id?.inn}
          />
          <Box display="flex" alignItems="center" flexWrap="flex" gap={5}>
            <CustomInput
              validImmediately={!isDisabledTransferSubject}
              debounceTime={0}
              disabled={isDemo || isDisabledTransferSubject}
              label="Номер документа"
              value={actualVolume.transfer_document_number}
              onChange={(e, value) => changeItem('transfer_document_number', value)}
            />
            <CustomDatePicker
              validImmediately={!isDisabledTransferSubject}
              disabled={isDemo || isDisabledTransferSubject}
              label="Дата"
              value={actualVolume.transfer_document_date}
              onChange={(e, value) => changeItem('transfer_document_date', value)}
            />
          </Box>
        </Box>
        <NavTabs getErrorTab={(tabId) => cadastreStore.errorForm6?.some((item) => item.includes(tabId))} tabsComponents={tabs} setTab={setTab} tab={tab} />
        <Form6TransportedWaste
          isDemo={isDemo}
          tab={tab}
          deleteForm6Entity={deleteForm6Entity}
          actualVolume={actualVolume}
          openDeleteModal={openDeleteModal}
          changeItemFormByField={(value, type) => changeItemFormByField({
            field: `form6.actualVolumes.${actualVolumeIndex}.${FORM6_TRANSPORTED_WASTE}`,
            value,
            type,
          })}
          onChange={changeItem}
          transportedWastes={transportedWastes}
        />
        <Box sx={flexGap2}>
          <Alert severity="info">
            Для поиска организации в справочнике введите наименование или ИНН в графу «Юридическое лицо (индивидуальный предприниматель), принявшее транспортируемые отходы»,  в случае отсутствия нажмите кнопку «+Создать субъект» и добавьте организацию в справочник.
          </Alert>
          <TextTitle h3>
            Юридическое лицо (индивидуальный предприниматель),
            принявшее транспортируемые отходы
          </TextTitle>
          <AsyncAutocomplete
            canBeZero
            validImmediately={!isDisabledAcceptingSubject}
            debounceTime={0}
            disabled={isDemo}
            name="accept_subject_id"
            sx={fullWidth}
            label="Наименование (ФИО)"
            request={(params) => getSubjectsRequest({ params })}
            noOptionComponent={(
              <AddButton
                variant="outlined"
                onClick={() => setCreateSubjectInfo({ index: actualVolumeIndex, fieldName: 'accept_subject_id' })}
              >
                Создать субъект
              </AddButton>
            )}
            value={actualVolume.accept_subject_id}
            onChange={(e, value) => { changeItem('accept_subject_id', value); actualVolume.importfromxlsx = false; }}
          />
          <CustomInput
            disabled
            label="ИНН"
            value={actualVolume.accept_subject_id?.inn}
          />
          <Box display="flex" alignItems="center" flexWrap="flex" gap={5}>
            <CustomInput
              validImmediately={!isDisabledAcceptingSubject}
              debounceTime={0}
              disabled={isDemo || isDisabledAcceptingSubject}
              label="Номер документа"
              value={actualVolume.accept_document_number}
              onChange={(e, value) => changeItem('accept_document_number', value)}
            />
            <CustomDatePicker
              validImmediately={!isDisabledAcceptingSubject}
              disabled={isDemo || isDisabledAcceptingSubject}
              label="Дата"
              value={actualVolume.accept_document_date}
              onChange={(e, value) => changeItem('accept_document_date', value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
