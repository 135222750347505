import React from 'react';
import ReferenceGenerator from '../../../components/ReferenceGenerator';
import { OKTMOS_URL } from '../../../constants/urls';

export default function OKTMOReferencePage() {
  return (
    <ReferenceGenerator
      url={OKTMOS_URL}
      importKind="oktmos"
      ignoreInputs={['object_boundaries']}
      additionalInfo={{
        object_boundaries: '',
      }}
      additionalInputs={[
        {
          id: 'object_boundaries',
          label: 'Границы объекта в системе координат WGS-84',
        },
      ]}
      additionalColumns={[
        { headerTitle: 'Границы объекта в системе координат WGS-84', field: 'object_boundaries' },
      ]}
    />
  );
}
