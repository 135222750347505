import React from 'react';
import { Box } from '@mui/material';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import AddButton from '../../../Buttons/AddButton';
import { getInitialManufacturedProduct } from '../../../../constants/lkCadastr/cadastrForm7';
import { flexGap2 } from '../../../../constants/lkCadastr/cadastrStorage';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import NavTabs from '../../../Tabs';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';

const MANUFACTURED_PRODUCTS_NAME = 'manufactured_products';

export function ManufacturedProducts({
  manufacturedProducts, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: manufacturedProducts, tabName: 'Производимая продукция' });

  return (
    <Box sx={flexGap2}>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeItem(
            MANUFACTURED_PRODUCTS_NAME,
            [...manufacturedProducts, getInitialManufacturedProduct()],
          )}
        >
          Добавить производимую продукцию
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {manufacturedProducts.map((manufacturedProduct, manufacturedProductIndex) => {
        if (tab !== manufacturedProduct.randId) return null;

        const change = (field, value) => changeItem(
          `${MANUFACTURED_PRODUCTS_NAME}.${manufacturedProductIndex}.${field}`,
          value,
          undefined,
          { manufacturedProductIndex },
        );

        const onDelete = async () => {
          if (manufacturedProduct?.id) {
            const deletedManufacturedProduct = await deleteForm7Entity({
              id: manufacturedProduct.id,
              apiType: MANUFACTURED_PRODUCTS_NAME,
            });

            if (!deletedManufacturedProduct) return;
          }

          const cloneArray = manufacturedProducts.slice();
          cloneArray.splice(manufacturedProductIndex, 1);

          changeItem(
            MANUFACTURED_PRODUCTS_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box key={manufacturedProduct.randId} sx={flexGap2}>
            {!isDemo && manufacturedProducts.length > 1 && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, manufacturedProductIndex, 'Производимая продукция')}
              >
                Удалить производимую продукцию
              </DeleteButton>
            )}
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Код ОКП"
              value={manufacturedProduct.code_okp}
              onChange={(e, value) => change('code_okp', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Наименование продукции"
              value={manufacturedProduct.product_name}
              onChange={(e, value) => change('product_name', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              label="Наличие сертификата"
              value={manufacturedProduct.certificate_available}
              onChange={(e, value) => change('certificate_available', value)}
            />
            <CustomInput
              validImmediately
              debounceTime={0}
              disabled={isDemo}
              type="number"
              label="Количество тонн/год"
              value={manufacturedProduct.tons_per_yer}
              onChange={(e, value) => change('tons_per_yer', value)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
