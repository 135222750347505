import React, { useState } from 'react';
import ModalCard from './ModalCard';
import { formValidator, getObjectValuesByField } from '../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { createReferenceRequest } from '../../api/referenceApi';
import { useApi } from '../../hooks';
import { OKTMOS_URL } from '../../constants/urls';
import { putAddressRequest } from '../../api/addressApi';

const frame = [
  {
    id: 1,
    inputFields: [
      {
        id: 'name', label: 'Название', multiline: true, required: true,
      },
      { id: 'code', label: 'Код', required: true },
      {
        id: 'object_boundaries',
        label: 'Границы объекта в системе координат WGS-84',
      },
    ],
  },
];

const initialInfo = {
  name: '',
  code: '',
  object_boundaries: '',
};

export default function ModalAddOktmo({
  setNewOktmo, setNewAddress, addressId, isOpen, close,
}) {
  const [modalInfo, setModalInfo] = useState(initialInfo);
  const [error, setError] = useState({});

  const changeModalInfo = (field) => (e, value) => {
    setModalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: '',
    }));
  };

  const closeModal = () => {
    close();
    setModalInfo();
  };

  const onCreateEdit = async () => {
    const { hasErrors, validField } = formValidator({
      form: getObjectValuesByField(initialInfo, modalInfo),
      ignoreInputs: ['object_boundaries'],
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const data = { code: modalInfo.code, name: modalInfo.name };

    if (modalInfo.object_boundaries) data.object_boundaries = modalInfo.object_boundaries;

    const newOktmo = await createReferenceRequest({ data, url: OKTMOS_URL });

    closeModal();

    if (setNewOktmo) setNewOktmo(newOktmo);

    if (addressId) {
      const newAddress = await putAddressRequest({ oktmo_id: newOktmo.id }, addressId);

      if (setNewAddress) setNewAddress(newAddress);
    }
  };

  const { defaultRequest } = useApi({
    request: onCreateEdit,
  });

  if (!isOpen) return null;

  return (
    <ModalCard
      open
      containerSx={{ width: '414px' }}
      inputData={modalInfo}
      handleChange={changeModalInfo}
      close={closeModal}
      inputs={frame}
      error={error}
      title="Добавление"
      secondButtonProp={{
        label: 'Сохранить',
        onClick: defaultRequest,
      }}
    />
  );
}
