import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import ModalWithTitle from './ModalWithTitle';
import CustomInput from '../Inputs';
import { useApi } from '../../hooks';
import { formValidator, getObjectValuesByField } from '../../helpers';
import {
  createVehicleRequest,
  getVehiclesEnumsRequest,
  getVehiclesLastPosition,
  updateVehicleRequest,
} from '../../api/vehiclesApi';
import { DEFAULT_SELECTOR_OPTION } from '../Selectors/Selector';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { useUserStore } from '../../store';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import { OWNERSHIP_TYPE_ENUM, OWNERSHIP_TYPE_ENUM_TEXT } from '../../constants/enums';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import CustomButton from '../Buttons/CustomButton';
import { AlertRequiredFields } from '../Inputs/AlertRequiredFields';
import { formatTSString } from '../../helpers/stringHelper';

const initialVehicleInfo = {
  egrz: '',
  egrz_trailer: '',
  brand: '',
  guid_bnso: DEFAULT_SELECTOR_OPTION,
  model: '',
  ownership_type: DEFAULT_SELECTOR_OPTION,
};

export default function ModalAddVehicle({
  close, vehicle, updateVehicleInfo, setNewVehicle, disabledFields = {},
}) {
  const isEditVehicle = vehicle?.id;
  const { userStore } = useUserStore();
  const [error, setError] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState(initialVehicleInfo);

  const createUpdateVehicle = async () => {
    const requestData = getObjectValuesByField(initialVehicleInfo, vehicleInfo);

    const ignoreInputs = ['guid_bnso', 'egrz_trailer', 'model', 'brand'];

    Object.keys(disabledFields).forEach((key) => ignoreInputs.push(key));

    const {
      hasErrors,
      validField,
    } = formValidator({
      form: requestData,
      ignoreInputs,
      generalRules: ['empty', 'null'],
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    requestData.subject_id = userStore.id;
    requestData.ownership_type = vehicleInfo.ownership_type?.value;
    requestData.guid_bnso = requestData.guid_bnso.label;
    requestData.egrz_trailer = vehicleInfo.egrz_trailer;

    if (isEditVehicle) {
      const updatedVehicle = await updateVehicleRequest(vehicle.id, requestData);
      if (updateVehicleInfo) updateVehicleInfo(updatedVehicle);
    } else {
      const updatedVehicle = await createVehicleRequest(requestData);
      if (setNewVehicle) setNewVehicle(updatedVehicle);
    }

    close(true);
  };

  const {
    makeRequest: onCreateUpdateVehicle,
    isLoading,
  } = useApi({
    request: createUpdateVehicle,
    successMessage: vehicle?.id ? 'ТС успешно обновлено' : 'ТС успешно создано',
  });

  const onChange = ({ target: { name } }, value) => {
    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setVehicleInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const hasDisabledField = (field) => disabledFields?.includes?.(field);

  useEffect(() => {
    if (vehicle.egrz) {
      setVehicleInfo((prevState) => ({
        ...prevState,
        ...vehicle,
        ownership_type: {
          id: vehicle[OWNERSHIP_TYPE_ENUM] || '',
          value: vehicle[OWNERSHIP_TYPE_ENUM] || '',
          label: vehicle[OWNERSHIP_TYPE_ENUM_TEXT] || '',
        },
        guid_bnso: {
          id: vehicle.guid_bnso || '',
          value: vehicle.guid_bnso || '',
          label: vehicle.guid_bnso || '',
        },
      }));
    }
  }, [vehicle]);

  return (
    <ModalWithTitle
      containerSx={{ width: '551px' }}
      mainSx={{ backgroundColor: 'white' }}
      close={() => close()}
      open
      isLoading={isLoading}
      title={`${isEditVehicle ? 'Редактирование' : 'Добавление нового'} ТС`}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Alert severity="warning">
          Укажите номер ТС заглавными буквами на русском языке без пробелов. Формат: А000АА178!
        </Alert>
        <AlertRequiredFields />
        <CustomInput
          fullWidth
          required={!hasDisabledField('egrz')}
          disabled={hasDisabledField('egrz')}
          onChange={onChange}
          name="egrz"
          label="ЕГРЗ ТС (Номер ТС)"
          error={error.egrz}
          value={vehicleInfo.egrz}
          onInput={(e) => {
            e.target.value = formatTSString(e.target.value).toUpperCase();
            return e;
          }}
        />
        <CustomInput
          fullWidth
          disabled={hasDisabledField('brand')}
          value={vehicleInfo.brand}
          error={error.brand}
          onChange={onChange}
          name="brand"
          label="Марка ТС"
        />
        <CustomInput
          fullWidth
          disabled={hasDisabledField('model')}
          value={vehicleInfo.model}
          error={error.model}
          onChange={onChange}
          name="model"
          label="Модель ТС"
        />
        <CustomInput
          fullWidth
          value={vehicleInfo.egrz_trailer}
          error={error.egrz_trailer}
          onChange={onChange}
          name="egrz_trailer"
          label="ЕГРЗ прицепа"
          onInput={(e) => {
            e.target.value = e.target.value.toUpperCase();
            return e;
          }}
        />
        <AsyncAutocomplete
          fullWidth
          value={vehicleInfo.guid_bnso}
          disabled={isEditVehicle}
          additionalFilter={({ data: options }) => options
            .filter(({ car_handler }) => car_handler.includes(vehicleInfo.guid_bnso.label))
            .map(({ car_handler }) => ({
              id: car_handler,
              label: car_handler,
              value: car_handler,
            }))}
          optionField={DEFAULT_AUTOCOMPLETE_FIELD}
          request={() => getVehiclesLastPosition()}
          error={error.guid_bnso?.label}
          onChange={onChange}
          name="guid_bnso"
          label="ID БНСО"
        />
        <AsyncAutocomplete
          required
          request={(params) => getVehiclesEnumsRequest(OWNERSHIP_TYPE_ENUM, params)}
          value={vehicleInfo[OWNERSHIP_TYPE_ENUM]}
          error={error?.[OWNERSHIP_TYPE_ENUM]?.value}
          optionField={DEFAULT_AUTOCOMPLETE_FIELD}
          name={OWNERSHIP_TYPE_ENUM}
          onChange={onChange}
          label="Тип владения"
        />
        {isEditVehicle && (
          <Typography>
            ТС
            <strong>
              {vehicle?.is_connected_rins ? '' : ' не'}
              {' '}
              подключено
            </strong>
            {' '}
            к РИНС ЛО
          </Typography>
        )}
        <CustomButton
          isLoading={isLoading}
          variant="contained"
          sx={{ width: 'fit-content' }}
          onClick={onCreateUpdateVehicle}
        >
          {isEditVehicle ? 'Обновить' : 'Добавить'}
          {' '}
          ТС
        </CustomButton>
      </Box>
    </ModalWithTitle>
  );
}
