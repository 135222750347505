import { Button } from '@mui/material';
import React from 'react';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg';

export default function CloseModalButton({ close, sx }) {
  return (
    <Button
      sx={{
        ml: 'auto',
        minWidth: '46px',
        minHeight: '40px',
        zIndex: 100000,
        background: BACKGROUND_LIGHT,
        ...sx,
      }}
      onClick={close}
    >
      <CloseIcon fontSize="large" />
    </Button>
  );
}
