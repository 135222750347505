import React from 'react';
import proj4 from 'proj4';
import { VehiclePopup } from '../components/Map/VehiclePopup';

export const getTrackCircleChildren = (
  vehicleTracks,
  vehicle = {},
  rnicInfo = {},
) => function (index) {
  return <VehiclePopup vehicle={{ ...vehicle, ...vehicleTracks[index], ...rnicInfo }} />;
};

export const formatLatLngToPostGisLineString = (coords) => {
  const firstPoint = coords[0][0];
  const coordsString = coords[0].map(({ lat, lng }) => `${lng} ${lat}`).join(', ');
  return `LINESTRING(${coordsString}, ${firstPoint.lng} ${firstPoint.lat})`;
};

export const formatLatLngToPostGisPoint = (lat, lng) => `POINT(${lng} ${lat})`;

export function convertCRS(lat, long) {
  return proj4('EPSG:3857').inverse([lat, long]).reverse();
}
