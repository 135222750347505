import { compareId } from '../../helpers';
import { getForm1WasteProducts } from '../../helpers/cadastrHelper';
import { addTotalCadastreCount } from '../../helpers/cadastreForms/getFormField';

export const getInitialWasteTransfer = () => ({
  randId: Math.random(),
  out_processing: '',
  out_utilization: '',
  out_neutralization: '',
  out_for_store: '',
  out_under_store: '',
  document_number: '',
  application_date: null,
  subject_id: '',
  store: '',
  under_store: '',
  available_on_organisation: '',
});

export const getInitialForm3Item = (is_accepting = false) => ({
  randId: Math.random(),
  availability_waste_period: '',
  get_import: '',
  get_all: '',
  waste_type_id: '',
  get_processing: '',
  get_utilization: '',
  get_neutralization: '',
  waste_transfers: [getInitialWasteTransfer()],
  is_accepting,
});

export const getInitialForm3 = () => ({ items: [getInitialForm3Item(true)], hasAcceptingWastes: false });

const wasteTransfersFields = [
  'out_processing',
  'out_utilization',
  'out_neutralization',
  'out_for_store',
  'out_under_store',
  'store',
  'under_store',
  'available_on_organisation',
  {
    name: 'subject_id',
    valid: (subject_id) => subject_id?.id || compareId(subject_id?.name, 0),
  },
  'document_number',
  { name: 'application_date', valid: (date, waste_product) => compareId(waste_product.document_number, 0) || Boolean(date) },
];

const wasteDataFields = [
  'availability_waste_period',
  {
    name: 'waste_type_id',
    valid: (waste_type_id, data) => data.hasBuilding || waste_type_id?.id,
  },
  'get_processing',
  'get_utilization',
  'get_neutralization',
];

const wasteTransferDataFields = [
  'availability_waste_period',
  'get_import',
  'get_all',
  {
    name: 'waste_type_id',
    valid: (waste_type_id, data) => data.hasBuilding || waste_type_id?.id,
  },
  'get_processing',
  'get_utilization',
  'get_neutralization',
];

export const form3PercentGetter = (forms, { errorSetter }) => {
  const { items } = forms.form1;
  const { items: form3Items, hasAcceptingWastes } = forms.form3;

  const allWastes = getForm1WasteProducts(items, 'form3Waste');

  if (!allWastes?.length) return 0;

  let allDataLength = 0;
  let totalFilledCount = 0;

  const addAllDataLength = (count) => {
    allDataLength += count;
  };

  const addTotalFilledCount = () => {
    totalFilledCount += 1;
  };

  const newErrors = [];

  allWastes.forEach((waste, index) => {
    addAllDataLength(wasteDataFields.length);
    addTotalCadastreCount(wasteDataFields, { ...waste, hasBuilding: true }, addTotalFilledCount, {
      index,
      errorSetter: () => {
        const wasteError = `generatingWaste${waste.waste_id}_${waste.report_building_id}`;
        if (!newErrors.includes(wasteError)) {
          newErrors.push(wasteError);
        }
      },
    });

    waste.waste_transfers.forEach((wasteTransfer) => {
      addAllDataLength(wasteTransfersFields.length);
      addTotalCadastreCount(wasteTransfersFields, wasteTransfer, addTotalFilledCount, {
        errorSetter: () => {
          const wasteError = `generatingWasteTransfers${waste.waste_id}_${waste.report_building_id}`;

          if (!newErrors.includes(wasteError)) {
            newErrors.push(wasteError);
          }
        },
      });
    });
  });

  if (hasAcceptingWastes) {
    form3Items.forEach((form3Waste) => {
      addAllDataLength(wasteTransferDataFields.length);
      addTotalCadastreCount(wasteTransferDataFields, form3Waste, addTotalFilledCount, {
        errorSetter: () => {
          const wasteError = `acceptingForm3Waste${form3Waste.waste_id}`;

          if (!newErrors.includes(wasteError)) {
            newErrors.push(wasteError);
          }
        },
      });

      form3Waste.waste_transfers.forEach((wasteTransfer) => {
        addAllDataLength(wasteTransfersFields.length);
        addTotalCadastreCount(wasteTransfersFields, wasteTransfer, addTotalFilledCount, {
          errorSetter: () => {
            const wasteError = `acceptingWasteTransfer${form3Waste.waste_id}`;

            if (!newErrors.includes(wasteError)) {
              newErrors.push(wasteError);
            }
          },
        });
      });
    });
  }

  errorSetter({ errorForm3: newErrors });

  return Math.floor((totalFilledCount / allDataLength) * 100);
};
