import { METHODS_API } from '../../api/api';
import {
  cadastreAreaDocumentsRequest,
  cadastreEnvironmentMonitoringTypesRequest, cadastreForm8ReportPlacingWastesRequest,
  cadastreObjectRightTypesRequest,
  cadastreObjectSubjectRelationshipRequest,
  cadastrePlacedWastesRequest,
  cadastrePlacingObjectsRequest,
  cadastreWastePlacementMunicipalitiesRequest,
} from '../../api/cadastreApi';
import { formatObjectWithAddress, getOktmoLabel, valueToString } from '../stringHelper';
import {
  ENVIRONMENT_MONITORING_TYPE_NAME,
  ENVIRONMENTAL_SYSTEM_TYPE,
  FORM8_AREA_DOCUMENTS_NAME,
  FORM8_LICENSES,
  FORM8_PLACING_OBJECTS_NAME,
  getInitialEnvironmentalSystemType,
  getInitialEnvironmentMonitoringType,
  getInitialForm8AreaDocuments,
  getInitialForm8Licenses,
  getInitialForm8PlacedObject,
  getInitialNearestTown,
  getInitialNearestWasterObject,
  getInitialObjectRight,
  getInitialObjectSubjectRelationship,
  getInitialPlacedWaste,
  getInitialProjectDocument,
  getInitialRestrictedAreaType,
  getInitialWastePlacementMunicipality,
  NEAREST_TOWN_NAME,
  NEAREST_WATER_OBJECT_NAME,
  OBJECT_RIGHT_TYPES_NAME,
  OBJECT_SUBJECT_RELATIONSHIPS_NAME,
  PLACED_WASTES_NAME,
  PROJECT_DOCUMENT_NAME,
  RESTRICTED_AREA_TYPE_NAME,
  WASTE_PLACEMENT_MUNICIPALITIES_NAME,
} from '../../constants/lkCadastr/cadastrForm8';
import { sortByCreatedAt } from '../sortHelper';
import { compareId } from '../compareId';

export const getReportPlacingWasteHelper = (report) => {
  if (report?.report_placing_wastes_attributes?.length) {
    const reportPlacingWaste = report?.report_placing_wastes_attributes.at(-1);

    const allPlacingObjects = [];

    const additionalFormItems = {};

    const objects = reportPlacingWaste?.attributes?.placing_objects_attributes;

    objects.forEach((placingObjectAttributes) => {
      const placingObject = {
        ...placingObjectAttributes,
        ...placingObjectAttributes?.attributes,
        noReclamation: placingObjectAttributes?.attributes?.reclamation_date === null,
        commissioning_year: valueToString(placingObjectAttributes.attributes.commissioning_year),
        operating_year_end: valueToString(placingObjectAttributes.attributes.operating_year_end),
        object_design_power: valueToString(placingObjectAttributes.attributes.object_design_power),
        object_actual_power: valueToString(placingObjectAttributes.attributes.object_actual_power),
        szz_size: valueToString(placingObjectAttributes.attributes.szz_size),
      };

      const {
        area_documents_attributes,
        environmental_monitoring_types_attributes,
        environmental_system_types_attributes,
        nearest_towns_attributes,
        nearest_water_objects_attributes,
        object_right_types_attributes,
        object_subject_relationships_attributes,
        waste_placement_municipalities_attributes,
        restricted_area_types_attributes,
        project_documents_attributes,
        placed_wastes_attributes,
        report_license_attributes,
      } = placingObject;

      if (placed_wastes_attributes?.length) {
        additionalFormItems[PLACED_WASTES_NAME] = sortByCreatedAt(placed_wastes_attributes, true)
          .map((placed_wastes_attribute) => {
            const placed_waste = placed_wastes_attribute?.attributes;

            return ({
              ...getInitialPlacedWaste(),
              ...placed_wastes_attribute,
              ...placed_waste,
              tons_per_year: valueToString(placed_waste.tons_per_year),
              waste_type_id: {
                ...placed_waste?.waste_type_attributes,
                ...placed_waste?.waste_type_attributes?.attributes,
                code: placed_waste?.waste_type_attributes?.attributes?.code || '0',
                name: placed_waste?.waste_type_attributes?.attributes?.name || '0',
              },
            });
          });
      }

      if (waste_placement_municipalities_attributes?.length) {
        additionalFormItems[WASTE_PLACEMENT_MUNICIPALITIES_NAME] = waste_placement_municipalities_attributes
          .map((waste_placement_municipalities_attribute) => {
            const waste_placement_municipality = waste_placement_municipalities_attribute?.attributes;

            return ({
              ...getInitialWastePlacementMunicipality(),
              ...waste_placement_municipalities_attribute,
              ...waste_placement_municipality,
              waste_volume: valueToString(waste_placement_municipality.waste_volume),
              oktmo_id: {
                ...waste_placement_municipality?.oktmo_attributes,
                ...waste_placement_municipality?.oktmo_attributes?.attributes,
                label: getOktmoLabel(waste_placement_municipality?.oktmo_attributes?.attributes) || '0',
              },
            });
          });
      }

      if (project_documents_attributes?.length) {
        const project_documents_attribute = project_documents_attributes[0];
        const project_document = project_documents_attribute?.attributes;

        additionalFormItems[PROJECT_DOCUMENT_NAME] = {
          ...getInitialProjectDocument(),
          ...project_documents_attribute,
          ...project_document,
          issue_date_gee: project_document?.issue_date_gee || '0',
          issue_date: project_document?.issue_date || '0',
        };
      }

      if (object_subject_relationships_attributes?.length) {
        additionalFormItems[OBJECT_SUBJECT_RELATIONSHIPS_NAME] = object_subject_relationships_attributes
          .map((object_subject_relationships_attribute) => {
            const object_subject_relationship = object_subject_relationships_attribute?.attributes;

            return ({
              ...getInitialObjectSubjectRelationship(),
              ...object_subject_relationships_attribute,
              ...object_subject_relationship,
              tons_qty: valueToString(object_subject_relationship.tons_qty) || '0',
              agreement_date: object_subject_relationship.agreement_date || '0',
              agreement_number: object_subject_relationship.agreement_number || '0',
              subject_id: {
                ...object_subject_relationship?.subject_attributes,
                ...object_subject_relationship?.subject_attributes?.attributes,
                name: object_subject_relationship?.subject_attributes?.attributes?.name || '0',
              },
            });
          });
      }

      if (object_right_types_attributes?.length) {
        additionalFormItems[OBJECT_RIGHT_TYPES_NAME] = object_right_types_attributes
          .map((object_right_types_attribute) => {
            const object_right_type = object_right_types_attribute?.attributes;

            return ({
              ...getInitialObjectRight(),
              ...object_right_types_attribute,
              ...object_right_type,
              issue_date: object_right_type?.issue_date || '0',
              right_type: {
                label: object_right_type.right_type_text || '0',
                id: object_right_type.right_type,
                value: object_right_type.right_type,
              },
            });
          });
      }

      if (area_documents_attributes?.length) {
        additionalFormItems[FORM8_AREA_DOCUMENTS_NAME] = area_documents_attributes
          .map((area_documents_attribute) => {
            const area_document = area_documents_attribute?.attributes;

            return ({
              ...getInitialForm8AreaDocuments(),
              ...area_documents_attribute,
              ...area_document,
              issue_date: area_document?.issue_date || '0',
            });
          });
      }

      if (environmental_monitoring_types_attributes?.length) {
        additionalFormItems[ENVIRONMENT_MONITORING_TYPE_NAME] = environmental_monitoring_types_attributes
          .map((environmental_monitoring_types_attribute) => {
            const environmental_monitoring_type = environmental_monitoring_types_attribute?.attributes;

            return ({
              ...getInitialEnvironmentMonitoringType(),
              ...environmental_monitoring_types_attribute,
              ...environmental_monitoring_type,
            });
          });
      }

      if (environmental_system_types_attributes?.length) {
        const environmental_system_types_attribute = environmental_system_types_attributes.at(-1);
        const environmental_system_type = environmental_system_types_attribute?.attributes;

        additionalFormItems[ENVIRONMENTAL_SYSTEM_TYPE] = {
          ...getInitialEnvironmentalSystemType(),
          ...environmental_system_types_attribute,
          ...environmental_system_type,
        };
      }

      if (restricted_area_types_attributes?.length) {
        const restricted_area_types_attribute = restricted_area_types_attributes.at(-1);
        const restricted_area_type = restricted_area_types_attribute?.attributes;

        additionalFormItems[RESTRICTED_AREA_TYPE_NAME] = {
          ...getInitialRestrictedAreaType(),
          ...restricted_area_type,
          ...restricted_area_types_attribute,
        };
      }

      if (nearest_towns_attributes?.length) {
        const nearest_towns_attribute = nearest_towns_attributes.at(-1);
        const nearest_town = nearest_towns_attribute?.attributes;
        additionalFormItems[NEAREST_TOWN_NAME] = {
          ...getInitialNearestTown(),
          ...nearest_towns_attribute,
          ...nearest_town,
          distance: nearest_town.distance?.toString?.() || '',
          direction: {
            label: nearest_town.direction_text || '0',
            id: nearest_town.direction,
            value: nearest_town.direction,
          },
        };
      }

      if (nearest_water_objects_attributes?.length) {
        const nearest_water_objects_attribute = nearest_water_objects_attributes.at(-1);
        const nearest_water_object = nearest_water_objects_attribute?.attributes;

        additionalFormItems[NEAREST_WATER_OBJECT_NAME] = {
          ...getInitialNearestWasterObject(),
          ...nearest_water_objects_attribute,
          ...nearest_water_object,
          distance: nearest_water_object.distance?.toString?.() || '',
        };
      }

      if (report_license_attributes) {
        const license = report_license_attributes?.attributes;

        additionalFormItems.licenses = [
          {
            ...getInitialForm8Licenses(),
            license: {
              ...report_license_attributes,
              ...license,
            },
          },
        ];
      }

      allPlacingObjects.push({
        ...getInitialForm8PlacedObject(),
        ...placingObject,
        registration_date_groro: placingObject?.registration_date_groro || '0',
        object_state: {
          label: placingObject.object_state_text || '0',
          id: placingObject.object_state,
          value: placingObject.object_state,
        },
        objective_id: {
          ...placingObject?.objective_attributes,
          ...placingObject?.objective_attributes?.attributes,
          label: formatObjectWithAddress({ objective: placingObject }) || '0',
          name: placingObject?.objective_attributes?.attributes?.name || '0',
        },
        ...additionalFormItems,
      });
    });

    if (!allPlacingObjects.length) allPlacingObjects.push(getInitialForm8PlacedObject());

    return {
      id: reportPlacingWaste?.id,
      [FORM8_PLACING_OBJECTS_NAME]: allPlacingObjects,
    };
  }
};

export const deleteForm8Entity = async ({ id, apiType }) => {
  const placedObjectApis = {
    [FORM8_PLACING_OBJECTS_NAME]: cadastrePlacingObjectsRequest,
    [FORM8_AREA_DOCUMENTS_NAME]: cadastreAreaDocumentsRequest,
    [ENVIRONMENT_MONITORING_TYPE_NAME]: cadastreEnvironmentMonitoringTypesRequest,
    [PLACED_WASTES_NAME]: cadastrePlacedWastesRequest,
    [OBJECT_RIGHT_TYPES_NAME]: cadastreObjectRightTypesRequest,
    [WASTE_PLACEMENT_MUNICIPALITIES_NAME]: cadastreWastePlacementMunicipalitiesRequest,
    [OBJECT_SUBJECT_RELATIONSHIPS_NAME]: cadastreObjectSubjectRelationshipRequest,
  };

  const deletedItem = await placedObjectApis[apiType]({
    method: METHODS_API.DELETE,
    id,
  });

  return deletedItem?.id;
};

export const saveForm8ReportPlacingObjects = async ({
  forms,
  reportId,
  isImportToExistingReport,
  previousForms,
}) => {
  const form8PlacingObjects = forms.form8[FORM8_PLACING_OBJECTS_NAME];
  const previousForm8PlacingObjects = previousForms?.form8?.[FORM8_PLACING_OBJECTS_NAME];
  const reportPlacingWasteId = forms.form8.id;

  const form5InfoLicenses = forms.form5.licenses;
  const previousForm5InfoLicenses = previousForms?.form5?.licenses;

  const mainForm8PlacingObjects = previousForm8PlacingObjects || form8PlacingObjects;

  const reportPlacingWaste = {
    report_history_id: reportId,
    placing_objects: mainForm8PlacingObjects.map((placingObject) => {
      let licenseId = placingObject[FORM8_LICENSES]?.[0]?.license?.id;

      if (previousForm5InfoLicenses) {
        const foundPreviousLicenseIndex = previousForm5InfoLicenses.findIndex((item) => compareId(item.id, licenseId));
        const foundCurrentLicense = form5InfoLicenses[foundPreviousLicenseIndex];

        licenseId = foundCurrentLicense?.id;
      }

      const placingObjectId = placingObject.id;

      const nearestTown = placingObject[NEAREST_TOWN_NAME];
      const nearestTownData = {
        name: nearestTown.name,
        direction: nearestTown?.direction?.id || '',
        distance: nearestTown.distance,
      };

      if (!isImportToExistingReport && placingObjectId) nearestTownData.placing_object_id = placingObjectId;
      if (!isImportToExistingReport && nearestTown.id) nearestTownData.id = nearestTown.id;

      const nearestWaterObject = placingObject[NEAREST_WATER_OBJECT_NAME];
      const nearestWaterObjectData = {
        name: nearestWaterObject.name,
        distance: nearestWaterObject.distance,
      };

      if (!isImportToExistingReport && placingObjectId) nearestWaterObjectData.placing_object_id = placingObjectId;
      if (!isImportToExistingReport && nearestWaterObject.id) nearestWaterObjectData.id = nearestWaterObject.id;

      const restrictedArea = placingObject[RESTRICTED_AREA_TYPE_NAME];
      const restrictedAreaData = {
        name: restrictedArea.name,
      };

      if (!isImportToExistingReport && placingObjectId) restrictedAreaData.placing_object_id = placingObjectId;
      if (!isImportToExistingReport && restrictedArea.id) restrictedAreaData.id = restrictedArea.id;

      const environmentalSystemType = placingObject[ENVIRONMENTAL_SYSTEM_TYPE];
      const environmentalSystemTypeData = {
        name: environmentalSystemType.name,
      };

      if (!isImportToExistingReport && placingObjectId) environmentalSystemType.placing_object_id = placingObjectId;
      if (!isImportToExistingReport && environmentalSystemType.id) environmentalSystemTypeData.id = environmentalSystemType.id;

      const projectDocument = placingObject[PROJECT_DOCUMENT_NAME];
      const projectDocumentData = {
        name: projectDocument.name,
        developer_name: projectDocument.developer_name,
        develope_year: projectDocument.develope_year,
        positive_result_gee: projectDocument.positive_result_gee,
        issue_date: valueToString(projectDocument.issue_date),
        document_number: projectDocument.document_number,
        organisation_approved_gee: projectDocument.organisation_approved_gee,
        issue_date_gee: valueToString(projectDocument.issue_date_gee),
        result_number_gee: projectDocument.result_number_gee,
      };

      if (!isImportToExistingReport && placingObjectId) projectDocumentData.placing_object_id = placingObjectId;
      if (!isImportToExistingReport && projectDocument.id) projectDocumentData.id = projectDocument.id;

      const placingObjectData = {
        object_state: placingObject.object_state?.value || '',
        reclamation_type: placingObject.noReclamation ? '' : placingObject.reclamation_type,
        reclamation_date: placingObject.noReclamation ? null : valueToString(placingObject.reclamation_date),
        operating_year_end: placingObject.operating_year_end,
        commissioning_year: placingObject.commissioning_year,
        document_name: placingObject.document_name,
        object_design_power: placingObject.object_design_power,
        object_actual_power: placingObject.object_actual_power,
        szz_size: placingObject.szz_size,
        object_capacity: placingObject.object_capacity,
        object_area: placingObject.object_area,
        placed_total: placingObject.placed_total,
        registration_number_groro: placingObject.registration_number_groro,
        registration_date_groro: valueToString(placingObject.registration_date_groro),
        objective_id: placingObject.objective_id?.id || '',
        nearest_towns: [nearestTownData],
        nearest_water_objects: [nearestWaterObjectData],
        restricted_area_types: [restrictedAreaData],
        project_documents: [projectDocumentData],
        environmental_system_types: [environmentalSystemTypeData],
        [OBJECT_SUBJECT_RELATIONSHIPS_NAME]: placingObject[OBJECT_SUBJECT_RELATIONSHIPS_NAME].map((objectSubjectRelationship) => {
          const objectSubjectRelationshipId = objectSubjectRelationship.id;

          const objectSubjectData = {
            subject_id: objectSubjectRelationship.subject_id?.id || '',
            agreement_number: objectSubjectRelationship.agreement_number,
            agreement_date: valueToString(objectSubjectRelationship.agreement_date),
            tons_qty: objectSubjectRelationship.tons_qty,
          };

          if (!isImportToExistingReport && placingObjectId) objectSubjectData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && objectSubjectRelationshipId) objectSubjectData.id = objectSubjectRelationshipId;

          return objectSubjectData;
        }),
        [FORM8_AREA_DOCUMENTS_NAME]: placingObject[FORM8_AREA_DOCUMENTS_NAME].map((areaDocument) => {
          const areaDocumentData = {
            document_name: areaDocument.document_name,
            body_name: areaDocument.body_name,
            issue_date: valueToString(areaDocument.issue_date),
            document_number: areaDocument.document_number,
            cadastral_number_area: areaDocument.cadastral_number_area,
            owner: areaDocument.owner,
            area_category: areaDocument.area_category,
            permitted_use_type: areaDocument.permitted_use_type,
          };

          if (!isImportToExistingReport && placingObjectId) areaDocumentData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && areaDocument.id) areaDocumentData.id = areaDocument.id;

          return areaDocumentData;
        }),
        environmental_monitoring_types: placingObject[ENVIRONMENT_MONITORING_TYPE_NAME].map((environmentMonitoringType) => {
          const environmentMonitoringTypeData = {
            normative_rule: environmentMonitoringType.normative_rule,
            name: environmentMonitoringType.name,
          };

          if (!isImportToExistingReport && placingObjectId) environmentMonitoringTypeData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && environmentMonitoringType.id) environmentMonitoringTypeData.id = environmentMonitoringType.id;

          return environmentMonitoringTypeData;
        }),
        [PLACED_WASTES_NAME]: placingObject[PLACED_WASTES_NAME].map((placedWaste) => {
          const placedWasteData = {
            waste_type_id: placedWaste.waste_type_id?.id || '',
            name: placedWaste.name,
            tons_per_year: placedWaste.tons_per_year,
            placement_technology: placedWaste.placement_technology,
            placement_method: placedWaste.placement_method,
          };

          if (!isImportToExistingReport && placingObjectId) placedWasteData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && placedWaste.id) placedWasteData.id = placedWaste.id;

          return placedWasteData;
        }),
        [OBJECT_RIGHT_TYPES_NAME]: placingObject[OBJECT_RIGHT_TYPES_NAME].map((objectRightType) => {
          const objectRightTypeData = {
            right_type: objectRightType.right_type?.value || '',
            document_number: objectRightType.document_number,
            body_name: objectRightType.body_name,
            issue_date: valueToString(objectRightType.issue_date),
            kind_number: objectRightType.kind_number,
          };

          if (!isImportToExistingReport && placingObjectId) objectRightTypeData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && objectRightType.id) objectRightTypeData.id = objectRightType.id;

          return objectRightTypeData;
        }),
        waste_placements_municipality: placingObject[WASTE_PLACEMENT_MUNICIPALITIES_NAME].map((wastePlacementMunicipality) => {
          const wastePlacementMunicipalityData = {
            oktmo_id: wastePlacementMunicipality.oktmo_id?.id || '',
            waste_volume: wastePlacementMunicipality.waste_volume,
          };

          if (!isImportToExistingReport && placingObjectId) wastePlacementMunicipalityData.placing_object_id = placingObjectId;
          if (!isImportToExistingReport && wastePlacementMunicipality.id) wastePlacementMunicipalityData.id = wastePlacementMunicipality.id;

          return wastePlacementMunicipalityData;
        }),
      };
      if (!isImportToExistingReport && reportPlacingWasteId) placingObjectData.report_placing_waste_id = reportPlacingWasteId;
      if (licenseId) placingObjectData.report_license_id = licenseId || '';
      if (!isImportToExistingReport && placingObjectId) placingObjectData.id = placingObjectId;

      return placingObjectData;
    }),
  };

  if (!isImportToExistingReport && reportPlacingWasteId) reportPlacingWaste.id = reportPlacingWasteId;

  const reportPlacingWasteData = [reportPlacingWaste];

  const responseReportPlacingWaste = await cadastreForm8ReportPlacingWastesRequest({
    method: METHODS_API.POST,
    data: reportPlacingWasteData,
  });

  return responseReportPlacingWaste?.data;
};
