import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Box } from '@mui/material';
import NavTabs from '../Tabs';
import {
  CADASTR_ADMIN_CONTACT_INFO_ROUTE,
  CADASTR_ADMIN_FAQ_ROUTE,
  CADASTR_ADMIN_FEEDBACK_ROUTE,
  CADASTR_ADMIN_ROUTE,
} from '../../constants/routes';
import { useApi } from '../../hooks';
import { cadastreFeedbacksRequest } from '../../api/cadastreApi';
import { useCadastreStore } from '../../store';

export default function CadastrAdminToolbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { cadastreStore: { questions = [] }, setCadastreStore } = useCadastreStore();

  const TABS = useMemo(() => [
    { name: CADASTR_ADMIN_FAQ_ROUTE, label: 'Вопросы и ответы' },
    { name: CADASTR_ADMIN_CONTACT_INFO_ROUTE, label: 'Контактная информация' },
    {
      name: CADASTR_ADMIN_FEEDBACK_ROUTE,
      label: (
        <Box sx={{
          '& .MuiBadge-badge': {
            position: 'relative', transform: 'initial', right: -8, top: -1,
          },
        }}
        >
          Обратная связь
          <Badge badgeContent={questions.filter((item) => !item.answer).length} color="error" max={999} />
        </Box>
      ),
    },
  ], [questions]);

  const tab = useMemo(() => TABS.find(({ name }) => pathname.includes(name))?.name, [pathname]);

  const { defaultRequest: feedbackApi } = useApi({
    request: cadastreFeedbacksRequest,
  });

  const getFeedbacks = () => feedbackApi().then((resQuestions) => {
    setCadastreStore({ questions: resQuestions });
  });

  useEffect(() => {
    if (pathname.includes('cadastr') || pathname.includes('admin')) getFeedbacks();
  }, []);

  if (!pathname.includes(`${CADASTR_ADMIN_ROUTE}/`)) return null;

  return (
    <Box display="flex" alignItems="flex-end" minHeight="64px">
      <NavTabs tabsComponents={TABS} setTab={(newTab) => navigate(newTab)} tab={tab} />
    </Box>
  );
}
