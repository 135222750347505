import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Chip, CircularProgress, FormControl, InputAdornment, MenuItem, TextField,
} from '@mui/material';
import { ReactComponent as SelectDownIcon } from '../../assets/select-down-icon.svg';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { useApi } from '../../hooks';
import { getError } from '../../helpers/formValidator';

const innerFormSx = {
  position: 'relative',
  width: 'fit-content',
  minWidth: '160px',
  '&.form-selector.opened': {
    svg: {
      transform: 'rotate(180deg)',
    },
  },
  '&.error': {
    fieldset: {
      border: ({ palette }) => `1px solid ${palette.error.main}`,
    },
  },
  '& .dropdown-icon': {
    pointerEvents: 'none',
    userSelect: 'none',
    position: 'absolute',
    top: 'initial',
    right: 12,
  },
  '.MuiInputBase-root.MuiInputBase-formControl': {
    borderRadius: '8px',
    background: BACKGROUND_LIGHT,
  },
  fieldset: {
    border: 'none',
  },
};

function SelectedChip({
  selected, onChange, name, optionField,
}) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((item, index) => (
        <Chip
          key={item[optionField.value]}
          label={item[optionField.label]}
          onDelete={(e) => onChange(
            {
              preventDefault: e.preventDefault,
              stopPropagation: e.stopPropagation,
              target: { name },
            },
            { props: { item: selected[index] } },
          )}
        />
      ))}
    </Box>
  );
}

export const DEFAULT_SELECTOR_FIELDS = { label: 'label', value: 'value', id: 'id' };
export const DEFAULT_SELECTOR_OPTION = { label: '', value: '', id: '' };

function IconComponent(props) {
  const { className } = props;
  return <SelectDownIcon params={props} className={`material-icons dropdown-icon ${className}`} />;
}

function Selector({
  optionField,
  multiple,
  request,
  className = '',
  disabled,
  useEmpty,
  error,
  required,
  value,
  multiline,
  sx,
  onChange,
  label,
  isLoading,
  options = [],
  name,
  StartIconComponent,
  placeholder,
  validImmediately = false,
  inputSx,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [innerOptions, setInnerOptions] = useState([]);
  const [innerValue, setInnerValue] = useState('');
  const errorText = error || getError({ value: innerValue, validImmediately });
  const errorClass = (!disabled && errorText) ? 'error' : '';
  const multipleValuesClass = multiple && value.length ? 'multiple-values' : '';

  const mainFields = useMemo(() => ({
    value: 'value',
    label: 'label',
    ...optionField,
  }), [optionField]);

  const { defaultRequest: getOptionsRequest } = useApi({
    setter: setInnerOptions,
    request,
    shouldRequest: value[mainFields.value],
  });

  const mainOptions = useMemo(() => {
    if (useEmpty) {
      return [
        {
          [mainFields.label]: 'Нет',
          [mainFields.value]: null,
        },
        ...options,
      ];
    }

    if (multiple || request) return innerOptions;

    return options || [];
  }, [options, innerOptions, multiple, useEmpty, mainFields.value, mainFields.label]);

  const handleChange = (e, { props }) => {
    if (!multiple) {
      return onChange(e, props.item);
    }

    const existingOptionIndex = value
      .findIndex((item) => props.item[mainFields.value] === item[mainFields.value]);

    if (existingOptionIndex === -1) {
      onChange(e, [...value, props.item]);
    } else {
      const copyValue = value.slice();
      copyValue.splice(existingOptionIndex, 1);
      onChange(e, copyValue);
    }
  };

  useEffect(() => {
    setInnerValue(multiple ? value.map((item) => item[mainFields.value] || '') : value?.[mainFields.value] || '');
  }, [value, mainFields.value, multiple]);

  const isOpenedClass = isOpen ? ' opened' : '';

  const getSelectedChip = () => (
    <SelectedChip optionField={optionField} selected={value} name={name} onChange={handleChange} />
  );

  return (
    <FormControl
      className={`${className} form-selector${isOpenedClass} ${errorClass}${multipleValuesClass}`}
      sx={{ ...innerFormSx, ...sx }}
      disabled={disabled}
      fullWidth
    >
      {isLoading && (
        <Box
          width="100%"
          height="100%"
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      <TextField
        select
        multiline={multiline}
        required={required}
        disabled={isLoading || disabled}
        labelid={label}
        error={!!errorText}
        helperText={errorText}
        defaultValue=""
        id={label}
        placeholder={placeholder}
        name={name}
        value={innerValue}
        InputProps={StartIconComponent && {
          startAdornment: (
            <InputAdornment position="start">
              {StartIconComponent}
            </InputAdornment>
          ),
        }}
        SelectProps={{
          multiple,
          open: isOpen,
          onOpen: (e) => {
            e.stopPropagation();

            if (!['path', 'svg'].includes(e.target.localName)) {
              if (getOptionsRequest) getOptionsRequest();
              setIsOpen(true);
            }
          },
          onClose: (e) => {
            e.stopPropagation();

            if (multiple && e.target.selected === undefined) {
              return setIsOpen(false);
            }

            setIsOpen(false);
          },
          IconComponent,
          renderValue: !multiple ? undefined : getSelectedChip,
        }}
        label={label}
        sx={inputSx}
        onChange={handleChange}
      >
        {mainOptions?.map?.((item) => (
          <MenuItem
            key={item[mainFields.label]}
            className={multiple && value.some((valueOption) => valueOption[mainFields.value] === item[mainFields.value]) ? 'Mui-selected' : ''}
            value={item[mainFields.value] || item[mainFields.label]}
            item={item}
          >
            {item[mainFields.label]}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

export default memo(Selector);
