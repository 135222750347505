import {
  ENUMS_URL, IMPORT_VIOLATIONS_URL, IMPORTS_URL, SMAV_URL, VIOLATIONS_URL,
} from '../constants/urls';
import api from './api';

export const getViolationsRequest = (params, setTableStore) => api({
  url: VIOLATIONS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const getViolationRequest = (id) => api(
  { url: `${VIOLATIONS_URL}/${id}` },
  { isEntityAttributes: true },
);

export const updateViolationRequest = (id, data) => api({ method: 'put', url: `${VIOLATIONS_URL}/${id}`, data });

export const deleteViolationRequest = (id) => api({ method: 'delete', url: `${VIOLATIONS_URL}/${id}` });

export const createViolationRequest = (application) => api({
  method: 'post',
  url: VIOLATIONS_URL,
  data: { application },
}, { isEntityAttributes: true });

export const importViolationRequest = () => api({
  method: 'post',
  url: `${IMPORTS_URL}${IMPORT_VIOLATIONS_URL}`,
}, { isEntityAttributes: true });

export const getViolationEnumsRequest = (enum_field) => api(
  { url: `${VIOLATIONS_URL}${ENUMS_URL}` },
  { enum_field },
);

export const updateSMAVRequest = () => api({ url: `${VIOLATIONS_URL}${SMAV_URL}` });
