import React, { useState } from 'react';
import { Box } from '@mui/material';
import NavTabs from '../Tabs';

const tabsComponents = [
  { label: 'Целевые показатели', name: 'target-statistic' },
  { label: 'Баланс количественных характеристик', name: 'balance-statistic' },
  { label: 'Количетсво отходов по типам', name: 'waste-statistic' },
];

export default function Statistics() {
  const [tab, setTab] = useState(tabsComponents[0].name);

  return (
    <Box>
      <NavTabs tabsComponents={tabsComponents} setTab={setTab} tab={tab} />
    </Box>
  );
}
