import React from 'react';
import { BUTTON_BLUE } from '../../constants/colors';

export { DropdownArrowIcon } from './DropdownArrowIcon';

export function SearchIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path d="M17.5607 15.4397L13.7529 11.6319C14.5397 10.4461 15.0002 9.02634 15.0002 7.50008C15.0002 3.36454 11.6356 0 7.50008 0C3.36454 0 0 3.36454 0 7.50008C0 11.6356 3.36454 15.0002 7.50008 15.0002C9.02634 15.0002 10.4461 14.5397 11.6319 13.7529L15.4397 17.5607C16.0247 18.1464 16.9757 18.1464 17.5607 17.5607C18.1464 16.9749 18.1464 16.0254 17.5607 15.4397ZM2.25002 7.50008C2.25002 4.60505 4.60505 2.25002 7.50008 2.25002C10.3951 2.25002 12.7501 4.60505 12.7501 7.50008C12.7501 10.3951 10.3951 12.7501 7.50008 12.7501C4.60505 12.7501 2.25002 10.3951 2.25002 7.50008Z" fill="#B6BABB" />
    </svg>
  );
}

export function CheckboxIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="21" height="21" rx="4" fill={BUTTON_BLUE} />
      <path d="M16.7832 7.26963L15.7303 6.21674C15.586 6.07227 15.4104 6 15.204 6C14.9974 6 14.8219 6.07227 14.6775 6.21674L9.59871 11.3032L7.32257 9.0193C7.17803 8.87477 7.00256 8.80258 6.79618 8.80258C6.58966 8.80258 6.41419 8.87477 6.26965 9.0193L5.21677 10.0722C5.07224 10.2167 5 10.3922 5 10.5987C5 10.8051 5.07224 10.9808 5.21677 11.1252L8.0193 13.9277L9.07227 14.9806C9.21672 15.1252 9.39224 15.1974 9.59871 15.1974C9.80509 15.1974 9.98062 15.125 10.1252 14.9806L11.1781 13.9277L16.7832 8.32259C16.9276 8.17806 17 8.00256 17 7.79607C17.0001 7.58968 16.9276 7.41416 16.7832 7.26963Z" fill="white" />
    </svg>
  );
}

export function RedPointIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" rx="4" fill="#E85050" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.35636 9.75211L2.91668 6.41666L10.7729 6.41666V7.58333H5.7957L7.16853 8.91456L6.35636 9.75211Z" fill="white" />
    </svg>
  );
}

export function GreenPointIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" rx="4" fill="#8CC63F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9165 4.83122L11.3562 8.16666H3.5V7H8.47716L7.10433 5.66877L7.9165 4.83122Z" fill="white" />
    </svg>
  );
}
