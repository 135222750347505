import React from 'react';
import CustomAccordion from './CustomAccordion';
import { Text, TextTitle } from './Text';
import DeleteButton from './Buttons/DeleteButton';

const baseFields = {
  question: 'question',
  answer: 'answer',
  created_at: 'created_at',
  updated_at: 'updated_at',
};

export default function FAQAccordion({
  faqs, getItem, getTitle, fields = baseFields, deleteItem,
}) {
  if (faqs.length === 0) {
    return <TextTitle>Вопросы отсутствуют</TextTitle>;
  }

  return (
    <>
      {
        faqs.map((questionInfo) => (

          <CustomAccordion
            key={questionInfo?.id || questionInfo[fields.question]}
            title={getTitle ? getTitle({ questionInfo }) : (questionInfo[fields.question])}
          >
            <Text sx={{ 'margin-bottom': '20px', 'font-style': 'italic' }}>
              Дата вопроса:
              {' '}
              {new Date(questionInfo.created_at).toLocaleString()}
            </Text>
            {questionInfo[fields.answer] && (
              <>
                <TextTitle sx={{ fontWeight: 'bold' }}>Ответ:</TextTitle>
                <Text sx={{ whiteSpace: 'pre-wrap' }}>
                  {questionInfo[fields.answer]}
                </Text>
                <Text sx={{ 'margin-top': '20px', 'font-style': 'italic' }}>
                  Дата ответа:
                  {' '}
                  {new Date(questionInfo.updated_at).toLocaleString()}
                </Text>
              </>
            )}
            {getItem && getItem(questionInfo)}
            {deleteItem && (
              <DeleteButton sx={{ width: 'fit-content', mt: 1 }} onClick={() => deleteItem(questionInfo)}>
                Удалить
                вопрос
              </DeleteButton>
            )}
          </CustomAccordion>
        ))
      }
    </>
  );
}
