import React from 'react';
import {
  Box, Stack,
} from '@mui/material';

export default function HeaderSortFilter({ component }) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mt="20px"
    >
      <Box>
        <Stack spacing={1} direction="row" alignItems="center">
          {component}
        </Stack>
      </Box>
    </Box>
  );
}
