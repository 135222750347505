import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar, Box, Button,
  Toolbar,
  styled,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { CARRIER_LINKS, DRAWER_WIDTH } from '../../constants';
import { CADASTR_ADMIN_LINKS, CADASTR_LINKS, COMMITTEE_STAFF_LINKS } from '../../constants/headerConstants';
import {
  ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE,
  ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE,
  ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE,
  ADMIN_FKKO_GUIDE,
  ADMIN_HAZARD_CLASSES_GUIDE,
  ADMIN_NOTIFICATIONS_ROUTE,
  ADMIN_OKTMO_GUIDE,
  ADMIN_OPERATIONS_ROUTE,
  ADMIN_PREMISES_APPOINTMENT_GUIDE,
  ADMIN_REGISTRY_APPLICATIONS,
  ADMIN_REGISTRY_OBJECTS_ROUTE,
  ADMIN_REGISTRY_SUBJECTS_ROUTE,
  ADMIN_REGISTRY_TS,
  ADMIN_ROUTES,
  ADMIN_SEND_EMAIL_ROUTE,
  ADMIN_SUBJECT_TYPE_PAGE,
  ADMIN_SUBSCRIPTIONS_ROUTE,
  ADMIN_TER_SCHEME_ROUTE,
  ADMIN_TYPE_ENTERPRISE_GUIDE,
  ADMIN_USERS_ROLES_ROUTE,
  ADMIN_WASTE_CADASTRE_ROUTE,
  ADMIN_WASTE_CONDITION_ROUTE,
  ADMIN_WASTE_MANAGEMENT_GUIDE,
  CADASTRE_ROUTE,
  CADASTR_ADMIN_ROUTE,
  CARRIER_GENERAL_INFO_ROUTE,
  CARRIER_PERSONAL_ACCOUNT_ROUTE,
  COMMITTEE_ANALYTICS,
  COMMITTEE_DEVIATION,
  COMMITTEE_MONITORING_VEHICLES,
  COMMITTEE_PROFILE,
  COMMITTEE_ROUTES,
  COMMITTEE_WASTE_CARRIERS,
} from '../../constants/routes';
import { useChangeSideDrawer } from '../../hooks/useChangeSideDrawer';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';
import { usePrivilegeRole } from '../../hooks/usePrivilegeRole';
import { useHeaderStore, useTableStore, useUserStore } from '../../store';
import SearchInput from '../Inputs/SearchInput';
import { Text, TextTitle } from '../Text';
import CadastrAdminToolbar from './CadastrAdminToolbar';
import CadastrToolbar from './CadastrToolbar';
import HeaderLinks from './HeaderLinks';
import HeaderUser from './HeaderUser';

const StyledHeader = styled(AppBar)({
  justifyContent: 'center',
  background: 'white',
});

const buttonHideHeaderSx = {
  width: '100%',
  height: 20,
  bottom: 0,
  left: 0,
  borderRadius: 0,
};

const toolBarTitle = {
  [ADMIN_REGISTRY_APPLICATIONS]: 'Реестр разрешений',
  [ADMIN_REGISTRY_SUBJECTS_ROUTE]: 'Реестр субъектов',
  [ADMIN_REGISTRY_OBJECTS_ROUTE]: 'Реестр объектов обращения с отходами',
  [ADMIN_REGISTRY_TS]: 'Реестр ТС',
  [ADMIN_WASTE_MANAGEMENT_GUIDE]: 'Просмотр справочника типов обращения с отходами',
  [ADMIN_USERS_ROLES_ROUTE]: 'Пользователи и роли',
  [ADMIN_OPERATIONS_ROUTE]: 'Логирование действий в Кадастре',
  [ADMIN_TER_SCHEME_ROUTE]: 'Администрирование терсхемы',
  [ADMIN_WASTE_CADASTRE_ROUTE]: 'Кадастр отходов',
  [ADMIN_SEND_EMAIL_ROUTE]: 'Отправка E-mail',
  [ADMIN_SUBSCRIPTIONS_ROUTE]: 'Рассылки',
  [ADMIN_NOTIFICATIONS_ROUTE]: 'Уведомления',
  [CARRIER_PERSONAL_ACCOUNT_ROUTE]: 'Личный кабинет перевозчика',
  [CARRIER_GENERAL_INFO_ROUTE]: 'Общие сведения',
  [COMMITTEE_PROFILE]: 'Профиль комитета',
  [COMMITTEE_ANALYTICS]: 'Аналитика',
  [ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE]: 'Аналитика "Контроль перевозчиков строительных отходов ЛО"',
  [ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE]: 'Аналитика "Кадастр отходов"',
  [COMMITTEE_DEVIATION]: 'Отклонения',
  [COMMITTEE_WASTE_CARRIERS]: 'Перевозчики отходов',
  [COMMITTEE_MONITORING_VEHICLES]: 'Мониторинг ТС',
  [ADMIN_TYPE_ENTERPRISE_GUIDE]: 'Просмотр справочника видов предприятий',
  [ADMIN_HAZARD_CLASSES_GUIDE]: 'Просмотр справочника классов опасности',
  [ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE]: 'Просмотр справочника видов деятельности субъектов',
  [ADMIN_SUBJECT_TYPE_PAGE]: 'Просмотр справочника типов субъектов',
  [ADMIN_WASTE_CONDITION_ROUTE]: 'Просмотр справочника агрегатных состояний и физических форм отходов',
  [ADMIN_PREMISES_APPOINTMENT_GUIDE]: 'Просмотр справочника назначений помещений',
  [ADMIN_OKTMO_GUIDE]: 'Просмотр справочника ОКТМО',
  [ADMIN_FKKO_GUIDE]: 'Просмотр справочника ФККО',
};

export default function Header() {
  const { pathname } = useLocation();
  const { tableStore: { search, searchText }, setTableStore } = useTableStore();
  const { openDrawer, isOpenDrawer } = useChangeSideDrawer();
  const { headerStore, setHeaderStore } = useHeaderStore();
  const { userStore } = useUserStore();

  const hasPrivilege = usePrivilegeRole();

  const getCurrentUserLinks = () => {
    if (pathname.includes(CADASTR_ADMIN_ROUTE)) {
      return CADASTR_ADMIN_LINKS;
    }

    if (pathname.includes(CADASTRE_ROUTE)) {
      return CADASTR_LINKS;
    }

    if (userStore.type === 'subject') {
      return CARRIER_LINKS;
    }
  };

  const getCurrentCommitteeLinks = () => {
    const hideAnalytic = !hasPrivilege('analyst_control');

    const committeeRoutes = hideAnalytic
      ? COMMITTEE_ROUTES.filter((committeeRoute) => committeeRoute !== COMMITTEE_ANALYTICS)
      : COMMITTEE_ROUTES;

    if (committeeRoutes.includes(pathname)) {
      if (hideAnalytic) {
        return COMMITTEE_STAFF_LINKS
          .filter((committeeLink) => committeeLink.href !== COMMITTEE_ANALYTICS);
      }
      return COMMITTEE_STAFF_LINKS;
    }
  };

  const isNotSubject = userStore?.type !== 'subject';

  const changeVisibilityHeader = () => {
    setHeaderStore({ visibleHeader: !headerStore.visibleHeader });
  };

  const { isMobileTablet } = useCustomMediaQuery();

  const searchStyle = isMobileTablet ? { width: '100%' } : {};

  const headerMinHeight = useMemo(() => {
    let minHeight = '68px';
    if (headerStore.visibleHeader) {
      minHeight = '98px';
    } else if (search) minHeight = '68px';

    return minHeight;
  }, [headerStore, search]);

  return (
    <StyledHeader
      sx={{
        width: isNotSubject && isOpenDrawer ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
        minHeight: headerMinHeight,
      }}
      position="fixed"
    >
      {
        ((isMobileTablet && headerStore.visibleHeader) || !isMobileTablet)
        && (
          <Box
            px={isMobileTablet ? 0 : 3}
            py={1}
            display="flex"
            alignItems={isMobileTablet ? 'flex-start' : 'center'}
            justifyContent="space-between"
            flexDirection={isMobileTablet && 'column'}
            overflow="auto"
          >
            <Box
              display="flex"
              width={isMobileTablet ? '100%' : 'initial'}
              alignItems={isMobileTablet ? 'flex-start' : 'center'}
              gap={3}
              flexDirection={isMobileTablet && 'column'}
            >
              <Box display="flex" mx="auto" gap={2} alignItems="center">
                {!isOpenDrawer && (
                  <Box onClick={openDrawer} display="flex" alignItems="center">
                    <MenuIcon sx={{ color: 'black' }} />
                    <Text sx={{ color: 'black' }}>Меню</Text>
                  </Box>
                )}
                <LogoIcon />
              </Box>
              <HeaderLinks links={getCurrentCommitteeLinks()} />
              <HeaderLinks links={getCurrentUserLinks()} />
            </Box>
            <HeaderUser />
          </Box>
        )
      }
      <Toolbar
        sx={{
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          px: isMobileTablet ? 1 : 2,
        }}
      >
        {isMobileTablet && (
          <Box display="flex" mx="auto" gap={2} alignItems="center">
            {!isOpenDrawer && (
              <Box onClick={openDrawer} display="flex" alignItems="center">
                <MenuIcon sx={{ color: 'black' }} />
                <Text sx={{ color: 'black' }}>Меню</Text>
              </Box>
            )}
            <LogoIcon />
          </Box>
        )}
        <CadastrToolbar />
        <CadastrAdminToolbar />
        {(headerStore.visibleHeader
            || (!headerStore.visibleHeader && !isMobileTablet)
            || (!headerStore.visibleHeader && !search))
          && (
            <TextTitle h1>
              {toolBarTitle[pathname]}
            </TextTitle>
          )}
        {search && (
          <SearchInput
            sx={{ ml: 'auto', ...searchStyle }}
            onChange={(e, value) => setTableStore({ searchText: value })}
            value={searchText}
            onKeyEnter={() => search()}
          />
        )}
      </Toolbar>
      {isMobileTablet && (
        !ADMIN_ROUTES.includes(pathname) && (
          <Button onClick={changeVisibilityHeader} sx={buttonHideHeaderSx}>
            {headerStore.visibleHeader ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
        )
      )}
    </StyledHeader>
  );
}
