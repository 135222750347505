import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ADMIN_ROUTES,
  CADASTRE_ROUTE,
  CADASTR_ADMIN_ROUTE,
  CADASTR_ROUTES,
  COMMITTEE_ROUTE,
  INITIAL_RKO_47_ROUTE,
  PUBLIC_APPLICATION_ROUTE,
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
  PUBLIC_ROUTES,
  SUBJECT_ROUTES,
} from '../constants/routes';
import { getCommitteeRoutes } from '../constants/routes/committeeRoutes';
import { compareId, readToken } from '../helpers';
import {
  checkIsControlOrigin,
  checkIsPublicCadastreOrigin,
  checkIsPublicRKO47Origin,
} from '../helpers/browserLocation';
import { readLastRoute, writeLastRoute } from '../helpers/storage';
import { useUserStore } from '../store';
import { useGetRoleRedirectRoute } from './useGetRoleRedirectRoute';

const roleRoute = {
  [CADASTRE_ROUTE.slice(1)]: CADASTR_ROUTES,
  [CADASTR_ADMIN_ROUTE.slice(1)]: ADMIN_ROUTES,
  admin: ADMIN_ROUTES,
  inspector: ADMIN_ROUTES,
  supervisor_oiv: ADMIN_ROUTES,
  subject: SUBJECT_ROUTES,
};

export function useRoleRedirect() {
  const navigate = useNavigate();
  const { userStore } = useUserStore();
  const { pathname } = useLocation();
  const isCadastr = compareId(userStore?.partition, 'cadastre');
  const isControl = compareId(userStore?.partition, 'control');
  const isPublic = pathname.includes('public');

  const getRedirectRoute = useGetRoleRedirectRoute({ getRoleRoutes: getCommitteeRoutes });

  const getcadastradmin = () => (pathname.includes(CADASTR_ADMIN_ROUTE) ? CADASTR_ADMIN_ROUTE.slice(1) : '');
  const getCadastre = () => {
    if (
      !checkIsControlOrigin()
      && (pathname.includes(CADASTRE_ROUTE)
        || (checkIsPublicCadastreOrigin() && !userStore?.role_attributes?.attributes?.code)
        || isCadastr)
    ) {
      return CADASTRE_ROUTE.slice(1);
    }

    return '';
  };

  useEffect(() => {
    if (
      pathname.includes(PUBLIC_APPLICATION_ROUTE)
      || pathname.includes('blob')
      || pathname.includes(PUBLIC_LOGIN_ADMIN_TER_SCHEME_PAGE_ROUTE)
    ) return;

    const token = readToken();

    const isControlAndCadastr = (isCadastr && compareId(userStore.additional_partition, 'control'))
      || (isControl && compareId(userStore.additional_partition, 'cadastre'));

    const role = getcadastradmin()
      || getCadastre()
      || userStore?.role_attributes?.attributes?.code || userStore.type;

    if (checkIsPublicCadastreOrigin() || checkIsControlOrigin()) {
      if (!token && !isPublic && pathname !== COMMITTEE_ROUTE) {
        navigate(checkIsPublicCadastreOrigin() ? PUBLIC_LOGIN_CADASTR_PAGE_ROUTE : PUBLIC_LOGIN_CARRIER_PAGE_ROUTE);
      } else if (
        token
        && role
        && roleRoute[role]
        && !roleRoute[role].some((route) => pathname.includes(route))
      ) {
        if (role === 'inspector') {
          getRedirectRoute(userStore);
          return;
        }
        navigate(roleRoute[role][0]);
      }
      return;
    }

    const emptyPathname = pathname === '/';

    if (!token) {
      if (!PUBLIC_ROUTES.some((path) => pathname.includes(path)) || emptyPathname) {
        let redirectPath = checkIsPublicRKO47Origin() ? INITIAL_RKO_47_ROUTE : PUBLIC_LOGIN_CADASTR_PAGE_ROUTE;

        if (role === 'admin') redirectPath = PUBLIC_LOGIN_ADMIN_PAGE_ROUTE;
        if (role === 'inspector' || (userStore?.type === 'user' && !checkIsPublicRKO47Origin())) redirectPath = PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE;

        if (checkIsPublicCadastreOrigin()) redirectPath = PUBLIC_LOGIN_CADASTR_PAGE_ROUTE;
        else if (checkIsControlOrigin()) redirectPath = PUBLIC_LOGIN_CARRIER_PAGE_ROUTE;

        navigate(redirectPath, { replace: true });
      }
    } else if (token && !emptyPathname) {
      const currentRoleRoutes = roleRoute[role];

      const lastRoute = readLastRoute();

      const isPathnameForCurrentRole = isControlAndCadastr
        ? CADASTR_ROUTES.some((route) => pathname.includes(route)) || SUBJECT_ROUTES.some((route) => pathname.includes(route))
        : currentRoleRoutes?.some?.((route) => pathname.includes(route));

      const isLastRouteForCurrentRole = currentRoleRoutes?.some?.((route) => lastRoute === route);

      if (currentRoleRoutes && lastRoute && !isPathnameForCurrentRole && isLastRouteForCurrentRole) {
        return navigate(lastRoute);
      }

      if (currentRoleRoutes && !isPathnameForCurrentRole) {
        if (role === 'inspector') {
          getRedirectRoute(userStore);
          return;
        }

        navigate(currentRoleRoutes[0]);
      }

      if (token && !isPublic) writeLastRoute();
    } else if (token && !isPublic) {
      const lastRoute = readLastRoute();

      if (lastRoute) navigate(lastRoute);
    }
    /* eslint-disable-next-line */
  }, [pathname, userStore.id]);
}
