// @ts-nocheck
import cloneDeep from 'lodash.clonedeep';
import { useDebouncedCallback } from 'use-debounce';
import { METHODS_API } from '../../api/api';
import { cadastreReportHistoryRequest } from '../../api/cadastreApi';
import { getBuildingsHelper } from '../../helpers/cadastreForms/form1Helper';
import { getFormReportApprovalStandardWastesHelper } from '../../helpers/cadastreForms/form4Helper';
import { getLicensesHelper } from '../../helpers/cadastreForms/form5Helper';
import { getActualVolumesHelper } from '../../helpers/cadastreForms/form6Helper';
import { getReportTechnologiesHelper } from '../../helpers/cadastreForms/form7Helper';
import { getReportPlacingWasteHelper } from '../../helpers/cadastreForms/form8Helper';
import { REPORT_STATUS_NAMES } from '../../constants/lkCadastr/cadastrStorage';
import cadastreFormsPrepareFrontend from '../../helpers/cadastreForms/cadastreFormsPrepareFrontend';

const getUpdateReportData = (newReport) => ({
  id: newReport.id,
  approved_date: newReport.approved_date,
  send_date: newReport.send_date,
  comment_form_one: newReport.comment_form_one,
  comment_form_two: newReport.comment_form_two,
  comment_form_three: newReport.comment_form_three,
  comment_form_four: newReport.comment_form_four,
  comment_form_five: newReport.comment_form_five,
  comment_form_six: newReport.comment_form_six,
  comment_form_seven: newReport.comment_form_seven,
  comment_form_eight: newReport.comment_form_eight,
  comments: newReport.comments,
  is_license_unneeded: newReport.is_license_unneeded,
  is_document_unneeded: newReport.is_document_unneeded,
  is_delayed: newReport.is_delayed,
  report_status_id: newReport.report_status_id,
  filling_percentage: newReport.filling_percentage,
  percent_form_one: newReport.percent_form_one,
  percent_form_two: newReport.percent_form_two,
  percent_form_three: newReport.percent_form_three,
  percent_form_four: newReport.percent_form_four,
  percent_form_five: newReport.percent_form_five,
  percent_form_six: newReport.percent_form_six,
  percent_form_seven: newReport.percent_form_seven,
  percent_form_eight: newReport.percent_form_eight,
  report_building_ids: [], // form1
  report_waste_ids: [], // form2
  report_generation_ids: [], // form3
  report_approval_standard_ids: [], // form4
  report_license_ids: [], // form5
  report_actual_volume_ids: [], // form6
  report_technology_ids: [], // form7
  report_placing_waste_ids: [], // form8
  subject_id: newReport.subject_id,
});

export default function useGetCadastreForms({
  report,
  forms,
  setForms,
  setReport,
  setFormsCommentary,
  setInitialForms,
  setLoading,
  findReportStatuses,
  setCadastreStore,
  setCompletedForms,
  excludeLicense,
  updateReport,
}) {
  const getAllForms = async (
    reportId = null,
    additionalInfo = {},
  ) => {
    setLoading(true);

    const importToExistingReportId = additionalInfo.reportId;

    let newReport = forms;
    let newForms = {
      ...forms,
    };

    const mainReportId = reportId || report?.id;

    if (mainReportId) {
      newReport = await cadastreReportHistoryRequest({
        method: METHODS_API.GET,
        id: mainReportId,
      });

      const { allBuildings: buildings, form3ReportWastes } = await getBuildingsHelper(newReport);
      const reportApprovalStandards = await getFormReportApprovalStandardWastesHelper(newReport);
      const reportLicenses = await getLicensesHelper(newReport);
      const actualVolumes = await getActualVolumesHelper(newReport);

      const reportTechnology = getReportTechnologiesHelper(newReport);
      const reportPlacingWaste = getReportPlacingWasteHelper(newReport);

      newForms = {
        ...forms,
        form1: buildings?.length ? { items: buildings } : forms.form1,
        form3: form3ReportWastes?.length ? { items: form3ReportWastes, hasAcceptingWastes: true } : forms.form3,
        form4: reportApprovalStandards?.documents?.length ? reportApprovalStandards : forms.form4,
        form5: reportLicenses?.licenses?.length ? reportLicenses : forms.form5,
        form6: actualVolumes?.actualVolumes?.length ? actualVolumes : forms.form6,
        form7: reportTechnology || forms.form7,
        form8: reportPlacingWaste || forms.form8,
      };

      const newCompletedForms = cadastreFormsPrepareFrontend({
        setCompletedForms, forms: newForms, excludeLicense, errorSetter: setCadastreStore,
      });

      if (importToExistingReportId) {
        const reportStatusFormation = findReportStatuses(REPORT_STATUS_NAMES.formation);

        newReport.id = importToExistingReportId;

        // we need to update data, because we work with status (formation)
        newReport.code = '';
        newReport.barcode_url = '';
        newReport.approved_date = '';
        newReport.send_date = '';
        newReport.report_number = '';
        newReport.transmittal_mail_url = '';
        newReport.transmittal_mail_signed_url = '';
        newReport.application_date = '';
        newReport.created_at = '';
        newReport.deleted_at = '';
        newReport.report_status_id = reportStatusFormation.id;
        newReport.report_status_attributes = { ...reportStatusFormation };
        newReport.report_status_attributes.attributes = { ...reportStatusFormation };
        newReport.comment_form_one = '';
        newReport.comment_form_two = '';
        newReport.comment_form_three = '';
        newReport.comment_form_four = '';
        newReport.comment_form_five = '';
        newReport.comments = [];
        newReport.comment_form_six = '';
        newReport.comment_form_seven = '';
        newReport.comment_form_eight = '';

        await updateReport({
          newCompletedForms,
          newForms,
          isImportToExistingReport: true,
          data: getUpdateReportData(newReport),
          reportId: importToExistingReportId,
          getAllFormsCallback: getAllForms,
        });

        getAllForms(importToExistingReportId);

        return;
      }
      if (additionalInfo.returnInfo) return { newForms, newReport };

      if (!importToExistingReportId) setReport(newReport);
    }

    if (newReport?.comments?.[0]?.id) setFormsCommentary(newReport.comments);
    else setFormsCommentary([]);

    setLoading(false);

    setForms(cloneDeep(newForms));
    setInitialForms(cloneDeep(newForms));
  };

  const getDebouncedCallback = useDebouncedCallback(getAllForms, 150);

  return { getDebouncedCallback, getAllForms };
}
