import { Box, styled } from '@mui/material';

const ModalScrollBox = styled(Box)({
  display: 'grid',
  maxHeight: '250px',
  overflow: 'auto',
  paddingLeft: '2px',
});

export default ModalScrollBox;
