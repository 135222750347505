import { useEffect } from 'react';
import { cadastreFillingRecommendationsRequest, cadastreRecommendationRequest } from '../../api/cadastreApi';
import { createUrlFromLocalBlob } from '../../helpers/fileHelper';
import { useApi } from '../useApi';

export function useLoadCadastreRecommendation({ setFiles }) {
  const { defaultRequest: fillingRecommendationApi, isLoading } = useApi({
    request: cadastreFillingRecommendationsRequest,
    skipError: true,
  });

  useEffect(() => {
    fillingRecommendationApi().then(async (data) => {
      const blobFiles = [];
      for await (const item of data) {
        const blobFile = await cadastreRecommendationRequest(item.id);
        const blobUrl = await createUrlFromLocalBlob(blobFile?.data);

        blobFiles.push({
          file_url: blobUrl,
          id: item.id,
          name: item.name,
        });
      }
      setFiles(blobFiles);
    });
  }, []);

  return { isLoading };
}
