// @ts-nocheck
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { deleteApplicationRequest } from '../../api/applicationsApi';
import { getSubjectRequest } from '../../api/subjectApi';
import { deleteVehicleRequest } from '../../api/vehiclesApi';
import CarrierTable from '../../components/Carrier/CarrierTable';
import { OWNERSHIP_TYPE_TRANSLATE } from '../../constants';
import { APPLICATIONS_URL, VEHICLES_URL } from '../../constants/urls';
import { formatDate } from '../../helpers/dateHelper';
import { useUserStore } from '../../store';

const vehicleTableColumns = [
  { headerTitle: 'ЕГРЗ ТС (Номер ТС)', field: 'egrz' },
  { headerTitle: 'ЕГРЗ прицепа', field: 'egrz_trailer' },
  { headerTitle: 'Марка ТС', field: 'brand' },
  { headerTitle: 'Модель ТС', field: 'model' },
  {
    headerTitle: 'Тип владения',
    field: 'ownership_type',
    getField: ({ ownership_type }) => OWNERSHIP_TYPE_TRANSLATE[ownership_type],
  },
  { headerTitle: 'ID БНСО', field: 'guid_bnso' },
  {
    headerTitle: 'Подключение к РИНС ЛО',
    field: 'is_connected_rins',
    getField: ({ is_connected_rins }) => (is_connected_rins ? 'Да' : 'Нет'),
  },
];

const applicationTableColumns = [
  {
    headerTitle: 'Дата выдачи разрешений',
    field: 'created_at',
    getField: (row) => formatDate({ date: row.created_at }),
  },
  {
    headerTitle: 'ЕГРЗ ТС (Номер ТС)',
    field: 'egrz',
    getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz,
  },
  {
    headerTitle: 'Образователь отходов',
    field: 'waste_generator_objective_id',
    getField: ({ application_objectives_attributes }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Объект образователя отходов',
    field: 'recipient_object_name',
    getField: ({
      application_objectives_attributes,
    }) => application_objectives_attributes
      ?.[0]?.attributes?.objective_attributes?.attributes?.name,
  },
];

export default function CarrierPersonalAccountPage() {
  const { userStore } = useUserStore();

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box pt={1} display="flex" justifyContent="space-between" flexWrap={tabletSizeAndLower && 'wrap'}>
      <CarrierTable
        title="Автопарк"
        search={(params, setTableStore) => getSubjectRequest(
          {
            id: userStore.id, url: VEHICLES_URL, params, setTableStore,
          },
        )}
        deleteMessage="Транспорт удалён"
        getDeleteObjectName={({ egrz }) => `транспорт c ЕГРЗ ${egrz}`}
        deleteItem={deleteVehicleRequest}
        type="vehicle"
        buttonText="Добавить транспортное средство"
        tableColumns={vehicleTableColumns}
      />
      <CarrierTable
        title="Разрешение"
        type="application"
        deleteMessage="Разрешение удалёно"
        getDeleteObjectName={(row) => `разрешение ${row.id}`}
        search={(params, setTableStore) => getSubjectRequest(
          {
            id: userStore.id, url: APPLICATIONS_URL, params, setTableStore,
          },
        )}
        deleteItem={deleteApplicationRequest}
        buttonText="Создать заявление на получение разрешения"
        tableColumns={applicationTableColumns}
      />
    </Box>
  );
}
