import React from 'react';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery';

const innerButtonSx = {
  position: 'relative',
  borderRadius: '8px',
  height: '58px',
  fontSize: '16px',
};

export default function CustomButton({
  isLoading,
  children,
  disabled,
  onClick,
  color,
  variant = 'contained',
  sx,
  startIcon,
  endIcon,
  download,
  href,
  target,
  htmlFor,
  component,
}) {
  const { isMobileTablet } = useCustomMediaQuery();

  const mobileTabletStyle = isMobileTablet ? { height: 42, fontSize: 14 } : {};

  return (
    <Button
      sx={{ ...innerButtonSx, ...sx, ...mobileTabletStyle }}
      download={download}
      component={component}
      htmlFor={htmlFor}
      target={target}
      href={href}
      disabled={Boolean(disabled) || Boolean(isLoading)}
      color={color}
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      variant={variant}
      onClick={onClick}
    >
      {isLoading && <CircularProgress sx={{ position: 'absolute' }} size="1rem" />}
      <Box visibility={isLoading && 'hidden'}>
        {typeof children === 'string' ? (
          <Typography fontSize={isMobileTablet ? 14 : undefined}>{children}</Typography>
        ) : children}
      </Box>
    </Button>
  );
}
