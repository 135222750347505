import React, { memo, useRef } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import trackIcon from '../../assets/tsMapIcon.svg';
import { createLIcon } from '../../hooks/useClusterGroup';

const updateFunction = (prevState, nextState) => {
  if (prevState.data.place?.x !== nextState.data.place?.x) {
    return false;
  }

  if (prevState.currentZoom !== nextState.currentZoom) return false;

  if (prevState.boundsMap !== nextState.boundsMap) return false;

  return prevState.data.place?.x === nextState.data.place?.x;
};

function TransportTracking({
  data = {}, onClickMarker, currentZoom, boundsMap,
}) {
  const ref = useRef();

  if (!(data?.place?.x && data?.place?.y)) return null;

  return (
    <Marker
      ref={ref}
      icon={createLIcon(trackIcon, 'vehicle-marker')}
      position={L.latLng(data?.place?.x, data?.place?.y)}
      eventHandlers={{
        click: (e) => {
          // eslint-disable-next-line no-underscore-dangle
          setTimeout( // todo timed solution for map focus
            // eslint-disable-next-line no-underscore-dangle
            () => {
              e?.target?._map?.setView([e.latlng.lat, e.latlng.lng]);
            },
            0,
          );
          onClickMarker?.(data);
          return e;
        },
      }}
    >
      {currentZoom > 13 && (boundsMap?.contains?.(L.latLng(data?.place?.x, data?.place?.y))) && (
        <Tooltip direction="top" offset={[2, 0]} opacity={0.8} permanent>
          {data.egrz || data.car_handler}
        </Tooltip>
      )}
    </Marker>
  );
}

export default memo(TransportTracking, updateFunction);
