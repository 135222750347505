import { compareId } from '../compareId';
import { METHODS_API } from '../../api/api';
import {
  cadastreForm6ReportActualVolumesRequest,
  cadastreReportActualVolumesRequest,
  cadastreTransportedWastesRequest,
} from '../../api/cadastreApi';
import {
  FORM6_TRANSPORTED_WASTE,
  getInitialForm6ActualVolume,
  getInitialForm6TransportedWaste,
} from '../../constants/lkCadastr/cadastrForm6';
import { valueToString } from '../stringHelper';

export const getActualVolumesHelper = async (report) => {
  if (report?.report_actual_volume_ids?.length) {
    const allActualVolumes = [];

    const reportActualVolumesByIds = await cadastreReportActualVolumesRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    reportActualVolumesByIds.forEach((actualVolume) => {
      const additionalFormItems = {};

      const transportedWastes = actualVolume.transported_wastes_attributes;

      if (transportedWastes.length) {
        additionalFormItems[FORM6_TRANSPORTED_WASTE] = transportedWastes
          .map((transportedWaste) => {
            const transportedWasteAttributes = transportedWaste?.attributes;
            // eslint-disable-next-line max-len
            const wasteTypeAttribute = transportedWasteAttributes?.waste_type_attributes?.attributes;

            const waste_management_type_ids = [];

            if (transportedWasteAttributes?.waste_management_types_attributes?.length) {
              transportedWasteAttributes?.waste_management_types_attributes.forEach((waste_management_type) => {
                waste_management_type_ids.push({
                  ...waste_management_type,
                  ...waste_management_type?.attributes,
                });
              });
            }

            return ({
              ...getInitialForm6TransportedWaste(),
              ...transportedWaste,
              ...transportedWasteAttributes,
              actual_volume_transported_waste: valueToString(transportedWasteAttributes?.actual_volume_transported_waste),
              waste_type_id: {
                ...transportedWasteAttributes?.waste_type_attributes,
                ...transportedWasteAttributes?.waste_type_attributes?.attributes,
                value: transportedWasteAttributes?.waste_type_attributes?.id || '',
                label: wasteTypeAttribute?.code ? `${wasteTypeAttribute?.code} ${wasteTypeAttribute?.name}` : '0',
                name: wasteTypeAttribute?.name || '0',
              },
              waste_management_type_ids,
            });
          });
      }

      allActualVolumes.push({
        ...getInitialForm6ActualVolume(),
        ...actualVolume,
        ...additionalFormItems,
        objective_id: {
          ...actualVolume?.objective_attributes,
          ...actualVolume?.objective_attributes?.attributes,
        },
        accept_subject_id: {
          ...actualVolume?.accept_subject_attributes,
          ...actualVolume?.accept_subject_attributes?.attributes,
          name: actualVolume?.accept_subject_attributes?.attributes?.name || (actualVolume?.importfromxlsx ? '' : '0'),
        },
        transfer_subject_id: {
          ...actualVolume?.transfer_subject_attributes,
          ...actualVolume?.transfer_subject_attributes?.attributes,
          name: actualVolume?.transfer_subject_attributes?.attributes?.name || (actualVolume?.importfromxlsx ? '' : '0'),
        },
      });
    });

    return { actualVolumes: allActualVolumes };
  }
};

export const deleteForm6ActualVolume = async ({ actualVolumeId }) => {
  const deletedActualVolume = await cadastreReportActualVolumesRequest({
    method: METHODS_API.DELETE,
    id: actualVolumeId,
  });

  return deletedActualVolume?.id;
};

export const deleteForm6TransportedWaste = async ({ transportedWasteId }) => {
  const deletedTransportedWaste = await cadastreTransportedWastesRequest({
    method: METHODS_API.DELETE,
    id: transportedWasteId,
  });

  return deletedTransportedWaste?.id;
};

export const deleteForm6Entities = async ({
  initialForms,
  forms,
}) => {
  // eslint-disable-next-line no-restricted-syntax
  for await (const initialActualVolumes of initialForms.form6.actualVolumes) {
    const initialActualVolumeId = initialActualVolumes?.id;

    // eslint-disable-next-line no-continue
    if (!initialActualVolumeId) continue;

    const existingActualVolume = forms.form6.actualVolumes
      .find((actualVolume) => compareId(actualVolume.id, initialActualVolumeId));

    if (!existingActualVolume) {
      await cadastreReportActualVolumesRequest({
        method: METHODS_API.DELETE,
        id: initialActualVolumeId,
      });
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for await (const initialTransportedWaste of initialActualVolumes[FORM6_TRANSPORTED_WASTE]) {
        const initialTransportedWasteId = initialTransportedWaste.id;

        // eslint-disable-next-line no-continue
        if (!initialTransportedWasteId) continue;

        const existingActualTransportedWaste = existingActualVolume[FORM6_TRANSPORTED_WASTE]
          .find((actualTransportedWaste) => compareId(
            actualTransportedWaste.id,
            initialTransportedWasteId,
          ));

        if (!existingActualTransportedWaste) {
          await cadastreTransportedWastesRequest({
            method: METHODS_API.DELETE,
            id: initialTransportedWasteId,
          });
        }
      }
    }
  }
};

export const saveOneForm6ActualVolume = async ({ actualVolume, reportId }) => {
  let actualVolumeId = actualVolume.id;

  try {
    const newActualVolume = await cadastreReportActualVolumesRequest({
      method: actualVolumeId ? METHODS_API.PUT : METHODS_API.POST,
      data: {
        transfer_subject_id: actualVolume.transfer_subject_id?.id || '',
        transfer_document_number: actualVolume.transfer_document_number,
        transfer_document_date: valueToString(actualVolume.transfer_document_date),
        objective_id: actualVolume.objective_id?.id,
        accept_subject_id: actualVolume.accept_subject_id?.id,
        accept_document_number: actualVolume.accept_document_number,
        accept_document_date: valueToString(actualVolume.accept_document_date),
        report_history_id: reportId,
      },
      id: actualVolumeId,
    });

    actualVolumeId = newActualVolume?.id;
  } catch (e) {
    console.error(e);
  }

  return { actualVolumeId };
};

export const saveOneForm6TransportedWaste = async ({ actualVolume, transportedWaste, reportId }) => {
  let actualVolumeId = actualVolume.id;
  let transportedWasteId = transportedWaste.id;

  if (!actualVolumeId) {
    const { actualVolumeId: newActualVolumeId } = await saveOneForm6ActualVolume({ actualVolume, reportId });

    if (newActualVolumeId) actualVolumeId = newActualVolumeId;
  }

  try {
    const form6TransportedWasteData = {
      name: transportedWaste.name,
      waste_type_id: transportedWaste.waste_type_id?.id,
      waste_management_type_ids: transportedWaste.waste_management_type_ids.map(({ id }) => id),
      actual_volume_transported_waste: valueToString(transportedWaste?.actual_volume_transported_waste),
      report_actual_volume_id: actualVolumeId,
    };

    const newTransportedWaste = await cadastreTransportedWastesRequest({
      data: form6TransportedWasteData,
      method: transportedWasteId ? METHODS_API.PUT : METHODS_API.POST,
      id: transportedWasteId,
    });

    transportedWasteId = newTransportedWaste?.id;
  } catch (e) {
    console.error(e);
  }

  return { actualVolumeId, transportedWasteId };
};

export const createUpdateForm6Entities = async ({ forms, reportId }) => {
  const form6Info = forms.form6;

  // eslint-disable-next-line no-restricted-syntax
  for await (const actualVolume of form6Info.actualVolumes) {
    try {
      let actualVolumeId = actualVolume.id;

      const newActualVolume = await cadastreReportActualVolumesRequest({
        method: actualVolumeId ? METHODS_API.PUT : METHODS_API.POST,
        data: {
          transfer_subject_id: actualVolume.transfer_subject_id?.id || '',
          transfer_document_number: actualVolume.transfer_document_number,
          transfer_document_date: valueToString(actualVolume.transfer_document_date),
          objective_id: actualVolume.objective_id?.id,
          accept_subject_id: actualVolume.accept_subject_id?.id,
          accept_document_number: actualVolume.accept_document_number,
          accept_document_date: valueToString(actualVolume.accept_document_date),
          report_history_id: reportId,
        },
        id: actualVolumeId,
      });

      actualVolumeId = newActualVolume?.id;

      // eslint-disable-next-line no-continue
      if (!actualVolumeId) continue;

      // eslint-disable-next-line no-restricted-syntax
      for await (const transportedWaste of actualVolume[FORM6_TRANSPORTED_WASTE]) {
        try {
          const form6TransportedWasteData = {
            name: transportedWaste.name,
            waste_type_id: transportedWaste.waste_type_id?.id,
            waste_management_type_ids: transportedWaste.waste_management_type_ids.map(({ id }) => id),
            actual_volume_transported_waste: valueToString(transportedWaste?.actual_volume_transported_waste),
            report_actual_volume_id: actualVolumeId,
          };

          const transportedWasteId = transportedWaste.id;

          await cadastreTransportedWastesRequest({
            data: form6TransportedWasteData,
            method: transportedWasteId ? METHODS_API.PUT : METHODS_API.POST,
            id: transportedWasteId,
          });
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveForm6ReportActualVolumes = async ({
  forms,
  reportId,
  isImportToExistingReport,
}) => {
  const reportActualVolumes = forms.form6.actualVolumes.map((actualVolume) => {
    const actualVolumeId = actualVolume.id;

    const reportActualVolumeData = {
      transfer_subject_id: actualVolume.transfer_subject_id?.id || '',
      transfer_document_number: actualVolume.transfer_document_number,
      transfer_document_date: valueToString(actualVolume.transfer_document_date),
      objective_id: actualVolume.objective_id?.id,
      accept_subject_id: actualVolume.accept_subject_id?.id,
      accept_document_number: actualVolume.accept_document_number,
      accept_document_date: valueToString(actualVolume.accept_document_date),
      transported_wastes: actualVolume[FORM6_TRANSPORTED_WASTE].map((transportedWaste) => {
        const transportedWasteId = transportedWaste.id;

        const form6TransportedWasteData = {
          name: transportedWaste.name,
          waste_type_id: transportedWaste.waste_type_id?.id,
          waste_management_type_ids: transportedWaste.waste_management_type_ids.map(({ id }) => id),
          actual_volume_transported_waste: valueToString(transportedWaste?.actual_volume_transported_waste),
          report_actual_volume_id: actualVolumeId,
        };

        if (!isImportToExistingReport && transportedWasteId) form6TransportedWasteData.id = transportedWasteId;

        return form6TransportedWasteData;
      }),
      report_history_id: reportId,
    };

    if (!isImportToExistingReport && actualVolumeId) reportActualVolumeData.id = actualVolumeId;

    return reportActualVolumeData;
  });

  const responseReportActualVolume = await cadastreForm6ReportActualVolumesRequest({
    method: METHODS_API.POST,
    data: reportActualVolumes,
  });

  return responseReportActualVolume?.data;
};
