import { compareId } from '../../helpers';
import { getForm1WasteProducts } from '../../helpers/cadastrHelper';
import { precisionAdd } from '../../helpers/numberHelper';
import { WASTE_CONDITION_ENUM } from '../enums';

export const getInitialComponentCompositions = () => ({
  randId: Math.random(),
  component_name: '',
  mass_fraction: 0,
});

// todo should use addTotalCadastreCount function
// const componentCompositionsFields = [
//   'component_name',
//   'mass_fraction',
// ];
//
// const form2ItemFields = [
//   'waste_approval_date',
//   'is_developed_passport',
//   'is_passport_approved',
//   'is_known_component_composition',
//   'name_technological_process',
//   'name_original_product',
//   WASTE_CONDITION_ENUM,
// ];

export const getInitialForm2Item = () => ({
  waste_approval_date: '',
  is_developed_passport: true,
  is_passport_approved: false,
  is_known_component_composition: false,
  name_technological_process: '',
  name_original_product: '',
  component_compositions: [getInitialComponentCompositions()],
  [WASTE_CONDITION_ENUM]: '',
});

export const HAZARD_CLASSES = ['one', 'two', 'three', 'four'];

export const filterComponentComposition = (data) => (!data ? [] : data
  .filter(({ code }) => HAZARD_CLASSES.includes(code?.hazard_class)));

export const form2PercentGetterItem = (data, addAllDataLength, { newErrors, waste }) => {
  let totalFilledCount = 0;
  let hasError = false;

  const addTotalCount = (count) => {
    totalFilledCount += count;
  };

  if (data.is_developed_passport) {
    addAllDataLength(4);

    if (data[WASTE_CONDITION_ENUM]?.id || compareId(data[WASTE_CONDITION_ENUM]?.name, 0)) addTotalCount(1);
    else hasError = true;

    if (data.waste_approval_date || compareId(data.waste_approval_date, 0)) addTotalCount(1);
    else hasError = true;

    if (data.name_technological_process) addTotalCount(1);
    else hasError = true;

    if (data.name_original_product) addTotalCount(1);
    else hasError = true;
  }

  if (data.is_known_component_composition) {
    data.component_compositions.forEach(({ component_name, mass_fraction }) => {
      addAllDataLength(2);

      if (component_name) addTotalCount(1);
      else hasError = true;

      if (mass_fraction) addTotalCount(1);
      else hasError = true;
    });

    const sumMassFraction = data.component_compositions
      ?.reduce?.((prevCount, componentItem) => precisionAdd(prevCount, componentItem?.mass_fraction), 0) || 0;

    if (sumMassFraction !== 0 && sumMassFraction < 100) {
      addAllDataLength(1);
      hasError = true;
    }
  }

  if (hasError && window?.showCadastreErrors) {
    newErrors.push(`waste${waste.randId}`);
  }

  return totalFilledCount;
};

export const form2PercentGetter = (forms, { errorSetter }) => {
  const { items: buildings } = forms.form1;

  const wasteProducts = getForm1WasteProducts(buildings);
  const isHasOnlyFiveHazardClasses = wasteProducts?.length && wasteProducts.every(({ code }) => code?.hazard_class === 'five');

  const filteredWastes = filterComponentComposition(wasteProducts);
  let allDataLength = 0;

  const addAllDataLength = (count) => {
    allDataLength += count;
  };

  if (isHasOnlyFiveHazardClasses) return 100;

  if (filteredWastes.length === 0) return 0;

  if (filteredWastes.every((item) => !item.form2Waste.is_developed_passport)) return 100;

  const newErrors = [];

  const totalFilledCount = filteredWastes.reduce((prev, waste) => {
    const { form2Waste } = waste;
    const totalItemPercent = form2PercentGetterItem(form2Waste, addAllDataLength, { newErrors, waste });
    return prev + totalItemPercent;
  }, 0);

  errorSetter({ errorForm2: newErrors });

  return Math.floor((totalFilledCount / allDataLength) * 100);
};
