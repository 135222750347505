import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomTable from '../../../components/Tables/CustomTable';
import { useTableSearch } from '../../../hooks';

const initialTableObjectAssignmentGuide = [
  { headerTitle: 'Идентификатор', field: 'id' },
  { headerTitle: 'Наименование', field: 'label' },
];

const objectAssignmentData = [
  { id: 'Жилые дома', label: 'Жилые дома' },
  { id: 'Общественные и административно-бытовые помещения', label: 'Гостиницы, общежития, пансионаты, д/о, мотели' },
  { id: 'ДОУ', label: 'Детсады, ясли' },
  { id: 'Объекты спортивного назначения', label: 'Стадионы, ФОК, бассейны, спортзалы, спортшколы' },
  { id: 'Здравоохранение', label: 'Больницы, родильные дома, поликлиники, амбулатории, госпитали, морги' },
  { id: 'Образование', label: 'Школы, интернаты, дом ребенка, ПТУ, училища, Вузы, амбулатории' },
  { id: 'Административно-офисные помещения', label: 'МФК, офисы, деловые центры, типографии, почта' },
  { id: 'Социально-бытовые объекты', label: 'Прачечные, химчистки, душевые, бани,парикмахерские, общественные туалеты' },
  { id: 'Объекты торгового назначения', label: 'Магазин, торговые центры, торговые залы, аптеки, вещевой рынок, ларьки, торговые палатки' },
  { id: 'Досугово-развлекательные учреждения', label: 'Дом культуры, интернет-кафе, дискотеки, клубы' },
  { id: 'Культурные объекты', label: 'Театры, кинотеатры, музеи, постоянно действующие выставочные комплексы (центры)' },
  { id: 'Предприятия общественного питания', label: 'Столовые, кафе, рестораны, пиццерии, хлебопекарня' },
  { id: 'Объекты производственного назначения', label: 'Заводы, цеха, станции, тепловые пункты, депо, гаражи,ремонтно-механические мастерские, склады, АЗС,СТО' },
  { id: 'Объекты городской инфраструктуры', label: 'Вокзалы, парки, оранжереи, теплицы, сельхоз.рынки' },
  { id: 'Транспортная инфраструктура', label: 'Дороги, эстакады, мосты, тоннели, путепроводы, жел.дор.пути' },
  { id: 'Религиозные сооружения', label: 'Храм, церковь, синагога, мечеть, монастырь, часовня, кирха' },
  { id: 'Объекты защиты населения', label: 'Полиция, мчс, военные, ГИБДД, вневедомственная охрана, ЧОПы, военная полиция' },
  { id: 'Объекты складского назначения', label: 'Склады, навесы, ангары, сараи' },
  { id: 'Нежилые помещения', label: 'Объекты заявлены как нежилые помещение без уточнения дальнейшего назначения' },
  { id: 'Комплексная застройка', label: 'Большая застройка, куда попадают  объекты из разных категорий' },
  { id: 'Временные объекты', label: 'Бытовки, строительные городки, объекты механизации' },
  { id: 'Объекты незавершенного строительства', label: 'Незавершенные объекты для проведения отделочных и пуско-наладочных работ' },
];

const getObjectAssignment = async () => objectAssignmentData;

export default function PremisesAppointmentsGuide() {
  const [tableData, setTableData] = useState([]);

  const { isLoading } = useTableSearch({
    search: getObjectAssignment,
    setter: setTableData,
  });

  return (
    <Box>
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={initialTableObjectAssignmentGuide}
        containerSx={{ maxHeight: '100%' }}
      />
    </Box>
  );
}
