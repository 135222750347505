// @ts-nocheck
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import initialUserStore from './userStore';
import {
  CADASTRE_STORE_NAME,
  HEADER_STORE_NAME,
  MODAL_STORE_NAME,
  TABLE_STORE_NAME,
  USER_STORE_NAME,
} from './storeName';
import { deleteToken } from '../helpers';
import { logoutRequest } from '../api/userApi';
import { SIGN_OUT_SUBJECT_URL, SIGN_OUT_USER_URL } from '../constants/urls';
import initialTableStore from './tableStore';
import initialModalStore from './modalStore';
import initialHeaderStore from './headerStore';
import {
  CADASTR_ADMIN_ROUTE,
  CADASTRE_ROUTE,
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
} from '../constants/routes';
import initialCadastreStore from './cadastreStore';

const StoreContext = React.createContext();

export const initialStore = {
  [CADASTRE_STORE_NAME]: initialCadastreStore,
  [USER_STORE_NAME]: initialUserStore,
  [TABLE_STORE_NAME]: initialTableStore,
  [MODAL_STORE_NAME]: initialModalStore,
  [HEADER_STORE_NAME]: initialHeaderStore,
};

// Global store
export function Store({ children }) {
  const [store, setStore] = useState(initialStore);

  const value = useMemo(() => ({ store, setStore }), [store]);

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

export const useStore = (storeName) => {
  const { store, setStore } = useContext(StoreContext);

  const changeStore = (newStoreValue) => {
    setStore((prevStore) => {
      const finalNewStoreValue = typeof newStoreValue === 'function' ? newStoreValue(prevStore[storeName]) : newStoreValue;

      return (({
        ...prevStore,
        [storeName]: {
          ...prevStore[storeName],
          ...finalNewStoreValue,
        },
      }));
    });
  };

  return {
    // example userStore
    [`${storeName}Store`]: store[storeName],
    // example setUserStore
    [`set${storeName[0].toUpperCase()}${storeName.slice(1)}Store`]: changeStore,
  };
};

export default StoreContext;

export const useUserStore = () => useStore(USER_STORE_NAME);
export const useCadastreStore = () => useStore(CADASTRE_STORE_NAME);
export const useTableStore = () => useStore(TABLE_STORE_NAME);
export const useModalStore = () => useStore(MODAL_STORE_NAME);
export const useHeaderStore = () => useStore(HEADER_STORE_NAME);

export const useLogoutStore = () => {
  const { store, setStore } = useContext(StoreContext);
  const navigate = useNavigate();

  return () => {
    let url = SIGN_OUT_USER_URL;

    try {
      if (store?.user?.type === 'subject' || store?.user?.subject_type_attributes?.id !== undefined) url = SIGN_OUT_SUBJECT_URL;

      if (url) logoutRequest(url);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    deleteToken();

    if (window.location.pathname.includes(`${CADASTR_ADMIN_ROUTE}/`)) {
      navigate(PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE);
    } else if (window.location.pathname.includes(`${CADASTRE_ROUTE}/`)) {
      navigate(PUBLIC_LOGIN_CADASTR_PAGE_ROUTE);
    } else if (store?.user?.type === 'subject') {
      navigate(PUBLIC_LOGIN_CARRIER_PAGE_ROUTE);
    } else if (store?.user?.role_attributes?.attributes?.code.includes('admin')) {
      navigate(PUBLIC_LOGIN_ADMIN_PAGE_ROUTE);
    } else {
      navigate(PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE);
    }
    setStore(initialStore);
    window.location.reload();
  };
};
