import React from 'react';
import { Box } from '@mui/material';
import CarrierEdit from './CarrierEdit';
import { TextTitle } from '../Text';

export default function CarrierFieldsList({
  error, fields, userInfo, editField, changeEdit, makeRequest, title, onChange,
}) {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <TextTitle h3>{title}</TextTitle>
      <Box display="flex" alignItems="flex-start" gap={3} flexWrap="wrap" flexDirection="column">
        {fields.map(({
          title: infoTitle, field, request, multiline, sx, getField,
        }) => (
          <CarrierEdit
            key={field}
            sx={sx}
            request={request}
            editField={editField}
            changeEdit={changeEdit}
            getField={getField}
            makeRequest={() => makeRequest([field])}
            error={error}
            onChange={onChange}
            field={field}
            userInfo={userInfo}
            title={infoTitle}
            multiline={multiline}
          />
        ))}
      </Box>
    </Box>
  );
}
