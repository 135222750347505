import api, { METHODS_API } from './api';
import {
  APPLICATION_OBJECTIVES_URL,
  APPLICATION_URL, APPLICATIONS_TER_SCHEME_URL, APPLICATIONS_URL, ENUMS_URL,
} from '../constants/urls';

export const getApplicationsRequest = (params, setTableStore) => api({
  url: APPLICATIONS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const getApplicationsTerSchemeRequest = (params, setTableStore) => api({
  url: APPLICATIONS_TER_SCHEME_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const getApplicationRequest = (id) => api(
  { url: `${APPLICATIONS_URL}/${id}` },
  { isEntityAttributes: true },
);

export const applicationQRCodeRequest = (id) => api({
  url: `${APPLICATIONS_URL}/${id}/file_download`,
  method: METHODS_API.GET,
  responseType: 'blob',
  params: { file: 'qr_code' },
}, { restUrl: '' });

export const applicationDocumentRequest = (id) => api({
  url: `${APPLICATIONS_URL}/${id}/file_download`,
  method: METHODS_API.GET,
  responseType: 'blob',
  params: { file: 'document' },
}, { restUrl: '' });

export const applicationObjectivesDocumentRequest = (id) => api({
  url: `${APPLICATION_OBJECTIVES_URL}/${id}/file_download`,
  method: METHODS_API.GET,
  responseType: 'blob',
  params: { file: 'document' },
}, { restUrl: '' });

export const updateApplicationRequest = (id, data, headers) => api({
  method: 'put', url: `${APPLICATIONS_URL}/${id}`, data, headers,
});

export const updateApplicationObjectivesRequest = (id, data) => api({
  method: 'put',
  url: `${APPLICATION_URL}_objectives/${id}`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data,
});

export const deleteApplicationRequest = (id) => api({ method: 'delete', url: `${APPLICATIONS_URL}/${id}` });

export const createApplicationRequest = (application) => api({
  method: 'post',
  url: APPLICATIONS_URL,
  data: { application },
}, { isEntityAttributes: true });

export const getApplicationEnumsRequest = (enum_field, params, filter) => api(
  { url: `${APPLICATIONS_URL}${ENUMS_URL}`, params },
  { enum_field, filter },
);
