import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useApi, usePushNotification } from '../hooks';
import { formatToServerDate, getTimeZoneDate } from '../helpers/dateHelper';
import CustomDatePicker from './CustomDatePicker';
import { formValidator } from '../helpers';
import { getVehiclesPosition } from '../api/vehiclesApi';
import CustomButton from './Buttons/CustomButton';
import { TextTitle } from './Text';
import { ReactComponent as MonitoringIcon } from '../assets/monitoring_ts_icon.svg';

function VehicleTrackControlComponent({
  guid_bnso, setter, boundVehicle,
}) {
  const pushNotification = usePushNotification();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [error, setError] = useState({});

  const [searchParams] = useSearchParams();
  const date_from = searchParams.get('date_from');
  const date_to = searchParams.get('date_to');

  const { makeRequest: showTrack, isLoading: trackLoading } = useApi(({
    request: (date) => {
      setter([]);

      return getVehiclesPosition(guid_bnso, {
        created_at: {
          from: formatToServerDate(dateFrom || date.dateFrom),
          to: formatToServerDate(dateTo || date.dateTo),
        },
      });
    },
    setter: ({ data }) => {
      if (data?.length) setter(data);
      else {
        pushNotification({
          message: 'Данные по ТС в указанный период не найдены',
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
    },
  }));

  const onShowTrack = () => {
    if (trackLoading) return;

    const { hasErrors, validField } = formValidator({
      form: { dateFrom, dateTo },
    });
    setError(validField);

    if (hasErrors) return;

    showTrack();
  };

  useEffect(() => {
    if (date_from && date_to) {
      showTrack({
        dateFrom: date_from,
        dateTo: date_to,
      });
      setDateFrom(getTimeZoneDate(date_from, true));
      setDateTo(getTimeZoneDate(date_to, true));
    }
  }, []);

  return (
    <Box display="flex" gap={1} mb={1} flexDirection="column" alignItems="flex-start">
      <Box display="flex" gap={1}>
        {boundVehicle && (
          <CustomButton sx={{ path: { fill: 'white' } }} startIcon={<MonitoringIcon />} onClick={boundVehicle}>
            Показать на карте
          </CustomButton>
        )}
        <CustomButton isLoading={trackLoading} color="warning" variant="contained" onClick={onShowTrack}>
          Показать трек
        </CustomButton>
      </Box>
      <Box display="flex" gap={2} flexWrap="wrap">
        <CustomDatePicker
          isDateTime
          value={dateFrom}
          onChange={(e, value) => {
            setDateFrom(value);
            setError((prevState) => ({ ...prevState, dateFrom: '' }));
          }}
          error={error.dateFrom}
        />
        <CustomDatePicker
          isDateTime
          value={dateTo}
          error={error.dateTo}
          onChange={(e, value) => {
            setDateTo(value);
            setError((prevState) => ({ ...prevState, dateTo: '' }));
          }}
        />
      </Box>
    </Box>
  );
}

export default function VehicleTrackControl({
  vehiclePosition, guid_bnso, setter, boundVehicle, isLoading,
}) {
  if (isLoading && !vehiclePosition?.place) return null;

  return (
    <>
      {vehiclePosition === null && (
        <TextTitle sx={{ mb: 2, color: 'orange' }}>Не удалось получить координаты ТС</TextTitle>
      )}
      <VehicleTrackControlComponent
        boundVehicle={vehiclePosition?.place && boundVehicle}
        setter={setter}
        guid_bnso={guid_bnso}
        isLoading={isLoading}
      />
    </>
  );
}
