import { DrawAreaSelection } from '@bopen/leaflet-area-selection';
import { createPortal } from 'react-dom';
import '@bopen/leaflet-area-selection/dist/index.css';
import { Box } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  GeoJSON,
  MapContainer, Marker, Polygon, Popup, TileLayer,
} from 'react-leaflet';
import DeviationEnd from '../../assets/address_blue.svg';
import addressGreen from '../../assets/address_green.svg';
import addressRed from '../../assets/address_red.svg';
import MapMarkerSvg from '../../assets/map-marker-blue.svg';
import ObjectIcon from '../../assets/map-marker.svg';
import NoTerScheme from '../../assets/noTerScheme.svg';
import { createLIcon } from '../../hooks/useClusterGroup';
import { TextTitle } from '../Text';
import ClustersRender from './ClustersRender';
import LegendControl from './LegendControl';
import MapControls from './MapControls';
import PlannedRoutesRender from './PlannedRoutesRender';
import TrackRoutes from './TrackRoutes';
import TransportTracking from './TransportTracking';

const FromAddressIcon = createLIcon(addressRed);
const ToAddressIcon = createLIcon(addressGreen);
const DefaultIcon = createLIcon(ObjectIcon);
const SelectAddressIcon = createLIcon(MapMarkerSvg, '', [21, 34]);
const NoTerSchemeIcon = createLIcon(NoTerScheme);
const DeviationEndIcon = createLIcon(DeviationEnd);

L.Marker.prototype.options.icon = DefaultIcon;

const CENTER_COORDINATES = [59.9342802, 30.3350986];

let timer;

const PATH_COLOR = {
  yellow: { color: 'yellow' },
  orange: { color: 'orange' },
  red: { color: 'red' },
};

export default function LeafletMap({
  sx,
  outerMap,
  hideControlButtons,
  setOuterMap,
  fromAddress,
  toAddress,
  planned_routes = [],
  selectedMarker,
  width = '100%',
  height = '100%',
  clusters,
  customComponent,
  setIsMapLoading,
  isMapLoading,
  zoomOutTime = 0,
  layers = [],
  trackedVehicles = [],
  tracked_routes = [],
  deviationRoutes = [],
  noTerSchemeObjects = [],
  selectArea,
  unselectArea,
  concentrationPoints,
  children,
  hasLegend = true,
  getTrackCircleChildren,
  onClickTrackedMarker,
  onClickMap,
}) {
  const mapRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [currentZoom, setCurrentZoom] = useState(11);
  const [boundsMap, setBoundsMap] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const trackedRouteMarkers = [
    { icon: FromAddressIcon, addresses: fromAddress },
    { icon: ToAddressIcon, addresses: toAddress },
  ];

  const whenMapReady = (mapInstance) => {
    if (outerMap !== null && setOuterMap) {
      setOuterMap(mapInstance.target);

      mapInstance.target?.on('zoomend', (e) => {
        // eslint-disable-next-line no-underscore-dangle
        setCurrentZoom(e?.target?._zoom);
      });

      mapInstance.target?.on('moveend', (e) => {
        const boundMap = e?.target?.getBounds?.();

        if (boundMap) setBoundsMap(boundMap);
      });
    }

    if (onClickMap) {
      mapInstance.target?.on('click', (e) => {
        const latlng = e?.latlng;

        onClickMap(latlng, e);
      });
    }

    if (selectArea) {
      const areaSelection = new DrawAreaSelection({
        onPolygonReady: (e) => selectArea(e),
        onButtonDeactivate: (e) => unselectArea?.(e),
      });

      mapInstance.target.addControl(areaSelection);
      mapInstance.target.areaSelection = areaSelection;
    }

    mapRef.current = mapInstance.target;

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      if (setIsMapLoading) {
        setIsMapLoading(false);
      }

      setIsLoading(false);
    }, 1000);
  };

  const escapeFromFullscreen = ({ key }) => {
    if (key === 'Escape') {
      setIsFullscreen(false);
      document.body.classList.remove('overflow-hidden');
      window.document.removeEventListener('keydown', escapeFromFullscreen);
    }
  };

  useEffect(() => {
    mapRef.current.invalidateSize(false);

    if (isFullscreen) return document.body.classList.add('overflow-hidden');
    document.body.classList.remove('overflow-hidden');
  }, [isFullscreen]);

  const changeFullScreen = () => {
    if (!isFullscreen) {
      document.body.classList.add('overflow-hidden');
      window.document.addEventListener('keydown', escapeFromFullscreen);
    } else {
      document.body.classList.remove('overflow-hidden');
      window.document.removeEventListener('keydown', escapeFromFullscreen);
    }
    setIsFullscreen((prevState) => !prevState);
  };

  const fullscreenSx = useMemo(() => {
    if (isFullscreen) return { top: '0', left: '0', position: 'fixed' };

    return {};
  }, [isFullscreen]);

  const mainComponent = (
    <Box
      width={isFullscreen ? '100vw' : width}
      height={isFullscreen ? '100vh' : height}
      position={isFullscreen ? 'fixed' : 'relative'}
      zIndex={isFullscreen ? 99999 : 'initial'}
      className={isMapLoading || isLoading ? 'leaflet-loading' : ''}
      sx={{ ...sx, ...fullscreenSx }}
    >
      {children}
      {!hideControlButtons && (
        <>
          {hasLegend && (
            <LegendControl
              clusters={clusters}
              concentrationPoints={concentrationPoints}
              hasNoTerSchemePoints={noTerSchemeObjects?.length}
              hasDeviationRoute={deviationRoutes?.length}
              hasPlannedRoute={planned_routes?.length}
              hasTrackedRoute={tracked_routes?.length}
            />
          )}
          <MapControls changeFullscreen={changeFullScreen} mapRef={mapRef} />
        </>
      )}
      <ClustersRender clusters={clusters} map={mapRef?.current} />
      <MapContainer
        whenReady={whenMapReady}
        center={CENTER_COORDINATES}
        zoom={11}
        id="default-map"
        minZoom={0}
        zoomControl={false}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          attribution='&amp;copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          setParams
        />
        <PlannedRoutesRender
          shouldFitBounds={!deviationRoutes.length}
          zoomOutTime={zoomOutTime}
          map={mapRef.current}
          planned_routes={planned_routes}
        />
        <TrackRoutes
          shouldShowPoints
          getTrackCircleChildren={getTrackCircleChildren}
          map={mapRef.current}
          pathOptions={PATH_COLOR.orange}
          planned_routes={tracked_routes}
        />
        <PlannedRoutesRender
          zoomOutTime={zoomOutTime}
          map={mapRef.current}
          pathOptions={PATH_COLOR.red}
          planned_routes={deviationRoutes}
        />
        {Boolean(deviationRoutes?.length) && (
          <Marker
            key={`${deviationRoutes[0][0]}, ${deviationRoutes[0][1]}`}
            icon={DeviationEndIcon}
            position={L.latLng(deviationRoutes.at(-1)[1], deviationRoutes.at(-1)[0])}
          />
        )}
        {selectedMarker?.lat && (
          <Marker
            draggable
            eventHandlers={{
              dragend(e) {
                const marker = e?.target;

                if (marker != null) {
                  const latlng = marker.getLatLng();

                  onClickMap(latlng, e);
                }
              },
            }}
            icon={SelectAddressIcon}
            position={L.latLng(selectedMarker.lat, selectedMarker.lng)}
          />
        )}
        {concentrationPoints?.map?.(({ polygonPoints, color }) => {
          if (polygonPoints.length < 2) return null;

          return (
            <Polygon
              pathOptions={PATH_COLOR[color]}
              key={`${polygonPoints[0][0]}${polygonPoints[0][1]}`}
              positions={polygonPoints}
            />
          );
        })}
        {layers.map(({ id, value }) => {
          if (value?.[0]?.coordinates) {
            return value.map((feature) => (
              <Polygon
                className="leaflet-regions"
                style={() => ({ opacity: 0.2 })}
                key={feature.id}
                positions={feature.coordinates}
              >
                <Popup>
                  <TextTitle>{feature.properties.name}</TextTitle>
                </Popup>
              </Polygon>
            ));
          }

          return (
            <GeoJSON style={() => ({ opacity: 0.2 })} key={id} data={value} />
          );
        })}
        {trackedVehicles?.map?.((vehicle) => (
          <TransportTracking
            currentZoom={currentZoom}
            boundsMap={boundsMap}
            onClickMarker={onClickTrackedMarker}
            key={vehicle.id}
            data={vehicle}
          />
        ))}
        {trackedRouteMarkers
          .map(({ addresses, icon }) => addresses?.map?.((address) => {
            const place = address?.place;

            if (!(place?.[0])) return null;

            return (
              <Marker
                key={`${place[0]}, ${place[1]}`}
                icon={icon}
                position={L.latLng(place[1], place[0])}
              />
            );
          }))}
        {noTerSchemeObjects.map(({ id, objective_attributes }) => {
          const {
            place,
          } = objective_attributes?.attributes?.address_attributes?.attributes || {};

          if (!place?.[0]) return null;

          return (
            <Marker
              key={id}
              icon={NoTerSchemeIcon}
              position={L.latLng(place[1], place[0])}
            />
          );
        })}
      </MapContainer>
      {customComponent}
    </Box>
  );

  if (isFullscreen) return createPortal(mainComponent, document.body);

  return mainComponent;
}
