// @ts-nocheck
import React, { useState } from 'react';
import { getError } from '../../helpers/formValidator';
import { useApi } from '../../hooks';
import CustomInput from '../Inputs';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';

const DEFAULT_ASYNC_AUTOCOMPLETE_FIELD = {
  label: 'name',
  id: 'id',
  value: 'id',
};

export function AsyncAutocomplete({
  id,
  label,
  deps,
  sx,
  onChange,
  disabled,
  value,
  stringValue,
  canBeZero,
  margin,
  error,
  name,
  request,
  optionField,
  noOptionComponent,
  useDefaultFilter,
  selectFirstOption,
  multiline,
  multiple,
  additionalFilter,
  validImmediately,
  containerSx,
  filterOptions,
  required,
  debounceTime,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const mainOptionField = { ...DEFAULT_ASYNC_AUTOCOMPLETE_FIELD, ...optionField };

  const { makeRequest } = useApi({
    setter: (newOptions) => setOptions(
      additionalFilter ? additionalFilter(newOptions) : newOptions,
    ),
    request,
    isLoading,
    setIsLoading,
    shouldRequest: selectFirstOption,
    deps,
  });

  if (stringValue) {
    return <CustomInput disabled={Boolean(disabled)} value={stringValue} sx={sx} />;
  }

  const checkValidation = () => {
    if (canBeZero && multiple) return value?.[0] !== '0';
    return validImmediately;
  };

  return (
    <CustomAutocomplete
      required={required}
      multiline={multiline}
      containerSx={containerSx}
      canBeZero={canBeZero}
      setIsLoading={setIsLoading}
      selectFirstOption={selectFirstOption}
      label={label}
      id={id}
      sx={sx}
      multiple={multiple}
      margin={margin}
      useDefaultFilter={useDefaultFilter}
      loading={isLoading}
      noOptionComponent={noOptionComponent}
      error={error || getError({
        value: multiple ? value?.length : value?.[mainOptionField?.id || mainOptionField?.value],
        validImmediately: checkValidation(),
      })}
      name={name}
      fields={mainOptionField}
      asyncSearch={makeRequest}
      disabled={Boolean(disabled)}
      options={options}
      value={value}
      onChange={(event, optionValue, changeType) => {
        if (makeRequest && !(multiple && ['selectOption', 'removeOption'].includes(changeType))) {
          setIsLoading(true);
          setOptions([]);
        }

        return onChange(event, optionValue, changeType);
      }}
      debounceTime={debounceTime}
      filterOptions={filterOptions}
    />
  );
}
