import React, { useState } from 'react';
import { Box } from '@mui/material';
import NavTabs from '../../components/Tabs';
import Statistics from '../../components/Statistics';
import MapWithFilters from '../../components/Map/MapWithFilters';

const tabsComponents = [
  { label: 'Электронная карта', name: 'map' },
  // { label: 'Показатели', name: 'statistics' }, // todo next sprint page
];

export default function MonitoringMap() {
  const [tab, setTab] = useState(tabsComponents[0].name);

  return (
    <Box width="100%">
      <Box>
        <NavTabs tabsComponents={tabsComponents} tab={tab} setTab={setTab} />
      </Box>
      {tab === 'map' && <MapWithFilters />}
      {tab === 'statistics' && <Statistics />}
    </Box>
  );
}
