import {
  ENUMS_URL,
  RNIC_PROXY_POSITIONS_URL,
  SEARCH_BY_POLYGON_IN_OUT_URL,
  SEARCH_BY_POLYGON_URL,
  VEHICLE_LAST_POSITIONS_URL,
  VEHICLE_POSITIONS_URL,
  VEHICLES_URL,
} from '../constants/urls';
import { checkIsControlOrigin, checkIsPublicCadastreOrigin } from '../helpers/browserLocation';
import api, { commonApi, defaultApi } from './api';

export const vehicleApi = commonApi(VEHICLES_URL, 'vehicle');

export const getVehiclesRequest = (params, setTableStore) => api({
  url: VEHICLES_URL,
  params: { is_inactive: false, ...params },
}, { isEntityAttributes: true, setTableStore });

export const getVehicleRequest = (id) => api(
  { url: `${VEHICLES_URL}/${id}` },
  { isEntityAttributes: true },
);

export const updateVehicleRequest = (id, data) => api({
  method: 'put',
  url: `${VEHICLES_URL}/${id}`,
  data,
}, { isEntityAttributes: true });

export const deleteVehicleRequest = (id) => api({ method: 'put', url: `${VEHICLES_URL}/${id}/deactivation` });

export const createVehicleRequest = (vehicle) => api({
  method: 'post',
  url: VEHICLES_URL,
  data: { vehicle },
}, { isEntityAttributes: true });

export const getVehicleItemsRequest = ({ id, url = '', params }, setTableStore) => api(
  { url: `${VEHICLES_URL}/${id}${url}`, params },
  { isEntityAttributes: true, setTableStore },
);

export const getVehiclesLastPosition = (id, params) => {
  const query = {
    ...params, per_page: 10000,
  };

  if (id !== undefined) query.car_handler = String(id);

  return defaultApi({
    url: VEHICLE_LAST_POSITIONS_URL,
    params: { json: JSON.stringify({ query }) },
    baseURL: (checkIsControlOrigin() || checkIsPublicCadastreOrigin())
      ? process.env.REACT_APP_PUBLIC_BASE_URL
      : process.env.REACT_APP_VEHICLES_URL,
  });
};

export const getVehiclesEnumsRequest = (enum_field, params) => api(
  { url: `${VEHICLES_URL}${ENUMS_URL}`, params },
  { enum_field },
);

export const getVehiclesPosition = (id, params) => api({
  url: VEHICLE_POSITIONS_URL,
  params: id !== undefined && {
    json: JSON.stringify({ query: { car_handler: String(id), ...params, per_page: 800 } }),
  },
  baseURL: (checkIsControlOrigin() || checkIsPublicCadastreOrigin())
    ? process.env.REACT_APP_PUBLIC_BASE_URL
    : process.env.REACT_APP_VEHICLES_URL,
});

export const getVehiclesByPolygonRequest = ({ polygon, date_from, date_to }) => api({
  url: SEARCH_BY_POLYGON_URL,
  params: {
    json: JSON.stringify({ polygon, time: { from: date_from, to: date_to } }),
  },
  baseURL: (checkIsControlOrigin() || checkIsPublicCadastreOrigin())
    ? process.env.REACT_APP_PUBLIC_BASE_URL
    : process.env.REACT_APP_VEHICLES_URL,
});

export const getVehiclesByPolygonInOutRequest = ({ polygon, date_from, date_to }) => api({
  url: SEARCH_BY_POLYGON_IN_OUT_URL,
  params: {
    json: JSON.stringify({ polygon, time: { from: date_from, to: date_to } }),
  },
  baseURL: (checkIsControlOrigin() || checkIsPublicCadastreOrigin())
    ? process.env.REACT_APP_PUBLIC_BASE_URL
    : process.env.REACT_APP_VEHICLES_URL,
});

export const getErnicVehicleRequest = (params) => api({
  url: RNIC_PROXY_POSITIONS_URL,
  params,
});
