import { memo } from 'react';
import useClusterGroup from '../../hooks/useClusterGroup';

const checkClusters = (prevClusters, nextClusters) => prevClusters
  ?.every?.(({ points }, index) => points.length === nextClusters?.[index]?.points?.length);

let prevZoom;

const shouldMemo = (prevProps, nextProps) => {
  if (!prevZoom && prevProps.map?._zoom) prevZoom = prevProps.map._zoom;

  if (prevProps.map?._zoom && prevProps.map?._zoom === nextProps.map?._zoom) {
    if (prevZoom !== prevProps.map?.zoom) {
      prevZoom = prevProps.map._zoom;
      return false;
    }
  }

  if (!prevProps.clusters && !nextProps.clusters) return true;

  const nextClusters = nextProps.clusters;
  const prevClusters = prevProps.clusters;

  return prevProps.map?._leaflet_id === nextProps.map?._leaflet_id
    && checkClusters(prevClusters, nextClusters);
};

const ClustersRender = memo(({ clusters, map }) => {
  useClusterGroup({ clusters, map });

  return null;
}, shouldMemo);

export default ClustersRender;
