import React from 'react';
import { Text } from './Text';

export function TextMain({
  children, big, medium, sx, component, onClick,
}) {
  return (
    <Text onClick={onClick} component={component} className="color-main" sx={sx} big={big} medium={medium}>
      {children}
    </Text>
  );
}
