import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getFileExtensions } from '../../helpers';

const containerSx = {
  border: '1px dashed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 100,
};

function DropZone({
  getFiles, sx, accept = getFileExtensions(), maxFiles = 1, loading, name,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      getFiles({ target: { name } }, acceptedFiles);
    },
    [getFiles, maxFiles, name],
  );
  const {
    getRootProps, getInputProps, isDragActive, isDragReject,
  } = useDropzone({ onDrop, accept, maxFiles });

  const rootProps = loading ? {} : getRootProps();

  return (
    <Box
      sx={{ ...containerSx, ...sx }}
      {...getRootProps()}
      onClick={() => {
      }}
      onKeyDown={() => {
      }}
      onFocus={() => {
      }}
    >
      {loading && <CircularProgress color="text" sx={{ display: 'flex', margin: '0 auto' }} />}
      {!loading && (
      <>
        <input {...getInputProps()} />
        {
          // eslint-disable-next-line no-nested-ternary
          isDragReject ? <Typography>Не верный формат файла</Typography>
            : isDragActive ? <Typography>Прикрепить</Typography>
              : (
                <Typography>
                  Перенесите файлы, чтобы прикрепить, или
                  {' '}
                  <Typography
                    className="color-blue"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                    component="span"
                    {...rootProps}
                  >
                    ВЫБЕРИТЕ ФАЙЛ
                  </Typography>
                </Typography>
              )
        }
      </>
      )}
    </Box>
  );
}

export default DropZone;
