import React from 'react';
import { Box } from '@mui/material';
import { TextTitle } from '../../../../Text';
import DeleteButton from '../../../../Buttons/DeleteButton';
import AddButton from '../../../../Buttons/AddButton';
import {
  ENVIRONMENT_MONITORING_TYPE_NAME,
} from '../../../../../constants/lkCadastr/cadastrForm8';
import { flexGap2 } from '../../../../../constants/lkCadastr/cadastrStorage';
import CustomInput from '../../../../Inputs';
import useCadastreTabs from '../../../../../hooks/useCadastreTabs';
import NavTabs from '../../../../Tabs';
import { getInitialMonitoringKinds } from '../../../../../constants/lkCadastr/cadastrForm7';
import { deleteForm8Entity } from '../../../../../helpers/cadastreForms/form8Helper';

export function EnvironmentMonitoringTypes({
  placingObject, changeObject, isDemo, openDeleteModal,
}) {
  const environmentMonitoringTypes = placingObject[ENVIRONMENT_MONITORING_TYPE_NAME];

  const { tab, setTab, tabs } = useCadastreTabs({ items: environmentMonitoringTypes, tabName: 'Вид мониторинга' });

  return (
    <Box sx={flexGap2}>
      <TextTitle h3>
        Виды мониторинга окружающей среды на объекте
      </TextTitle>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeObject(
            ENVIRONMENT_MONITORING_TYPE_NAME,
            [...environmentMonitoringTypes, getInitialMonitoringKinds()],
          )}
        >
          Добавить вида мониторинга
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {environmentMonitoringTypes.map((environmentMonitoringType, environmentMonitoringTypeIndex) => {
        if (tab !== environmentMonitoringType.randId) return null;

        const onChange = (field, value) => {
          changeObject(`${ENVIRONMENT_MONITORING_TYPE_NAME}.${environmentMonitoringTypeIndex}.${field}`, value, undefined, { environmentMonitoringTypeIndex });
        };

        const onDelete = async () => {
          const deletedAreaDocument = await deleteForm8Entity({ id: environmentMonitoringType.id, apiType: ENVIRONMENT_MONITORING_TYPE_NAME });

          if (!deletedAreaDocument) return;

          const cloneArray = environmentMonitoringTypes.slice();
          cloneArray.splice(environmentMonitoringTypeIndex, 1);

          changeObject(
            ENVIRONMENT_MONITORING_TYPE_NAME,
            cloneArray,
            'delete',
          );
        };

        return (
          <Box sx={flexGap2} key={environmentMonitoringType.randId}>
            <TextTitle>
              Вид мониторинга
              №
              {environmentMonitoringTypeIndex + 1}
            </TextTitle>
            {!isDemo && environmentMonitoringTypes.length > 1 && environmentMonitoringType?.id && (
              <DeleteButton
                onClick={() => openDeleteModal(onDelete, environmentMonitoringTypeIndex, 'Вид мониторинга')}
              >
                Удалить вида мониторинга
              </DeleteButton>
            )}
            <Box sx={flexGap2}>
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Наименование вида мониторинга"
                value={environmentMonitoringType.name}
                onChange={(e, value) => onChange('name', value)}
              />
              <CustomInput
                debounceTime={0}
                validImmediately
                disabled={isDemo}
                label="Соблюдение нормативов качества ОС"
                value={environmentMonitoringType.normative_rule}
                onChange={(e, value) => onChange('normative_rule', value)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
