import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { BACKGROUND_LIGHT } from '../../constants/colors';
import { FORMS_NAME, REPORT_STATUS_NAMES, SHOW_FORMS } from '../../constants/lkCadastr/cadastrStorage';
import { useUserStore } from '../../store';
import CustomButton from '../Buttons/CustomButton';
import ModalSignatureMail from '../Modals/Cadastr/ModalSignatureMail';
import ModalCadastrForms from '../Modals/ModalCadastrForms/ModalCadastrForms';
import CustomTable from '../Tables/CustomTable';
import { TextTitle } from '../Text';

export default function CadastrTable({
  tableColumns,
  title,
  buttonText,
  tableData,
  forms,
  reports,
  buttonAction,
  type,
  setModalSignature,
  disabledRow,
  isLoading,
  modalSignature,
  getReports,
}) {
  const { userStore } = useUserStore();
  const [modalCreate, setModalCreate] = useState('');
  const [openedReport, setOpenedReport] = useState({});

  const isForms = type === 'forms';
  const isReports = type === 'reports';

  const openCreateModal = () => {
    setModalCreate('create');
  };

  const onClickRow = (row) => {
    if (isForms) {
      setOpenedReport(reports[0]);
      return setModalCreate(`form${row.id}`);
    }

    if (isReports) {
      setOpenedReport(row);
      let notCompletedForm = forms.findIndex((item, index) => (
        +item.complete !== 100 && FORMS_NAME[index].show(userStore)
      ));

      if (notCompletedForm !== -1) notCompletedForm = +notCompletedForm + 1;
      else notCompletedForm = 1;

      setModalCreate(`form${notCompletedForm}`);
    }
  };

  const closeCreate = () => {
    getReports();
    setModalCreate('');
    setOpenedReport({});
  };

  const openModalSignature = (row) => {
    setModalSignature(row);
  };

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const additionalControls = useMemo(() => {
    const showEditRow = tableData.some((row) => {
      const reportStatusCode = row?.report_status_attributes?.attributes?.code;

      return ([
        REPORT_STATUS_NAMES.checked,
        REPORT_STATUS_NAMES.paper_document_printed,
        REPORT_STATUS_NAMES.paper_document_accepted,
      ].includes(reportStatusCode) && (row?.transmittal_mail_signed_url || row?.transmittal_mail_url));
    });

    if (modalSignature !== undefined && showEditRow) {
      return [
        {
          render: (row) => {
            const reportStatusCode = row?.report_status_attributes?.attributes?.code;

            if (![
              REPORT_STATUS_NAMES.checked,
              REPORT_STATUS_NAMES.paper_document_printed,
              REPORT_STATUS_NAMES.paper_document_accepted,
            ].includes(reportStatusCode) || (!row?.transmittal_mail_signed_url && !row?.transmittal_mail_url)) return null;

            return (
              <CustomButton
                onClick={(e) => {
                  e.stopPropagation();
                  openModalSignature(row);
                }}
              >
                Сопроводительное письмо
              </CustomButton>
            );
          },
          id: Math.random(),
        },
      ];
    }
  }, [tableData]);

  return (
    <Box position="relative" width={tabletSizeAndLower ? '100%' : '50%'} background={BACKGROUND_LIGHT}>
      {Boolean(modalSignature?.created_at) && (
        <ModalSignatureMail
          getReports={getReports}
          report={modalSignature}
          close={() => setModalSignature({})}
        />
      )}
      {modalCreate && (
        <ModalCadastrForms
          isDemo={!isForms && tableData?.[0]?.status === 'process'}
          initialForm={modalCreate === 'create' ? undefined : modalCreate}
          report={openedReport}
          isCreating={modalCreate === 'create'}
          allRerorts={tableData.filter((item) => item.report_status_attributes?.attributes?.code === REPORT_STATUS_NAMES.paper_document_accepted)}
          close={closeCreate}
        />
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={3} mb={3}>
        <TextTitle h2>{title}</TextTitle>
        {buttonText && (
          <CustomButton
            sx={{ height: '32px' }}
            variant="contained"
            onClick={buttonAction || openCreateModal}
          >
            {buttonText}
          </CustomButton>
        )}
      </Box>
      <Box
        overflow="auto"
        gap={3}
        p={1}
        display="flex"
        flexDirection="column"
      >
        <CustomTable
          hideTotal
          hidePagination
          showRowsNumber
          disabledRow={disabledRow}
          isLoading={isLoading}
          disabled={isForms && !reports[0]?.id}
          additionalControls={!isForms && additionalControls}
          controlHeaderCellName="Редактирование"
          onClick={onClickRow}
          hideBodyCell={(row, index) => isForms && !SHOW_FORMS[`form${index + 1}`](userStore)}
          data={tableData}
          columns={tableColumns}
          containerSx={{ maxHeight: '480px' }}
        />
      </Box>
    </Box>
  );
}
