import React from 'react';
import {
  Badge, Box, Divider, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import SearchInput from '../Inputs/SearchInput';
import CustomAccordion from '../CustomAccordion';
import { objectChangeDotProperty } from '../../helpers/objectHelper';
import { ReactComponent as RedHouseIcon } from '../../assets/map-marker-red.svg';
import { ReactComponent as YellowHouseIcon } from '../../assets/map-marker-yellow.svg';
import { ReactComponent as VehicleIcon } from '../../assets/tsMapIcon.svg';
import CustomSwitch from '../CustomSwitch';
import SelectAreaControl from '../SelectAreaControl';
import CustomCheckbox from '../CustomCheckbox';
import { TextTitle } from '../Text';
import { OBJECT_STATUS } from '../../constants/objectConstants';

let timeout;

function AccordionFilter({
  text, items, onChange, endComponent,
}) {
  return (
    <Badge badgeContent={items.filter(({ value }) => Boolean(value)).length} color="primary">
      <CustomAccordion sx={{ width: '100%' }} titleComponent={text}>
        <Box display="flex" flexDirection="column">
          {items.map(({ id, value: itemValue, name }, index) => (
            <CustomSwitch
              key={id}
              name={id}
              label={name}
              value={itemValue}
              onChange={(e, value) => onChange(e, value, index)}
            />
          ))}
          {endComponent}
        </Box>
      </CustomAccordion>
    </Badge>
  );
}

export default function WasteMapFilters({
  clearSelectedArea,
  setAreVehiclesVisible,
  areVehiclesVisible,
  wasteFilters,
  setWasteFilters,
  layers,
  setLayers,
  wasteRecipientFilters,
  setWasteRecipientFilters,
  setSearchVehicle,
  searchVehicle,
  onStartSearching,
  selectedArea,
  setSelectedAreaVehicles,
  setWasteGeneratorStatuses,
  wasteGeneratorStatuses,
}) {
  const changeWasteGeneratorFilter = (getter, setter) => (e, value, index) => {
    const newFilters = objectChangeDotProperty({
      field: `${index}.value`,
      value,
      object: getter,
    });
    setter(newFilters);
  };

  const changeAreVehicleVisible = (e, value) => {
    if (!areVehiclesVisible) {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => setAreVehiclesVisible?.(false), 60000 * 5);
    }
    setAreVehiclesVisible(value);
  };

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: tabletSizeAndLower ? '100vw' : '26vw' }}>
      <SearchInput
        sx={{ bgcolor: 'white' }}
        placeholder="Поиск транспорта"
        onChange={setSearchVehicle}
        value={searchVehicle}
        onKeyEnter={onStartSearching}
      />
      <Divider sx={{ my: 2 }} />
      <CustomSwitch
        value={areVehiclesVisible}
        onChange={changeAreVehicleVisible}
        name="track-vehicles"
        label="Транспортные средства"
        sx={{ mb: 2, px: 2 }}
        Icon={<VehicleIcon />}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <CustomAccordion sx={{ width: '100%' }} titleComponent="Просмотр ТС по выделяемой области">
          <SelectAreaControl
            areVehiclesVisible={areVehiclesVisible}
            setSelectedAreaVehicles={setSelectedAreaVehicles}
            selectedArea={selectedArea}
            clearSelectedArea={clearSelectedArea}
          />
        </CustomAccordion>
        <Box display="flex" alignItems="center" gap={2} px={2}>
          <YellowHouseIcon />
          {wasteRecipientFilters.map(({ id, value: itemValue, name }, index) => (
            <CustomSwitch
              key={id}
              name={id}
              label={name}
              value={itemValue}
              onChange={(e, value) => changeWasteGeneratorFilter(
                wasteRecipientFilters,
                setWasteRecipientFilters,
              )(e, value, index)}
            />
          ))}
        </Box>
        <AccordionFilter
          items={wasteFilters}
          icon={RedHouseIcon}
          text={(
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <RedHouseIcon />
              Объекты обращения с отходами
            </Typography>
          )}
          onChange={changeWasteGeneratorFilter(wasteFilters, setWasteFilters)}
          endComponent={(
            <>
              <TextTitle sx={{ mt: 2 }}>Статусы</TextTitle>
              <CustomCheckbox
                value={wasteGeneratorStatuses.includes(OBJECT_STATUS.SIMPLE)}
                onChange={setWasteGeneratorStatuses}
                name={OBJECT_STATUS.SIMPLE}
                label="Обычный"
              />
              <CustomCheckbox
                value={wasteGeneratorStatuses.includes(OBJECT_STATUS.PUBLISHED_TER_SCHEME)}
                onChange={setWasteGeneratorStatuses}
                label="Опубликован в тер. схеме"
                name={OBJECT_STATUS.PUBLISHED_TER_SCHEME}
              />
            </>
          )}
        />
        <AccordionFilter
          items={layers}
          text="Слои"
          onChange={changeWasteGeneratorFilter(layers, setLayers)}
        />
      </Box>
    </Box>
  );
}
