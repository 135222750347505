import React, { useMemo, useState } from 'react';
import {
  Box, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import NavTabs from '../../components/Tabs';
import { USER_ENTITY_TYPE } from '../../constants';
import RegistrationCarrier from '../../components/RegistrationCarrier';
import BackButton from '../../components/BackButton';
import { Text, TextTitle } from '../../components/Text';
import { TEXT_MAIN, TEXT_TITLE } from '../../constants/colors';

const paperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  boxShadow: '0px 8px 30px rgba(10, 30, 44, 0.1)',
  borderRadius: '8px',
};

const tabsComponents = Object.values(USER_ENTITY_TYPE).slice(0, 3);

export default function RegistrationSubjectPage() {
  const [tab, setTab] = useState(tabsComponents[0].name);
  const [isMatch, setIsMatch] = useState(false);
  const [searchParams] = useSearchParams();
  const isCadastr = searchParams.get('isCadastr');

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const changeTab = (newTab) => {
    setIsMatch(newTab === USER_ENTITY_TYPE.self_employed.name);
    setTab(newTab);
  };

  const tabs = useMemo(() => (
    isCadastr ? tabsComponents.filter(({ name }) => name !== 'self_employed') : tabsComponents
  ), [isCadastr]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      component="form"
    >
      <Paper sx={{ ...paperStyle, px: tabletSizeAndLower ? '4px' : '20px', py: tabletSizeAndLower ? 6 : '20px' }}>
        <BackButton path={-1} />
        <TextTitle sx={{ color: TEXT_TITLE }} h1>Регистрация субъекта</TextTitle>
        <Text sx={{ color: TEXT_MAIN }}>
          {isCadastr ? 'Регистрация в Кадастр отходов' : 'Регистрация в Контроле перевозчиков строительных отходов Ленинградской области'}
        </Text>
        <NavTabs sx={{ mb: 2 }} tabsComponents={tabs} tab={tab} setTab={changeTab} />
        <RegistrationCarrier isMatch={isMatch} setIsMatch={setIsMatch} subject_type={tab} setSubjectType={setTab} />
      </Paper>
    </Box>
  );
}
