import React from 'react';
import LoginPage from '../Public/LoginPage';
import { loginCommitteeRequest } from '../../api/committeApi';
import { getCommitteeRoutes } from '../../constants/routes/committeeRoutes';
import { useGetRoleRedirectRoute } from '../../hooks/useGetRoleRedirectRoute';

export default function CommitteeLoginPage() {
  const getRedirectRoute = useGetRoleRedirectRoute({ getRoleRoutes: getCommitteeRoutes });

  return <LoginPage forgotPasswordParams="committee" loginRequest={loginCommitteeRequest} redirectUrl={getRedirectRoute} />;
}
