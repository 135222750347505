// @ts-nocheck
import { Box, Paper, Typography } from '@mui/material';
import cloneDeep from 'lodash.clonedeep';
import React, { useMemo, useState } from 'react';
import { getCitiesRequest, getDistrictsRequest } from '../api/addressApi';
import { getApplicationEnumsRequest, getApplicationsRequest } from '../api/applicationsApi';
import { getObjectivesRequest } from '../api/objectivesApi';
import { getReferencesRequest } from '../api/referenceApi';
import { createReportRequest, getReportColumnsRequest, getReportsRequest } from '../api/reportApi';
import { getSubjectsRequest } from '../api/subjectApi';
import { getVehiclesRequest } from '../api/vehiclesApi';
import { getWasteTypesRequest } from '../api/wasteTypeApi';
import { AsyncAutocomplete } from '../components/AutoCompletes/AsyncAutocomplete';
import BackdropLoading from '../components/BackdropLoading';
import CustomButton from '../components/Buttons/CustomButton';
import Submit from '../components/Buttons/SubmitButton';
import CustomCheckbox from '../components/CustomCheckbox';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomRadioGroup from '../components/CustomRadio';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../components/Inputs/CustomAutocomplete';
import Selector, { DEFAULT_SELECTOR_FIELDS } from '../components/Selectors/Selector';
import { TextTitle } from '../components/Text';
import { DEFAULT_ENUM_OPTION, HAZARD_CLASS_ENUM } from '../constants/enums';
import { WASTE_MANAGEMENT_TYPES_URL } from '../constants/urls';
import { formValidator } from '../helpers';
import { formatDate, formatToServerDate } from '../helpers/dateHelper';
import { HAS_REQUIRED_FIELDS_TEXT } from '../helpers/formValidator';
import { useApi } from '../hooks';
import { useDownloadFile } from '../hooks/useDownloadFile';

const initialForm = {
  date_from: null,
  date_to: null,
  subject: {
    id: '',
    name: '',
  },
  kind: DEFAULT_AUTOCOMPLETE_OPTION,
  format_type: 'pdf',
  waste_type_id: { ...DEFAULT_ENUM_OPTION },
  [HAZARD_CLASS_ENUM]: [],
  is_connected_rins: false,
  subject_waste_recipient_id: DEFAULT_AUTOCOMPLETE_OPTION,
  subject_waste_generator_id: DEFAULT_AUTOCOMPLETE_OPTION,
  city_waste_generator: DEFAULT_AUTOCOMPLETE_OPTION,
  district_waste_generator: DEFAULT_AUTOCOMPLETE_OPTION,
  district: DEFAULT_AUTOCOMPLETE_OPTION,
  waste_management_type: DEFAULT_AUTOCOMPLETE_OPTION,
  objective_id: DEFAULT_AUTOCOMPLETE_OPTION,
};

const getReportColumn = (column, data) => {
  const generatedColumn = [];

  Object.keys(column).forEach((key) => {
    if (data[key]) generatedColumn.push(key);
  });

  return generatedColumn;
};

export default function AnalyticsPage() {
  const [form, setForm] = useState(initialForm);
  const [columnForm, setColumnForm] = useState({});
  const [vehiclesTotal, setVehiclesTotal] = useState({ totalRINS: 0, total: 0 });
  const [applicationsTotal, setApplicationsTotal] = useState(0);
  const [reportOptions, setReportOptions] = useState([]);
  const [columnType, setColumnType] = useState({});

  const { isLoading: loadingApplications } = useApi({
    request: () => getApplicationsRequest(
      {},
      (tableData) => setApplicationsTotal(tableData?.meta?.total),
    ),
    shouldRequest: true,
  });

  const { isLoading: loadingVehicles } = useApi({
    request: () => getVehiclesRequest({}, (tableData) => setVehiclesTotal((prevState) => ({
      ...prevState,
      total: tableData?.meta?.total,
    }))),
    shouldRequest: true,
  });

  const { isLoading: loadingRinsVehicles } = useApi({
    request: () => getVehiclesRequest(
      { is_connected_rins: true },
      (tableData) => setVehiclesTotal((prevState) => ({
        ...prevState,
        totalRINS: tableData?.meta?.total,
      })),
    ),
    shouldRequest: true,
  });

  const { isLoading: loadingReports } = useApi(({
    setter: ({ data: enumData }) => {
      const newReportOptions = [];
      const enumKind = enumData.kind;

      Object.keys(enumKind).forEach((enumField) => {
        const enumValue = enumKind[enumField];

        if (!enumValue.includes('Аналитический отчет')) {
          newReportOptions.push({
            label: enumValue,
            value: enumField,
            id: enumField,
          });
        }
      });
      setReportOptions(newReportOptions);
    },
    request: getReportsRequest,
    shouldRequest: true,
  }));

  const {
    kind,
    waste_type_id,
    subject,
    format_type,
    subject_waste_recipient_id,
    subject_waste_generator_id,
    objective_id,
    city_waste_generator,
    district,
    waste_management_type,
    hazard_class,
    is_connected_rins,
    date_to,
    date_from,
  } = form;

  const isMonthlyReport = kind.value === 'monthly_districts_stats';
  const isCarrierStats = kind.value === 'carriers_stats';
  const isObjectivesWasteRecipient = kind.value === 'objectives_waste_recipient_stats';
  const isApplication = kind.value === 'applications';
  const isVehicles = kind.value === 'vehicles';

  const [error, setError] = useState({});

  const { isLoading: loadingReportColumns } = useApi({
    request: getReportColumnsRequest,
    shouldRequest: kind.value && !isMonthlyReport,
    setter: (newColumnType) => {
      const cloneColumnType = {};

      Object.keys(newColumnType[form.kind.value]).forEach((key) => {
        cloneColumnType[key] = true;
      });

      setColumnForm(cloneColumnType);

      setColumnType(newColumnType);
    },
    deps: [kind.value],
  });

  const onChangeColumn = ({ target: { name } }, value) => {
    setColumnForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChange = ({ target: { name } }, value) => {
    if (name === 'kind') {
      setError({});
      setColumnForm({});
      setForm({
        ...cloneDeep(initialForm),
        [name]: value,
      });
      return;
    }

    setError((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { makeRequest: makeFileLink, isLoading: isDownloadingFile } = useDownloadFile({
    fileName: `${kind.label}${form.date_from ? ` c ${formatDate({
      date: form.date_from,
    })} по ${formatDate({
      date: form.date_to,
    })}` : ''}`,
  });

  const downloadReport = async () => {
    const requiredField = {
      date_to,
      date_from,
      kind,
    };

    const ignoreInputs = [];

    const { hasErrors, validField } = formValidator({
      form: requiredField,
      ignoreInputs,
      generalRules: ['null', 'empty'],
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const options = {};

    if (isApplication) {
      if (subject_waste_generator_id.id) {
        options.subject_waste_generator_id = subject_waste_generator_id.id;
      }
      if (district.id) options.district_waste_generator = district.id;
      if (city_waste_generator.id) options.city_waste_generator = city_waste_generator.id;
      if (waste_type_id.id) options.waste_type_id = waste_type_id.id;
      // eslint-disable-next-line prefer-destructuring
      if (hazard_class.length && hazard_class[0]?.value) {
        options[HAZARD_CLASS_ENUM] = hazard_class.map(({ value }) => value);
      }
      if (subject_waste_recipient_id.id) {
        options.subject_waste_recipient_id = subject_waste_recipient_id.id;
      }
      if (waste_management_type.id) {
        options.waste_management_type_id = waste_management_type.id;
      }
    }

    if (isVehicles || isApplication) {
      options.is_connected_rins = is_connected_rins;
    }

    if (isMonthlyReport || isCarrierStats) {
      if (district.id) options.district = district.id;
    }

    if (isObjectivesWasteRecipient) {
      if (objective_id.id) options.objective_id = objective_id.id;
      if (waste_management_type.id) options.waste_management_type_id = waste_management_type.id;
    }

    if (date_from) {
      options.date_from = formatToServerDate(date_from);
      options.date_to = formatToServerDate(date_to);
    }

    if (subject.id !== '') options.subject_id = subject.id;

    const columns = !isMonthlyReport
      ? { columns: getReportColumn(columnType[kind.value], columnForm) }
      : {};

    const response = await createReportRequest({
      format_type,
      kind: kind.value,
      options,
      ...columns,
    });

    makeFileLink({ url: response?.file_url });
  };

  const { makeRequest, isLoading: loadingDownloadReport } = useApi({
    request: downloadReport,
  });

  const isLoading = loadingVehicles
    || loadingReportColumns
    || loadingReports
    || loadingApplications
    || loadingRinsVehicles
    || isDownloadingFile
    || loadingDownloadReport;

  const clearDates = () => setForm((prevState) => ({
    ...prevState,
    date_to: null,
    date_from: null,
  }));

  const districtLabel = useMemo(() => {
    if (isCarrierStats) return 'Район деятельности перевозчика';
    if (isApplication) return 'Район образования';
    if (isMonthlyReport) return 'Район объекта образования';
  }, [isCarrierStats, isApplication, isMonthlyReport]);

  return (
    <Box>
      <Paper elevation={4} sx={{ padding: '20px', mt: '15px', mb: '15px' }}>
        <TextTitle h2="h2">Общие сведения</TextTitle>
        <Box display="flex" gap={10}>
          <Box>
            <TextTitle>Общее количество разрешений:</TextTitle>
            <Box display="flex" alignItems="baseline">
              <Typography fontSize="45px" fontWeight="bold">{applicationsTotal}</Typography>
            </Box>
          </Box>
          <Box>
            <TextTitle>Транспортных средств подключено к РИНС:</TextTitle>
            <Box display="flex" alignItems="baseline">
              <Typography fontSize="45px" fontWeight="bold">{vehiclesTotal.totalRINS}</Typography>
              <Typography fontSize="20px">
                &nbsp;/
                {vehiclesTotal.total}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
      <BackdropLoading position="fixed" isLoading={isLoading} />
      <TextTitle h2="h2" sx={{ mb: '10px' }}>Создание отчета</TextTitle>
      <Box display="flex" justifyContent="space-between" gap={2} flexWrap="wrap" mb={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextTitle sx={{ fontWeight: 'bold' }}>Тип отчета</TextTitle>
          <Selector
            sx={{ minWidth: '320px' }}
            label="Выберите тип отчета"
            name="kind"
            value={kind}
            onChange={onChange}
            options={reportOptions}
            error={error.kind?.id}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} flexBasis="25%">
          <TextTitle sx={{ fontWeight: 'bold' }}>Набор атрибутов, используемых в отчете</TextTitle>
          {Object.keys(columnForm).map((key) => (
            <CustomCheckbox
              key={key}
              label={columnType[kind.value][key]}
              onChange={onChangeColumn}
              name={key}
              value={columnForm[key]}
            />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} flexBasis="38%">
          <TextTitle sx={{ fontWeight: 'bold' }}>Условия фильтрации</TextTitle>
          <Box>
            <TextTitle>Период создания разрешений</TextTitle>
            {(date_from || date_to) && (
              <Box>
                <CustomButton sx={{ mb: 2 }} variant="text" onClick={clearDates}>Сбросить дату и время</CustomButton>
              </Box>
            )}
            <CustomDatePicker
              isDateTime={false}
              sx={{ mr: '10px' }}
              placeholder="Начало:"
              name="date_from"
              value={date_from}
              error={error.date_from}
              onChange={onChange}
            />
            <CustomDatePicker
              isDateTime={false}
              name="date_to"
              placeholder="Окончание:"
              value={date_to}
              error={error.date_to}
              onChange={onChange}
            />
          </Box>
          {(isCarrierStats || isMonthlyReport || isApplication) && (
            <AsyncAutocomplete
              sx={{ width: '100%' }}
              label={districtLabel}
              name="district"
              optionField={DEFAULT_AUTOCOMPLETE_FIELD}
              request={getDistrictsRequest}
              value={district}
              onChange={onChange}
            />
          )}
          {(isApplication || isVehicles) && (
            <CustomCheckbox
              label="Подключен к РИНС ЛО"
              value={is_connected_rins}
              onChange={onChange}
              name="is_connected_rins"
            />
          )}
          {isApplication && (
            <>
              <AsyncAutocomplete
                sx={{ width: '100%' }}
                label="Субъект-отходообразователь"
                name="subject_waste_generator_id"
                value={subject_waste_generator_id}
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                onChange={onChange}
              />
              <AsyncAutocomplete
                sx={{ width: '100%' }}
                label="Поселение образования"
                name="city_waste_generator"
                request={getCitiesRequest}
                value={city_waste_generator}
                onChange={onChange}
              />
              <AsyncAutocomplete
                sx={{ width: '100%' }}
                label="Вид отхода (ФККО)"
                name="waste_type_id"
                optionField={DEFAULT_SELECTOR_FIELDS}
                request={getWasteTypesRequest}
                value={waste_type_id}
                onChange={onChange}
              />
              <Selector
                multiple
                sx={{ width: '100%' }}
                label="Класс отходов"
                name={HAZARD_CLASS_ENUM}
                optionField={DEFAULT_SELECTOR_FIELDS}
                request={(params) => getApplicationEnumsRequest(
                  HAZARD_CLASS_ENUM,
                  params,
                  (options) => options.filter((item) => item.id !== 'one' && item.id !== 'two'),
                )}
                value={hazard_class}
                onChange={onChange}
              />
              <AsyncAutocomplete
                sx={{ width: '100%' }}
                label="Субъект, принявший отходы"
                name="subject_waste_recipient_id"
                request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
                value={subject_waste_recipient_id}
                onChange={onChange}
              />
            </>
          )}
          {(isObjectivesWasteRecipient || isApplication) && (
            <AsyncAutocomplete
              sx={{ width: '100%' }}
              label="Назначение объекта, на который приняты отходы"
              name="waste_management_type"
              request={(params) => getReferencesRequest({
                url: WASTE_MANAGEMENT_TYPES_URL,
                params,
              })}
              value={waste_management_type}
              onChange={onChange}
            />
          )}
          {isObjectivesWasteRecipient && (
            <AsyncAutocomplete
              sx={{ width: '100%' }}
              label="Объект, принявший отходы"
              request={(params) => getObjectivesRequest({ params })}
              name="objective_id"
              value={objective_id}
              onChange={onChange}
            />
          )}
          {!isObjectivesWasteRecipient && (
            <AsyncAutocomplete
              sx={{ width: '100%' }}
              label="Субъект транспортировщик отходов"
              name="subject"
              error={error.subject?.id}
              value={subject}
              request={(params) => getSubjectsRequest({ params: { ...params, total: 100 } })}
              onChange={onChange}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <CustomRadioGroup
          required
          onChange={onChange}
          value={format_type}
          name="format_type"
          error={error.format_type}
          title="Формат отчета"
          subTitle="Выберите в каком формате будет сформирован отчет:"
          options={[
            { label: 'PDF', value: 'pdf' },
            { label: 'XLSX', value: 'xlsx' },
          ]}
        />
        <Submit value="Создать отчет" onClick={makeRequest} sx={{ height: '50px', width: '200px' }} />
      </Box>
    </Box>
  );
}
