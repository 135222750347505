/* eslint-disable no-undef */
// @ts-nocheck
import React from 'react';
import { Box } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { TextMain, TextSignature, TextTitle } from '../Text';
import { getDayMonthName } from '../../helpers/dateHelper';
import CustomIconButton from '../Buttons/CustomIconButton';
import { useUserStore } from '../../store';
import { isWebView } from '../../constants';
import { readToken } from '../../helpers';
import { PUBLIC_APPLICATION_ROUTE } from '../../constants/routes';

const AUTH_TOKEN_ID = 'AuthToken';
const FILE_LINK_ID = 'fileLink';
const FILE_NAME_ID = 'fileName';
const FILE_EXTENSION_ID = 'fileExtension';

export default function ApplicationSubjectInfo({
  name, inn, ogrn, created_at, address, documents = [], index, objectName,
}) {
  const { pathname } = useLocation();
  const { userStore } = useUserStore();

  const webviewDownloadFile = (fileLink, fileName, fileExtension) => {
    // <-- minimal documentation -->
    // flutter JavascriptChannel name 'DownloadApplication'
    // flutter app will react to this event in Webview, check JavascriptChannel method in flutter app
    DownloadApplication.postMessage(`EMPTY${FILE_LINK_ID}${fileLink}${FILE_LINK_ID}${FILE_NAME_ID}${fileName}${FILE_NAME_ID}${FILE_EXTENSION_ID}${fileExtension}${FILE_EXTENSION_ID}${AUTH_TOKEN_ID}${readToken()}${AUTH_TOKEN_ID}`);
  };

  // you need to check userAgent of flutter webview in react side
  // we can change userAgent name to any other, check Webview Widget in flutter app
  const getWebviewProps = (fileLink, fileName, extension) => (isWebView ? {
    href: undefined,
    download: false,
    onClick: () => webviewDownloadFile(fileLink, fileName, extension),
  } : {});

  return (
    <Box>
      <TextSignature small>
        {getDayMonthName(created_at)}
      </TextSignature>
      <TextTitle sx={{ maxWidth: 320 }}>
        {index !== undefined && `${index + 1}.`}
        {' '}
        Объект:
        {' '}
        {objectName}
        {' '}
        {index === 0 && '(Начальная точка)'}
      </TextTitle>
      <TextSignature small sx={{ maxWidth: 320 }}>
        Субъект:
        {' '}
        <TextMain component="span">{name}</TextMain>
      </TextSignature>
      <TextSignature small>
        ИНН:
        <TextMain component="span">{inn}</TextMain>
      </TextSignature>
      <TextSignature small>
        ОГРН:
        <TextMain component="span">{ogrn}</TextMain>
      </TextSignature>
      <TextSignature sx={{ maxWidth: 320 }}>
        Адрес:
        {' '}
        <TextMain component="span">{address}</TextMain>
      </TextSignature>
      {(pathname?.includes?.(PUBLIC_APPLICATION_ROUTE) || userStore.id) && documents.map(({ document, documentName, extension }) => (
        <CustomIconButton
          key={document}
          sx={{ width: 'fit-content' }}
          Icon={Download}
          variant="outlined"
          target="_blank"
          href={document}
          download
          {...getWebviewProps(document, documentName, extension)}
        >
          {documentName}
        </CustomIconButton>
      ))}
    </Box>
  );
}
