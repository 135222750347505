// @ts-nocheck
import { Box } from '@mui/material';
import React, { memo, useMemo, useState } from 'react';
import { OBJECTIVE_TYPES, SUBJECT_TYPE_ATTRIBUTES } from '../../../constants/enums';
import { getInitialForm1Item } from '../../../constants/lkCadastr/cadastrForm1';
import { OBJECT_STATUS } from '../../../constants/objectConstants';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import useGetReferenceItems from '../../../hooks/useGetRefferenceItems';
import AddButton from '../../Buttons/AddButton';
import CadastreForm1Building from '../../Cadastr/CadastreForm1Building';
import NavTabs from '../../Tabs';
import { TextMain } from '../../Text';
import ModalAddObject from '../ModalAddObject';

function ModalCadastrForm1({
  isDemo,
  data: { items: buildings },
  onChange,
  changeItemFormByField,
  wasteExcludeAccumulationParams,
  enterpriseAccumulationParam,
  wasteExcludeAccumulationCodes,
  subjectAttributes,
  onChangeForm1Entity,
  deleteForm1Entity,
}) {
  const [createObjectIndex, setCreateObjectIndex] = useState(null);

  const { getReferenceTypeIdsByCode: getObjectiveTypeIdsByCode } = useGetReferenceItems({
    referenceUrl: OBJECTIVE_TYPES,
  });

  const objectTypeIds = getObjectiveTypeIdsByCode(['mkd']);

  const changeItem = (field, value, buildingForm1Index, wasteForm1Index, platformForm1Index) => {
    onChange({
      field: `items.${field}`, value, buildingForm1Index, wasteForm1Index, platformForm1Index,
    });
  };

  const addNewBuilding = async () => {
    changeItemFormByField({ field: 'form1.items', value: [...buildings, getInitialForm1Item()] });
  };

  const { tabs: buildingTabs, tab: buildingTab, setTab: setBuildingTab } = useCadastreTabs({
    items: buildings,
    tabName: 'Здание/Помещение/Сооружение',
  });

  const newObjectName = useMemo(() => {
    if (createObjectIndex !== null) {
      if (createObjectIndex.platformIndex !== undefined) {
        return buildings[createObjectIndex.buildingIndex]
          .platforms[createObjectIndex.platformIndex].objective_id.name;
      }
      return buildings[createObjectIndex.buildingIndex].objective_id?.name;
    }
  }, [createObjectIndex, buildings]);

  const setNewObject = (newObject) => {
    let field = 'objective_id';

    if (createObjectIndex.platformIndex !== undefined) field = `platforms.${createObjectIndex.platformIndex}.objective_id`;

    changeItem(
      `${createObjectIndex.buildingIndex}.${field}`,
      {
        ...newObject,
        label: formatObjectWithAddress({ objective: newObject }),
      },
      createObjectIndex.buildingIndex,
      undefined,
      createObjectIndex.platformIndex,
    );
  };

  const { openDeleteModal } = useDeleteCadastreEntity();

  const isPlatformObject = typeof createObjectIndex?.platformIndex === 'number';

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <NavTabs
        tabsComponents={buildingTabs}
        setTab={setBuildingTab}
        tab={buildingTab}
      />
      {createObjectIndex !== null && (
        <ModalAddObject
          showMap={isPlatformObject}
          objectTypeTitle=""
          close={() => setCreateObjectIndex(null)}
          modalAddressTitle={isPlatformObject ? 'для площадки' : ''}
          helpComponent={(
            <Box mb={2}>
              <TextMain>
                Примеры наименования объекта
              </TextMain>
              <TextMain>
                1) Объект образования отходов ...
              </TextMain>
              <TextMain>
                2) Объекта обращения с отходами ...
              </TextMain>
              <TextMain>
                3) Производственная площадка ...
              </TextMain>
            </Box>
          )}
          division="internal"
          status={OBJECT_STATUS.DRAFT}
          subjectData={{
            subject: subjectAttributes,
            inn: { inn: subjectAttributes.inn },
            subject_type: subjectAttributes?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
            name: newObjectName,
            address: {
              label: buildings[createObjectIndex.buildingIndex].platforms[createObjectIndex.platformIndex]?.objective_id?.label,
              ...buildings[createObjectIndex.buildingIndex].platforms[createObjectIndex.platformIndex]?.objective_id?.dadataAddress,
            },
          }}
          wasteExcludeAccumulationCodes={createObjectIndex.platformIndex === undefined && wasteExcludeAccumulationCodes}
          setNewObject={setNewObject}
        />
      )}
      {!isDemo && (
        <AddButton variant="outlined" onClick={addNewBuilding}>Добавить Здание/Помещение/Сооружение</AddButton>
      )}
      {buildings.map((building, buildingIndex) => {
        if (building.randId !== buildingTab) return null;

        const changeItemData = (field, value, wasteIndex, platformIndex) => changeItem(`${buildingIndex}.${field}`, value, buildingIndex, wasteIndex, platformIndex);

        return (
          <CadastreForm1Building
            key={building.randId}
            objectTypeIds={objectTypeIds}
            onChangeForm1Entity={onChangeForm1Entity}
            deleteForm1Entity={deleteForm1Entity}
            enterpriseAccumulationParam={enterpriseAccumulationParam}
            wasteExcludeAccumulationParams={wasteExcludeAccumulationParams}
            building={building}
            buildings={buildings}
            openDeleteModal={openDeleteModal}
            buildingIndex={buildingIndex}
            changeItemData={changeItemData}
            isDemo={isDemo}
            changeItemFormByField={changeItemFormByField}
            subjectAttributes={subjectAttributes}
            setCreateObjectIndex={setCreateObjectIndex}
          />
        );
      })}
    </Box>
  );
}

export default memo(ModalCadastrForm1);
