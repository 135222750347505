import { useState } from 'react';
import { downloadBlobFileOnClick } from '../helpers';
import api from '../api/api';
import { useApi } from './useApi';

export const useDownloadFile = ({ url, fileName, setLoading }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async (prop) => {
    const apiParams = {};

    const mainUrl = url || prop?.url;

    // has direct link like http://192.168.0.1 ...
    if (mainUrl.includes('http')) {
      apiParams.baseURL = mainUrl;
    } else {
      apiParams.url = mainUrl;
    }

    const { data } = await api({
      ...apiParams,
      responseType: 'blob',
    });

    if (prop.download !== false) downloadBlobFileOnClick(data, fileName);

    return data;
  };

  const { defaultRequest } = useApi({
    setIsLoading: setLoading || setIsLoading,
    request: downloadFile,
  });

  return { makeRequest: defaultRequest, isLoading };
};
