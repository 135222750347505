import api from './api';
import { OPERATION_TYPES_URL, OPERATIONS_URL } from '../constants/urls';

export const getOperationsRequest = (params, setTableStore) => api({
  url: OPERATIONS_URL,
  params,
}, { isEntityAttributes: true, setTableStore });

export const getOperationTypesRequest = (params) => api({
  url: OPERATION_TYPES_URL,
  params,
}, { isEntityAttributes: true });
