import React, { memo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import CustomAutocomplete, { DEFAULT_AUTOCOMPLETE_FIELD } from '../Inputs/CustomAutocomplete';
import { useAddressRequest } from '../../hooks';
import { TextSignature } from '../Text';
import AddButton from '../Buttons/AddButton';
import ModalAddAddress from '../Modals/ModalAddAddress';

export const AddressAutocomplete = memo(({
  id,
  label = 'Адрес',
  sx,
  onChange,
  value,
  error,
  name = 'address',
  sxBox,
  containerSx,
  disabled,
  required,
  filter,
  optionField,
  setNewAddress,
  hasExample = true,
}) => {
  const [addresses, setAddresses] = useState(null);
  const [isOpenAddress, setIsOpenAddress] = useState(false);

  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const { isLoading, makeRequest } = useAddressRequest({
    setter: (newAddresses) => setAddresses(filter ? filter(newAddresses) : newAddresses),
  });

  const openAddressModal = () => setIsOpenAddress(true);

  const closeAddressModal = () => setIsOpenAddress(false);

  return (
    <Box display="flex" flexDirection="column" sx={sxBox}>
      {isOpenAddress && <ModalAddAddress setNewAddress={setNewAddress} close={closeAddressModal} />}
      <CustomAutocomplete
        multiline
        required={required}
        containerSx={containerSx}
        label={label}
        loading={isLoading}
        id={id}
        sx={{ width: tabletSizeAndLower ? '100%' : '900px', ...sx }}
        error={error}
        name={name}
        noOptionComponent={setNewAddress ? (
          <AddButton variant="outlined" onClick={openAddressModal}>
            Создать адрес
          </AddButton>
        ) : undefined}
        options={addresses}
        fields={optionField || DEFAULT_AUTOCOMPLETE_FIELD}
        asyncSearch={(params) => makeRequest({ params })}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {hasExample && (
        <TextSignature sx={{ pl: 2, wordBreak: 'break-all', whiteSpace: 'wrap' }}>
          Формат: Ленинградская область,
          {' '}
          Всеволожский район, Сертолово,
          {' '}
          Школьная улица, 99
        </TextSignature>
      )}
    </Box>
  );
});
