// @ts-nocheck
import { METHODS_API } from '../../api/api';
import {
  cadastreAreasRequest,
  cadastreContainerRequest,
  cadastreForm1BuildingsRequest,
  cadastreReportBuildingsRequest,
  cadastreWasteRequest,
} from '../../api/cadastreApi';
import { OWNERSHIP_TYPE_TRANSLATE } from '../../constants';
import {
  CONTAINER_MATERIALS,
  getInitialContainer,
  getInitialForm1Item,
  getInitialPlatforms,
  getInitialWasteProduct,
} from '../../constants/lkCadastr/cadastrForm1';
import { compareId } from '../compareId';
import { getHazardClassObject } from '../entityHelper';
import { sortByCreatedAt } from '../sortHelper';
import { formatObjectWithAddress, valueToString } from '../stringHelper';
import { getReportWastesHelper } from './form2Helper';
import { getReportGenerationsHelper } from './form3Helper';

export const parsePlatform = (platform) => ({
  ...getInitialPlatforms(),
  ...platform,
  containers: !platform.containers_attributes?.length ? [getInitialContainer()] : platform.containers_attributes.map((container) => ({
    container_material: CONTAINER_MATERIALS.find((material) => material.value === container?.attributes?.container_material) || '',
    container_capacity: valueToString(container?.attributes?.container_capacity),
    container_quantity: valueToString(container?.attributes?.container_quantity),
    id: container.id,
  })),
  objective_id: {
    ...platform.objective_attributes,
    ...platform.objective_attributes?.attributes,
    label: formatObjectWithAddress({ objective: platform }),
  },
});

export const getBuildingsHelper = async (report) => {
  if (report?.report_building_ids?.length) {
    const allBuildings = [];

    const buildingsByIds = await cadastreReportBuildingsRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    const wastesByIds = await cadastreWasteRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    const areasByIds = await cadastreAreasRequest({
      params: {
        report_history_ids: report?.id,
      },
    });

    let form2ReportWastes = [];
    form2ReportWastes = await getReportWastesHelper(report);

    let form3ReportWastes = [];

    form3ReportWastes = await getReportGenerationsHelper(report);

    const pushedForm2WasteIds = [];
    const pushedForm3WasteIds = [];

    buildingsByIds.forEach((building) => {
      const buildingId = building.id;
      const currentAreas = areasByIds
        .filter((item) => compareId(item.report_building_id, buildingId));

      const currentWastes = wastesByIds
        .filter((item) => compareId(item.report_building_id, buildingId));

      const additionalForm1Items = {};

      if (currentAreas.length) {
        additionalForm1Items.platforms = currentAreas.map(parsePlatform);
      }

      if (building.lease_contract_date || building.lease_contract_number) {
        additionalForm1Items.ownership_type = OWNERSHIP_TYPE_TRANSLATE.rent;

        if (!building.lease_contract_date) additionalForm1Items.lease_contract_date = '0';
      } else {
        additionalForm1Items.ownership_type = OWNERSHIP_TYPE_TRANSLATE.own;
      }

      if (currentWastes.length) {
        additionalForm1Items.waste_products = sortByCreatedAt(currentWastes)
          .map((waste) => {
            const form2Waste = form2ReportWastes
              ? form2ReportWastes.find((reportWaste) => {
                const ACTUAL_COMPARE = compareId(reportWaste?.waste_id, waste.id);

                const wasteWasteTypeId = waste.waste_type_id?.id || waste.waste_type_id;

                // TODO delete after every report_waste has waste_id
                const LEGACY_COMPARE = ((compareId(reportWaste.name_code_waste, wasteWasteTypeId))
                  && !pushedForm2WasteIds.some((wasteId) => compareId(wasteId, reportWaste.id)));

                return reportWaste?.waste_id ? ACTUAL_COMPARE : LEGACY_COMPARE;
              })
              : undefined;

            if (form2Waste?.id) pushedForm2WasteIds.push(form2Waste.id);

            const form3Waste = form3ReportWastes?.find?.((form3WasteItem) => {
              if (form3WasteItem.is_accepting) return false;

              const ACTUAL_COMPARE = compareId(form3WasteItem?.waste_id, waste.id);

              // TODO delete after every report_generation has waste_id
              const getLegacyCompareResult = () => {
                const itemWasteTypeId = form3WasteItem.waste_type_id?.id || form3WasteItem.waste_type_id;

                const isSameWasteType = compareId(itemWasteTypeId, waste.waste_type_id)
                  && !pushedForm3WasteIds.some((wasteId) => compareId(wasteId, form3WasteItem.id));

                if (form3WasteItem.report_building_id) {
                  return isSameWasteType && compareId(form3WasteItem.report_building_id, buildingId);
                }
                return isSameWasteType;
              };

              return form3WasteItem?.waste_id ? ACTUAL_COMPARE : getLegacyCompareResult();
            });

            if (form3Waste?.id) pushedForm3WasteIds.push(form3Waste.id);

            const wasteAttribute = waste?.waste_type_attributes?.attributes;

            const additionalWasteFormInfo = {};

            if (form2Waste) additionalWasteFormInfo.form2Waste = form2Waste;
            if (form3Waste) additionalWasteFormInfo.form3Waste = form3Waste;

            const newWaste = {
              ...getInitialWasteProduct(),
              ...waste,
              code: {
                ...waste?.waste_type_attributes,
                ...wasteAttribute,
                value: waste?.waste_type_attributes?.id || '',
                label: wasteAttribute?.code ? `${wasteAttribute?.code} ${wasteAttribute?.name}` : '',
                ...getHazardClassObject(waste),
              },
              ...additionalWasteFormInfo,
              buildingId,
              quantity: valueToString(waste?.quantity),
              id: waste.id,
            };

            delete newWaste.report_building_attributes;

            return newWaste;
          });
      }

      const enterprise_activity_types = [];

      if (building?.enterprise_activity_types_attributes?.length) {
        building?.enterprise_activity_types_attributes.forEach((enterprise_activity_type) => {
          enterprise_activity_types.push({
            label: enterprise_activity_type?.attributes?.name,
            value: enterprise_activity_type?.id,
            id: enterprise_activity_type?.id,
          });
        });
      } else {
        enterprise_activity_types.push('0');
      }

      allBuildings.push({
        ...getInitialForm1Item(),
        ...building,
        ...additionalForm1Items,
        total_area: valueToString(building.total_area),
        lease_contract_number: valueToString(building.lease_contract_number),
        objective_id: {
          ...building?.objective_attributes,
          ...building?.objective_attributes?.attributes,
          label: !building?.objective_attributes ? '0' : formatObjectWithAddress({ objective: building }),
        },
        enterprise_activity_types,
      });
    });

    return { allBuildings, form3ReportWastes: form3ReportWastes?.filter((item) => item.is_accepting) || [] };
  }
  return {};
};

export const deleteForm1Building = async ({ buildingId }) => {
  const deletedBuilding = await cadastreReportBuildingsRequest({
    method: METHODS_API.DELETE,
    id: buildingId,
  });

  return deletedBuilding?.id;
};

export const deleteForm1Waste = async ({ wasteId }) => {
  const deletedWaste = await cadastreWasteRequest({
    method: METHODS_API.DELETE,
    id: wasteId,
  });

  return deletedWaste?.id;
};

export const deleteForm1Container = async ({ containerId }) => {
  const deletedContainer = await cadastreContainerRequest({
    method: METHODS_API.DELETE,
    id: containerId,
  });

  return deletedContainer?.id;
};

export const deleteForm1Area = async ({ areaId }) => {
  const deletedArea = await cadastreAreasRequest({
    method: METHODS_API.DELETE,
    id: areaId,
  });

  return deletedArea?.id;
};

export const deleteForm1Entities = async ({
  initialForms,
  forms,
  fieldPropsDelete = {},
}) => {
  const mainForms = initialForms || forms;

  for await (const initialBuilding of mainForms.form1.items) {
    if (initialBuilding?.id) {
      const actualBuilding = forms.form1.items
        .find((building) => compareId(building.id, initialBuilding.id));

      const hasBuilding = compareId(initialBuilding.id, fieldPropsDelete?.buildingId);

      if (fieldPropsDelete?.buildingId && !hasBuilding) continue;

      if (!actualBuilding || hasBuilding) {
        await cadastreReportBuildingsRequest({
          method: METHODS_API.DELETE,
          id: fieldPropsDelete?.buildingId || initialBuilding.id,
        });
        if (fieldPropsDelete?.buildingId) return fieldPropsDelete?.buildingId;
      } else {
        for await (const platform of initialBuilding.platforms) {
          const foundActualPlatform = actualBuilding.platforms
            .find((actualPlatform) => compareId(actualPlatform.id, platform.id));

          const shouldRemovePlatform = !foundActualPlatform;

          const hasPlatform = compareId(platform?.id, fieldPropsDelete.platformId);

          if (fieldPropsDelete?.platformId && !hasPlatform) continue;

          if (shouldRemovePlatform || hasPlatform) {
            await cadastreAreasRequest({
              method: METHODS_API.DELETE,
              id: fieldPropsDelete?.platformId || platform.id,
            });

            if (fieldPropsDelete?.platformId) return fieldPropsDelete?.platformId;
          }
        }

        for await (const wasteProduct of initialBuilding.waste_products) {
          const foundActualWaste = actualBuilding.waste_products
            .find((actualWaste) => compareId(actualWaste.id, wasteProduct.id));

          const removedWaste = !foundActualWaste;

          const hasWaste = compareId(wasteProduct.id, fieldPropsDelete?.wasteId);

          if (fieldPropsDelete?.wasteId && !hasWaste) continue;

          if (removedWaste || hasWaste) {
            await cadastreWasteRequest({
              method: METHODS_API.DELETE,
              id: fieldPropsDelete?.wasteId || wasteProduct.id,
            });

            if (fieldPropsDelete?.wasteId) return fieldPropsDelete?.wasteId;
          }
        }
      }
    }
  }
};

export const createUpdateForm1Entities = async ({
  forms,
  reportId,
}) => {
  for await (const itemsData of forms.form1.items) {
    try {
      const isOwn = itemsData.ownership_type === OWNERSHIP_TYPE_TRANSLATE.own;

      const form1Data = {
        objective_id: itemsData.objective_id?.id || '',
        building: itemsData.building,
        lease_contract_date: isOwn ? '' : valueToString(itemsData.lease_contract_date),
        lease_contract_number: isOwn ? '' : itemsData.lease_contract_number,
        enterprise_activity_type_ids: itemsData.enterprise_activity_types.map(({ id }) => id),
        total_area: itemsData.total_area,
        report_history_id: reportId,
      };

      let buildingId = itemsData.id;

      const createdBuilding = await cadastreReportBuildingsRequest({
        data: form1Data,
        method: buildingId ? METHODS_API.PUT : METHODS_API.POST,
        id: buildingId,
      });

      buildingId = createdBuilding?.id;

      if (!buildingId) continue;

      for await (const platform of itemsData.platforms) {
        try {
          const platformData = {
            objective_id: platform?.objective_id?.id || '',
            address_id: platform.objective_id?.address_attributes?.id || '',
            area_number: platform.area_number,
            report_building_id: buildingId,
          };

          await cadastreAreasRequest({
            data: platformData,
            method: platform.id ? METHODS_API.PUT : METHODS_API.POST,
            id: platform.id,
          });
        } catch (e) {
          console.error(e);
        }
      }

      for await (const wasteProduct of itemsData.waste_products) {
        try {
          const wasteProductData = {
            waste_type_id: wasteProduct?.code?.id || '',
            hazard_class: wasteProduct?.code?.hazard_class || '',
            quantity: wasteProduct.quantity,
            report_building_id: buildingId,
          };

          await cadastreWasteRequest({
            data: wasteProductData,
            method: wasteProduct.id ? METHODS_API.PUT : METHODS_API.POST,
            id: wasteProduct.id,
          });
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveForm1Buildings = async ({
  forms,
  reportId,
  isImportToExistingReport,
}) => {
  const finalForm = forms.form1.items.map((building) => {
    const isOwn = building.ownership_type === OWNERSHIP_TYPE_TRANSLATE.own;

    const buildingId = building.id;

    const form1Data = {
      objective_id: building.objective_id?.id || '',
      building: building.building,
      lease_contract_date: isOwn ? '' : valueToString(building.lease_contract_date),
      lease_contract_number: isOwn ? '' : building.lease_contract_number,
      enterprise_activity_type_ids: building.enterprise_activity_types?.[0] === '0' ? '' : building.enterprise_activity_types.map(({ id }) => id),
      total_area: building.total_area,
      wastes: building.waste_products.map((wasteProduct) => {
        const wasteId = wasteProduct.id;

        const newWaste = {
          waste_type_id: wasteProduct?.code?.id || '',
          hazard_class: wasteProduct?.code?.hazard_class || '',
          quantity: Number(wasteProduct.quantity),
        };

        if (!isImportToExistingReport && wasteId) newWaste.id = wasteId;

        return newWaste;
      }),
      areas: building.platforms.map((area) => {
        const areaId = area.id;

        const containers = area.containers.map((container) => {
          const newContainer = {
            container_capacity: container.container_capacity,
            container_material: container.container_material?.value || '',
            container_quantity: container.container_quantity,
          };

          if (!isImportToExistingReport && container.id) newContainer.id = container.id;

          return newContainer;
        });

        const newArea = {
          objective_id: area?.objective_id?.id || '',
          address_id: area.objective_id?.address_attributes?.id || '',
          area_number: area.area_number,
          containers,
          report_building_id: buildingId,
        };

        if (!isImportToExistingReport && areaId) newArea.id = areaId;

        return newArea;
      }),
      report_history_id: reportId,
    };

    if (!isImportToExistingReport && buildingId) form1Data.id = buildingId;

    return form1Data;
  });

  const form1Response = await cadastreForm1BuildingsRequest({
    method: METHODS_API.POST,
    data: finalForm,
    isEntityAttributes: false,
  });

  return form1Response?.data;
};
