// @ts-nocheck
import React from 'react';
import { Alert, Box } from '@mui/material';
import useCadastreTabs from '../../hooks/useCadastreTabs';
import { getInitialWasteTransfer } from '../../constants/lkCadastr/cadastrForm3';
import { AsyncAutocomplete } from '../AutoCompletes/AsyncAutocomplete';
import AddButton from '../Buttons/AddButton';
import { TextTitle } from '../Text';
import {
  fitContent, flexGap2, flexRowGap2, fullWidth,
} from '../../constants/lkCadastr/cadastrStorage';
import Tabs from '../Tabs';
import { getSubjectsRequest } from '../../api/subjectApi';
import CustomInput from '../Inputs';
import { precisionAdd } from '../../helpers/numberHelper';
import CustomDatePicker from '../CustomDatePicker';
import { deleteForm3WasteTransfer } from '../../helpers/cadastreForms/form3Helper';
import DeleteButton from '../Buttons/DeleteButton';

export function CadastreForm3WasteTransfer({
  items,
  isDemo,
  setCreateSubjectField,
  changeItem,
  index: reportGenerationIndex,
  buildingIndex,
  openDeleteModal,
  mainField,
  f3waste,
  wastequant,
  isBuilding,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items, tabName: 'Лицо, принимающее отходы' });

  const addNewWasteTransfer = () => {
    changeItem(
      'waste_transfers',
      [...items, getInitialWasteTransfer()],
    );
  };

  function getavailable_on_organisation(wasteTransfer) {
    const xTotal = precisionAdd(
      f3waste.availability_waste_period,
      wastequant,
      Number(f3waste.get_processing) * -1,
      Number(f3waste.get_utilization) * -1,
      Number(f3waste.get_neutralization) * -1,
      Number(isBuilding ? 0 : f3waste.get_all),
      Number(items.reduce((x, i) => precisionAdd(x, i.out_processing, i.out_utilization, i.out_neutralization, i.out_for_store, i.out_under_store), 0)) * -1,
      Number(wasteTransfer.store) * -1,
      Number(wasteTransfer.under_store) * -1,
    );

    return xTotal;
  }

  return (
    <Box sx={flexGap2}>
      <TextTitle h3>Передача отходов другим организациям</TextTitle>
      <Box sx={flexGap2}>
        <Alert severity="info">
          Для поиска организации в справочнике введите наименование или ИНН в графу «Юридическое лицо (индивидуальный предприниматель) принявшее отходы», в случае отсутствия нажмите кнопку «+Создать субъект» и добавьте организацию в справочник. Если отход не передавался, введите «0».
        </Alert>
        {!isDemo && (
          <AddButton variant="outlined" onClick={addNewWasteTransfer}>
            Добавить лицо, принимающее отходы
          </AddButton>
        )}
        <Tabs tabsComponents={tabs} setTab={setTab} tab={tab} />
        {items?.map?.((wasteTransfer, wasteTransferIndex) => {
          if (wasteTransfer.randId !== tab) return null;

          const changeTransferItem = (field, value) => {
            changeItem(`waste_transfers.${wasteTransferIndex}.${field}`, value);
          };


          // eslint-disable-next-line no-lone-blocks
          {
            wasteTransfer.available_on_organisation = getavailable_on_organisation(wasteTransfer);
          }

          const onDeleteWasteTransfer = async () => {
            const cloneArray = items.slice();

            if (wasteTransfer.id) {
              const deletedId = await deleteForm3WasteTransfer({ wasteTransferId: wasteTransfer.id });

              if (!deletedId) return;
            }

            cloneArray.splice(wasteTransferIndex, 1);

            changeItem(
              'waste_transfers',
              cloneArray,
              { type: 'delete' },
            );
          };

          return (
            <Box sx={flexGap2} key={wasteTransfer.randId}>
              <Box sx={flexRowGap2}>
                <TextTitle h2>
                  Лицо, принимающее отходы №
                  {wasteTransferIndex + 1}
                </TextTitle>
                {!isDemo && items.length > 0 && (
                  <DeleteButton onClick={() => openDeleteModal(onDeleteWasteTransfer, wasteTransferIndex, 'Лицо, принимающее отходы')}>
                    Удалить лицо, принимающее отходы
                  </DeleteButton>
                )}
              </Box>
              <AsyncAutocomplete
                validImmediately={wasteTransfer.subject_id?.name !== '0'}
                debounceTime={0}
                disabled={isDemo}
                sx={fullWidth}
                request={(params) => getSubjectsRequest({ params })}
                label="Юридическое лицо (индивидуальный предприниматель), принявшее отходы"
                value={wasteTransfer.subject_id}
                noOptionComponent={(
                  <AddButton
                    variant="outlined"
                    onClick={() => setCreateSubjectField({
                      field: `${mainField}.waste_transfers.${wasteTransferIndex}.subject_id`,
                      reportGenerationIndex,
                      buildingIndex,
                    })}
                  >
                    Создать субъект
                  </AddButton>
                )}
                onChange={(e, value) => changeTransferItem('subject_id', value)}
              />
              <CustomInput
                disabled
                sx={fitContent}
                label="ИНН"
                value={wasteTransfer.subject_id?.inn}
              />
              <Box display="flex" alignItems="center" flexWrap="flex" gap={5}>
                <CustomInput
                  validImmediately
                  debounceTime={0}
                  disabled={isDemo}
                  label="Номер договора"
                  value={wasteTransfer.document_number}
                  onChange={(e, value) => changeTransferItem('document_number', value)}
                />
                <CustomDatePicker
                  validImmediately={wasteTransfer.document_number !== '0'}
                  disabled={isDemo || wasteTransfer.document_number === '0'}
                  label="Дата договора"
                  value={wasteTransfer.document_number === '0' ? null : wasteTransfer.application_date}
                  onChange={(e, value) => changeTransferItem('application_date', value)}
                />
              </Box>
              <TextTitle h3>
                Всего:
                {
                  precisionAdd(
                    wasteTransfer.out_processing,
                    wasteTransfer.out_utilization,
                    wasteTransfer.out_neutralization,
                    wasteTransfer.out_for_store,
                    wasteTransfer.out_under_store,
                  )
                }
              </TextTitle>
              <Box sx={flexGap2}>
                <TextTitle>Из них</TextTitle>
                <Box sx={flexRowGap2}>
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fitContent}
                    label="Обработка"
                    type="number"
                    value={wasteTransfer.out_processing}
                    onChange={(e, value) => changeTransferItem('out_processing', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fitContent}
                    label="Утилизация"
                    type="number"
                    value={wasteTransfer.out_utilization}
                    onChange={(e, value) => changeTransferItem('out_utilization', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fitContent}
                    type="number"
                    label="Обезвреживание"
                    value={wasteTransfer.out_neutralization}
                    onChange={(e, value) => changeTransferItem('out_neutralization', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fitContent}
                    label="Для хранения"
                    type="number"
                    value={wasteTransfer.out_for_store}
                    onChange={(e, value) => changeTransferItem('out_for_store', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    sx={fitContent}
                    label="Для захоронения"
                    type="number"
                    value={wasteTransfer.out_under_store}
                    onChange={(e, value) => changeTransferItem('out_under_store', value)}
                  />
                </Box>
              </Box>
              <Box sx={flexGap2}>
                <TextTitle h3>
                  Размещение отходов на собственных объектах за отчётный год
                </TextTitle>
                <TextTitle h3>
                  Всего:
                  {precisionAdd(wasteTransfer.store, wasteTransfer.under_store)}
                </TextTitle>
                <Box sx={flexGap2}>
                  <TextTitle>Из них</TextTitle>
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    label="Хранение"
                    type="number"
                    value={wasteTransfer.store}
                    onChange={(e, value) => changeTransferItem('store', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled={isDemo}
                    label="Захоронение"
                    type="number"
                    value={wasteTransfer.under_store}
                    onChange={(e, value) => changeTransferItem('under_store', value)}
                  />
                  <CustomInput
                    validImmediately
                    debounceTime={0}
                    disabled
                    label="Наличие на предприятии на конец отчётного года"
                    type="number"
                    value={wasteTransfer.available_on_organisation}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
