import { Box } from '@mui/material';
import React from 'react';
import { formatAddressString } from '../../../../helpers/stringHelper';
import CloseModalButton from '../../../Buttons/CloseModalButton';
import { TextMain } from '../../../Text';
import TitleSubtitle from '../../../TitleSubtitle';
import style from './slider.module.scss';

function ObjectInfoSlider({ onClose, info }) {
  return (
    <Box className={style.info_wrapper}>
      <CloseModalButton close={onClose} />
      <Box display="flex" flexDirection="column" gap={1}>
        <TitleSubtitle title="Наименование обьекта" subTitle={info.name} />
        <TitleSubtitle title="Адрес" subTitle={formatAddressString(info)} />
        <TitleSubtitle
          title="Вид обращения отходов"
          subTitle={
            info?.subject_attributes?.attributes?.enterprise_activity_types_attributes?.map((item) => (
              <TextMain big>{item?.attributes?.name}</TextMain>
            ))
          }
        />
        <TitleSubtitle title="ИНН субъекта" subTitle={info?.subject_attributes?.attributes?.inn} />
        <TitleSubtitle title="Наименование субъекта" subTitle={info?.subject_attributes?.attributes?.name} />
      </Box>
    </Box>
  );
}

export default ObjectInfoSlider;
