// @ts-nocheck
import React from 'react';
import { Box } from '@mui/material';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomInput from '../../../Inputs';
import AddButton from '../../../Buttons/AddButton';
import { getInitialSecondaryProducts } from '../../../../constants/lkCadastr/cadastrForm7';
import { AsyncAutocomplete } from '../../../AutoCompletes/AsyncAutocomplete';
import { getWasteTypesRequest } from '../../../../api/wasteTypeApi';
import { DEFAULT_AUTOCOMPLETE_FIELD } from '../../../Inputs/CustomAutocomplete';
import { flexGap2 } from '../../../../constants/lkCadastr/cadastrStorage';
import useCadastreTabs from '../../../../hooks/useCadastreTabs';
import NavTabs from '../../../Tabs';
import { deleteForm7Entity } from '../../../../helpers/cadastreForms/form7Helper';

const SECONDARY_PRODUCTS_NAME = 'secondary_products';

export function SecondaryProducts({
  secondaryProducts, changeItem, isDemo, openDeleteModal,
}) {
  const { tab, tabs, setTab } = useCadastreTabs({ items: secondaryProducts, tabName: 'Вторичный отход' });

  return (
    <Box sx={flexGap2}>
      {!isDemo && (
        <AddButton
          variant="outlined"
          onClick={() => changeItem(
            SECONDARY_PRODUCTS_NAME,
            [...secondaryProducts, getInitialSecondaryProducts()],
          )}
        >
          Добавить вторичный отход
        </AddButton>
      )}
      <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
      {secondaryProducts.map((secondaryWaste, secondaryWasteIndex) => {
        if (tab !== secondaryWaste.randId) return null;

        const change = (field, value) => changeItem(`${SECONDARY_PRODUCTS_NAME}.${secondaryWasteIndex}.${field}`, value, undefined, { secondaryWasteIndex });

        const onDelete = async () => {
          if (secondaryWaste?.id) {
            const deletedSecondaryWaste = await deleteForm7Entity({
              id: secondaryWaste.id,
              apiType: SECONDARY_PRODUCTS_NAME,
            });

            if (!deletedSecondaryWaste) return;
          }

          const cloneArray = secondaryProducts.slice();
          cloneArray.splice(secondaryWasteIndex, 1);

          changeItem(
            SECONDARY_PRODUCTS_NAME,
            cloneArray,
            'delete',
          );
        };

        const zeroWasteType = secondaryWaste.waste_type_id?.name === '0' || secondaryWaste.waste_type_id?.code === '0';
        const isZeroName = secondaryWaste.waste_type_id?.name === '0';
        const isZeroCode = secondaryWaste.waste_type_id?.code === '0' && !isZeroName;

        return (
          <Box key={secondaryWaste.randId} sx={flexGap2}>
            {!isDemo && secondaryProducts.length > 1 && (
              <DeleteButton onClick={() => openDeleteModal(onDelete, secondaryWasteIndex, 'Вторичный отход')}>
                Удалить вторичный отход
              </DeleteButton>
            )}
            <AsyncAutocomplete
              debounceTime={0}
              validImmediately={!zeroWasteType}
              disabled={isDemo || isZeroCode}
              sx={{ width: '100%' }}
              request={getWasteTypesRequest}
              value={secondaryWaste.waste_type_id}
              optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: isZeroCode ? 'code' : 'name' }}
              label="Наименование отхода по ФККО"
              onChange={(e, value) => change('waste_type_id', value)}
            />
            <AsyncAutocomplete
              debounceTime={0}
              validImmediately={!zeroWasteType}
              disabled={isDemo || isZeroName}
              sx={{ width: '100%' }}
              request={getWasteTypesRequest}
              value={secondaryWaste.waste_type_id}
              optionField={{ ...DEFAULT_AUTOCOMPLETE_FIELD, label: isZeroName ? 'name' : 'code' }}
              label="Код отхода по ФККО"
              onChange={(e, value) => change('waste_type_id', value)}
            />
            <CustomInput
              debounceTime={0}
              validImmediately
              disabled={isDemo}
              type="number"
              label="Количество, т/год"
              value={secondaryWaste.tons_per_yer}
              onChange={(e, value) => change('tons_per_yer', value)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
