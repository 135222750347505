import React from 'react';
import { Route } from 'react-router-dom';
import AdminNotifications from '../../pages/Admin/AdminNotifications';
import AdminOperationsPage from '../../pages/Admin/AdminOperationsPage';
import AdminSendMailPage from '../../pages/Admin/AdminSendMailPage';
import AdminTerSchemePage from '../../pages/Admin/AdminTerSchemePage';
import AdminWasteCadastrePage from '../../pages/Admin/AdminWasteCadastrePage';
import AnalyticsWasteCadastrePage from '../../pages/Admin/AnalyticsWasteCadastrePage';
import EnterpriseActivityTypeReferencePage from '../../pages/Admin/ReferenceInformation/EnterpriseActivityTypeReferencePage';
import FKKOReferencePage from '../../pages/Admin/ReferenceInformation/FKKOReferencePage';
import HazardClassesGuide from '../../pages/Admin/ReferenceInformation/HazardClassesGuide';
import OKTMOReferencePage from '../../pages/Admin/ReferenceInformation/OKTMOReferencePage';
import PremisesAppointmentsGuide from '../../pages/Admin/ReferenceInformation/PremisesAppointmentsGuide';
import SubjectTypeReferencePage from '../../pages/Admin/ReferenceInformation/SubjectTypeReferencePage';
import TypeEnterpriseGuide from '../../pages/Admin/ReferenceInformation/TypeEnterpriseGuide';
import WasteConditionsReferencePage from '../../pages/Admin/ReferenceInformation/WasteConditionsReferencePage';
import WasteManagementTypeReferencePage from '../../pages/Admin/ReferenceInformation/WasteManagementTypeReferencePage';
import RegistryApplicationsPage from '../../pages/Admin/RegistryApplicationsPage';
import RegistryObjects from '../../pages/Admin/RegistryObjectsPage';
import RegisterSubjects from '../../pages/Admin/RegistrySubjectsPage';
import RegistrySubscriptionsPage from '../../pages/Admin/RegistrySubscriptionsPage';
import RegistryTS from '../../pages/Admin/RegistryTS';
import UsersRolesPage from '../../pages/Admin/UsersRolesPage';
import AnalyticsPage from '../../pages/AnalyticsPage';
import {
  ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE,
  ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE,
  ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE,
  ADMIN_FKKO_GUIDE,
  ADMIN_HAZARD_CLASSES_GUIDE,
  ADMIN_NOTIFICATIONS_ROUTE,
  ADMIN_OKTMO_GUIDE,
  ADMIN_OPERATIONS_ROUTE,
  ADMIN_PREMISES_APPOINTMENT_GUIDE,
  ADMIN_REGISTRY_APPLICATIONS,
  ADMIN_REGISTRY_OBJECTS_ROUTE,
  ADMIN_REGISTRY_SUBJECTS_ROUTE,
  ADMIN_REGISTRY_TS,
  ADMIN_SEND_EMAIL_ROUTE,
  ADMIN_SUBJECT_TYPE_PAGE,
  ADMIN_SUBSCRIPTIONS_ROUTE,
  ADMIN_TER_SCHEME_ROUTE,
  ADMIN_TYPE_ENTERPRISE_GUIDE,
  ADMIN_USERS_ROLES_ROUTE,
  ADMIN_WASTE_CADASTRE_ROUTE,
  ADMIN_WASTE_CONDITION_ROUTE,
  ADMIN_WASTE_MANAGEMENT_GUIDE,
} from '../routes';

export const getAdminRoutes = (hasPrivilege) => (
  <>
    {(hasPrivilege('roles_read_all') || hasPrivilege('users_read_all')) && (
      <Route exact path={ADMIN_USERS_ROLES_ROUTE} element={<UsersRolesPage />} />
    )}
    {hasPrivilege('subjects_read_all') && (
      <Route exact path={ADMIN_REGISTRY_SUBJECTS_ROUTE} element={<RegisterSubjects />} />
    )}
    {hasPrivilege('objectives_read_all')
      && <Route exact path={ADMIN_REGISTRY_OBJECTS_ROUTE} element={<RegistryObjects />} />}
    {hasPrivilege('isMainAdmin') && <Route exact path={ADMIN_OPERATIONS_ROUTE} element={<AdminOperationsPage />} />}
    {hasPrivilege('isMainAdmin') && <Route exact path={ADMIN_NOTIFICATIONS_ROUTE} element={<AdminNotifications />} />}
    {hasPrivilege('send_mail') && (
      <Route exact path={ADMIN_SEND_EMAIL_ROUTE} element={<AdminSendMailPage />} />
    )}
    {hasPrivilege('applications_read_all') && (
      <Route
        exact
        path={ADMIN_REGISTRY_APPLICATIONS}
        element={<RegistryApplicationsPage />}
      />
    )}
    {hasPrivilege('admin_ter_scheme') && (
      <Route
        exact
        path={ADMIN_TER_SCHEME_ROUTE}
        element={<AdminTerSchemePage />}
      />
    )}
    {hasPrivilege('vehicles_read_all') && (
      <Route exact path={ADMIN_REGISTRY_TS} element={<RegistryTS />} />
    )}
    {hasPrivilege('reference_information') && (
      <>
        <Route
          exact
          path={ADMIN_WASTE_MANAGEMENT_GUIDE}
          element={<WasteManagementTypeReferencePage />}
        />
        <Route exact path={ADMIN_TYPE_ENTERPRISE_GUIDE} element={<TypeEnterpriseGuide />} />
        <Route exact path={ADMIN_HAZARD_CLASSES_GUIDE} element={<HazardClassesGuide />} />
        <Route exact path={ADMIN_SUBJECT_TYPE_PAGE} element={<SubjectTypeReferencePage />} />
        <Route
          exact
          path={ADMIN_ENTERPRISE_ACTIVITY_TYPE_PAGE}
          element={<EnterpriseActivityTypeReferencePage />}
        />
        <Route
          exact
          path={ADMIN_WASTE_CONDITION_ROUTE}
          element={<WasteConditionsReferencePage />}
        />
        <Route
          exact
          path={ADMIN_WASTE_CADASTRE_ROUTE}
          element={<AdminWasteCadastrePage />}
        />
        <Route
          exact
          path={ADMIN_PREMISES_APPOINTMENT_GUIDE}
          element={<PremisesAppointmentsGuide />}
        />
        <Route exact path={ADMIN_OKTMO_GUIDE} element={<OKTMOReferencePage />} />
        <Route exact path={ADMIN_FKKO_GUIDE} element={<FKKOReferencePage />} />
      </>
    )}
    {hasPrivilege('analyst_control') && (
      <Route
        exact
        path={ADMIN_ANALYTICS_CONTROL_CARRIERS_PAGE}
        element={<AnalyticsPage />}
      />
    )}
    {hasPrivilege('isMainAdmin') && (
      <Route
        exact
        path={ADMIN_SUBSCRIPTIONS_ROUTE}
        element={<RegistrySubscriptionsPage />}
      />
    )}
    {hasPrivilege('analyst_cadastre') && (
      <Route
        exact
        path={ADMIN_ANALYTICS_WASTE_CADASTRE_PAGE}
        element={<AnalyticsWasteCadastrePage />}
      />
    )}
  </>
);
