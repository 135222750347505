import React, { useMemo } from 'react';
import {
  Box, Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CustomInput from '../Inputs';
import CarrierFieldsList from './CarrierFieldsList';
import CarrierEdit from './CarrierEdit';
import BackdropLoading from '../BackdropLoading';
import { ENTERPRISE_ACTIVITY_TYPES_ENUM } from '../../constants/enums';
import { Text, TextMain, TextTitle } from '../Text';
import { FNSLoadData } from '../FNSLoadData';

const subjectActivityFields = [
  {
    title: 'Виды деятельности',
    field: ENTERPRISE_ACTIVITY_TYPES_ENUM,
    getField: (form) => form.enterprise_activity_types_attributes?.map(({
      attributes,
      id,
    }) => <Text key={id}>{attributes.name}</Text>),
  },
  {
    title: 'Тип субъекта',
    field: 'subject_type_attributes',
    getField: (form) => form.subject_type_attributes?.attributes?.name,
  },
];

export default function CarrierRegistrationData({
  error, editField, changeEdit, userInfo, makeRequest, onChange, isLoading, getMember, subject_type,
}) {
  const theme = useTheme();
  const tabletSizeAndLower = useMediaQuery(theme.breakpoints.down('md'));

  const subjectDataFields = useMemo(() => [
    {
      title: 'Адрес регистрации',
      field: 'registry_address',
      multiline: true,
      sx: { width: tabletSizeAndLower ? 320 : 500 },
    },
    {
      title: 'Адрес фактического местонахождения',
      field: 'actual_address',
      multiline: true,
      sx: { width: tabletSizeAndLower ? 320 : 500 },
    },
    { title: 'ИНН', field: 'inn' },
    { title: 'ОГРН', field: 'ogrn' },
    { title: 'КПП', field: 'kpp' },
  ], [tabletSizeAndLower]);

  return (
    <Box position="relative">
      <BackdropLoading isLoading={isLoading} />
      <Box display="flex" gap={10} flexWrap="wrap">
        <Box>
          <Box mb={3}>
            <Typography mb={2} component="h3" className="text-title">Регистрационные данные</Typography>
            {userInfo.full_name && (
              <Box width={320}>
                <TextTitle>Полное наименование субъекта</TextTitle>
                <TextMain>{userInfo.full_name}</TextMain>
              </Box>
            )}
            <Box display="flex" gap={3} flexDirection="column">
              <CarrierEdit
                error={error}
                onChange={onChange}
                editField={editField}
                field="email"
                title="E-mail"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
              <CarrierEdit
                error={error}
                editField={editField}
                onChange={onChange}
                title="Номер телефона"
                field="phone"
                type="tel"
                changeEdit={changeEdit}
                makeRequest={makeRequest}
                userInfo={userInfo}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} mb={5} width="320px">
            <Typography component="h4" className="text-title">Изменение пароля</Typography>
            <CustomInput
              required
              value={userInfo.old_password}
              type="password"
              label="Текущий пароль"
              name="old_password"
              onChange={onChange}
              error={error.old_password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Новый пароль"
              name="password"
              type="password"
              value={userInfo.password}
              error={error.password}
            />
            <CustomInput
              required
              onChange={onChange}
              label="Подтверждение пароля"
              type="password"
              name="password_confirmation"
              value={userInfo.password_confirmation}
              error={error.password_confirmation}
            />
            <Button onClick={() => makeRequest(['password_confirmation', 'password', 'old_password'])}>
              Сохранить
              пароль
            </Button>
          </Box>
        </Box>
        <CarrierFieldsList
          userInfo={userInfo}
          editField={editField}
          changeEdit={changeEdit}
          makeRequest={makeRequest}
          onChange={onChange}
          error={error}
          fields={subjectDataFields}
          title="Данные субъекта"
        />
        <CarrierFieldsList
          userInfo={userInfo}
          editField={editField}
          onChange={onChange}
          changeEdit={changeEdit}
          makeRequest={makeRequest}
          error={error}
          fields={subjectActivityFields}
          title="Деятельность субъекта"
        />
        <FNSLoadData userInfo={userInfo} subject_type={subject_type} callback={() => getMember()} />
      </Box>
    </Box>
  );
}
