import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme(
  {},
  {
    components: {
      MuiLocalizationProvider: {
        defaultProps: {
          localeText: {
            cancelButtonLabel: 'Отмена',
            okButtonLabel: 'Ок',
            clearButtonLabel: 'Сбросить',
            calendarViewSwitchingButtonAriaLabel: () => 'Выберите Дату',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            transition: 'none !important',

            '& .MuiAccordionSummary-root': {
              transition: 'none !important',
            },
            '& .MuiCollapse-root': {
              transition: 'none !important',
            },
            '& .MuiCollapse-wrapper': {
              transition: 'none !important',
            },
            '& .MuiCollapse-wrapperInner': {
              transition: 'none !important',
            },
            '& .MuiAccordionDetails-root': {
              transition: 'none !important',
            },
          },
        },
      },
    },
  },
);
