import { Route } from 'react-router-dom';
import React from 'react';
import {
  COMMITTEE_ANALYTICS, COMMITTEE_DEVIATION,
  COMMITTEE_MONITORING_VEHICLES,
  COMMITTEE_PROFILE,
  COMMITTEE_WASTE_CARRIERS,
} from '../routes';
import CommitteeCarriersPage from '../../pages/Committee/CommitteeCarriersPage';
import CommitteeProfilePage from '../../pages/Committee/CommitteeProfilePage';
import AnalyticsPage from '../../pages/AnalyticsPage';
import MonitoringMap from '../../pages/Committee/MonitoringMap';
import DeviationPage from '../../pages/Committee/DeviationPage';

export const getCommitteeRoutes = (hasPrivileges) => (!hasPrivileges('lk_kgen') ? null : (
  <>
    <Route exact path={COMMITTEE_WASTE_CARRIERS} element={<CommitteeCarriersPage />} />
    <Route exact path={COMMITTEE_PROFILE} element={<CommitteeProfilePage />} />
    {hasPrivileges('analytics_control') && (
      <Route exact path={COMMITTEE_ANALYTICS} element={<AnalyticsPage />} />
    )}
    <Route exact path={COMMITTEE_MONITORING_VEHICLES} element={<MonitoringMap />} />
    <Route exact path={COMMITTEE_DEVIATION} element={<DeviationPage />} />
  </>
));
