// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import ModalCard from './ModalCard';
import { useAddressRequest, useApi } from '../../hooks';
import { compareId, formValidator } from '../../helpers';
import { HAS_REQUIRED_FIELDS_TEXT } from '../../helpers/formValidator';
import { createObjectiveRequest, deleteObjectiveRequest, updateObjectiveRequest } from '../../api/objectivesApi';
import { getSubjectsRequest } from '../../api/subjectApi';
import { DEFAULT_SELECTOR_OPTION } from '../Selectors/Selector';
import { DEFAULT_ENUM_OPTION, SUBJECT_TYPES, WASTE_MANAGEMENT_ENUM } from '../../constants/enums';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../Inputs/CustomAutocomplete';
import { useModalStore } from '../../store';
import { TextMain } from '../Text';
import { filterIndividualSubjectKind } from '../../helpers/entityHelper';
import ModalAddAddress from './ModalAddAddress';
import { formatAddressString, stringWithStartEnd } from '../../helpers/stringHelper';
import { addAddressRequest, getAddressEnumsRequest } from '../../api/addressApi';
import { formatLatLngToPostGisPoint } from '../../helpers/mapHelper';
import { getReferencesRequest } from '../../api/referenceApi';
import { OKTMOS_URL, WASTE_MANAGEMENT_TYPES_URL } from '../../constants/urls';
import { OBJECT_STATUS } from '../../constants/objectConstants';
import { AlertRequiredFields } from '../Inputs/AlertRequiredFields';

const signOfOwnershipOptions = [
  { id: 1, value: 'house', label: 'Дом' },
  { id: 2, value: 'building', label: 'Здание' },
];

const initialObjectInputs = {
  subject: { id: '', name: '' },
  name: '',
  [WASTE_MANAGEMENT_ENUM]: { id: '', label: '', value: '' },
  region: '',
  city: '',
  district: '',
  street: '',
  house: '',
  control_method: { ...DEFAULT_AUTOCOMPLETE_OPTION },
  hull_number: '',
  building_number: '',
  non_residential_block_number: '',
  latitude: '',
  longitude: '',
  housing_stock: { ...DEFAULT_SELECTOR_OPTION },
  ownership_type: { ...DEFAULT_SELECTOR_OPTION },
  room_number: '',
  cadastral_number: '',
  housing_stock_identifier: '',
  inn: { inn: '' },
  subject_type: { ...DEFAULT_ENUM_OPTION },
  oktmo: { ...DEFAULT_ENUM_OPTION },
  okato: '',
  groro: '',
  capacity_objective_statement: '',
  sanitary_area_info: '',
  power_objective_statement: '',
  license: '',
  conclusion_ecological_expertise: '',
  object_documentation_info: '',
  technological_solutions_info: '',
  disposal_of_waste: false,
  status: { ...DEFAULT_AUTOCOMPLETE_OPTION },
};

const housingStockOptions = [
  { label: 'Многоквартирный дом', value: 'apartment_house' },
  { label: 'Индивидуальное строительство', value: 'individual_construction' },
  { label: 'Не жилищный фонд', value: 'not_a_housing_stock' },
];

const selectorOption = {
  status: [
    { id: OBJECT_STATUS.SIMPLE, value: OBJECT_STATUS.SIMPLE, label: 'Обычный' },
    { id: OBJECT_STATUS.DRAFT, value: OBJECT_STATUS.DRAFT, label: 'Черновик' },
    { id: OBJECT_STATUS.PUBLISHED_TER_SCHEME, value: OBJECT_STATUS.PUBLISHED_TER_SCHEME, label: 'Опубликован в тер. схеме' },
  ],
  housing_stock: housingStockOptions,
  ownership_type: signOfOwnershipOptions,
};

export default function ModalAdminAddObject({
  close,
  updateObjects,
  setNewObject,
  objectData = {},
  wasteManagementTypes,
  subjectData,
  division,
}) {
  const { modalStore, setModalStore } = useModalStore();
  const [objectInput, setObjectInput] = useState(initialObjectInputs);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [isOpenAddress, setIsOpenAddress] = useState(false);

  const { defaultRequest: getAddress } = useAddressRequest();

  const closeAddress = () => setIsOpenAddress(false);

  const isPlacing = objectInput[WASTE_MANAGEMENT_ENUM].code === 'placing';

  const isMultiplyWasteType = ['pending', 'neutralization', 'utilization'].includes(objectInput[WASTE_MANAGEMENT_ENUM].code);

  const isFormation = compareId(objectInput[WASTE_MANAGEMENT_ENUM].code, 'generating');

  const objectFrame = useMemo(() => [
    {
      id: 1,
      containers: [
        {
          id: 1,
          title: 'Адрес объекта',
          inputFields: [
            {
              id: 'region',
              label: 'Область',
              required: true,
              multiline: true,
            },
            {
              id: 'city',
              label: 'Город/Населённый пункт',
              required: true,
              multiline: true,
            },
            {
              id: 'district',
              label: 'Район',
              required: true,
              multiline: true,
            },
            {
              id: 'street',
              label: 'Улица',
              required: true,
              multiline: true,
            },
            {
              id: 'house',
              label: 'Номер дома',
              required: true,
              multiline: true,
            },
            {
              id: 'hull_number',
              label: 'Номер корпуса',
              multiline: true,
            },
            {
              id: 'building_number',
              label: 'Номер строения',
              multiline: true,
            },
            { id: 'address_coordinates_text', text: 'Пример координат: 20.123213' },
            { id: 'latitude', label: 'Широта' },
            { id: 'longitude', label: 'Долгота' },
            {
              id: 'oktmo',
              label: 'ОКТМО',
              type: 'autocomplete',
              request: (params) => getReferencesRequest({ url: OKTMOS_URL, params }),
            },
            { id: 'okato', label: 'ОКАТО' },
            {
              visible: () => isFormation,
              id: 'housing_stock',
              label: 'Жилищный фонд',
              type: 'select',
              required: true,
            },
            {
              id: 'ownership_type',
              label: 'Признак владения',
              type: 'select',
              required: true,
            },
            { id: 'residential_block_number', label: 'Номер жилого блока' },
            { id: 'non_residential_block_number', label: 'Номер нежилого блока' },
            { id: 'room_number', label: 'Номер комнаты' },
            { id: 'cadastral_number', label: 'Кадастровый номер' },
            {
              visible: () => isFormation,
              id: 'control_method',
              label: 'Способ управления',
              type: 'autocomplete',
              required: true,
              fields: DEFAULT_AUTOCOMPLETE_FIELD,
              request: (params) => getAddressEnumsRequest('control_method', params),
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: 'Данные объекта',
      inputFields: [
        {
          id: 'status',
          label: 'Статус',
          type: 'select',
          required: true,
        },
        { id: 'name', label: 'Наименование объекта', required: true },
        {
          component: (
            <Box mb={2}>
              <TextMain>
                Примеры наименования объекта
              </TextMain>
              <TextMain>
                1) Строительство жилого дома по адресу..
              </TextMain>
              <TextMain>
                2) Контейнерная площадка..,
              </TextMain>
              <TextMain>
                3) жилой дом, магазин и т д
              </TextMain>
            </Box>
          ),
        },
        {
          id: WASTE_MANAGEMENT_ENUM,
          label: 'Вид обращения отходов',
          required: true,
          type: 'autocomplete',
          selectFirstOption: wasteManagementTypes?.length === 1,
          disabled: wasteManagementTypes?.length === 1,
          request: (params) => getReferencesRequest({
            url: WASTE_MANAGEMENT_TYPES_URL,
            params,
            filter: (options) => {
              if (wasteManagementTypes?.length) {
                return options
                  .filter(({ id }) => wasteManagementTypes?.some((type) => compareId(type, id)));
              }
              return options;
            },
          }),
        },
        {
          id: 'housing_stock_identifier_text',
          text: 'Глобальный уникальный идентификатор объекта жилищного фонда',
        },
        { id: 'housing_stock_identifier' },
        { id: 'groro', label: 'Регистрационный номер ГРОРО', visible: () => isPlacing },
        {
          id: 'capacity_objective_text',
          text: 'Данные о состоянии объектов, включая информацию о проектной вместимости для объектов размещения отходов *',
          visible: () => isPlacing,
        },
        {
          id: 'capacity_objective_statement', type: 'number', required: true, visible: () => isPlacing,
        },
        {
          visible: () => isPlacing,
          id: 'power_objective_statement_text',
          text: 'Данные о состоянии объектов, включая информацию о свободной мощности для объектов размещения отходов *',
        },
        {
          id: 'power_objective_statement', required: true, type: 'number', visible: () => isPlacing,
        },
        {
          id: 'license',
          label: 'Наличие лицензии (дата, №)',
          required: true,
          visible: () => isPlacing || isMultiplyWasteType,
        },
        {
          id: 'conclusion_ecological_expertise_text',
          text: 'Наличие заключения государственной экологической экспертизы *',
          visible: () => isPlacing,
        },
        {
          id: 'conclusion_ecological_expertise',
          required: true,
          multiline: true,
          visible: () => isPlacing,
        },
        {
          id: 'sanitary_area_info',
          label: 'Сведения о санитарно-защитной зоне',
          required: true,
          visible: () => isPlacing || isMultiplyWasteType,
        },
        {
          id: 'object_documentation_info_text',
          text: 'Сведения из проектной документации объектов обработки (о производственной мощности (тонн/единиц в год, суммарно по видам отходов) *',
          visible: () => isMultiplyWasteType,
        },
        {
          id: 'object_documentation_info', required: true, multiline: true, visible: () => isMultiplyWasteType,
        },
        {
          id: 'technological_solutions_info_text',
          text: 'Сведения о применяемых технологических решениях и об оборудовании объектов *',
          visible: () => isMultiplyWasteType,
        },
        {
          id: 'technological_solutions_info', required: true, multiline: true, visible: () => isMultiplyWasteType,
        },
        {
          id: 'disposal_of_waste',
          label: 'Несанкционированное размещение отходов',
          type: 'checkbox',
        },
      ],
    },
    {
      id: 5,
      containers: [
        {
          id: 6,
          title: 'Данные субъекта',
          inputFields: [
            {
              id: 'subject',
              label: 'Наименование субъекта',
              required: true,
              type: 'autocomplete',
              request: (params) => getSubjectsRequest({ params: { ...params, division } }),
              disabled: subjectData?.subject?.id,
            },
            {
              id: 'inn',
              label: 'ИНН',
              type: 'autocomplete',
              required: true,
              request: (params) => getSubjectsRequest({ params: { ...params, division } }),
              disabled: subjectData?.inn?.inn,
              fields: { label: 'inn', id: 'inn' },
            },
            {
              id: 'subject_type',
              label: 'Тип субъекта',
              type: 'autocomplete',
              required: true,
              disabled: subjectData?.subject_type?.id,
              request: (params) => getReferencesRequest({
                url: SUBJECT_TYPES,
                params,
                filter: filterIndividualSubjectKind,
              }),
            },
          ],
        },
      ],
    },
    /* eslint-disable-next-line */
  ], [wasteManagementTypes, division, isMultiplyWasteType, isPlacing, isFormation]);

  useEffect(() => {
    if (objectData?.id !== undefined) {
      const {
        subject_attributes,
        name,
        waste_management_type_attributes,
        housing_stock_identifier,
      } = objectData;

      const addressAttributes = objectData?.address_attributes?.attributes;

      setObjectInput({
        subject: { id: subject_attributes?.id, name: subject_attributes?.attributes?.name },
        name,
        [WASTE_MANAGEMENT_ENUM]: {
          id: waste_management_type_attributes?.id,
          name: waste_management_type_attributes?.attributes?.name,
          code: waste_management_type_attributes?.attributes?.code,
        },
        region: addressAttributes?.region,
        city: addressAttributes?.city,
        district: addressAttributes?.district,
        street: addressAttributes?.street,
        house: addressAttributes?.house,
        control_method: {
          id: addressAttributes?.control_method || '',
          value: addressAttributes?.control_method || '',
          label: addressAttributes?.control_method_text || '',
        },
        room_number: addressAttributes?.room_number,
        hull_number: addressAttributes?.hull_number,
        building_number: addressAttributes?.building_number,
        ownership_type: addressAttributes?.ownership_type
          ? signOfOwnershipOptions.find(({ value }) => value === addressAttributes.ownership_type)
          : { ...DEFAULT_SELECTOR_OPTION },
        non_residential_block_number: addressAttributes?.non_residential_block_number,
        residential_block_number: addressAttributes?.residential_block_number,
        housing_stock_identifier,
        housing_stock: {
          value: addressAttributes?.housing_stock,
          label: housingStockOptions.find(({ value }) => value === addressAttributes?.housing_stock)?.label || addressAttributes?.housing_stock || '',
        },
        cadastral_number: addressAttributes?.cadastral_number,
        latitude: addressAttributes?.place?.[1] || '',
        longitude: addressAttributes?.place?.[0] || '',
        oktmo: {
          name: addressAttributes?.oktmo_attributes?.attributes?.name || '',
          id: addressAttributes?.oktmo_attributes?.id || '',
        },
        okato: addressAttributes?.okato,
        inn: { inn: objectData?.subject_attributes?.attributes?.inn || '' },
        subject_type: {
          id: objectData?.subject_attributes?.attributes?.subject_type_attributes?.id || '',
          name: objectData?.subject_attributes?.attributes?.subject_type_attributes?.attributes?.name || '',
        },
        status: {
          id: objectData?.status,
          value: objectData?.status,
          label: objectData?.status_text,
        },
      });
    }
    /* eslint-disable-next-line */
  }, [objectData]);

  useEffect(() => {
    if (subjectData) {
      setObjectInput((prevState) => ({
        ...prevState,
        ...subjectData,
      }));
    }
  }, [subjectData]);

  const isUpdate = objectData?.id !== undefined;

  const onCreateObject = async () => {
    const {
      subject,
      name,
      oktmo,
      okato,
      residential_block_number,
      room_number,
      cadastral_number,
      groro,
      capacity_objective_statement,
      power_objective_statement,
      license,
      conclusion_ecological_expertise,
      disposal_of_waste,
      sanitary_area_info,
      technological_solutions_info,
      object_documentation_info,
      control_method,
      region,
      city,
      street,
      district,
      housing_stock_identifier,
      housing_stock,
      ownership_type,
      latitude,
      longitude,
      house,
      building_number,
      hull_number,
      non_residential_block_number,
    } = objectInput;

    const ignoreInputs = [
      'groro',
      'oktmo',
      'housing_stock_identifier',
      'non_residential_block_number',
      'latitude',
      'longitude',
      'hull_number',
      'building_number',
      'address_recipient_oktmo',
      'okato',
      'address_recipient_okato',
      'disposal_of_waste',

      'residential_block_number',
      'room_number',
      'cadastral_number',

      'address_recipient_residential_block_number',
      'address_recipient_room_number',
      'address_recipient_cadastral_number',
    ];

    if (!isPlacing) ignoreInputs.push(...['capacity_objective_statement', 'power_objective_statement', 'conclusion_ecological_expertise']);
    if (!(isPlacing || isMultiplyWasteType)) ignoreInputs.push(...['license', 'sanitary_area_info']);
    if (!isMultiplyWasteType) ignoreInputs.push(...['object_documentation_info', 'technological_solutions_info']);

    if (!isFormation) ignoreInputs.push(...['housing_stock', 'address_recipient_housing_stock', 'control_method']);

    const { hasErrors, validField } = formValidator({
      form: {
        ...objectInput,
        subject: { id: subject.id },
        inn: { inn: objectInput.inn.inn },
        [WASTE_MANAGEMENT_ENUM]: { id: objectInput[WASTE_MANAGEMENT_ENUM].id },
      },
      ignoreInputs,
    });

    setError(validField);

    if (hasErrors) throw { frontendError: HAS_REQUIRED_FIELDS_TEXT };

    const searchAddressString = objectInput.region
      + stringWithStartEnd({ string: objectInput.city, start: ' ' })
      + stringWithStartEnd({ string: objectInput.district, start: ' ' });

    const addresses = await getAddress({
      params: { search: searchAddressString },
    });

    const requestData = {
      disposal_of_waste,
      subject_id: subject.id,
      name,
      waste_management_type_id: objectInput[WASTE_MANAGEMENT_ENUM].id,
    };

    if (groro) requestData.groro = groro;
    if (license) requestData.license = license;
    if (sanitary_area_info) requestData.sanitary_area_info = sanitary_area_info;
    if (technological_solutions_info) {
      requestData.technological_solutions_info = technological_solutions_info;
    }
    if (object_documentation_info) {
      requestData.object_documentation_info = object_documentation_info;
    }
    if (conclusion_ecological_expertise) {
      requestData.conclusion_ecological_expertise = conclusion_ecological_expertise;
    }
    if (capacity_objective_statement) {
      requestData.capacity_objective_statement = capacity_objective_statement;
    }
    if (power_objective_statement) {
      requestData.power_objective_statement = power_objective_statement;
    }
    if (housing_stock_identifier) {
      requestData.housing_stock_identifier = housing_stock_identifier;
    }

    if (addresses?.[0]?.id) {
      requestData.address_id = addresses[0].id;
    } else {
      const createdAddress = await addAddressRequest({
        region,
        city,
        district,
        street,
        house,
        hull_number,
        building_number,
        place: formatLatLngToPostGisPoint(latitude, longitude),
        okato,
        oktmo_id: oktmo?.id,
        residential_block_number,
        room_number,
        cadastral_number,
        non_residential_block_number,
        control_method: control_method.id,
        housing_stock: housing_stock.value,
        ownership_type: ownership_type?.value,
      });
      requestData.address_id = createdAddress.id;
    }

    if (objectInput?.status?.id) requestData.status = objectInput.status.id;

    let newObject;

    if (isUpdate) newObject = await updateObjectiveRequest(objectData.id, requestData);
    else newObject = await createObjectiveRequest(requestData);

    if (setNewObject) setNewObject(newObject);

    if (updateObjects) await updateObjects();

    close();
  };

  const { makeRequest: createObject } = useApi({
    request: onCreateObject,
    setIsLoading,
    successMessage: `Объект ${isUpdate ? 'обновлён' : 'создан'}`,
  });

  const handleChange = (field) => (event, value) => {
    if (['inn', 'subject'].includes(field)) {
      if (value.inn) {
        setError((prevState) => ({
          ...prevState,
          inn: { inn: '' },
          subject: { id: '', name: '' },
          subject_type: { ...DEFAULT_ENUM_OPTION },
        }));

        setObjectInput((prevState) => ({
          ...prevState,
          inn: { ...prevState.inn, ...value },
          subject: value,
          subject_type: {
            id: value?.subject_type_attributes?.id,
            name: value?.subject_type_attributes?.attributes?.name,
          },
        }));
        return;
      }
      setError((prevState) => ({
        ...prevState,
        inn: { inn: '' },
        subject: { id: '', name: '' },
        subject_type: { ...DEFAULT_ENUM_OPTION },
      }));

      setObjectInput((prevState) => ({
        ...prevState,
        inn: { inn: '' },
        subject: { id: '', name: '' },
        subject_type: { ...DEFAULT_ENUM_OPTION },
      }));
      return;
    }

    setError((prevState) => ({
      ...prevState,
      [field]: '',
    }));
    setObjectInput((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const deleteObject = () => {
    setModalStore({
      ...modalStore,
      deleteModal: {
        isOpen: true,
        callback: async () => {
          await updateObjects();
          close();
        },
        objectName: 'объект',
        deleteRequest: deleteObjectiveRequest,
        id: objectData.id,
        message: 'Объект удалён',
      },
    });
  };

  const setNewAddress = (newAddress) => {
    setObjectInput((prevState) => ({
      ...prevState,
      address: {
        ...newAddress,
        value: newAddress.id,
        label: formatAddressString(newAddress),
      },
    }));
  };

  return (
    <ModalCard
      open
      inputs={objectFrame}
      inputData={objectInput}
      close={close}
      additionalTopContainer={<AlertRequiredFields />}
      selectorOption={selectorOption}
      isLoading={isLoading}
      title={`${isUpdate ? `${objectData?.deleted_at ? '(АРХИВ) ' : ''}Редактирование` : 'Добавление'} объекта обращения с отходами`}
      error={error}
      additionalContainer={isOpenAddress && (
        <ModalAddAddress close={closeAddress} setNewAddress={setNewAddress} />
      )}
      handleChange={handleChange}
      firstButtonProp={!objectData?.deleted_at && objectData?.subject_id && {
        label: 'Удалить объект',
        onClick: deleteObject,
      }}
      secondButtonProp={!objectData?.deleted_at && {
        label: `${isUpdate ? 'Обновить' : 'Добавить'} объект`,
        onClick: () => createObject(),
      }}
    />
  );
}
