import React, { memo, useState } from 'react';
import { Box } from '@mui/material';
import NavTabs from '../../Tabs';
import AddButton from '../../Buttons/AddButton';
import { getInitialForm4Document } from '../../../constants/lkCadastr/cadastrForm4';
import CustomCheckbox from '../../CustomCheckbox';
import { flexGap2, flexRowGap2 } from '../../../constants/lkCadastr/cadastrStorage';
import CadastreForm4Document from '../../Cadastr/CadastreForm4Document';
import { ENTERPRISE_ACTIVITY_TYPES_ENUM, SUBJECT_TYPE_ATTRIBUTES } from '../../../constants/enums';
import ModalWithTitle from '../ModalWithTitle';
import CustomInput from '../../Inputs';
import { ERROR_REQUIRED_FIELD } from '../../../helpers/formValidator';
import CustomButton from '../../Buttons/CustomButton';
import { updateObjectiveRequest } from '../../../api/objectivesApi';
import ModalAddSubject from '../ModalAddSubject';
import ModalAddObject from '../ModalAddObject';
import { OBJECT_STATUS } from '../../../constants/objectConstants';
import { formatObjectWithAddress } from '../../../helpers/stringHelper';
import { TextMain } from '../../Text';
import useGetReferenceItems from '../../../hooks/useGetRefferenceItems';
import useCadastreTabs from '../../../hooks/useCadastreTabs';
import { useDeleteCadastreEntity } from '../../../hooks/cadastre/useDeleteCadastreEntity';

function ModalCadastrForm4({
  data: {
    documents: reportApprovalStandards, requiredForm,
  },
  deleteForm4Entity,
  form1Objects,
  changeItemFormByField,
  onChange,
  isDemo,
  subjectAttributes,
}) {
  const [openedObjectInfo, setOpenedObjectInfo] = useState(null);
  const [createObjectInfo, setCreateObjectInfo] = useState(null);
  const [groro, setGroro] = useState('');
  const [createSubjectField, setCreateSubjectField] = useState(null);

  const { getReferenceTypeIdsByCode: getEnterpriseTypeIdsByCode } = useGetReferenceItems({ referenceUrl: ENTERPRISE_ACTIVITY_TYPES_ENUM });

  const placingTypesIds = getEnterpriseTypeIdsByCode(['placing']);

  const closeObjectModal = () => setCreateObjectInfo(null);

  const setNewObject = (newObject) => {
    onChange({
      field: createObjectInfo.field,
      value: {
        ...newObject,
        label: formatObjectWithAddress({ objective: newObject }),
      },
    });
    closeObjectModal();
  };

  const onChangeForm4 = (field, value) => onChange({ field, value });

  const addNewDocument = () => {
    changeItemFormByField({ field: 'form4.documents', value: [...reportApprovalStandards, getInitialForm4Document()] });
  };

  const form1ObjectsPlacingOptions = form1Objects
    .filter((item) => item.enterprise_activity_types.some(({ label }) => label === 'Размещение'))
    .map((item) => ({
      ...item.objective_id,
    }));

  const closeGroroModal = () => {
    setGroro('');
    setOpenedObjectInfo(null);
  };

  const onSaveGroro = async () => {
    const newObject = await updateObjectiveRequest(openedObjectInfo.object.id, { groro });

    onChangeForm4(
      openedObjectInfo.field,
      {
        ...newObject,
        label: formatObjectWithAddress({ objective: newObject }),
      },
    );
    closeGroroModal();
  };

  const closeNewSubjectModal = () => setCreateSubjectField(null);

  const setNewSubject = (newSubject) => {
    onChange({
      field: createSubjectField,
      value: { ...newSubject, ...newSubject?.attributes },
    });
    closeNewSubjectModal();
  };

  const { tabs, tab, setTab } = useCadastreTabs({ items: reportApprovalStandards, tabName: 'Документ' });

  const { openDeleteModal } = useDeleteCadastreEntity();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {createSubjectField !== null && (
        <ModalAddSubject
          division="internal"
          close={closeNewSubjectModal}
          setNewSubject={setNewSubject}
        />
      )}
      {createObjectInfo !== null && (
        <ModalAddObject
          objectTypeTitle=""
          close={closeObjectModal}
          division="internal"
          helpComponent={(
            <Box mb={2}>
              <TextMain>
                Примеры наименования объекта
              </TextMain>
              <TextMain>
                1) Объект образования отходов ...
              </TextMain>
              <TextMain>
                2) Объекта обращения с отходами ...
              </TextMain>
              <TextMain>
                3) Производственная площадка ...
              </TextMain>
            </Box>
          )}
          status={OBJECT_STATUS.DRAFT}
          subjectData={{
            subject: createObjectInfo.subject,
            inn: { inn: createObjectInfo.subject.inn },
            subject_type: createObjectInfo.subject?.[SUBJECT_TYPE_ATTRIBUTES]?.attributes?.name || '',
            name: createObjectInfo.newObjectName,
          }}
          wasteExcludeAccumulationCodes={['placing']}
          setNewObject={setNewObject}
        />
      )}
      <ModalWithTitle
        containerSx={{ width: 'fit-content' }}
        close={closeGroroModal}
        open={openedObjectInfo !== null}
        title="Добавить ГРОРО"
      >
        <Box sx={flexRowGap2}>
          <CustomInput
            error={!groro ? ERROR_REQUIRED_FIELD : ''}
            debounceTime={0}
            label="ГРОРО"
            value={groro}
            onChange={(e, value) => setGroro(value)}
          />
          <CustomButton disabled={!groro} onClick={onSaveGroro}>Сохранить</CustomButton>
        </Box>
      </ModalWithTitle>
      <CustomCheckbox
        disabled={isDemo}
        label="Документ об утверждении нормативов образования и лимитов на их размещение не требуется"
        onChange={(e, value) => onChangeForm4('requiredForm', value)}
        value={requiredForm}
      />
      {!requiredForm && (
        <Box sx={flexGap2}>
          <NavTabs tabsComponents={tabs} setTab={setTab} tab={tab} />
          {!isDemo && (
            <AddButton variant="outlined" onClick={addNewDocument}>Добавить документ</AddButton>
          )}
          {reportApprovalStandards.map((reportApprovalStandard, reportApprovalStandardIndex) => {
            if (reportApprovalStandard.randId !== tab) return null;

            const onChangeForm4ReportApprovalStandard = ({
              field, value, approvalWasteIndex, limitWasteIndex, limitWasteKind,
            }) => onChange({
              field,
              value,
              reportApprovalStandardIndex,
              approvalWasteIndex,
              limitWasteIndex,
              limitWasteKind,
            });

            return (
              <CadastreForm4Document
                openDeleteModal={openDeleteModal}
                deleteForm4Entity={deleteForm4Entity}
                key={reportApprovalStandard.randId}
                form1ObjectsPlacingOptions={form1ObjectsPlacingOptions}
                setOpenedObject={setOpenedObjectInfo}
                reportApprovalStandardIndex={reportApprovalStandardIndex}
                reportApprovalStandard={reportApprovalStandard}
                subjectAttributes={subjectAttributes}
                reportApprovalStandards={reportApprovalStandards}
                onChange={onChangeForm4ReportApprovalStandard}
                isDemo={isDemo}
                placingTypesIds={placingTypesIds}
                setCreateSubjectField={setCreateSubjectField}
                setCreateObjectInfo={setCreateObjectInfo}
                changeItemFormByField={changeItemFormByField}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default memo(ModalCadastrForm4);
