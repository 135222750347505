// @ts-nocheck
import { useApi } from '../useApi';
import { applicationDocumentRequest, applicationObjectivesDocumentRequest } from '../../api/applicationsApi';
import { objectChangeDotProperty } from '../../helpers/objectHelper';
import { isWebView, getExtensionWebView } from '../../constants';

export function useGetApplicationFiles({
  id, setApplication, application, setIsLoading,
}) {
  useApi({
    request: () => applicationDocumentRequest(id),
    shouldRequest: id !== undefined,
    setter: (response) => {
      if (response?.data) {
        setApplication((prev) => ({
          ...prev,
          document_url: isWebView ? response.request.responseURL : URL.createObjectURL(response.data),
          extension: getExtensionWebView(response.headers['content-type']),
        }));
      }
    },
    setIsLoading,
  });

  useApi({
    request: async () => {
      const cloneApplicationObjectives = application.application_objectives_attributes.slice();
      let index = -1;

      for await (const objective of cloneApplicationObjectives) {
        index += 1;

        const response = await applicationObjectivesDocumentRequest(objective.id);

        if (response?.data) {
          objectChangeDotProperty({
            field: `${index}.attributes.document_url`,
            value: isWebView ? response.request.responseURL : URL.createObjectURL(response.data),
            object: cloneApplicationObjectives,
          });
          objectChangeDotProperty({
            field: `${index}.attributes.extension`,
            value: getExtensionWebView(response.headers['content-type']),
            object: cloneApplicationObjectives,
          });
        }
      }
    },
    shouldRequest: application?.application_objectives_attributes?.length,
    setIsLoading,
  });
}
