import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import CustomCheckbox from './CustomCheckbox';
import ModalColumnTitle from './Modals/components/ModalColumnTitle';
import { PRIVILEGES_TYPES } from '../constants/privileges';
import CustomInput from './Inputs';

export default function PrivilegeCheckboxes({
  privilegeData, onChange, setRole,
}) {
  const [filter, setFilter] = useState('');

  const changeFilter = (e, value) => setFilter(value);

  const onChangeGroup = (groupPrivileges, value) => {
    const groupValue = {};
    groupPrivileges.forEach((privilege) => {
      groupValue[privilege.code] = { ...privilege, value };
    });

    setRole((prevState) => ({
      ...prevState,
      privilege: {
        ...prevState.privilege,
        ...groupValue,
      },
    }));
  };

  return (
    <Box>
      <ModalColumnTitle>Привилегии</ModalColumnTitle>
      <CustomInput
        sx={{ width: '320px', mb: 2 }}
        onChange={changeFilter}
        label="Поиск по группе привилегий"
        value={filter}
      />
      <Grid container gap={2} flexWrap="wrap">
        {PRIVILEGES_TYPES
          .filter(({ label }) => label.toLowerCase().includes(filter.toLowerCase()))
          .map(({ id: idType, label }) => {
            const filteredPrivileges = Object.values(privilegeData)
              .filter((privilege) => privilege?.code?.includes?.(idType));

            return (
              <Grid
                sx={{ background: 'rgb(0,0,0,0.1)', p: 3, borderRadius: '20px' }}
                item
                xs={2.9}
                key={idType}
                display="flex"
                flexDirection="column"
              >
                <CustomCheckbox
                  sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold', fontSize: 18 }, mb: 1 }}
                  key={idType}
                  id={idType}
                  name={idType}
                  label={label}
                  value={filteredPrivileges.every(({ code }) => privilegeData[code].value)}
                  onChange={(e, value) => onChangeGroup(filteredPrivileges, value)}
                />
                {
                  filteredPrivileges.map((filteredPrivilege) => (
                    <CustomCheckbox
                      key={filteredPrivilege.id}
                      id={filteredPrivilege.id}
                      name={filteredPrivilege.code}
                      label={filteredPrivilege.name}
                      value={privilegeData[filteredPrivilege.code].value}
                      onChange={(e, value) => onChange(e, value, filteredPrivilege)}
                    />
                  ))
                }
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
