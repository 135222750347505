import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import ModalWasteCarrier from '../../components/Modals/ModalWasteCarrier/ModalWasteCarrier';
import CustomTable from '../../components/Tables/CustomTable';
import HeaderSortFilter from '../../components/HeaderSortFilter';
import NavTabs from '../../components/Tabs';
import { useApi, useTableSearch } from '../../hooks';
import { getVehiclesRequest } from '../../api/vehiclesApi';
import { getSubjectsRequest } from '../../api/subjectApi';
import ModalVehicleInfo from '../../components/Modals/Carrier/ModalVehicleInfo';
import ModalViolation from '../../components/Modals/ModalWasteCarrier/ModalViolation';
import { getViolationEnumsRequest, getViolationsRequest, importViolationRequest } from '../../api/violationsApi';
import { getDayMonthYear, getHourMinute } from '../../helpers/dateHelper';
import { TextTitle } from '../../components/Text';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomCheckbox from '../../components/CustomCheckbox';
import { DEFAULT_AUTOCOMPLETE_FIELD, DEFAULT_AUTOCOMPLETE_OPTION } from '../../components/Inputs/CustomAutocomplete';
import { AsyncAutocomplete } from '../../components/AutoCompletes/AsyncAutocomplete';
import {
  ENTERPRISE_ACTIVITY_TYPE_IDS,
  ENTERPRISE_ACTIVITY_TYPES_ENUM,
  VIOLATION_TYPE_ENUM,
} from '../../constants/enums';
import CustomButton from '../../components/Buttons/CustomButton';
import useGetReferenceItems from '../../hooks/useGetRefferenceItems';
import { compareId } from '../../helpers';

const initialTableSubject = [
  { headerTitle: 'ID', field: 'id' },
  { headerTitle: 'ИНН', field: 'inn' },
  { headerTitle: 'Субъект', field: 'name' },
  { headerTitle: 'ОГРН', field: 'ogrn' },
  {
    headerTitle: 'Кол-во разрешений',
    field: 'applications_count',
    getField: ({ applications_count }) => applications_count,
  },
  { headerTitle: 'Адрес электронной почты', field: 'email' },
  { headerTitle: 'Номер телефона', field: 'phone' },
  { headerTitle: 'Адрес регистрации', field: 'registry_address' },
  { headerTitle: 'Адрес фактического местонахождения ', field: 'actual_address' },
  {
    headerTitle: 'Подключение к РИНС ЛО',
    field: 'is_connected_rins',
    getField: ({ is_connected_rins }) => (is_connected_rins
      ? <DoneIcon color="success" />
      : <CloseIcon color="error" />),
  },
];

const initialTableVehicle = [
  { headerTitle: 'ЕГРЗ ТС (Номер ТС)', field: 'egrz' },
  { headerTitle: 'ЕГРЗ прицепа (Номер прицепа)', field: 'egrz_trailer' },
  { headerTitle: 'Тип владения', field: 'ownership_type_text' },
  { headerTitle: 'ID БНСО', field: 'guid_bnso' },
  { headerTitle: 'Марка ТС', field: 'brand' },
  { headerTitle: 'Модель ТС', field: 'model' },
  {
    headerTitle: 'Субъект',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  {
    headerTitle: 'Кол-во разрешений',
    field: 'applications_count',
    getField: ({ applications_count }) => applications_count,
  },
  {
    headerTitle: 'Номер телефона',
    field: 'phone',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.phone,
  },
  {
    headerTitle: 'Подключение к РИНС ЛО',
    field: 'is_connected_rins',
    getField: ({ is_connected_rins }) => (is_connected_rins
      ? <DoneIcon color="success" />
      : <CloseIcon color="error" />),
  },
];

const initialTableViolation = [
  {
    headerTitle: 'ЕГРЗ ТС (Номер ТС)',
    field: 'egrz',
    getField: ({ vehicle_attributes }) => vehicle_attributes?.attributes?.egrz,
  },
  {
    headerTitle: 'ИНН субъекта',
    field: 'inn',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.inn,
  },
  {
    headerTitle: 'Наименование субъекта',
    field: 'subject_name',
    getField: ({ subject_attributes }) => subject_attributes?.attributes?.name,
  },
  { headerTitle: 'Наименование нарушения', field: 'name' },
  {
    headerTitle: 'Тип нарушения',
    field: 'subject_type',
    getField: (({ subject_type_attributes }) => subject_type_attributes?.attributes?.name),
  },
  {
    headerTitle: 'Дата нарушения',
    field: 'committed_at',
    getField: ({ committed_at }) => getDayMonthYear(committed_at),
  },
  {
    headerTitle: 'Время нарушения',
    field: 'violation_time',
    getField: ({ committed_at }) => getHourMinute(committed_at),
  },
  { headerTitle: 'Основание нарушения', field: 'reason' },
];

const initialTableDestination = [
  {
    headerTitle: 'Дата выдачи разрешений',
    field: 'issue_date',
  },
  {
    headerTitle: 'Наименование субъекта',
    field: 'subject_name',
  },
  {
    headerTitle: 'ЕГРЗ ТС (Номер ТС)',
    field: 'EGRZTS',
  },
  {
    headerTitle: 'Адрес образователя отходов',
    field: 'address_educator',
  },
  {
    headerTitle: 'Адрес объекта обращения с отходами',
    field: 'address_object',
  },
  {
    headerTitle: 'Дата и время фиксации отклонения',
    field: 'date_time',
  },
];

const destinationTableData = [{
  issue_date: '',
  subject_name: '',
  EGRZTS: '',
  address_educator: '',
  address_object: '',
  date_time: '',
}];

const tabsComponents = [
  { name: 'subject', label: 'Субъекты' },
  { name: 'vehicle', label: 'ТС' },
  { name: 'violation', label: 'Нарушения' },
  // { name: 'destination', label: 'Не доехал до места назначения' },
];

const getDestinationRequest = async () => destinationTableData;

const subjectRequest = (params, setTableStore) => getSubjectsRequest({ params, setTableStore });

export default function CommitteeCarriersPage() {
  const [tableData, setTableData] = useState([]);
  const [tab, setTab] = useState(tabsComponents[0].name);
  const [modal, setModal] = useState({
    subject: {}, vehicle: {}, violation: {},
  });
  const [notConnectedRINS, setNotConnectedRINS] = useState(false);
  const [areSubjectsWithViolation, setAreSubjectsWithViolation] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [violationType, setViolationType] = useState(DEFAULT_AUTOCOMPLETE_OPTION);

  const isViolation = tab === 'violation';
  const isSubject = tab === 'subject';
  const isVehicle = tab === 'vehicle';
  const isDestination = tab === 'destination';

  const { referenceTypes } = useGetReferenceItems({ referenceUrl: ENTERPRISE_ACTIVITY_TYPES_ENUM });

  const transportingActivityType = useMemo(
    () => ['transporting'].map((type) => referenceTypes.find(({ code }) => compareId(code, type))?.id) || '',
    [referenceTypes],
  );

  const changeConnectedRINS = () => setNotConnectedRINS(!notConnectedRINS);

  const changeAreSubjectWithViolations = () => setAreSubjectsWithViolation(
    !areSubjectsWithViolation,
  );

  const changeModal = (modalData) => {
    setModal((prevState) => ({ ...prevState, [tab]: modalData }));
  };

  const close = () => changeModal({});

  const getColumns = () => {
    if (isSubject) return initialTableSubject;
    if (isVehicle) return initialTableVehicle;
    if (isViolation) return initialTableViolation;
    if (isDestination) return initialTableDestination;
  };

  const getTab = useCallback(() => {
    if (isSubject) return subjectRequest;
    if (isVehicle) return getVehiclesRequest;
    if (isViolation) return getViolationsRequest;
    if (isDestination) return getDestinationRequest;
  }, [isSubject, isVehicle, isViolation, isDestination]);

  const { isLoading, defaultRequest: getTableData } = useTableSearch({
    search: getTab(),
    setter: setTableData,
    deps: [tab, notConnectedRINS, areSubjectsWithViolation, violationType, transportingActivityType],
    params: {
      is_connected_rins: notConnectedRINS === true ? false : undefined,
      violations: isSubject && areSubjectsWithViolation ? true : undefined,
      [VIOLATION_TYPE_ENUM]: isViolation ? violationType.id : undefined,
      [ENTERPRISE_ACTIVITY_TYPE_IDS]: transportingActivityType?.length ? transportingActivityType.join(',') : undefined,
    },
  });

  const changeViolationType = (e, value) => setViolationType(value);

  useEffect(() => {
    setTableData([]);
    setViolationType(DEFAULT_AUTOCOMPLETE_OPTION);
  }, [tab]);

  const { isLoading: isLoadingSMAV, makeRequest: updateSMAV } = useApi({
    request: async () => {
      await importViolationRequest();
      await getTableData();
    },
  });

  return (
    <>
      <NavTabs tabsComponents={tabsComponents} tab={tab} setTab={setTab} />
      {isViolation && (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={3}>
          <AsyncAutocomplete
            sx={{ my: 2 }}
            label="Фильтр по типу нарушения"
            value={violationType}
            optionField={DEFAULT_AUTOCOMPLETE_FIELD}
            onChange={changeViolationType}
            request={(params) => getViolationEnumsRequest(VIOLATION_TYPE_ENUM, params)}
          />
          <CustomButton isLoading={isLoadingSMAV} onClick={updateSMAV}>
            Загрузить данные ЭИС
          </CustomButton>
        </Box>
      )}
      {isVehicle && <HeaderSortFilter onChange={changeConnectedRINS} value={notConnectedRINS} />}
      {isDestination && (
        <Box mb={3} mt={3}>
          <TextTitle>Период фиксации нарушения</TextTitle>
          <CustomDatePicker
            sx={{ mt: '10px', mr: '10px' }}
            placeholder="Период с:"
            name="formation_period"
            value={dateFrom}
            onChange={(e, value) => {
              setDateFrom(value);
            }}
          />
          <CustomDatePicker
            sx={{ mt: '10px' }}
            name="formation_period"
            placeholder="Период по:"
            value={dateTo}
            onChange={(e, value) => {
              setDateTo(value);
            }}
          />
        </Box>
      )}
      {(isVehicle || isSubject) && (
        <HeaderSortFilter
          component={(
            <CustomCheckbox
              label={isVehicle ? 'Не подключено к РИНС ЛО' : 'Субъекты с нарушениями'}
              onChange={isVehicle ? changeConnectedRINS : changeAreSubjectWithViolations}
              value={isVehicle ? notConnectedRINS : areSubjectsWithViolation}
            />
          )}
        />
      )}
      <CustomTable
        isLoading={isLoading}
        data={tableData}
        columns={getColumns()}
        tableBodySx={{
          '.MuiTableCell-root': {
            whiteSpace: 'initial',
          },
        }}
        onClick={changeModal}
      />
      {modal.subject.id !== undefined && (
        <ModalWasteCarrier close={close} subject={modal.subject} />
      )}
      {modal.vehicle.id !== undefined && (
        <ModalVehicleInfo close={close} vehicle={modal.vehicle} />
      )}
      {modal.violation.id !== undefined && (
        <ModalViolation close={close} violation={modal.violation} />
      )}
    </>
  );
}
