import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomButton from '../../components/Buttons/CustomButton';
import {
  PUBLIC_LOGIN_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE,
  PUBLIC_LOGIN_CADASTR_PAGE_ROUTE,
  PUBLIC_LOGIN_CARRIER_PAGE_ROUTE,
  PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE,
} from '../../constants/routes';
import { PETROSOFT_BEARER_TOKEN } from '../../constants/urls';

const buttonStyle = {
  width: '100%',
};

const PUBLIC_TER_SCHEME_URL = `http://192.168.25.149:8080/operator-ui/?aistoken=${PETROSOFT_BEARER_TOKEN}#template/3913`;

export default function Rko47InitialPage() {
  const navigate = useNavigate();

  const onNavigate = (route) => () => navigate(route);

  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box
        width="fit-content"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="20px"
      >
        <CustomButton sx={buttonStyle} onClick={onNavigate(PUBLIC_LOGIN_CADASTR_PAGE_ROUTE)}>Кадастр</CustomButton>
        <CustomButton sx={buttonStyle} onClick={onNavigate(PUBLIC_LOGIN_CADASTR_ADMIN_PAGE_ROUTE)}>
          Администратор Кадастра
        </CustomButton>
        <CustomButton sx={buttonStyle} onClick={onNavigate(PUBLIC_LOGIN_CARRIER_PAGE_ROUTE)}>ЛК Перевозчика</CustomButton>
        <CustomButton sx={buttonStyle} onClick={onNavigate(PUBLIC_LOGIN_COMMITTEE_PAGE_ROUTE)}>ЛК ГКЭН</CustomButton>
        <CustomButton sx={buttonStyle} onClick={onNavigate(PUBLIC_LOGIN_ADMIN_PAGE_ROUTE)}>Администрирование</CustomButton>
        <CustomButton sx={buttonStyle} href={PUBLIC_TER_SCHEME_URL}>Публичный портал терсхемы</CustomButton>
      </Box>
    </Box>
  );
}
