import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { useEffect } from 'react';

export const createLIcon = (icon, className, iconAnchor = [18, 0]) => {
  const classNameObject = className ? { className } : {};
  return L.icon({
    iconUrl: icon,
    iconShadow: icon,
    iconAnchor,
    ...classNameObject,
  });
};

let markerClusters = [];

export default function useClusterGroup({
  clusters, map,
}) {
  useEffect(() => {
    if (map && markerClusters.length) {
      markerClusters.forEach((item) => map.removeLayer(item));
      markerClusters = [];
    }

    if (clusters?.length && map && L?.markerClusterGroup) {
      const newClusters = [];
      clusters.forEach(({
        points,
        markerIcon,
        clusterColor,
        onClick,
        polygonColor,
        markerClassName = '',
        spiderfyDistanceMultiplier = 4,
        ...restOptions
      }) => {
        if (!points.length) return;

        const polygonColorOption = polygonColor ? { color: polygonColor } : {};

        const markers = L.markerClusterGroup({
          polygonOptions: {
            stroke: true,
            weight: 4,
            ...polygonColorOption,
            opacity: 0.5,
            fill: false,
            clickable: true,
          },
          removeOutsideVisibleBounds: true,
          spiderfyDistanceMultiplier,
          iconCreateFunction(cluster) {
            return L.divIcon({
              html: `<div class="custom-cluster" style="background: ${clusterColor}">${cluster.getChildCount()}</div>`,
            });
          },
          ...restOptions,
        });
        const iconOption = markerIcon ? { icon: createLIcon(markerIcon, `marker ${markerClassName}`, [18, 30]) } : {};
        points.forEach((point) => {
          if (point?.place?.[0]) {
            markers.addLayer(
              L.marker([point.place[1], point.place[0]], { ...iconOption })
                .bindTooltip(`${point.tooltip || ''}`, { permanent: true, direction: 'top', offset: [1, -30] })
                .on('click', (markerInfo) => onClick?.(point, markerInfo)),
            );
          }
        });
        newClusters.push(markers);
        map.addLayer(markers);
      });
      markerClusters.push(...newClusters);
    }

    /* eslint-disable-next-line */
  }, [clusters, map]);
}
