import React from 'react';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { flexGap2, flexRowGap2 } from '../../constants/lkCadastr/cadastrStorage';
import { TextTitle } from '../../components/Text';
import prikaz3PdfUrl from './prikaz3.pdf';

export default function CadastrAdminNormativeBase() {
  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <Box height="600px" sx={flexRowGap2}>
        <Box>
          <TextTitle>
            Приказ 3
          </TextTitle>
          <Link target="_blank" download href={prikaz3PdfUrl} rel="noreferrer">
            Cсылка на файл
          </Link>
        </Box>
        <Box flex="1 1 0">
          <Box component="iframe" width="100%" height="100%" title="Приказ 3" src={prikaz3PdfUrl} />
        </Box>
      </Box>
    </Box>
  );
}
