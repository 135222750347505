import {
  ADDRESS_BY_COORDINATES,
  ADDRESSES_CITIES_URL, ADDRESSES_DISTRICTS_URL, ADDRESSES_URL, ENUMS_URL,
} from '../constants/urls';
import api from './api';

export const addAddressRequest = (data) => api({
  url: ADDRESSES_URL,
  method: 'POST',
  data,
}, { isEntityAttributes: true });

export const addressByCoordinatesRequest = (params) => api({
  url: ADDRESS_BY_COORDINATES,
  params,
});

export const putAddressRequest = (data, id) => api({
  url: `${ADDRESSES_URL}/${id}`,
  method: 'PUT',
  data,
}, { isEntityAttributes: true });

export const getCitiesRequest = (params) => api({
  url: ADDRESSES_CITIES_URL,
  params,
}, { filter: (({ data: options }) => options.map(({ city }, index) => ({ id: `${city}${index}`, name: city }))) });

export const getDistrictsRequest = (params) => api({
  url: ADDRESSES_DISTRICTS_URL,
  params,
}, {
  filter: (({ data: options }) => {
    const uniqDistricts = new Map(
      options?.map((object) => [object.district, object.district]),
    );

    return Array.from(uniqDistricts).map((districtArray) => ({
      id: districtArray[0],
      label: districtArray[0],
      value: districtArray[0],
    }));
  }),
});

export const getAddressEnumsRequest = (enum_field, params) => api({
  url: `${ADDRESSES_URL}${ENUMS_URL}`,
  params,
}, {
  enum_field,
});
