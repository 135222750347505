import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { flexGap2 } from '../../constants/lkCadastr/cadastrStorage';
import FAQAccordion from '../../components/FAQAccordion';
import { useApi } from '../../hooks';
import { cadastreQuestionAnswersRequest } from '../../api/cadastreApi';
import SearchInput from '../../components/Inputs/SearchInput';
import { includesString } from '../../helpers/stringHelper';

export default function CadastrFAQ() {
  const [search, setSearch] = useState('');
  const [questions, setQuestions] = useState([]);

  const { defaultRequest: questionApi } = useApi({
    request: cadastreQuestionAnswersRequest,
  });

  useEffect(() => {
    questionApi({ params: { total: 1000 } }).then((resQuestions) => {
      setQuestions(resQuestions);
    });
  }, []);

  const changeSearch = (e, value) => {
    setSearch(value);
  };

  return (
    <Box pt={3} width="100%" sx={flexGap2}>
      <SearchInput sx={{ width: 'fit-content' }} value={search} onChange={changeSearch} />
      <FAQAccordion faqs={questions.filter((questionInfo) => includesString(questionInfo.question, search))} />
    </Box>
  );
}
