import { Box, styled } from '@mui/material';

const TitleBoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: 16,
});

export default TitleBoxStyled;
