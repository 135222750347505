import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { getErnicVehicleRequest } from '../api/vehiclesApi';

let interval;

export default function useRnicInfo({ guid_bnso }) {
  const [rnicInfo, setRnicInfo] = useState({});

  const { makeRequest } = useApi({
    request: () => getErnicVehicleRequest({
      value: guid_bnso,
    }),
    setter: ({ data }) => {
      if (data) setRnicInfo(data);
    },
    errorCallback: (e) => {
      if (interval) clearInterval(interval);

      e.frontendError = null;
    },
    shouldRequest: !!guid_bnso,
  });

  useEffect(() => () => {
    if (interval) clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!guid_bnso && interval) {
      clearInterval(interval);
      setRnicInfo({});
    }
    if (guid_bnso) {
      if (interval) clearInterval(interval);

      interval = setInterval(() => makeRequest(), 10000);
    }
    /* eslint-disable-next-line */
  }, [guid_bnso]);

  return { rnicInfo };
}
