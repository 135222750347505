import React from 'react';
import LoginPage from '../Public/LoginPage';
import { loginAdminRequest } from '../../api/adminApi';
import { getAdminRoutes } from '../../constants/routes/adminRoutes';
import { useGetRoleRedirectRoute } from '../../hooks/useGetRoleRedirectRoute';

export default function AdminLoginPage() {
  const getRedirectRoute = useGetRoleRedirectRoute({ getRoleRoutes: getAdminRoutes });

  return (
    <LoginPage
      forgotPasswordParams="admin"
      title="администратора"
      loginRequest={loginAdminRequest}
      redirectUrl={getRedirectRoute}
    />
  );
}
